<script>
import { AntennaType, AntennaName } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import BackgroundProperties from './background-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-background-properties': BackgroundProperties
  },

  data () {
    return {
      AntennaType
    }
  },

  computed: {
    // Available antenna types
    antennaTypes () {
      return Object
        .values(AntennaType)
        .map(value => ({ value, label: AntennaName[value] }))
    },

    allowChangingType () {
      return true
    }
  }
}

</script>

<template>
  <main class="plan-item-properties">
    <section v-if="allowChangingType">
      <label>
        Antenna
      </label>
      <q-select outlined square dense emit-value map-options :options="antennaTypes"
        :style="styles.select.wide" :model-value="getValue('antennaType', AntennaType.IndoorOmni)"
        @update:model-value="value => setProperties({ 'antennaType': value })">
      </q-select>
    </section>

    <section v-if="isAdvancedMode">
      <label>
        Strength (%)
      </label>
      <q-input
        type="number" :min="20" :max="500"
        dense outlined square
        debounce="500"
        :input-style="styles.number"
        :model-value="getValue('radiationStrength')"
        @update:model-value="value => setProperties({ radiationStrength: parseInt(value) })">
      </q-input>
    </section>
  </main>

  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction" :can-edit-label="false">
  </sc-item-properties>

  <sc-background-properties v-if="isAdvancedMode" :renderer="renderer" :items="items" @action="executeAction">
  </sc-background-properties>
</template>

<style lang="scss" scoped></style>
