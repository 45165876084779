<script>
import { mapActions, mapGetters } from 'vuex'
import { capitalize } from '@stellacontrol/utilities'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { Place, PlaceType, getPlaceIcon, getPlaceLabel, sortOrganizations, OrganizationSortOrder } from '@stellacontrol/model'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    // Place to edit
    place: {
      type: Place,
      required: true
    },
    // Organization which owns the place.
    // If not specified, organization selector is shown
    organization: {
      required: false
    },
    // Allows editing all details of the place.
    // If not specified, simplified editor is shown
    details: {
      default: true
    },
    // If true, we're editing a new place
    isNewPlace: {
      default: false
    }
  },

  data () {
    return {
      PlaceType
    }
  },

  computed: {
    ...mapGetters([
      'organizations',
      'currentOrganization'
    ]),

    // Default form name
    formName () {
      return 'place'
    },

    // Place types to choose from
    placeTypes () {
      return [
        this.placeType(PlaceType.Building),
        this.placeType(PlaceType.Ship),
        this.placeType(PlaceType.Vehicle),
        this.placeType(PlaceType.Other)
      ].filter(p => p)
    },

    // All organizations to select from
    visibleOrganizations () {
      const { currentOrganization, organizations, place } = this
      const items = [...organizations]
      if (place.organization && !organizations.find(o => o.id === place.organizationId)) {
        items.push(place.organization)
      }
      return sortOrganizations(items, OrganizationSortOrder.Rank, { currentOrganization })
    },

    // Currently selected organization
    selectedOrganization () {
      const { visibleOrganizations, place } = this
      return visibleOrganizations.find(o => o.id === place.organizationId)
    },

    // Returns true if organization can assign the place to another organization
    canEditChildPlaces () {
      return this.canUse('child-places')
    }
  },

  methods: {
    ...mapActions([
      'organizationPlaceExists'
    ]),

    capitalize,
    getPlaceIcon,
    getPlaceLabel,

    // Returns an option for place type select input
    placeType (value, options) {
      return {
        value,
        label: capitalize(getPlaceLabel(value, 'place')),
        ...options
      }
    },

    // Validation rule which checks whether the specified organization name is already in use
    async placeNameIsUnique (name) {
      const { isNewPlace, place } = this
      const { organization } = place
      const { id, exists } = await this.organizationPlaceExists({ name, organization })
      const isUnique = !exists || (!isNewPlace && id === this.place.id)
      return isUnique || 'The name must be unique'
    },

    // Organization has been selected, assign to place
    organizationSelected () {
      this.place.organization = this.selectedOrganization
    },

    // Checks whether the entered place is valid
    async isPlaceValid () {
      const { place } = this

      if (!place.name?.trim()) return false
      if (await this.placeNameIsUnique(place.name) !== true) return false

      return true
    }
  },

  created () {
    this.place.organization = this.organization || this.place.organization
  }
}
</script>

<template>
  <q-form ref="place" autofocus>
    <div class="q-gutter-sm">
      <q-select dense square outlined v-model="place.placeType" :options="placeTypes" emit-value
        lazy-rules map-options :rules="[
        rules.required('Place type is required')
      ]">
        <template v-slot:before> <label>Type</label> </template>
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section side>
              <q-icon :name="getPlaceIcon(scope.opt.value)" color="indigo-5" size="sm"></q-icon>
            </q-item-section>
            <q-item-section>
              <q-item-label v-html="scope.opt.label"></q-item-label>
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:selected>
          <div>
            <q-icon v-if="place.placeType" :name="getPlaceIcon(place.placeType)" size="sm"
              color="indigo-5" />
            <span class="q-ml-sm">{{ capitalize(getPlaceLabel(place.placeType, '-')) }}</span>
          </div>
        </template>
        <template v-slot:after>
          <sc-hint text="Type of the place, such as building, ship etc."></sc-hint>
        </template>
      </q-select>

      <sc-organization-selector bg-color="white" dense square style="margin-bottom: 20px;"
        v-if="!organization" v-model="place.organizationId" :readonly="!canEditChildPlaces"
        :items="visibleOrganizations" @update:model-value="value => organizationSelected(value)">
        <template v-slot:before>
          <label>Owner</label>
        </template>
        <template v-slot:after>
          <sc-hint>
            Organization to which the {{ getPlaceLabel(place.placeType) }} belongs
          </sc-hint>
        </template>
      </sc-organization-selector>

      <q-input v-model="place.name" dense square outlined debounce="1000" maxlength="255" lazy-rules
        :rules="[
        rules.required('Name is required'),
        name => placeNameIsUnique(name)
      ]">
        <template v-slot:before>
          <label>
            {{ capitalize(getPlaceLabel(place.placeType)) }}
            Name
          </label>
        </template>
        <template v-slot:after>
          <sc-hint>
            Unique name of the {{ getPlaceLabel(place.placeType) }}
          </sc-hint>
        </template>
      </q-input>

      <q-input v-model="place.description" dense square outlined maxlength="255">
        <template v-slot:before>
          <label>
            {{ capitalize(getPlaceLabel(place.placeType)) }}
            Description
          </label>
        </template>
        <template v-slot:after>
          <sc-hint-placeholder></sc-hint-placeholder>
        </template>
      </q-input>

      <q-input v-model="place.city" dense square outlined
        v-if="details && place.placeType === PlaceType.Building" maxlength="255" class="q-mt-lg">
        <template v-slot:before>
          <label>City</label>
        </template>
        <template v-slot:after>
          <sc-hint-placeholder></sc-hint-placeholder>
        </template>
      </q-input>

      <q-input v-model="place.address" dense square outlined
        v-if="details && place.placeType === PlaceType.Building" maxlength="255" class="q-mt-lg">
        <template v-slot:before>
          <label>Address</label>
        </template>
        <template v-slot:after>
          <sc-hint-placeholder></sc-hint-placeholder>
        </template>
      </q-input>

      <q-field class="q-mt-md" dense borderless
        v-if="details && isSuperAdministrator && place.placeType === PlaceType.Ship">
        <div class="row items-center">
          <q-toggle v-model="place.hasRegion" color="green"></q-toggle>
          <sc-hint>
            If enabled, RF region of devices in this
            {{ getPlaceLabel(place.placeType) }}
            can be changed by administrator.
          </sc-hint>
        </div>
        <template v-slot:before>
          <label>Can change RF Region?</label>
        </template>
      </q-field>

      <q-field dense borderless v-if="details && !place.isNew">
        <template v-slot:before>
          <label>Created</label>
        </template>
        <div class="row items-center">
          {{ place.createdText }}
        </div>
      </q-field>

      <q-field dense borderless v-if="details && !place.isNew">
        <template v-slot:before>
          <label>Updated</label>
        </template>
        <div class="row items-center">
          {{ place.updatedText }}
        </div>
      </q-field>
    </div>
  </q-form>
</template>

<style scoped lang="scss">
label {
  font-size: 14px;
  min-width: 160px;
}
</style>