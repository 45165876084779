import { safeParseFloat } from '@stellacontrol/utilities'

/**
 * Values reported by device which should be deemed
 * as no-position
 * @type {Array[String]}
 */
const GEO_EMPTY = [
  '0.000000, 0.000000',
  '0.00000, 0.000'
]

/**
 * Geographic coordinates
 */
export class GeoCoordinates {
  constructor (data = {}) {
    Object.assign(this, data)
  }

  /**
   * Latitude in degrees
   * @type {Number}
   */
  latitude

  /**
   * Longitude in degrees
   * @type {Number}
   */
  longitude

  /**
   * Checks whether the geographic location is valid
   * @type {Boolean}
   */
  get isValid () {
    return this.latitude != null && this.longitude != null
  }

  /**
   * Determines whether the specified text represents an empty GPS position.
   * Devices not only report `null` or empty string, but also various
   * mutations of `0` values which all should be deemed as no-position.
   * @param {String} value Value to check
   * @returns {Boolean}
   */
  static isValid (value) {
    if (value == null ||
      !value.toString().trim() ||
      GEO_EMPTY.includes(value)) {
      return false
    }

    const coordinates = GeoCoordinates.fromString(value)
    return coordinates != null
  }

  /**
   * Parses the coordinates from string, as submitted by device
   * eg. "gps": ".000000, .0000",
   * @return {GeoCoordinates}
   */
  static fromString (value) {
    if (value) {
      const parts = value
        .toString()
        .split(',')
        .map(v => (v || '').trim())
      if (parts.length === 2) {
        const latitude = safeParseFloat(parts[0].startsWith('.') ? `0.${parts[0]}` : parts[0])
        const longitude = safeParseFloat(parts[1].startsWith('.') ? `0.${parts[1]}` : parts[1])
        if (latitude != null && longitude != null) {
          return new GeoCoordinates({ latitude, longitude })
        }
      }
    }
  }

  /**
   * Parses the coordinates from specified latitude and longitude
   * @return {GeoCoordinates}
   */
  static fromLatLon (latitude, longitude) {
    if (latitude != null && longitude != null) {
      return new GeoCoordinates({ latitude, longitude })
    }
  }
}
