import { mapGetters } from 'vuex'
import { distinctValues, getPropertyValue } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { PlannerMode } from '@stellacontrol/model'
import { PlanItemType } from '@stellacontrol/planner'
import { PlanActions } from '../../../renderer/actions'
import styles from './input-styles'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Plan renderer
    renderer: {
    },
    // Items to edit the properties for
    items: {
    }
  },

  data () {
    return {
      PlanItemType,
      // Default styles for controls
      styles
    }
  },

  computed: {
    ...mapGetters([
      'planEditingMode'
    ]),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.planEditingMode === PlannerMode.Advanced
    },

    // Determines whether the planner allows only a limited feature set
    isRegularMode () {
      return this.planEditingMode === PlannerMode.Regular
    },

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.planEditingMode === PlannerMode.ReadOnly
    },

    // Determines whether the planner works in edit mode
    isEditable () {
      return !this.isReadOnly
    },

    // Layout details
    layout () {
      return this.renderer.layout
    },

    // Edited floor
    floor () {
      return this.renderer.floor
    },

    // Single edited item
    item () {
      return this.items[0]
    },

    // Indicates editing of multiple items
    isBatch () {
      return this.items.length > 1
    },

    // Returns true if all items are one of the specified types
    isType () {
      return (...itemTypes) => {
        return (this.items || []).every(i => i.is(...itemTypes))
      }
    },

    // Returns true if all items support the specified property
    hasProperty () {
      return (name) => {
        return (this.items || []).every(i => i.hasProperty(name))
      }
    },

    // Returns shared value of the specified property in all items
    getValue () {
      return (getter, defaultValue) => {
        const fn = typeof getter === 'string'
          ? item => getPropertyValue(item, getter, defaultValue, false)
          : getter
        const values = distinctValues(this.items || [], fn) || []
        return values[0]
      }
    },

    // Returns true if the specified property has
    // the specified value for all items
    isEqual () {
      return (getter, value) => {
        return distinctValues(this.items || [], getter).every(v => v === value)
      }
    },

    // Returns true if the specified property does not have
    // the specified value for all items
    isNotEqual () {
      return (getter, value) => {
        return distinctValues(this.items || [], getter).every(v => v !== value)
      }
    }
  },

  methods: {
    // Passes the action requested in the properties
    executeAction (details) {
      this.$emit('action', details)
    },

    // Sets item properties
    setProperties (properties) {
      const { items } = this
      if (items?.length > 0) {
        this.executeAction({
          action: PlanActions.SetItemProperties,
          items,
          properties
        })
      }
    }
  },

  emits: [
    'action'
  ]
}
