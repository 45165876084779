import { isEnum } from '@stellacontrol/utilities'
import { bandsToIdentifiers } from '@stellacontrol/model'
import { FastSamplingSpeed } from './fast-sampling-speed'

/**
 * Known device commands
 */
export const DeviceCommands = {
  Ping: 'PING',
  StartFastSampling: 'START_FAST_SAMPLING',
  Reset: 'RESET',
  Reboot: 'REBOOT',
  ToggleBands: 'TOGGLE_BANDS',
  WipeEEPROM: 'EPROM_WIPE',
  Blink: 'BLINK',
  RequestCellScan: 'CELL_SCAN',
  RequestDeepCellScan: 'DEEP_CELL_SCAN',
  MoveToStock: 'MOVE_TO_STOCK'
}

/**
 * Device command
 */
export class DeviceCommand {
  constructor (data = {}) {
    Object.assign(this, {
      ...data,
      parameters: data.parameters || {}
    })
  }

  /**
   * Command name
   * @type {String}
   */
  name

  /**
   * Command parameters
   * @type {Dictionary<String, any>}
   */
  parameters

  // COMMAND FACTORY METHODS
  /**
   * Verifies whether a command with specified name is a valid command
   * @param {String} name Command name
   * @returns {Boolean} True if command is a valid command
   */
  static isKnownCommand (name) {
    if (!name) throw new Error('Command name is required')
    return isEnum(DeviceCommands, name)
  }

  /**
   * Creates an instance of a command with specified name and parameters
   * @param {String} name Command name
   * @param {Object} parameters Required command parameters
   * @returns {DeviceCommand} Command instance
   */
  static create (name, parameters) {
    if (!name) throw new Error('Command name is required')
    if (!DeviceCommand.isKnownCommand(name)) throw new Error(`Unknown device command: ${name}`)
    return new DeviceCommand({ name, parameters })
  }

  /**
   * Creates a ping command
   * @returns {DeviceCommand}
   */
  static ping () {
    return DeviceCommand.create(DeviceCommands.Ping)
  }

  /**
   * Creates a blink command
   * @returns {DeviceCommand}
   */
  static blink () {
    return DeviceCommand.create(DeviceCommands.Blink)
  }

  /**
   * Creates a command turning on fast-sampling mode
   * @param {String} speed Sampling speed identifier: 10m, 10s, 1s, 250ms
   * @param {Number} duration Fast sampling duration, number of seconds after which device
   * will switch back to slow mode
   * @returns {DeviceCommand}
   */
  static startFastSampling (speed = FastSamplingSpeed.OneSecond, duration = 60) {
    return DeviceCommand.create(DeviceCommands.StartFastSampling, {
      FAST_SAMPLING_SPEED: speed,
      FAST_SAMPLING_WINDOW_SECS: duration
    })
  }

  /**
   * Creates a command for resetting devices
   * @returns {DeviceCommand}
   */
  static reset () {
    return DeviceCommand.create(DeviceCommands.Reset, undefined, true)
  }

  /**
   * Creates a command for resetting devices back to factory settings
   * by wiping their EEPROM
   * @returns {DeviceCommand}
   */
  static wipeEEPROM () {
    return DeviceCommand.create(DeviceCommands.WipeEEPROM)
  }

  /**
   * Creates a command for shutting down device bands
   * @param {Device} device Device to shut down the bands
   * @returns {DeviceCommand}
   */
  static shutdownBands (device) {
    if (!device) throw new Error('Device is required')
    if (!device.bands) throw new Error('Device bands are required')

    const identifiers = bandsToIdentifiers(device.bands)
    const shadow = identifiers.reduce((values, identifier) => {
      const key = `_shutdown_${identifier}`
      values[key] = true
      return values
    }, {})
    return DeviceCommand.create(DeviceCommands.ShutdownBands, shadow, true)
  }

  /**
   * Creates commands for shutting down device bands for multiple devices
   * @param {Array[Device]} devices Devices to shut down the bands
   * @returns {DeviceCommand}
   */
  static shutdownBandsMany (devices = []) {
    return devices
      .map(device => DeviceCommands.shutdownBands(device))
  }

  /**
   * Creates a command for turning on device bands
   * @param {Device} device Device to turn on the bands
   * @returns {DeviceCommand}
   */
  static turnOnBands (device) {
    if (!device) throw new Error('Device is required')
    if (!device.bands) throw new Error('Device bands are required')

    const identifiers = bandsToIdentifiers(device.bands)
    const shadow = identifiers.reduce((values, identifier) => {
      const key = `_shutdown_${identifier}`
      values[key] = false
      return values
    }, {})
    return DeviceCommand.create(DeviceCommands.TurnOnBands, shadow, true)
  }

  /**
   * Creates a command for turning on device bands for multiple devices
   * @param {Array[Device]} devices Devices to turn on the bands
   * @returns {DeviceCommand}
   */
  static turnOnBandsMany (devices = []) {
    return devices
      .map(device => DeviceCommands.turnOnBands(device))
  }
}
