<script>
import { PlanEditMode } from '@stellacontrol/planner'

export default {
  props: {
    // Plan renderer
    renderer: {
    },
    // Plan layout to edit
    layout: {
    },
    // Floor to show
    floor: {
    }
  },

  computed: {
    /**
     * Current edit mode of the plan
     * @type {PlanEditMode}
     */
    editMode () {
      return this.renderer?.editMode
    },

    /**
     * Label representing the current edit mode of the plan
     * @type {String}
     */
    editModeLabel () {
      const { editMode, renderer } = this
      const { itemToAdd } = renderer
      switch (editMode) {
        case PlanEditMode.AddingItems:
          if (itemToAdd) {
            const label = itemToAdd.labelLong || itemToAdd.label
            return `Click to add ${label} to the plan. Hold <b>CTRL</b> and click to add multiple ${label}s.`
          }
          break

        case PlanEditMode.DrawingLine:
          return 'Click the line points. Finish with <b>double-click</b> or <b>ENTER</b>. Press <b>ESC</b> to cancel'

        case PlanEditMode.DrawingPolygon:
          return 'Click the polygon points. Click on the first point to close the polygon. Press <b>ESC</b> to cancel'

        case PlanEditMode.DrawingWalls:
          return 'Click the wall points. Click on the first point or press <b>ENTER</b> to finish the wall. Press <b>ESC</b> to cancel'

        case PlanEditMode.EditingWalls:
          return 'Click and drag the wall points. Press <b>ESC</b> to finish'

        case PlanEditMode.DrawingMapScale:
          return 'Click two points indicating the distance of one meter. Press <b>ESC</b> to cancel'

        case PlanEditMode.DrawingRuler:
          return 'Click two points to measure the distance between them. Press <b>ESC</b> to cancel'

        case PlanEditMode.SelectingTransparentColors:
          return 'Click on colored areas to make them transparent. Press <b>ENTER</b> to finish, <b>ESC</b> to cancel'

        case PlanEditMode.DrawingCable:
          return 'Click to guide the cable. Click the destination port to finish. <b>Right-click</b> to cancel the last point, <b>ESC</b> to cancel the entire cable.'
      }
      return ''
    },

    // Determines whether FINISH button should be displayed for the current edit mode
    isFinishActionVisible () {
      const { editMode } = this

      const hasFinish = [
        PlanEditMode.SelectingTransparentColors
      ]

      return hasFinish.includes(editMode)
    },

    // Determines whether CANCEL button should be displayed for the current edit mode
    isCancelActionVisible () {
      const { editMode } = this
      const noCancel = []
      return !noCancel.includes(editMode)
    }
  },

  methods: {
    // Cancels the ongoing editing
    async cancelEditing () {
      const { renderer } = this
      await renderer.cancelEditing()
      await renderer.deselect()
    },

    // Finishes the ongoing editing
    async finishEditing () {
      const { renderer } = this
      await renderer.finishEditing()
      await renderer.deselect()
    }
  }
}
</script>

<template>
  <div class="edit-mode q-pl-md q-pt-sm q-pr-md q-pb-sm bg-indigo-7 text-white row items-center"
    v-if="editModeLabel">
    <span v-html="editModeLabel" class="q-mr-lg">
    </span>
    <q-btn v-if="isFinishActionVisible" label="Finish" dense flat @click="finishEditing()"
      class="text-white bg-indigo-5 q-mr-sm">
    </q-btn>
    <q-btn v-if="isCancelActionVisible" label="Cancel" dense flat @click="cancelEditing()"
      class="text-orange bg-indigo-5">
    </q-btn>
  </div>
</template>

<style scoped lang="scss">
.edit-mode {
  flex: 1;
}
</style>
