// Default styles for input controls in the palette
export default {
  color: {
    width: '108px',
    height: '40px'
  },
  number: {
    'width': '84px',
    'max-width': '84px',
    small: {
      'width': '30px',
      'max-width': '30px',
    },
  },
  text: {
    'width': '84px',
    'max-width': '84px',
    medium: {
      'width': '160px',
      'max-width': '160px'
    },
    wide: {
      'width': '197px',
      'max-width': '197px',
    },
    max: {
      'width': '100%',
      'max-width': '100%',
      'padding-right': '2px'
    }
  },
  select: {
    'width': '100%',
    small: {
      'width': '108px',
      'max-width': '108px'
    },
    medium: {
      'width': '204px',
      'max-width': '204px'
    },
    wide: {
      'width': '221px',
      'max-width': '221px',
    }
  }
}
