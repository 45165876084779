import { stringCompare, numberCompare, groupItems, notNull } from '@stellacontrol/utilities'
import { EntityType, GSMServiceName, bandLabelToIdentifier, getBandFrequency } from '@stellacontrol/model'
import { getSignalLevel, plmnToOperator } from './utilities'

/**
 * Extracts the survey scan results from the specified scan
 * @param {Attachment} attachment Scan file to process
 * @param {Object} options Various options
 * @returns {Object}
 */
export function getScanSurvey (attachment, { skipUnboostedBands } = {}) {
  if (!attachment?.isScan) return
  if (!attachment.data?.results) return
  const { surveyResults: items, operatorNames: operators = {} } = attachment.data.results
  if (!(items?.length > 0)) return

  // Extract scan results of individual cells, avoid duplicates and empties
  const isDuplicate = (item, items) =>
    items.some(i =>
      item.plmn === i.plmn &&
      item.cellId === i.cellId &&
      item.bandNumber === i.bandNumber)

  // Get device which has sent the scan
  const device = attachment.entityType === EntityType.Device
    ? attachment.entity
    : null

  // Get individual cell results,
  // Resolve operator, get timing metrics,
  // Check whether they're for the scanning cell or neighboring cell,
  const cells = items
    .flatMap(({ cellInfos, timeOffsetMs, timeTakenMs }) => {
      const cells = cellInfos
        .map(cellInfo => {
          const { cellId, bandNumber, serviceType, plmn } = cellInfo
          // Check if band is supported by the device.
          // The modem might pick up signals from bands which are not amplified by the device!
          const bandLabel = `B${bandNumber}`
          const band = bandLabelToIdentifier(bandLabel)
          const deviceHasBand = device?.hasBand(band) || device?.isTestTool
          if (skipUnboostedBands && !deviceHasBand) return

          // For TT devices the reported signal power must be reduced by 40dB
          // as per https://stelladoradus.atlassian.net/browse/SCL-629
          const isTestTool = device?.isTestTool
          const signalPower = notNull(cellInfo.signalPower, cellInfo.peakSignalPower) -
            (isTestTool ? 30 : 0)
          const signalQuality = notNull(cellInfo.signalQuality, cellInfo.peakQuality)
          const operator = plmnToOperator(plmn, operators)
          const key = `${plmn}-${cellId}-${bandNumber}-${serviceType}`
          const service = GSMServiceName[serviceType] || 'Unknown'

          // Calculate power/quality indicators
          const { level: signalPowerLevel, range: signalPowerRange } = getSignalLevel(signalPower, -120, -105, -100, -60)
          const { level: signalQualityLevel, range: signalQualityRange } = getSignalLevel(signalQuality, -20, -14, -12, -5)

          return {
            key,
            band,
            bandLabel,
            deviceHasBand,
            plmn,
            operator,
            service,
            timeOffsetMs,
            timeTakenMs,
            ...cellInfo,
            signalPower,
            signalQuality,
            signalPowerLevel,
            signalPowerRange,
            signalQualityLevel,
            signalQualityRange,
            appearances: undefined
          }
        })

      return cells.filter(cell => cell != null && cell.cellId !== 0)
    })
    .reduce((all, cell) => isDuplicate(cell, all) ? [...all] : [cell, ...all], [])

  // Sort by operator, then band number
  cells.sort((a, b) => {
    let result = stringCompare(a.operator, b.operator)
    if (result === 0) result = -numberCompare(
      getBandFrequency(a.band),
      getBandFrequency(b.band)
    )
    if (result === 0) result = numberCompare(a.physicalCellId, b.physicalCellId)
    return result
  })

  // Group by operator
  const groups = groupItems(cells, 'operator')
  return groups
}
