<script>
import { PlanBackgroundStyle, PlanLayerLabels } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  props: {
    label: {
      type: Boolean,
      default: true
    },

    labelText: {
      default: 'Label'
    },

    labelRadius: {
      default: 'Radius'
    },

    canEditLabel: {
      default: true
    },

    canEditPosition: {
      default: true
    },

    canEditSize: {
      default: true
    }
  },

  data () {
    return {
      defaults: new PlanBackgroundStyle().defaults,
      PlanLayerLabels
    }
  },

  computed: {
    canResize () {
      return this.items.every(item => item.canResize)
    },

    canRotate () {
      return this.items.every(item => item.canRotate)
    }
  }
}

</script>

<template>
  <main class="plan-item-properties">
    <!-- item identifier -->
    <section v-if="renderer.isDebugging && isAdvancedMode">
      <label>
        ID
      </label>
      <q-input dense outlined square readonly :input-style="styles.text.wide"
      :model-value="getValue('id')" type="text">
      </q-input>
    </section>

    <section v-if="!item.isOnItemLayer && isAdvancedMode">
      <label>
        Layer
      </label>
      <q-input dense outlined square readonly :input-style="styles.text.wide"
      :model-value="PlanLayerLabels[item.layer]" type="text">
      </q-input>
    </section>

    <!-- label / text -->
    <section v-if="label && hasProperty('label') && item.isNot(PlanItemType.Text) && canEditLabel">
      <label>
        {{ labelText }}
      </label>
      <q-input dense outlined square debounce="500" label="Text" :input-style="styles.text"
        :model-value="getValue('label')" type="text"
        @update:model-value="label => setProperties({ label })">
      </q-input>
      <q-input type="number" :min="1" :max="100" dense outlined square label="Size" debounce="500" class="q-ml-xs"
        :input-style="{ width: '39px' }" :model-value="getValue('textStyle.size')"
        @update:model-value="value => setProperties({ 'textStyle.size': parseInt(value) })">
      </q-input>
      <sc-color-picker :model-value="getValue('textStyle.color', defaults.color)" class="q-ml-xs"
        @update:model-value="value => setProperties({ 'textStyle.color': value })" full clear
        width="42px" :height="styles.color.height">
      </sc-color-picker>
    </section>
    <section v-if="label && hasProperty('label') && item.is(PlanItemType.Text) && canEditLabel">
      <q-input dense outlined square debounce="500" label="Text" :style="styles.text.max" :input-style="styles.text.max"
        :model-value="getValue('label')" type="textarea"
        @update:model-value="label => setProperties({ label })">
      </q-input>
    </section>

    <!-- position and size -->
    <section v-if="hasProperty('x') && isAdvancedMode && canEditPosition">
      <label>
        Position
      </label>
      <q-input type="number" :min="0" :max="floor.canvasDimensions.width" dense outlined square label="X"
        debounce="500" :input-style="styles.number" :model-value="getValue('x')"
        @update:model-value="value => setProperties({ x: parseInt(value) })">
      </q-input>
      <q-input type="number" :min="0" :max="floor.canvasDimensions.height" dense outlined square label="Y"
        class="q-ml-xs" debounce="500" :input-style="styles.number" :model-value="getValue('y')"
        @update:model-value="value => setProperties({ y: parseInt(value) })">
      </q-input>
    </section>

    <section v-if="hasProperty('radius') && canResize && isAdvancedMode && canEditSize">
      <label>
        Size
      </label>
      <q-input type="number" :min="1" :max="floor.canvasDimensions.width" dense outlined square
        :label="labelRadius" debounce="500" :input-style="styles.number"
        :model-value="getValue('radius')"
        @update:model-value="value => setProperties({ radius: parseInt(value) })">
      </q-input>
    </section>
    <section v-else-if="hasProperty('width') && canResize && isAdvancedMode && canEditSize">
      <label>
        Size
      </label>
      <q-input type="number" :min="1" :max="floor.canvasDimensions.width" dense outlined square label="Width"
        debounce="500" :input-style="styles.number" :model-value="getValue('width')"
        @update:model-value="value => setProperties({ width: parseInt(value) })">
      </q-input>
      <q-input type="number" :min="1" :max="floor.canvasDimensions.height" dense outlined square
        label="Height" class="q-ml-xs" debounce="500" :input-style="styles.number"
        :model-value="getValue('height')"
        @update:model-value="value => setProperties({ height: parseInt(value) })">
      </q-input>
    </section>

    <slot>
    </slot>
  </main>
</template>

<style lang="scss">

.plan-item-properties {
  > section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
    font-size: 13px;

    > label:not(.q-field) {
      min-width: 93px;
      margin-right: 8px;

      &.top {
        align-self: baseline;
        margin-top: 4px;
      }
    }
  }
}

</style>
