import { PlannerMode } from '@stellacontrol/model'

// Plan list columns
const columns = [
]

export function createState () {
  return {
    // Plan list columns
    columns,

    // Plans of the current organization
    plans: [],

    // Edited plan
    plan: null,

    // Edited plan floor
    floorId: null,

    // Indicates that plan editor is maximized
    isMaximized: false,

    // Identifiers of the recently selected items.
    // Used to preserve selection while changing floors
    recentlySelectedItems: [],

    // Indicates that there's content in the clipboard
    // that can be pasted into the plan
    canPaste: false,

    // Planner usage mode: normal, advanced or read-only
    mode: PlannerMode.Regular,

    // Detailed explanation why the specified planner editing mode is set. Specified when some automatic restrictions are applied, such as during concurrent editing.
    modeDetails: null,

    // Push listeners for plan status updates
    planListeners: {},

    // Edit lock on plans has limited duration.
    // This to prevent indefinite locks, if `editing` flag is not cleared on the plan
    // due to technical issues
    editLockDuration: 1800
  }
}

export const state = createState()

export const getters = {
  // Plan editing mode.
  // If user cannot use Advanced mode, enforce regular mode!
  planEditingMode: (state, getters) => {
    const { mode } = state
    const { guardian } = getters

    if (guardian) {
      const canEdit = guardian.canUse('planner-edit-plans') && mode !== PlannerMode.ReadOnly
      const canUseAdvancedMode = guardian.canUse('planner-advanced') && mode !== PlannerMode.ReadOnly

      if (canEdit) {
        return (mode === PlannerMode.Advanced && !canUseAdvancedMode)
          ? PlannerMode.Regular
          : mode
      }
    }

    return PlannerMode.ReadOnly
  },

  // Indicates whether we're now editing a plan
  isEditingPlan: (state) => {
    return state.plan !== null && state.mode !== PlannerMode.ReadOnly
  },

  // Currently edited plan
  editedPlan: (state) => {
    if (state.mode !== PlannerMode.ReadOnly) {
      return state.plan
    }
  },

  // Indicates whether we're now watching plan updates by other users
  isWatchingPlanUpdates: (state) => {
    return state.plan !== null &&
      Object
        .values(state.planListeners)
        .some(l => l != null)
  },
}
