import { Point, safeParseInt, merge } from '@stellacontrol/utilities'
import { PlanItem, PlanItemType } from './plan-item'
import { PlanArrowStyle } from '../styles'

/**
 * Line
 */
export class PlanLine extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Line
  }

  /**
   * Creates a new generic line
   * @param {Object} data Initial data
   * @returns {PlanLine}
   */
  static create (data = {}) {
    const points = [
      new Point({ x: 0, y: 0 }),
      new Point({ x: 0, y: 0 })
    ]
    if (data.x && data.y) {
      points.every(p => p.moveBy(data))
    }
    return new PlanLine({
      ...data,
      points,
      lineStyle: merge({ width: 2 }, data.lineStyle),
    })
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      // Lines don't have a position, they're defined by their points
      x: undefined,
      y: undefined,
      z: undefined,
      // If specified, line points are aligned if moved by margin smaller than the specified here
      alignThreshold: 4,
      // Default arrow style
      arrowStyle: PlanArrowStyle.None,
      // Maximal number of points which the line can have
      maxPointCount: 0
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.points = this.castArray(this.points, Point, [])
    this.arrowStyle = this.customize(this.arrowStyle, PlanArrowStyle, defaults.arrowStyle)
    this.alignThreshold = safeParseInt(this.alignThreshold, defaults.alignThreshold)
    this.maxPointCount = safeParseInt(this.maxPointCount, defaults.maxPointCount)
  }

  /**
   * Checks whether the data of the item is valid.
   * Invalid items will be discarded on load, to prevent the application from crashing
   * @type {Boolean}
   */
  get isValid () {
    return this.points.length > 1
  }

  /**
   * Removes any duplicate {@link points}
   */
  normalizePoints () {
    const points = this.points
      .filter((p, i, points) => i === 0 || !p.sameAs(points[i - 1]))
    this.points = points
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()
    delete result.x
    delete result.y
    delete result.z
    if (result.arrowStyle?.isDefault()) {
      delete result.arrowStyle
    }
    return result
  }

  /**
   * Maximal amount of points to add for the line.
   * If specified and the count is reached, the line is automatically finished.
   * @type {Number}
   */
  maxPointCount

  /**
   * Checks whether the item has all the points as required by {@link maxPointCount}
   * @type {Boolean}
   */
  get hasAllPoints () {
    if (this.maxPointCount > 0) {
      return this.points.length === this.maxPointCount
    } else {
      return false
    }
  }

  /**
   * Returns the first point of the line
   * @returns {Point}
   */
  getFirstPoint () {
    return this.points[0]
  }

  /**
   * Line points
   * @type {Array[Point]}
   */
  points

  /**
   * Returns points making up the shape
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]}
   */
  // eslint-disable-next-line no-unused-vars
  getPoints (isCrossSection) {
    return this.points || []
  }

  /**
   * Assigns points making up the shape,
   * if {@link isPointBased} shape
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @param {Array[Point]} points Points to assign
   */
  // eslint-disable-next-line no-unused-vars
  setPoints (points, isCrossSection) {
    this.points = (points || [])
      .filter(t => t)
      .map(t => Point.from(t))
  }

  /**
   * Line start
   * @type {Point}
   */
  get start () {
    return this.points[0]
  }

  /**
   * Line end
   * @type {Point}
   */
  get end () {
    return this.points[this.points.length - 1] || this.points[0]
  }

  /**
   * Line arrow style
   * @type {PlanArrowStyle}
   */
  arrowStyle

  /**
   * Indicates that connector has one or more arrows
   * @type {Boolean}
   */
  get hasArrows () {
    return this.arrowStyle && !this.arrowStyle.isEmpty
  }

  /**
   * If true, line points are aligned if moved by margin smaller than the specified here
   * @type {Number}
   */
  alignThreshold

  /**
   * Adds a point to the line.
   * @param {Point} point Point to add
   * @param {Number} index Index at which to add the point
   * @param {Point} align If true, points are aligned to each other to ensure proper straight angles when movements are minuscule
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]} Item points
  */
  // eslint-disable-next-line no-unused-vars
  addPoint (point, index, align, isCrossSection) {
    const { points, alignThreshold } = this
    if (index == null) {
      index = points.length
    }
    if (index >= 0 && index <= points.length) {
      // Align the point, if position on X and/or Y axis is only slightly different,
      // to ensure that we have nice straight lines
      if (align) {
        const previous = points[index - 1]
        const next = points[index]
        this.alignPoint(previous, point, next, alignThreshold)
      }
      // Insert the point at the specified index
      this.points = [...points.slice(0, index), point, ...points.slice(index)]
    }
    return this.points
  }

  /**
   * Removes a point from the line.
   * @param {Number} index Index at which to remove the point
   * @returns {Array[Point]} Item points
   */
  // eslint-disable-next-line no-unused-vars
  removePoint (index, isCrossSection) {
    const { points } = this
    if (index >= 0 && index < points.length) {
      this.points = [...points.slice(0, index), ...points.slice(index + 1)]
    }
    this.normalizePoints()
    return this.points
  }

  /**
   * Removes the last point from the shape.
   * @param {Number} index Index at which to remove the point
   * @returns {Point} Removed point
  */
  // eslint-disable-next-line no-unused-vars
  removeLastPoint (isCrossSection) {
    const count = this.points.length
    const lastPoint = this.points[count - 1]
    this.points = this.points.slice(0, count - 1)
    return lastPoint
  }

  /**
   * Moves line point to the specified new coordinates
   * @param {Number} index Point index
   * @param {Point} position Position to which the point was moved
   * @param {Point} align If true, points are aligned to each other
   * to ensure proper straight angles when movements are minuscule
   * @returns {Point} Moved point
   */
  movePoint (index, position, align) {
    const { points, alignThreshold } = this
    const point = points[index]
    const previous = points[index - 1]
    const next = points[index + 1]
    if (point) {
      const { x, y } = position.round()
      point.x = x
      point.y = y
      if (align) {
        this.alignPoint(previous, point, next, alignThreshold)
      }
      return this.getPoint(index)
    }
  }

  /**
   * Returns the index of the last point of the item
   * @returns {Number}
   */
  get lastPointIndex () {
    return this.points.length - 1
  }

  /**
   * Moves the item by the specified delta.
   * @param {Point} delta Delta to move the item by
   * @returns {PlanItem}
   */
  moveBy (delta) {
    super.moveBy(delta)
    if (delta) {
      for (const point of this.points) {
        point.moveBy(delta)
      }
    }
    return this
  }

  /**
   * Sets item properties from the specified dictionary
   * @param {Dictionary<String, any|Function<PlanItem>} properties Properties to set
   * @description Properties are specified as dictionary, where keys represent:
   * - simple property names, such as `x` or `width`
   * - nested property names, such as `scale.x` or `backgroundStyle.color`
   * - special value `item`, which requires that the value of the key
   *   is a function plan item as input parameter, and modifying it
   */
  setProperties (properties = {}) {
    super.setProperties(properties)
    // If line width changed, update the joint style
    this.jointStyle.radius = Math.max(5, Math.ceil(this.lineStyle.width * 1.2))
  }
}
