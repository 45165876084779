import { reactive } from 'vue'

/**
 * Viewport service
 */
export const Viewport = reactive({
  /**
  * Typical screen sizes
  */
  VGA: {
    width: 640,
    height: 480
  },
  SVGA: {
    width: 800,
    height: 600
  },
  XGA: {
    width: 1024,
    height: 768
  },
  HD: {
    width: 1366,
    height: 768
  },
  HDPlus: {
    width: 1600,
    height: 900
  },
  FullHD: {
    width: 1920,
    height: 1080
  },

  /**
   * Indicates whether current window size qualifies as small screen - phone, tablet, old laptop
   * @type {Boolean}
   */
  isSmallScreen: false,

  /**
   * Indicates whether current window size qualifies as wide screen - anything at FullHD or above
   * @type {Boolean}
   */
  isWideScreen: false,

  /**
   * Indicates whether current window size is within mobile phone view range
   * @type {Boolean}
   */
  isMobilePhone: false,

  /**
   * Indicates whether current window size is within HD view range
   * @type {Boolean}
   */
  atLeastHD: false,

  /**
   * Indicates whether current window size is within HD+ view range
   * @type {Boolean}
   */
  atLeastHDPlus: false,

  /**
   * Indicates whether current window size is within Full HD view range
   * @type {Boolean}
   */
  atLeastFullHd: false,

  /**
   * Indicates whether current window size is within desktop view range
   * @type {Boolean}
   */
  isNormalScreen () {
    return !(this.isSmallScreen || this.isMobilePhone)
  },

  /**
   * Returns document body element
   */
  body () {
    return (window.document || {}).body
  },

  /**
   * Returns true if current window size is equal or above the specified one
   */
  isWidthAtLeast (value) {
    return this.body()?.offsetWidth >= value
  },

  /**
   * Returns true if current window size is equal or below the specified one
   */
  isWidthAtMost (value) {
    return this.body()?.offsetWidth <= value
  },

  /**
   * Returns true if current window size is below the specified one
   */
  isWidthBelow (value) {
    return this.body()?.offsetWidth < value
  },

  /**
   * Returns true if current window size is above the specified one
   */
  isWidthAbove (value) {
    return this.body()?.offsetWidth > value
  }
})

// Checks the current screen size
function checkScreenSize () {
  const isMobilePhone = Viewport.isWidthAtMost(Viewport.XGA.width)
  const isSmallScreen = Viewport.isWidthAtMost(Viewport.HD.width - 1)
  const isWideScreen = Viewport.isWidthAtLeast(Viewport.FullHD.width)

  const atLeastHD = Viewport.isWidthAtLeast(Viewport.HD.width)
  const atLeastHDPlus = Viewport.isWidthAtLeast(Viewport.HDPlus.width)
  const atLeastFullHd = Viewport.isWidthAtLeast(Viewport.FullHD.width)

  if (Viewport.isSmallScreen !== isSmallScreen) {
    Viewport.isSmallScreen = isSmallScreen
  }

  if (Viewport.isWideScreen !== isWideScreen) {
    Viewport.isWideScreen = isWideScreen
  }

  if (Viewport.isMobilePhone !== isMobilePhone) {
    Viewport.isMobilePhone = isMobilePhone
  }

  if (Viewport.atLeastHD !== atLeastHD) {
    Viewport.atLeastHD = atLeastHD
  }

  if (Viewport.atLeastHDPlus !== atLeastHDPlus) {
    Viewport.atLeastHDPlus = atLeastHDPlus
  }

  if (Viewport.atLeastFullHd !== atLeastFullHd) {
    Viewport.atLeastFullHd = atLeastFullHd
  }
}

window.addEventListener('load', () => {
  checkScreenSize()
})

window.addEventListener('resize', () => {
  checkScreenSize()
  const isSmallScreen = Viewport.isWidthAtMost(Viewport.HD.width - 1)
  const isMobilePhone = Viewport.isWidthAtMost(Viewport.XGA.width)
  const atLeastHD = Viewport.isWidthAbove(Viewport.HD.width - 1) && Viewport.isWidthBelow(Viewport.FullHD.width)
  const atLeastFullHd = Viewport.isWidthAbove(Viewport.FullHD.width - 1)

  if (Viewport.isSmallScreen !== isSmallScreen) {
    Viewport.isSmallScreen = isSmallScreen
  }

  if (Viewport.isMobilePhone !== isMobilePhone) {
    Viewport.isMobilePhone = isMobilePhone
  }

  if (Viewport.atLeastHD !== atLeastHD) {
    Viewport.atLeastHD = atLeastHD
  }

  if (Viewport.atLeastFullHd !== atLeastFullHd) {
    Viewport.atLeastFullHd = atLeastFullHd
  }


})
