import { PlanAction, PlanActions } from './plan-action'

/**
 * Brings the item to the top of the stack
 */
export class BringToTopAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.BringToTop
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Bring to top'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'arrow_upward'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  execute ({ renderer, items } = {}) {
    if (renderer && items) {
      const itemsToProcess = items.filter(i => i.canReorder)
      for (const item of itemsToProcess) {
        renderer.moveToTop(item)
      }
    }
  }
}

/**
 * Brings the item to the bottom of the stack
 */
export class BringToBottomAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.BringToBottom
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Bring to bottom'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'arrow_downward'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  execute ({ renderer, items } = {}) {
    if (renderer && items) {
      const itemsToProcess = items.filter(i => i.canReorder)
      for (const item of itemsToProcess) {
        renderer.moveToBottom(item)
      }
    }
  }
}
