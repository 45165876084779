import { Point } from '@stellacontrol/utilities'
import { Assignable } from '../common/assignable'

/**
 * Plan settings
 */
export class PlanSettings extends Assignable {
  constructor (data = {}) {
    super()
    this.reset()
    this.assign(data)
  }

  normalize () {
    super.normalize()

    if (!this.floor) {
      this.floor = {}
    }

    for (const [key, value] of Object.entries(this.floor || {})) {
      this.floor[key] = this.cast(value, PlanFloorSettings)
    }
  }

  /**
   * Indicates whether the palette is expanded
   * @type {Boolean}
   */
  paletteExpanded

  /**
   * Palette position
   * @type {Rectangle}
   */
  palettePosition

  /**
   * Individual floor settings, where key is floor identifier
   * @type {<Dictionary, PlanFloorSettings>}
   */
  floor

  /**
   * Resets the settings to defaults
   */
  reset () {
    this.paletteExpanded = true
    this.palettePosition = null

    for (const floor of Object.values(this.floor || {})) {
      floor.reset()
    }
  }

  /**
   * Returns settings of the specified floor
   * @param {String|PlanFloor} floor Plan floor, can also be specified as floor identifier
   * @returns Floor settings
   */
  getFloor (floor) {
    const id = floor.id || floor
    if (id) {
      if (!this.floor[id]) {
        this.floor[id] = new PlanFloorSettings()
      }
      return this.floor[id]
    }
  }
}

/**
 * Plan floor settings
 */
export class PlanFloorSettings extends Assignable {
  constructor (data = {}) {
    super()
    this.reset()
    this.assign(data)
  }

  /**
   * Plan scroll
   * @type {Point}
   */
  scroll

  /**
   * Hidden layers
   * @type {Dictionary<string, Boolean>}
   */
  hiddenLayers

  /**
   * Sections expanded in the plan palette
   * @type {Dictionary<string, Boolean>}
   */
  expandedSections

  /**
   * Resets the settings to defaults
   */
  reset () {
    this.scroll = Point.Zero
    this.hiddenLayers = {}
    this.expandedSections = {
      radiation: false,
      background: false,
      items: false,
      toolbox: true,
      properties: false,
      snapshots: false
    }
  }
}
