import { Confirmation } from '@stellacontrol/client-utilities'
import { PlanAction, PlanActions } from './plan-action'

/**
 * Creates a named snapshot of the current plan
 */
export class SavePlanSnapshotAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SavePlanSnapshot
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Create'
  }

  /**
   * Action tooltip
   * @type {String}
   */
  get tooltip () {
    return 'Create a snapshot of the plan'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'add_circle'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return false
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer } = {}) {
    if (renderer) {
      await renderer.saveSnapshot()
      renderer.changed({ action: this })
    }
  }
}

/**
 * Deletes a snapshot of a plan
 */
export class DeletePlanSnapshotAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.DeletePlanSnapshot
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Delete'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'delete'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return false
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Plan} snapshot Snapshot to remove
   */
  async execute ({ renderer, snapshot } = {}) {
    if (renderer && snapshot) {
      const yes = await Confirmation.ask(({
        title: 'Confirmation',
        message: `Delete the plan snapshot ${snapshot.name}?`
      }))

      if (yes) {
        renderer.deleteSnapshot({ snapshot })
        renderer.changed({ action: this })
      }
    }
  }
}

/**
 * Restores a snapshot as the current plan
 */
export class RestorePlanSnapshotAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.RestorePlanSnapshot
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Restore'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'history'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return false
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Plan} snapshot Snapshot to remove
   */
  async execute ({ renderer, snapshot } = {}) {
    if (renderer && snapshot) {
      const message = snapshot.isRecentSnapshot
        ? 'Restore the recent plan snapshot?'
        : `Restore the plan snapshot ${snapshot.name}? <br><br> To protect your work, the current plan will be stored as RECENT snapshot.`
      const yes = await Confirmation.ask(({ title: 'Confirmation', html: true, message }))
      if (yes) {
        renderer.restoreSnapshot({ snapshot })
        renderer.changed({ action: this })
      }
    }
  }
}

