import { format } from 'date-fns'

/**
 * Regular expression for detecting presence of ISO datetime
 * @type {String}
 */
export const ISODateTimeRegex = /\b(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?(?:Z|[+-]\d{2}:\d{2})?\b/

/**
 * Global date formats.
 * Auto-detected on application bootstrap, can be overridden with {@link setDateFormat}.
 * @type {Object}
 */
export const DateFormats = {
  /**
   * Current OS locale
   * @type {String}
   */
  locale: null,
  /**
   * Date-only format
   * @type {String}
   */
  date: null,
  /**
   * Date/time format
   * @type {String}
   */
  dateTime: null
}

/**
 * Checks whether the specified value is a valid `Date` object
 * with correct date/time value in it
 * @param {any} value
 * @returns {Boolean}
 */
export function isDate (value) {
  return (value && value instanceof Date && !isNaN(value.getTime()))
}

/**
 * Detects the default date formats
 * @returns {String}
 */
export function resetDateFormat () {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  const date = new Intl.DateTimeFormat(locale).format(new Date(2345, 11, 31))
  DateFormats.locale = locale
  DateFormats.date = date
    .replace('12', 'MM')
    .replace('2345', 'yyyy')
    .replace('31', 'dd')
  DateFormats.dateTime = `${DateFormats.date} HH:mm:ss`
}

/**
 * Enforces the specified date formats
 * @returns {String}
 */
export function setDateFormat ({ locale, date, dateTime }) {
  DateFormats.locale = locale || DateFormats.locale
  DateFormats.date = date || DateFormats.date
  DateFormats.dateTime = dateTime || DateFormats.dateTime
}

/**
 * Returns the date string of current or specified date
 * @param {Date} date Optional date
 * @returns {String} Date string
 */
export function getDateString (date) {
  return format(date || new Date(), DateFormats.date)
}

/**
 * Returns the date and time string of current or specified date
 * @param {Date} date Optional date
 * @returns {String} Date and time string
 */
export function getDateTimeString (date) {
  return format(date || new Date(), DateFormats.dateTime)
}

/**
 * Parses a date value, if string
 * @param {Date} defaultValue Default value to return, if date cannot be parsed
 * @returns {Date}
 */
export function parseDate (value, defaultValue) {
  if (value) {
    if (value instanceof Date) {
      return value
    } else if (typeof value === 'number') {
      return new Date(value)
    } else {
      try {
        const date = new Date(Date.parse(value.toString()))
        return isDate(date) ? date : defaultValue
      } catch {
        return defaultValue
      }
    }
  }
}

/**
 * Parses UTC date string to UTC date
 * @param defaultValue Default value to return, if date cannot be parsed
 */
export function parseUTCDate (value, defaultValue) {
  if (value) {
    if (value instanceof Date) {
      return value
    } else if (typeof value === 'number') {
      return new Date(value)
    } else {
      try {
        const s = value.toString()
        const hasTimeZone = (s.endsWith('Z') || s.includes('+') || s.includes('-'))
        const date = hasTimeZone
          ? new Date(Date.parse(s))
          : new Date(s + 'Z')
        return isDate(date) ? date : defaultValue
      } catch {
        return defaultValue
      }
    }
  }
}

/**
 * Formats date/time into locale format or the specified format
 * @param {Date|String|Number} value Date, specified as date object, date string or timestamp
 * @param {String} customFormat Optional custom date/time format
 * @returns {String} Date and time formatted as string
 */
export function formatDateTime (value, customFormat) {
  if (value) {
    try {
      const date = value instanceof Date || typeof value === 'number'
        ? new Date(value)
        : Date.parse(value.toString())
      if (isDate(date)) {
        return customFormat
          ? format(date, customFormat)
          : format(date, DateFormats.dateTime)
      } else {
        return value.toString()
      }
    } catch {
      return value.toString()
    }
  }
}

/**
 * Formats a date value to string
 * @param {Date|String|Number} value Date, specified as date object, date string or timestamp
 * @param {String} customFormat Optional custom date format
 * @returns {String} Date as string (only date part)
 */
export function formatDate (value, customFormat) {
  if (value) {
    try {
      const date = value instanceof Date || typeof value === 'number'
        ? new Date(value)
        : Date.parse(value.toString())
      if (isDate(date)) {
        return customFormat
          ? format(date, customFormat)
          : format(date, DateFormats.date)
      } else {
        return value.toString()
      }
    } catch {
      return value.toString()
    }
  }
}

/**
 * Verifies whether the two specified dates are the same
 * @param {Date} value1 First date, specified either as Date or ISO date string
 * @param {Date} value2 Second date, specified either as Date or ISO date string
 * @returns {Boolean}
 */
export function sameDate (value1, value2) {
  if (value1 && value2) {
    const s1 = isDate(value1) ? value1.toISOString() : value1.toString()
    const s2 = isDate(value2) ? value2.toISOString() : value2.toString()
    return s1 === s2
  }
}

/**
 * Returns current or specified date parts
 * @param {Date} date Date to get the parts
 * @returns {Object} Dictionary of date parts:
 *   year: full year
 *   month: month in the year, starting with 1
 *   day: day of the month
 *   dayOfWeek: day of the week, Monday is 1
 *   hours: hour part of the time
 *   minutes: minutes part of the time
 *   seconds: seconds part of the time
 *   milliseconds: milliseconds part of the time
 *   iso: date/time ISO string
 */
export function getDateParts (date = new Date()) {
  if (date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const dayOfWeek = date.getDay() === 0 ? 7 : date.getDay()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const milliseconds = date.getMilliseconds()
    const iso = date.toISOString()

    return {
      year,
      month,
      day,
      dayOfWeek,
      hours,
      minutes,
      seconds,
      milliseconds,
      iso
    }
  }
}

/**
 * Converts the specified UTC time to local datetime
 * @param {String} country Country code or country-culture code, i.e. `de`, `be-nl`
 * @param {String} timeZone Timezone name, i.e. `Europe/Dublin`,
 * must be listed in https://www.iana.org/time-zones (human-readable list at https://timezonedb.com/time-zones)
 * @param {Date} time Time to convert
 * @returns {Number} Local time in milliseconds
 */
export function getLocalDateTime (country, timeZone, time) {
  time = time == null ? getUTCDateTime() : time
  if (country && timeZone) {
    const utcParts = getDateParts(new Date(time))
    const utc = Date.UTC(utcParts.year, utcParts.month - 1, utcParts.day, utcParts.hours, utcParts.minutes, utcParts.seconds)
    const formatter = new Intl.DateTimeFormat(country, {
      timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      millisecond: 'numeric',
      fractionalSecondDigits: 3,
      hour12: false
    })
    const localParts = formatter.formatToParts(utc)
    const year = parseInt(localParts.find(part => part.type === 'year').value)
    const month = parseInt(localParts.find(part => part.type === 'month').value)
    const day = parseInt(localParts.find(part => part.type === 'day').value)
    const hours = parseInt(localParts.find(part => part.type === 'hour').value)
    const minutes = parseInt(localParts.find(part => part.type === 'minute').value)
    const seconds = parseInt(localParts.find(part => part.type === 'second').value)
    return new Date(year, month - 1, day, hours, minutes, seconds).getTime()
  } else {
    return time.getTime()
  }
}

/**
 * Converts the specified local date/time to UTC date/time
 * @param {Date} time Time to convert
 * @returns {Date} UTC date/time
 */
export function getUTCDateTime (time = new Date()) {
  if (time != null) {
    const utc = new Date(time)
      .toISOString()
      .replace('Z', '')
    return new Date(utc)
  }
}

/**
 * Checks whether the specified value contains an ISO date/time
 * @param {String} value Value to check
 * @returns {Boolean}
 */
export function hasISODateTimeString (value) {
  if (value) {
    return ISODateTimeRegex.test(value.toString())
  }
  return false
}

/**
 * Extracts and parses ISO date/time from the specified string
 * @param {String} value Value to process
 * @returns {Date}
 */
export function extractISODateTimeString (value) {
  if (value) {
    const match = value.toString().match(ISODateTimeRegex)
    if (match) {
      const date = match[0]
      if (date) {
        return new Date(date)
      }
    }
  }
}

// Auto-detect date formats
resetDateFormat()
