<script>
import { mapGetters, mapActions } from 'vuex'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  computed: {
    ...mapGetters([
      'company'
    ])
  },

  methods: {
    ...mapActions([
      'clearDevices'
    ]),

    async clear () {
      if (await this.validate()) {
        const { devices } = this
        await this.clearDevices({ devices })
        this.executed()
      }
    }
  }
}
</script>

<template>
  <sc-device-action
    :action="action"
    :devices="devices"
    execute-label="Clear device data"
    @closing="closing"
    @close="close"
    @execute="clear">

    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent>

      <div>
        <label class="text-body2 text-grey-9">
          This will make {{ devicesLabel }} unavailable to current owner,
          distributor and other organizations which currently can access {{ devicesLabel }}.
          The history of the device will be cleared as well.
        </label>
      </div>

      <div class="q-mt-sm">
        <label class="text-body2 text-grey-9">
        </label>
      </div>

      <div class="q-mt-sm">
        <label class="text-subtitle1 text-red-10">
          Also, remember to do a factory reset on the LCD of the device.
        </label>
      </div>

    </q-form>

  </sc-device-action>

</template>

<style lang="scss" scoped>
</style>
