<script>
import { mapActions } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { FormMixin, DialogMixin } from '@stellacontrol/client-utilities'

const dialog = 'file-print-dialog'

export default {
  mixins: [
    FormMixin,
    DialogMixin
  ],

  data () {
    return {
      dialog,
      files: [],
      options: {
        // Scan print options
        ttscan: {
          // Print the Summary section
          summary: true,
          // Print the Live Scan section
          neighbours: true,
          // Print Spectrum section
          spectrum: true,
          // Print Survey section
          survey: true,
          // Print Surplus Power section
          surplusPower: true,
          // Hide unboosted bands
          skipUnboostedBands: true,
          // Print in BW
          blackAndWhite: true
        }
      }
    }
  },

  computed: {
    // Indicates that the files to print are all network scans
    ttScans () {
      return this.files.every(f => f.isScan)
    },

    // Devices associated with the scans to print
    devices () {
      return this.files
        .filter(f => f.isScan)
        .map(f => f.entity)
        .filter(d => d)
    },

    // Checks whether the devices associated with the scans are all Test Tool.
    // This impacts availability of certain options in the print dialog
    onlyTestTools () {
      return this.files.every(f => f.entity?.isTestTool)
    },

    // Checks whether the devices associated with the scans are all repeaters.
    // This impacts availability of certain options in the print dialog
    onlyRepeaters () {
      return this.files.every(f => f.entity?.isConnectedDevice && !f.entity?.isTestTool)
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'getUserPreference'
    ]),

    // Called when dialog is shown
    async dialogShown () {
      // Pass on initial data
      const { data: { files }, ttScans, onlyTestTools, options } = this
      this.files = files
      if (files.length === 1) {
        this.title = `Print ${files[0].isScan ? 'Scan Report ' : ''}${files[0].name}`
      } else {
        this.title = `Print ${countString(files, 'file')}`
      }

      if (ttScans) {
        // Follow user's selection of the unboosted bands on the preview
        options.ttscan.skipUnboostedBands = await this.getUserPreference({
          name: 'ttscan-hide-unboosted-bands',
          defaultValue: false
        })

        if (!onlyTestTools) {
          // Some report sections are applicable only for Test Tool scans
          options.ttscan.neighbours = false
          options.ttscan.spectrum = false
          options.ttscan.survey = false
        }
      }
    },

    // Validates and OKs the dialog
    ok () {
      const { files, options } = this
      this.dialogOk({ dialog, data: { files, options } })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form class="form" ref="form">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-lg">
        <div v-if="ttScans" class="column">
          <span class="text-bold q-mb-sm">
            Print Sections
          </span>
          <q-checkbox label="Print Summary" v-model="options.ttscan.summary">
          </q-checkbox>
          <q-checkbox v-if="onlyTestTools" label="Print Live Scan"
            v-model="options.ttscan.neighbours">
          </q-checkbox>
          <q-checkbox v-if="onlyTestTools" label="Print Spectrum Scan"
            v-model="options.ttscan.spectrum">
          </q-checkbox>
          <q-checkbox v-if="onlyTestTools" label="Print Surplus Power"
            v-model="options.ttscan.surplusPower">
          </q-checkbox>
          <q-checkbox label="Print Cell ID Scan" v-model="options.ttscan.survey">
          </q-checkbox>

          <span class="text-bold q-mb-sm q-mt-md">
            Print Options
          </span>
          <q-checkbox v-if="onlyRepeaters" label="Hide Unboosted Bands"
            v-model="options.ttscan.skipUnboostedBands">
          </q-checkbox>
          <q-checkbox label="Black & White"
            v-model="options.ttscan.blackAndWhite">
          </q-checkbox>
        </div>

        <div class="row q-mt-xl">
          <q-space></q-space>
          <q-btn label="Cancel" unelevated class="q-mr-md" @click="cancel()"></q-btn>
          <q-btn label="Print" unelevated class="primary" @click="ok()"></q-btn>
        </div>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 16px;
}

.form {
  min-width: 500px;
}
</style>