<script>
import { getScanSpectrumChart } from '@stellacontrol/devices'

/**
 * Spectrum Scan
 */
export default {
  props: {
    // Section collapse status, dictionary
    collapsed: {
      type: Object,
      default: () => { }
    },
    // Operators
    operators: {
      default: () => { }
    },
    // Spectrum results
    items: {
      default: () => []
    }
  },

  data () {
    return {
      // Chart data per item
      chartData: {}
    }
  },

  computed: {
    // Scan results which actually have some data
    visibleItems () {
      return this.items.filter(item => item?.spectrumPoints?.length > 0)
    },

    // Spectrum item name
    itemName () {
      return index => `spectrum-${index}`
    },

    // Spectrum item title
    itemTitle () {
      return item => {
        const bandLabel = `B${item.bandNumber}`
        return [
          'Band',
          bandLabel,
          item.wasUplink ? 'UL' : 'DL',
          item.samplingMode === 0 ? 'Peak,' : 'Inst,',
          item.timeTakenMs,
          'ms'
        ].join(' ')
      }
    },

    // Spectrum frequency range
    frequencyRange () {
      return item => {
        return [
          item.startKHz / 1000,
          '-',
          item.endKHz / 1000,
          'MHz'
        ].join(' ')
      }
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    // Toggles a section
    toggle ({ section }) {
      this.$emit('toggle', { section })
    },

    // Loads chart data and populates the chart
    populate () {
      let index = 0
      for (const item of this.visibleItems) {
        this.chartData[index++] = getScanSpectrumChart(item, this.operators)
      }
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <div class="spectrum">
    <div class="item" v-for="(item, index) in visibleItems" :key="item.timeOffsetMs">
      <div class="title row items-center">
        <span>
          {{ itemTitle(item) }}
        </span>
      </div>
      <div class="content">
        <sc-line-chart v-if="chartData[index]" :labels="chartData[index].labels" :zoom="false"
          :series="chartData[index].series" :annotations="chartData[index].annotations"
          :options="chartData[index].options" :data-labels="false">
        </sc-line-chart>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spectrum {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  overflow: hidden;

  .item {
    flex-basis: calc(50% - 4px);
    height: 450px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: solid #0000001f 1px;

    .title {
      background-color: #f0f0f0;
      padding: 8px 8px 8px 16px;
    }

    .content {
      flex: 1;
      overflow: hidden;
    }

    &:hover {
      border: solid #0000005c 1px;

      .title {
        background-color: #e0e0e0;
      }
    }
  }
}
</style>
