<script>
import { mapActions } from 'vuex'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  computed: {
    // Status of the update of the viewed device
    updateStatus () {
      const { updateStatus } = this.device || {}
      if (updateStatus) {
        const { firmwareVersion, updatedAt, progress, inProgress, isScheduled } = updateStatus
        return {
          inProgress,
          isScheduled,
          version: firmwareVersion,
          progress: progress,
          updatedAt
        }
      } else {
        return {}
      }
    },

    // Icon representing the current state of FW update
    icon () {
      const { updateStatus } = this

      return updateStatus.inProgress
        ? 'motion_photos_on'
        : (updateStatus.isScheduled
          ? 'update'
          : 'memory')
    },

    iconClass () {
      return {
        'rotate': this.updateStatus?.inProgress
      }
    },

    iconColor () {
      const { updateStatus } = this

      return updateStatus.inProgress
        ? 'orange-8'
        : (updateStatus.isScheduled
          ? 'green-8'
          : 'indigo-6')
    },

    // Widget class, indicating eventual updates and their progress
    widgetClass () {
      const { updateStatus } = this

      return {
        'updating': updateStatus.inProgress,
        'update-scheduled': updateStatus.isScheduled
      }
    },

    // Label describing the current FW and eventual updates
    widgetLabel () {
      const { device, updateStatus } = this
      if (updateStatus.inProgress) {
        return `Updating ${device.firmwareVersionLong} to ${updateStatus.version}, ${updateStatus.progress}%`
      }
      if (updateStatus.isScheduled) {
        return `FW ${device.firmwareVersionLong}, update to ${updateStatus.version} scheduled`
      }
      return `FW ${device.firmwareVersionLong}`
    }
  },

  methods: {
    ...mapActions([
      'watchUploadStatus',
      'unwatchUploadStatus'
    ]),

    // Starts polling for firmware update progress,
    // if pending update
    startPolling () {
      const { updateStatus } = this
      if (updateStatus.inProgress) {
        this.watchUploadStatus({
          name: 'device-dashboard',
          hasUploadsInProgress: () => {
            if (updateStatus.inProgress) {
              return true
            } else {
              this.stopPolling()
              return false
            }
          }
        })
      }
    },

    // Stops polling for firmware update progress
    stopPolling () {
      this.unwatchUploadStatus({ name: 'device-dashboard' })
    }
  },

  created () {
    this.startPolling()
  },

  beforeUnmount () {
    this.stopPolling()
  }
}
</script>

<template>
  <div class="widget mini-widget widget-device-firmware" :class="widgetClass">
    <header class="row items-center no-wrap">
      <q-icon size="32px" :name="icon" :color="iconColor" :class="iconClass">
      </q-icon>
      <span class="q-ml-sm">
        {{ widgetLabel }}
    </span>
    </header>
  </div>
</template>

<style lang="scss" scoped>
.widget-device-firmware {
  &.latest-firmware {
    background-color: #e7ffdd;
  }

  &.old-firmware {
    background-color: #ffe6e4;
  }

  &.update-scheduled {
    background-color: #fff9dd;
  }

  &.updating {
    background-color: #fff9dd
  }
}
</style>
