import { PlanLayers } from '@stellacontrol/planner'
import { PlanActions, executePlanAction } from '../actions'
import { PlanEvent } from './plan-event'

/**
 * Utility class for handling keyboard events on the plan
 */
export class KeyboardEvents {
  /**
   * Initializes the keyboard events service
   * @param {PlanRenderer} renderer
   */
  constructor (renderer) {
    this.__renderer = renderer
    this.__enabled = true
  }

  __keyDownEventHandler
  __keyUpEventHandler
  __renderer

  /**
   * Binds keyboard event handlers
   * @param {PlanRenderer} renderer Plan renderer
   */
  bindEvents () {
    this.__keyDownEventHandler = async (e) => await this.keyPressed(e)
    this.__keyUpEventHandler = async (e) => await this.keyReleased(e)
    window.addEventListener('keydown', this.__keyDownEventHandler)
    window.addEventListener('keyup', this.__keyUpEventHandler)
  }

  /**
   * Key down event handler
   * @param {KeyboardEvent} e Event details
   */
  async keyPressed (e) {
    // Ignore keystrokes if renderer is not in focus
    const { __renderer: renderer } = this
    if (!renderer.isFocused) {
      return
    }

    // Global keyboard events
    switch (e.key) {
      // Select all items
      case 'a':
        if (PlanEvent.isCtrlKey(e)) {
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: PlanActions.SelectAllItems,
            layer: PlanLayers.Items
          })
        }
        break

      // Pastes the copied items
      case 'v':
        if (PlanEvent.isCtrlKey(e)) {
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: PlanActions.PasteItems,
            position: renderer.currentPosition || renderer.selectedPosition
          })
        }
        break
    }

    // Events which require some items to be selected
    const isCtrl = PlanEvent.isCtrlKey(e)
    const isShift = PlanEvent.isShiftKey(e)
    const delta = isCtrl ? renderer.layout.gridSize : 1
    const { selectedItems: items, isEditing } = renderer

    // If adding items, drawing lines, polygons, connecting elements etc.
    // ENTER should finish and ESC should cancel
    switch (e.key) {
      case 'Escape':
        renderer.deselect()
        if (isEditing) {
          PlanEvent.cancelEvent(e)
          await renderer.cancelEditing()
        }
        break

      case 'Enter':
        // Finish adding items
        if (isEditing) {
          PlanEvent.cancelEvent(e)
          await renderer.finishEditing()
        }
        break
    }

    // Undo/Redo
    if (isCtrl) {
      switch (e.key) {
        case 'z':
          await renderer.undo()
          PlanEvent.cancelEvent(e)
          break
        case 'y':
          await renderer.redo()
          PlanEvent.cancelEvent(e)
          break
      }
    }

    // Actions on selected items
    if (items.length > 0) {
      switch (e.key) {
        // Duplicates the selected items
        case 'd':
          if (PlanEvent.isCtrlKey(e)) {
            PlanEvent.cancelEvent(e)
            await executePlanAction({
              renderer,
              action: PlanActions.DuplicateItems,
              items
            })
          }
          break

        // Copies the selected items to clipboard
        case 'c':
          if (PlanEvent.isCtrlKey(e)) {
            PlanEvent.cancelEvent(e)
            await executePlanAction({
              renderer,
              action: PlanActions.CopyItems,
              items
            })
          }
          break

        // Rotates the selected items
        case 'r':
          if (PlanEvent.isCtrlKey(e)) {
            PlanEvent.cancelEvent(e)
            await executePlanAction({
              renderer,
              action: PlanActions.RotateItems,
              items,
              angle: 45
            })
          }
          break

        // Delete the currently selected items
        case 'Delete':
          PlanEvent.cancelEvent(e)
          await executePlanAction({ renderer, action: PlanActions.RemoveItems, items })
          break

        // Move/Resize selected items
        case 'ArrowLeft':
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: isShift ? PlanActions.ResizeItems : PlanActions.MoveItems,
            items,
            x: -delta,
            snapToGrid: isCtrl
          })
          break

        case 'ArrowRight':
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: isShift ? PlanActions.ResizeItems : PlanActions.MoveItems,
            items,
            x: delta,
            snapToGrid: isCtrl
          })
          break

        case 'ArrowUp':
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: isShift ? PlanActions.ResizeItems : PlanActions.MoveItems,
            items,
            y: -delta,
            snapToGrid: isCtrl
          })
          break

        case 'ArrowDown':
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: isShift ? PlanActions.ResizeItems : PlanActions.MoveItems,
            items,
            y: delta,
            snapToGrid: isCtrl
          })
          break
      }
    }
  }

  /**
   * Key up event handler
   * @param {KeyboardEvent} e Event details
   */
  async keyReleased (e) {
    // Ignore keystrokes if renderer is not in focus
    const { __renderer: renderer } = this
    if (!renderer.isFocused) {
      return
    }

    // If renderer in multi-add mode, which is indicated by CTRL key pressed on its own,
    // finish adding items now
    const isCtrlPressedAlone = PlanEvent.isCtrlKey(e) && e.key === 'Control'
    if (isCtrlPressedAlone && renderer.isAddingItem) {
      renderer.stopAddingItem()
    }
  }

  /**
   * Unbinds any global event handlers.
   * Should be called before the class is destroyed
   */
  unmount () {
    window.removeEventListener('keydown', this.__keyDownEventHandler)
    window.removeEventListener('keyup', this.__keyUpEventHandler)
    this.__keyDownEventHandler = null
    this.__keyUpEventHandler = null
    this.__renderer = null
  }
}
