<script>
import { mapActions } from 'vuex'
import { FormMixin, DialogMixin } from '@stellacontrol/client-utilities'
import { Place, getPlaceLabel } from '@stellacontrol/model'

const dialog = 'clone-building'

export default {
  mixins: [
    FormMixin,
    DialogMixin
  ],

  data () {
    return {
      dialog,
      building: null,
      clonePlan: true,
      name: ''
    }
  },

  computed: {
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      const { data: { building } } = this
      this.building = new Place({
        ...building,
        name: ''
      })
      this.title = `Clone ${getPlaceLabel(building.placeType)} ${building.name}`
    },

    // Validates and OKs the dialog
    async ok () {
      const { building, clonePlan } = this
      const { placeEditor } = this.$refs
      if (await placeEditor.isPlaceValid()) {
        this.dialogOk({ dialog, data: { building, clonePlan } })
      }
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form class="form" ref="form">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-lg">
        <sc-place-editor ref="placeEditor" :place="building" :details="false" :is-new-place="true"
          class="q-mb-lg q-mt-md">
        </sc-place-editor>

        <q-field dense borderless>
          <template v-slot:before>
            <label>Also clone</label>
          </template>
          <div class="row items-center">
            <q-checkbox label="Floor plans" v-model="clonePlan"></q-checkbox>
          </div>
        </q-field>

        <div class="row q-mt-xl">
          <q-space></q-space>
          <q-btn label="Cancel" unelevated class="q-mr-md" @click="cancel()" />
          <q-btn label="Clone" unelevated class="primary" @click="ok()" />
        </div>
      </div>

    </q-form>
  </sc-dialog>

</template>

<style scoped lang="scss">
.title {
  font-size: 16px;
}

.form {
  min-width: 500px;
}

label {
  font-size: 14px;
  min-width: 160px;
}
</style>