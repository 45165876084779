<script>
// Wrapper component allowing to render any icon inside a circle
export default {
  props: {
    icon: {
      type: String,
      required: true
    },

    size: {
      type: String,
      default: '36px'
    },

    borderWidth: {
      type: String,
      default: '2px'
    },

    color: {
      type: String,
      default: 'white'
    },

    bgColor: {
      type: String,
      default: 'transparent'
    },

    disable: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  methods: {
    activate () {
      if (!this.disable) {
        this.isActive = true
      }
    },

    deactivate () {
      if (!this.disable) {
        this.isActive = false
      }
    },
  }
}
</script>

<template>
  <q-btn flat dense round v-bind="{ ...$props, ...$attrs }" :size="undefined" :color="undefined"
    :icon="undefined" @mouse-enter="activate()" @mouse-exit="deactivate()" @touch-start="activate()"
    @touch-end="deactivate()" @touch-cancel="deactivate">
    <sc-round-icon :name="icon" :size="size" :border-width="borderWidth" :color="color"
      :bg-color="bgColor" :interactive="!disable" :active="isActive">
    </sc-round-icon>
    <slot></slot>
  </q-btn>
</template>

<style scoped lang="scss"></style>