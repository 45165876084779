import Konva from 'konva'
import { PlanItemType } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Circle
 */
export class CircleShape extends Shape {
  constructor (item, data) {
    super(item, data)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Circle
  }

  /**
   * Circle shape
   * @type {Konva.Ring}
   */
  circle

  createShapes () {
    super.createShapes()
    this.circle = new Konva.Ring()
    this.content.add(this.circle)
  }

  render (renderer) {
    super.render(renderer)
    const { circle, item } = this
    if (circle && item) {
      const { radius, innerRadius, lineStyle, backgroundStyle } = item
      circle.innerRadius(innerRadius || 0)
      circle.outerRadius(radius)
      circle.fill(backgroundStyle.color)
      circle.stroke(lineStyle.color)
      circle.strokeWidth(lineStyle.width)
      circle.dash(lineStyle.dash)
      this.applyFilters()
    }
  }

  /**
   * Triggered when shape is being transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Shape position after the transformation
   * @param {PlanScale} scale Scale of the shape after the transformation
   * @param {Number} rotation Shape rotation after the transformation
   * @returns {Boolean}
   */
  // eslint-disable-next-line no-unused-vars
  async transforming ({ renderer, position, scale, rotation }) {
    return false
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Shape position after the transformation
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   * @returns {Boolean}
   */
  async transformed ({ renderer, position, scale, rotation }) {
    super.transformed({ renderer, position, scale, rotation })
    const { item } = this

    // Scale the radius
    if (scale) {
      item.radius = Math.round(item.radius * scale.x)
      if (item.innerRadius > 0) {
        item.innerRadius = Math.round(item.innerRadius * scale.x)
      }
    }

    // Adjust the position
    if (position) {
      item.setCoordinates(position, renderer.isCrossSection)
    }

    // Re-render the shape
    await this.render(renderer)

    return true
  }
}
