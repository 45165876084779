import { differenceInSeconds } from 'date-fns'
import { isEnum, parseDate, sortItems } from '@stellacontrol/utilities'
import { Principal, PrincipalType } from '../security/principal'
import { Tag } from '../common/tag'
import { Note } from '../common/note'
import { Permission } from '../security/permission'
import { OrganizationLevel, OrganizationLevelWeight, OrganizationIcons } from './organization-level'
import { OrganizationSite } from './organization-site'
import { UserLevel, UserLevelWeight } from './user-level'
import { Wallet } from '../service-management/wallet'

/**
 * Organization such as distributor, customer, reseller etc.
 * Stella Doradus itself is also an organization, so called
 * super organization
 */
export class OrganizationProfile extends Principal {
  constructor (data = {}) {
    super(data)

    this.isDefault = false
    this.assign({
      level: OrganizationLevel.Organization,
      ...data,
      type: PrincipalType.OrganizationProfile,
      isEnabled: data.isEnabled == null ? true : data.isEnabled
    })

    if (!isEnum(OrganizationLevel, this.level)) throw new Error(`Invalid organization profile level ${this.level}`)

    if (!this.icon) {
      this.icon = OrganizationIcons[this.level]
    }
  }

  /**
   * Identifier of owner organization
   * @type {String}
   */
  ownerId

  /**
   * Owner organization
   * @type {Organization}
   */
  owner

  /**
   * Indicates whether profile is a shared profile, usable for all organizations,
   * as opposed to a profile with {@link owner}, which are private profiles created by resellers
   * @type {Boolean}
   */
  get isShared () {
    return !this.ownerId
  }

  /**
   * Identifier of terms and conditions applicable to this profile
   * @type {String}
   */
  termsAndConditionsId

  /**
   * Custom icon representing this profile in the UI
   * @type {String}
   */
  icon

  /**
   * Full name of the profile, which is description + name
   * @type {String}
   */
  get fullName () {
    const { description, name } = this
    return [description, name]
      .map(s => (s || '').trim())
      .filter(s => s)
      .join(' ')
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    this.organizations = this.castArray(this.organizations, Organization)
    this.parentOrganizations = this.castArray(this.parentOrganizations, Organization)
    this.creator = this.cast(this.creator, User)
    this.updater = this.cast(this.updater, User)
    this.owner = this.cast(this.owner, Organization)
  }

  /**
   * List of organizations assigned to the profile
   * @type {Array[Organization]}
   */
  organizations

  /**
   * Indicates that profile has organizations assigned to it
   * @type {Boolean}
   */
  get hasOrganizations () {
    return this.organizations && this.organizations.length > 0
  }

  /**
   * Indicates whether profile represents a super organization
   * @type {Boolean}
   */
  get isSuperOrganization () {
    return this.level === OrganizationLevel.SuperOrganization
  }

  /**
   * Indicates whether profile represents a reseller organization which can have child organizations
   * @type {Boolean}
   */
  get isResellerOrganization () {
    return this.level === OrganizationLevel.ResellerOrganization
  }

  /**
   * Indicates whether profile represents a regular organization
   */
  get isRegularOrganization () {
    return this.level === OrganizationLevel.Organization
  }

  /**
   * Indicates whether profile represents a guest organization with very limited functionality
   * @type {Boolean}
   */
  get isGuestOrganization () {
    return this.level === OrganizationLevel.GuestOrganization
  }

  /**
   * Indicates whether profile represents a shipping company with no access to system or devices,
   * present only to capture the realities of shipping devices between organizations
   * @type {Boolean}
   */
  get isShippingCompany () {
    return this.level === OrganizationLevel.ShipppingCompany
  }

  /**
   * Returns true if organization profile is the same as the specified one
   * @param {OrganizationProfile} organizationProfile Organization profile to compare with
   * @returns {Boolean}
   */
  sameAs (organizationProfile) {
    const { id } = this
    return organizationProfile && id && id === organizationProfile.id
  }

  /**
   * Returns true if organization profile has the same level as the specified one
   * @param {OrganizationProfile} organizationProfile Organization profile to compare with
   * @returns {Boolean}
   */
  sameLevelAs (organizationProfile) {
    const { level } = this
    return organizationProfile && level && level === organizationProfile.level
  }

  /**
   * Returns true if organization profile has level higher than the specified one
   * @param {OrganizationLevel} level Level to compare with
   * @returns {Boolean}
   */
  levelHigherThan (level) {
    return OrganizationLevelWeight[this.level] > OrganizationLevelWeight[level]
  }
}

/**
 * Organization such as distributor, customer, reseller etc.
 * Stella Doradus itself is also an organization, so called
 * super organization
 */
export class Organization extends Principal {
  constructor (data = {}) {
    super(data)
    this.assign(
      {
        ...data,
        type: PrincipalType.Organization,
        preferences: data.preferences || {},
        isLocked: data.isLocked || false,
        isEnabled: data.isEnabled == null ? true : data.isEnabled,
        isPrimary: data.isPrimary == null ? false : data.isPrimary
      },
      {
        isLocked: Boolean
      }
    )
    this.tags = this.tags || []
    this.notes = this.notes || []
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    this.profile = this.cast(this.profile, OrganizationProfile)
    this.parentOrganization = this.cast(this.parentOrganization, Organization)
    this.users = this.castArray(this.users, User)
    this.organizations = this.castArray(this.organizations, Organization)
    this.creator = this.cast(this.creator, User)
    this.updater = this.cast(this.updater, User)
    this.reseller = this.cast(this.reseller, Organization)
    this.wallet = this.cast(this.wallet, Wallet)
    if (this.tags) {
      this.tags = this.castArray(this.tags, Tag)
      for (const tag of this.tags || []) {
        tag.creator = this.cast(tag.creator, User)
        tag.updater = this.cast(tag.creator, User)
      }
    }
    if (this.notes) {
      this.notes = this.castArray(this.notes, Note)
      for (const note of this.notes || []) {
        note.creator = this.cast(note.creator, User)
        note.updater = this.cast(note.creator, User)
      }
    }
  }

  /**
   * Returns core information about the organization
   * @returns {Organization}
   */
  core () {
    const organization = new Organization(this)
    delete organization.preferences
    delete organization.parentOrganizations
    delete organization.users
    delete organization.notes
    delete organization.tags
    delete organization.places
    delete organization.permissions
    delete organization.site
    delete organization.siteDetails
    delete organization.creator
    delete organization.updater
    delete organization.profile
    delete organization._profile
    return organization
  }

  /**
   * Max users
   * 
   */
  static MaxUsers = 50

  /**
   * Email address
   * @type {String}
   */
  email

  /**
   * Phone number
   * @type {String}
   */
  phone

  /**
   * Identifier of the organization in the legacy database
   * @type {Number}
   */
  legacyId

  /**
   * Indicates whether the organization is locked
   * so it can only be edited by its creator
   * @type {Boolean}
   */
  isLocked

  /**
   * Identifier of parent organization where the organization belongs to
   * @type {String}
   */
  parentOrganizationId

  /**
   * Parent organization where the organization belongs to
   * @type {Organization}
   */
  parentOrganization

  /**
   * Indicates that organization has a parent
   * @returns {Boolean} True if organization is a child of another organization
   */
  get hasParent () {
    return Boolean(this.parentOrganizationId)
  }

  /**
   * Identifier of the assigned organization profile
   * @type {String}
   */
  profileId

  /**
   * Returns the assigned organization profile.
   * Take organization level from the profile.
   * @type {OrganizationProfile}
   */
  get profile () {
    return this._profile
  }

  /**
   * Assigns the organization profile
   * @param {OrganizationProfile} value Organization profile to assign
   */
  set profile (value) {
    this._profile = value
    if (value) {
      this.level = value.level
      this.profileId = value.id
    } else {
      if (value === null) {
        this.profileId = undefined
      }
    }
  }

  /**
   * Identifier of terms and conditions applicable to this organization,
   * as specified in the profile
   * @type {String}
   */
  get termsAndConditionsId () {
    return this.profile ? this.profile.termsAndConditionsId : undefined
  }

  /**
   * Custom icon representing this organization,
   * as specified either in the organization itself, or in the profile
   * @type {String}
   */
  get icon () {
    if (this._icon) {
      return this._icon
    } else if (this.profile) {
      return this.profile.icon
    }
  }
  set icon (value) {
    this._icon = value
  }

  /**
   * Full name of the organization, including profile
   * @type {String}
   */
  get fullName () {
    const { name, profile } = this
    return [profile ? profile.fullName : null, name]
      .map(s => (s || '').trim())
      .filter(s => s)
      .join(' ')
  }

  /**
   * Organization wallet balance
   * @type {Number}
   */
  get balance () {
    return this.wallet?.balance
  }

  /**
   * Checks if the specified permission is granted to the principal
   */

  /**
   * Indicates that organization is a premium customer,
   * obliged to purchase subscriptions to use premium features
   * @type {Boolean}
   */
  get isPremiumCustomer () {
    return this.canUse('premium-services-buy')
  }

  /**
   * Indicates that organization has premium customers,
   * obliged to purchase subscriptions to use premium features
   * @type {Boolean}
   */
  get hasPremiumCustomers () {
    return this.organizations?.some(o => o.canUse('premium-services-buy'))
  }

  /**
   * Indicates that organization is a premium reseller,
   * able to sell subscriptions to his customers
   * @type {Boolean}
   */
  get isPremiumReseller () {
    return this.canUse('premium-services-sell')
  }

  /**
   * Indicates that organization is a token bank
   * @type {Boolean}
   */
  get isBank () {
    return this.level === OrganizationLevel.SuperOrganization ||
      this.canUse('premium-services-bank')
  }

  /**
  * Organization preferences
  * @type {Dictionary<String, any>}
  */
  preferences

  /**
   * Users within the organization
   * @type {Array[User]}
   */
  users

  /**
   * Checks whether the organization has any users
   * @returns {Boolean}
   */
  get hasUsers () {
    return this.users?.length > 0
  }

  /**
   * Admin users of the organization
   * @type {Array[User]}
   */
  get administrators () {
    return this.users?.filter(user => user.isAdministrator)
  }

  /**
   * Default admin user of the organization.
   * @type {User}
   */
  get administrator () {
    return this.administrators?.find(a => a.isEnabled)
  }

  /**
   * Identifier of the administrator
   * @type {String}
   */
  get administratorId () {
    return this.administrator?.id
  }

  /**
   * Returns a primary administrator
   * @type {User}
   */
  get primaryAdministrator () {
    // Get all administrators
    const administrators = this.administrators || []
    // Find one marked explicitly as primary
    const primary = administrators.find(user => user.isEnabled && !user.deletedAt && user.isPrimaryAdministrator)
    // If not found, find the oldest one
    if (primary) {
      return primary
    } else {
      return sortItems(administrators, user => user.createdAt).filter(user => user.isAdministrator && user.isEnabled && !user.deletedAt)[0]
    }
  }

  /**
  * Child organizations
  * @type {Array[Organization]}
  */
  organizations

  /**
   * Indicates that organization has child organizations
   * @type {Boolean}
   */
  get hasChildOrganizations () {
    return (this.organizations || []).length > 0
  }

  /**
   * Checks whether organization level allows it to have child organizations.
   * Notice that this can be further limited in organization permissions!
   * @type {Boolean}
   */
  get canHaveChildOrganizations () {
    const { level } = this
    return level === OrganizationLevel.SuperOrganization ||
      level === OrganizationLevel.ResellerOrganization ||
      this.canUse('child-organizations')
  }

  /**
   * Parent organizations - we only return names and identifiers for security reasons
   * @type {Array[Organization]}
   */
  parentOrganizations

  /**
   * Checks whether the specified organization is one of ancestors
   * listed in {@link parentOrganizations}
   * @param {String} id Ancestor organization identifier
   * @returns {Boolean}
   */
  isAncestor ({ id } = {}) {
    return (this.parentOrganizations || []).some(p => p.id === id)
  }

  /**
   * Checks whether the specified organization is a direct parent
   * @param {String} id Parent organization identifier
   * @returns {Boolean}
   */
  isParent ({ id } = {}) {
    return this.parentOrganizationId === id
  }

  /**
   * Site on which the organization is hosted
   * @type {String}
   */
  site

  /**
   * Site details
   * @type {OrganizationSite}
   */
  siteDetails

  /**Fvariant:
   * Code of the site on which the organization is hosted
   * @type {String}
   */
  get siteCode () {
    return this.siteDetails?.code
  }

  /**
   * Company identity of the site on which the organization is hosted
   * @type {String}
   */
  get siteCompany () {
    return this.siteDetails?.company
  }

  /**
   * Places within the organization
   * @type {Array[Place]}
   */
  places

  /**
   * Checks whether the organization has any places
   * @type {Boolean}
   */
  get hasPlaces () {
    return this.places && this.places.length > 0
  }

  /**
   * Number of devices owned by the organization
   * @type {Number}
   */
  deviceCount

  /**
   * Code of the country where organization belongs to
   * ISO 3166 Alpha-2 country code
   * @type {String}
   */
  countryCode

  /**
   * Code of the preferred language used by the organization
   * ISO 639-2 language code
   * @type {String}
   */
  languageCode

  /**
   * Name of country timezone, as per https://www.iana.org/time-zones
   * @type {String}
   */
  timezone

  /**
   * Indicates whether an organization is a super organization
   * @type {Boolean}
   */
  get isSuperOrganization () {
    return this.level === OrganizationLevel.SuperOrganization
  }

  /**
   * Indicates whether an organization is a reseller organization which can have child organizations
   * @type {Boolean}
   */
  get isResellerOrganization () {
    return this.level === OrganizationLevel.ResellerOrganization
  }

  /**
   * Indicates whether an organization is a regular organization
   * @type {Boolean}
   */
  get isRegularOrganization () {
    return this.level === OrganizationLevel.Organization
  }

  /**
   * Indicates whether an organization is a guest organization with very limited functionality
   * @type {Boolean}
   */
  get isGuestOrganization () {
    return this.level === OrganizationLevel.GuestOrganization
  }

  /**
   * Indicates whether profile represents a shipping company with no access to system or devices,
   * present only to capture the realities of shipping devices between organizations
   * @type {Boolean}
   */
  get isShippingCompany () {
    return this.level === OrganizationLevel.ShipppingCompany
  }

  /**
   * Organization's parent principal is its profile
   * @type {OrganizationProfile}
   */
  get parentPrincipals () {
    const { profile } = this
    return profile
      ? [profile].map(p => new OrganizationProfile(p))
      : []
  }

  /**
   * Returns true if organization is the same as the specified one
   * @param {Organization} organization Organization to compare with
   * @returns {Boolean}
   */
  sameAs (organization) {
    const { id } = this
    return organization && id && id === organization.id
  }

  /**
   * Returns true if organization is parent of the specified one
   * @param {Organization} organization Organization to compare with
   * @returns {Boolean}
   */
  isParentOf (organization) {
    const { id } = this
    return organization && id && id === organization.parentOrganizationId
  }

  /**
   * Returns true if organization is child of the specified one
   * @param {Organization} organization Organization to compare with
   * @returns {Boolean}
   */
  isChildOf (organization) {
    const { parentOrganizationId } = this
    return organization && parentOrganizationId && parentOrganizationId === organization.id
  }

  /**
   * Returns true if organization has the same level as the specified one
   * @param {Organization} organization Organization to compare with
   * @returns {Boolean}
   */
  sameLevelAs (organization) {
    const { level } = this
    return organization && level && level === organization.level
  }

  /**
   * Returns true if organization has level higher than the specified one
   * @param {OrganizationLevel} level Level to compare with
   * @returns {Boolean}
   */
  levelHigherThan (level) {
    return OrganizationLevelWeight[this.level] > OrganizationLevelWeight[level]
  }

  /**
   * Returns true if organization is in the same profile as the specified one
   * @param {Organization} organization Organization to compare with
   * @returns {Boolean}
   */
  sameProfileAs (organization) {
    const { profileId } = this
    return organization && profileId && profileId === organization.parentOrganizationId
  }

  /**
   * Returns true if organization is in the same parent organization as the specified one
   * @param {Organization} organization Organization to compare with
   * @returns {Boolean}
   */
  sameParentOrganizationAs (organization) {
    const { parentOrganizationId } = this
    return organization && parentOrganizationId && parentOrganizationId === organization.parentOrganizationId
  }

  /**
   * Overrides serialization to prevent serializing of certain
   * runtime-only properties
   */
  toJSON () {
    const result = {
      ...this,
      profile: this._profile,
      icon: this._icon,
      fullName: this.fullName
    }
    if (result.wallet) {
      delete result.wallet.id
      delete result.wallet.createdAt
      delete result.wallet.updatedAt
      delete result.wallet.organization
      delete result.wallet.organizationId
      delete result.wallet.canSubscribe
      if (!result.wallet.isBank) delete result.wallet.isBank
    }
    delete result._profile
    delete result._icon
    return result
  }

  /**
   * Finds all descendants of the organization,
   * including the organization itself
   * @param {Array[Organization]} all All organizations
   * @param {Boolean} deep If true, also indirect descendants are included
   * @returns {Array[Organization]} List of descendants
   */
  getMyDescendants (all = [], deep) {
    return Organization.getDescendantsOf(this.id, all, deep)
  }

  /**
 * Returns hierarchy of the organization
 * @param {Array[Organization]} all All organizations
 * @param {Boolean} includeMe If true, also the child organization is included in the result
 * @returns {Array[Organization]} List of ancestors
 */
  getMyAncestors (all = [], includeMe = true) {
    return Organization.getAncestorsOf(this.id, all, includeMe)
  }

  /**
   * Finds all descendants of the specified organization,
   * including the organization itself
   * @param {String} id Organization identifier
   * @param {Array[Organization]} all All organizations
   * @param {Boolean} deep If true, also indirect descendants are included
   * @param {Array[Organization]} result Used internally to accumulate the result in recursive calls
   * @returns {Array[Organization]} List of descendants
   */
  static getDescendantsOf (id, all, deep, result = []) {
    const children = (all || []).filter(o => o.parentOrganizationId == id)
    result.push(...children)
    if (deep) {
      for (const child of children) {
        Organization.getDescendantsOf(child.id, all, deep, result)
      }
    }
    return result
  }

  /**
   * Returns organization hierarchy
   * @param {String} id Organization identifier
   * @param {Array[Organization]} all All organizations
   * @param {Boolean} includeChild If true, also the child organization is included in the result
   * @returns {Array[Organization]} List of ancestors
   */
  static getAncestorsOf (id, all, includeChild = true) {
    let result = []
    let organization = (all || []).find(o => o.id == id)
    if (organization) {
      do {
        if (includeChild || organization.id !== id) {
          result = [organization, ...result]
        }
        organization = (all || []).find(o => o.id == organization.parentOrganizationId)
      } while (organization)
    }
    return result
  }
}

/**
 * Application user
 */
export class User extends Principal {
  constructor (data = {}) {
    super()
    this.assign({
      level: UserLevel.User,
      ...data,
      type: PrincipalType.User,
      preferences: data.preferences || {},
      isEnabled: data.isEnabled == null ? true : Boolean(data.isEnabled),
      isPrimary: data.isPrimary == null ? false : Boolean(data.isPrimary)
    })
    if (!isEnum(UserLevel, this.level)) throw new Error(`Invalid user level ${this.level}`)
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    this.organization = this.cast(this.organization, Organization)
    this.creator = this.cast(this.creator, User)
    this.updater = this.cast(this.updater, User)
    this.disabler = this.cast(this.disabler, User)
    this.permissions = this.castArray(this.permissions, Permission)
    this.siteDetails = this.cast(this.siteDetails, OrganizationSite)
    this.lastLoginAt = parseDate(this.lastLoginAt)
    this.loginSucceeded = Boolean(this.loginSucceeded)
  }

  /**
   * Last login attempt by the user
   * @type {Date}
   */
  lastLoginAt

  /**
   * Time in seconds when last login has been attempted
   * @type {Number}
   */
  get lastLoginAttempt () {
    return differenceInSeconds(Date.now(), this.lastLoginAt || Date.now())
  }

  /**
   * Indicates whether the last login attempt has succeeded
   * @type {Boolean}
   */
  loginSucceeded

  /**
   * The number of subsequent failed login attempts
   * @type {Number}
   */
  failedLoginCount

  /**
   * Identifier of an organization where the user belongs to
   * @type {String}
   */
  organizationId

  /**
   * Organization where the user belongs to
   * @type {Organization}
   */
  organization

  /**
   * Password hash
   * @type {String}
   */
  passwordHash

  /**
   * Code of the country where user resides
   * @type {String}
   */
  countryCode

  /**
   * Code of the preferred language
   */
  languageCode

  /**
   * Email address is equivalent to user name
   * @type {String}
   */
  get email () {
    return this.name
  }

  set email (value) {
    this.name = value
  }

  /**
   * First name
   * @type {String}
   */
  firstName

  /**
   * Last name
   * @type {String}
   */
  lastName

  /**
   * Phone number
   * @type {String}
   */
  phone

  /**
   * Full user name
   * @type {String}
   */
  get fullName () {
    if (this.firstName || this.lastName) {
      return [this.firstName || '', this.lastName || '']
        .filter(part => Boolean(part))
        .join(' ')
    } else {
      return this.name
    }
  }

  /**
   * Full user name and organization name
   * @type {String}
   */
  get nameAndOrganization () {
    return this.organization
      ? `${this.fullName}, ${this.organization.name}`
      : this.fullName
  }

  /**
   * User preferences
   * @type {Dictionary<String,any>}
   */
  preferences = {}

  /**
   * User permissions
   * @type {Array[Permission]}
   */
  permissions

  /**
   * Indicates that the user is a regular user
   * @type {Boolean}
   */
  get isRegularUser () {
    return this.level === UserLevel.User
  }

  /**
   * Indicates that the user is a guest user
   * @type {Boolean}
   */
  get isGuestUser () {
    return this.level === UserLevel.Guest || (this.organization && this.organization.isGuestOrganization)
  }

  /**
   * Indicates that the user is an integration account
   * @type {Boolean}
   */
  get isIntegration () {
    return this.level === UserLevel.Integration
  }

  /**
   * Indicates that the user is an administrator within his organization
   * @type {Boolean}
   */
  get isAdministrator () {
    return this.level === UserLevel.Administrator
  }

  /**
   * Indicates that user is a primary user in his category
   * @type {Boolean}
   */
  isPrimary

  /**
   * Indicates that the user is a primary administrator
   * @type {Boolean}
   */
  get isPrimaryAdministrator () {
    return this.isAdministrator && this.isPrimary
  }

  /**
   * Indicates that the user is not an administrator within his organization
   * @type {Boolean}
   */
  get isNotAdministrator () {
    return !this.isAdministrator
  }

  /**
   * Indicates that the user is an administrator within a super organization
   * @type {Boolean}
   */
  get isSuperAdministrator () {
    return this.isAdministrator && this.organization && this.organization.isSuperOrganization
  }

  /**
   * Indicates that the user is an integration account within a super organization
   * @type {Boolean}
   */
  get isSuperIntegrator () {
    return this.isIntegration && this.organization && this.organization.isSuperOrganization
  }

  /**
   * Indicates that the user is not an administrator within a super organization
   * @type {Boolean}
   */
  get isNotSuperAdministrator () {
    return !this.isSuperAdministrator
  }

  /**
   * Indicates that the user is an administrator within a reseller organization
   * @type {Boolean}
   */
  get isResellerAdministrator () {
    return this.isAdministrator && this.organization && this.organization.isResellerOrganization
  }

  /**
   * User's parent principal is its organization, then organization profile
   * @type {Array[Principal]}
   */
  get parentPrincipals () {
    const { organization } = this
    return [
      organization ? new Organization(organization) : null,
      organization ? organization.profile : null]
      .filter(p => p)
  }

  /**
   * Returns true if user is the same as the specified one
   * @param {User} user User to compare with
   * @type {Boolean}
   */
  sameAs (user) {
    const { id } = this
    return user && id && id === user.id
  }

  /**
   * Returns true if user has the same level as the specified one
   * @param {User} user User to compare with
   * @type {Boolean}
   */
  sameLevelAs (user) {
    const { level } = this
    return user && level && level === user.level
  }

  /**
   * Returns true if user has level higher than the specified one
   * @param {UserLevel} level Level to compare with
   * @type {Boolean}
   */
  levelHigherThan (level) {
    return UserLevelWeight[this.level] > UserLevelWeight[level]
  }


  /**
   * Returns true if user is in the same organization as the specified one
   * @param {User} user User to compare with
   * @type {Boolean}
   */
  sameOrganizationAs (user) {
    return user && this.organizationId === user.organizationId
  }

  /**
   * Checks whether the specified permission has been granted to the organization.
   * Requires {@link permissions} to be populated!
   * @param {String} name Permission name
   * @type {Boolean}
   */
  hasPermission (name) {
    if (this.isSuperAdministrator) {
      return true
    } else {
      return super.hasPermission(name)
    }
  }

  /**
   * Overrides serialization to add some of the computed properties
   */
  toJSON () {
    const result = {
      ...this,
      fullName: this.fullName,
      email: this.email,
      isAdministrator: this.isAdministrator,
      isRegularUser: this.isRegularUser,
      isGuestUser: this.isGuestUser
    }
    return result
  }
}
