import {
  addMilliseconds,
  addSeconds,
  addMinutes,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  subMilliseconds,
  subSeconds,
  subMinutes,
  subHours,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  setMilliseconds,
  setSeconds,
  setMinutes,
  endOfDay,
  differenceInMilliseconds,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInWeeks,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  intervalToDuration,
  parse,
  getTime,
  startOfMinute,
  startOfHour,
  startOfDay,
  format,
  formatDistanceToNow,
  parseISO
} from 'date-fns'
import { padLeft } from './string'
import { isNumber } from './number'
import timezones from '../assets/timezones.json'

/**
 * All timezones
 */
export const Timezones = timezones

/**
 * Time movement direction
 */
export const TimeDirection = {
  Back: -1,
  Forward: 1
}

/**
 * Time period units
 */
export const Period = {
  Millisecond: 'ms',
  Second: 's',
  Minute: 'm',
  Hour: 'h',
  Day: 'D',
  Week: 'W',
  Month: 'M',
  Year: 'Y'
}

/**
 * Time period units, long
 */
export const PeriodLong = {
  [Period.Millisecond]: 'millisecond',
  [Period.Second]: 'second',
  [Period.Minute]: 'minute',
  [Period.Hour]: 'hour',
  [Period.Day]: 'day',
  [Period.Week]: 'week',
  [Period.Month]: 'month',
  [Period.Year]: 'year'
}

/**
 * Time period unit names
 */
export const PeriodNames = {
  Singular: {
    [Period.Millisecond]: 'millisecond',
    [Period.Second]: 'second',
    [Period.Minute]: 'minute',
    [Period.Hour]: 'hour',
    [Period.Day]: 'day',
    [Period.Week]: 'week',
    [Period.Month]: 'month',
    [Period.Year]: 'year'
  },
  Plural: {
    [Period.Millisecond]: 'milliseconds',
    [Period.Second]: 'seconds',
    [Period.Minute]: 'minutes',
    [Period.Hour]: 'hours',
    [Period.Day]: 'days',
    [Period.Week]: 'weeks',
    [Period.Month]: 'months',
    [Period.Year]: 'years'
  }
}

/**
 * Parses the specified time string or timestamp to time-only stamp.
 * Unlike with JavaScript Date timestamp returned by `Date.getTime()`,
 * this timestamp is relative and based at midnight when it has value of 0,
 * and ends on next midnight where it has value of 24*60*60*60
 * @param {Date|Number|String} value Time to parse, specified as date, timestamp or string in 'HH:mm' or 'HH:mm:ss' format
 * @param {String} format Expected input format, guessed from time length if possible
 * @param {Number} defaultValue Default value to return if time cannot be parsed
 * @returns {Number} Parsed time in milliseconds from midnight
 */
export function parseTime (value, format, defaultValue) {
  if (value == null) {
    return
  }

  try {
    let timestamp
    let base = startOfDay(new Date())

    // Input is a date
    if (value instanceof Date) {
      timestamp = getTime(value)

    } else {
      // Input is a numeric timestamp,
      // either full-date timestamp or time-only timestamp
      let number = parseInt(value)
      if (!isNaN(number) && number.toString() === value.toString()) {
        if (number <= (1000 * 60 * 60 * 24)) {
          return number
        } else {
          base = startOfDay(new Date(number))
          timestamp = getTime(new Date(number))
        }
      } else {
        // Input is a time string
        const text = value.toString().trim()
        if (text.length === 5 && text[2] === ':') format = 'HH:mm'
        if (text.length === 8 && text[2] === ':' && text[5] === ':') format = 'HH:mm:ss'
        const time = parse(text, format || 'HH:mm:ss', base)
        if (time instanceof Date && !isNaN(time.getTime())) {
          timestamp = getTime(time)
        }
      }
    }

    // Return relative timestamp
    return timestamp == null
      ? defaultValue
      : timestamp - getTime(base)

  } catch {
    return defaultValue
  }
}

/**
 * Converts the specified time to local time
 * @param {String} country Country code or country-culture code, i.e. `de`, `be-nl`
 * @param {String} timeZone Timezone name, i.e. `Europe/Dublin`,
 * must be listed in https://www.iana.org/time-zones (human-readable list at https://timezonedb.com/time-zones)
 * @param {Date} time Time to convert
 * @returns {Number} Local time in milliseconds from midnight
 */
export function getLocalTime (country, timeZone, time = new Date()) {
  if (time != null) {
    if (country && timeZone) {
      const formatter = new Intl.DateTimeFormat(country, {
        timeZone,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        millisecond: 'numeric',
        fractionalSecondDigits: 3,
        hour12: false
      })
      const parts = formatter.formatToParts(time)
      const hours = parseInt(parts.find(part => part.type === 'hour').value)
      const minutes = parseInt(parts.find(part => part.type === 'minute').value)
      const seconds = parseInt(parts.find(part => part.type === 'second').value)
      return (hours * 3600 + minutes * 60 + seconds) * 1000
    } else {
      return getUTCTime(time)
    }
  }
}

/**
 * Returns UTC time
 * @param {Date} time Time to return UTC time for
 * @returns {Number} UTC time in milliseconds from midnight
 */
export function getUTCTime (time = new Date()) {
  if (time != null) {
    const [hours, minutes, seconds] = time
      .toISOString()
      .substring(11, 23)
      .split(':')
      .map(s => parseFloat(s))
    return (hours * 3600 + minutes * 60 + seconds) * 1000
  }
}

/**
 * Difference in seconds between the current local time and UTC time
 * @returns {Number}
 */
export function getUTCDelta () {
  const time = new Date()
  const hours = time.getHours()
  const minutes = time.getMinutes()
  const utcHours = time.getUTCHours()
  const utcMinutes = time.getUTCMinutes()
  return (hours * 3600 + minutes * 60) - (utcHours * 3600 + utcMinutes * 60)
}

/**
 * Checks if the specified time is time-of-day timestamp, rather than absolute time
 * @param {Date|Number} value Time to check
 * @returns {Boolean}
 */
export function isTimeOfDay (value) {
  if (value != null) {
    if (value instanceof Date) {
      return false
    } else if (isNumber(value)) {
      return value <= 24 * 3600 * 1000
    }
  }
}

/**
 * Formats a date/time value to string, taking only time part
 * @param {Date|Number|String} value Time to format, specified as Date, timestamp or time string
 * @param {String} timeFormat Time format
 * @returns {String} Formatted time
 */
export function formatTime (value, timeFormat = 'HH:mm:ss') {
  if (value != null) {
    const midnight = startOfDay(new Date())
    let time

    if (value instanceof Date) {
      time = value

    } else if (isNumber(value)) {
      // Check if absolute time or time-of-day
      if (value <= 24 * 3600 * 1000) {
        time = new Date(midnight.getTime() + value)
      } else {
        time = new Date(value)
      }
    } else {
      time = (value.toString().includes('T')
        ? parseISO(value.toString())
        : parse(value.toString(), 'HH:mm:ss', midnight))
    }
    if (time != null && time instanceof Date && !isNaN(time.getTime())) {
      return format(time, timeFormat)
    }
  }
}

/**
 * Formats a date value to short time HH:mm
 * @param {Date} value Time to format
 * @param {String} timeFormat Time format
 * @returns {String} Formatted time
 */
export function formatTimeShort (value) {
  return formatTime(value, 'HH:mm')
}

/**
 * Formats time part to a two-digit string prefixed with zero
 * @param {String|Number} part Time part to format
 * @returns {String} Formatted time part
 */
export function formatTimePart (value) {
  if (value != null) {
    const number = parseInt(value || 0)
    if (!isNaN(number)) {
      return padLeft(number.toString(), 2, '0')
    }
  }
}

/**
 * Returns the current timestampof the specified or current date
 * @param {Date} date Optional date
 * @returns {Number} Current timestamp
 */
export function getTimestamp (date) {
  if (date) {
    if (date instanceof Date) {
      return date.getTime()
    }
  } else {
    return (new Date()).getTime()
  }
}

/**
 * Returns the time string of the specified or current date
 * @param {Date|Number} date Optional date
 * @returns {String} Time string
 */
export function getTimeString (date) {
  const template = 'HH:mm:ss'
  if (date) {
    if (date instanceof Date) {
      return format(date, template)
    } else if (typeof date === 'number') {
      return format(new Date(date), template)
    }
  } else {
    return format(new Date(), template)
  }
}

/**
 * Returns the current time string of the specified or current date, without seconds
 * @param {Date:Number} date Optional date
 * @returns {String} Time string
 */
export function getShortTimeString (date) {
  const template = 'HH:mm'
  if (date) {
    if (date instanceof Date) {
      return format(date, template)
    } else if (typeof date === 'number') {
      return format(new Date(date), template)
    }
  } else {
    return format(new Date(), template)
  }
}

/**
 * Converts timestamp to datetime
 * @param {Number|Date} time Time to convert. If not specified, returns the current date.
 * @returns {Date} Date representing the specified or the current time
 */
export function getDateTime (time) {
  if (time != null) {
    if (time instanceof Date) {
      return time
    } else if (typeof time === 'number') {
      return new Date(time)
    }
  } else {
    return new Date()
  }
}

/**
 * Parses the specified duration into years, months, weeks, days, hours, minutes and seconds
 * @param {Number} duration Duration in seconds
 * @returns {Object} Parsed duration, returned as `{ years, months, weeks, days, totalDays, hours, minutes, seconds, duration }` dictionary
 */
export function getDuration (duration) {
  let result
  if (duration != null) {
    if (duration >= 0) {
      const start = new Date()
      const end = addSeconds(start, duration)
      const totalDays = Math.floor(duration / (24 * 3600))
      result = {
        ...intervalToDuration({ start, end }),
        duration,
        totalDays
      }
    } else {
      result = { years: 0, months: 0, weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0, duration: 0 }
    }
  }
  return result
}

/**
 * Converts the specified amount of seconds into
 * human-readable duration string showing
 * days, hours and minutes
 * @param {Number} duration Duration in seconds
 * @param {Object} customOptions Custom options for formatting the output.
 * Specify `days`, `hours`, `minutes` or `seconds` as false
 * to skip the part from the output.
 * Specify `prefix` to add it to the beginning of the returned string, if duration is greater than 0
 * Specify `suffix` to add it to the end of the returned string, if duration is greater than 0
 * @returns {String}
 */
export function getDurationString (duration, customOptions = {}) {
  const options = {
    now: 'now',
    separator: ' ',
    days: 'd',
    hours: 'h',
    minutes: 'm',
    seconds: 's',
    prefix: '',
    suffix: '',
    ...customOptions
  }
  if (duration === 0) {
    return options.now
  } else if (duration > 0) {
    const { totalDays: days, hours, minutes, seconds } = getDuration(duration)
    const result = [
      `${days > 0 && options.days ? (days + options.days) : ''}`,
      `${hours > 0 && options.hours ? (hours + options.hours) : ''}`,
      `${minutes > 0 && options.minutes ? (minutes + options.minutes) : ''}`,
      `${seconds > 0 && options.seconds ? (seconds + options.seconds) : ''}`
    ]
      .filter(part => part)
      .join(options.separator)
    return `${options.prefix}${result}${options.suffix}`
  }
}

/**
 * Returns a concise duration string, without getting into unnecessary
 * details such as seconds, when duration gets into days etc.
 * @param {Number} duration Duration in seconds
 * @param {String} suffix Custom suffix to add to the output
 * @returns {String}
 */
export function getConciseDurationString (duration, { now = 'now', suffix = '' } = {}) {
  let result
  if (duration >= 0 && duration < 1) {
    result = now
  } else if (duration > 0) {
    if (isDurationMonths(duration)) {
      const months = getDurationMonths(duration)
      result = `${months === 1 ? 'one month' : months + ' months'} ${suffix}`
    } else if (isDurationManyDays(duration)) {
      result = getDurationString(duration, { days: ' days', hours: false, minutes: false, seconds: false }) + ' ' + suffix
    } else if (isDurationFewDays(duration) || isDurationManyHours(duration)) {
      result = getDurationString(duration, { minutes: false, seconds: false }) + ' ' + suffix
    } else if (duration > 60) {
      result = getDurationString(duration, { seconds: false }) + ' ' + suffix
    } else {
      result = getDurationString(duration) + ' ' + suffix
    }
  }
  return result
}

/**
 * Converts the specified amount of seconds into
 * fully worded human-readable duration string
 * @param {Number} duration Duration in seconds
 * @returns {String}
 */
export function getFullDurationString (duration) {
  if (duration >= 0) {
    return formatDistanceToNow(subSeconds(Date.now(), duration))
  }
}

/**
 * Calculates a difference between now and the specified date and time
 * @param {Date} time
 * @param {Object} options Options for `getDurationString` function used to format the result
 * @param {Date} now Current time
 * @returns {Number} Time difference, in seconds
 */
export function getAge (time, now = new Date()) {
  if (time) {
    return differenceInSeconds(now, time)
  }
}

/**
 * Calculates a difference between now and the specified date and time,
 * returns a human-readable duration string showing
 * days, hours and minutes
 * @param {Date} time
 * @param {Date} now Current time
 * @param {Object} options Options for `getDurationString` function used to format the result
 * @returns {String}
 */
export function getAgeString (time, now = new Date(), options) {
  if (time) {
    const duration = differenceInSeconds(now, time)
    return getDurationString(duration, options)
  }
}

/**
 * Returns true if duration is in months range
 * @param {Number} duration Duration in seconds
 * @returns {Boolean}
 */
export function isDurationMonths (duration) {
  if (duration >= 0) {
    const months = Math.floor(duration / (3600 * 24 * 31))
    return months >= 1
  }
}

/**
 * Returns an approximate amount of months
 * representing the duration
 * @param {Number} duration Duration in seconds
 * @returns {Number}
 */
export function getDurationMonths (duration) {
  if (duration >= 0) {
    const now = new Date()
    const future = addSeconds(now, duration)
    const months = differenceInMonths(future, now)
    return months
  }
}

/**
 * Returns true if duration is in days range
 * @param {Number} duration Duration in seconds
 * @returns {Boolean}
 */
export function isDurationDays (duration) {
  if (duration >= 0) {
    const days = Math.floor(duration / (3600 * 24))
    return days >= 1 && days <= 31
  }
}

/**
 * Returns true if duration is just a few days
 * @param {Number} duration Duration in seconds
 * @param {Number} few What it means `few` days
 * @returns {Boolean}
 */
export function isDurationFewDays (duration, few = 5) {
  if (duration >= 0 && few >= 0) {
    const days = Math.floor(duration / (3600 * 24))
    return days >= 1 && days <= few
  }
}

/**
 * Returns true if duration is many days
 * @param duration Duration in seconds
 * @param many What it means `many` days
 * @returns {Boolean}
 */
export function isDurationManyDays (duration, many = 6) {
  if (duration >= 0 && many >= 0) {
    const days = Math.floor(duration / (3600 * 24))
    return days >= many
  }
}

/**
 * Returns true if duration is in hours range, but less than a day
 * @param {Number} duration Duration in seconds
 * @returns {Boolean}
 */
export function isDurationHours (duration) {
  if (duration >= 0) {
    const hours = Math.floor(duration / 3600)
    return hours >= 1 && hours < 24
  }
}

/**
 * Returns true if duration is just a few hours
 * @param {Number} duration Duration in seconds
 * @param {Number} few What it means `few` hours
 * @returns {Boolean}
 */
export function isDurationFewHours (duration, few = 6) {
  if (duration >= 0 && few >= 0) {
    const hours = Math.floor(duration / 3600)
    return hours >= 1 && hours <= few
  }
}

/**
 * Returns true if duration is many hours, but still less than a day
 * @param {Number} duration Duration in seconds
 * @param {Number} many What it means `many` seconds
 * @returns {Boolean}
 */
export function isDurationManyHours (duration, many = 6) {
  if (duration >= 0 && many >= 0) {
    const hours = Math.floor(duration / 3600)
    return hours < 24 && hours > many
  }
}

/**
 * Returns true if duration is in minutes range, but less than an hour
 * @param {Number} duration Duration in seconds
 * @returns {Boolean}
 */
export function isDurationMinutes (duration) {
  if (duration >= 0) {
    const minutes = Math.floor(duration / 60)
    return minutes >= 1 && minutes < 60
  }
}

/**
 * Returns true if duration is just a few minutes
 * @param {Number} duration Duration in seconds
 * @param {Number} few What it means `few` minutes
 * @returns {Boolean}
 */
export function isDurationFewMinutes (duration, few = 10) {
  if (duration >= 0 && few >= 0) {
    const minutes = Math.floor(duration / 60)
    return minutes >= 1 && minutes <= few
  }
}

/**
 * Returns true if duration is in seconds range, but less than a minute
 * @param {Number} duration Duration in seconds
 * @returns {Boolean}
 */
export function isDurationSeconds (duration) {
  if (duration >= 0) {
    return duration >= 1 && duration < 60
  }
}

/**
 * Returns true if duration is just a few seconds
 * @param {Number} duration Duration in seconds
 * @param {Number} few What it means `few` seconds
 * @returns {Boolean}
 */
export function isDurationFewSeconds (duration, few = 10) {
  if (duration >= 0 && few >= 0) {
    return duration >= 1 && duration <= few
  }
}

/**
 * Returns date/time the specified number of seconds before now
 * @param {Number} before Number of seconds to go back in time
 * @returns {Date}
 */
export function getTimeBefore (before) {
  if (before >= 0) {
    const now = new Date().getTime()
    const time = Math.floor((now - before * 1000) / 1000) * 1000
    return new Date(time)
  }
}

/**
 * Returns the number of period units within the specified date span
 * @param {Date} start Start date
 * @param {Date} end End date (inclusive)
 * @param {Period} period Period length
 * @returns {Number}
 */
export function getPeriod (start, end, period = Period.Day) {
  if (start && end && period) {
    switch (period) {
      case Period.Millisecond:
        return differenceInMilliseconds(end, start)
      case Period.Second:
        return differenceInSeconds(end, start)
      case Period.Minute:
        return differenceInMinutes(end, start)
      case Period.Hour:
        return differenceInHours(end, start)
      case Period.Day:
        return differenceInDays(end, start)
      case Period.Week:
        return differenceInWeeks(end, start)
      case Period.Month:
        return differenceInMonths(end, start)
      case Period.Year:
        return differenceInYears(end, start)
    }
  }
}

/**
 * Adds the specified amount of period units to the given date
 * @param {Date} start Start date
 * @param {Period} period Period length
 * @param {Number} count Number of periods to add
 * @returns {Date}
 */
export function addPeriod (date, period = Period.Day, count = 1) {
  if (date && period && count != null && count >= 0) {
    switch (period) {
      case Period.Millisecond:
        return addMilliseconds(date, count)
      case Period.Second:
        return addSeconds(date, count)
      case Period.Minute:
        return addMinutes(date, count)
      case Period.Hour:
        return addHours(date, count)
      case Period.Day:
        return addDays(date, count)
      case Period.Week:
        return addWeeks(date, count)
      case Period.Month:
        return addMonths(date, count)
      case Period.Year:
        return addYears(date, count)
    }
  }
}

/**
 * Subtracts the specified amount of period units from the given date
 */
export function subPeriod (date, period = Period.Day, count = 1) {
  if (date && period && count != null && count >= 0) {
    switch (period) {
      case Period.Millisecond:
        return subMilliseconds(date, count)
      case Period.Second:
        return subSeconds(date, count)
      case Period.Minute:
        return subMinutes(date, count)
      case Period.Hour:
        return subHours(date, count)
      case Period.Day:
        return subDays(date, count)
      case Period.Week:
        return subWeeks(date, count)
      case Period.Month:
        return subMonths(date, count)
      case Period.Year:
        return subYears(date, count)
    }
  }
}

/**
 * Converts period from one unit to another
 * @param {Period} source Period unit
 * @param {Period} target Period unit to convert to
 * @returns {Number} Number of units of the target period equivalent to the source period
 */
export function convertPeriod (source, target) {
  if (source && target) {
    if (source === target) {
      return 1
    } else {
      const now = startOfDay(new Date())
      const then = addPeriod(now, source, 1)
      return getPeriod(now, then, target)
    }
  }
}

/**
 * Returns seconds passed from start of the minute,
 * until the specified or current time
 * @param {Date} time Time to end on, optional. If not specified, current time is assumed
 * @returns {Number} Milliseconds passed from start of the minute until the specified time
 */
export function secondsFromMinuteStart (time) {
  const base = time || new Date()
  const passed = Math.floor(differenceInSeconds(base, startOfMinute(base)))
  return passed
}

/**
 * Returns seconds passed from start of the hour,
 * until the specified or current time
 * @param {Date} time Time to end on, optional. If not specified, current time is assumed
 * @returns {Number} Milliseconds passed from start of the hour until the specified time
 */
export function secondsFromHourStart (time) {
  const base = time || new Date()
  const passed = Math.floor(differenceInSeconds(base, startOfHour(base)))
  return passed
}

/**
 * Returns seconds passed from start of the day,
 * until the specified or current time
 * @param {Date} time Time to end on, optional. If not specified, current time is assumed
 * @returns {Number} Milliseconds passed from start of the day until the specified time
 */
export function secondsFromDayStart (time) {
  const base = time || new Date()
  const passed = Math.floor(differenceInSeconds(base, startOfDay(base)))
  return passed
}

/**
 * Returns seconds remaining to the end of the minute,
 * starting from the specified or current time
 * @param {Date} time Time to start with, optional. If not specified, current time is assumed
 * @returns {Number} Milliseconds remaining from specified time to the end of the minute
 */
export function secondsToMinuteEnd (time) {
  const base = time || new Date()
  const start = setMilliseconds(setSeconds(base, 0), 0)
  const remaining = 60 - Math.floor((differenceInMilliseconds(base, start) + 1) / 1000)
  return remaining
}

/**
 * Returns seconds remaining to the end of the current quarter,
 * starting from the specified or current time
 * @param {Date} time Time to start with, optional. If not specified, current time is assumed
 * @returns {Number} Milliseconds remaining from specified time to the end of the current quarter
 */
export function secondsToQuarterEnd (time) {
  const base = time || new Date()
  const quarterMinutes = base.getMinutes() % 15
  const minutesToQuarterEnd = quarterMinutes === 0 ? 0 : 15 - (base.getMinutes() % 15)
  const start = setMilliseconds(setSeconds(base, 0), 0)
  const end = addMinutes(start, minutesToQuarterEnd)
  const remaining = Math.floor((differenceInMilliseconds(end, start) + 1) / 1000)
  return remaining
}

/**
 * Returns seconds remaining to the end of the hour,
 * starting from the specified or current time
 * @param {Date} time Time to start with, optional. If not specified, current time is assumed
 * @returns {Number} Milliseconds remaining from specified time to the end of the hour
 */
export function secondsToHourEnd (time) {
  const base = time || new Date()
  const start = setMilliseconds(setSeconds(setMinutes(base, 0), 0), 0)
  const remaining = 3600 - Math.floor((differenceInMilliseconds(base, start) + 1) / 1000)
  return remaining
}

/**
 * Returns seconds remaining to the end of the day,
 * starting from the specified or current time
 * @param {Date} time Time to start with, optional. If not specified, current time is assumed
 * @returns {Number} Milliseconds remaining from specified time to the end of the day
 */
export function secondsToDayEnd (time) {
  const base = time || new Date()
  const end = endOfDay(base)
  const remaining = Math.floor((differenceInMilliseconds(end, base) + 1) / 1000)
  return remaining
}

/**
 * Enumerates minutes starting from specified time
 * @param {Number} count Number of minutes to enumerate. If negative, we're enumerating backwards.
 * @param {Date} time Time to start with, optional. If not specified, current time is assumed
 * @returns {Array[Number]} List of minutes ahead of or before the specified time
 */
export function* enumerateMinutes (count, time) {
  if (count === 0) return
  if (!(count > 0 || count < 0)) throw new Error('Count must be a positive or negative number')
  const base = time || new Date()
  let minute = base.getMinutes()
  let counter = Math.abs(count)
  while (counter > 0) {
    yield minute
    minute = (count > 0) ? minute + 1 : minute - 1
    if (minute === 60) minute = 0
    if (minute === -1) minute = 59
    counter--
  }
}

/**
 * Enumerates hours starting from specified time
 * @param {Number} count Number of hours to enumerate. If negative, we're enumerating backwards.
 * @param {Date} time Time to start with, optional. If not specified, current time is assumed
 * @returns {Array[Number]} List of hours ahead of or before the specified time
 */
export function* enumerateHours (count, time) {
  if (count === 0) return
  if (!(count > 0 || count < 0)) throw new Error('Count must be a positive or negative number')
  const base = time || new Date()
  let hour = base.getHours()
  let counter = Math.abs(count)
  while (counter > 0) {
    yield hour
    hour = (count > 0) ? hour + 1 : hour - 1
    if (hour === 24) hour = 0
    if (hour === -1) hour = 23
    counter--
  }
}

