<script>
import { mapState } from 'vuex'
import { isToday, isYesterday } from 'date-fns'
import { formatDateTime, formatTime, countString } from '@stellacontrol/utilities'
import { isAlertSupported } from '@stellacontrol/alerts'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  props: {
    // Renders the widget as mini-widget
    mini: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      // Age of the alerts to display
      age: state => state.deviceDashboard.lastAlertsAge,
      // Recently retrieved alerts of monitored devices
      lastAlerts: state => state.deviceStatus.alerts,
      // Alert configurations of the viewed device
      alertConfigurations: state => state.deviceDashboard.alertConfigurations
    }),

    // Mini-widget label
    widgetLabel () {
      const { alerts, alertConfigurations } = this
      if (alertConfigurations) {
        return alerts?.length > 0
          ? countString(alerts, 'alert')
          : 'No alerts'
      }
    },

    // Full widget items
    items () {
      const { lastAlert } = this
      const items = [
        { text: 'Alerts', class: { label: true } }
      ]

      if (lastAlert) {
        // Returns alert time in user-friendly format
        const alertTimeText = alert => {
          const alertTime = this.toLocalDateTime(alert.time)
          const alertTimeText = isToday(alertTime)
            ? formatTime(alertTime)
            : (isYesterday(alertTime) ? `Yesterday, ${formatTime(alertTime)}` : formatDateTime(alertTime, 'd MMM, HH:mm:ss'))
          return alertTimeText
        }

        items.push(
          {
            text: alertTimeText(lastAlert),
            class: { small: true }
          },
          {
            text: lastAlert.message,
            class: { small: true },
          }
        )

      } else {
        items.push({
          text: 'No alerts logged recently',
          class: { small: true }
        })
      }
      return items
    },

    // Recently logged alerts,
    // filtered by user permissions and current capabilities of the device
    alerts () {
      const { device, lastAlerts, status, currentOrganizationGuardian: guardian, alertConfigurations } = this
      if (alertConfigurations) {
        const alerts = lastAlerts[device.serialNumber] || []
        return alerts
          .filter(alert => guardian.canReceiveAlert(alert.alertType))
          .filter(alert => {
            const configuration = alertConfigurations.find(c => c.alertType === alert.alertType)
            if (configuration != null) {
              return isAlertSupported(alert.alertType, configuration, device, status)
            }
          })
      } else {
        return []
      }
    },

    // Most recent alert
    lastAlert () {
      return this.alerts[0]
    },

    // Other alerts except last one
    otherAlerts () {
      return this.alerts.slice(1)
    },

    // Indicates that there are more recent alerts
    hasMoreAlerts () {
      return this.otherAlerts.length > 0
    },

    // Alerts popup title
    alertsTitle () {
      const { device, alerts } = this
      if (alerts?.length > 0) {
        return `Recent alerts reported by ${device.acronym} ${device.serialNumber}`
      }
    },

    // Widget icon
    icon () {
      const { device } = this
      return device.canTriggerAlerts ? 'notifications_active' : 'notifications_off'
    },

    // Color of the widget icon
    iconColor () {
      const { device } = this
      return device.canTriggerAlerts
        ? 'light-green-7'
        : 'grey-5'
    },

    // Determines whether the widget can be shown
    canSeeAlerts () {
      const { device, alertConfigurations } = this

      // Device must be alert-enabled ...
      if (!(device?.canTriggerAlerts && alertConfigurations)) return false

      return true
    }
  }
}
</script>

<template>
  <template v-if="canSeeAlerts">

    <!-- Mini-widget for mobile -->
    <div v-if="mini" class="widget mini-widget widget-alerts" v-bind="{ ...$props, ...$attrs }">
      <header class="row items-center no-wrap pointer">
        <q-icon size="32px" :name="icon" :color="iconColor">
        </q-icon>
        <span class="q-ml-sm">
          {{ widgetLabel }}
        </span>
        <q-space></q-space>
        <q-btn v-if="lastAlert" round flat dense size="11px" icon="more_horiz">
        </q-btn>

        <sc-alert-popup v-if="lastAlert" :alerts="alerts" ref="alertPopup" :showSerialNumber="false"
          :title="alertsTitle">
        </sc-alert-popup>
      </header>
    </div>

    <!-- Full-widget for desktop -->
    <sc-widget-text v-else iconMargin="0" :icon="icon" :icon-color="iconColor" icon-size="32px"
      :items="items" :alerts="alerts" class="widget-alerts" v-bind="{ ...$props, ...$attrs }">
      <template #toolbar>
        <div class="toolbar">
          <q-btn v-if="hasMoreAlerts" class="q-pl-sm q-pr-sm" unelevated dense no-caps
            icon="notifications_active" :ripple="false" label="Show more ..." @click.stop>
            <sc-alert-popup :alerts="alerts" ref="alertPopup" :showSerialNumber="false"
              :title="alertsTitle">
            </sc-alert-popup>
          </q-btn>
        </div>
      </template>

    </sc-widget-text>
  </template>
</template>

<style lang="scss" scoped>
.toolbar {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-bottom: 8px;
}
</style>
