<script>
import { PlanTextStyle, PlanHorizontalAlignment } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  data () {
    return {
      PlanHorizontalAlignment,
      defaults: new PlanTextStyle().defaults
    }
  },

  computed: {
    // Available line types
    alignments () {
      return [
        { value: PlanHorizontalAlignment.Left, label: 'Left' },
        { value: PlanHorizontalAlignment.Center, label: 'Center' },
        { value: PlanHorizontalAlignment.Right, label: 'Right' }
      ]
    }
  }
}

</script>

<template>
  <main class="plan-item-properties">
    <section>
      <label>
        Font
      </label>
      <q-input
        type="number" :min="1" :max="150"
        dense outlined square
        debounce="500"
        :input-style="styles.number"
        :model-value="getValue('textStyle.size', defaults.size)"
        @update:model-value="value => setProperties({ 'textStyle.size': parseInt(value), 'textStyle.sizeChanged': true })">
      </q-input>
      <div>
        <sc-color-picker class="q-ml-xs"
          :model-value="getValue('textStyle.color', defaults.color)"
          @update:model-value="value => setProperties({ 'textStyle.color': value })"
          full clear :width="styles.color.width" :height="styles.color.height">
        </sc-color-picker>
      </div>
    </section>

    <section v-if="isAdvancedMode">
      <label>
        Text Layout
      </label>
      <q-input
        type="number" :min="0.1" :max="10" :step="0.1" label="Line Height"
        dense outlined square
        debounce="500"
        :input-style="styles.number"
        :model-value="getValue('textStyle.lineHeight', defaults.lineHeight)"
        @update:model-value="value => setProperties({ 'textStyle.lineHeight': parseFloat(value) })">
      </q-input>
      <q-select class="q-ml-xs" outlined square dense emit-value map-options label="Align"
        :options="alignments"
        :style="styles.select.small"
        :model-value="getValue('textStyle.alignment', defaults.alignment)"
        @update:model-value="value => setProperties({ 'textStyle.alignment': value })">
      </q-select>
    </section>

  </main>
</template>

<style lang="scss" scoped>
</style>
