<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { Log, plainText, getId, delay, createDataUrl } from '@stellacontrol/utilities'
import { ViewMixin, FormMixin, Exporter, Notification } from '@stellacontrol/client-utilities'
import { Device, DeviceType, StandardDeviceBands, Plan } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceAPI } from '@stellacontrol/client-api'
import { PlanLayout, PlanFloors, PlannerMode } from '@stellacontrol/planner'
import sampleLayout from './layout.json'

const name = 'stellaplanner-playground'

/**
 * Playground for Planner
 */
export default {
  mixins: [
    ViewMixin,
    FormMixin,
    Secure
  ],

  data () {
    return {
      name,
      floorId: null,
      layoutChanged: null,
      isDebugging: false,
      places: [],
      state: null,
      stateLabel: null,
      pointerPosition: null
    }
  },

  computed: {
    ...mapState({
      // Planner editing mode
      mode: state => state.planner.mode,
      // Edited plan
      plan: state => state.planner.plan,
      // Normal/maximized status of the view
      isMaximized: state => state.planner.isMaximized,
    }),

    ...mapGetters([
      'currentRoute'
    ]),

    devices () {
      return [
        new Device({
          type: DeviceType.Repeater,
          serialNumber: 'rig101',
          bands: StandardDeviceBands.FiveBands,
          portCount: 6
        }),
        new Device({
          type: DeviceType.Repeater,
          serialNumber: 'rig102',
          bands: StandardDeviceBands.SixBands,
          portCount: 4
        }),
        new Device({
          type: DeviceType.LineAmp,
          serialNumber: 'rig103',
          bands: StandardDeviceBands.FiveBands,
          portCount: 5
        })
      ]
    },

    place () {
      return this.places[0]
    },

    // View title
    title () {
      return 'Playground'
    },

    // Indicates that the plan is initialized
    isInitialized () {
      return this.plan?.layout != null
    },

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },
  },



  methods: {
    ...mapActions([
      'gotoHome',
      'toggleSidebar',
      'setPlannerMode',
      'setPlannerView',
      'editPlan',
      'updateRoute',
      'showDialog'
    ]),

    async initialize ({ data, snapshots, restoredFrom, restoredAt }) {
      this.places = await DeviceAPI.getPlaces()

      // Check if debugging not forced by query
      this.isDebugging = this.currentRoute.query.debugging != null

      // Load the plan
      const { currentUser, currentRoute } = this
      const plan = new Plan({
        id: 'e84e83a8cd764f8',
        createdBy: currentUser.id,
        updatedBy: currentUser.id,
        restoredFrom: restoredFrom?.id,
        restoredAt,
        name: 'Warehouse',
        layout: new PlanLayout(data, 'Plan'),
        snapshots
      })
      this.editPlan({ plan })

      // Determine the floor to show
      this.floorId = currentRoute.query.floor
      if (!(this.floorId && plan.layout.getFloor(this.floorId))) {
        this.floorId = PlanFloors.CrossSection
      }

      // Start in the advanced mode
      this.setPlannerMode({ mode: PlannerMode.Advanced })

      // Save the plan immediately if any changes,
      // upgrades etc. have been applied while loading the plan
      if (plan.layout.isDirty) {
        this.save()
      }
    },

    // Triggered when plan has been loaded
    planInitialized ({ renderer, layout, hierarchy }) {
      // Show current position in debug mode
      renderer.events.addEventListener('position', ({ detail: { position } }) => {
        this.pointerPosition = position
      })

      // Save the plan immediately if any changes,
      // upgrades etc. have been applied while loading the plan
      this.save()
    },

    // Goes back to the previous view
    async cancel () {
      await this.gotoHome()
    },

    // Notifies about changes to the plan layout
    onChanged () {
      this.save()
    },

    // Resets the layout from JSON file
    resetLayout () {
      this.plan.layout = new PlanLayout(sampleLayout, 'Plan')
    },

    // Toggles between regular and advanced mode
    toggleAdvancedMode () {
      const mode = this.isAdvancedMode ? PlannerMode.Regular : PlannerMode.Advanced
      this.setPlannerMode({ mode })
    },

    // Load the plan layout from session store if present,
    // otherwise from defaults
    load () {
      let data, snapshots
      try {
        data = JSON.parse(window.localStorage.getItem('plan-layout')) || sampleLayout
        snapshots = data ? JSON.parse(window.localStorage.getItem('plan-snapshots') || '[]') : null
      } catch {
        data = sampleLayout
        snapshots = []
      }
      return { data, snapshots }
    },

    // Saves the plan layout as JSON file
    async save ({ floor, download } = {}) {
      const { plan: { layout } } = this
      if (layout) {
        if (floor) {
          await this.selectFloor({ floor })
        }

        const { label, id } = layout
        const fileName = `${plainText(label || id)}.json`
        const data = JSON.stringify(layout.serialize(), null, 2)
        window.localStorage.setItem('plan-layout', data)
        Log.debug('Plan saved')
        if (download) {
          await Exporter.toFile(data, fileName, 'application/json')
        }
      }
    },

    // Saves/removes the floor image
    async saveImage ({ renderer, floor, image } = {}) {
      if (floor) {
        if (image) {
          // When on playground, we store the image inline as data URL
          image.content = await renderer.getBackgroundImageData({ binary: false })
          image.mimeType = 'application/base64'
          if (!image.id) {
            image.id = getId('img')
          }
        }
        Log.debug(floor.label, image ? 'Image saved' : 'Image removed')
        return this.save()
      }
    },

    // Saves the plan snapshot
    async saveSnapshot ({ name, description } = {}) {
      const { plan, currentUser: user } = this
      const snapshot = plan.createSnapshot({ name, description, user })
      snapshot.id = getId()
      plan.storeSnapshot({ snapshot })
      window.localStorage.setItem('plan-snapshots', JSON.stringify(plan.snapshots))
      Log.debug(`[${plan.name}] Plan snapshot [${snapshot.name}] saved`, snapshot)
      Notification.success({ message: `Plan snapshot ${snapshot.name.toUpperCase()} has been created` })
    },

    // Deletes the specified plan snapshot
    async deleteSnapshot ({ snapshot } = {}) {
      const { plan } = this
      plan.snapshots = (plan.snapshots || []).filter(s => s.id !== snapshot.id)
      window.localStorage.setItem('plan-snapshots', JSON.stringify(plan.snapshots))
      Log.debug(`[${plan.name}] Plan snapshot [${snapshot.name}] deleted`, snapshot)
      Notification.success({ message: `Plan snapshot ${snapshot.name.toUpperCase()} has been deleted` })
    },

    // Restores the specified plan snapshot
    async restoreSnapshot ({ snapshot } = {}) {
      const user = this.currentUser
      const { plan } = this

      // If restoring the recent snapshot, remove it from snapshots
      if (snapshot.isRecentSnapshot) {
        plan.removeRecentSnapshot()
      } else {
        // Store the current plan as recent snapshot
        const recentSnapshot = plan.createSnapshot({
          name: Plan.RecentSnapshot,
          description: `Plan before restoring the ${snapshot.name.toUpperCase()} snapshot`,
          user
        })
        recentSnapshot.id = getId()
        plan.storeSnapshot({ snapshot: recentSnapshot })
      }
      window.localStorage.setItem('plan-snapshots', JSON.stringify(plan.snapshots))

      // Unload the current plan
      await this.editPlan()

      // Restore the layout from the snapshot
      await delay(500)
      await this.initialize({
        data: snapshot.layout,
        snapshots: plan.snapshots,
        restoredFrom: snapshot,
        restoredAt: new Date()
      })

      Log.debug(`[${plan.name}] Plan snapshot [${snapshot.name}] restored`, snapshot)
      Notification.success({ message: `Plan snapshot ${snapshot.name.toUpperCase()} has been restored` })
    },

    // Triggered when user selects another floor
    async selectFloor ({ floor, save } = {}) {
      if (save) {
        await this.save()
      }
      const id = floor?.id || PlanFloors.CrossSection
      this.updateRoute({ query: { floor: id } })
    },

    // Triggered when user selects cross-section
    async selectCrossSection ({ save } = {}) {
      if (save) {
        await this.save()
      }
      this.updateRoute({ query: { floor: PlanFloors.CrossSection } })
    },

    // Displays help
    showHelp () {
      this.$refs.plan.showHelp()
    },

    // Displays the current plan state
    showState ({ state, label }) {
      this.state = state
      this.stateLabel = label
    },

    // Toggles maximal/normal view
    async toggleMaximize () {
      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen()
        await this.setPlannerView({ isMaximized: false })
      } else if (!document.fullscreenElement && document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
        await this.setPlannerView({ isMaximized: true })
      }
      await this.toggleSidebar({ isCollapsed: this.isMaximized })
    },


    // Toggles debugging
    toggleDebugging () {
      this.isDebugging = !this.isDebugging
      this.$refs.plan.debug(this.isDebugging)
    },

    // Shows the dialog for selecting and editing the background image(s)
    async importPlanImages () {
      this.$refs.plan.importPlanImages()
    }
  },

  // Reload data on navigation to another plan or floor
  async beforeRouteUpdate (to, from, next) {
    const { data, snapshots } = this.load()
    this.initialize({ data, snapshots })
    return next()
  },

  async created () {
    const { data, snapshots } = this.load()
    await this.initialize({ data, snapshots })
    // setTimeout(() => this.importPlanImages(), 200)
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" :noHeader="isMaximized">
    <template #toolbar>
      <div class="q-gutter-xs row items-center">
        <div class="plan-state q-mr-lg bg-green-6 text-white" v-if="stateLabel">
          {{ stateLabel }}
        </div>
        <q-btn v-if="isInitialized && isDebugging && pointerPosition"
          :label="pointerPosition.toString() || ''" unelevated style="width: 120px;">
        </q-btn>
        <q-btn v-if="isInitialized" label="Debug" :outline="!isDebugging" unelevated
          :class="{ warning: isDebugging }" @click="toggleDebugging()"></q-btn>
        <q-btn v-if="isInitialized" label="Revert" unelevated @click="resetLayout()"
          class="q-mr-lg"></q-btn>
        <q-btn v-if="isInitialized" label="Help" icon="help" unelevated @click="showHelp()"></q-btn>
        <q-btn v-if="isInitialized" :label="isAdvancedMode ? 'Advanced Mode' : 'Normal Mode'"
          icon="tune" :class="isAdvancedMode ? 'bg-orange-7 text-white' : undefined" unelevated
          @click="toggleAdvancedMode()"></q-btn>
        <q-btn v-if="isInitialized" label="Maximize" unelevated @click="toggleMaximize()"></q-btn>
        <q-btn v-if="isInitialized" label="Import Plan" unelevated
          @click="importPlanImages()"></q-btn>
        <q-btn v-if="isInitialized" label="Save" unelevated
          @click="save({ download: true })"></q-btn>
        <q-btn label="Close" unelevated @click="cancel()"></q-btn>
      </div>
    </template>

    <sc-plan v-if="plan" ref="plan" :is-debugging="isDebugging" :devices="devices" :place="place"
      :plan="plan" :layout="plan?.layout" :floorId="floorId" :changeInterval="2000"
      :isMaximized="isMaximized" :initialized="(args) => planInitialized(args)"
      :changed="(args) => onChanged(args)" :saved="(args) => save(args)"
      :image-saved="(args) => saveImage(args)" :snapshot-saved="(args) => saveSnapshot(args)"
      :snapshot-deleted="(args) => deleteSnapshot(args)"
      :snapshot-restored="(args) => restoreSnapshot(args)"
      :floor-selected="(args) => selectFloor({ ...args, save: true })"
      :cross-section-selected="(args) => selectCrossSection({ ...args, save: true })"
      :state-changed="(args) => showState(args)">
    </sc-plan>

    <q-btn dense unelevated flat v-if="isMaximized" class="button-minimize" label="Normal View"
      @click="toggleMaximize()"></q-btn>
  </sc-view>
</template>

<style lang="scss" scoped>
.button-minimize {
  position: absolute;
  right: 350px;
  top: 8px;
}

:deep(.q-tab-panel) {
  padding: 0 !important;
}

.plan-state {
  padding: 4px 8px 5px 8px;
  border-radius: 4px;
  font-size: 12px;
}
</style>