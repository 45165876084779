<script>
import { mapActions, mapGetters } from 'vuex'
import { FormMixin, ListAction } from '@stellacontrol/client-utilities'

export default {
  mixins: [
    FormMixin
  ],

  props: [
    'data'
  ],

  data () {
    return {
      ListAction
    }
  },

  computed: {
    ...mapGetters([
      'organizationsInProfileColumns'
    ]),

    organizations () {
      return this.data.organizations || []
    },

    hasOrganizations () {
      return this.organizations.length > 0
    }
  },

  methods: {
    ...mapActions([
      'editOrganization'
    ]),

    async executeAction (organization, action) {
      switch (action.name) {
        case 'edit':
          await this.editOrganization({ organization, drilldown: true })
          break
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}

</script>

<template>
  <div class="container">
    <div class="header q-mb-sm" v-if="!hasOrganizations">
      There are no customers assigned to this profile.
    </div>

    <sc-list
      v-if="hasOrganizations"
      name="organizations-in-profile"
      row-key="id"
      :columns="organizationsInProfileColumns"
      :items="organizations"
      :actions="[ ListAction.Edit ]"
      @action="executeAction">

      <template v-slot:body-cell-name="props">
        <q-td :props="props">
          <router-link class="item-link" :to="{ name: 'organization', params: { id: props.row.id } }">
            {{ props.row.name }}
          </router-link>
        </q-td>
      </template>

    </sc-list>
  </div>
</template>

<style lang='scss' scoped>
.container {
  flex: 1;
}
</style>
