<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { capitalize, range, clone, sortItems } from '@stellacontrol/utilities'
import { Notification } from '@stellacontrol/client-utilities'
import { isMegaParameterApplicable } from '@stellacontrol/mega'
import { getDeviceLabel, AlertType, AlertConfiguration } from '@stellacontrol/model'
import { PortSenseMode } from '@stellacontrol/devices'
import Widget from './widget'
import PortSenseSettingsDialog from '../dialogs/portsense-settings-dialog.vue'

export default {
  mixins: [
    Widget
  ],

  components: {
    'sc-port-sense-settings-dialog': PortSenseSettingsDialog
  },

  props: {
    // Indicates whether the widget is embedded in another component,
    // which impacts the way it resizes itself to available size
    embedded: {
      type: Boolean,
      default: false
    },
    // Indicates whether the header should be visible
    showHeader: {
      type: Boolean,
      default: true
    },
  },

  computed: {
    ...mapState({
      // Alert configurations of the viewed device
      alertConfigurations: state => state.deviceDashboard.alertConfigurations
    }),

    // Indicates whether PortSense feature is available on the device
    isPortSenseAvailable () {
      const { device, status } = this
      return isMegaParameterApplicable('status_portsense', device, status) &&
        status?.health?.hasPortSense
    },

    // Configuration of PortSense feature for the current device
    portsConfiguration () {
      return (this.alertConfigurations || []).find(c => c.alertType === AlertType.PortSense)?.parameters?.ports || []
    },

    // The number of ports on the device
    portCount () {
      return this.device.portCount
    },

    // Device ports
    ports () {
      const { status, portCount, externalAntennaConnected, internalAntennaConnected } = this
      if (!status || portCount === 0) return []

      return [...range(0, portCount + 1)].map(id => {
        const port = this.portsConfiguration.find(p => p.id === id)
        const isExternal = id === 0
        const isInternal = id !== 0
        // Check whether the antenna has been reported by PortSense as connected
        const isConnected = Boolean(isExternal
          ? externalAntennaConnected
          : internalAntennaConnected[id - 1])
        // Check whether the antenna is monitored.
        // When user disabled monitoring but antenna again reported as connected,
        // it is monitored automatically again!
        const isMonitored = Boolean(port?.isMonitored || isConnected)
        const label = port?.label

        return { id, isExternal, isInternal, label, isMonitored, isConnected }
      })
    },

    // Internal antenna ports
    internalPorts () {
      return this.ports.filter(p => p.isInternal)
    },

    // External antenna ports
    externalPorts () {
      return this.ports.filter(p => p.isExternal)
    },

    // Tooltip explaining the whereabouts of the port
    portLabel () {
      return port => port.label || (port.isExternal ? 'Upstream' : `Antenna ${port.id.toString()}`)
    },

    // Tooltip explaining the whereabouts of the port
    portTooltip () {
      return port => {
        const label = this.portLabel(port)
        if (port.isMonitored) {
          return `${capitalize(label)} is monitored, currently ${port.isConnected ? 'connected' : 'disconnected'}.`
        } else {
          return `${capitalize(label)} is disconnected and not monitored.`
        }
      }
    },

    // The current status of the external antenna
    externalAntennaConnected () {
      return this.status?.health?.externalAntenna
    },

    // The current status of the internal antennas
    internalAntennaConnected () {
      return this.status?.health?.internalAntenna || []
    },

    // Indicates whether antenna LEDs are currently enabled
    antennaLEDsOn () {
      return this.status?.health?.antennaLEDsOn
    },

    // Label representing the LED status
    antennaLEDsLabel () {
      return this.antennaLEDsOn ? 'LEDs ON' : 'LEDs OFF'
    },

    // Determines whether the user is allowed to configure the PortSense parameters
    canConfigure () {
      return this.canUseAll([
        'alerts-configure',
        'alert-type-portsense'
      ])
    }
  },

  methods: {
    ...mapActions([
      'updateDeviceSettings',
      'saveDeviceAlertConfigurations',
      'showDialog'
    ]),

    ...mapMutations([
      'storeDeviceAlertConfigurations'
    ]),

    // Shows the editor for the PortSense settings
    async configure () {
      const { device, portCount, ports, antennaLEDsOn } = this
      const { isOk, data } = await this.showDialog({
        dialog: 'portsense-settings-dialog',
        data: {
          device,
          ports: ports.slice(0, portCount + 1),
          antennaLEDsOn
        }
      })

      if (isOk) {
        const { ports, antennaLEDsOn } = data

        // Update the port configurations
        let portsConfiguration = clone(this.portsConfiguration)
        for (const { id, isMonitored, label } of ports) {
          // Ensure that port configuration exists
          let port = portsConfiguration.find(p => p.id === id)
          if (!port) {
            port = { id }
            portsConfiguration.push(port)
          }

          // Store the modified settings
          port.label = label?.trim() || undefined
          port.isMonitored = isMonitored
        }
        portsConfiguration = sortItems(portsConfiguration, 'id')

        // Store the port configurations
        await this.updatePortSenseConfiguration(portsConfiguration)

        // Store antenna LEDs setting
        if (antennaLEDsOn !== this.antennaLEDsOn) {
          await this.setAntennaLEDs(antennaLEDsOn)
        }
      }
    },

    // Changes the work mode of the PortSense,
    // to control the status of antenna LEDs - whether they should be ON or OFF
    async setAntennaLEDs (antennaLEDsOn) {
      const { device } = this
      const parameters = {
        _portsense_mode: antennaLEDsOn
          ? PortSenseMode.AntennaLEDsOn
          : PortSenseMode.AntennaLEDsOff
      }

      await this.updateDeviceSettings({ device, parameters, silent: true })
    },


    // Updates PortSense configuration for the device
    async updatePortSenseConfiguration (ports) {
      const { device } = this
      const alertConfigurations = this.alertConfigurations.map(a => new AlertConfiguration(a))
      const portSenseConfiguration = alertConfigurations.find(c => c.alertType === AlertType.PortSense)

      portSenseConfiguration.parameters = {
        ...portSenseConfiguration.parameters,
        ports
      }

      await this.saveDeviceAlertConfigurations({
        configurations: [portSenseConfiguration],
        devices: [device],
        silent: true
      })

      await this.storeDeviceAlertConfigurations({ device, alertConfigurations })

      Notification.success({
        message: getDeviceLabel(device),
        icon: 'sentiment_satisfied_alt',
        details: 'PortSense configuration has been updated'
      })
    }
  }
}
</script>

<template>
  <sc-widget class="widget-portsense" v-bind="$props" v-if="isPortSenseAvailable">
    <div class="portsense">
      <header class="title row items-center q-mb-sm" v-if="showHeader">
        <span>
          PortSense
        </span>
        <q-space></q-space>
        <div class="button-setup" v-if="canConfigure">
          <q-btn icon="settings" round flat unelevated dense size="13px" color="grey-7"
            @click="configure()">
            <sc-tooltip>
              Click to configure PortSense monitoring
            </sc-tooltip>
          </q-btn>
        </div>
      </header>

      <main>
        <div class="ports internal">
          <div class="port" v-for="port in internalPorts"
            :class="{ 'not-monitored': !port.isMonitored, 'disconnected': !port.isConnected }">
            <div class="led">
            </div>
            <label>
              {{ portLabel(port) }}
            </label>
            <sc-tooltip :text="portTooltip(port)"></sc-tooltip>
          </div>
        </div>

        <div class="ports external">
          <div class="port" v-for="port in externalPorts"
            :class="{ 'not-monitored': !port.isMonitored, 'disconnected': !port.isConnected }">
            <label>
              {{ portLabel(port) }}
            </label>
            <div class="led">
            </div>
            <sc-tooltip :text="portTooltip(port)"></sc-tooltip>
          </div>

          <div class="led-indicator" :class="{ on: antennaLEDsOn }">
            <label :class="{ 'text-green-8': antennaLEDsOn, 'text-grey-8': !antennaLEDsOn }">
              {{ antennaLEDsLabel }}
            </label>
          </div>
        </div>

      </main>
    </div>

    <sc-port-sense-settings-dialog></sc-port-sense-settings-dialog>
  </sc-widget>
</template>

<style lang="scss">
.portsense {
  background-color: transparent;
  border: solid #c8b2a4 0px;
  position: relative;
  display: flex;
  flex-direction: column;

  --gap: 12px;
  --led-size-outer: 18px;
  --led-size-inner: 14px;
  --led-color-border: #6d6d6d;
  --led-color-on: #fbfcff;
  --led-color-off: #909090;
  --led-color-error: #ffe014;
  --label-size: 12px;
  --label-color: #53504e;

  >header {
    flex: 0;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #2a2a2a;
  }

  >main {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .ports {
      align-items: stretch;
      flex-direction: column;
      display: flex;
      white-space: nowrap;
      gap: var(--gap);

      &.internal {
        flex: 1;
      }

      &.external {
        flex: 1;
        justify-content: space-between;
        align-items: end;
      }

      label {
        font-size: var(--label-size);
        color: var(--label-color);
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .led {
        background-color: var(--led-color-border);
        width: var(--led-size-outer);
        height: var(--led-size-outer);
        border-radius: calc(var(--led-size-outer) / 2);
        padding: calc((var(--led-size-outer) - var(--led-size-inner)) / 2);
        box-shadow: 0 0 3px #686868;

        /** LED inside */
        &::after {
          content: "";
          display: block;
          background-color: var(--led-color-on);
          width: var(--led-size-inner);
          height: var(--led-size-inner);
          border-radius: calc(var(--led-size-outer) / 2);
        }
      }

      .port {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--gap);
        position: relative;

        /** Cable connector on the side */
        &::before {
          content: "";
          position: absolute;
          width: 7px;
          height: 8px;
          background: linear-gradient(180deg, #492f1b, #e8b729, #975501);
        }

        &.disconnected:not(.not-monitored) {
          .led::after {
            content: "!";
            color: black;
            font-weight: bold;
            font-size: 16px;
            font-family: 'Courier New', Courier, monospace;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--led-color-error);
          }
        }

        &.not-monitored {
          .led::after {
            background-color: var(--led-color-off);
          }
        }
      }

      &.internal {
        .port {
          max-width: 134px;
        }

        /** Cable connector position for internal ports */
        .port::before {
          left: -12px;
          top: 5px;
        }
      }

      &.external {
        .port {
          max-width: 105px;
        }

        /** Cable connector position for external ports */
        .port::before {
          right: -12px;
          top: 5px;
        }
      }
    }

    .led-indicator {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--gap);
      padding-bottom: 2px;

      .led::after {
        background-color: var(--led-color-off);
      }

      &.on {
        .led::after {
          background-color: var(--led-color-on);
        }
      }

      label {
        text-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .portsense {
    /* Paradoxically, on mobile phone the widget
       can afford larger labels because it's screen-wide */
    --label-size: 14px;
  }
}
</style>
