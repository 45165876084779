import { mapGetters } from 'vuex'
import { PlannerMode } from '@stellacontrol/model'

export default {
  props: {
    // Selector of the container element where the menu is positioned
    container: {
      type: String,
      required: true
    },
    // Menu to show
    menu: {
    }
  },

  data () {
    return {
      // Menu container
      containerElement: null,
      // Menu element
      menuElement: null
    }
  },

  computed: {
    ...mapGetters([
      'planEditingMode'
    ]),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.planEditingMode === PlannerMode.Advanced
    },

    // Determines whether the planner allows only a limited feature set
    isRegularMode () {
      return this.planEditingMode === PlannerMode.Regular
    },

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.planEditingMode === PlannerMode.ReadOnly
    },

    // Determines whether the planner works in edit mode
    isEditable () {
      return !this.isReadOnly
    },

    // Menu position
    position () {
      return this.menu?.position
    },

    // Indicates whether the menu is visible
    isVisible () {
      return this.position != null
    },

    // Menu CSS class
    cssClass () {
      const { isVisible } = this
      return {
        visible: isVisible,
      }
    },

    // Menu CSS style
    cssStyle () {
      const menu = this.$refs.menu
      const { position } = this
      if (menu && position) {
        // Make sure the menu is entirely visible within the menu container
        const { width, height } = this.containerElement.getBoundingClientRect()
        const { offsetWidth: menuWidth, offsetHeight: menuHeight } = menu
        const dx = width - (position.x + menuWidth) - 5
        const dy = height - (position.y + menuHeight) - 5
        if (dx < 0) position.moveBy({ x: dx })
        if (dy < 0) position.moveBy({ y: dy })

        return {
          'left': `${position.x}px`,
          'top': `${position.y}px`,
        }
      }
    },

    // CSS class of an action button
    buttonClass () {
      return (action) => ({
        action: true,
        [action.name]: true
      })
    },
  },

  emits: [
    'execute'
  ],

  watch: {
    isVisible () {
      this.containerElement = document.querySelector(this.container)
      if (!this.containerElement) {
        throw new Error(`Menu container ${this.container} not found`)
      }
      this.menuElement = this.$refs.menu
    }
  },

  methods: {
    // Signals that the user wants to execute the specified action on the item
    execute ({ action, items }) {
      this.$emit('execute', { action, items })
    }
  }
}
