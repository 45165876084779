<script>
import { isToday } from 'date-fns'
import { mapActions } from 'vuex'
import { Log, formatDateTime, findBiggestValue, findSmallestValue, merge } from '@stellacontrol/utilities'
import { Color, Notification } from '@stellacontrol/client-utilities'
import { getBandLabel, ChartSeries, getDeviceBandIdentifiers } from '@stellacontrol/model'
import { getMegaParameter, getBandMegaParameter } from '@stellacontrol/mega'
import { DeviceAuditEventType } from '@stellacontrol/devices'

const ColorPalette = [
  'blue',
  'green',
  'orange',
  'purple',
  'magenta',
  'cyan',
  'indigo',
  'brown'
]

const NO_DATA = {
  labels: [],
  series: [],
  annotations: [],
  options: {}
}

export default {
  props: {
    // Chart rendering options
    options: {
      type: Object,
      default: () => { }
    },

    // Device to view
    device: {
    },

    // Selected period
    period: {
    },

    // Parameters to show
    parameters: {
    },

    // Extras to show, such as alerts, firmware updates etc.
    extras: {
    }
  },


  data () {
    return {
      // Chart data
      chartData: NO_DATA,
      // Indicates whether the component is still initializing
      isInitializing: true,
      // Indicates whether the chart is currently populating
      isPopulating: false
    }
  },

  computed: {
    // List of parameter details for the selected parameters
    parameterDetails () {
      const { device, parameters } = this
      return parameters.map(name => getMegaParameter(name, device))
    },

    // List of parameters applicable to the currently selected devices.
    // It can happen that parameter selected while viewing one device,
    // is not present on another device.
    applicableParameters () {
      const { device, parameterDetails } = this
      const deviceBands = getDeviceBandIdentifiers(device) || []

      return parameterDetails
        .filter(p => p.isApplicable)
        .filter(p => {
          if (p.isBandParameter) {
            return deviceBands.some(id => p.band === id)
          } else {
            return true
          }
        })
        .map(p => p.name)
    },

    // Returns true if all input parameters are valid and chart can be populated
    canPopulate () {
      const { device, period, applicableParameters, extras } = this
      return device &&
        period?.isValid &&
        (applicableParameters?.length > 0 || extras?.length > 0)
    },

    // Additional elements to show on the chart
    withSettings () {
      return this.extras?.includes(DeviceAuditEventType.SettingsChanged)
    },

    withCommands () {
      return this.extras?.includes(DeviceAuditEventType.CommandSent)
    },

    withAlerts () {
      return this.extras?.includes(DeviceAuditEventType.Alert)
    },

    withUpdates () {
      return this.extras?.includes(DeviceAuditEventType.FirmwareUpdated)
    },

    decimate () {
      return !this.extras?.includes('nodecimate')
    }
  },

  methods: {
    ...mapActions([
      'getDeviceHistory'
    ]),

    // Loads device history data
    async getHistory () {
      const { device, period, applicableParameters: parameters, withSettings, withCommands, withAlerts, withUpdates, decimate } = this

      try {
        const { history } = await this.getDeviceHistory({
          id: device.id,
          serialNumber: device.serialNumber,
          from: period.from,
          to: period.to,
          decimate: decimate ? 7 * 24 * 6 : undefined,
          withStatus: parameters?.length > 0,
          withParameters: parameters,
          withSettings,
          withCommands,
          withAlerts,
          withUpdates
        }) || {}

        return history
      } catch (error) {
        Log.error('Error retrieving device history', { device: device.serialNumber, period, parameters, withSettings, withCommands, withAlerts, withUpdates })
        Log.exception(error)
        Notification.warning({ message: 'Could not retrieve device history. Please try again with a shorter period.' })
      }
    },

    // Returns color for series representing the specified mega parameter
    getSeriesColor (parameter, index) {
      if (parameter.color) {
        const color = Color.getColor(parameter.color)
        if (parameter.band) {
          return Color.lighten(color, parameter.bandIndex * 10)
        } else {
          return Color.getColor(color)
        }
      } else {
        const color = (parameter.bandIndex == null)
          ? Color.getColor(ColorPalette[index % ColorPalette.length])
          : Color.getColor(ColorPalette[parameter.bandIndex])
        return color
      }
    },

    // Creates chart series representing the specified chart parameter,
    // populated with device historical data
    getSeries (name, index, history) {
      const { device } = this
      const parameter = getBandMegaParameter(name, device) || getMegaParameter(name, device)
      const color = this.getSeriesColor(parameter, index)
      if (parameter) {
        const description = parameter.band
          ? `${parameter.label}: ${getBandLabel(parameter.band, device.family)}`
          : `${parameter.label}`

        const unit = parameter.getValueUnit()
        const label = parameter.band
          ? `${parameter.label}${unit ? ', ' : ''}${unit || ''}: ${getBandLabel(parameter.band, device.family)}`
          : `${parameter.label}${unit ? ', ' : ''}${unit || ''}`

        const data = this.getSeriesData(parameter, history)
        const type = parameter.isBoolean || parameter.isEnumeration ? 'scatter' : 'line'
        const radius = parameter.isBoolean || parameter.isEnumeration ? 7 : undefined
        const borderColor = color
        let backgroundColor
        if (parameter.isBoolean) {
          backgroundColor = (context, options) => (context.raw?.value == null || context.raw?.value === 1)
            ? parameter.getValueColor(context.raw?.value, options.color)
            : parameter.getValueColor(context.raw?.value, 'white')
        } else if (parameter.isEnumeration) {
          backgroundColor = (context) => parameter.getValueColor(context.raw?.value, 'white')
        } else {
          backgroundColor = color
        }

        const series = new ChartSeries({
          type,
          name,
          description,
          label,
          unit,
          data,
          isTimeSeries: true,
          parsing: false,
          backgroundColor,
          borderColor,
          radius
        })
        return series
      }
    },

    // Returns data for chart series representing the specified chart parameter
    getSeriesData (parameter, history) {
      const data = []
      let previousStatus

      for (const item of history) {
        let x = item.time.getTime()

        // Get the value, but the one applicable for displaying.
        // Some parameters are shown using a different unit than the unit with which they're stored,
        // i.e.`uptime` parameter stored in seconds but shown in hours.
        let value = item.values ? item.values[parameter.name] : null
        let displayValue = parameter.getDisplayValue(value)

        if (item.type === DeviceAuditEventType.Status) {
          if (displayValue != null) {
            previousStatus = item
          } else {
            // If status does not have the value for parameter, assume the same value
            value = previousStatus?.values[parameter.name]
            displayValue = parameter.getDisplayValue(value)
          }
        }

        // Some values are skipped, for example `false` booleans on indicators such as `feedback`
        if (displayValue != null && parameter.isValueVisibleOnHistoryGraphs(displayValue)) {
          // Booleans aren't shown as lines, but as dots on level-zero with or without background color, depending on value.
          // Discrete strings with color coding are shown as dots on level-zero, with background color reflecting the value
          let y
          if (parameter.isBoolean) {
            y = 0
          } else if (parameter.isEnumeration && parameter.colors?.length === parameter.values?.length) {
            y = 0
          } else {
            y = displayValue
          }

          // We show the series using display values.
          // Still, store the raw value, as it might be needed when evaluating tooltips etc.
          data.push({
            x,
            y,
            value: displayValue,
            rawValue: value
          })
        }
      }

      return data
    },

    // Returns labels for device history points
    getDataLabels (history) {
      const labels = history
        .map(item => formatDateTime(item.time, isToday(item.time) ? 'HH:mm' : 'MM/dd HH:mm'))
      return labels
    },

    // Returns chart scales
    getScales () {
      let min = findSmallestValue(this.parameterDetails, 'min')
      let max = findBiggestValue(this.parameterDetails, 'max')

      const scale = {
        type: 'linear',
        display: true
      }

      if (min != null) {
        scale.suggestedMin = min - Math.min(1, 0.3 * min)
      }

      if (max != null) {
        scale.suggestedMax = max + Math.min(1, 0.3 * max)
      }

      if (scale.suggestedMin == null) {
        scale.suggestedMin = 0
      }

      if (scale.suggestedMax == null) {
        scale.suggestedMax = 1
      }

      return {
        y: scale
      }
    },

    // Returns additional series for alerts, updates, settings etc.
    getExtraSeries (history, scales) {
      const { withSettings, withCommands, withAlerts, withUpdates } = this
      if (!(withSettings || withCommands || withAlerts || withUpdates)) {
        return []
      }

      const series = []

      // Dot size
      const radius = 8

      const settings = new ChartSeries({
        type: 'scatter',
        name: DeviceAuditEventType.SettingsChanged,
        dataType: DeviceAuditEventType.SettingsChanged,
        isTimeSeries: true,
        parsing: false,
        label: 'Settings',
        backgroundColor: (context) => {
          const parameter = getMegaParameter(context?.raw?.parameter)
          return (parameter && parameter.isBoolean)
            ? parameter.getValueColor(context?.raw?.value, 'green')
            : 'green'
        },
        borderColor: 'transparent',
        radius
      })

      const commands = new ChartSeries({
        type: 'scatter',
        name: DeviceAuditEventType.CommandSent,
        dataType: DeviceAuditEventType.CommandSent,
        isTimeSeries: true,
        parsing: false,
        label: 'Commands',
        backgroundColor: 'blue',
        borderColor: 'transparent',
        radius
      })

      const updates = new ChartSeries({
        type: 'scatter',
        name: DeviceAuditEventType.FirmwareUpdated,
        dataType: DeviceAuditEventType.FirmwareUpdated,
        isTimeSeries: true,
        parsing: false,
        label: 'Firmware Updates',
        backgroundColor: 'orange',
        borderColor: 'transparent',
        radius
      })

      const alerts = new ChartSeries({
        type: 'scatter',
        name: DeviceAuditEventType.Alert,
        dataType: DeviceAuditEventType.Alert,
        isTimeSeries: true,
        parsing: false,
        label: 'Alerts',
        backgroundColor: 'red',
        borderColor: 'transparent',
        radius
      })

      // Place markers on the bottom of the chart
      const y = scales.y.suggestedMin || 0

      for (const item of history) {
        let x = item.time.getTime()

        if (withCommands && item.type === DeviceAuditEventType.CommandSent) {
          commands.add({ x, y, command: item.name, user: item.user })

        } else if (withSettings && item.type === DeviceAuditEventType.SettingsChanged) {
          settings.add({ x, y, parameter: item.name, value: item.value, user: item.user, reconciled: item.reconciled })

        } else if (withUpdates && item.type === DeviceAuditEventType.FirmwareUpdated) {
          const { hardwareVersion, firmwareVersion, eepromVersion } = item
          updates.add({ x, y, hardwareVersion, firmwareVersion, eepromVersion, user: item.user })

        } else if (withAlerts && item.type === DeviceAuditEventType.Alert) {
          alerts.add({ x, y, alertType: item.alert, message: item.message })
        }
      }

      series.push(commands)
      series.push(settings)
      series.push(updates)
      series.push(alerts)

      return series.filter(s => !s.isEmpty)
    },

    // Returns annotations for additional events
    getAnnotations (history, scales) {
      if (history && scales) {
        const annotations = {}

        /* Example annotation
        const annotation = {
          type: 'point',
          xValue: 1,
          yValue: 2,
          backgroundColor: 'rgba(255, 99, 132, 0.25)'
        }
        annotations['123'] = annotation
        */

        return annotations
      }
    },

    // Returns data point tooltip
    getTooltip (context) {
      const { device } = this
      const { dataset, raw } = context
      if (!(device && dataset && raw)) return

      // Obtain tooltip parts for the event
      let parameter
      let showUser = false
      const afterTitle = `${formatDateTime(new Date(raw.x))}`

      let title, label, parameterLabel, valueLabel, valueUnit
      switch (dataset.dataType) {
        // Command events
        case DeviceAuditEventType.CommandSent:
          title = 'Command Sent'
          label = ` ${raw.command}`
          break

        // Device Settings events
        case DeviceAuditEventType.SettingsChanged:
          title = raw.reconciled ? 'Settings Reconciled' : 'Settings Changed By User'
          parameter = getMegaParameter(raw.parameter, device)
          parameterLabel = ` ${parameter.label}${parameter.band ? ' (' + getBandLabel(parameter.band, device.family) + ')' : ''}`
          valueLabel = parameter.getValueLabel(raw.value)
          valueUnit = parameter.getValueUnit()
          label = ` ${parameterLabel}: ${valueLabel} ${valueUnit}`
          showUser = !raw.reconciled
          break

        // Alert events
        case DeviceAuditEventType.Alert:
          title = 'Alert'
          label = ` ${raw.message}`
          break

        // Firmware update events
        case DeviceAuditEventType.FirmwareUpdated:
          title = 'Firmware Updated'
          label = ` FW: ${raw.firmwareVersion}`
          break

        default:
          // All other data points
          title = 'Device Status'
          parameter = getMegaParameter(dataset.name, device)
          parameterLabel = ` ${parameter.label}${parameter.band ? ' (' + getBandLabel(parameter.band, device.family) + ')' : ''}`
          valueLabel = parameter?.getValueLabel(raw.rawValue) || raw.value
          valueUnit = parameter?.getValueUnit()
          label = ` ${parameterLabel}: ${valueLabel} ${valueUnit}`
      }

      const footer = showUser && raw.user ? `User: ${raw.user}` : undefined

      return { title, afterTitle, label, footer }
    },

    // Loads chart data and populates the chart
    async populate () {
      if (this.isPopulating) return

      this.chartData = NO_DATA
      const { canPopulate, applicableParameters } = this

      if (canPopulate) {
        try {
          this.isPopulating = true
          const history = await this.getHistory()
          if (history) {
            const labels = this.getDataLabels(history)
            const series = applicableParameters.map((name, index) => this.getSeries(name, index, history))
            const scales = this.getScales(series)
            const annotations = this.getAnnotations(history, scales)
            const extras = this.getExtraSeries(history, scales)

            if (extras?.length > 0) {
              series.push(...extras)
            }

            this.chartData = {
              labels,
              series,
              annotations,
              options: merge(this.options, { scales })
            }
          }
        } finally {
          if (this.isInitializing) {
            this.isInitializing = false
            this.$emit('initialized')
          }
          this.isPopulating = false
        }
      }
    },

    // Resets chart zoom
    resetZoom () {
      this.$refs.chart?.resetZoom()
    }
  },

  emits: [
    'initialized'
  ]
}
</script>

<template>
  <main>
    <sc-line-chart ref="chart" v-if="!isPopulating" :labels="chartData.labels" :period="period"
      :series="chartData.series" :annotations="chartData.annotations" :options="chartData.options"
      :decimation="true" :data-labels="false" :tooltips="getTooltip">
    </sc-line-chart>

    <q-inner-loading :showing="isPopulating">
      <span class="text-black title q-mb-md">
        Loading the history of {{ device.acronym }}, please wait ...
      </span>
      <q-spinner-gears size="48px" color="grey-8"></q-spinner-gears>
    </q-inner-loading>
  </main>
</template>

<style scoped lang="scss">
main {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>

<style>
.chart-reset-zoom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 120px;
}
</style>