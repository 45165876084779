<script>
import { capitalizeWords } from '@stellacontrol/utilities'
import { GeoCoordinates } from '@stellacontrol/model'
import { getMegaParameter, getMegaValue, isMegaParameterApplicable, getMegaParameterLabel, getMegaParameterPermissions } from '@stellacontrol/mega'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  computed: {
    // Device label
    deviceLabel () {
      return this.device.serialNumber
    },

    // Device model
    deviceModel () {
      const { device, currentUser } = this
      return device.getModel(currentUser)
    },

    // Widget icon
    widgetIcon () {
      const { device, status } = this
      if (!status) return 'info'
      if (status?.identity?.isShip) return 'directions_boat'
      if (device.isTestTool) return 'radar'
      return 'home'
    },

    // MEGA
    mega () {
      return this.status?.mega
    },

    // Status parameters to display in the detailed view
    parameters () {
      const { device, status, mega = {}, ticks } = this
      if (device && status && mega && ticks >= 0) {
        const items = [
          // TODO: old-style definitions => refactor as per below new-style to use MEGA definitions from @stellacontrol/mega package
          { label: 'ID', value: device.id, permissions: ['super-administrator'] },
          { label: 'Serial', value: device.serialNumber },
          { parameter: 'sw_version', label: 'FW', value: status.identity.firmwareVersionLong },
          { parameter: 'product_model' },
          { parameter: 'product_bands' },
          { parameter: 'gps' },
          { parameter: 'ip_server' },
          { parameter: 'ip_local' },
          { parameter: 'mac' },
          { parameter: 'sim_iccid' },
          { parameter: 'bypass' },
          { parameter: '_default_sampling_speed' },
          { parameter: '_timer_long_mins' },
          { parameter: 'reboot_count_sw' },
          { parameter: 'reboot_count_hw' },
          { parameter: 'temperature' },
          { parameter: 'battery' },
          { parameter: '_rf_region' },
          { parameter: 'rf_disabled' },
          { parameter: '_tft_hostname' },
          { parameter: 'uptime', label: 'Uptime', value: status.timings.uptimeString }
        ]

        return items
          // Fix labels, determine values, filter out empty values
          .filter(item => {
            if (!item) return false
            // Take parameter label from MEGA definitions, if not specified explicitly
            if (!item.label) {
              item.label = getMegaParameterLabel(item.parameter, device)
            }
            item.label = capitalizeWords(item.label, true)
            // Determine current value of the item
            if (item.parameter && !item.value) {
              const value = getMegaValue(item.parameter, mega, device, status)?.label
              item.value = item.format ? item.format(value, item.unit) : value
            }
            const { isGeo } = getMegaParameter(item.parameter) || {}
            return isGeo
              ? GeoCoordinates.isValid(item?.value)
              : (item?.value != null && item.value != '')
          })
      } else {
        return []
      }
    },

    // Status parameters visible to the current user
    visibleParameters () {
      const { device, status } = this
      return this.parameters
        // Check whether user has permission to see the MEGA parameter
        .filter(item => {
          const permissions = (item.parameter && !item.permissions)
            ? getMegaParameterPermissions(item.parameter, device, status)
            : item.permissions || []
          return this.guardian.canUseAll(permissions)
        })
        // Check if MEGA parameter is applicable to the currently displayed device
        .filter(item => item.parameter ? isMegaParameterApplicable(item.parameter, device, status) : true)
    }
  }
}
</script>

<template>
  <div class="widget mini-widget device-summary">
    <header class="row items-center no-wrap pointer">
      <q-icon size="36px" :name="widgetIcon" color="indigo-6">
      </q-icon>
      <div class="column justify-center q-ml-sm">
        <span>{{ deviceModel }}</span>
        <span>{{ deviceLabel }}</span>
      </div>
      <q-space></q-space>

      <q-btn round flat dense size="11px" icon="more_horiz">
      </q-btn>

      <q-popup-proxy>
        <main class="details">
          <header class="bg-indigo-7 text-white text-bold q-pa-sm row items-center">
            <span>
              Device Parameters
            </span>
            <q-space></q-space>
            <q-btn size="sm" round flat dense icon="close" color="white" v-close-popup>
            </q-btn>
          </header>

          <div class="item q-pa-sm" v-for="(parameter, index) in visibleParameters" :key="index">
            <span class="name" :title="parameter.label">
              {{ parameter.label }}
            </span>
            <span class="value">
              {{ parameter.value }} {{ parameter.unit }}
            </span>
          </div>
        </main>
      </q-popup-proxy>
    </header>
  </div>

</template>

<style lang="scss" scoped>
.details {
  overflow: hidden;
  overflow-y: auto;
  max-height: 600px;
  min-width: 250px;
  background-color: #f8f8ff;
  --font-size-item: 13px;

  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: solid #dddddd 1px;

    &:hover {
      background-color: #f1f1ff;
    }

    &:last-child {
      border-bottom: none;
    }

    .name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: var(--font-size-item);
      color: #222222;
    }

    .value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: var(--font-size-item);
      color: #1c1c1c;
      padding-right: 2px;
    }

    &.present {

      .name,
      .value {
        color: #304ffe;
      }
    }

    &.absent {

      .name,
      .value {
        color: #7a7a7a;
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (width <=640px) {
  .details {
    --font-size-item: 15px;
    width: 90vw;
    height: 90vh;
    max-height: unset;
  }
}
</style>
