<script>
import { mapActions, mapGetters } from 'vuex'
import { DialogMixin, Confirmation } from '@stellacontrol/client-utilities'
import { getUTCDateTime } from '@stellacontrol/utilities'
import { Attachment, AttachmentType, isAllowedAttachmentType, AttachmentTypeDescription } from '@stellacontrol/model'

const dialog = 'document-upload'

export default {
  mixins: [
    DialogMixin
  ],

  data () {
    return {
      // Dialog title
      title: 'Upload documents',
      // OK button label
      okLabel: 'OK',
      // Cancel button label
      cancelLabel: 'Cancel',
      // If true, multiple file uploads are allowed
      allowMultiple: false,
      // Maximal allowed number of files to upload
      maxFiles: 1,
      // Maximal allowed size of files to upload
      maxFileSize: 100000000,
      // Label to display on file selector control
      selectFilesLabel: 'Select files to upload ...',
      // Newly selected files, array of File objects
      files: null,
      // Currently selected documents, array of Attachment objects
      documents: null,
      // Documents removed by the user
      removedDocuments: [],
      // Notes to store
      notes: null,
      // Dialog identifier
      dialog,
      // Rejected files
      invalidFiles: null,
      // true when in the process of taking a picture
      takingPicture: false
    }
  },

  computed: {
    ...mapGetters([
      'isMobilePhone'
    ]),
    // Checks whether there are any documents
    hasDocuments () {
      return this.documents?.length > 0
    },

    // Returns all new files have been selected for upload
    newDocuments () {
      return this.documents?.filter(d => d.isNew && (d.file || d.content))
    },

    // Checks whether any new files have been selected for upload
    hasNewDocuments () {
      return this.newDocuments?.length > 0
    },

    // Indicates whether notes can be edited for the document
    hasNotes () {
      return this.notes != null
    },

    // Label describing the selected files which are not allowed
    invalidFilesLabel () {
      const { invalidFiles } = this
      return invalidFiles?.length > 0
        ? `Only the following file types are  permitted: ${Object.values(AttachmentTypeDescription).join(', ')}`
        : null
    }
  },

  watch: {
    files () {
      this.refreshDocuments()
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'openUrl',
      'getAttachmentUrl'
    ]),

    // Updates the list of attachments when files are added or removed
    async refreshDocuments () {
      const { files } = this
      for (const file of files || []) {
        // Filter out unsupported file types
        if (!isAllowedAttachmentType(file.name)) continue
        // Do not allow duplicate uploads of the same file
        if (this.documents.find(d => d.name === file.name)) continue
        const document = await Attachment.fromFile(file)
        this.documents.push(document)
      }
      this.files = null
    },

    // Removes the specified document or newly added file
    async removeDocument (document) {
      if (document) {
        const yes = await Confirmation.ask({ title: 'Remove document', message: `Remove document ${document.name}?` })
        if (yes) {
          this.documents = this.documents.filter(d => d.id
            ? d.id !== document.id
            : d.name !== document.name)
          if (document.id) {
            this.removedDocuments.push(document)
          }
        }
      }
    },

    // Opens the specified document
    async openDocument (attachment) {
      if (attachment && attachment.id) {
        const url = await this.getAttachmentUrl({ attachment })
        this.openUrl({ url })
      }
    },

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      this.files = []
      this.removedDocuments = []
      this.title = this.data.title || this.title
      this.okLabel = this.data.okLabel || this.okLabel
      this.cancelLabel = this.data.cancelLabel || this.cancelLabel
      this.allowMultiple = this.data.allowMultiple || this.allowMultiple
      this.maxFiles = this.data.maxFiles || this.maxFiles
      this.maxFileSize = this.data.maxFileSize || this.maxFileSize
      this.selectFilesLabel = this.data.selectFilesLabel || this.selectFilesLabel
      this.notes = this.data.notes
      this.documents = this.data.documents || []
    },

    // Validates and OKs the dialog
    async ok () {
      const { newDocuments, removedDocuments, notes } = this
      const data = { newDocuments, removedDocuments, notes }
      this.dialogOk({ dialog, data })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    },

    // Opens file picker
    addFiles () {
      this.invalidFiles = null
      this.$refs.filePicker.pickFiles()
    },

    // Validates the selected files
    validateFiles (files) {
      if (files) {
        const allowedFiles = files.filter(file => isAllowedAttachmentType(file.name))
        this.invalidFiles = files.filter(file => !isAllowedAttachmentType(file.name))
        return allowedFiles
      }
    },

    // Uploads an image taken with mobile cam
    async uploadImage (image) {
      this.takingPicture = false
      const response = await fetch(image)
      const content = await response.arrayBuffer()
      const now = getUTCDateTime()
      const document = new Attachment({
        name: 'photo-' + now.getTime() + '.jpg',
        description: 'Photo taken on ' + new Date().toLocaleString('en-US'),
        type: AttachmentType.Image,
        mimeType: 'image/jpeg',
        createdAt: now,
        updatedAt: now,
        size: content.byteLength,
        file: new Blob([content]),
        isNew: true
      })
      this.documents.push(document)
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()">
    <q-form v-if="takingPicture" class="photo-form">
      <sc-photo-shot @cancel="takingPicture = false" @image="uploadImage($event)"></sc-photo-shot>
    </q-form>
    <q-form v-else ref="form" :class="['form', isMobilePhone ? 'mobile' : 'desktop']">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-lg">
        <div v-if="hasNotes"
             class="notes">
             <q-input v-model="notes" type="textarea" dense square outlined class="note"
             label="Notes and remarks">
          </q-input>
        </div>

        <div class="row q-mt-md" v-if="hasDocuments">
          <template v-for="document of documents" :key="document.name">
            <q-chip text-color="indigo-9" icon="attachment" class="document"
              :color="document.isNew ? 'green-2' : 'indigo-1'" removable
              :clickable="!document.isNew" @remove="removeDocument(document)"
              @click="openDocument(document)">
              {{ document.name }}
            </q-chip>
          </template>
        </div>

        <div class="buttons row items-center q-mt-lg">
          <q-btn unelevated no-wrap class="success" icon="attach_file"
            :label="isMobilePhone ? '' : selectFilesLabel" @click="addFiles()" />
          <q-btn v-if="isMobilePhone" unelevated no-wrap icon="add_a_photo" class="success"
            @click="takingPicture = true" />
          <q-file dense bordeless ref="filePicker" :max-files="maxFiles" class="file-picker"
            :max-file-size="maxFileSize" :multiple="allowMultiple" append v-model="files"
            :filter="files => validateFiles(files)">
          </q-file>

          <div class="invalid-files q-ml-md q-mr-lg q-pa-sm text-white bg-red-5"
               v-if="invalidFilesLabel">
            {{ invalidFilesLabel }}
          </div>

          <q-space v-else></q-space>

          <q-btn :label="cancelLabel" unelevated class="q-mr-md" @click="cancel()"></q-btn>
          <q-btn :label="okLabel" unelevated class="primary" @click="ok()"></q-btn>
        </div>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.photo-form {
  width: 100vw;
  height: calc(100vh - 108px);
  /* c/p from quasar-ui */
}

.form.desktop {
  min-width: 850px;
  max-width: 850px;
}

.form.mobile {
  width: 100vw;

  .buttons.row {
    justify-content: space-between;
    gap: 6px;
  }
}

.title {
  font-size: 18px;
}

.button-select-all {
  min-width: 140px;
}

.band-button {
  min-width: 70px;
  width: 70px;
}

.band-label {
  font-size: 10px;
  text-align: center;
}

.notes {
  flex: 1;
  height: 300px;
  display: flex;
  flex-direction: column;

  .note {
    height: 100%;
  }

  :deep(.q-textarea) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  :deep(.q-field__control) {
    height: 100%;
    flex: 1;
  }
}

.documents {
  width: 100%;

  .q-td.size {
    text-align: right;
    max-width: 120px;
  }

  .q-td.actions {
    text-align: right;
    max-width: 120px;
  }
}

.file-picker {
  display: none;
}

.invalid-files {
  flex: 1;
}
</style>