/**
 * Client state mutations
 */
export const mutations = {
  /**
   * Sets the application details, first step in bootstrapping the application
   */
  setApplication (state, pkg = {}) {
    const { isApplet, name, version, description } = pkg
    state.pkg = pkg
    state.application.isApplet = isApplet
    state.application.name = name
    state.application.description = description
    state.application.version = version
  },

  /**
   * Marks the client as initializing
   */
  clientInitializing (state) {
    state.isInitialized = false
  },

  /**
   * Initializes the client with the specified context
   * @param {Object} state Current state
   * @param {Environment} environment Application environment
   * @param {Configuration} configuration Application configuration
   * @param {Origin} origin Application origin
   * @param {ApplicationFlags} flags Application flags
   */
  initializeClient (state, { environment, origin, configuration, flags } = {}) {
    // Put everything from context in the state
    // Object.assign(state, context)
    state.environment = environment
    state.origin = origin
    state.configuration = configuration
    state.flags = flags

    // Overwrite application if custom name and details received with configuration
    const { name, description, version, url } = configuration.application
    if (name) {
      state.application.name = name || state.application.name
      state.application.description = description || state.application.description
      state.application.version = version || state.application.version
      state.application.url = url || window.location.toString()
    }
  },

  /**
   * Ticks the client ticker
   * @param state Current state
   */
  tick (state) {
    state.ticker++
  },

  /**
   * Marks the client as initialized
   */
  clientInitialized (state) {
    state.isInitialized = true
  },

  /**
   * Marks the client as failed during initialization
   */
  initializationFailed (state) {
    state.isInitialized = false
    state.initializationFailed = true
  },

  /**
   * Triggered when the application is unloaded
   */
  unloadApplication (state) {
    state.isUnloading = true
  },

  /**
   * Report an unhandled error
   * @param error Exception
   * @param message User-friendly message
   */
  error (state, { error, message } = {}) {
    if (error) {
      state.error = {
        exception: error,
        message
      }
    } else {
      state.error = {
        exception: null,
        message: null
      }
    }
  },

  /**
   * Indicates start of a long-lasting activity,
   * displays progress notification if message is specified
   * @param {String} action Action with which we're busy, unique code
   * @param {String} message User-friendly message describing the activity
   * @param {String} details More detailed description of the activity
   * @param {Object} data Data associated with the activity
   * @param {Boolean} hideNotification Callback to close the pending notification
   */
  busy (state, { action, message, details, data, hideNotification } = {}) {
    state.busy.now = true
    state.busy.startedAt = new Date()
    state.busy.finishedAt = null
    state.busy.action = action
    state.busy.message = message
    state.busy.details = details
    state.busy.data = data
    state.busy.hideNotification = hideNotification
  },

  /**
   * Indicates start of a loading activity,
   * displays progress notification if message is specified
   * @param message User-friendly message describing the activity
   * @param details More detailed description of the activity
   * @param data Data associated with the activity
   * @param hideNotification Callback to close the pending notification
   */
  loading (state, { message, details, data, hideNotification } = {}) {
    state.busy.now = true
    state.busy.isLoading = true
    state.busy.startedAt = new Date()
    state.busy.finishedAt = null
    state.busy.action = 'load'
    state.busy.message = message
    state.busy.details = details
    state.busy.data = data
    state.busy.hideNotification = hideNotification
  },

  /**
   * Indicates the end of a running activity, started with `busy` action.
   * Displays success or failure notification if message or error is specified.
   * @param message User-friendly message to report with end of the activity
   * @param warning Warning to report with end of the activity
   * @param error Error to report with end of the activity
   * @param details More detailed description of the activity
   * @param data Data associated with end of the activity
   */
  done (state, { message, warning, error, details, data } = {}) {
    state.busy.now = false
    state.busy.isLoading = false
    state.busy.finishedAt = new Date()
    state.busy.duration = (state.busy.finishedAt - state.busy.startedAt)
    state.busy.message = message || state.busy.message
    state.busy.warning = warning
    state.busy.error = error
    state.busy.details = details || state.busy.details
    state.busy.data = data || state.busy.data
    state.busy.hideNotification = null
  },

  /**
   * Redirects to another URL
   * @param message User-friendly message describing the redirection
   */
  redirectToUrl (state, { message, url } = {}) {
    state.redirecting.message = message
    state.redirecting.url = url
  },

  /**
   * Performs a full page reload
   * @param message User-friendly message describing the reloading
   */
  reload (state, { message } = {}) {
    state.reloading.message = message
    state.reloading.url = window.location
  },

  /**
   * Notifies that the application flags have been changed
   * @param {ApplicationFlags} flags New application flags
   */
  applicationFlagsChanged (state, { flags }) {
    state.flags = flags
  },

  /**
   * Notifies about the result of detecting an ad blocker
   * @param {Boolean} isActive If `true`, indicates an active ad blocker
   */
  adBlock (state, { isActive }) {
    state.adBlockActive = isActive
  }
}
