import { safeParseInt } from '@stellacontrol/utilities'
import { DeviceConnectionStatus, DeviceConnectionQuality, DeviceConnectionStatusColor, DeviceConnectionStatusName, DeviceRegion } from '@stellacontrol/model'
import { DeviceStatusTimings } from './device-status-timings'

/**
 * Details of device connection status
 */
export class DeviceStatusConnection {
  /**
   * Initializes the instance
   * @param serialNumber Device serial number
   * @param mega Live device data retrieved from Shadow API
   * @param data Initial values of the properties
   */
  constructor (serialNumber, mega, data = {}) {
    this.serialNumber = serialNumber
    Object.assign(this, data)
    if (mega) {
      this.parse(mega)
    }
  }

  /**
   * Creates am instance populated with deserialized data
   */
  static from (data = {}) {
    const status = new DeviceStatusConnection()
    Object.assign(status, data)
    return status
  }

  /**
   * MEGA message version
   * @type {String}
   */
  megaVersion

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * Status of device connection (online, offline etc.)
   * @type {DeviceConnectionStatus}
   */
  status

  /**
   * Device operating mode
   * @type {DeviceOperatingMode}
   */
  operatingMode

  /**
   * Default message send frequency
   * @type {MessageFrequency}
   */
  messageFrequency

  /**
   * Device reset frequency
   * @type {Number}
   */
  rebalanceFrequency

  /**
   * Quality of device connection (good, bad etc.)
   * @type {DeviceConnectionQuality}
   */
  quality

  /**
   * Human-friendly status description
   * @type {String}
   */
  description

  /**
   * Human-friendly status description, shorter version
   * @type {String}
   */
  shortDescription

  /**
   * Connection error
   * @type {String}
   */
  error

  /**
   * Indicates that RF has been disabled on the device
   * @type {Boolean}
   */
  isRFDisabled

  /**
   * Device operational region
   * @type {DeviceRegion}
   */
  region

  /**
   * Indicates whether device region is controlled manually by device owner / installer
   * @type {Boolean}
   */
  isManualRegionControl

  /**
   * Indicates that RF has been disabled on the device
   * @type {Boolean}
   */
  get isRFOff () {
    return this.isRFDisabled || this.region === DeviceRegion.Off
  }

  /**
   * Returns true if status is unknown yet
   * @type {Boolean}
   */
  get isUnknown () {
    return !this.status || this.status === DeviceConnectionStatus.Unknown
  }

  /**
   * Returns true if device is online and has communicated recently
   * @type {Boolean}
   */
  get isOnline () {
    return this.status === DeviceConnectionStatus.Online
  }

  /**
   * Indicates that device is now communicating in fast sampling mode
   * @type {Boolean}
   */
  isFastSampling

  /**
   * Returns true if device is online in heartbeat mode
   * @type {Boolean}
   */
  get isHeartbeat () {
    return this.status === DeviceConnectionStatus.Heartbeat
  }

  /**
   * Returns true if device is deemed connected, in either online or heartbeat mode
   * @type {Boolean}
   */
  get isConnected () {
    return this.status === DeviceConnectionStatus.Online || this.status === DeviceConnectionStatus.Heartbeat
  }

  /**
   * Returns true if device has communicated but it's been a long time ever since
   * @type {Boolean}
   */
  get isLost () {
    return this.status === DeviceConnectionStatus.Lost
  }

  /**
   * Returns true if error has happened while retrieving device status
   * @type {Boolean}
   */
  get isError () {
    return this.status === DeviceConnectionStatus.Error
  }

  /**
   * Returns true if device has never communicated yet
   * @type {Boolean}
   */
  get hasNeverConnected () {
    return this.status === DeviceConnectionStatus.NeverConnected
  }

  /**
   * Returns true if device connection quality is good
   * @type {Boolean}
   */
  get isGoodQuality () {
    return this.quality === DeviceConnectionQuality.Good
  }

  /**
   * Returns true if device connection quality is bad
   * @type {Boolean}
   */
  get isBadQuality () {
    return this.quality === DeviceConnectionQuality.Bad
  }

  /**
   * Human-friendly label representing the connection status
   * @type {String}
   */
  get label () {
    return DeviceConnectionStatusName[this.status] || DeviceConnectionStatusName[DeviceConnectionStatus.Unknown]
  }

  /**
   * Color representing the connection status
   * @type {String}
   */
  get color () {
    return DeviceConnectionStatusColor[this.status] || DeviceConnectionStatusColor[DeviceConnectionStatus.Unknown]
  }

  /**
   * Determines connection status of the device
   * @param {Dictionary<String, any>} mega Raw mega
   */
  parse (mega) {
    if (mega) {
      this.megaVersion = mega['@version']

      if (!this.megaVersion) {
        this.neverConnected()

      } else {
        const timings = new DeviceStatusTimings(this.serialNumber, mega)
        this.status = timings.isRealTime
          ? DeviceConnectionStatus.Online
          : (timings.isHeartbeat ? DeviceConnectionStatus.Heartbeat : DeviceConnectionStatus.Lost)
        this.operatingMode = safeParseInt(mega['operating_mode'], 0)
        this.messageFrequency = mega['_default_sampling_speed']
        this.fastSamplingSpeed = mega['fast_sampling_speed']
        this.rebalanceFrequency = mega['_timer_long_mins']
        this.hourlyMessageCount = safeParseInt(mega['hourly_message_count'])
        this.dailyMessageCount = safeParseInt(mega['daily_message_count'])
        this.isRFDisabled = Boolean(mega['rf_disabled'])
        this.region = mega['_rf_region']
        this.isManualRegionControl = mega['_rf_region_control'] === 'MANUAL'
        this.description = ''
        this.shortDescription = ''
        if (this.isUnknown) {
          this.unknown()
        } else if (this.hasNeverConnected) {
          this.neverConnected()
        } else if (this.isOnline || timings.isHeartbeat) {
          this.description = `Heartbeat: ${timings.statusAgeString}`
          this.shortDescription = timings.statusAgeString
        } else if (this.isLost) {
          this.description = `Lost ${timings.statusAgeString}`
          this.shortDescription = this.description
        }
      }
    }
  }

  /**
   * Indicates that device has never connected yet
   * @param {String} description Status description
   */
  neverConnected (description = 'Never connected') {
    this.status = DeviceConnectionStatus.NeverConnected
    this.description = description
    this.shortDescription = description
  }

  /**
   * Indicates that device status is unknown yet
   * (but will be soon retrieved)
   * @param {String} description Status description
   */
  unknown (description = 'Retrieving status ...') {
    this.status = DeviceConnectionStatus.Unknown
    this.description = description
    this.shortDescription = 'Retrieving ...'
  }

  /**
   * Indicates that error has happened while retrieving the device status
   * @param {Error} error Error details
   * @param {String} description Status description
   */
  failed (error, description = 'Device status is not available') {
    this.status = DeviceConnectionStatus.Error
    this.description = description
    this.shortDescription = 'N/A'
    this.error = error
  }

  /**
   * Updates the specified device with the parsed status
   * @param {Device} device Device to update with this status
   */
  update (device) {
    if (device) {
      if (device) {
        const {
          region,
          isManualRegionControl,
        } = this

        // Make sure to preserve the already known values,
        // if they're absent for whatever reason in the received status
        Object.assign(device, {
          region: region || device.region,
          isManualRegionControl: isManualRegionControl == null ? device.isManualRegionControl : isManualRegionControl,
        })
      }
    }
  }
}