<script>
import { mapGetters, mapActions } from 'vuex'
import { ViewMixin, DefaultListActions } from '@stellacontrol/client-utilities'
import { Secure } from '../../components/secure-component'
import { Organization } from '@stellacontrol/model'

const name = 'users'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      DefaultListActions
    }
  },

  computed: {
    ...mapGetters([
      'users',
      'hasUsers',
      'userColumns'
    ]),

    title () {
      return `Users of ${this.currentOrganization.name}`
    },

    userCount () {
      return (this.users || []).length
    },

    hasReachedUsersLimit () {
      return this.userCount >= Organization.MaxUsers
      
    },

    maxUsersWarning () {
      return `The company can have ${Organization.MaxUsers} user accounts`
    }
  },

  methods: {
    ...mapActions([
      'getUsers',
      'createUser',
      'editUser',
      'removeUser'
    ]),

    executeAction (user, action) {
      switch (action.name) {
        case 'edit':
          return this.editUser({ user })
        case 'delete':
          return this.removeUser({ user, confirm: true })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" v-if="isLoggedIn">
    <template #toolbar>
      <q-btn label="Add User" icon="add" unelevated class="button-add-user primary"
        :disable="hasReachedUsersLimit"
        :title="hasReachedUsersLimit ? maxUsersWarning : ''"
        @click="createUser()">
      </q-btn>
    </template>

    <div class="label-no-users q-ma-md" v-if="!hasUsers">
      <div class="q-mb-md">
        You can add here users, who will be able to log into the organization.
      </div>
      <q-btn label="Add User" icon="add" class="primary" unelevated @click="createUser()"></q-btn>
    </div>

    <template #header>
      <sc-list-filter v-if="hasUsers" :name="name"></sc-list-filter>
    </template>

    <sc-list
       v-if="hasUsers"
      :name="name"
      :columns="userColumns"
      :items="users"
      row-key="id"
      :actions="[ ...DefaultListActions ]"
      @action="executeAction">

      <template v-slot:body-cell-fullName="props">
        <q-td :props="props">
          <router-link class="item-link" :to="{ name: 'user', params: { id: props.row.id } }">
            {{ props.row.fullName }}
            <sc-tooltip :text="`Edit user ${props.row.fullName}`">
            </sc-tooltip>
          </router-link>
        </q-td>
      </template>

    </sc-list>

  </sc-view>
</template>

<style lang='scss' scoped>
</style>
