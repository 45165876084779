<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // Devices which can be used as replacement
      replacementDevices: [],
      // Identifier of a device to replace with
      replaceWithId: null,
      // Flags indicating which data to copy to the replacement device
      copyDeviceSettings: true,
      copyPlace: true,
      copyAlerts: true,
      copyPremiumServices: true,
      copyComments: true,
      // Additional notes
      notes: null
    }
  },

  computed: {
    ...mapState({
      inventory: state => state.inventoryView.items.map(item => item.device)
    }),

    ...mapGetters([
      'organizationPreferences'
    ]),

    // All devices owned by the owner of the selected devices
    ownerDevices () {
      return this.inventory.filter(device => device.ownerId === this.ownerId)
    },

    // True, if there are replacement devices to select from
    hasReplacementDevices () {
      return this.replacementDevices.length > 0
    },

    // Device to replace with
    replaceWith () {
      const { replaceWithId, ownerDevices } = this
      return replaceWithId
        ? ownerDevices.find(d => d.id === this.replaceWithId)
        : undefined
    }
  },

  methods: {
    ...mapActions([
      'swapDevice'
    ]),

    // Devices which qualify as replacement devices for the selected one
    loadDevices () {
      const { ownerDevices, device } = this
      this.replacementDevices = ownerDevices
        .filter(d => d.id !== device.id)
        .filter(d => d.isMultiDevice === device.isMultiDevice)
        .filter(d => d.type === device.type)
        .map(({ id, label }) => ({ id, label }))
    },

    // Filter function for filtering devices
    filterDevices (value = '', update) {
      update(() => {
        this.loadDevices()
        if (value !== '') {
          const text = value.toLowerCase()
          this.replacementDevices = this.replacementDevices.filter(d => d.label.toLowerCase().includes(text))
        }
      })
    },

    // Swaps the device with the selected replacement device
    async swap () {
      if (await this.validate()) {
        const { device, replaceWith, notes, copyDeviceSettings, copyPlace, copyAlerts, copyPremiumServices, copyComments } = this
        await this.swapDevice({ device, replaceWith, notes, copyDeviceSettings, copyPlace, copyAlerts, copyPremiumServices, copyComments })
        this.executed()
      }
    }
  },

  watch: {
    device () {
      this.loadDevices()
      // Deselect the selected device, if no longer applicable as replacement device
      if (this.replaceWithId && !this.replacementDevices.find(d => d.id === this.replaceWithId)) {
        this.replaceWithId = null
      }
    }
  },

  created () {
    this.loadDevices()
  }
}
</script>

<template>
  <sc-device-action
    :action="action"
    :devices="devices"
    :canExecute="replaceWith != null"
    execute-label="Swap"
    @closing="closing"
    @close="close"
    @execute="swap">

    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent>
      <div v-if="hasReplacementDevices">
        <div>
          <label>
            Select a replacement device for {{ device.label }}:
          </label>
        </div>

        <q-select
          square
          outlined
          v-model="replaceWithId"
          :options="replacementDevices"
          emit-value
          map-options
          option-value="id"
          option-label="label"
          use-input
          debounce="500"
          fill-input
          hide-selected
          clearable
          @filter="filterDevices">
        </q-select>

        <div class="q-mt-sm q-pl-sm">
          <label class="text-green-8">
            You can only select from devices of the same type, which belong to the same owner. The selected device will assume all settings, service subscriptions and alert configurations of {{ device.label }}.
          </label>
        </div>

        <div class="q-mt-md q-pl-sm">
          <label>
            Copy the following data from {{ device.label }} to {{ replaceWith?.label || 'the replacement device' }}:
          </label>
        </div>

        <div class="q-pl-sm q-mt-sm column">
          <q-checkbox v-model="copyDeviceSettings" label="Device parameters">
            <sc-tooltip>
              The selected parameters will be applied to the replacement device, including band parameters, reset frequency, ship/land mode, messaging gateway and LCD settings.
            </sc-tooltip>
          </q-checkbox>

          <q-checkbox v-model="copyPlace" label="Place and location">
            <sc-tooltip>
              The replacement device will be assigned to the same place and location
            </sc-tooltip>
          </q-checkbox>

          <q-checkbox v-model="copyAlerts" label="Alert configurations" v-if="canUse('alerts')">
            <sc-tooltip>
              Alert configurations will be applied to the replacement device
            </sc-tooltip>
          </q-checkbox>

          <q-checkbox v-model="copyPremiumServices" label="Premium subscriptions" v-if="canUse('premium-services-sell')">
            <sc-tooltip>
              Premium service subscriptions active on the old device will be applied to the replacement device
            </sc-tooltip>
          </q-checkbox>

          <q-checkbox v-model="copyComments" label="Device Comments on Dashboard">
            <sc-tooltip>
              Comments associated with the old device will be applied to the replacement device
            </sc-tooltip>
          </q-checkbox>
        </div>

        <q-input square outlined
          class="q-mt-sm"
          label="Notes"
          v-model="notes"
          autogrow
          type="textarea">
        </q-input>
      </div>

      <div v-else>
        <div>
          <label class="text-orange-8">
            Currently there are no devices which could be used as a replacement. You can only select from devices of the same type, which belong to the same owner.
          </label>
        </div>
      </div>
    </q-form>

  </sc-device-action>
</template>

<style lang="scss" scoped>
</style>
