<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // Path displayed in the breadcrumbs, an array of { name, title, params, query } pairs
    path: {
      type: Array,
      required: true
    },
    // Custom title of the last item. Normally this will be taken
    // from default item title, but in some situations
    // we might want to override that. Example: a view
    // where we're editing a record such as user or device,
    // and we'd like to show the user ID or device serial as title.
    title: String
  },

  computed: {
    ...mapGetters([
      'getView',
      'getRouteByView'
    ]),

    // Items to display in the breadcrumbs
    visiblePath () {
      return (this.path || []).filter(p => p && !p.isHidden)
    },

    // Route to navigate to, when breadcrumbs item is clicked
    itemRoute () {
      return item => ({
        name: this.getRoute(item),
        params: item.params,
        query: item.query
      })
    }
  },

  methods: {
    // Returns route for the specified breadcrumbs item
    getRoute (item) {
      if (item.route) {
        return item.route
      }
      const view = this.getView(item.name)
      if (view) {
        let route = view.route || this.getRouteByView(item.name)
        if (route) {
          return route.name
        }
      }
    }
  }
}
</script>

<template>
  <ul class="breadcrumbs">
    <li v-for="(item, index) in visiblePath" :key="item.name">
      <span>
        {{ index === 0 ? '' : ' > ' }}
      </span>

      <router-link v-if="getRoute(item) && (index < visiblePath.length - 1)" :to="itemRoute(item)"
        class="item-link">
        {{ item.title }}
      </router-link>

      <span v-else>
        {{ item.title }}
      </span>
    </li>
  </ul>
</template>

<style scoped lang="scss">
ul.breadcrumbs {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-left: 4px;
    font-size: 13px;
    color: #474747;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      font-weight: bold;
    }
  }
}
</style>