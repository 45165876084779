import { createState } from './installations.state'
import { sortItemsWith, stringCompare, itemCompare } from '@stellacontrol/utilities'

export const mutations = {
  /**
   * Populates the installations view
   */
  loadBuildings (state, { places }) {
    state.places = places
    state.installationsRefresh++
  },

  /**
   * Filters the buildings by the specified conditions
   * @param {String} filter Free-text filter
   */
  filterBuildings (state, { filter } = {}) {
    state.filter = filter
    state.installationsRefresh++
  },

  /**
   * Sorts the buildings by the specified field and order
   * @param {String} sortBy Field to sort by
   * @param {String} sortDescending Sort order, `asc|desc`
   */
  sortBuildings (state, { sortBy, sortDescending } = {}) {
    state.sortBy = sortBy == null ? state.sortBy : sortBy
    state.sortDescending = sortDescending == null ? state.sortDescending : sortDescending

    const isPlacePinned = place => state.pinnedBuildings.includes(place.id)
    const comparer = (i1, i2) => {
      const p1 = isPlacePinned(i1)
      const p2 = isPlacePinned(i2)
      let result

      // Pinned places are always on top (but then, the same sorting order within them applies!)
      if (p1 && !p2) {
        return -1
      } else if (p2 && !p1) {
        return 1
      } else {
        // If sorting by name and both are stock places, sort by organization name, case-insensitive
        if (sortBy === 'name' && i1.isStock && i2.isStock) {
          result = stringCompare(i1.organizationName, i2.organizationName, false)
        } else {
          // Otherwise sort by the selected field, case-insensitive
          result = itemCompare(i1, i2, state.sortBy, false)
        }
        return state.sortDescending ? -result : result
      }
    }

    state.places = sortItemsWith(state.places, comparer)
    state.installationsRefresh++
  },

  /**
   * Pins the specified buildings
   * @param {Array[String]} pinned Identifiers of buildings to pin/unpin
   */
  pinFavouriteBuildings (state, { pinned }) {
    state.pinnedBuildings = pinned || []
    state.installationsRefresh++
  },

  /**
   * Pins or unpins the specified buildings
   * @param {String} id Identifier of a building to pin/unpin
   * @param {Boolean} pin If specified, defines whether the place should be pinned.
   * If not specified, the current `pinned` state of the place is toggled
   */
  pinFavouriteBuilding (state, { id, pin }) {
    const index = state.pinnedBuildings.indexOf(id)
    const wasPinned = index > -1
    pin = pin == null ? !wasPinned : Boolean(pin)

    if (wasPinned && !pin) {
      state.pinnedBuildings.splice(index, 1)
    } else if (!wasPinned && pin) {
      state.pinnedBuildings.push(id)
    }

    state.pinnedBuildings = state.pinnedBuildings.filter(id => id)
    state.installationsRefresh++
  },

  /**
   * Triggered when someone updates a place.
   * Update any buildings visible in the installations accordingly
   * @param {Place} place Updated place
   */
  storePlace (state, { place: { id, name, updatedAt, updatedBy, organizationId, hasNotes, hasAttachments } }) {
    const place = state.places.find(p => p.id === id && p.organizationId === organizationId)
    if (place) {
      place.name = name
      place.hasNotes = hasNotes || hasAttachments
      if (updatedAt > place.updatedAt) {
        place.updatedAt = updatedAt
        place.updatedBy = updatedBy
      }
    }
    state.installationsRefresh++
  },

  /**
   * Triggered when someone removes an place from the state
   * Update any buildings visible in the installations accordingly
   * @param {Place} place Removed place
   */
  removePlace (state, { place } = {}) {
    state.places = state.places.filter(p => !(p.id === place.id && p.organizationId === place.organizationId))
    state.installationsRefresh++
  },

  /**
   * Triggered when someone updates a place.
   * Update any buildings visible in the installations accordingly
   */
  storePlan (state) {
    state.installationsRefresh++
  },

  /**
   * Stores the last opened route under buildings menu
   * @param {Route} route Last opened route
   */
  storeBuildingsRoute (state, { route }) {
    state.lastRoute = route
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
