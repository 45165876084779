import { Log, isEnum, createClock, processBatch, wait, getTimeString, distinctItems, countString } from '@stellacontrol/utilities'
import { Notification, isRouteDataChanged } from '@stellacontrol/client-utilities'
import { Device, getDevicesDescription } from '@stellacontrol/model'
import { LoadingBar } from '@stellacontrol/client-utilities'
import { DeviceStatus, DeviceStatusClient, DeviceCommandClient, FastSamplingSpeed } from '@stellacontrol/devices'
import { DevicesPushClient } from '@stellacontrol/client-api'

export const actions = {
  /**
   * On route changes stop all the watching for device status
   */
  async navigationStarted ({ dispatch }, { from, to } = {}) {
    if (isRouteDataChanged(from, to)) {
      await dispatch('unsubscribeDeviceStatus')
    }
  },

  /**
   * Retrieves live status of the specified devices.
   * @param {String} name Name of a view or process which is retrieving the status
   * @param {Array[Device]} devices Devices to retrieve
   * @param {Device} device Single device to retrieve
   * @param {Boolean} isFastSampling Indicates that we're in fast mode, so skip some checks as we only need the status now
   * @param {Number} batchSize Maximal amount of devices to poll in one query, when retrieving status of multiple devices
   * @param {Number} pause Pause between batches, in ms
   * @param {Number} delay Delay before requesting the status, in ms
   * @emits deviceStatusReceived Mutation to notify about the new status of each device.
   * Other state stores might save the status in their devices, so that the status is reflected in the UI.
   */
  async getLiveStatus ({ commit }, { name, devices = [], device, isFastSampling, batchSize = 10, pause = 0, delay = 0 } = {}) {
    devices = device ? [device] : devices
    if (!(devices?.length > 0)) return

    const statusService = new DeviceStatusClient()
    await wait(delay)
    const results = await processBatch({
      // Only poll connected devices
      items: devices.filter(d => d && d.isConnectedDevice),
      handler: async batch => {
        if (batch.length > 0) {
          const results = await statusService.getDeviceStatus(batch, { isFastSampling })
          // Store the received status for each monitored device
          for (const device of batch) {
            const masterDevice = device?.partOf ? devices.find(d => d.id === device.partOf) : undefined
            const status = results.find(result => result.serialNumber === device.serialNumber)
            if (status) {
              commit('deviceStatusReceived', { name, device, masterDevice, status })
            } else {
              commit('deviceStatusReceived', { name, device, masterDevice, status: DeviceStatus.notAvailable(device.serialNumber) })
            }
          }
          return results
        } else {
          return []
        }
      },
      batchSize,
      pause
    })

    // Notify that we're done retrieving status
    commit('finishRetrievingDeviceStatus', { name, devices })

    return results || []
  },

  /**
   * Probes for device status, until fresh status has arrived
   * @param {Device} device Device to probe
   * @param {Number} timeout Timeout in milliseconds, after which probing should be stopped
   * @param {Number} interval Interval in milliseconds, between probing attempts
   * @param {Number} age Required age of the retrieved status, for the probing to finish with success
   * @returns {Promise<DeviceStatus>} Status is also stored in the state.
   */
  async waitForLiveStatus ({ commit }, { device, timeout = 10000, interval = 1000, age = 10000 } = {}) {
    const commandService = new DeviceCommandClient()
    const statusService = new DeviceStatusClient()

    try {
      await commandService.switchToFastMode(device, { speed: FastSamplingSpeed.OneSecond, duration: 10 })
      const status = await statusService.waitForLiveStatus(device, { timeout, interval, age })
      if (status) {
        commit('deviceStatusReceived', { status })
        return status
      }
    } catch (error) {
      Log.warn(`[${device.serialNumber}] Live status could not be retrieved`, error.message)
    }
  },

  /**
   * Briefly peeks at live status of specified devices, by switching them
   * to fast mode for a very short time and fetching the status a few times
   * @param {Device} device Device to poll
   * @param {Array[Device]} devices Alternatively, a list of devices to poll
   * @param {Number} retries Number of retries to fetch live status
   * @param {Number} delay Delay between the retries, in milliseconds
   * @param {Boolean} useFastSampling To force the device to report the status, PING command is sent.
   * Additionally, we might want to switch the device to fast sampling mode for a while
   * @param {Boolean} silent If true, no UI notifications will be shown
   * @returns {Promise<Array[DeviceStatus]} List of recent statuses of the queried devices
   */
  async peekLiveStatus ({ dispatch }, { device, devices = [], retries = 3, delay = 1000, useFastSampling, silent = true } = {}) {
    if (device) {
      devices.push(device)
    }

    // Make sure we're not touching devices unable to send status
    devices = devices.filter(d => d.canSendStatus)

    if (devices && devices.length > 0) {
      await dispatch('pingDevice', { devices })

      if (useFastSampling) {
        const fastSamplingSpeed = FastSamplingSpeed.OneSecond
        const fastSamplingDuration = Math.ceil((retries * delay * 2) / 1000)
        await dispatch('startFastSampling', { devices, fastSamplingSpeed, fastSamplingDuration })
      }

      if (!silent) {
        const identification = getDevicesDescription(devices)
        const message = `Waiting for live status of ${identification} ...`
        const details = 'Please be patient, it might take a while before the new status is received.'
        Notification.success({ message, details })
      }

      let results
      await wait(delay)
      for (let i = 0; i < retries; i++) {
        results = await dispatch('getLiveStatus', { devices })
        if (i < retries - 1) await wait(delay)
      }

      return results
    }
  },

  /**
   * Subscribes to push notifications with status of the specified devices
   * @param {String} name Name of a view or process which has initiated the subscription
   * @param {Array[Device]} devices Devices to subscribe
   * @param {Boolean|Array[Device]} fastSampling If specified, switches all or specified devices into fast sampling mode
   * @param {FastSamplingSpeed} fastSamplingSpeed If specified, toggles devices to fast mode at given frequency, in seconds
   * @param {Number} fastSamplingDuration Specifies for how long the device should stay in fast-sampling mode, in seconds
   * @param {Number} alertsInterval TODO: rework to push. Specifies the frequency of fetching device alerts, in seconds. Set to zero to disable alerts retrieval.
   * @param {Number} idleInterval Specifies the time of user inactivity after which the fast-sampling devices with fall back to slow mode, in seconds. Set to zero to disable idleness checks.
   * @param {Number} duration Optional duration of watching the status. The watch will be stopped after the specified time passes.
   * @param {Boolean} debug If true, received status is logged in the console
   * @param {Function<DeviceStatus, any>} onStatus Optional callback to call on received device status
   */
  async subscribeDeviceStatus (
    { commit, dispatch, getters },
    {
      name,
      devices: deviceProvider,
      fastSampling = false,
      fastSamplingSpeed = FastSamplingSpeed.Off,
      fastSamplingDuration = 30,
      alertsInterval = 30,
      idleInterval = 30,
      duration,
      debug = false,
      onStatus
    } = {}
  ) {
    // Function retrieving devices to listen to
    const getDevices = () => {
      let devices
      if (typeof deviceProvider === 'function') {
        devices = deviceProvider() || []
      } else {
        devices = (Array.isArray(deviceProvider) ? deviceProvider : undefined) || []
      }
      return distinctItems(devices, 'serialNumber')
        // Watch only devices which are connected, thus no multi-devices aggregates,
        // no not-connected devices etc.
        .flatMap(device => device.isMultiDevice ? (device.parts || []) : [device])
        .filter(device => device &&
          (device.id || device.serialNumber) &&
          device.isConnectedBoard)
    }

    // Stop any currently running subscriptions under the same name
    await dispatch('unsubscribeDeviceStatus', { name })

    if (!name) throw new Error('Name of the process subscribing to device status updates is required')
    if (!isEnum(FastSamplingSpeed, fastSamplingSpeed)) throw new Error(`Invalid fast sampling speed ${fastSamplingSpeed}`)
    if (fastSamplingDuration < 0 || fastSamplingDuration > 900) throw new Error(`Invalid fast sampling duration ${fastSamplingDuration}`)

    // Check if there actually are any devices,
    // determine which ones should be running under fast-sampling mode.
    const devices = getDevices()
    if (devices?.length === 0) return

    // Determine if/which devices should be running under fast sampling mode
    const fastSamplingDevices = fastSampling ? (Array.isArray(fastSampling) ? fastSampling : [...devices]) : []
    const isFastSampling = fastSamplingDevices.length > 0 && fastSamplingSpeed && fastSamplingSpeed !== FastSamplingSpeed.Off

    // Create a clock with 1-second precision for all activities
    const clock = createClock({ name, frequency: 1000 })

    // START STATUS LISTENER
    const statusBuffer = []
    const { configuration } = getters
    const listener = new DevicesPushClient()
    commit('subscribeDeviceStatus', { name, clock, listener })
    await listener.initialize(configuration.services.push)
    const subscribed = await listener.subscribeToDeviceStatus({
      devices,
      onMessage: message => {
        if (clock.isSuspended) return
        if (fastSampling && clock.isAlertSuspended('fast-sampling')) return
        const status = DeviceStatus.from(message.data)
        const device = devices.find(d => d.serialNumber === status.serialNumber)
        if (device) {
          // Skip out-of-order status messages!
          const previousTime = getters.getDeviceStatusTime(device)
          if (!(previousTime > status.receivedAt)) {
            const masterDevice = device?.partOf ? devices.find(d => d.id === device.partOf) : undefined
            commit('deviceStatusReceived', { name, device, masterDevice, status })
            statusBuffer.push(status)
            const { id, timings: { delay, receivedAtString } } = status
            Log.details(
              status.serialNumber,
              `Status [${id}][${receivedAtString}] received`,
              delay > 1000 ? `with delay [${delay}ms]` : undefined
            )

            if (onStatus) {
              onStatus(device, status)
            }
          }
        }
      }
    })

    if (!subscribed) {
      commit('unsubscribeDeviceStatus', { name })
      Log.warn(`[${name}] Could not subscribe to device status updates`)
      return
    }

    // CLOCK TICKER
    clock.addEventListener('tick', () => {
      commit('tickDeviceStatus', { ticks: clock.ticks })

      // Log all status messages received during the last tick
      const items = [...statusBuffer]
      if (items.length > 0) {
        statusBuffer.splice(0)
        const status = items.length === 1 ? items[0] : items
        const description = items.length <= 5
          ? items.map(s => s.serialNumber).join(' ')
          : `${items.length} items`
        if (debug) {
          Log.debug(`[${getTimeString()}] Status received [${description}]`, status)
        }
      }
    })

    // CLOCK ALERTS
    // Process for enabling fast mode on devices.
    // Suspended if we're starting in slow mode.
    if (isFastSampling) {
      clock.addAlert({ name: 'fast-sampling', interval: 1 })
      clock.addAlert({ name: 'enable-fast-sampling', interval: fastSamplingDuration })
      clock.addAlertListener('enable-fast-sampling', async () => {
        await dispatch('startFastSampling', { devices: fastSamplingDevices, fastSamplingSpeed, fastSamplingDuration })
      })
    }

    // Stopping status monitoring after the specified amount of time
    if (duration > 0) {
      clock.addAlert({ name: 'stop', interval: duration })
      clock.addAlertListener('stop', async () => {
        await dispatch('suspendFastSampling', { name })
      })
    }

    // Process for fetching device alerts periodically.
    if (alertsInterval > 0 && getters.canUse('alerts')) {
      clock.addAlert({ name: 'device-alerts', interval: alertsInterval })
      clock.addAlertListener('device-alerts', async () => {
        for (const device of devices) {
          if (device.canTriggerAlerts) {
            const maxAge = 3600 * 24 * 7
            const alerts = await dispatch('getRecentAlertOccurrences', { device, maxAge, count: 5 })
            if (alerts) {
              commit('deviceAlertsReceived', { device, alerts, age: maxAge })
            }
          }
        }
      })
    }

    // Process for watching the user going idle / coming back.
    // This will be used to pause and restore fast sampling accordingly.
    // Suspended if we're starting in slow mode.
    if (isFastSampling && idleInterval > 0) {
      clock.addAlert({ name: 'user-idle', interval: idleInterval, isSuspended: !isFastSampling })
      clock.addAlertListener('user-idle', async () => {
        await dispatch('suspendFastSampling', { name })
      })

      // Ensures that fast sampling remains active, resumes it if necessary.
      const continueFastSampling = async () => {
        if (!clock.isSuspended) {
          if (clock.isAlertSuspended('fast-sampling')) {
            const devices = fastSamplingDevices
            if (devices && devices.length > 0) {
              const resumed = await dispatch('resumeFastSampling', { name })
              if (resumed) {
                await dispatch('startFastSampling', { devices, fastSamplingSpeed, fastSamplingDuration })
              } else {
                clock.deleteAlert('fast-sampling')
              }
            }
          }
        }
        clock.resetAlert('user-idle')
      }

      // Attach UI event handlers for continuing/restoring fast-sampling mode
      const onMouseDown = document.addEventListener('mousedown', () => continueFastSampling())
      const onMouseMove = document.addEventListener('mousemove', () => continueFastSampling())
      const onKeyDown = document.addEventListener('keydown', () => continueFastSampling())

      // Watch tab/window visibility,toggle between slow and fast mode accordingly.
      let tabIsHidden = document.hidden
      clock.addEventListener('tick', async () => {
        if (document.hidden && !tabIsHidden) await dispatch('suspendFastSampling', { name })
        if (tabIsHidden && !document.hidden) await continueFastSampling()
        tabIsHidden = document.hidden
      })

      // Detach UI event handlers when the clock stops
      clock.addEventListener('stop', () => {
        document.removeEventListener('mousedown', onMouseDown)
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('keydown', onKeyDown)
        Log.debug(`[${name}] Stopped watching user activity`)
      })

      Log.debug(`[${name}] Fast sampling will be stopped if user remains idle longer than ${idleInterval}s`)
    }

    // Start the clock and immediately run all alerts for the first time.
    // These initially marked as suspended will be ignored.
    await clock.runAlert('enable-fast-sampling')
    await clock.runAlert('device-alerts')
    await clock.start()

    Log.debug(`[${name}] Watching device status of ${countString(devices, 'device')}${duration > 0 ? ` for ${duration}s` : ''}`, devices.map(d => d.serialNumber).join(' '))
    if (isFastSampling) {
      Log.debug(`[${name}] Fast mode enabled`, devices.map(d => d.serialNumber).join(' '))
      if (clock.isAlertActive('user-idle')) {
        Log.debug(`[${name}] Switching back to slow mode after ${idleInterval}s of inactivity`)
      }
    }
    if (clock.isAlertActive('device-alerts')) {
      Log.debug(`[${name}] Watching device alerts every ${alertsInterval} s`)
    }
  },

  /**
   * Stops listening to device status updates
   * @param {String} name Name of a view or process which has initiated listening.
   * If not specified, all status listeners are stopped.
   */
  async unsubscribeDeviceStatus ({ commit }, { name } = {}) {
    commit('unsubscribeDeviceStatus', { name })
  },

  /**
   * Subscribes to push notifications with data updates of the specified devices
   * @param {String} name Name of a view or process which has initiated the subscription
   * @param {Array[Device]} devices Devices to poll - either a fixed list or a function returning devices to poll
   * @param {Boolean} debug If true, received status is logged in the console
   */
  async subscribeDeviceUpdates ({ commit, dispatch, getters }, { name, devices } = {}) {
    // Expand multi-unit devices to parts
    // Watch only connected devices
    const subscribeDevices = distinctItems(devices, 'serialNumber')
      .flatMap(device => device.isMultiDevice ? (device.parts || []) : [device])
      .filter(device => device.serialNumber && device.isConnectedBoard)
    if (subscribeDevices.length === 0) return

    // Stop any currently running subscriptions under the same name
    await dispatch('unsubscribeDeviceUpdates', { name })
    if (!name) throw new Error('Name of the process subscribing to device updates is required')

    // START STATUS LISTENER
    const { configuration } = getters
    const listener = new DevicesPushClient()
    commit('subscribeDeviceUpdates', { name, listener })
    await listener.initialize(configuration.services.push)
    const subscribed = await listener.subscribeToDeviceUpdates({
      devices: subscribeDevices,
      onMessage: message => {
        const update = new Device(message.data)
        const part = message.entityPart
        const device = subscribeDevices.find(d => d.serialNumber === update.serialNumber)
        if (device) {
          const masterDevice = device?.partOf ? subscribeDevices.find(d => d.id === device.partOf) : undefined
          commit('deviceUpdateReceived', { name, device, masterDevice, update, part })
        }
      }
    })
    if (!subscribed) {
      commit('unsubscribeDeviceUpdates', { name })
      Log.warn(`[${name}] Could not subscribe to device updates`)
      return
    }

    commit('subscribeDeviceUpdates', { name, listener })
    Log.debug(`[${name}] Watching device updates of ${countString(subscribeDevices, 'device')}`, subscribeDevices.map(d => d.serialNumber).join(' '))
  },

  /**
   * Stops listening to device updates
   * @param {String} name Name of a view or process which has initiated listening.
   * If not specified, all status listeners are stopped.
   */
  async unsubscribeDeviceUpdates ({ commit, state }, { name } = {}) {
    // Stop the specified listener or all listeners
    const deviceListeners = name
      ? [state.deviceListeners[name]]
      : Object.values(state.deviceListeners)

    for (const { name, listener } of deviceListeners.filter(p => p)) {
      if (listener) {
        listener.close()
        Log.debug(`[${name}] Stopped watching device updates`)
        commit('unsubscribeDeviceUpdates', { name })
      }
    }
  },

  /**
   * Suspends fast sampling mode
   * @param {String} name Name of a view or process which has initiated the watching
   */
  async suspendFastSampling ({ state }, { name } = {}) {
    const listener = state.statusListeners[name]
    const { clock } = listener || {}
    if (clock && clock.isAlertActive('fast-sampling')) {
      clock.suspendAlert('fast-sampling')
      clock.suspendAlert('enable-fast-sampling')
      clock.resumeAlert('slow-sampling')
      LoadingBar.enable(name)
      Log.debug(`[${name}] Fast sampling stopped`)
    }
  },

  /**
   * Resumes the previously suspended watching device status
   * @param {String} name Name of a view or process which has initiated the watching
   * @returns {Boolean} True if fast sampling has been resumed
   */
  async resumeFastSampling ({ state }, { name } = {}) {
    const listener = state.statusListeners[name]
    const { clock } = listener || {}
    if (clock && clock.isAlertSuspended('fast-sampling')) {
      clock.suspendAlert('slow-sampling')
      clock.resumeAlert('fast-sampling')
      clock.resumeAlert('enable-fast-sampling')
      LoadingBar.disable(name)
      Log.debug(`[${name}] Fast sampling resumed`)
      return true
    }
  },

  /**
   * Starts receiving device status by subscribing to push notifications.
   * A short-hand for `subscribeDeviceStatus` and `subscribeDeviceUpdates`
   * using predefined watch configurations from configuration file
   * @param {String} name Name of a view or process which has initiated the subscription
   * @param {Array[Device]|Function<Array[Device]>} devices List or a function returning a list of devices to subscribe to
   * @param {Boolean|Array[Device]} fastSampling If specified, switches all or specified devices into fast sampling mode
   * @param {Number} duration Optional duration of watching the status. The watch will be stopped after the specified time passes.
   * @param {Function<DeviceStatus, any>} onStatus Optional callback to call on received device status
   */
  async watchDeviceStatus ({ getters, dispatch }, { name, devices, fastSampling = false, duration, onStatus } = {}) {
    const { getStatusWatchSettings } = getters
    const settings = getStatusWatchSettings(name)
    let {
      fastSamplingSpeed,
      fastSamplingDuration,
      alertsInterval,
      idleInterval } = settings

    // Enforce default settings if fast sampling requested explicitly
    if (fastSampling) {
      if (!fastSamplingSpeed || fastSamplingSpeed === 'off') {
        fastSamplingSpeed = '1s'
      }
      if (fastSamplingDuration == null) {
        fastSamplingDuration = 30
      }
      if (idleInterval == null) {
        idleInterval = 30
      }
    }

    await dispatch('unwatchDeviceStatus', { name })
    await dispatch('subscribeDeviceUpdates', { name, devices })
    return dispatch('subscribeDeviceStatus', { name, devices, fastSampling, fastSamplingSpeed, fastSamplingDuration, alertsInterval, idleInterval, duration, onStatus })
  },

  /**
   * Watches for device status briefly, using fast-sampling.
   * Used to receive the results of settings changes and commands.
   * @param {Array[Device]|Function<Array[Device]>} devices List or a function returning a list of devices to watch
   * @param {Device} device Alternatively, a single device to watch
   * @param {Number} duration Duration of watching the status. The watch will be stopped after the specified time passes.
   */
  peekDeviceStatus ({ dispatch }, { devices, device, duration = 20 } = {}) {
    devices = devices || (device ? [device] : undefined)
    if (!(devices?.length > 0)) return

    const name = `peek-${devices.map(d => d.serialNumber).join('-')}`.substring(0, 250)
    return dispatch('watchDeviceStatus', {
      name,
      devices,
      fastSampling: true,
      duration
    })
  },

  /**
   * Stops receiving device status
   * @param {String} name Name of a view or process which has initiated the watching. If not specified, all processes are stopped.
   */
  async unwatchDeviceStatus ({ dispatch }, { name } = {}) {
    await dispatch('unsubscribeDeviceUpdates', { name })
    return dispatch('unsubscribeDeviceStatus', { name })
  },

  /**
 * Suspends watching device status
 * @param {String} name Name of a view or process which has initiated the watching
 */
  async suspendWatchingDeviceStatus ({ state }, { name } = {}) {
    const listener = state.statusListeners[name]
    const { clock } = listener || {}
    if (clock && !clock.isSuspended) {
      clock.suspend()
      Log.debug(`[${name}] Watching device status suspended`)
    }
  },

  /**
   * Resumes the previously suspended watching of device status
   * @param {String} name Name of a view or process which has initiated the watching
   * @returns {Boolean} True if watching has been resumed
   */
  async resumeWatchingDeviceStatus ({ state }, { name } = {}) {
    const listener = state.statusListeners[name]
    const { clock } = listener || {}
    if (clock && clock.isSuspended) {
      clock.resume()
      Log.debug(`[${name}] Watching device status resumed`)
      return true
    }
  },

  /**
   * Initiates counting down the fast-sampling mode on the selected devices for the specified duration
   * @param {String} name View or component which has initiated the fast-sampling mode
   * @param {Array[Device]} devices Devices on which fast-sampling mode has been initiated
   * @param {Number} fastSamplingDuration Duration of the fast-sampling mode
   */
  async fastSamplingCountdown ({ commit }, { name, devices, fastSamplingDuration } = {}) {
    if (!name) throw new Error('Fast sampling countdown name is required')
    if (!(fastSamplingDuration >= 0)) throw new Error('Fast sampling duration is invalid')
    if (!(devices?.length > 0)) return

    commit('fastSamplingCountdown', { name, devices, fastSamplingDuration })
  }
}
