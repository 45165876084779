import { differenceInSeconds, isToday } from 'date-fns'
import { parseDate } from '@stellacontrol/utilities'
import { Entity } from '../common/entity'
import { User } from '../organization'
import { Place } from '../device'
import { Organization } from '../organization'

/**
 * Plan
 */
export class Plan extends Entity {
  constructor (data = {}) {
    super()

    this.assign(
      {
        ...data,
        version: data.version || 1
      },
      {
        restoredAt: parseDate
      })
  }

  /**
   * Creates a new empty plan associated with the specified organization and place
   * @param {String} name Plan name
   * @param {String} description Plan description
   * @param {Organization} organization Plan owner
   * @param {Place} place Place to which the plan belongs
   * @returns {Plan}
   */
  static createEmpty ({ name, description, organization, place } = {}) {
    return new Plan({
      name,
      description,
      organization,
      organizationId: organization ? organization.id : undefined,
      place,
      placeId: place ? place.id : undefined,
      snapshots: []
    })
  }

  /**
   * Creates a new plan using the other plan's layout
   * @param {String} name Plan name
   * @param {String} description Plan description
   * @param {Organization} organization Plan owner
   * @param {Place} place Place to which the plan belongs
   * @param {Plan} plan Plan to borrow the layout from
   * @returns {Plan}
   */
  static createFrom ({ name, description, organization, place, plan } = {}) {
    return new Plan({
      name,
      description,
      organization,
      organizationId: organization ? organization.id : undefined,
      place,
      placeId: place ? place.id : undefined,
      layout: plan.layout,
      version: 1
    })
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    if (this.creator) {
      this.creator = this.cast(this.creator, User)
    }
    if (this.updater) {
      this.updater = this.cast(this.updater, User)
    }
    if (this.deleter) {
      this.deleter = this.cast(this.deleter, User)
    }
    if (this.place) {
      this.place = this.cast(this.place, Place)
    }
    if (this.organization) {
      this.organization = this.cast(this.organization, Organization)
    }
    if (this.snapshots) {
      this.snapshots = this.castArray(this.snapshots, Plan)
    }
  }

  /**
   * Plan age in seconds
   * @type {Number}
   */
  get age () {
    const { createdAt } = this
    return createdAt
      ? differenceInSeconds(new Date(), createdAt)
      : 0
  }

  /**
   * Indicates that the plan has been created today
   * @type {Boolean}
   */
  get isCreatedToday () {
    const { createdAt } = this
    return createdAt && isToday(createdAt)
  }

  /**
   * Detailed description
   * @type {String}
   */
  description

  /**
   * The plan is marked as read-only for whatever reason
   * @type {Boolean}
   */
  isReadOnly

  /**
   * Identifier of organization which owns the plan
   * @type {String}
   */
  organizationId

  /**
   * Organization which owns the plan
   * @type {Organization}
   */
  organization

  /**
   * Identifier of a place associated with the plan
   * @type {String}
   */
  placeId

  /**
   * Place associated with the plan
   * @type {Plan}
   */
  place

  /**
   * Plan layout, as created with the StellaPlanner tool
   * @type {PlanLayout}
   */
  layout

  /**
   * Plan version, used for optimistic locking
   * when plan is edited simultaneously by more people
   * @type {Number}
   */
  version

  /**
   * If specified, it indicates that this plan is an auto-backup of another plan
   * @type {String}
   */
  backupOf

  /**
   * If specified, it indicates that this plan is a snapshot of another plan
   * @type {String}
   */
  snapshotOf

  /**
   * If specified, it indicates that this plan has been restored from the specified snapshot
   * @type {String}
   */
  restoredFrom

  /**
   * If specified, it indicates the date and time when plan has been restored from a snapshot
   * @type {DateTime}
   */
  restoredAt

  /**
   * Plan snapshots
   * @type {Array[Plan]}
   */
  snapshots

  /**
   * Checks whether the plan has any snapshots
   * @type {Boolean}
   */
  get hasSnapshots () {
    return !this.snapshotOf && this.snapshots?.length > 0
  }

  /**
   * Default name for snapshots of the most recent plan,
   * created as backup when restoring snapshot
   * @type {String}
   */
  static RecentSnapshot = 'Recent'

  /**
   * Checks whether the snapshot is a snapshot of recent plan
   * just before restoring another snapshot
   * @type {Boolean}
   */
  get isRecentSnapshot () {
    return this.snapshotOf && this.name === Plan.RecentSnapshot
  }

  /**
   * Creates a new snapshot of the plan
   * @param {String} name Snapshot name
   * @param {String} description Snapshot description
   * @param {User} user User creating the snapshot
   * @description The created snapshot IS NOT stored yet in {@link snapshots}!
   * You have to call {@link storeSnapshot} to do this.
   * @returns {Plan}
   */
  createSnapshot ({ name, description, user }) {
    if (!user) throw new Error('User is required')
    if (!name?.trim()) throw new Error('Snapshot name is required')

    const { id, organizationId, placeId } = this

    const snapshot = new Plan({
      ...this,
      name,
      description,
      id: undefined,
      snapshotOf: id,
      organizationId,
      placeId,
      createdAt: new Date(),
      updatedAt: new Date(),
      createdBy: user.id,
      updatedBy: user.id,
      restoredFrom: undefined,
      restoredAt: undefined,
      layout: this.layout.clone({ version: 1})
    })

    return snapshot
  }

  /**
   * Stores the specified snapshot.
   * If a snapshot with the same name already exists, it will be overwritten!
   * @param {Plan} snapshot Snapshot to store
   * @returns {Array[Plan]} Plan snapshots
   */
  storeSnapshot ({ snapshot } = {}) {
    if (!snapshot) return

    if (!this.snapshots) {
      this.snapshots = []
    }

    // Ensure that the snapshot is marked as ours
    snapshot.snapshotOf = this.id
    snapshot.placeId = this.placeId
    snapshot.organizationId = this.organizationId

    // Overwrite any existing snapshot with the same identifier or name
    const foundAt = this.snapshots.findIndex(s => s.name === snapshot.name || s.id === snapshot.id)
    if (foundAt > -1) {
      this.snapshots[foundAt] = snapshot
    } else {
      this.snapshots.push(snapshot)
    }

    return this.snapshots
  }

  /**
   * Finds the specified snapshot
   * @param {String} id Snapshot identifier
   * @param {String} name Alternatively, snapshot name
   * @returns {Plan} Plan snapshot
   */
  getSnapshot ({ id, name }) {
    if (!(id || name)) throw new Error('Snapshot identifier or name is required')
    return this.snapshots.find(s => id ? s.id === id : s.name === name)
  }

  /**
   * Removes the specified snapshot
   * @param {String} id Snapshot identifier
   * @param {String} name Alternatively, snapshot name
   * @returns {Plan} Removed snapshot
   */
  removeSnapshot ({ id, name }) {
    if (!(id || name)) throw new Error('Snapshot identifier or name is required')

    const snapshot = this.getSnapshot({ id, name })
    if (snapshot) {
      this.snapshots = this.snapshots.filter(s => id ? s.id !== id : s.name !== name)
    }

    return snapshot
  }

  /**
   * Removes the snapshot marked as 'recent'
   * @returns {Array[Plan]} Plan snapshots
   */
  removeRecentSnapshot () {
    return this.removeSnapshot({ name: Plan.RecentSnapshot })
  }

  /**
   * Checks whether the plan has a background image
   * @type {Boolean}
   */
  get hasImage () {
    return this.layout.background.image != null
  }

  /**
   * URL of the plan image
   * @type {String}
  */
  get imageUrl () {
    return this.layout.background.image?.reference

  }

  // Removes runtime data on serialization
  toJSON () {
    const result = {
      ...this
    }

    return result
  }

  /**
   * Used to purge images associated with plan floors, before saving the plan.
   * Background images are to be stored externally in file storage, not inside the plan layout.
   */
  purgeFloorImages () {
    const { layout } = this
    if (layout?.background?.image) {
      layout.background.image.content = null
    }
  }
}
