<script>
import { mapState } from 'vuex'
import { DeviceRegion, DeviceRegionDescription, isDeviceRegionValid } from '@stellacontrol/model'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  props: {
    // Icon size
    iconSize: {
      type: String
    }
  },

  computed: {
    ...mapState({
      // Indicates whether device region is currently being changed
      isChangingRegion: state => state.deviceSettings.changingRegionOfDevices.length > 0,
      changingRegionTo: state => state.deviceSettings.changingRegionTo
    }),

    // Current device region
    region () {
      return this.status?.connection?.region
    },

    isEMEA () {
      return this.region === DeviceRegion.EMEA
    },

    isUSA () {
      return this.region === DeviceRegion.USA
    },

    isOff () {
      return this.status?.connection?.isRFOff
    },

    isUnknown () {
      return !isDeviceRegionValid(this.region)
    },

    regionDescription () {
      const { status, region, isChangingRegion, changingRegionTo } = this
      if (status) {
        return isChangingRegion && (region !== changingRegionTo)
          ? `${DeviceRegionDescription[region] || 'Unknown'} > ${DeviceRegionDescription[changingRegionTo]}`
          : `RF: ${DeviceRegionDescription[region] || 'Unknown'}`
      } else {
        return ''
      }
    },

    // Label describing the region
    widgetLabel () {
      const { regionDescription } = this
      return regionDescription
    }
  }
}
</script>

<template>
  <div class="widget mini-widget device-firmware" v-if="hasStatus">
    <header class="row items-center text-bold no-wrap">
      <sc-icon-flag-eu v-if="!isChangingRegion && isEMEA" class="icon-emea" width="32" height="32"></sc-icon-flag-eu>
      <sc-icon-flag-usa v-if="!isChangingRegion && isUSA" class="icon-us"> width="32" height="32"</sc-icon-flag-usa>
      <q-icon v-if="!isChangingRegion && isOff" name="stop_circle" color="grey-6"
        size="32px"></q-icon>
      <q-icon v-if="!isChangingRegion && isUnknown" name="help_outline" color="grey-6"
        size="32px"></q-icon>
      <q-icon v-if="isChangingRegion" name="change_circle" class="rotate-reverse" color="orange-4"
        size="32px"></q-icon>

      <span class="q-ml-md">
        {{ widgetLabel }}
      </span>
    </header>
  </div>
</template>

<style lang="scss" scoped>
/*
svg {

  &.icon-emea,
  &.icon-us {
    left: 6px;
    top: 6px;
  }
}
*/
</style>
