<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { Device, getDeviceLabel, getDeviceDescription } from '@stellacontrol/model'
import { Notification } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import DeviceActions from './actions/device-actions.vue'
import DeviceTags from './actions/device-tags.vue'
import DeviceAudit from './audit/device-audit.vue'
import DeviceAlerts from './alerts/device-alerts.vue'

export default {
  mixins: [
    Secure
  ],

  components: {
    'sc-device-actions': DeviceActions,
    'sc-device-tags': DeviceTags,
    'sc-device-alerts': DeviceAlerts,
    'sc-device-audit': DeviceAudit
  },

  computed: {
    ...mapState({
      devicePanel: state => state.devicePanel,
      tab: state => state.devicePanel.tab,
      isCollapsed: state => state.devicePanel.isCollapsed,
      owner: state => state.devicePanel.owner,
      ownerGuardian: state => state.devicePanel.ownerGuardian,
      ownerWallet: state => state.devicePanel.ownerWallet,
      items: state => state.devicePanel.items,
      devices: state => state.devicePanel.devices
    }),

    ...mapGetters([
      'organizations'
    ]),

    // Returns true if editing a batch of devices
    isBatch () {
      return this.devices.length > 1
    },

    // Returns true if editing a single device
    isSingle () {
      return this.devices.length === 1
    },

    // Returns the first item to edit
    item () {
      return this.items[0]
    },

    // Returns the first device to edit
    device () {
      return this.devices[0]
    },

    // Owner organization of the device
    owner () {
      const { isBatch, device, organizations } = this
      return (!isBatch && device && device.owner)
        ? organizations.find(organization => organization.id === device.owner.id)
        : null
    },

    // Returns tab CSS classes
    tabClass () {
      return tab => ({
        ['bg-indigo-2']: tab === this.tab,
        ['text-black']: tab === this.tab
      })
    },

    // Checks whether ALERTS tab is accessible
    canConfigureAlerts () {
      return this.canUse('alerts')
    },

    // Checks whether AUDIT tab is accessible
    canShowAudit () {
      return !this.isBatch && this.item
    },

    // Checks whether the user can edit device notes
    canEditNotes () {
      return this.canUse('edit-device-notes')
    },

    // Checks whether the user can tag devices
    canTag () {
      return this.canUse('tag-devices')
    }
  },

  emits: [
    'close',
    'executeAction',
    'closeAction'
  ],

  methods: {
    ...mapMutations([
      'openActionPanelTab',
      'expandActionPanel',
      'collapseActionPanel'
    ]),

    ...mapActions([
      'updateDevices'
    ]),

    getDeviceLabel,
    getDeviceDescription,

    // Saves the changes and closes
    save ({ values }) {
      if (values) {
        const devices = this.devices.map(device => new Device({ ...device, ...values }))
        const device = this.device
        this.updateDevices({ devices })
        const message = this.isBatch
          ? `${devices.length} devices updated`
          : `Device ${device.serialNumber} updated`
        Notification.success({ message })
      }
      this.close()
    },

    // Closes the panel, eventually signalling the need to refresh the UI
    close (refresh) {
      this.$emit('close', refresh)
    },

    // Triggered when action has been executed on selected devices.
    // Indicates whether to refresh the inventory grid.
    actionExecuted ({ action, refresh } = {}) {
      const { devices } = this
      this.$emit('executeAction', { action, devices, refresh })
    },

    // Triggered when action panel has been closed
    actionClosed ({ action } = {}) {
      this.$emit('closeAction', { action })
    },

    // Toggles panel visibility
    toggle () {
      if (this.isCollapsed) {
        this.expandActionPanel()
      } else {
        this.collapseActionPanel()
      }
    },

    // Tab has been selected
    tabSelected (tab) {
      this.openActionPanelTab({ tab })
    }
  }
}
</script>

<template>
  <div class="panel" v-if="device" :class="{ collapsed: isCollapsed, 'bg-indigo-6': isCollapsed }"
    :title="isCollapsed ? 'Click to expand the action panel' : ''"
    @click="() => { if (isCollapsed) toggle() }">

    <!-- Panel title bar -->
    <header class="info-bar bg-indigo-6 row items-center q-pt-sm q-pb-sm">
      <div class="button-toggle row q-pa-sm">
        <q-btn dense flat round size="sm" color="white"
          :icon="isCollapsed ? 'expand_less' : 'expand_more'" @click.stop="toggle()">
        </q-btn>
      </div>

      <div class="title q-pa-xs row items-center" @click.stop="toggle()">
        <span v-if="isBatch" class="text-subtitle1 text-grey-1">
          {{ `${devices.length} devices selected` }}
          <sc-tooltip
            :text="devices.map(d => getDeviceLabel(d)).join(devices.length > 10 ? ', ' : '<br>')" />
        </span>
        <span v-else class="text-subtitle1 text-grey-1">
          {{ getDeviceDescription(device) }} selected
        </span>
      </div>

      <q-space>
      </q-space>

      <div class="tags q-mr-md" v-if="canTag">
        <sc-device-tags :devices="devices">
        </sc-device-tags>
      </div>

      <div class="button-close row q-pa-sm">
        <q-btn dense flat round icon="close" @click.stop="close()" size="sm" color="white">
        </q-btn>
      </div>
    </header>

    <sc-tabs v-if="!isCollapsed" :model-value="tab" @update:model-value="tabSelected" align="left"
      inline-label no-caps class="text-grey-10">
      <q-tab name="actions" icon="settings" label="Actions" :ripple="false"
        :class="tabClass('actions')">
      </q-tab>
      <q-tab name="alerts" v-if="canConfigureAlerts" icon="notifications" label="Alerts"
        :ripple="false" :class="tabClass('alerts')"></q-tab>
      <q-tab name="notes" icon="notes" label="Notes" :ripple="false" :class="tabClass('notes')">
      </q-tab>
      <q-tab name="audit" v-if="canShowAudit" icon="list" label="Audit" :ripple="false"
        :class="tabClass('audit')">
      </q-tab>
    </sc-tabs>

    <sc-tab-panels v-if="!isCollapsed" v-model="tab" class="bg-white">
      <q-tab-panel name="actions">
        <sc-device-actions @execute="actionExecuted" @close="actionClosed">
        </sc-device-actions>
      </q-tab-panel>
      <q-tab-panel name="alerts" v-if="canConfigureAlerts">
        <sc-device-alerts></sc-device-alerts>
      </q-tab-panel>
      <q-tab-panel name="notes" v-if="canEditNotes">
        <sc-device-notes :items="items" :devices="devices" @save="save()">
        </sc-device-notes>
      </q-tab-panel>
      <q-tab-panel name="audit" v-if="canShowAudit">
        <sc-device-audit></sc-device-audit>
      </q-tab-panel>
    </sc-tab-panels>

  </div>
</template>

<style lang="scss" scoped>
.panel {
  width: 630px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.collapsed {
    overflow: hidden;

    .info-bar {
      flex: 1;
      flex-basis: 100%;
    }
  }

  .info-bar {
    flex: 0;
    cursor: pointer;
  }
}
</style>
