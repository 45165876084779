<script>
import { differenceInDays } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'
import { findBiggest } from '@stellacontrol/utilities'
import { Confirmation, Color } from '@stellacontrol/client-utilities'
import { getDeviceLabel } from '@stellacontrol/model'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  props: {
    // Pending premium service associated with device
    pendingPremiumService: {
      type: Object
    }
  },

  computed: {
    ...mapGetters([
      'getFeature'
    ]),

    // Premium subscriptions currently associated with the viewed device
    subscriptions () {
      const { device, organizationGuardian } = this
      return organizationGuardian.getDeviceSubscriptions(device) || []
    },

    // Premium subscriptions currently active
    activeSubscriptions () {
      return this.subscriptions.filter(s => s.isActive)
    },

    // Indicates whether device has any subscriptions
    hasSubscriptions () {
      return this.subscriptions.length > 0
    },

    // Indicates whether device has any subscriptions
    hasActiveSubscriptions () {
      return this.activeSubscriptions.length > 0
    },

    // Finds the subscription to show
    lastSubscription () {
      if (this.allSubscriptionsExpired) {
        // if all expired, show the most recently expired one
        return findBiggest(this.subscriptions, 'expiresAt')
      } else {
        // otherwise show the most recently activated one
        return findBiggest(this.subscriptions, 'startedAt')
      }
    },

    // Indicates whether device has any subscriptions which aren't active yet
    // because their start date is in future
    hasActiveFutureSubscription () {
      return this.lastSubscription &&
        this.lastSubscription.startsAt > new Date()
    },

    // Indicates that the current subscription expires soon
    expiresSoon () {
      return this.lastSubscription &&
        this.lastSubscription.expiresAt >= new Date() &&
        differenceInDays(this.lastSubscription.expiresAt, new Date()) <= 31
    },

    // Indicates whether all subscriptions have expired on this device
    allSubscriptionsExpired () {
      const { hasSubscriptions, subscriptions } = this
      return hasSubscriptions && subscriptions.every(s => s.isExpired)
    },

    // Indicates that premium service can be started on this device,
    // assigned to the device when it was sold to its current owner
    hasPendingPremiumService () {
      const { pendingPremiumService, organizationGuardian } = this
      return pendingPremiumService && organizationGuardian.mustUse('premium-services-buy')
    },

    // Indicates whether current user has right to start the pending premium service
    canStartPremiumService () {
      return this.hasPendingPremiumService &&
        ((this.isMyDevice && this.canUse('premium-services-buy') ||
          this.canUse('premium-services-sell')))
    },

    // Widget CSS class
    widgetClass () {
      const { allSubscriptionsExpired, expiresSoon, hasActiveSubscriptions, hasPendingPremiumService } = this
      return {
        'is-not-active': hasPendingPremiumService,
        'is-active': hasActiveSubscriptions,
        'expires-soon': expiresSoon,
        'expired': allSubscriptionsExpired
      }
    },

    // Widget CSS style
    widgetStyle () {
      const { hasActiveSubscriptions, lastSubscription } = this
      const color = hasActiveSubscriptions
        ? lastSubscription.service.getColor()
        : undefined
      return {
        'background-color': Color.getColor(color)
      }
    },

    // User-friendly label of a premium service
    serviceLabel () {
      return service => service.code
        ? `Service ${service.code}`
        : service.name
    },

    // Label to display on the widget
    widgetLabel () {
      const { hasSubscriptions, lastSubscription, allSubscriptionsExpired, hasPendingPremiumService, pendingPremiumService, expiresSoon } = this
      if (hasPendingPremiumService) {
        return `${this.serviceLabel(pendingPremiumService)} not active`
      }

      if (hasSubscriptions) {
        return allSubscriptionsExpired
          ? `${this.serviceLabel(lastSubscription.service)} expired`
          : `${this.serviceLabel(lastSubscription.service)} ${expiresSoon ? 'expires soon' : ''}`
      }

      return 'No premium service'
    },

    // Widget icon color
    iconColor () {
      const { allSubscriptionsExpired, hasActiveSubscriptions, hasPendingPremiumService, lastSubscription } = this
      if (allSubscriptionsExpired) return 'red-8'
      if (hasActiveSubscriptions) return lastSubscription.service.getColor()
      if (hasPendingPremiumService) return 'orange-8'
      return 'grey-6'
    },

    // Determines whether the widget should be shown.
    // Don't, if device owner is not required to purchase premium services,
    // or when looking at device part - for these, their parent device carries the subscription.
    isVisible () {
      return !this.isDevicePart &&
        this.organizationGuardian.mustUse('premium-services-buy')
    }
  },

  methods: {
    ...mapActions([
      'startDeviceSubscriptions',
      'reloadRoute'
    ]),

    // Starts the premium service attached to the device
    async startPremiumService () {
      const { hasPendingPremiumService, pendingPremiumService, device } = this
      if (hasPendingPremiumService) {
        const message = `Activate ${pendingPremiumService.label} on ${getDeviceLabel(device)}?`
        const ask = await Confirmation.ask({ message })
        if (ask) {
          await this.startDeviceSubscriptions({ devices: [device] })
          await this.reloadRoute()
        }
      }
    }
  }
}
</script>

<template>
  <div v-if="isVisible" class="widget mini-widget device-subscriptions" :class="widgetClass">
    <div class="background" :style="widgetStyle"></div>
    <div class="content row q-ml-md items-center no-wrap pointer">
      <q-icon size="32px" name="euro" :color="iconColor">
      </q-icon>
      <span class="q-ml-sm">
        {{ widgetLabel }}
      </span>
      <q-space></q-space>

      <q-btn round flat dense size="11px" icon="more_horiz">
      </q-btn>

      <q-popup-proxy ref="popup">
        <main class="details" :class="widgetClass">
          <header class="bg-indigo-7 text-white text-bold q-pa-sm row items-center">
            <span>
              Premium Service
            </span>
            <q-space></q-space>
            <q-btn size="sm" round flat dense icon="close" color="white" v-close-popup>
            </q-btn>
          </header>

          <div class="q-pa-md">
            <div v-if="hasPendingPremiumService">
              <div>
                Service:
                <b>{{ pendingPremiumService.code || pendingPremiumService.name }}</b>
              </div>

              <div v-if="canStartPremiumService" class="q-mt-sm">
                Activate the premium service now, to enable the following features:
              </div>
              <div v-else class="q-mt-sm">
                Ask your reseller to activate the service, to enable the following features:
              </div>

              <div class="q-mt-sm row items-center"
                v-for="feature in pendingPremiumService.features">
                <q-icon name="fiber_manual_record" size="9px" class="q-ml-xs q-mr-xs"></q-icon>
                <span>
                  {{ getFeature(feature).description }}
                </span>
              </div>

              <div class="column items-center q-mt-md q-mb-md">
                <q-btn v-if="canStartPremiumService" flat dense unelevated class="success"
                  label="Activate" size="md" icon="play_circle" @click="startPremiumService()">
                </q-btn>
              </div>
            </div>

            <div v-else-if="hasSubscriptions && allSubscriptionsExpired">
              <div>
                Service:
                <b>{{ lastSubscription.service.code || lastSubscription.service.name }}</b>
              </div>
              <div>
                Expired on
                <span class="text-bold">
                  {{ date(lastSubscription.expiresAt) }}
                </span>
              </div>
              <div class="q-mt-sm">
                Functionality no longer available:
                <div class="q-mt-xs row items-center"
                  v-for="feature in lastSubscription.service.features">
                  <q-icon name="fiber_manual_record" size="9px" class="q-ml-xs q-mr-xs"></q-icon>
                  <span>
                    {{ getFeature(feature).description }}
                  </span>
                </div>
              </div>
            </div>

            <div v-else-if="hasSubscriptions && hasActiveSubscriptions">
              <div>
                Service:
                <b>{{ lastSubscription.service.code || lastSubscription.service.name }}</b>
              </div>
              <div>
                {{ hasActiveFutureSubscription ? 'Will be active from:' : 'Active from:' }}
                <span class="text-bold">
                  {{ date(lastSubscription.startsAt) }}
                </span>
              </div>

              <div>
                Expires on:
                <span class="text-bold">
                  {{ date(lastSubscription.expiresAt) }}
                </span>
              </div>

              <div class="q-mt-sm">
                Available functionality:
                <div class="q-mt-xs row items-center"
                  v-for="feature in lastSubscription.service.features">
                  <q-icon name="fiber_manual_record" size="9px" class="q-ml-xs q-mr-xs"></q-icon>
                  <span>
                    {{ getFeature(feature).description }}
                  </span>
                </div>
              </div>
            </div>

            <div v-else>
              The device does not have any premium service.
              <br>
              <br>
              Contact your reseller to purchase premium services
              and get access to live status monitoring, device alerts,
              automatic firmware updates and many other exciting features!
            </div>
          </div>
        </main>

      </q-popup-proxy>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.device-subscriptions {
  background-color: #ffffff;
  position: relative;
  padding-left: 0px;

  .background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }

  .content {
    z-index: 2;
  }

  &.is-not-active {
    .background {
      background-color: #fddb47;
    }
  }

  &.expires-soon {
    .background {
      background-color: #ffa600;
    }
  }

  &.expired {
    .background {
      background-color: #ff1e00;
    }
  }
}

.details {
  width: 300px;
  background-color: #f8f8ff;
}

/* Layout adjustments for small screens */
@media screen and (width <=640px) {
  .details {
    font-size: 15px;
  }
}
</style>
