<script>
import { dateCompareReverse } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    // Returns the date when my organization has sold this device the last time
    lastSoldByMe () {
      const { item, currentOrganization } = this
      const { links = [] } = item.device

      // When device is owned by us, don't show sell date
      if (item.device.ownerId === currentOrganization.id) return

      // Otherwise find out when we've sold this device for the last time
      const mine = links
        .filter(l => l.creatorOrganizationId === currentOrganization.id)
        .map(l => l.createdAt)
      if (mine.length > 0) {
        mine.sort(dateCompareReverse)
        return mine[0]
      }

      // If we haven't sold the device, it likely means that the device
      // went directly to the customer over my head. In such case, show
      // the most recent sell date
      return item.device.soldAt
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="text clickable clip">
    {{ lastSoldByMe ? date(lastSoldByMe) : '' }}
  </div>
</template>

<style></style>