<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import { FormMixin } from '@stellacontrol/client-utilities'
import { DateRange } from '@stellacontrol/model'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    // Indicates whether editing is enabled
    isEnabled: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapState({
      // Selected organization
      organizationId: state => state.alertsView.statisticsFilter.organizationId,
      // Recently loaded statistics
      statistics: state => state.alertsView.statistics,
      // Selected period
      from: state => state.alertsView.statisticsFilter.from,
      to: state => state.alertsView.statisticsFilter.to,
      // Fetch the entire hierarchy
      all: state => state.alertsView.statisticsFilter.all
    }),

    ...mapGetters([
      'organizations'
    ]),

    // Selected period
    period () {
      const { from, to } = this
      return new DateRange({ from, to })
    },

    // Indicates that initial statistics were loaded
    isInitialized () {
      return this.statistics != null
    },

    // Currently selected organization
    selectedOrganization () {
      const organization = this.organizations.find(o => o.id === this.organizationId)
      return organization
    },

    // Indicates that current organization is selected
    isCurrentOrganization () {
      return this.selectedOrganization?.id === this.currentOrganization.id
    }
  },

  methods: {
    ...mapActions([
      'filterAlertStatistics'
    ]),

    selectOrganization (id) {
      const organization = this.organizations.find(o => o.id === id)
      if (organization) {
        this.filterAlertStatistics({ organization })
      }
    },

    selectFrom (from) {
      if (from) {
        this.filterAlertStatistics({ from })
      }
    },

    selectTo (to) {
      if (to) {
        this.filterAlertStatistics({ to })
      }
    },

    selectPeriod (period) {
      const { from, to } = period || DateRange.today()
      this.filterAlertStatistics({ from, to })
    },

    selectAll (all) {
      if (all != null) {
        this.filterAlertStatistics({ all })
      }
    }
  }
}
</script>


<template>
  <div class="period-filter">
    <sc-date-range-input class="q-mr-sm" bg-color="white" dense :input-style="{ width: '160px' }"
      :disable="!isEnabled" :model-value="period" :extended="true"
      @update:model-value="value => selectPeriod(value)">
    </sc-date-range-input>
  </div>

  <div class="row q-ml-sm">
    <sc-organization-selector class="q-mr-sm" :items="organizations" :dense="true"
      :disable="!isEnabled" :model-value="organizationId"
      @update:model-value="value => selectOrganization(value)" label="Organization">
    </sc-organization-selector>

    <q-checkbox v-if="false" :model-value="all" @update:model-value="value => selectAll(value)"
      :disable="!isEnabled" label="Fetch statistics for the entire hierarchy">
    </q-checkbox>

    <div v-if="selectedOrganization" class="q-pt-sm">
      <span>
        The statistics include
      </span>
      <span v-if="isCurrentOrganization">
        all your devices
      </span>
      <span v-else>
        all devices of {{ selectedOrganization.name }}
      </span>
      <span v-if="selectedOrganization.canHaveChildOrganizations && isCurrentOrganization">
        and devices of your customers
      </span>
      <span v-else-if="selectedOrganization.canHaveChildOrganizations">
        and their customers
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.period-filter {
  min-width: 380px;
}
</style>