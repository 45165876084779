<script>
import { mapState, mapGetters } from 'vuex'
import { formatDateTime, sortItemsWith, dateCompare } from '@stellacontrol/utilities'
import { PlanPalette, PlannerMode } from '@stellacontrol/planner'
import { PlanActions } from '../../renderer/actions/plan-action'

export default {
  props: {
    // Plan renderer
    renderer: {
      required: true
    }
  },

  data () {
    return {
      // Palette configuration
      palette: PlanPalette
    }
  },

  computed: {
    ...mapState({
      // Planner editing mode
      mode: state => state.planner.mode,
      // Edited plan
      plan: state => state.planner.plan
    }),

    ...mapGetters([
      'allUsersDictionary'
    ]),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },

    // Determines whether the plan has any snapshots
    hasSnapshots () {
      return this.plan?.snapshots?.length > 0
    },

    // Sorted snapshots
    snapshots () {
      const { plan: { snapshots } = {} } = this
      if (snapshots) {
        return sortItemsWith(snapshots,
          (a, b) => a.isRecentSnapshot
            ? -1
            : (b.isRecentSnapshot
              ? 1
              : (dateCompare(a.createdAt, b.createdAt))))
      } else {
        return []
      }
    },

    // Returns the tooltip with details about the snapshot
    tooltip () {
      return snapshot => {
        const { plan } = this
        const creator = this.allUsersDictionary[snapshot.createdBy]?.fullName
        const source = plan.restoredFrom === snapshot.id ? plan.getSnapshot({ id: plan.restoredFrom }) : null
        return `
          <b>${snapshot.name}</b>
          <br>
          Created on ${formatDateTime(snapshot.createdAt)}
          ${creator ? '<br>' : ''}
          ${creator ? `Created by ${creator}` : ''}
          ${source ? '<br>' : ''}
          ${source ? `Restored on ${formatDateTime(plan.restoredAt)}` : ''}
          ${snapshot.description ? '<br>' : ''}
          ${snapshot.description || ''}
        `
      }
    },

    // Checks whether the edited plan has been restored from the specified snapshot
    restoredFrom () {
      return snapshot => {
        return this.plan.restoredFrom === snapshot.id
      }
    },

    // Determines whether the snapshot can be deleted
    canDelete () {
      return snapshot => {
        return !snapshot.isReadOnly
      }
    }
  },

  emits: [
    'action'
  ],

  methods: {
    // Passes the action requested in the properties
    executeAction (details) {
      this.$emit('action', details)
    },

    // Restores the selected snapshot as current plan
    restoreSnapshot (snapshot) {
      this.executeAction({
        action: PlanActions.RestorePlanSnapshot,
        snapshot
      })
    },

    // Deletes the selected snapshot
    deleteSnapshot (snapshot) {
      this.executeAction({
        action: PlanActions.DeletePlanSnapshot,
        snapshot
      })
    }
  }
}

</script>

<template>
  <div class="plan-snapshots q-pa-sm">
    <div v-if="hasSnapshots" class="items">
      <div class="item row items-center q-pa-xs" v-for="snapshot in snapshots">

        <q-icon name="info" size="16px" class="q-mr-sm" :color="restoredFrom(snapshot) ? 'green-8' : 'indigo-6'" >
          <sc-tooltip :text="tooltip(snapshot)">
          </sc-tooltip>
        </q-icon>

        <span :class="{ 'text-bold': restoredFrom(snapshot) }">
          {{ snapshot.name }}
        </span>

        <q-space>
        </q-space>

        <div class="buttons">
          <q-btn dense flat round size="11px" icon="delete" @click="deleteSnapshot(snapshot)"
            color="red-10" v-if="canDelete(snapshot)">
            <sc-tooltip nowrap>
              Delete the snapshot
            </sc-tooltip>
          </q-btn>

          <q-btn dense flat round size="11px" icon="history" color="indigo-7"
            @click="restoreSnapshot(snapshot)">
            <sc-tooltip nowrap>
              {{ snapshot.isRecentSnapshot ? 'Restore the recent plan' : 'Restore the snapshot' }}
            </sc-tooltip>
          </q-btn>
        </div>

      </div>
    </div>
    <div v-else>
      There are no snapshots yet
    </div>
  </div>
</template>

<style lang="scss" scoped>
.plan-snapshots {
  display: flex;
  flex-direction: column;
  user-select: none;

  .items {
    max-height: 400px;
    overflow: auto;

    .item {
      height: 36px;
      cursor: pointer;
      border: solid transparent 1px;

      .buttons {
        display: none;
      }

      &:hover {
        border: solid #0000001f 1px;
        background-color: #f0f0f0;

        .buttons {
          display: block;
        }
      }
    }
  }
}
</style>
