<script>
import { AvailableCableTypes } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import LineStyleProperties from './line-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-line-style-properties': LineStyleProperties
  },

  data() {
    return {
      AvailableCableTypes
    }
  },

  computed: {
    defaults () {
      return this.item.defaults
    }
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-item-properties>

  <main class="plan-item-properties">
    <section>
      <label>
        Cable Type
      </label>
      <q-select outlined square dense emit-value map-options :options="AvailableCableTypes"
        :style="styles.select.wide" :model-value="getValue('cableType', defaults.cableType)"
        @update:model-value="value => setProperties({ 'cableType': value })">
      </q-select>
    </section>
  </main>

  <sc-line-style-properties :renderer="renderer" :items="items" @action="executeAction" v-if="item.isCustom">
  </sc-line-style-properties>
</template>

<style lang="scss" scoped></style>
