import { differenceInSeconds, isToday } from 'date-fns'

/**
 * Possible states of live connection with the device
 */
export const DeviceConnectionStatus = {
  // Nothing is known yet, the data about device status has not yet been requested from the system
  Unknown: 'unknown',
  // The device has never connected nor reported its status
  NeverConnected: 'never-connected',
  // The device is online and is continuously reporting its status
  Online: 'online',
  // The device is online in heartbeat mode
  Heartbeat: 'heartbeat',
  // The device has connected at some point, but hasn't given much of a sign of life since long time
  Lost: 'lost',
  // An error has happened while retrieving device status
  Error: 'error'
}

/**
 * Thresholds for determining device connection status
 * based on status age
 */
export const DeviceConnectionAge = {
  [DeviceConnectionStatus.Online]: 60,
  [DeviceConnectionStatus.Heartbeat]: 35 * 60
}

/**
 * Sort order for connection status
 */
export const DeviceConnectionStatusOrder = {
  [DeviceConnectionStatus.Online]: 0,
  [DeviceConnectionStatus.Heartbeat]: 1,
  [DeviceConnectionStatus.Lost]: 2,
  [DeviceConnectionStatus.NeverConnected]: 3,
  [DeviceConnectionStatus.Unknown]: 4,
  [DeviceConnectionStatus.Error]: 5
}

/**
 * Colors representing various connection states
 */
export const DeviceConnectionStatusColor = {
  [DeviceConnectionStatus.Unknown]: 'transparent',
  [DeviceConnectionStatus.NeverConnected]: '#e0e0e0',
  [DeviceConnectionStatus.Online]: '#2ecd6f',
  [DeviceConnectionStatus.Heartbeat]: '#82caa0',
  [DeviceConnectionStatus.Lost]: '#6b6b6b',
  [DeviceConnectionStatus.Error]: '#e20400'
}

/**
 * Colors representing various connection states using background color, which is lighter than the above
 */
export const DeviceConnectionStatusBackgroundColor = {
  [DeviceConnectionStatus.Unknown]: '#f8f8f8',
  [DeviceConnectionStatus.NeverConnected]: '#f8f8f8',
  [DeviceConnectionStatus.Online]: '#bcf3b7',
  [DeviceConnectionStatus.Heartbeat]: '#dbf5e6',
  [DeviceConnectionStatus.Lost]: '#d8d8d8',
  [DeviceConnectionStatus.Error]: '#fd615e'
}

/**
 * Text colors matching the above, for good visibility
 */
export const DeviceConnectionStatusTextColor = {
  [DeviceConnectionStatus.Unknown]: '#f8f8f8',
  [DeviceConnectionStatus.NeverConnected]: '#080808',
  [DeviceConnectionStatus.Online]: '#ffffff',
  [DeviceConnectionStatus.Heartbeat]: '#ffffff',
  [DeviceConnectionStatus.Lost]: '#080808',
  [DeviceConnectionStatus.Error]: '#ff0000'
}

/**
 * User-friendly descriptions of various connection states
 */
export const DeviceConnectionStatusName = {
  [DeviceConnectionStatus.Unknown]: 'Status unknown',
  [DeviceConnectionStatus.NeverConnected]: 'Never connected',
  [DeviceConnectionStatus.Online]: 'Device connected',
  [DeviceConnectionStatus.Heartbeat]: 'Device connected',
  [DeviceConnectionStatus.Lost]: 'Device lost',
  [DeviceConnectionStatus.Error]: 'Error retrieving status'
}


/**
 * Determines device connection status using its age
 * @param {Date} receivedAt Date and time when last device status has been received
 * @param {DeviceConnectionStatus} defaultValue Default status to assign if no status date specified
 * @returns {DeviceConnectionStatus} Device connection status
 */
export function getDeviceConnectionStatus (receivedAt, defaultValue = DeviceConnectionStatus.NeverConnected) {
  if (receivedAt) {
    const status = isDeviceConnectionRealTime(receivedAt)
      ? DeviceConnectionStatus.Online
      : (isDeviceConnectionRecent(receivedAt)
        ? DeviceConnectionStatus.Heartbeat
        : DeviceConnectionStatus.Lost)
    return status
  } else {
    return defaultValue
  }
}


/**
 * Determines whether device connection status is real-time (<=60s)
 * @param {Date} receivedAt Date and time when last device status has been received
 * @returns {Boolean}
 */
export function isDeviceConnectionRealTime (receivedAt) {
  if (receivedAt) {
    const age = differenceInSeconds(new Date(), receivedAt)
    return age <= DeviceConnectionAge[DeviceConnectionStatus.Online]
  }
}

/**
 * Determines whether device connection status is reliably new.
 * @param {Date} receivedAt Date and time when last device status has been received
 * @returns {Boolean}
 */
export function isDeviceConnectionRecent (receivedAt) {
  if (receivedAt) {
    const age = differenceInSeconds(new Date(), receivedAt)
    return age > DeviceConnectionAge[DeviceConnectionStatus.Online] && age <= DeviceConnectionAge[DeviceConnectionStatus.Heartbeat]
  }
}

/**
 * Determines whether device connection status is old enough to treat device as lost.
 * @param {Date} receivedAt Date and time when last device status has been received
 * @returns {Boolean}
 */
export function isDeviceConnectionLost (receivedAt) {
  if (receivedAt) {
    const age = differenceInSeconds(new Date(), receivedAt)
    return age > DeviceConnectionAge[DeviceConnectionStatus.Heartbeat]
  }
}

/**
 * Determines whether device connection status came in today
 * @param {Date} receivedAt Date and time when last device status has been received
 * @returns {Boolean}
 */
export function isDeviceConnectionToday (receivedAt) {
  if (receivedAt) {
    return isToday(receivedAt)
  }
}
