<script>
import { dateCompareReverse, formatDateTime, sortItems } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  data () {
    return {
      tooltip: 'Loading ...'
    }
  },

  computed: {
    // Returns the date when my organization has sold this device the last time
    lastSoldByMe () {
      const { item, currentOrganization } = this
      const { links = [] } = item.device

      // When device is owned by us, don't show sell date
      if (item.device.ownerId === currentOrganization.id) return

      // Otherwise find out when we've sold this device for the last time
      const mine = links
        .filter(l => l.creatorOrganizationId === currentOrganization.id)
        .map(l => l.createdAt)
      if (mine.length > 0) {
        mine.sort(dateCompareReverse)
        return mine[0]
      }

      // If we haven't sold the device, it likely means that the device
      // went directly to the customer over my head. In such case, show
      // the most recent sell date
      return item.device.soldAt
    },

    // Checks whether the tooltip showing ownership history should be displayed
    showTooltip () {
      return this.item.device.links.length > 0 && this.isSuperAdministrator
    }
  },

  methods: {
    // Populates the tooltip showing device ownership history
    async loadTooltip () {
      const { item: { device } } = this
      const lines = []
      const links = sortItems(device.links || [], 'createdAt')
      lines.push('<b>Ownership history:</b>')
      lines.push('<div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 4px; margin-top: 8px;">')
      lines.push(...links.map(link =>
        `<span>
          ${link.principal.isSuperOrganization ? 'Added to stock' : `Sold to ${link.principal.name}`}
         </span>
         <span>
          ${formatDateTime(link.createdAt)}
         </span>
        `))
      lines.push('</div>')
      this.tooltip = lines.join('')
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="text clickable clip">
    {{ lastSoldByMe ? date(lastSoldByMe) : '' }}
    <sc-tooltip v-if="showTooltip" @show="loadTooltip()" :text="tooltip">
    </sc-tooltip>
  </div>
</template>

<style></style>