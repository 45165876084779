<script>
import { Place, getPlaceIcon } from '@stellacontrol/model'

export default {
  props: {
    // Identifier of a place selected initially,
    // passed with `v-model` attribute
    modelValue: {
      required: true
    },

    // List of all places
    items: {
      required: true
    },

    // If true, a special item representing STOCK is added on top of the list
    stock: {
      value: false
    },

    // Label for NO PLACE item
    stockLabel: {
      value: '- Stock -'
    }
  },

  data () {
    return {
      selection: null,
      unassignedPlace: null,
      matchingItems: []
    }
  },

  watch: {
    value () {
      this.selection = this.modelValue || (this.stock ? Place.ID_STOCK : undefined)
    },

    items () {
      this.matchingItems = [...this.allItems]
      this.selection = this.modelValue || (this.stock ? Place.ID_STOCK : undefined)
    }
  },

  computed: {
    // All places to show in the list
    allItems () {
      return this.stock
        ? [this.unassignedPlace, ...this.items]
        : [...this.items]
    }
  },

  methods: {
    getPlaceIcon,

    // Notify about model change.
    // When NO PLACE selected, emit `undefined`
    placeSelected (value) {
      this.$emit('update:model-value', (value === Place.ID_STOCK || !value) ? undefined : value)
    },

    // Filter function for free-text filtering of places by name
    filterPlaces (value = '', update) {
      update(() => {
        if (value === '') {
          this.matchingItems = [...this.allItems]
        } else {
          const text = value.toLowerCase()
          this.matchingItems = this.allItems.filter(o => o.name.toLowerCase().includes(text))
        }
      })
    }
  },

  created () {
    this.unassignedPlace = new Place({ id: Place.ID_STOCK, name: this.stockLabel })
    this.selection = this.modelValue || (this.stock ? Place.ID_STOCK : undefined)
    this.matchingItems = [...this.allItems]
  }
}
</script>

<template>
  <q-select v-model="selection" :options="matchingItems" outlined emit-value map-options
    option-value="id" option-label="name" use-input debounce="500" fill-input hide-selected
    @filter="filterPlaces" @update:model-value="value => placeSelected(value)"
    v-bind="{ ...$props, ...$attrs }">
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section side>
          <q-icon :name="getPlaceIcon(scope.opt.placeType)" color="indigo-6" size="sm" />
        </q-item-section>
        <q-item-section>
          <q-item-label v-html="scope.opt.name" />
        </q-item-section>
      </q-item>
    </template>

    <!--
      Pass all other custom slots from the parent,
      which might supply additional custom templates.
    -->
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" :props="slotData"></slot>
    </template>

  </q-select>

</template>

<style></style>