<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { AlertStatisticsPart, AlertStatisticsPartName, AlertStatisticsPartKey } from '@stellacontrol/model'
import FilterComponent from './components/filter.vue'
import DetailsAlerts from './components/details-alerts.vue'
import DetailsDevices from './components/details-devices.vue'
import DetailsPendingNotifications from './components/details-pending-notifications.vue'
import DetailsSentNotifications from './components/details-sent-notifications.vue'
import DetailsConfigurationChanges from './components/details-configuration-changes.vue'
import ErrorComponent from './components/error.vue'

const name = 'alerts'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    'sc-statistics-filter': FilterComponent,
    'sc-statistics-error': ErrorComponent,
    'sc-details-alerts': DetailsAlerts,
    'sc-details-devices': DetailsDevices,
    'sc-details-pending-notifications': DetailsPendingNotifications,
    'sc-details-sent-notifications': DetailsSentNotifications,
    'sc-details-failed-notifications': DetailsSentNotifications,
    'sc-details-configuration-changes': DetailsConfigurationChanges
  },

  data () {
    return {
      name,
      AlertStatisticsPart,
      AlertStatisticsPartName,
      // Preferences of the currently logged in user
      preferences: {}
    }
  },

  computed: {
    ...mapState({
      // Statistics filter
      filter: state => state.alertsView.statisticsFilter,
      detailsFilter: state => state.alertsView.statisticsFilter.detailsFilter || '',
      // Retrieved alert statistics, merged for all organizations in the hierarchy
      statistics: state => state.alertsView.mergedStatistics,
      // Recent error
      error: state => state.alertsView.error,
      // Viewed details
      part: state => state.alertsView.part,
      partName: state => AlertStatisticsPartName[state.alertsView.part],
      partLoaded: state => state.alertsView.partLoaded,
      // Details refresh countdown
      autoRefresh: state => state.alertsView.refresh.clock != null,
      refreshCountdown: state => state.alertsView.refresh.countdown,
      isRefreshing: state => state.alertsView.refresh.isRefreshing
    }),

    ...mapGetters([
      'isBusy',
      'isBusyWith',
      'isNavigating',
      'isProductionEnvironment',
      'organizations',
      'alertStatisticsDetails',
      'visibleAlertStatisticsDetails'
    ]),

    // Checks whether alert statistics are loaded
    hasStatistics () {
      return this.statistics != null && !this.isNavigating
    },

    // Indicates that there was an error while loading statistics
    hasError () {
      return this.error != null
    },

    // Checks whether the selected statistics details are loaded
    hasDetails () {
      if (this.partLoaded) {
        const key = AlertStatisticsPartKey[this.part]
        const items = this.statistics?.details[key] || []
        return items.length > 0
      }
    },

    // Checks whether we're now loading the statistics
    isLoadingStatistics () {
      return this.isBusy && this.isBusyWith('load-statistics')
    },

    // Checks whether we're now loading the selected details
    isLoadingDetails () {
      return this.isBusy && this.isBusyWith('load-part')
    },

    // True if viewing the statistics of the current organization
    isCurrentOrganization () {
      return this.currentOrganization.id === this.filter?.organizationId
    },

    // True if any alerts were detected
    hasAlerts () {
      return this.statistics?.counters?.alerts > 0
    },

    // True if any failing devices were detected
    hasDevices () {
      return this.statistics?.counters?.devices > 0
    },

    // True if any notifications were created
    hasPendingNotifications () {
      return this.statistics?.counters?.pendingNotifications > 0
    },

    // True if any notifications were sent
    hasSentNotifications () {
      return this.statistics?.counters?.sentNotifications > 0
    },

    // True if any notifications have failed
    hasFailedNotifications () {
      return this.statistics?.counters?.failedNotifications > 0
    },

    // True if any configuration changes have happened
    hasConfigurationChanges () {
      return this.statistics?.counters?.configurationChanges > 0
    },

    // Indicates whether current user can receive
    // and has opted in to receive emails with alert notifications
    receivesAlertNotifications () {
      return this.isAdministrator && this.preferences['notifications-receive']
    },

    // Indicates whether current user can receive
    // and has opted in to receive emails with alert notifications of their customers
    receivesCustomerAlertNotifications () {
      return this.isAdministrator && this.preferences['notifications-receive-customers']
    },

    // Items loaded in the details view
    details () {
      const { statistics } = this
      return this.alertStatisticsDetails(statistics) || []
    },

    // Items visible in details view after applying filters
    visibleDetails () {
      const { statistics } = this
      return this.visibleAlertStatisticsDetails(statistics) || []
    },

    // Viewed organization
    organization () {
      const { filter: { organizationId } } = this
      return this.organizations.find(o => o.id === organizationId)
    },

    // Label indicating which devices are being analyzed
    ownerLabel () {
      const { filter: { all }, organization, currentOrganization, isCurrentOrganization } = this
      if (organization) {
        if (isCurrentOrganization) {
          if (currentOrganization.canHaveChildOrganizations && all) {
            return 'under your management'
          } else {
            return 'in your inventory'
          }
        } else {
          if (organization.canHaveChildOrganizations && all) {
            return `under ${organization.name}`
          } else {
            return `of ${organization.name}`
          }
        }
      }
    },

    // Component for viewing the selected statistics details
    detailsComponent () {
      const { part, statistics } = this
      if (part && statistics) {
        return `sc-details-${part}`
      }
    },

    // CSS class for the above component
    detailsClass () {
      const { part, statistics, partLoaded } = this
      if (part && statistics) {
        return { 'loaded': partLoaded }
      }
    },

    // Determines whether the specified part of alert stats should be visible
    // for the current user
    isPartVisible () {
      return part => {
        const { isSuperAdministrator, canUse, hasFailedNotifications } = this
        switch (part) {
          case AlertStatisticsPart.Alerts:
          case AlertStatisticsPart.Devices:
            return true

          case AlertStatisticsPart.PendingNotifications:
          case AlertStatisticsPart.SentNotifications:
            return isSuperAdministrator && canUse('alerts-subscribe')

          case AlertStatisticsPart.FailedNotifications:
            return isSuperAdministrator && hasFailedNotifications && canUse('alerts-subscribe')

          case AlertStatisticsPart.ConfigurationChanges:
            return isSuperAdministrator && canUse('alerts-configure')

          default:
            return false
        }
      }
    },

    // Indicates whether user can delete the selected records
    canDelete () {
      const { isSuperAdministrator, isProductionEnvironment, part, partLoaded, visibleDetails } = this
      return isSuperAdministrator &&
        !isProductionEnvironment &&
        part &&
        partLoaded &&
        visibleDetails.length > 0 &&
        part === AlertStatisticsPart.Alerts
    },

    // Returns the stats about data that is protected from viewing by current user
    protectedData () {
      return this.statistics?.getProtectedData(this.part)
    },

    allDataIsProtected () {
      const { protectedData } = this
      return Boolean(protectedData && protectedData.visibleCount === 0)
    }
  },

  methods: {
    ...mapActions([
      'getUserPreferences',
      'loadAlertStatistics',
      'filterAlertStatisticsDetails',
      'deleteAlertOccurrences'
    ]),

    // Loads alert statistics
    showStatistics () {
      const autoRefresh = true
      return this.loadAlertStatistics({ autoRefresh })
    },

    // Loads statistics details for the selected section
    showDetails (part, available) {
      if (part && available) {
        return this.filterAlertStatisticsDetails({ part })
      }
    },

    // Filters the details table
    filterDetails (filter) {
      const { part } = this
      if (part) {
        this.filterAlertStatisticsDetails({ part, filter })
      }
    },

    // Deletes the currently displayed alerts (only those visible!)
    async deleteAlerts () {
      const { part, partLoaded, statistics, visibleDetails: alerts } = this
      if (part === AlertStatisticsPart.Alerts && partLoaded && statistics) {
        if (alerts?.length > 0) {
          await this.deleteAlertOccurrences({ alerts, confirm: true })
        }
      }
    }
  },

  async created () {
    this.preferences = await this.getUserPreferences()
    await this.showStatistics()
  }
}

</script>

<template>
  <sc-view :name="name">
    <template #header>
      <div class="row q-pl-md q-pr-md q-pt-md bg-white">
        <sc-statistics-filter :isEnabled="!(isLoadingStatistics || isLoadingDetails)">
        </sc-statistics-filter>
      </div>
    </template>

    <div v-if="isLoadingStatistics" class="loading">
      <q-inner-loading :showing="true">
        <span class="text-gray-9 title q-mb-md">
          Loading alert statistics ...
        </span>
        <q-spinner-gears size="48px" color="grey-8"></q-spinner-gears>
      </q-inner-loading>
    </div>

    <div v-else-if="hasError || hasStatistics" class="content q-pa-md">
      <sc-statistics-error v-if="hasError">
      </sc-statistics-error>

      <section v-if="hasStatistics" class="counters">
        <q-banner dense class="counters-header bg-indigo-2 q-pl-md">
          <div class="row items-center">
            <span class="text-subtitle1">
              Alerts Summary
            </span>
            <q-space>
            </q-space>
            <q-icon v-if="isRefreshing" name="motion_photos_on" class="rotate q-ml-sm" size="sm"
              color="indigo-7">
            </q-icon>
            <span v-else-if="autoRefresh && !(isLoadingStatistics || isLoadingDetails || hasError)"
              class="text-subtitle1 q-ml-sm text-grey-8">
              Refreshing in {{ duration(refreshCountdown) }}
            </span>
          </div>
        </q-banner>
        <div class="counters-content">
          <!-- Alerts count -->
          <article class="tile" v-if="isPartVisible(AlertStatisticsPart.Alerts)"
            :class="{ selected: part === AlertStatisticsPart.Alerts, 'has-details': hasAlerts }"
            @click="showDetails(AlertStatisticsPart.Alerts, hasAlerts)">
            <div class="value" :class="{ 'text-red-8': hasAlerts, 'text-green-8': !hasAlerts }">
              {{ statistics.counters.alerts }}
            </div>
            <div class="text">
              <template v-if="statistics.counters.alerts === 0">
                No alerts
              </template>
              <template v-else>
                {{ statistics.counters.alerts === 1 ? 'Alert was' : 'Alerts were' }}
              </template>
              detected
            </div>
            <sc-tooltip v-if="hasAlerts">
              Click to see the detected alerts
            </sc-tooltip>
          </article>

          <!-- Devices count -->
          <article class="tile" v-if="isPartVisible(AlertStatisticsPart.Devices)"
            :class="{ selected: part === AlertStatisticsPart.Devices, 'has-details': hasDevices }"
            @click="showDetails(AlertStatisticsPart.Devices, hasDevices)">
            <div class="value" :class="{ 'text-red-8': hasDevices, 'text-green-8': !hasDevices }">
              {{ statistics.counters.devices }}
            </div>
            <div v-if="statistics.counters.devices === 0" class="text">
              {{ capitalize(pluralize(statistics.counters.devices, 'device')) }}
              {{ ownerLabel }}
              reported alerts
            </div>
            <div v-else class="text">
              {{ capitalize(pluralize(statistics.counters.devices, 'device')) }}
              {{ ownerLabel }}
              reported
            </div>

            <sc-tooltip v-if="hasDevices">
              Click to see the devices which reported alerts
            </sc-tooltip>
          </article>

          <!-- Pending notifications count : shown only if organization has permission to receive them -->
          <article class="tile" v-if="isPartVisible(AlertStatisticsPart.PendingNotifications)"
            :class="{ selected: part === AlertStatisticsPart.PendingNotifications, 'has-details': hasPendingNotifications }"
            @click="showDetails(AlertStatisticsPart.PendingNotifications, hasPendingNotifications)">
            <div class="value text-blue-7">
              {{ statistics.counters.pendingNotifications }}
            </div>
            <div class="text">
              Alerts waiting to be sent by e-mail
            </div>

            <sc-tooltip v-if="hasPendingNotifications">
              Click to see the pending alert notifications
            </sc-tooltip>
          </article>

          <!-- Sent notifications count : shown only if organization has permission to receive them -->
          <article class="tile" v-if="isPartVisible(AlertStatisticsPart.SentNotifications)"
            :class="{ selected: part === AlertStatisticsPart.SentNotifications, 'has-details': hasSentNotifications }"
            @click="showDetails(AlertStatisticsPart.SentNotifications, hasSentNotifications)">
            <div class="value text-blue-7">
              {{ statistics.counters.sentNotifications }}
            </div>
            <div v-if="hasSentNotifications" class="text">
              Alerts sent by e-mail
            </div>
            <div v-else class="text">
              No alerts sent by e-mail
            </div>

            <sc-tooltip v-if="hasSentNotifications">
              Click to see the sent alert notifications
            </sc-tooltip>
          </article>

          <!-- Failed notifications count : shown only if any are failed AND organization has permission to receive them -->
          <article class="tile" v-if="isPartVisible(AlertStatisticsPart.FailedNotifications)"
            :class="{ selected: part === AlertStatisticsPart.FailedNotifications, 'has-details': true }"
            @click="showDetails(AlertStatisticsPart.FailedNotifications, true)">
            <div class="value text-red-7">
              {{ statistics.counters.failedNotifications }}
            </div>
            <div class="text">
              Alerts which could not be sent
            </div>
            <sc-tooltip>
              Click to see the failed alert notifications
            </sc-tooltip>
          </article>

          <!-- Alert configuration changes : shown only if allowed to configure alerts -->
          <article class="tile" v-if="isPartVisible(AlertStatisticsPart.ConfigurationChanges)"
            :class="{ selected: part === AlertStatisticsPart.ConfigurationChanges, 'has-details': hasConfigurationChanges }"
            @click="showDetails(AlertStatisticsPart.ConfigurationChanges, hasConfigurationChanges)">
            <div class="value"
              :class="{ 'text-orange-7': hasConfigurationChanges, 'text-blue-6': !hasConfigurationChanges }">
              {{ statistics.counters.configurationChanges }}
            </div>
            <div v-if="statistics.counters.configurationChanges === 0" class="text">
              No alert configurations were changed
            </div>
            <div v-else class="text">
              Alert configuration changes
            </div>

            <sc-tooltip v-if="hasConfigurationChanges">
              Click to see the details of configuration changes
            </sc-tooltip>
          </article>

          <!-- Warning about not subscribing to alert notifications -->
          <article class="q-pa-lg column items-center"
            v-if="currentUser.isAdministrator && !receivesAlertNotifications && !receivesCustomerAlertNotifications">
            <div class="column items-center bg-orange-9 text-white q-pa-md">
              <span>
                Currently you are not receiving alert notification emails.
              </span>
              <br>
              <router-link class="item-link bright"
                :to="{ name: 'preferences', query: { tab: 'user-preferences' } }">
                Go to User Preferences to subscribe.
              </router-link>
            </div>
          </article>

        </div>
      </section>

      <!-- Details of the selected statistic -->
      <section v-if="hasStatistics && isLoadingDetails" class="details loading q-ml-md">
        <q-inner-loading :showing="true">
          <span class="text-gray-9 title q-mb-md">
            Loading {{ lowercase(partName) }} ...
          </span>
          <q-spinner-gears size="48px" color="grey-8"></q-spinner-gears>
        </q-inner-loading>
      </section>

      <section v-else-if="hasStatistics" class="details q-ml-md" :class="detailsClass">
        <q-banner dense v-if="detailsComponent && !allDataIsProtected"
          class="details-header bg-indigo-2">
          <div class="row items-center q-pl-md">
            <span class="text-subtitle1">
              {{ visibleDetails.length }}
              {{ partName }} {{ (detailsFilter && details.length > visibleDetails.length) ? `(of
              ${details.length} in total)` : '' }}
            </span>
            <q-space>
            </q-space>
            <q-btn unelevated v-if="canDelete" label="Delete alerts" class="q-mr-md" color="orange-8"
              @click="deleteAlerts()">
            </q-btn>
            <q-input v-if="hasDetails" dense outlined square clearable class="bg-white"
              :style="{ 'width': '300px' }" label="Filter the table"
              :model-value="detailsFilter[part]" @update:model-value="value => filterDetails(value)"
              debounce="500">
              <template v-slot:append>
                <q-icon color="grey-6" name="info" size="sm">
                  <sc-tooltip>
                    Enter the text to filter by, such as serial number or alert name.
                    <br>
                    <br>
                    Specify alternative conditions using 'or' operator or space.
                    Items containing any of the specified values will be shown:
                    <br>
                    <b>69HJ or 69HG or 69HK</b>
                    <br>
                    <br>
                    Specify multiple conditions using 'and' operator.
                    Only items containing all the specified values will be shown:
                    <br>
                    <b>69HJ and feedback</b>
                    <br>
                    <br>
                    Use 'not' operator to find items which don't match the specified text:
                    <br>
                    <b>not 69HJ and not feedback and not offline</b>
                  </sc-tooltip>
                </q-icon>
              </template>
            </q-input>
          </div>
        </q-banner>

        <!-- When some details were hidden due to security limitations, show the warning -->
        <q-banner dense v-if="detailsComponent && protectedData"
          class="details-header bg-orange-3 q-pl-lg">
          <span v-if="allDataIsProtected">
            To see more details about
            the {{ AlertStatisticsPartName[part].toLowerCase() }}
            you need to activate premium services on your devices.
          </span>
          <span v-else>
            There are {{ protectedData.totalCount }}
            {{ AlertStatisticsPartName[part].toLowerCase() }} ,
            but you can only see {{ protectedData.visibleCount }}
            of them. To see more details you need to activate
            premium services on your devices.
          </span>
        </q-banner>
        <div v-if="detailsComponent && !allDataIsProtected" class="details-content">
          <component :is="detailsComponent" :part="part" :canDelete="canDelete">
          </component>
        </div>
      </section>
    </div>

  </sc-view>
</template>

<style lang='scss' scoped>
.loading {
  flex: 1;
  position: relative;
}

.content {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  .counters {
    overflow: auto;
    display: flex;
    flex-direction: column;
    min-width: 380px;

    .counters-header {
      flex-basis: 60px;
      display: flex;
      flex-direction: row;
    }

    .counters-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      border: solid #0000001f 1px;
      border-top: none;

      .tile {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        flex-wrap: nowrap;
        border-bottom: solid #0000001f 1px;

        *:last-child {
          border-bottom: none;
        }

        &.selected {
          background-color: #e8eaf6;
        }

        &.has-details {
          transition: all 0.15s ease-out;
          cursor: pointer;

          &:hover {
            background-color: #e8eaf6;
          }
        }

        .value {
          padding-left: 18px;
          padding-top: 2px;
          display: flex;
          flex-basis: 80px;
          flex-direction: column;
          justify-content: center;
          font-size: 28px;
        }

        .text {
          padding: 16px;
          padding-top: 20px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 16px;
        }
      }
    }
  }

  .details {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 0;

    &.loading {
      opacity: 1;
    }

    &.loaded {
      opacity: 1;
    }

    .details-header {
      flex-basis: 58px;
      display: flex;
      flex-direction: row;
    }

    .details-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      border: solid #0000001f 1px;
      border-top: none;
    }
  }
}
</style>
