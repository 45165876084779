<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { getDeviceTypeName, getDevicesDescription } from '@stellacontrol/model'
import { DeviceCommand, DeviceCommands, DeviceCommandDefinitions } from '@stellacontrol/devices'

/**
 * Button with drop-down menu for sending commands to devices
 */
export default {
  props: {
    // Devices to send the commands to
    devices: {
      type: Array
    },

    // Device commands to add to the dropdown menu
    commands: {
      type: Array,
      required: true
    },

    // Additional commands to add to the dropdown menu
    customCommands: {
      type: Array,
      default: () => []
    },

    // Text to show in the header
    header: {
      type: String
    },

    // Indicates whether header with device name should be shown
    showHeader: {
      type: Boolean,
      default: true
    },

    horizontal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices || {}
    }),

    ...mapGetters([
      'isDeviceCommandAvailable',
      'currentUser'
    ]),

    // Indicates whether any devices are selected for sending commands to
    hasDevices () {
      return this.devices.length > 0
    },

    // Returns true if multiple devices are target of the command
    isBatch () {
      return this.devices.length > 1
    },

    // Description of devices
    batchDescription () {
      return getDevicesDescription(this.devices)
    },

    // Commands to show in the menu
    availableCommands () {
      const { devices, deviceStatus, commands, customCommands } = this
      const availableCommands = [
        ...(commands || []),
        // Custom commands separated with a dash
        ...(customCommands ? [null, ...customCommands] : [])
      ]

      // Check which commands are available under the current selection of devices
      const visibleCommands = availableCommands
        .filter(command => command && (command.separator || this.isDeviceCommandAvailable(command, devices, deviceStatus)))

      // Filter out leading and trailing separators
      return visibleCommands
        .filter((command, index) => (index === 0 || index === availableCommands.length - 1)
          ? Boolean(command) && (!command.separator)
          : true)
    }
  },

  methods: {
    ...mapActions([
      'showDialog',
      'sendDeviceCommand',
      'toggleBands'
    ]),

    getDeviceTypeName,
    countString,

    // Executes the command
    async execute ({ name, handler }) {
      const { devices, availableCommands, deviceStatus: status } = this

      // Determine command to execute
      const commandDefinition = availableCommands.find(c => c.name === name)
      if (!commandDefinition) {
        return
      }

      // Determine notifications to show
      const { notifications = {} } = commandDefinition
      const notificationExecuting = notifications.executing == null ? false : notifications.executing
      const notificationExecuted = notifications.executed == null ? true : notifications.executed
      const notificationFailed = notifications.failed == null ? true : notifications.failed

      // If custom command handler is provided,
      // expect it to create a command
      const command = handler
        ? handler({ name, devices })
        : DeviceCommand.create(name)

      // Determine command parameters
      if (await this.setCommandParameters(command)) {
        // Submit the command
        if (command && command.name) {
          await this.sendDeviceCommand({
            command,
            devices,
            status,
            notificationExecuting,
            notificationExecuted,
            notificationFailed
          })

          this.commandExecuted({ command, devices })
        }
      } else {
        // Also notify after custom handler
        if (handler) {
          this.commandExecuted({ command, devices })
        }
      }
    },

    // Determine command parameters
    async setCommandParameters (command) {
      if (command) {
        // Get command definition
        const definition = DeviceCommandDefinitions[command.name]
        const { devices } = this

        let bands
        switch (command.name) {

          // Toggle bands - ask user which ones
          case DeviceCommands.ToggleBands:
            await this.toggleBands({
              devices,
              title: `Toggle bands on ${this.batchDescription}`
            })
            break

          // Shutdown bands - ask user which ones
          case DeviceCommands.ShutdownBands:
            bands = await this.selectBands({
              devices,
              header: `Shut down bands on ${this.batchDescription}`,
              okLabel: 'Shut Down',
              selectedBandColor: 'grey-9',
              selectedBands: ''
            })
            if (bands) {
              command.parameters = this.bandsToShadow(bands, '_shutdown', true)
              return command
            }
            break

          // Turn on bands - ask user which ones
          case DeviceCommands.TurnOnBands:
            bands = await this.selectBands({
              devices,
              header: `Turn on bands on ${this.batchDescription}`,
              okLabel: 'Turn On',
              selectedBandColor: 'green-9',
              selectedBands: ''
            })
            if (bands) {
              command.parameters = this.bandsToShadow(bands, '_shutdown', false)
              return command
            }
            break

          // Turn fast sampling on - assume defaults
          case DeviceCommands.StartFastSampling:
            command.parameters = { ...definition.defaults }
            return command

          // Other commands - just return as-is
          default:
            return command
        }
      }
    },

    // Creates a dictionary of shadow values for the specified bands
    bandsToShadow (identifiers = [], key, value) {
      return identifiers.reduce((shadow, identifier) => {
        shadow[`${key}_${identifier}`] = value
        return shadow
      }, {})
    },

    /** Shows a dialog for selecting bands to operate on
      * @param devices Devices to operate on
      * @param title Dialog title
      * @param okLabel Dialog OK button label
      * @param selectedBandColor Color used to render a selected band
      * @param selectedBands Initially selected bands
      */
    async selectBands ({ devices, title, okLabel, selectedBandColor, selectedBands = '' }) {
      const { isOk, data } = await this.showDialog({
        dialog: 'band-selector',
        data: {
          devices,
          title,
          okLabel,
          selectedBands,
          selectedBandColor
        }
      })

      if (isOk) {
        return data.identifiers
      }
    },

    // Triggered when command has been executed
    commandExecuted ({ command, devices }) {
      this.$emit('command', { command, devices })
    }
  }
}
</script>

<template>
  <q-list v-if="hasDevices" :class="{ horizontal }">
    <q-item v-if="showHeader">
      <q-item-section>
        <q-item-label>
          <div v-if="isBatch" style="font-weight: bold; white-space: nowrap; text-align: center;">
            {{ header || `${countString(this.devices, 'device')} selected` }}
          </div>
          <div v-else style="font-weight: bold; white-space: nowrap; text-align: center;">
            {{ header || `${getDeviceTypeName(devices[0].type)} [${devices[0].serialNumber}]` }}
          </div>
        </q-item-label>
      </q-item-section>
    </q-item>

    <q-separator v-if="showHeader"></q-separator>

    <slot name="prefix-commands"></slot>

    <template v-for="(command, index) in availableCommands">
      <q-separator v-if="command.separator" :key="`separator-${index}`" />
      <q-item class="command" v-if="command.name" clickable v-close-popup :key="command.name"
        @click.stop="execute(command)">
        <q-item-section side v-if="command.icon">
          <q-icon class="command-icon" :name="command.icon" :color="command.color" size="24px">
          </q-icon>
        </q-item-section>
        <q-item-section v-if="command.label">
          <q-item-label class="command-label no-wrap">
            {{ command.label }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>

  </q-list>
</template>

<style scoped lang="scss">
.command-label {
  white-space: nowrap;
}

.q-list.horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .command {
    border-bottom: solid #0000001f 1px;

    &:last-child {
      border-bottom: none;
    }
  }

  .command-icon {
    font-size: 38px !important;
  }

  .command-label {
    font-size: 16px !important;
  }
}

</style>
