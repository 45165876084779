import { dispatch } from '@stellacontrol/client-utilities'

/**
 * Prepares data for playground route
 */
export async function resolve ({ from, to } = {}) {
  const data = {}
  await dispatch('requireUsers')
  await dispatch('setRouteData', { from, to, data })
  return true
}
