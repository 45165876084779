/**
 * Inventory grid cell mixin
 */
export const CellMixin = {
  props: {
    column: {
      type: Object
    }
  },

  computed: {
    // Returns the style for a column
    columnStyle () {
      const { column } = this
      if (column) {
        return {
          'min-width': column.width,
          'max-width': column.width
        }
      }
    }
  }
}
