/**
 * Device SIM providers
 */
export const DeviceSIMProvider = {
  Emnify: 'emnify'
}

/**
 * Device SIM provider names
 */
export const DeviceSIMProviderName = {
  [DeviceSIMProvider.Emnify]: 'Emnify'
}
