<script>
import { mapGetters } from 'vuex'

/**
 * Widget in the widgets panel on the left
 */
export default {
  props: {
    // Action icon
    actionIcon: {
      type: String,
      default: 'more_vert'
    },

    // Action icon color
    actionIconColor: {
      type: String,
      default: 'grey-6'
    },

    // Render the widget without any chrome
    // - no border, padding, bg color etc.
    // Useful when widgets are used elsewhere,
    // outside the device dashboard
    noChrome: {
      type: Boolean,
      default: false
    },

    // Actions
    actions: {
      type: Array,
      default: () => []
    },

    // Widget tooltip
    tooltip: {
      type: String
    }
  },

  data () {
    return {
      dummy: null
    }
  },

  computed: {
    ...mapGetters([
      'guardian'
    ]),

    // List of actions permitted
    // for the currently logged in user
    allowedActions () {
      return this
        .actions
        .filter(action => action && this.isActionAllowed(action))
    },

    // Returns true if any actions are permitted on the widget
    hasActions () {
      return this.allowedActions.length > 0
    },

    // Widget CSS class
    widgetClass () {
      if (this.noChrome) {
        return {
          nochrome: true
        }
      } else {
        const { bgColor } = this
        return {
          nochrome: false,
          ['bg-' + bgColor]: bgColor[0] !== '#'
        }
      }
    },

    // Widget CSS style
    widgetStyle () {
      if (this.noChrome) {
        return {
          'background-color': 'transparent'
        }
      }
    }
  },

  methods: {
    // Checks whether the specified action
    // is allowed for the current user
    isActionAllowed (action) {
      return !(action.isEnabled === false) && this.guardian.canUseAll(action.permissions)
    },

    // Executes the action
    executeAction (action) {
      this.$emit('action', action)
    }
  }
}
</script>

<template>
  <div flat square class="widget" :style="widgetStyle">

    <slot>
    </slot>

    <sc-tooltip v-if="tooltip" :text="tooltip" anchor="bottom middle"
      self="center middle"></sc-tooltip>
    <div class="actions" v-if="hasActions">
      <q-btn class="action-dropdown no-label" size="14px" padding="2px" flat dense rounded
        :icon="actionIcon" :color="actionIconColor" @click.stop="() => { }">

        <q-popup-edit square :style="{ padding: 0 }" :cover="false" :model-value="dummy">
          <q-list>
            <template v-for="action in allowedActions">
              <q-separator v-if="action.separator"></q-separator>
              <q-item clickable v-close-popup @click.stop="executeAction(action)">
                <q-item-section side v-if="action.icon">
                  <q-icon :name="action.icon" :color="action.color" size="24px"></q-icon>
                </q-item-section>
                <q-item-section v-if="action.label">
                  <q-item-label>{{ action.label }} </q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-list>
        </q-popup-edit>

      </q-btn>
    </div>

  </div>
</template>

<style lang="scss">
.widget {
  --widget-width: 240px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: var(--widget-width);
  min-height: 200px;
  overflow: hidden;
  background-color: #ffffff;
  padding: 10px;

  &.mini-widget {
    justify-content: center;
    background-color: #ffffff;
    min-height: 64px;
    padding-left: 16px;
    padding-right: 8px;
    font-weight: bold;
  }

  &.panel-widget {
    width: auto;
    justify-content: center;
    background-color: #ffffff;
    min-height: 64px;
    padding-left: 16px;
    padding-right: 8px;
  }

  /** Widget-specific rules */
  &.widget-band-details {
    width: calc(var(--widget-width) * 2 + var(--widget-spacing)*1);
  }

  &.widget-device-history {
    width: calc(var(--widget-width) * 3 + var(--widget-spacing)*2);
  }

  &.widget-alerts {
    /* Show full alerts widget when in desktop mode, hide the mini widget */
    display: flex !important;

    &.mini-widget {
      display: none !important;
    }
  }


  .actions {
    position: absolute;
    right: 0;
    top: 4px;

    .action-dropdown.no-label {
      .q-btn-dropdown__arrow {
        margin-left: 0 !important;
      }
    }
  }
}

.widget-content {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-right: 5px;

  >.icon {
    flex: 0;
    padding-right: 10px;
  }

  >.lines {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;

    >span {
      color: #2a2a2a !important;
      width: 100%;
    }

    >span,
    >a {
      font-size: 16px;

      &.label {
        font-weight: 500 !important;
      }

      &.highlight {
        color: #388e3c !important;
      }

      &.muted {
        color: #808080 !important;
      }

      &.warning {
        color: orangered !important;
      }

      &.bold {
        font-weight: bold !important;
      }

      &.huge {
        font-size: 32px !important;
      }

      &.big {
        font-size: 18px !important;
      }

      &.small {
        font-size: 14px !important;
      }

      &.italic {
        font-style: italic;
      }

      &.tiny {
        font-size: 12px !important;
      }

      &.tooltip {
        cursor: pointer;
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (width: 1366px) {
  .widget {
    &.widget-band-details {
      width: calc(var(--widget-width) * 3 + var(--widget-spacing) * 2);
    }
  }
}

@media screen and (max-width: 640px) {
  .widget {
    width: calc(50% - 3px);
    min-height: unset;
    padding: 5px;

    /* Compress the content of normal widgets */
    .widget-content {
      .lines {
        >span.label {
          font-size: 15px !important;
        }

        >span.small {
          font-size: 14px !important;
        }
      }
    }

    /* Compress the content of mini widgets */
    &.mini-widget {
      padding-left: 8px;
      padding-right: 4px;
      font-weight: normal;
      font-size: 14px;
    }

    /* Hide full alerts widget in mobile mode,
       Show the mini widget instead */
    &.widget-alerts {
      display: none !important;

      &.mini-widget {
        display: flex !important;
      }
    }

    /* Hide device flags widget in mobile mode */
    &.widget-device-flags {
      display: none !important;
    }

    /* Hide message counters widget in mobile mode */
    &.widget-message-counters {
      display: none !important;
    }

    /* Expand PortSense widget to full width */
    &.widget-portsense {
      width: 100%;
      height: auto;
    }

    &.widget-band-details {
      width: 100%;
    }
  }
}
</style>
