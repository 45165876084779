<script>
import { mapGetters, mapActions } from 'vuex'
import { capitalize } from '@stellacontrol/utilities'
import { Place, PlaceType, getPlaceIcon, getPlaceDescription, isPlaceLockedFor } from '@stellacontrol/model'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    DeviceActionMixin,
    Secure
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // Place to assign the device to
      placeId: null,
      // Originally assigned place
      originalPlace: null,
      // Newly edited place
      newPlace: null,
      // Originally assigned location
      originalLocation: null,
      // Newly edited location
      newLocation: null,
      // Places to choose from
      places: [],
      allPlaces: [],
      ownerPlaces: [],
      // Date and time of starting the premium services
      premiumServiceStartsAt: new Date(),
      // Additional notes
      notes: null,
      // All available premium services, including those which are not
      // in my pricelist, but were assigned by distributors above
      premiumServices: [],
      // All premium services available in my pricelist
      myPremiumServices: [],
      // Premium service associated with device, which owner can activate
      premiumServiceId: null
    }
  },

  computed: {
    ...mapGetters([
      'organizationPreferences'
    ]),

    // Current place of the edited devices
    place () {
      const id = this.getValue('placeId')
      if (id) {
        return this.allPlaces.find(p => p && p.id === id)
      }
    },

    // Current location of the edited devices
    location () {
      const location = this.getValue('location', '').trim()
      const customLocation = this.getValue('customLocation', '').trim()
      return customLocation || location
    },

    // Checks whether device has been assigned to a custom location
    hasCustomLocation () {
      const location = this.getValue('location', '').trim()
      const customLocation = this.getValue('customLocation', '').trim()
      return customLocation && customLocation !== location
    },

    // Label for the internal location
    locationLabel () {
      const { hasCustomLocation, newLocation, originalLocation } = this
      if (hasCustomLocation || newLocation?.trim() !== originalLocation?.trim() || !originalLocation) {
        return 'Device location'
      } else {
        return 'Location reported by device'
      }
    },

    // Returns true if user has chosen to
    // add a new place and assign devices to it
    addingNewPlace () {
      return this.placeId === 'new'
    },

    // Returns true if user has chosen to
    // remove the device from its current place
    removingFromPlace () {
      return this.originalPlace != null && this.placeId === 'unassign' || !this.placeId
    },

    // Returns true if user has chosen to
    // assign the device to a new place
    assigningPlace () {
      return this.placeId && !this.removingFromPlace
    },

    // Selected or newly entered place
    // to which the devices should be assigned
    selectedPlace () {
      if (this.addingNewPlace) return this.newPlace
      if (!this.removingFromPlace) return this.allPlaces.find(p => p && p.id === this.placeId)
    },

    // Place type preposition, for human-friendly sentences
    placeTypePreposition () {
      return placeType => {
        if (placeType === PlaceType.Building) return 'in'
        if (placeType === PlaceType.Place) return 'at'
        return 'on'
      }
    },

    // Icon representing the place
    placeIcon () {
      return place => {
        if (place) {
          if (place.id === 'new') return 'add'
          if (place.id === 'unassign') return 'close'
          return getPlaceIcon(place.placeType)
        }
      }
    },

    // Determines label for EXECUTE button
    executeLabel () {
      const { placeId, originalPlace, newLocation, originalLocation } = this
      return placeId != originalPlace?.id
        ? (placeId ? 'Assign to building' : 'Remove from building')
        : (newLocation !== originalLocation
          ? (newLocation ? 'Set Location' : 'Clear Location')
          : 'Assign to building'
        )
    },

    // Warning to display when place cannot be assigned.
    // This happens when device either has no owner yet,
    // or selected devices have different owners, so we cannot
    // display places of a single owner
    warningLabel () {
      const { devicesLabel, haveDifferentOwners, hasOwner } = this
      let warning
      if (haveDifferentOwners) {
        warning = ' The devices cannot be assigned to the same building. They belong to different owners.'
      } else {
        warning = `${capitalize(devicesLabel)} cannot be assigned to a building. `
        if (!hasOwner) {
          warning = `You must select an owner of ${devicesLabel} first.`
        }
      }
      return warning
    },

    // Premium services assigned to selected devices and awaiting activation
    premiumServicesToActivate () {
      const { devices, premiumServices, assigningPlace } = this
      const services = assigningPlace
        ? devices.map(device => {
          const { premiumServiceId, isPremiumServiceNotStarted } = device
          if (premiumServiceId && isPremiumServiceNotStarted) {
            return premiumServices.find(p => p.id === premiumServiceId)
          }
        })
        : []
      return services.filter(s => s)
    },

    // Premium service on the selected device, awaiting activation
    premiumServiceToActivate () {
      return this.premiumServicesToActivate[0]
    },

    // Indicates whether current organization is obliged to buy premium services
    // in order to use premium features
    mustBuyPremiumServices () {
      return this.mustUse('premium-services-buy')
    },

    // Checks whether user is allowed to assign the place
    canAssignPlace () {
      const { placeId, newLocation, originalPlace, originalLocation } = this
      const locationChanged = ((newLocation || '') !== (originalLocation || ''))
      if (placeId) {
        return placeId !== originalPlace?.id || locationChanged
      } else {
        return this.originalPlace != null || locationChanged
      }
    }
  },

  emits: [
    'execute',
    'close'
  ],

  methods: {
    ...mapActions([
      'requirePlaces',
      'savePlace',
      'setDevicesPlace',
      'removeDevicesFromPlace',
      'setDevicesCustomLocation',
      'getPremiumService',
      'getPremiumServices',
      'startDeviceSubscriptions'
    ]),

    capitalize,

    // Populates the view
    async populate () {
      // Prepare a list of places to select from
      const { owner: organization, device, currentUser } = this
      if (organization && device) {
        const allPlaces = await this.requirePlaces()
        const newPlace = new Place({ id: 'new', name: 'Add new building' })
        const unassignPlace = new Place({ id: 'unassign', name: 'Remove from current building' })
        this.ownerPlaces = allPlaces.filter(p => p.organizationId === organization.id)
        this.allPlaces = [
          newPlace,
          device.placeId ? unassignPlace : undefined,
          ...this.ownerPlaces
        ].filter(p => p && !isPlaceLockedFor(p, currentUser))
        this.places = this.allPlaces
        // Newly edited place
        this.newPlace = new Place({
          organization,
          organizationId: organization.id
        })

        // Show the currently assigned place and location
        this.populatePlaceAndLocation()

        // Load premium services available to organization
        await this.populatePremiumServices()
      }
    },

    // Populates the details of a place associated with selected device
    async populatePlaceAndLocation () {
      const { device, ownerPlaces } = this
      // Show the currently assigned place
      this.placeId = ownerPlaces.some(p => p.id === device.placeId) ? device.placeId : null
      // Show the currently assigned location
      this.originalPlace = device.placeId ? ownerPlaces.find(p => p.id === device.placeId) : null
      this.originalLocation = this.location
      this.newLocation = this.location
    },

    // Loads paid premium services
    async populatePremiumServices () {
      if (this.mustBuyPremiumServices) {
        const myServices = await this.getPremiumServices()
        const otherServices = []
        const otherIdentifiers = new Set(this.devices
          .map(d => d.premiumServiceId)
          .filter(id => id && !myServices.find(s => s.id === id)))

        for (const id of Array.from(otherIdentifiers)) {
          const service = await this.getPremiumService({ id })
          otherServices.push(service)
        }

        this.myPremiumServices = myServices
        this.premiumServices = [...myServices, ...otherServices]
      }
    },

    // Filter function for filtering options
    // in the place selector
    filterPlaces (value = '', update) {
      update(() => {
        if (value === '') {
          this.places = this.allPlaces
        } else {
          const text = value.toLowerCase()
          this.places = this.allPlaces.filter(p => p && p.name.toLowerCase().includes(text))
        }
      })
    },

    // Validates the entered place
    async validatePlace () {
      return !this.$refs.placeEditor || await this.$refs.placeEditor.validate()
    },

    // Executes the action
    async assign () {
      if (await this.validate() && await this.validatePlace()) {
        let { selectedPlace: place, originalPlace, originalLocation, devices } = this
        const newLocation = this.newLocation?.trim() || ''

        if (place) {
          // Place has been selected, either new or existing one.
          // Save the place first.
          if (place.isNew) {
            const result = await this.savePlace({ place })
            if (result.place) {
              place = result.place
            } else {
              {
                return
              }
            }
          }

          // Update the place on associated device
          await this.setDevicesPlace({ devices, place })

          // Activate premium services on devices
          // if any services are waiting to be activated
          const { premiumServiceToActivate, premiumServiceStartsAt } = this
          if (premiumServiceToActivate) {
            await this.startDeviceSubscriptions({
              devices,
              startsAt: premiumServiceStartsAt,
              details: `Premium service has been activated because ${this.devicesHaveLabel} been assigned to ${getPlaceDescription(place)}`,
              silent: true,
            })
          }
        } else {
          if (originalPlace) {
            // Place has been cleared:
            // unassign devices from the original place
            await this.removeDevicesFromPlace({ devices, place: originalPlace })
          }
        }

        if (newLocation) {
          if (newLocation !== originalLocation) {
            // Location has been changed:
            // Assign devices to the new custom location
            await this.setDevicesCustomLocation({ devices, location: newLocation })
          }
        } else {
          if (originalLocation) {
            // Location has been cleared:
            // Remove devices from their custom location
            await this.setDevicesCustomLocation({ devices, location: null })
          }
        }

        this.executed({ refresh: false })
      }
    }
  },

  watch: {
    devices () {
      this.populate()
    }
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <sc-device-action :action="action" :devices="devices" :isAllowed="hasOwner"
    :execute-label="executeLabel" :canExecute="canAssignPlace" :allowWhenDifferentOwners="false"
    :warningLabel="warningLabel" @closing="closing" @close="close" @execute="assign">

    <q-form ref="form" autofocus class="q-mt-lg q-gutter-sm" @submit.prevent>
      <div>
        <label class="text-body2 text-grey-9" v-if="owner && owner.id !== currentOrganization.id">
          The owner of {{ devicesLabel }} is
          <b>{{ owner.name }}</b>.
        </label>
        <label class="text-body2 text-grey-9" v-if="place">
          {{ capitalize(devicesAreLabel) }}
          installed {{ placeTypePreposition(place.placeType) }} a {{ place.placeType }}
          <b>{{ place.fullText }}</b>.
        </label>
      </div>

      <div>
        <label class="text-body2 text-grey-9">
          Select new building for {{ devicesLabel }}.
          Clear the building to move the device back to stock.</label>
      </div>

      <q-select square outlined label="Select place" v-model="placeId" :options="places" emit-value
        map-options option-value="id" option-label="name" use-input debounce="500" fill-input
        hide-selected clearable @filter="filterPlaces">
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section side>
              <q-icon :name="placeIcon(scope.opt)" color="indigo-5" size="sm" />
            </q-item-section>
            <q-item-section>
              <q-item-label v-html="scope.opt.name" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>

      <sc-place-editor ref="placeEditor" v-if="addingNewPlace" :place="newPlace"
        :organization="owner" class="q-mb-lg q-mt-md">
      </sc-place-editor>

      <div v-if="premiumServiceToActivate" class="q-mt-md">
        <!-- Show currently assigned free-of-charge premium service -->
        <label v-if="premiumServicesToActivate.length === 1">
          <div class="text-orange-8 q-mt-sm">
            <span v-if="premiumServiceToActivate.neverExpires">
              Never-expiring subscription to
              <b>{{ premiumServiceToActivate.label }}</b>
            </span>
            <span v-else>
              {{ capitalize(premiumServiceToActivate.periodDescription) }} of
              <b>{{ premiumServiceToActivate.label }}</b>
            </span>
            <span>
              service is included <b>free of charge</b>
              and will be started on {{ date(premiumServiceStartsAt) }}.
            </span>
          </div>
        </label>
        <label v-if="premiumServicesToActivate.length > 1">
          <div class="text-orange q-mt-sm">
            <span>
              There are premium services included with {{ selectionLabel }},
              <b>free of charge</b>
              and will be started on {{ date(premiumServiceStartsAt) }}.
            </span>
            <sc-tooltip :text="premiumServicesToActivate.map(s => s.label).join(', ')"></sc-tooltip>
          </div>
        </label>

        <sc-date-input class="q-mt-sm" :dense="false" v-if="isBank"
          label="Start premium services from" v-model="premiumServiceStartsAt" :rules="[
    rules.required('Date is required')
  ]">
        </sc-date-input>
      </div>

      <div class="q-mt-md">
        <label class="text-body2 text-grey-9">
          Enter the internal location of {{ devicesLabel }}:
        </label>
      </div>

      <q-input square outlined v-model="newLocation" :label="locationLabel" clearable>
      </q-input>

      <q-input square outlined class="q-mt-md" label="Notes" v-model="notes" type="textarea"
        autogrow v-if="false">
      </q-input>

    </q-form>
  </sc-device-action>
</template>

<style lang="scss" scoped></style>
