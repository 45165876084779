import { Point } from './point'
import { pointBelongsToVector } from './utilities'

/**
 * Path made of points
 */
export class Points {
  constructor ({ points = [], ...data } = {}) {
    this.points = points
      .filter(p => p)
      .map(p => Point.from(p))
    Object.assign(this, data)
  }

  /**
   * Starts the path
   * @param {Point} point Start point of the path. If not specified, we start at zero point
   * @returns {Points}
   */
  static startAt (point) {
    const points = new Points()
    points.moveTo(point || Point.Zero)
    return points
  }

  /**
   * Creates a path from the specified flat array of coordinates specified as `[x1, y1, x2, y2, ..., xn, yn]`
   * @param {Array[Number]} coordinates Coordinates
   * @returns {Points}
   */
  static fromArray (coordinates = []) {
    const points = []
    let i = 0
    while (i < coordinates.length - 1) {
      const point = Point.from({ x: coordinates[i], y: coordinates[i + 1] })
      points.push(point)
      i += 2
    }
    return new Points({ points })
  }

  /**
   * Creates a path from the specified points
   * @param {Array[Point]} points Points of the path
   * @returns {Points}
   */
  static fromPoints (points) {
    return new Points({ points })
  }

  /**
   * Checks whether the specified point is located on one of the vectors of the specified poly-line
   * @param {Point} point Point to check
   * @param {Array[Point]} points Poly-line points
   * @param {Number} radius If specified, the point can be away from the vector by the specified radius. Useful for fuzzy proximity searches.
   * @returns {Array[Point]} Vector on which the point is located or null, if point is not on any of them
   */
  static isOnTheLine (point, points, radius = 1) {
    if (point && points) {
      let i = 0
      while (i < points.length - 1) {
        const start = points[i]
        const end = points[i + 1]
        if (pointBelongsToVector(point, start, end, radius)) {
          return [start, end]
        }
        i++
      }
    }
  }

  /**
   * Points making up the math
   * @type {Array[Point]}
   */
  points

  /**
   * Checks whether the {@link points} contain anything
   * @type {Boolean}
   */
  get isEmpty () {
    return this.points.length > 0
  }

  /**
   * Returns the number of {@link points}
   * @type {Number}
   */
  get length () {
    return this.points.length
  }

  /**
   * Returns the first point
   * @type {Point}
   */
  get first () {
    return this.points[0]
  }

  /**
   * Returns the last point
   * @type {Point}
   */
  get last () {
    return this.points[this.length - 1]
  }

  /**
   * Removes all {@link points}
   * @returns {Points} Returns this instance, allowing for chained calls
   */
  clear () {
    this.points = []
    return this
  }

  /**
   * Adds an absolute point to the path
   * @param {Point} point
   * @returns {Points} Returns this instance, allowing for chained calls
   */
  moveTo ({ x, y, z } = {}) {
    const point = Point.from({ x, y, z })
    if (point.isDefined) {
      this.points.push(point)
    }
    return this
  }

  /**
   * Adds a relative point to the path
   * @param {Point} delta Delta by which to move. Both `x` and `y` are optional, assumed to be `0` unless specified otherwise.
   * @returns {Points} Returns this instance, allowing for chained calls
   */
  moveBy (delta) {
    if (delta?.x == null && delta?.y == null && delta?.z == null) {
      return this
    }

    const last = this.last
      ? Point.from(this.last)
      : Point.Zero

    const point = last.moveBy({
      x: delta.x == null ? 0 : delta.x,
      y: delta.y == null ? 0 : delta.y,
      z: delta.z == null ? 0 : delta.z
    })
    this.points.push(point)

    return this
  }

  /**
   * Adds a point equal to the the first point of the path
   * @returns {Points} Returns this instance, allowing for chained calls
   */
  moveToStart () {
    this.moveTo(this.last)
    return this
  }

  /**
   * Converts the {@link points} to flat list of coordinates `[x1, y1, x2, y2, ..., xn, yn]`
   * @returns {Array[Number]}
   */
  toArray () {
    return this.points.flatMap(p => p.toArray())
  }
}
