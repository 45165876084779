<script>
import { FormMixin } from '@stellacontrol/client-utilities'

export default {
  mixins: [
    FormMixin
  ],

  props: {
    data: {
      type: Object,
      required: true
    },

    permissions: {
      type: Object,
      required: true
    }
  }
}

</script>

<template>
  <sc-permissions :principal="data" :permissions="permissions" :collapsed="false">
  </sc-permissions>
</template>

<style lang='scss' scoped></style>
