<script>
import { createPlanAction } from '../../renderer/actions/execute-action'

export default {
  props: {
    // Plan renderer
    renderer: {
      required: true
    },
    // Unique name of the section
    section: {
      type: String,
      required: true
    },
    // Sections' status
    sections: {
      type: Object,
      required: true
    },
    // Section title
    title: {
      type: String,
      required: true
    },
    // Section icon
    icon: {
      type: String,
      required: true
    },
    // Action to show in the section header
    action: {
    },
    // If true, action icon is shown, otherwise the label
    actionIcon: {
      type: Boolean,
      default: false
    },
    // Items impacted by the header action
    items: {
    },
    // If layer identifier is specified, it indicates that section represents a layer
    layer: {
      type: String
    },
    // If `layer` not present but this is, it indicates that there should still be an icon
    // for hiding the specified layer
    hidesLayer: {
      type: String
    },
    // Layers' visibility
    layers: {
      type: Object,
      default: () => { }
    }
  },

  computed: {
    // Indicates that the section is now expanded
    isSectionExpanded () {
      return Boolean(this.sections[this.section])
    },

    // Indicates that section represents a layer
    isLayer () {
      return Boolean(this.layer)
    },

    // Indicates that the hideable layer is now visible
    isLayerVisible () {
      return Boolean(this.layers[this.layer || this.hidesLayer])
    },

    // Indicates whether the button for hiding layer should be visible
    canHideLayer () {
      return this.renderer.isFloor && (this.layer || this.hidesLayer)
    },

    // Action in the section header
    headerAction () {
      const { action, items } = this
      if (action) {
        return createPlanAction({ action, items })
      }
    }
  },

  methods: {
    toggleSection () {
      this.$emit('toggle-section', this.section)
    },

    toggleLayer () {
      this.$emit('toggle-layer', this.layer || this.hidesLayer)
    },

    executeAction (action) {
      const { items } = this
      this.$emit('action', { action, items })
    }
  },

  emits: [
    'toggle-section',
    'toggle-layer',
    'action',
  ]
}

</script>

<template>
  <div class="plan-section">
    <header class="bg-grey-10 row items-center" @click="toggleSection()">
      <slot name="header">
        <q-icon size="sm" :name="icon" color="grey-2" class="q-mr-sm">
        </q-icon>
        <span class="text-grey-2 text-bold">
          {{ title }}
        </span>
        <q-space>
        </q-space>
        <template v-if="headerAction">
          <q-btn v-if="actionIcon" class="q-mr-sm text-blue-3" dense flat round size="11px"
            :icon="headerAction.icon" @click.stop="executeAction(headerAction)">
            <sc-tooltip :text="headerAction.tooltip" nowrap></sc-tooltip>
          </q-btn>
          <span v-else class="header-action q-mr-sm" href="#" @click.stop="executeAction(headerAction)"
            :class="{ warning: headerAction.warning }">
            {{ headerAction.label }}
            <sc-tooltip :text="headerAction.tooltip" nowrap></sc-tooltip>
          </span>
        </template>
        <q-btn v-if="canHideLayer" class="q-mr-sm text-blue-3" dense flat round size="11px"
          :icon="isLayerVisible ? 'visibility' : 'visibility_off'" @click.stop="toggleLayer()">
          <sc-tooltip nowrap>
            {{ isLayerVisible ? 'Hide layer' : 'Show layer' }}
          </sc-tooltip>
        </q-btn>
        <q-icon size="sm" :name="isSectionExpanded ? 'expand_more' : 'chevron_right'"
          color="indigo-3">
        </q-icon>
      </slot>
    </header>
    <main v-if="isSectionExpanded">
      <slot>
      </slot>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.plan-section {
  header {
    height: 40px;
    padding: 8px 8px 10px 10px;
    border-bottom: solid #393939 1px;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    font-size: 13px;
  }
}

.header-action {
  color: #f5f5f5;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: orange;
  }

  &.warning {
    color: orange;

    &:hover {
      color: orangered;
    }
  }
}
</style>
