<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { isAvailableProductPart, getDeviceLabel, isPlaceLockedFor, DateRange } from '@stellacontrol/model'
import { Confirmation } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import DeviceShareAction from './device-action-share.vue'
import DeviceSellAction from './device-action-sell.vue'
import DevicePlaceAction from './device-action-place.vue'
import DeviceClearAction from './device-action-clear.vue'
import DeviceDecommissionAction from './device-action-decommission.vue'
import DeviceLCDAction from './device-action-lcd.vue'
import DeviceFirmwareAction from './device-action-firmware.vue'
import DevicePremiumServicesAction from './device-action-premium-services.vue'
import DeviceConfigureAction from './device-action-configure.vue'
import DeviceSwapAction from './device-action-swap.vue'
import MultiDeviceAction from './device-action-multi.vue'
import { DevicePanelTabMixin } from '../device-panel-tab-mixin'

export default {
  mixins: [
    DevicePanelTabMixin,
    Secure
  ],

  components: {
    'sc-device-share': DeviceShareAction,
    'sc-device-sell': DeviceSellAction,
    'sc-device-place': DevicePlaceAction,
    'sc-device-clear': DeviceClearAction,
    'sc-device-decommission': DeviceDecommissionAction,
    'sc-device-lcd': DeviceLCDAction,
    'sc-device-firmware': DeviceFirmwareAction,
    'sc-device-premium-services': DevicePremiumServicesAction,
    'sc-device-multi': MultiDeviceAction,
    'sc-device-configure': DeviceConfigureAction,
    'sc-device-swap': DeviceSwapAction
  },

  data () {
    return {
      // Currently selected action
      action: null,
      // Tags set for the selected devices
      tags: []
    }
  },

  computed: {
    ...mapState({
      allDevices: state => state.devices.devices || [],
      actionDetails: state => state.devicePanel.action
    }),

    ...mapGetters([
      'isDevelopmentEnvironment',
      'currentRoute'
    ]),

    // Returns true if currently selected devices are all connected
    onlyConnectedDevices () {
      return this.devices.every(device => device.isConnectedDevice)
    },

    // Returns true if currently selected devices are all real and connected
    onlyRealConnectedDevices () {
      return this.devices.every(device => device.isConnectedDevice && !device.isSimulatedDevice)
    },

    // Returns true if some of the currently selected devices are multi-devices
    someMultiDevices () {
      return this.devices.some(device => device.isMultiDevice)
    },

    // Returns true if there's only one device selected and it's a multi-device
    isMultiDevice () {
      return this.isSingle && this.device.isMultiDevice
    },

    // Returns the list of device parts of a multi-device
    deviceParts () {
      return this.isSingle && this.device.isMultiDevice
        ? this.allDevices.filter(d => d.partOf === this.device.id)
        : []
    },

    // Master device, available if processing a multi-device part
    masterDevice () {
      return this.isMultiDevicePart ? this.allDevices.find(d => d.id === this.device.partOf) : null
    },

    // Indicates that we're processing a part of a multi-device
    isMultiDevicePart () {
      return this.isSingle && Boolean(this.device.partOf)
    },
    // Returns true if some of the currently selected devices are parts of a multi-devices
    someMultiDeviceParts () {
      return this.devices.every(device => Boolean(device.partOf))
    },

    // Returns true if none of the currently selected devices are parts of a multi-devices
    noMultiDeviceParts () {
      return this.devices.every(device => !device.partOf)
    },

    // Indicates whether device can be sold
    // by the current user
    canSellDevice () {
      return !this.someMultiDeviceParts && this.canUse('sell-devices')
    },

    // Indicates whether device part can be unlinked from master device
    // by the current user
    canUnlinkDevicePart () {
      return this.isMultiDevicePart && this.isSuperAdministrator
    },

    // Actions allowed to the current user
    allowedActions () {
      return this.isDecommissioned
        ? []
        : this.actions.filter(action => {
          const canUse = this.canUseAll(action.permissions)
          const isVisible = typeof action.isVisible === 'function' ? action.isVisible() : action.isVisible
          return canUse && isVisible
        })
    },

    // Currently visible actions
    visibleActions () {
      return this.allowedActions
    },

    // Editor used to execute the currently selected action
    actionEditor () {
      return this.action ? `sc-device-${this.action.name}` : 'sc-empty'
    },

    // Available actions
    actions () {
      return [
        {
          name: 'sell',
          label: `Sell ${this.devicesLabel}`,
          details: `Select organization which owns ${this.devicesLabel}`,
          icon: 'euro_symbol',
          permissions: ['sell-devices'],
          isVisible: () => !this.someMultiDeviceParts
        },
        {
          name: 'place',
          label: 'Assign to place',
          details: `Assign ${this.devicesLabel} to building, ship etc.`,
          icon: 'place',
          permissions: ['set-device-place'],
          // Cannot assign multi-device parts to places separately.
          // Cannot change place if some of the selected devices
          // are currently assigned to a locked place
          isVisible: () => this.haveSameOwner &&
            this.noMultiDeviceParts &&
            !this.devices.some(d => isPlaceLockedFor(d.place, this.currentUser))
        },
        {
          name: 'premium-services',
          label: 'Premium Services',
          details: `Activate premium services on ${this.devicesLabel}`,
          icon: 'payments',
          permissions: ['premium-services-sell'],
          isVisible: () => this.onlyConnectedDevices && this.haveSameOwner && !this.isMultiDevicePart
        },
        {
          name: 'history',
          label: 'Device History',
          details: 'Shows the history of device activity',
          icon: 'timeline',
          permissions: ['history-graph'],
          isVisible: () => {
            const canUseHistory = this.guardian.canUse('history-graph')
            const hasHistorySubscription = this.guardian.canDeviceUse('history-graph', this.device.serialNumber)
            const hasOwnerHistorySubscription = this.ownerGuardian?.isSubscribedTo('history-graph', this.device.serialNumber)
            return this.onlyConnectedDevices &&
              this.isSingle &&
              !this.isMultiDevice &&
              canUseHistory &&
              (hasHistorySubscription || hasOwnerHistorySubscription)
          }
        },
        {
          name: 'multi',
          label: this.isMultiDevice
            ? 'Edit multi-device'
            : (this.isBatch
              ? 'Create multi-device'
              : 'Add to multi-device'),
          details: this.isMultiDevice
            ? 'Edit a multi-device product'
            : (this.isBatch
              ? 'Create a product composed of multiple device boards'
              : 'Add the selected board to an existing multi-device'),
          icon: 'filter_none',
          permissions: ['super-administrator'],
          // This action can be used to:
          // - Combine the selected boards into a multi-device
          // - Add a selected board to existing multi-device
          // - Edit the properties of a multi-device
          isVisible: () => {
            // Parts must be owned by the same owner
            if (!this.haveSameOwner) return false
            // If single device is selected, we're possibly editing a multi-device
            if (this.isMultiDevice) return true
            // If multiple devices selected, we're possibly adding a multi-device
            if (this.isBatch && this.devices.every(d => !d.partOf && isAvailableProductPart(d.type, d.model))) return true
            // Otherwise, we're maybe adding a part to an existing multi-device
            if (this.isSingle && !this.device.partOf && isAvailableProductPart(this.device.type, this.device.model)) return true
            return false
          }
        },
        {
          name: 'firmware',
          label: 'Firmware Update',
          details: `Update the firmware on ${this.devicesLabel}`,
          icon: 'upgrade',
          color: 'orange-8',
          permissions: ['device-management-firmware'],
          isVisible: () => {
            return this.onlyConnectedDevices &&
              !this.someMultiDevices &&
              (this.isSuperAdministrator || this.ownerGuardian?.canDeviceUse('device-management-firmware', this.device.serialNumber, this.currentOrganizationGuardian))
          }
        },
        {
          name: 'configure',
          label: `Configure ${this.devicesLabel}`,
          details: `Edit configuration settings of ${this.devicesLabel}`,
          icon: 'settings',
          color: 'blue-8',
          permissions: ['device-configuration'],
          isVisible: () => this.onlyConnectedDevices &&
            !this.someMultiDevices &&
            (!this.isBatch || this.canUse('device-configuration-batch')) &&
            (this.isSuperAdministrator || this.ownerGuardian?.canDeviceUse('live-status', this.device.serialNumber, this.currentOrganizationGuardian))
        },
        {
          name: 'clear',
          label: 'Clear device data',
          details: `Clear the data related to ${this.devicesLabel}`,
          icon: 'loop',
          color: 'orange-8',
          permissions: ['clear-devices'],
          isVisible: () => true
        },
        {
          name: 'decommission',
          label: `Remove ${this.devicesLabel}`,
          details: `Permanently remove ${this.devicesLabel} from inventory`,
          icon: 'delete',
          color: 'red-10',
          permissions: ['decommission-devices'],
          isVisible: () => true
        }
      ]
    }

  },

  emits: [
    'execute',
    'close'
  ],

  methods: {
    ...mapActions([
      'removePartFromMultiDevice',
      'gotoRoute',
      'showDialog',
      'showDeviceHistory',
      'openInventoryAction',
      'closeInventoryAction'
    ]),

    getDeviceLabel,

    // Starts the specified action
    start (action) {
      const { device, devices, isBatch } = this

      switch (action.name) {
        // If configuring a single device, use simply the configuration dialog.
        // When more than one device is selected, use batch configuration.
        case 'configure':
          if (isBatch) {
            this.action = action
          } else {
            this.showDialog({ dialog: 'device-configuration', data: { serialNumber: device.serialNumber } })
          }
          break

        // Navigate to device history
        case 'history':
          this.showDeviceHistory({ device, period: DateRange.today() })
          break

        default:
          this.action = action
      }

      this.openInventoryAction({ device, devices, action })
    },

    // Signals closing the current action without execution
    close ({ action } = {}) {
      this.action = null
      this.$emit('close', { action })
      this.closeInventoryAction({ action })
    },

    // Signals execution of the action
    // and requirement to refresh the inventory grid
    executed ({ action, refresh } = {}) {
      this.action = null
      this.$emit('execute', { action, refresh })
    },

    // Unlinks the device part from the master device
    async unlinkDevicePart () {
      const { masterDevice: device, device: part } = this
      const message = `Remove ${getDeviceLabel(part)} board from ${getDeviceLabel(device)} unit?`
      if (await Confirmation.ask({ message })) {
        await this.removePartFromMultiDevice({ device, part })
      }
    }
  },

  async created () {
    // Select an action to start (DEV only)
    if (this.isDevelopmentEnvironment) {
      const actionName = this.currentRoute.query.action
      const action = this.actions.find(action => action.name === actionName)
      if (action) {
        this.start(action)
      }
    }
  }
}
</script>

<template>
  <div class="column">
    <div class="container q-gutter-md" v-if="!action">
      <div class="action bg-grey-1" v-if="deviceParts.length > 0">
        <div class="row items-center">
          <div class="icon">
            <q-icon name="router" size="40px" color="indigo-4" />
          </div>

          <label class="text-subtitle1 q-ml-md text-grey-10">
            {{ device.product.name }} unit, composed of
            <template v-for="(part, index) of deviceParts">
              {{ index === 0 ? '' : ',' }}
              <router-link class="item-link"
                :to="{ name: 'inventory', query: { selection: part.serialNumber } }">
                <sc-tooltip :text="`Select device part ${getDeviceLabel(part)}`"></sc-tooltip>
                {{ getDeviceLabel(part) }}
              </router-link>
            </template>
          </label>
        </div>
      </div>

      <div class="action bg-grey-1" v-if="isMultiDevicePart && masterDevice">
        <div class="row items-center">
          <div class="icon">
            <q-icon name="memory" size="40px" color="indigo-4"></q-icon>
          </div>

          <label class="text-subtitle1 q-ml-md text-grey-10">
            {{ device.product.name }} board, part of
            <router-link class="item-link"
              :to="{ name: 'inventory', query: { selection: masterDevice.serialNumber } }">
              <sc-tooltip :text="`Select the unit ${getDeviceLabel(masterDevice)}`"></sc-tooltip>
              {{ getDeviceLabel(masterDevice) }}
            </router-link>
            unit
          </label>

          <q-space></q-space>

          <q-btn icon="close" size="md" round dense unelevated v-if="canUnlinkDevicePart"
            @click="unlinkDevicePart()">
            <sc-tooltip>
              Remove from {{ getDeviceLabel(masterDevice) }}
            </sc-tooltip>
          </q-btn>
        </div>
      </div>

      <div class="action q-pa-sm bg-grey-1" v-if="isDecommissioned && !isBatch">
        <div class="row items-center no-wrap">
          <div class="icon">
            <q-icon name="close" size="44px" color="red-10"></q-icon>
          </div>

          <label class="text-subtitle1 q-ml-md text-grey-10">
            {{ capitalize(device.type) }} <b>{{ device.serialNumber }}</b> was decommisioned on
            <b>{{ dateString(device.decommissionedAt) }}</b>
          </label>
        </div>
      </div>

      <div class="actions" v-if="visibleActions.length > 0">
        <div class="action interactive" v-for="action in visibleActions" :key="action.name"
          @click="start(action)">

          <div class="row items-center q-pt-xs q-pl-xs q-pr-xs">
            <div class="icon q-mr-xs">
              <q-icon :name="action.icon" size="20px" :color="action.color || 'indigo-5'">
              </q-icon>
            </div>
            <label class="title text-grey-10">
              {{ action.label }}
            </label>
          </div>
          <div class="row q-mt-sm">
            <label class="details q-pl-sm text-grey-8">
              {{ action.details }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <component v-if="action" :is="actionEditor" :devices="devices" :action="action"
      :details="actionDetails" @execute="executed" @close="close">
    </component>

  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .actions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
  }

  .action {
    border-radius: 4px;
    transition: all 0.15s ease-in-out;
    border: solid #e5e5e5 1px;
    padding: 5px;

    &.tags {
      padding: 16px;
    }

    &.interactive {
      cursor: pointer;
      background-color: #e8eaf6;
      border-color: #ede7f6;

      &:hover {
        background-color: #c5cae9;
      }
    }

    label {
      user-select: none;
      cursor: pointer;
    }

    .title {
      display: flex;
      flex-direction: row;
      font-size: 15px;
      font-weight: 500;
      margin-top: 2px;
    }
  }
}
</style>
