<script>
import { mapActions, mapGetters } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { AttachmentFolder } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { FilesMixin } from '../../views/files/files-mixin'
import FolderComponent from '../../views/files/components/folder.vue'
import PreviewComponent from '../../views/files/components/preview.vue'
import NotesComponent from '../../views/files/components/file-notes.vue'
import FilePrintDialog from '../../views/files/components/file-print-dialog.vue'

const dialog = 'files-dialog'

export default {
  mixins: [
    Secure,
    DialogMixin,
    FilesMixin
  ],

  components: {
    'sc-folder': FolderComponent,
    'sc-file-preview': PreviewComponent,
    'sc-file-notes': NotesComponent,
    'sc-file-print-dialog': FilePrintDialog
  },

  data () {
    return {
      dialog,

      // Dialog title
      title: 'Files',

      // Files to show
      files: [],

      // Collapsed file groups
      collapsedGroups: {}
    }
  },

  computed: {
    ...mapGetters([
      'isMobilePhone'
    ]),

    // Checks whether there are any files to show
    hasFiles () {
      return this.files?.length > 0
    },

    // Folder
    folder () {
      const { title, files } = this
      return new AttachmentFolder({
        id: 'folder',
        label: title,
        icon: 'home',
        files
      })
    },

    // Expanded folders
    expandedFolders () {
      const { folder } = this
      return folder
        ? { [folder.id]: true }
        : {}
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      this.title = this.data.title
      this.files = this.data.files
    },

    // Validates and OKs the dialog
    async ok () {
      this.dialogOk({ dialog })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    },

    // Toggles the specified files
    selectFiles ({ files, isSelected }) {
      for (const file of files) {
        file.isSelected = isSelected
      }
    },

    // Toggles the specified group of files
    toggleGroup ({ group, expanded } = {}) {
      const { collapsedGroups } = this
      collapsedGroups[group] = expanded == null ? !collapsedGroups[group] : !expanded
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()">
    <div class="files">
      <div class="title">
        <q-banner class="bg-indigo-6">
          <div class="row items-center no-wrap">
            <div class="text-subtitle2 text-white title">
              {{ title }}
              ({{ count(files, 'file') }})
            </div>
            <q-space>

            </q-space>
            <q-btn dense flat @click="ok" icon="close" color="white">
            </q-btn>
          </div>
        </q-banner>
      </div>

      <div class="items" v-if="hasFiles">
        <sc-folder :key="folder.id" :folder="folder" :expanded-folders="expandedFolders"
          :collapsed-groups="collapsedGroups" :show-header="false" :batch-select="false" @select="selectFiles"
          @toggle="toggleGroup" @download="downloadFile" @preview="previewFile"
          @remove="removeFiles" @print="printFiles">
        </sc-folder>
      </div>

      <div v-else>
        There aren't any files yet.
      </div>
    </div>

    <sc-file-preview></sc-file-preview>
    <sc-file-notes></sc-file-notes>
    <sc-file-print-dialog></sc-file-print-dialog>

  </sc-dialog>
</template>

<style scoped lang="scss">
.files {
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 80vh;
  max-width: 1200px;
  max-height: 900;
  max-width: 1200px;
  max-height: 400;
  overflow: hidden;

  .title {
    flex: 0;
    display: block;
    white-space: nowrap;
  }

  .items {
    flex: 1;
    overflow: auto;
  }
}
</style>