<script>
import { mapGetters } from 'vuex'
import { formatDateTime, formatTime } from '@stellacontrol/utilities'
import { isToday } from 'date-fns'
export default {
  props: {
    // List of alerts to be displayed
    alerts: {
      required: true
    },
    // Title of the popup
    title: {
      type: String
    },
    // Indicates if the serial number is displayed or not
    showSerialNumber: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'toLocalDateTime'
    ]),

    // Indicates if this popup is already populated with alerts
    hasAlerts () {
      const { alerts } = this
      return alerts == null || alerts?.length == 0
    }
  },

  data () {
    return {
    }
  },

  emits: [
    'showing',
    'shown',
    'hiding',
    'hidden'
  ],

  methods: {
    alertTimeText (time) {
      const alertTime = this.toLocalDateTime(time)
      const timeText = isToday(alertTime)
        ? formatTime(alertTime)
        : formatDateTime(alertTime, 'd MMM, HH:mm')
      return timeText
    },

    onBeforeShow () {
      this.$emit('showing')
    },

    onShow () {
      this.$emit('shown')
    },

    onBeforeHide () {
      this.$emit('hiding')
    },

    onHide () {
      this.$emit('hidden')
    },

    show () {
      this.$refs.popup.show()
    },

    hide () {
      this.$refs.popup.hide()
    },

    toggle () {
      this.$refs.popup.toggle()
    }
  }
}
</script>

<template>
  <q-popup-proxy ref="popup" @before-show="onBeforeShow" show="onShow" @before-hide="onBeforeHide"
    @hide="onHide">
    <div class="alerts-popup" @click="hide()">
      <header v-if="title" class="bg-indigo-7 text-white text-bold row items-center no-wrap">
        <span class="title">
          {{ title }}
        </span>
        <q-space></q-space>
        <q-btn size="sm" round flat dense icon="close" color="white" v-close-popup>
        </q-btn>
      </header>

      <section class="alerts" :class="{ 'show-serial': showSerialNumber }">

        <p v-if="hasAlerts">
          Loading ...
        </p>

        <template v-else v-for="item in alerts">
          <div class="message">{{ item.message }}</div>
          <div class="serial" v-if="showSerialNumber">
            {{ item.device.serialNumber }}
          </div>
          <div class="time">
            {{ alertTimeText(item.time) }}
          </div>
        </template>

      </section>
    </div>
  </q-popup-proxy>
</template>

<style lang="scss" scoped>
.alerts-popup {
  min-width: 300px;
  min-height: 50px;
  max-width: 95vw;
  max-height: 350px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #f8f8ff;

  >header {
    overflow: hidden;
    flex: 0;
    padding: 10px;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .alerts {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
    padding: 16px;

    &.show-serial {
      grid-template-columns: auto auto auto;
    }

    .message {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .serial {
      white-space: nowrap;
      padding-left: 8px;
    }

    .time {
      white-space: nowrap;
      padding-left: 8px;
      justify-self: end;
    }
  }
}

/* Layout adjustments for small screens */
/* Layout adjustments for small screens */
@media screen and (width <=640px) {
  .alerts-popup {
    font-size: 15px;

    .alerts {
      gap: 2px;
      padding: 8px;
    }
  }
}

@media screen and (width <=480px) {
  .alerts-popup {
    width: 100vw;
    background-color: #f8f8f8;

    .alerts {
      &.show-serial {
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;

        .message:nth-child(3n+1) {
          grid-column: 1;
        }

        .serial:nth-child(3n+2) {
          grid-column: 2;
          justify-self: end;
        }

        .time:nth-child(3n+3) {
          grid-column: 1;
          justify-self: start;
          padding-left: 0;
          padding-bottom: 6px;
          color: #0a0a0a;
          font-style: italic;
        }
      }
    }
  }
}
</style>