import { Log, parseDate } from '@stellacontrol/utilities'
import { Device, Place, FloorPlan, DeviceFlags, LogBundle, LogCategory, LogFormat, AttachmentType } from '@stellacontrol/model'
import { APIClient } from './api-client'

/**
 * Device API client
 */
export class DeviceAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'Device'
  }

  /**
   * Retrieves a specified device
   * @param id Identifier of a device to retrieve
   * @param withDetails If true, device details are retrieved such as profile, place where it belongs etc.
   * @param withParents If true, device owner, creator, organization and all other such details are retrieved.
   */
  async getDevice ({ id, withDetails = false, withParents = false } = {}) {
    try {
      const url = this.endpoint('device', id)
      const params = { details: withDetails, parents: withParents }
      const { device } = await this.request({ url, params })
      return this.asDevice(device)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves a status of a specified device or a list of devices
   * @param {Array[Device]} devices List of devices to retrieve
   * @param {String} id Identifier of a device to retrieve
   * @param {String} serialNumber Alternative, serial number of a device to retrieve
   * @param {Boolean} raw If raw is set to true, only raw MEGA is returned, otherwise a parsed `DeviceStatus` structure
   * @param {Boolean} isFastSampling Indicates that we're in fast mode, so skip some checks as we only need the status now
   * @param {String} part Identifier of the part of the status to return
   * @param {Boolean} updateSnapshot If true, status information in device snapshot should be updated with the retrieved status.
   * @return {Promise<Object>} Device status
   * @description IMPORTANT! The returned data is a raw object.
   * To turn it into {@link DeviceStatus}, use `DeviceStatus.from()` factory function.
   */
  async getDeviceStatus ({ devices, id, serialNumber, raw, isFastSampling, part, updateSnapshot } = {}) {
    try {
      const params = {}
      if (raw != null) params.raw = Boolean(raw)
      if (isFastSampling != null) params.fast = Boolean(isFastSampling)
      if (part != null) params.part = part
      if (updateSnapshot != null) params.updateSnapshot = Boolean(updateSnapshot)

      if (devices) {
        const method = 'post'
        const url = this.endpoint('device', 'status')
        const data = { devices: devices.map(({ id, serialNumber }) => ({ id, serialNumber })) }
        const { results } = await this.request({ method, url, data, params, progress: false })
        return results || []

      } else {
        const url = this.endpoint('device', serialNumber ? 'serial-number' : '', serialNumber || id, 'status')
        const { status } = await this.request({ url, params, progress: false, retry: false })
        return status
      }

    } catch (error) {
      // If failure during fast sampling, be concise with logging
      if (isFastSampling) {
        if (devices) {
          Log.warn(`Error retrieving status [${devices.map(d => d.serialNumber || d.id).join(',')}]`, error.message)
          return []
        } else {
          Log.warn(`[${serialNumber || id}}]: error retrieving status`, error.message)
        }
      }

      // Handle any auth errors
      this.handleAuthenticationError(error)
    }
  }

  /**
   * Retrieves all known device types, firmwares, hardwares, models etc.
   * @param {String} minFirmwareVersion Minimal required firmware version for the interrogated devices, optional
   * @param {String} maxFirmwareVersion Maximal required firmware version for the interrogated devices, optional
   * @returns Dictionary of all known device types, firmwares, hardwares, models etc.
   */
  async getDeviceTypes ({ minFirmwareVersion, maxFirmwareVersion } = {}) {
    try {
      const url = this.endpoint('device', 'type')
      const params = { minFirmwareVersion, maxFirmwareVersion }
      const { types, models, firmwares, hardwares } = await this.request({ url, params })
      return { types, models, firmwares, hardwares }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sends a command to the specified devices
   * @param devices Devices to send the command to. Either identifier or serial number is required on each device.
   * @param device Alternatively, a single device to send the command to. Either identifier or serial number is required.
   * @param name Name of the command to send
   * @param parameters Command parameters
   */
  async sendCommand ({ devices, device, command: { name, parameters } = {} } = {}) {
    try {
      const method = 'post'
      const url = device
        ? this.endpoint('device', device.id ? '' : 'serial-number', device.id || device.serialNumber, 'command')
        : this.endpoint('device', 'command')
      const data = {
        name,
        parameters,
        devices: devices ? devices.map(({ id, serialNumber }) => ({ id, serialNumber })) : undefined
      }
      const { results } = await this.request({ method, url, data })
      return results
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Switches device bands on and off
   * @param {String} serialNumber Device serial number
   * @param {Object} bands Dictionary of bands to turn on and off.
   * Keys are band identifiers: `07`, `08`, `09`, `18`, `21` and `26`.
   * Values are true or false, depending on the required status of particular band.
   * You don't have to specify status of all bands, but only those which need to be changed.
   * @returns {Promise<Device>} Resolves with command results
   */
  async setDeviceBands ({ device, bands = [] } = {}) {
    if (!device) throw new Error('Device is required')
    if (!bands) throw new Error('Bands are required')

    if (bands.length > 0) {
      try {
        const method = 'post'
        const url = device
          ? this.endpoint('device', device.id ? '' : 'serial-number', device.id || device.serialNumber, 'bands')
          : this.endpoint('device', 'bands')
        const data = { ...bands }
        const result = await this.request({ method, url, data })
        return result
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Sends REBOOT command to the specified devices
   * @param devices Devices to send the command to. Either identifier or serial number is required on each device.
   * @param device Alternatively, a single device to send the command to. Either identifier or serial number is required.
   */
  async rebootDevice ({ devices, device } = {}) {
    try {
      const method = 'post'
      const url = device
        ? this.endpoint('device', device.id ? '' : 'serial-number', device.id || device.serialNumber, 'reboot')
        : this.endpoint('device', 'reboot')
      const data = {
        devices: devices
          ? devices.map(({ id, serialNumber }) => ({ id, serialNumber }))
          : undefined
      }
      const { results } = await this.request({ method, url, data })
      return results
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sends RESET command to the specified devices
   * @param devices Devices to send the command to. Either identifier or serial number is required on each device.
   * @param device Alternatively, a single device to send the command to. Either identifier or serial number is required.
   */
  async resetDevice ({ devices, device } = {}) {
    try {
      const method = 'post'
      const url = device
        ? this.endpoint('device', device.id ? '' : 'serial-number', device.id || device.serialNumber, 'reset')
        : this.endpoint('device', 'reset')
      const data = {
        devices: devices
          ? devices.map(({ id, serialNumber }) => ({ id, serialNumber }))
          : undefined
      }
      const { results } = await this.request({ method, url, data })
      return results
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Updates device settings in device shadow
   * @param {Array[Device]} devices Devices to update
   * @param {Dictionary<String, any>} parameters Settings to update
   */
  async updateSettings ({ devices = [], parameters = {} }) {
    try {
      const method = 'put'
      const url = this.endpoint('device-settings')
      const data = {
        parameters,
        devices: devices.map(({ id }) => ({ id }))
      }
      const { results, error } = await this.request({ method, url, data })
      return { results, error }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Clears customized device settings
   * @param {Array[Device]} devices Devices to clear
   * @param {Array[String]} parameters Settings to clear. If not specified, the entire desired shadow is cleared.
   */
  async clearSettings ({ devices = [], parameters }) {
    try {
      const method = 'delete'
      const url = this.endpoint('device-settings')
      const data = {
        parameters,
        devices: devices.map(({ id }) => ({ id }))
      }
      const { results, error } = await this.request({ method, url, data })
      return { results, error }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Makes sure that device settings are reconciled with the device shadow
   * @param {Device} device Device to reconcile
   * @param {Array[String]} parameters Parameters to reconcile. If not specified,
   * all currently unreconciled parameters will be reconciled
   * @returns {Promise<DeviceSettings>} Device settings after eventual reconciliation
   */
  async reconcileSettings ({ device, parameters }) {
    try {
      const { id, serialNumber } = device
      const method = 'put'
      const url = id
        ? this.endpoint('device-settings', id, 'reconcile')
        : this.endpoint('device-settings', 'serial-number', serialNumber, 'reconcile')
      const data = { parameters }
      const { result, error, warning } = await this.request({ method, url, data })
      return { result, error, warning }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Resets device settings to their factory values
   * @param {Array[Device]} devices Devices to reset
   * @param {Dictionary<String, any>} parameters Settings to reset. If not specified, the default set of factory settings will be applied.
   * @param {Boolean} clearShadow If true, the reset settings are then cleared from the device shadow
   */
  async resetSettings ({ devices = [], parameters, clearShadow }) {
    try {
      const method = 'put'
      const url = this.endpoint('device-settings', 'reset')
      const data = {
        parameters,
        devices: devices.map(({ id }) => ({ id })),
        clearShadow
      }
      const { results, error } = await this.request({ method, url, data })
      return { results, error }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves a specified device by its serial number
   * @param serialNumber Serial number of a device to retrieve
   * @param withDetails If true, device details are retrieved such as profile, place where it belongs etc.
   * @param withParents If true, device owner, creator, organization and all other such details are retrieved.
   */
  async getDeviceBySerialNumber ({ serialNumber, withDetails = false, withParents = false } = {}) {
    try {
      const url = this.endpoint('device', 'serial-number', serialNumber)
      const params = { details: withDetails, parents: withParents }
      const { device } = await this.request({ url, params })
      return this.asDevice(device)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Checks if the specified device exists
   * @param id Device identifier
   * @param serialNumber Device serial number, alternative to identifier
   */
  async deviceExists ({ id, serialNumber }) {
    try {
      const url = id
        ? this.endpoint('device', id, 'exists')
        : this.endpoint('device', 'serial-number', serialNumber, 'exists')
      return await this.request({ url }) || {}
    } catch (error) {
      this.handleError(error)
      return false
    }
  }

  /**
   * Checks whether the specified devices exist,
   * returns a list of those which do
   * @param devices Devices to check
   */
  async devicesExist ({ devices = [] }) {
    try {
      const method = 'post'
      const url = this.endpoint('device', 'exists')
      const data = { devices }
      const { exist = [] } = await this.request({ method, url, data }) || {}
      return exist
    } catch (error) {
      this.handleError(error)
      return false
    }
  }

  /**
   * Retrieves all devices available to the specified organization
   * and accessible to the current user
   * @param {String} id Identifier of organization whose devices are to be returned
   * @param {Boolean} optimized If true, minimal data set is returned
   * @returns {Promise<Array[Device]>}
   */
  async getDevices ({ id, optimized } = {}) {
    try {
      const url = id
        ? this.endpoint('organization', id, 'device', 'all')
        : this.endpoint('device', 'all')
      const params = { optimized }
      const { devices } = await this.request({ url, params })
      return this.asDevices(devices)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Retrieves all devices owned by the specified organization
   * and accessible to the current user
   * @param {String} id Identifier of organization whose devices are to be returned
   * @param {Boolean} noCache Indicates that we don't want any cached data
   * @param {Boolean} refresh Indicates that we want to refresh the cached data
   * @returns {Promise<Array[Device]>}
   */
  async getOwnDevices ({ id, noCache, refresh } = {}) {
    try {
      const url = id
        ? this.endpoint('organization', id, 'device')
        : this.endpoint('device')
      const parameters = {
        nocache: noCache ? true : undefined,
        refresh: refresh ? true : undefined,
      }
      const { devices } = await this.request({ url, parameters })
      return this.asDevices(devices)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Retrieves all devices shared with the specified organization
   * and accessible to the current user
   * @param {String} id Identifier of organization whose devices are to be returned
   * @param {Boolean} noCache Indicates that we don't want cached data
   * @param {Boolean} refresh Indicates that we want to refresh the cached data
   * @returns {Promise<Array[Device]>}
   */
  async getSharedDevices ({ id, noCache, refresh } = {}) {
    try {
      const url = id
        ? this.endpoint('organization', id, 'device', 'shared')
        : this.endpoint('device', 'shared')
      const params = {
        nocache: noCache ? true : undefined,
        refresh: refresh ? true : undefined
      }
      const { devices } = await this.request({ url, params })
      return this.asDevices(devices)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Retrieves all devices accessible to the current organization and user,
   * including those owned by the organization directly and those which
   * were shared with the organization in any way
   * @param {String} id Identifier of organization whose devices are to be returned
   * @param {Boolean} noCache Indicates that we don't want cached data
   * @param {Boolean} refresh Indicates that we want to refresh the cached data
   * @returns {Promise<Array[Device]>}
   */
  async getAvailableDevices ({ id, noCache, refresh } = {}) {
    try {
      const url = id
        ? this.endpoint('organization', id, 'device', 'all')
        : this.endpoint('device', 'all')

      // Ask for data optimized, by extracting redundant relationships into separate collections,
      // cleaning the returned data of nulls and empty collections etc
      const params = {
        optimized: true,
        nocache: noCache ? true : undefined,
        refresh: refresh ? true : undefined
      }
      const { devices } = await this.request({ url, params })
      return this.asDevices(devices)

    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Saves a device
   * @param {Device} device Device to save
   * @returns {Promise<Device>} Updated device
   */
  async saveDevice ({ device }) {
    try {
      const data = { ...device }
      const { id } = data
      const method = id == null ? 'post' : 'put'
      const url = this.endpoint('device', id)
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Splits a multi-device
   * @param {Device} device Device to split
   * @returns {Promise<Device>} Updated device
   */
  async splitMultiDevice ({ device }) {
    try {
      const { id } = device
      const method = 'put'
      const data = { id }
      const url = this.endpoint('device', id, 'split')
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Adds a part to a multi-device
   * @param {Device} device Multi-device
   * @param {Device} part Part to add to the device
   * @returns {Promise<Device>} Updated device
   */
  async addPartToMultiDevice ({ device, part }) {
    try {
      const method = 'put'
      const data = { id: part.id }
      const url = this.endpoint('device', device.id, 'part')
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Removes a part from a multi-device
   * @param {Device} device Multi-device
   * @param {Device} part Part to remove from the device
   * @returns {Promise<Device>} Updated device
   */
  async removePartFromMultiDevice ({ device, part }) {
    try {
      const method = 'delete'
      const data = { id: part.id }
      const url = this.endpoint('device', device.id, 'part')
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sells a device to a new owner
   * @param {Device} device Device to sell
   * @param {Organization} organization New owner of the device
   * @param {Date} soldAt Date and time of the sale
   * @param {String} notes Additional notes
   * @param {String} premiumServiceId Premium service on which credited tokens can be spent
   * @returns {Promise<Device>} Sold device details
   */
  async sellDevice ({ device, organization, soldAt, notes, premiumServiceId }) {
    try {
      const data = {
        id: device.id,
        organizationId: organization.id,
        soldAt,
        notes,
        premiumServiceId
      }
      const method = 'put'
      const url = this.endpoint('device', device.id, 'sell')
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Links a device with an organization
   * @param device Device to link
   * @param organization New delegate of the device
   * @param type Link type, defaults to `delegate` unless specified otherwise
   * @param notes Additional notes
   */
  async linkDevice ({ device, organization, type, notes }) {
    try {
      const data = {
        id: device.id,
        organizationId: organization.id,
        type,
        notes
      }
      const method = 'put'
      const url = this.endpoint('device', device.id, 'link')
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Unlinks a device from an organization
   * @param device Device to unlink
   * @param organization Delegate of the device
   * @param type Link type, defaults to `delegate` unless specified otherwise
   * @param notes Additional notes
   */
  async unlinkDevice ({ device, organization, type, notes }) {
    try {
      const data = {
        id: device.id,
        organizationId: organization.id,
        type,
        notes
      }
      const method = 'put'
      const url = this.endpoint('device', device.id, 'unlink')
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Decommissions a device
   * @param {Device} device Device to decommission
   * @param {String} notes Notes to register in the audit log
   */
  async decommissionDevice ({ device, decommissionedOn, notes }) {
    try {
      const data = {
        id: device.id,
        decommissionedOn,
        notes
      }
      const method = 'put'
      const url = this.endpoint('device', device.id, 'decommission')
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Swaps a device with a replacement device which will take over all settings and associated data.
   * @param {Device} device Device to replace
   * @param {Device} replaceWith Replacement device
   * @param {Boolean} copyPlace If true, the replacement device is assigned to the same place as the old device
   * @param {Boolean} copyAlerts If true, alert configuration of the old device is applied to the replacement device
   * @param {Boolean} copyPremiumServices If true, premium services of the old device are transferred to the replacement device
   * @param {Boolean} copyComments If true, notes associated with the old device will be moved to the replacement device
   * @param {String} notes Notes to register in the audit log
   */
  async swapDevice ({ device, replaceWith, copyPlace, copyAlerts, copyPremiumServices, copyComments, notes }) {
    try {
      const method = 'put'
      const url = this.endpoint('device', device.id, 'swap', replaceWith.id)
      const data = { copyPlace, copyAlerts, copyPremiumServices, copyComments, notes }
      const result = await this.request({ method, url, data })
      if (result) {
        return {
          device: this.asDevice(result.device),
          replaceWith: this.asDevice(result.replaceWith)
        }
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Clears device data
   * @param {Device} device Device to clear
   * @returns {Promise<Device>}
   */
  async clearDevice ({ device }) {
    try {
      const data = {
        id: device.id
      }
      const method = 'put'
      const url = this.endpoint('device', device.id, 'clear')
      const { device: savedDevice } = await this.request({ method, url, data })
      return this.asDevice(savedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the specified device
   * @param data Device to delete
   */
  async deleteDevice ({ device: { id } }) {
    try {
      const method = 'delete'
      const url = this.endpoint('device', id)
      const { device } = await this.request({ method, url })
      return this.asDevice(device)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the specified place
   * @param id Place identifier
   * @param withDetails If true, place details are retrieved such as devices under it etc.
   */
  async getPlace ({ id, withDetails }) {
    try {
      const url = this.endpoint('place', id)
      const { place } = await this.request({ url, params: { details: withDetails } })
      return this.asPlace(place)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves places of an organization - the current one unless specified otherwise
   * @param {Organization} organization Organization
   * @param {Boolean} withDetails If true, place details are retrieved such as devices under it etc.
   * @param {Boolean} withChildren If true, places of all child organisations are retrieved as well
   * @returns {Promise<Array[Place]>}
   */
  async getPlaces ({ organization, withDetails, withChildren } = {}) {
    try {
      const url = organization
        ? this.endpoint('organization', organization.id, 'place')
        : this.endpoint('place')
      const params = {
        details: withDetails,
        children: withChildren
      }
      const { places } = await this.request({ url, params })
      return this.asPlaces(places)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Checks if the specified place exists in an organization
   * @param {Organization} organization Organization to which the place belongs
   * @returns {Promise<Boolean>}
   */
  async placeExists ({ name, organization }) {
    try {
      const url = this.endpoint('organization', organization.id, 'place', name, 'exists')
      const { id, exists } = await this.request({ url }) || {}
      return { id, exists }
    } catch (error) {
      this.handleError(error)
      return false
    }
  }

  /**
   * Returns place with the specified name from an organization
   * @param {String} name Place name
   * @param {Organization} organization Organization to which the place belongs
   * @returns {Promise<Place>}
   */
  async getPlaceByName ({ name, organization }) {
    try {
      const url = this.endpoint('organization', organization.id, 'place', name)
      const { place } = await this.request({ url }) || {}
      return place
    } catch (error) {
      this.handleError(error)
      return false
    }
  }

  /**
   * Saves a place
   * @param {Place} place Place to save
   * @returns {Promise<Place>} Saved place
   */
  async savePlace ({ place }) {
    try {
      const data = { ...place }
      const { id } = data

      // Purge excessive runtime data
      delete data.__attachments
      delete data.organization
      delete data.devices
      delete data.notes

      const method = id == null ? 'post' : 'put'
      const url = this.endpoint('place', id)
      const { error, place: savedPlace } = await this.request({ method, url, data })
      return savedPlace
        ? { place: this.asPlace(savedPlace) }
        : { error }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the specified place
   * @param {Place} place Place to delete
   * @returns {Promise<Place>} Deleted place
   */
  async deletePlace ({ place: { id } = {} }) {
    try {
      const method = 'delete'
      const url = this.endpoint('place', id)
      const { place } = await this.request({ method, url })
      return this.asPlace(place)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Assigns device to a place
   * @param device Device to assign to the place
   * @param place Place to assign to
   */
  async setDevicePlace ({ device, place }) {
    try {
      const method = 'put'
      const url = this.endpoint('place', place.id, 'device', device.id)
      const { place: updatedPlace } = await this.request({ method, url })
      return this.asPlace(updatedPlace)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Remove device from a place
   * @param device Device to remove from the place
   * @param place Place to remove the device from
   */
  async removeDeviceFromPlace ({ device, place }) {
    try {
      const method = 'delete'
      const url = this.endpoint('place', place.id, 'device', device.id)
      const { place: updatedPlace } = await this.request({ method, url })
      return this.asPlace(updatedPlace)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Clones a building, optionally with other data such as plans
   * @param {Place} building Building to clone
   * @param {Boolean} clonePlan If `true`, plan belonging to the building will be cloned too
   * @returns {Promise<Place>}
   */
  async cloneBuilding ({ building, clonePlan } = {}) {
    try {
      const method = 'put'
      const url = this.endpoint('place', 'clone')
      const data = { building, clonePlan }
      const { building: result } = await this.request({ method, url, data })
      return this.asPlace(result)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sets device location
   * @param device Device whose custom location is to be set
   * @param location Location details
   * @param customLocation Custom location details
   */
  async setDeviceLocation ({ device, location, customLocation } = {}) {
    try {
      const method = 'put'
      const url = this.endpoint('device', device.id, 'location')
      const data = {
        location,
        customLocation
      }
      const { device: updatedDevice } = await this.request({ method, url, data })
      return this.asDevice(updatedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sets device comments
   * @param device Device whose comments are to be set
   * @param comments Comments text
   */
  async setDeviceComments ({ device, comments } = {}) {
    try {
      const method = 'put'
      const url = this.endpoint('device', device.id, 'comments')
      const data = {
        comments
      }
      const { device: updatedDevice } = await this.request({ method, url, data })
      return this.asDevice(updatedDevice)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sets order of the specified places
   * @param places Places to save
   */
  async setPlacesOrder ({ places = [] }) {
    const data = {
      places: places.map(({ id, sortOrder }) => ({ id, sortOrder }))
    }
    if (data.places.length > 0) {
      try {
        const method = 'put'
        const url = this.endpoint('place', 'order')
        await this.request({ method, url, data })
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Sets order of the specified devices
   * @param devices Devices to save
   */
  async setDevicesOrder ({ devices = [] }) {
    const data = {
      devices: devices.map(({ id, sortOrder }) => ({ id, sortOrder }))
    }
    if (data.devices.length > 0) {
      try {
        const method = 'put'
        const url = this.endpoint('device', 'order')
        await this.request({ method, url, data })
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Sets device group inside its place
   * @param device Device to update
   * @param placeGroup Group within the place, where the device belongs
   * @param placeSeparator Alternatively, device is preceded by a separator
   */
  async setDeviceGroup ({ device, placeGroup, placeSeparator }) {
    try {
      const method = 'put'
      const data = { placeGroup, placeSeparator }
      const url = this.endpoint('device', device.id, 'group')
      await this.request({ method, url, data })
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sets device groups inside its place
   * @param devices Devices to update
   */
  async setDeviceGroups ({ devices }) {
    try {
      const method = 'put'
      const data = {
        devices: devices.map(({ id, placeGroup, placeSeparator }) => ({ id, placeGroup, placeSeparator }))
      }
      const url = this.endpoint('device', 'group')
      await this.request({ method, url, data })
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the specified floor plan
   * @param id FloorPlan identifier
   * @param withDetails If true, floor plan details are retrieved such as devices under it etc.
   */
  async getFloorPlan ({ id, withDetails }) {
    try {
      const url = this.endpoint('floor-plan', id)
      const { floorPlan } = await this.request({ url, params: { details: withDetails } })
      return this.asFloorPlan(floorPlan)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves floor plans of an organization
   * (current one unless specified otherwise)
   * @param organization Organization
   * @param withDetails If true, floor plan details are retrieved such as devices under it etc.
   * @param withChildren If true, also floor plans of child organizations are retrieved
   */
  async getFloorPlans ({ organization, withDetails, withChildren }) {
    try {
      const url = organization
        ? this.endpoint('organization', organization.id, 'floor-plan')
        : this.endpoint('floor-plan')
      const { floorPlans } = await this.request({ url, params: { details: withDetails, children: withChildren } })
      return this.asFloorPlans(floorPlans)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Checks if the specified floor plan exists in an organization
   * @param name Floor plan name
   * @param organizationId Organization identifier
   */
  async floorPlanExists ({ name, organizationId }) {
    try {
      const url = this.endpoint('organization', organizationId, 'floor-plan', name, 'exists')
      const { id, exists } = await this.request({ url }) || {}
      return { id, exists }
    } catch (error) {
      this.handleError(error)
      return false
    }
  }

  /**
   * Saves a floor plan
   * @param floorPlan Floor plan to save
   */
  async saveFloorPlan ({ floorPlan }) {
    try {
      const data = {
        ...floorPlan,
        organization: undefined
      }
      const { id } = data
      const method = id == null ? 'post' : 'put'
      const url = this.endpoint('floor-plan', id)
      const { floorPlan: savedFloorPlan } = await this.request({ method, url, data })
      return this.asFloorPlan(savedFloorPlan)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns the specified floor plan model
   * @param id Identifier of a floor plan whose model to retrieve
   */
  async getFloorPlanModel ({ id }) {
    try {
      const method = 'get'
      const url = this.endpoint('floor-plan', id, 'model')
      const { model, modelVersion } = await this.request({ method, url })
      return { model, modelVersion }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Checks if the specified floor plan model can be saved
   * (it hasn't been edited by someone else in the meantime)
   * @param id Identifier of a floor plan whose model will be saved
   * @param modelVersion Floor plan model version
   */
  async canSaveFloorPlanModel ({ id, modelVersion: currentModelVersion }) {
    try {
      const method = 'get'
      const url = this.endpoint('floor-plan', id, 'model', 'can-update', currentModelVersion)
      const { exists, modelVersion, canUpdate } = await this.request({ method, url })
      return { id, exists, modelVersion, canUpdate }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves a floor plan model
   * @param id Identifier of a floor plan whose model to save
   * @param model Floor plan model to save
   * @param modelVersion Floor plan model version
   */
  async saveFloorPlanModel ({ id, model, modelVersion }) {
    try {
      const data = { id, model, modelVersion }
      const method = 'put'
      const url = this.endpoint('floor-plan', id, 'model')
      const { floorPlan: savedFloorPlan } = await this.request({ method, url, data })
      return this.asFloorPlan(savedFloorPlan)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the specified floor plan
   * @param floorPlan Floor plan to delete
   */
  async deleteFloorPlan ({ floorPlan: { id } }) {
    try {
      const method = 'delete'
      const url = this.endpoint('floor-plan', id)
      const { floorPlan } = await this.request({ method, url })
      return this.asFloorPlan(floorPlan)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves device status entries recorded during the specified period, optionally with values
   * @param {String} id Identifier number of a device to query
   * @param {String} serialNumber Alternatively, serial number of a device to query
   * @param {Date} from Period start
   * @param {Date} to Period end (exclusive)device, query, history* @param {Boolean} withStatus If specified, device status reported by the device will be returned
   * @param {Number} decimate If present, excessive data will be decimated to retain the specified number of status data points
   * @param {Boolean|Array[String]} withParameters If specified, also the values recorded with the status will be returned.
   * If `true` passed, all the values will be returned, otherwise the parameters with the specified names or keys.
   * Keys are particularly useful for retrieving band variables. Instead of specifying them one by one, i.e.
   * 'mgn_dw_08', 'mgn_dw_18' one can just pass the key 'mgn_dw' and obtain the entire group in the result.
   * @param {Boolean} withSettings If specified, also the settings changes performed on the device will be returned
   * @param {Boolean} withCommands If specified, also the commands sent to the device will be returned
   * @param {Boolean} withAlerts If specified, alerts triggered by the device will be returned
   * @param {Boolean} withUpdates If specified, firmware updates performed on the device will be returned
   * @param {Boolean} debug Detailed profiling is ON
   * @returns {Promise} Combined history of the device
   */
  async getDeviceHistory ({ id, serialNumber, from, to, decimate, withStatus, withParameters, withSettings, withCommands, withAlerts, withUpdates, debug }) {
    try {
      const method = 'get'
      const url = this.endpoint('device', id ? '' : 'serialNumber', id || serialNumber, 'history')
      const params = {
        serialNumber,
        from,
        to,
        decimate,
        status: withStatus,
        parameters: Array.isArray(withParameters) ? withParameters.join(',') : (withParameters ? 'true' : undefined),
        settings: withSettings,
        commands: withCommands,
        alerts: withAlerts,
        updates: withUpdates,
        debug
      }
      const { device, query, history } = await this.request({ method, url, params, retry: false }) || {}

      for (const item of history) {
        item.time = parseDate(item.time)
      }

      return { device, query, history }

    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns all simulated devices,
   * whose status messages will be generated programmatically
   * @param {Organization} organization Optional owner of devices. If not specified, all simulated devices are returned
   * @returns {Array[Device]} Simulated devices
   */
  async getSimulatedDevices ({ organization } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('device', 'simulated')
      const params = organization ? { organization: organization.id } : undefined
      const { devices } = await this.request({ method, url, params }) || {}
      return this.asDevices(devices)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sets device flags
   * @param {Device} device Device to start logging
   * @param {DeviceFlags} flags Device flags to set
   * @returns {DeviceFlags} Modified device flags
   */
  async setFlags ({ device, flags }) {
    try {
      const method = 'put'
      const url = this.endpoint('device', 'serial-number', device.serialNumber, 'flags')
      const data = { ...flags }
      const { flags: updatedFlags } = await this.request({ method, url, data }) || {}
      return this.asDeviceFlags(updatedFlags)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Initiates debug logging for the specified device.
   * Previously collected logs are cleared.
   * @param {User} user User starting the logging
   * @param {Device} device Device to start logging
   * @param {Number} duration Duration of logging, in seconds
   * @param {Boolean} profile If true, also profiling is enabled
   * @param {Boolean} clear If true, any previously collected logs will be cleared first
   * @returns {DeviceFlags} Device flags
   */
  async startLogging ({ user, device, duration = 3600, profile, clear = true }) {
    try {
      const method = 'put'
      const url = this.endpoint('device', device.serialNumber, 'log', 'start')
      const params = { duration, profile, clear, user: user.id }
      const { flags } = await this.request({ method, url, params }) || {}
      return this.asDeviceFlags(flags)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Finishes debug logging for the specified device,
   * captures the collected logs in a bundle and clears the logs.
   * @param {User} user User stopping the logging
   * @param {Device} device Device to stop logging
   * @param {Boolean} capture If true, the data recorded so far should be captured and made ready for download
   * @returns {LogBundle} Details of the captured logs bundle
   */
  async stopLogging ({ user, device, capture = true }) {
    try {
      const method = 'put'
      const url = this.endpoint('device', device.serialNumber, 'log', 'stop')
      const params = { capture }
      const { id } = await this.request({ method, url, params }) || {}
      return new LogBundle({
        id,
        url: this.getLogBundleUrl({ device, id, user }),
        entity: device.serialNumber,
        category: LogCategory.Device,
        format: LogFormat.Binary,
        isCompressed: true
      })
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns log bundle
   * @param {User} user User retrieving the log bundle
   * @param {Device} device Device to retrieve the bundle
   * @param {String} id Log bundle identifier. Specify `recent` to retrive the most recent log bundle associated with the device'
   * @returns {LogBundle} Log bundle
   */
  async getLogBundle ({ user, device, id } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('device', device.serialNumber, 'log', id)
      const { bundle: data } = await this.request({ method, url }) || {}
      const bundle = this.asLogBundle(data)
      if (bundle) {
        bundle.url = this.getLogBundleUrl({ user, device, id: bundle.id })
      }
      return bundle
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns URL for downloading device log bundle
   * @param {User} user User downloading the log bundle
   * @param {Device} device Device to stop logging
   * @param {String} id Log bundle identifier
   * @returns {String} URL for downloading the log bundle
   */
  getLogBundleUrl ({ user, device, id } = {}) {
    const url = this.endpoint('device', device.serialNumber, 'log', id, 'download') + `?user=${user.id}`
    return url
  }

  /**
   * Downloads scan results sent by the specified device
   * @param {Device} device Device
   * @param {Number} age Number of recent days for which to return the results. If not specified, all scan results are returned.
   * @param {Boolean} content If true, scan contents are returned
   * @returns {Array[Scan]}
   */
  async getScans ({ device, age, content } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('device', device.id, 'scan')
      const params = { age, content }
      const { scans = [], errors } = await this.request({ method, url, params })
      return { scans, errors }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Downloads scan results reports
   * @param {Array[String]} identifiers Identifiers of reports to download
   * @param {Object} options Print options
   * @param {AttachmentType} format Report format, supported are `json`, `html` and `pdf`
   * @param {String} bundle Name of the output file (ZIP). If not specified, the report will be returned in original format.
   */
  async printScanReports ({ identifiers, options, format = AttachmentType.HTML, bundle } = {}) {
    try {
      const method = 'post'
      const url = this.endpoint('scan', 'print')
      const data = {
        identifiers,
        options,
        format,
        bundle
      }
      const { file } = await this.request({ method, url, data })
      return file
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Converts the specified data item
   * received from API to Device instance
   * @param {Object} item Data item
   * @returns {Device} Device instance initialized with the content of the data item
   */
  asDevice (item) {
    if (item) {
      return new Device(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to Device instance
   * @param {Array[Object]} items Data items
   * @returns {Array[Device]} Device instances initialized with the content of the data items
   */
  asDevices (items = []) {
    return items.map(item => new Device(item))
  }

  /**
   * Converts the specified data item
   * received from API to Place instance
   * @param {Object} item Data item
   * @returns {Place} Place instance initialized with the content of the data item
   */
  asPlace (item) {
    if (item) {
      const place = new Place(item)
      if (place.floorPlans) {
        place.floorPlans = this.asFloorPlans(place.floorPlans)
      }
      return place
    }
  }

  /**
   * Converts the specified data items
   * received from API to Place instances
   * @param {Array[Object]} items Data items
   * @returns {Array[Place]} Place instances initialized with the content of the data items
   */
  asPlaces (items = []) {
    return items.map(item => this.asPlace(item))
  }

  /**
   * Converts the specified data item
   * received from API to DeviceFlags instance
   * @param {Object} item Data item
   * @returns {DeviceFlags} DeviceFlags instance initialized with the content of the data item
   */
  asDeviceFlags (item) {
    if (item) {
      const flags = new DeviceFlags(item)
      return flags
    }
  }

  /**
   * Converts the specified data item
   * received from API to LogBundle instance
   * @param {Object} item Data item
   * @returns {LogBundle} LogBundle instance initialized with the content of the data item
   */
  asLogBundle (item) {
    if (item) {
      const bundle = new LogBundle(item)
      return bundle
    }
  }

  /**
   * Converts the specified data item
   * received from API to FloorPlan instance
   * @param {Object} item Data item
   * @returns {FloorPlan} FloorPlan instance initialized with the content of the data item
   */
  asFloorPlan (item) {
    if (item) {
      const floorPlan = new FloorPlan(item)
      return floorPlan
    }
  }

  /**
   * Converts the specified data items
   * received from API to FloorPlan instances
   * @param {Array[Object]} items Data items
   * @returns {Array[FloorPlan]} FloorPlan instances initialized with the content of the data items
   */
  asFloorPlans (items = []) {
    return items.map(item => this.asFloorPlan(item))
  }
}
