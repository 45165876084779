<script>
import { mapActions, mapGetters } from 'vuex'
import { capitalize } from '@stellacontrol/utilities'
import { FormMixin } from '@stellacontrol/client-utilities'
import { PlaceType, getPlaceIcon, getPlaceLabel, sortOrganizations, OrganizationSortOrder } from '@stellacontrol/model'
import { Secure } from '../../components'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      originalOrganization: null,
      PlaceType
    }
  },

  computed: {
    ...mapGetters([
      'organizations',
      'currentOrganization'
    ]),

    // Place types to choose from
    placeTypes () {
      return [
        this.placeType(PlaceType.Building),
        this.placeType(PlaceType.Ship),
        this.placeType(PlaceType.Vehicle),
        this.placeType(PlaceType.Other)
      ].filter(p => p)
    },

    // All organizations
    allOrganizations () {
      const { currentOrganization, organizations } = this
      return sortOrganizations(organizations, OrganizationSortOrder.Rank, { currentOrganization })
    },

    // Indicates that we're adding a place in another organization, not ours
    inAnotherOrganization () {
      return !this.data.isNew && this.data.organizationId !== this.originalOrganization
    },

    // Currently selected organization
    selectedOrganization () {
      const { allOrganizations, data } = this
      return allOrganizations.find(o => o.id === data.organizationId)
    }
  },

  methods: {
    ...mapActions([
      'organizationPlaceExists'
    ]),

    getPlaceIcon,
    getPlaceLabel,

    // Returns an option for place type select input
    placeType (value, options) {
      return {
        value,
        label: capitalize(getPlaceLabel(value, 'place')),
        ...options
      }
    },

    // Validation rule which checks whether the specified organization name is already in use
    async placeNameIsUnique (name) {
      const { organization } = this.data
      const { id, exists } = await this.organizationPlaceExists({ name, organization })
      const isUnique = !exists || id === this.data.id
      return isUnique || `Place with this name already exists in organization ${organization.name}`
    },

    // Organization has been selected, assign to place
    organizationSelected () {
      this.data.organization = this.selectedOrganization
    }
  },

  created () {
    this.originalOrganization = this.data.organizationId
  }
}

</script>

<template>
  <q-form ref="form" autofocus>
    <q-banner v-if="inAnotherOrganization" class="bg-orange-5">
      Warning! You are moving this place to another customer!
    </q-banner>

    <div class="row">
      <div class="col-sm-12 col-md-6 q-pa-md q-gutter-sm">
        <q-select class="q-mt-lg" dense square outlined v-model="data.placeType"
          :options="placeTypes" emit-value lazy-rules map-options :rules="[
      rules.required('Place type is required')
    ]">
          <template v-slot:before>
            <label>Type</label>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section side>
                <q-icon :name="getPlaceIcon(scope.opt.value)" color="indigo-5" size="sm" />
              </q-item-section>
              <q-item-section>
                <q-item-label v-html="scope.opt.label" />
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:selected>
            <div>
              <q-icon v-if="data.placeType" :name="getPlaceIcon(data.placeType)" size="sm"
                color="indigo-5" />
              <span class="q-ml-sm">{{ capitalize(getPlaceLabel(data.placeType, '-')) }}</span>
            </div>
          </template>
          <template v-slot:after>
            <sc-hint text="Type of the place, such as building, ship etc." />
          </template>
        </q-select>

        <sc-organization-selector v-if="!data.isNew" bg-color="white" dense square
          style="margin-bottom: 20px;" v-model="data.organizationId" :items="allOrganizations"
          @update:model-value="value => organizationSelected(value)">
          <template v-slot:before>
            <label>Customer</label>
          </template>
          <template v-slot:after>
            <sc-hint>
              Customer to which the {{ getPlaceLabel(data.placeType) }} belongs
            </sc-hint>
          </template>
        </sc-organization-selector>

        <q-input v-model="data.name" dense square outlined debounce="1000" maxlength="255"
          lazy-rules :rules="[
      rules.required('Name is required'),
      name => placeNameIsUnique(name)
    ]">
          <template v-slot:before>
            <label>
              {{ capitalize(getPlaceLabel(data.placeType)) }} Name
            </label>
          </template>
          <template v-slot:after>
            <sc-hint>
              Unique name of the {{ getPlaceLabel(data.placeType) }}
            </sc-hint>
          </template>
        </q-input>

        <q-input v-model="data.description" dense square outlined maxlength="255">
          <template v-slot:before>
            <label>
              {{ capitalize(getPlaceLabel(data.placeType)) }} Description
            </label>
          </template>
          <template v-slot:after>
            <sc-hint-placeholder />
          </template>
        </q-input>

        <q-input v-model="data.city" dense square outlined
          v-if="data.placeType === PlaceType.Building" class="q-mt-lg" maxlength="255">
          <template v-slot:before>
            <label>City</label>
          </template>
          <template v-slot:after>
            <sc-hint-placeholder />
          </template>
        </q-input>

        <q-input v-model="data.address" dense square outlined
          v-if="data.placeType === PlaceType.Building" class="q-mt-lg" maxlength="255">
          <template v-slot:before>
            <label>Address</label>
          </template>
          <template v-slot:after>
            <sc-hint-placeholder />
          </template>
        </q-input>

        <q-field class="q-mt-md" dense borderless
          v-if="isSuperAdministrator && data.placeType === PlaceType.Ship">
          <div class="row items-center">
            <q-toggle v-model="data.hasRegion" color="green"></q-toggle>
            <sc-hint>
              If enabled, RF region of devices in this
              {{ getPlaceLabel(data.placeType) }}
              can be changed by administrator.
            </sc-hint>
          </div>
          <template v-slot:before>
            <label>Can change RF Region?</label>
          </template>
        </q-field>

        <q-field dense borderless v-if="!data.isNew">
          <template v-slot:before>
            <label>Created</label>
          </template>
          <div class="row items-center">
            {{ data.createdText }}
          </div>
        </q-field>

        <q-field dense borderless v-if="!data.isNew">
          <template v-slot:before>
            <label>Updated</label>
          </template>
          <div class="row items-center">
            {{ data.updatedText }}
          </div>
        </q-field>
      </div>
    </div>
  </q-form>
</template>

<style lang='scss' scoped>
label {
  font-size: 14px;
  min-width: 180px;
}
</style>
