<script>
import { range } from '@stellacontrol/utilities'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  computed: {
    // Internal antenna ports with their PortSense monitoring status.
    // The ports are numbered from `1`, while `0` is reserved for the external port.
    ports () {
      return [...range(0, this.device.portCount + 1)].map(id => {
        const port = (this.configuration.parameters?.ports || []).find(p => p.id === id)
        const isExternal = id === 0
        const isInternal = id !== 0
        const isConnected = Boolean(isExternal
          ? this.status?.health?.externalAntenna
          : this.status?.health?.internalAntenna[id - 1])
        const label = port?.label || (isExternal ? 'Upstream' : `Antenna ${id.toString()}`)
        const isMonitored = Boolean(port?.isMonitored || isConnected)

        return { isExternal, isInternal, id, isConnected, label, isMonitored }
      })
    },

    // Internal antenna ports
    internalPorts () {
      return this.ports.filter(p => p.isInternal)
    },

    // External antenna port
    externalPort () {
      return this.ports.find(p => p.isExternal)
    },

    portTooltip () {
      return port => `${port.isMonitored ? 'Monitored' : 'Not monitored'} ${port.isConnected ? '(connected)' : '(disconnected)'}`
    },

    // Checks whether any antennas were connected to the device
    hasAntennas () {
      return this.internalPorts.some(p => p.isConnected) || this.externalPort.isConnected
    },

    // Indicates whether to show the antenna details
    showAntennas () {
      return this.hasAntennas && !this.isBatch
    }
  }
}
</script>

<template>
  <article v-if="isEditable && showAntennas">

    <div v-if="hasAntennas" class="q-ml-sm q-mt-md q-mb-md">
      <div class="q-mb-sm">
        External Antenna
      </div>
      <q-markup-table flat dense bordered square separator="cell" class="antennas">
        <tbody>
          <tr class="antenna">
            <td class="icon">
              <q-icon size="18px" class="q-mr-sm"
                :name="externalPort.isMonitored ? 'check_circle' : 'circle'"
                :color="externalPort.isConnected ? 'green-7' : 'grey-5'">
              </q-icon>
            </td>
            <td class="label">
              {{ externalPort.label }}
            </td>
            <td class="status">
              {{ portTooltip(externalPort) }}
            </td>
          </tr>
        </tbody>
      </q-markup-table>

      <div class="q-mb-sm q-mt-md">
        Internal Antennas
      </div>

      <q-markup-table flat dense bordered square separator="cell" class="antennas">
        <tbody>
          <tr v-for="port in internalPorts" class="antenna">
            <td class="icon">
              <q-icon size="18px" class="q-mr-sm"
                :name="port.isMonitored ? 'check_circle' : 'circle'"
                :color="port.isConnected ? 'green-7' : 'grey-5'">
              </q-icon>
            </td>
            <td class="label">
              {{ port.label }}
            </td>
            <td class="status">
              {{ portTooltip(port) }}
            </td>
          </tr>
        </tbody>
      </q-markup-table>

    </div>

    <div v-else class="q-ma-md text-orange">
      The antennas are not yet connected to the device ports.
      PortSense alert is not yet monitored.
    </div>

  </article>
</template>

<style scoped lang="scss">
.antennas {
  .antenna {
    td.icon {
      width: 40px;
    }

    td.status {
      width: 230px;
    }
  }
}
</style>