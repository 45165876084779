<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    // Menu item
    item: {
      type: Object,
      required: true
    },
    // Custom CSS classes to assign to item, separated by space
    customClass: {
      type: String,
      default: 'menu-item'
    },
    // Indicates whether item contains a navigation link
    isLink: {
      type: Boolean,
      default: true
    },
    // Indicates that we don't want uppercase in item title
    noCaps: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters([
      'menuItemTitle',
    ]),

    itemClass () {
      const { customClass = '', item, isLink } = this
      const itemClass = {
        ...customClass
          .split(' ')
          .reduce((all, c) =>
            ({ ...all, [c]: true }), {}),
        link: isLink,
        selected: item.isSelected,
        'has-items': Boolean(item.items)
      }
      return itemClass
    }
  },

  methods: {
    ...mapActions([
      'toggleMenuItem'
    ]),

    itemClicked (item) {
      this.$emit('selected', item)
      if (item.items && item.items.length > 0) {
        this.toggleMenuItem({ item })
      }
    }
  }
}
</script>

<template>
  <router-link :class="{ selected: item.isSelected }" v-if="item.route && isLink"
    :to="item.route ? { name: item.route, params: item.params, query: item.query } : undefined">

    <div class="menu-item" :class="itemClass" @click.stop="itemClicked(item)">

      <q-icon :name="item.icon" size="sm" class="q-mr-md">
      </q-icon>

      <label class="text-subtitle1">
        {{ noCaps ? menuItemTitle(item) : menuItemTitle(item).toUpperCase() }}
      </label>

      <q-icon v-if="item.items" :name="item.isCollapsed ? 'chevron_right' : 'expand_more'"
        size="26px" class="q-ml-md">
      </q-icon>

    </div>
  </router-link>

  <div v-else class="menu-item" :class="itemClass" @click.stop="itemClicked(item)">
    <q-icon :name="item.icon" size="sm" class="q-mr-md">
    </q-icon>

    <label class="text-subtitle1">
      {{ noCaps ? menuItemTitle(item) : menuItemTitle(item).toUpperCase() }}
    </label>

    <q-icon v-if="item.items" :name="item.isCollapsed ? 'chevron_right' : 'expand_more'" size="26px"
      class="q-ml-md">
    </q-icon>
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  color: #9fa8da;

  >label {
    white-space: nowrap;
  }

  >.q-icon,
  label {
    cursor: pointer;
  }

  &:hover,
  &:active {
    color: #fff !important;
  }

  &.selected {
    color: #fff !important;
    background-color: #414b7d;
  }
}

a {
  text-decoration: none;
}
</style>
