import { PlanAction, PlanActions } from './plan-action'
import { createShape } from '../shapes'

/**
 * Adds a new item to the plan
 */
export class AddItemAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.AddItem
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanFloor} floor Floor to add to, optional. If not specified, we use the current floor
   * @param {Array[PlanItem]} items Plan items to apply the action to
   * @param {Point} position Position at which the action should be executed
   * @param {Boolean} select If true, added item will become selected
  */
  async execute ({ renderer, floor, items, position, select } = {}) {
    if (renderer && items) {
      for (const item of items) {
        floor = floor || renderer.selectedFloor
        if (item && floor) {
          // Add the item to the floor
          renderer.layout.addItem(item, floor)

          // Render the item on the plan
          await this.render({ renderer, item, position, select })
        }

        // Refresh all
        await renderer.refreshLegends()
        await renderer.refreshEquipmentHierarchy()
      }

      return items
    }
  }

  /**
   * Renders the newly added item
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item Item to render
   * @param {Point} position Position at which the action was executed
   * @param {Boolean} select If true, added item will become selected
   */
  async render ({ renderer, item, position, select } = {}) {
    if (renderer && item) {
      // Check if item floor matches the one currently viewed at renderer
      if (!renderer.canRenderOnTheFloor(item)) return

      // Determine the layer to which to add the item shape
      const layer = item.layer ? renderer.getLayer(item.layer) : renderer.itemLayer

      // Create the shape and add to items layer
      const shape = createShape(item, renderer.dataCallback)
      if (shape != null && !shape.isEmpty) {
        layer.add(shape)
        renderer.bindShapeEvents(layer, shape)
      }

      // Mark the item as selected.
      // A little delay is needed to ensure that te item is actually rendered,
      // before it can be selected
      if (select && !layer.isLocked) {
        setTimeout(() => renderer.selectItem({ item, position }), 100)
      }

      // Notify all layers so they can update other dependent items
      renderer.notifyLayers(layer => layer.itemAdded(item))
    }
  }

  /**
   * Undoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
  */
  async undo ({ renderer, data }) {
    await super.undo({ renderer, data })

    // Remove the added items from the layout
    const items = []
    const { layout } = renderer
    for (const { id } of data.items) {
      const item = layout.getItem(id)
      if (!item) continue
      if (!item.canRemove) continue

      // Get the item and all its related items that have to go with it
      const floor = layout.getFloorOf(item)
      const itemsToDelete = layout.getItemAndConnectors(item)
      for (const item of itemsToDelete) {
        items.push(item)
        layout.removeItem(item, floor)
        // If removed item is a connector, re-render the items which it used to connect
        if (item.isConnector) {
          renderer.refreshItem(item.start.item)
          renderer.refreshItem(item.end.item)
        }
      }
    }

    // Re-evaluate legend elements to show accurate equipment counts
    await renderer.refreshLegends()
    await renderer.refreshEquipmentHierarchy()
    for (const item of items) {
      renderer.notifyLayers(layer => layer.itemRemoved(item))
    }

    // Change notification
    renderer.changed({ action: this })
  }
}
