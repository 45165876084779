<script>
import { mapGetters } from 'vuex'
import { getDuration, getConciseDurationString } from '@stellacontrol/utilities'
import { Animation, Color } from '@stellacontrol/client-utilities'
import { DeviceConnectionStatus } from '@stellacontrol/model'
import Widget from './widget'

const ICON_BUSY = 'change_circle'

export default {
  mixins: [
    Widget
  ],

  props: {
    // Indicates whether live status is allowed at all on this device
    isLiveStatusAllowed: {
      type: Boolean
    },
    // Indicates whether fast-sampling is allowed
    canFastSample: {
      type: Boolean
    },
    // Indicates whether the device is fast-sampling right now
    isFastSampling: {
      type: Boolean
    },
    // Indicates the remaining time of fast-sampling
    remainingFastSamplingTime: {
    },
    // Fast sampling settings for the current view
    fastSamplingSettings: {
    },
    // Name of the status watch process
    name: {
      type: String
    },
    // Icon size
    iconSize: {
      type: String
    }
  },

  data () {
    return {
      // Icon element representing device status
      iconElement: null,
      // The number of received statuses
      statusCount: 0
    }
  },

  computed: {
    ...mapGetters([
      'getStatusListener'
    ]),

    // Device status listener
    listener () {
      return this.getStatusListener(this.name)
    },

    // Widget title
    title () {
      return 'Device Status'
    },

    // Device connection status
    connectionStatus () {
      const { status } = this
      return (status ? status.connection.status : '') || DeviceConnectionStatus.NeverConnected
    },

    /**
     * Returns true if device is online in heartbeat mode
     */
    isDeviceCommunicating () {
      const { status } = this
      return status?.timings?.isHeartbeat || status?.timings?.isRealTime
    },

    // Indicates whether the device has ever connected to report its status
    hasConnected () {
      const { connectionStatus } = this
      return connectionStatus !== DeviceConnectionStatus.NeverConnected && connectionStatus !== DeviceConnectionStatus.Unknown
    },

    // Indicates a device in ship mode
    isShip () {
      return this.status?.identity?.isShip
    },

    // Indicates that listening to status updates now
    isListening () {
      return this.listener?.isListening
    },

    // Indicates that listening is now suspended
    isSuspended () {
      const { listener } = this
      return listener?.isSuspended
    },

    // Indicates that we're receiving real-time data
    isRealTime () {
      const { listener } = this
      return listener?.isListening
    },

    // Indicates that we're in heartbeat mode
    isHeartbeat () {
      return this.status?.connection?.isHeartbeat
    },

    // Indicates device hasn't reported in a long time
    isLost () {
      return this.status?.connection?.isLost
    },

    // Indicates that we're retrieving the device status now
    isRetrieving () {
      return this.isLiveStatusAllowed && !this.status
    },

    // Age of the most recent status
    statusAge () {
      return this.ticks ? this.status?.timings?.statusAge : ''
    },

    // Label representing the status age
    statusAgeLabel () {
      const { hasConnected, isFastSampling, isLost, statusAge, ticks } = this

      if (hasConnected && !isLost && ticks > 0 && statusAge != null) {
        const { seconds, minutes, hours, days } = getDuration(statusAge)
        if (days > 2) return `${days}d`
        if (hours > 0) return `${hours}h`
        if (minutes > 0) return `${minutes}m`
        // When in fast sampling, don't show when less than 1s, to reduce blinking
        if (seconds > 0) return (isFastSampling && seconds < 2) ? '' : `${seconds}s`
      }

      return ''
    },

    // Detailed status description, such as message age
    statusDescription () {
      const { isLiveStatusAllowed, ticks, status, isFastSampling } = this
      if (isLiveStatusAllowed && ticks >= 0) {
        if (status) {
          const {
            timings: { isRealTime, isLost, statusAge, isHeartbeat },
            connection: { isUnknown }
          } = status

          if (isUnknown) {
            return 'Retrieving status ...'

          } else if (isRealTime && statusAge < 10) {
            return isFastSampling ? 'Device is live' : 'Just connected'

          } else if ((isRealTime || isHeartbeat)) {
            if (statusAge >= 10 && statusAge <= 600) return 'Connected recently'
            else return `Connected ${getConciseDurationString(statusAge)} ago`

          } else if (isLost) {
            return `No status since ${getConciseDurationString(statusAge)}`

          } else {
            return 'Status unknown'
          }
        }

        return 'Retrieving status ...'
      }

      return 'Not available'
    },

    // Label to show on the fast-sampling button
    fastSamplingLabel () {
      return this.isFastSampling
        ? this.remainingFastSamplingTime
        : this.fastSamplingSettings.fastSamplingDuration
    },

    // Icon representing the current status
    icon () {
      const { isLiveStatusAllowed, status } = this
      if (isLiveStatusAllowed) {
        const connectedIcon = 'offline_bolt'
        const disconnectedIcon = 'stop_circle'
        return status
          ? {
            [DeviceConnectionStatus.Online]: connectedIcon,
            [DeviceConnectionStatus.Heartbeat]: connectedIcon,
            [DeviceConnectionStatus.Lost]: disconnectedIcon,
            [DeviceConnectionStatus.NeverConnected]: 'do_not_disturb',
            [DeviceConnectionStatus.Unknown]: ICON_BUSY,
            [DeviceConnectionStatus.Error]: 'error',
          }[status.connection.status]
          : ICON_BUSY
      } else {
        return 'info'
      }
    },

    // CSS style of the icon
    iconStyle () {
      const { status, isLiveStatusAllowed, isRetrieving, isRealTime, isHeartbeat, ticks } = this
      if (ticks < 0) return

      if (isRetrieving) {
        return {
          'color': '#f0f0f0',
          'background-color': '#f0f0f0'
        }
      }

      const textColor = (isRealTime || isHeartbeat)
        ? '#ffffff'
        : '#080808'

      const bgColor = (status && isLiveStatusAllowed)
        ? status.connection.color || 'grey-2'
        : 'grey-7'

      return {
        'color': textColor,
        'background-color': Color.getColor(bgColor)
      }
    },

    // Text items to show in the widget
    items () {
      const { ticks } = this
      if (ticks >= 0) {
        const items = [
          { text: this.title, class: { label: true } },
          { text: this.statusDescription, class: { small: true } }
        ]
        return items
      }
    }
  },

  watch: {
    status (value) {
      // Blink status icon gently on receiving new status, just skip the first one
      if (value != null) {
        this.statusCount++
        if (this.statusCount > 1) {
          const { iconElement: element } = this
          if (element) {
            Animation.run({ element, cssClass: 'pulse', duration: 500 })
          }
        }
      }
    }
  },

  emits: [
    'start-fast-sampling'
  ],

  methods: {
    // Triggered when widget has initialized
    initialized ({ iconElement } = {}) {
      this.iconElement = iconElement
    },

    // Initiates fast sampling
    startFastSampling () {
      const { device } = this
      this.$emit('start-fast-sampling', { device })
    }
  }
}
</script>

<template>
  <sc-widget-text :items="items" @initialized="initialized">
    <template #icon>
      <div class="icon" :style="iconStyle">
        {{ statusAgeLabel }}
        <sc-tooltip v-if="statusAge > 0">
          {{ duration(statusAge) }} ago
        </sc-tooltip>
      </div>
    </template>
    <template #toolbar>
      <div class="toolbar">
        <q-btn v-if="canFastSample" class="button-live" unelevated dense no-caps icon="play_arrow"
          :ripple="false" :label="`Live ${fastSamplingLabel}s`" :disable="isFastSampling"
          :color="isFastSampling ? 'green-7' : undefined" @click="startFastSampling()">
          <sc-tooltip nowrap v-if="isFastSampling">
            Fast sampling is ON, remaining time {{ remainingFastSamplingTime }}s
          </sc-tooltip>
          <sc-tooltip nowrap v-else>
            Turn on fast sampling for {{ fastSamplingSettings.fastSamplingDuration }}s
          </sc-tooltip>
        </q-btn>
      </div>
    </template>
  </sc-widget-text>
</template>

<style lang="scss" scoped>
.icon {
  margin-top: 4px;
  margin-left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
}

.toolbar {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-bottom: 8px;

  .button-live {
    width: 110px;
  }
}
</style>
