import MEGA from './mega.json'
import { isNodeJS } from '@stellacontrol/utilities'
import { versionMatches, megaVersionMatches, DeviceBandIdentifiers, hasLegacyFirmware } from '@stellacontrol/model'
import { MegaParameter } from './mega-parameter'
import { MegaParameterStatus } from './mega-parameter-status'

/**
 * Returns the entire MEGA definition
 * @param {Device} device Optional device in whose context the parameters should be resolved.
 * If specified, only mega parameters applicable to this device are returned.
 * @returns {Array[MegaParameter]}
 */
export function getMegaParameters (device) {
  return Object
    .keys(MEGA)
    .filter(name => !(name.startsWith('__') || name.startsWith('#')))
    .map(name => getMegaParameter(name, device))
    .filter(parameter => parameter && parameter.type)
}

/**
 * Returns the entire MEGA definition, with band parameters expanded to specific per-band parameters.
 * @param {Device} device Optional device in whose context the parameters should be resolved.
 * If specified, only mega parameters applicable to this device are returned.
 * @returns {Array[MegaParameter]}
 */
export function getExpandedMegaParameters (device) {
  const parameters = getMegaParameters(device)
  return parameters.flatMap(parameter => {
    if (parameter.isBandParameter) {
      return DeviceBandIdentifiers.map(band => new MegaParameter({
        ...parameter,
        name: `${parameter.name}_${band}`,
        band
      }))
    } else {
      return [parameter]
    }
  })
}

/**
 * Returns definition of MEGA parameter with a specified name
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * If specified, MEGA parameter is checked against firmware and mega versions
 * and marked with proper MegaParameterStatus value: Present, Obsolete or Absent.
 * Values and labels can also vary per firmware and mega version.
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @returns {MegaParameter}
 */
export function getMegaParameter (name, device, status) {
  if (!name) return
  const bandParameter = isBandParameter(name)
  const band = bandParameter ? name.replace(/.*_/, '') : undefined
  const key = bandParameter ? name.replace(/_\d{2}$/, '') : name
  const parameter = MEGA[key]

  if (parameter && parameter.type) {
    const isBandParameter = bandParameter || parameter.isBandParameter
    const result = {
      ...parameter,
      key,
      name,
      // Indicate whether it's a band parameter, expanded to actual per-band parameters
      isBandParameter,
      band,
      // Assume the parameter is present, unless specified otherwise
      status: parameter.status || MegaParameterStatus.Present
    }

    delete result.__info

    // Apply parameter overrides, depending on device versions and types
    if (device && parameter.when) {
      const overrides = parameter.when
        .map(rule => getApplicableOverrides(rule, device, status))
        .filter(override => override)
      for (const override of overrides) {
        Object.assign(result, override)
      }
    }

    return new MegaParameter(result)

  } else {
    return new MegaParameter({
      name,
      status: MegaParameterStatus.Unknown,
      notApplicable: true
    })
  }
}

/**
 * Returns true if specified band parameter is defined
 * @param {String} name Name of the parameter
 * @returns {Boolean}
 */
export function isKnownMegaParameter (name) {
  const parameter = MEGA[name]
  return parameter && parameter.status !== MegaParameterStatus.Unknown
}

/**
 * Returns true if name specifies a band parameter, with band identifier suffix
 * @param {String} name Name of the parameter
 * @returns {Boolean}
 */
export function isBandParameter (name) {
  return Boolean(name && name.match(/_\d{2}$/))
}

/**
 * Returns definitions of band-related MEGA parameter with a specified name
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * @returns {Array[MegaParameter]}
 */
export function expandMegaParameter (name, device) {
  const parameter = getMegaParameter(name, device)
  if (parameter.isBandParameter) {
    return DeviceBandIdentifiers.map(band => new MegaParameter({
      ...parameter,
      name: `${parameter.name}_${band}`,
      band
    }))

  } else {
    return [parameter]
  }
}

/**
 * Returns MEGA parameter representing the specified band parameter.
 * Main parameter name is still available under `key` property.
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @returns {MegaParameter}
 */
export function getBandMegaParameter (name, device, status) {
  if (isBandParameter(name)) {
    const key = name.replace(/_\d{2}$/, '')
    const band = name.replace(/.*_/, '')
    const parameter = getMegaParameter(key, device, status)
    parameter.key = key
    parameter.name = name
    parameter.band = band
    return parameter
  }
}

/**
 * Returns all MEGA parameters which are stored in history database
 * @returns {Array[MegaParameter]}
 */
export function getStoredMegaParameters () {
  return getExpandedMegaParameters().filter(p => p.store)
}

/**
 * Returns MEGA parameter applicability rules
 * @param {String} name Name of the parameter
 * @returns {Array}
 */
export function getMegaParameterRules (name) {
  const parameter = isBandParameter(name)
    ? MEGA[name.replace(/_\d{2}$/, '')]
    : MEGA[name]

  if (parameter) {
    return parameter.when || {}
  } else {
    return {}
  }
}

/**
 * Returns permissions required for accessing the specified MEGA parameter
 * @param {String} name Name of the parameter
 * @returns {Array[String]}
 */
export function getMegaParameterPermissions (name, device) {
  const parameter = getMegaParameter(name, device)
  return (parameter && parameter.status !== MegaParameterStatus.Unknown)
    ? (parameter.permissions || [])
    : []
}

/**
 * Returns human-friendly label of MEGA parameter with a specified name.
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * @param {String} defaultLabel Default label to return, when parameter not found or label not specified
 * If specified, MEGA parameter is checked against firmware and mega versions
 * and marked with proper MegaParameterStatus value: Present, Obsolete or Absent.
 * Values and labels can also vary per firmware and mega version.
 * @returns {String}
 */
export function getMegaParameterLabel (name, device, defaultLabel = '') {
  const parameter = getMegaParameter(name, device)
  return (parameter && parameter.status !== MegaParameterStatus.Unknown)
    ? (parameter.label || defaultLabel)
    : defaultLabel
}

/**
 * Returns human-friendly description of MEGA parameter with a specified name
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * @param {String} defaultDescription Default description to return, when parameter not found or description not specified
 * If specified, MEGA parameter is checked against firmware and mega versions
 * and marked with proper MegaParameterStatus value: Present, Obsolete or Absent.
 * Values and labels can also vary per firmware and mega version.
 * @returns {String}
 */
export function getMegaParameterDescription (name, device, defaultDescription = '') {
  const parameter = getMegaParameter(name, device)
  return (parameter && parameter.status !== MegaParameterStatus.Unknown)
    ? (parameter.description || defaultDescription)
    : defaultDescription
}

/**
 * Returns true if MEGA parameter with a specified name
 * is applicable to the specified device
 * under its current firmware and software versions
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @returns {Boolean}
 */
export function isMegaParameterApplicable (name, device, status) {
  const parameter = getMegaParameter(name, device, status)
  return parameter?.status &&
    parameter.status !== MegaParameterStatus.Absent &&
    parameter.status !== MegaParameterStatus.Unknown
}

/**
 * Returns true if the parameter has reliable value.
 * Some parameters can only be trusted upon
 * on certain models, firmware versions etc.,
 * i.e. `sliding` which is not reliable on legacy firmwares
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @returns {Boolean}
 */
export function isMegaParameterReliable (name, device, status) {
  const parameter = getMegaParameter(name, device, status)
  return (parameter && parameter.isReliable !== false)
}

/**
 * Returns true if the parameter can be used to determine alerts.
 * Some alerts aren't applicable on certain versions or models.
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @returns {Boolean}
 */
export function isMegaParameterMonitored (name, device, status) {
  if (isMegaParameterApplicable(name, device, status) && isMegaParameterReliable(name, device, status)) {
    const parameter = getMegaParameter(name, device, status)
    return (parameter && parameter.isMonitored !== false)
  }
}

/**
 * Returns definition of MEGA parameter with a specified name,
 * if parameter is applicable to the specified device
 * under its current firmware and software versions
 * @param {String} name Name of the parameter
 * @param {Device} device Device in whose context the parameter is retrieved.
 * If specified, MEGA parameter is checked against firmware and mega versions
 * If the determined parameter status equals MegaParameterStatus.Absent,
 * parameter is deemed as not applicable and not returned.
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @returns {MegaParameter}
 */
export function getMegaParameterIfApplicable (name, device, status) {
  const parameter = getMegaParameter(name, device, status)
  if (parameter && parameter.status !== MegaParameterStatus.Absent) {
    return parameter
  }
}

/**
 * Returns definition of device command with a specified name
 * @param {String} name Name of the command
 * @param {Device} device Device in whose context the command is to be executed.
 * If specified, command is checked against firmware and mega versions
 * and marked with proper status value: Present, Obsolete or Absent.
 * Values and labels can also vary per firmware and mega version.
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @param {MegaParameter}
 */
export function getDeviceCommand (name, device, status) {
  if (name != null) {
    const commandName = `#${name}`
    if (isKnownMegaParameter(commandName)) {
      return getMegaParameter(commandName, device, status)
    }
  }
}

/**
 * Returns true if device command with a specified name
 * is applicable to the specified device
 * under its current firmware and software versions
 * @param {String} name Name of the command
 * @param {Device} device Device in whose context the command is to be executed.
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @returns {Boolean}
 */
export function isDeviceCommandApplicable (name, device, status) {
  const command = getDeviceCommand(name, device, status)
  return command
    ? command.status !== MegaParameterStatus.Absent && command.status !== MegaParameterStatus.Unknown
    : true
}

/**
 * Returns rules of applicability of the specified device command
 * @param {String} name Name of the command
 * @returns {Array}
 */
export function getDeviceCommandRules (name) {
  return getMegaParameterRules(`#${name}`)
}

/**
 * Applies device rules to the specified data object.
 * Properties can be changed depending on device model, firmware and other attributes.
 * @param {Device} device Device to apply rules to
 * @param {Object} data Data to apply the rules to
 * @param {Array[Object]} rules Rules to apply
 * @param {DeviceStatus} status Last known status of the device
 * @returns {Object} Data with rules applies
 */
export function applyDeviceRules (device, data, rules, status) {
  const result = { ...(data || {}) }

  if (rules) {
    const overrides = rules
      .map(rule => getApplicableOverrides(rule, device, status))
      .filter(override => override)
    for (const override of overrides) {
      Object.assign(result, override)
    }
  }

  return result
}

/**
 * Parses a condition expression, which can be either just value or value preceded by operator, i.e.
 *
 *    mega-v6.4     Device value matches exactly the specified one
 *    != combiner   Device value is different than the specified one
 *    > 6.6.5       Device value is greater than the specified one
 *    >= 6.6.5      Device value is greater or equal to the specified one
 *    < 6.6.5       Device value is smaller than the specified one
 *    <= 6.6.5      Device value is smaller or equal to the specified one
 *    ~ combiner    Device value contains the specified string
 *    !~ combiner   Device value does not contain the specified string
 *    legacy        Device firmware is a legacy firmware, which is no longer developed except critical patches
 *
 * Multiple conditions can be combined using `and` and `or` operators.
 *
 * Returns a tuple of value and operator, which defaults to = unless specified otherwise
 */
function parseConditionExpression (value = '') {
  if (typeof value == 'boolean') {
    return {
      conditions: [
        {
          operator: '=',
          value
        }
      ]
    }
  }

  if (typeof value == 'number') {
    return {
      conditions: [
        {
          operator: '=',
          value
        }
      ]
    }
  }

  const join = value.includes(' && ') ? '&&' : (value.includes(' || ') ? '||' : '')
  const conditions = join ? value.split(` ${join} `) : [value]

  return {
    join,
    conditions: conditions.map(condition => {
      const parts = condition.split(' ')

      if (parts.length === 2) {
        return {
          operator: parts[0],
          value: parts[1]
        }
      }

      if (condition.startsWith('/') && condition.endsWith('/')) {
        return {
          operator: 'regex',
          value: condition.substring(1, condition.length - 1)
        }
      }

      return {
        operator: '=',
        value: condition
      }
    })
  }
}

/**
 * Checks if the specified value matches the given condition
 * @param {String} value Value to check against the condition
 * @param {String} expression Condition expression
 * @returns {Boolean}
 */
export function valueMatches (value, expression) {
  if (value == null || expression == null) return false

  const { conditions, join } = parseConditionExpression(expression)
  let matches = true

  for (const { value: conditionValue, operator } of conditions) {
    switch (operator) {
      case '=':
        matches = value == conditionValue
        break
      case '!=':
        matches = value != conditionValue
        break
      case '~':
        matches = (value || '').includes(conditionValue)
        break
      case '!~':
        matches = !(value || '').includes(conditionValue)
        break
      case 'regex':
        matches = new RegExp(conditionValue).test(value)
        break
      case '>':
        matches = value > conditionValue
        break
      case '>=':
        matches = value >= conditionValue
        break
      case '<':
        matches = value < conditionValue
        break
      case '<=':
        matches = value <= conditionValue
        break
    }

    if (join === '&&' && !matches) {
      break
    }

    if (join === '||' && matches) {
      break
    }
  }

  return matches
}

/**
 * Checks if the specified device feature is enabled or disabled
 * @param {Dictionary<String, Boolean>} features Device features
 * @param {String} expression Device features expression, for example
 *    Ethernet && !Portsense
 *    GlobalRfOnOff || RfBypassMode || Gps
 * @returns {Boolean}
 */
export function deviceFeatureMatches (features, expression) {
  if (!(features && expression)) return false

  const { conditions, join } = parseConditionExpression(expression)
  let matches = true

  for (const { value } of conditions) {
    const negate = (value[0] === '!') ? true : false
    const feature = (negate ? value.substring(1) : value).trim()
    if (negate) {
      matches = features[feature] === false
    } else {
      matches = features[feature] === true
    }

    if (join === '&&' && !matches) {
      break
    }

    if (join === '||' && matches) {
      break
    }
  }

  return matches
}

/**
 * Checks if the specified device reports the specified feature at all
 * @param {Dictionary<String, Boolean>} features Device features
 * @param {String} expression Device feature expression to check
 *    Ethernet
 *    !Portsense
 *    GlobalRfOnOff || RfBypassMode || Gps
 * @returns {Boolean}
 */
export function deviceReportsFeature (features, expression) {
  if (!(features && expression)) return false

  const { conditions, join } = parseConditionExpression(expression)
  let matches = true

  for (const { value } of conditions) {
    const negate = (value[0] === '!') ? true : false
    const feature = (negate ? value.substring(1) : value).trim()
    if (negate) {
      matches = features[feature] == null
    } else {
      matches = features[feature] != null
    }

    if (join === '&&' && !matches) {
      break
    }

    if (join === '||' && matches) {
      break
    }
  }

  return matches
}

/**
 * Checks if current environment matches the given condition
 * @param {String} expression Condition expression
 * @returns {Boolean}
 */
export function environmentMatches (expression) {
  const environment = isNodeJS
    ? global?.StellaControl?.environment
    : window?.StellaControl?.environment

  if (environment == null || expression == null) return false

  return valueMatches(environment, expression)
}

/**
 * Checks if the specified version string matches the given condition
 * @param {String} version Version string
 * @param {String} expression Condition expression
 * @param {Boolean} isMegaVersion Indicates that we're checking MEGA version which uses different comparison function
 * @returns {Boolean}
 */
export function deviceVersionMatches (version, expression, isMegaVersion) {
  if (version == null || expression == null) return false

  const { conditions, join } = parseConditionExpression(expression)
  let matches = true

  for (const { value, operator } of conditions) {
    if (isMegaVersion) {
      matches = megaVersionMatches(version, value, operator)
    } else {
      matches = versionMatches(version, value, operator)
    }

    if (join === '&&' && !matches) {
      break
    }

    if (join === '||' && matches) {
      break
    }
  }

  return matches
}

/**
 * Checks if specified overrides apply to the parameters of a given device,
 * returns the list of applicable overrides
 * @param {Object} override Parameter override to check, from `when` list
 * @param {Device} device Device to check against
 * @param {DeviceStatus} status Last known status of the device
 * @returns {Array} List of applicable value overrides
 */
function getApplicableOverrides (override, device, status) {
  if (device) {
    let applies = true
    const { firmware, hardware, eeprom, mega, environment, then } = override

    // Check overrides applicable to environment
    if (applies && environment) {
      applies = applies && environmentMatches(environment)
    }

    // Check overrides applicable to device versions
    if (applies && firmware) {
      applies = applies && deviceVersionMatches(device.firmwareVersionLong || device.firmwareVersion, firmware)
    }
    if (applies && hardware) {
      applies = applies && deviceVersionMatches(device.hardwareVersion, hardware)
    }
    if (applies && eeprom) {
      applies = applies && deviceVersionMatches(device.eepromVersion, eeprom)
    }
    if (applies && mega) {
      applies = applies && deviceVersionMatches(device.megaVersion, mega, true)
    }

    // Check overrides applicable to other device properties
    if (applies) {
      for (const field of ['type', 'model', 'resellerModel', 'family', 'status', 'features', 'legacy', 'serial']) {
        if (override[field] != null) {
          // Device status variables
          if (field === 'status') {
            for (const [key, expression] of Object.entries(override[field])) {
              applies = applies && valueMatches(status?.mega[key], expression)
              if (!applies) break
            }
          }
          // Device feature flags
          if (field === 'features') {
            applies = applies && deviceFeatureMatches(status?.features, override[field])
            if (!applies) break
          } else if (field === 'featureReported') {
            applies = applies && deviceReportsFeature(status?.features, override[field])
            if (!applies) break
          } else if (field === 'featureNotReported') {
            applies = applies && !deviceReportsFeature(status?.features, override[field])
            if (!applies) break
          } else if (field === 'legacy') {
            // Legacy firmware check
            applies = hasLegacyFirmware(device) === override[field]
          } else if (field === 'serial') {
            // Serial number check
            applies = applies && valueMatches(device.serialNumber, override[field])
          } else {
            // Other device properties
            applies = applies && valueMatches(device[field], override[field])
          }

          if (!applies) break
        }
      }
    }

    // Return if override applies
    if (applies) {
      const overrides = { ...then }
      delete overrides.when
      return overrides
    }
  }
}

/**
 * Indicates whether the device can send us scans
 * @param {Device} device Device to check
 * @param {DeviceStatus} status Device status
 * @returns {Boolean}
 */
export function canDeviceSendScans (device, status) {
  return isMegaParameterApplicable('can_send_scans', device, status)
}

/*
const items = [
  { value: '7.1.1', expression: '7.1.1', isVersion: true, isMegaVersion: false },
  { value: '7.1.1', expression: '7.1.2', isVersion: true, isMegaVersion: false },
  { value: '7.1.1', expression: '!= 7.1.1', isVersion: true, isMegaVersion: false },
  { value: 'mega-v7.1', expression: 'mega-v7.1', isVersion: true, isMegaVersion: true },
  { value: 'mega-v7.1', expression: 'mega-v7.2', isVersion: true, isMegaVersion: true },
  { value: 'mega-v7.1', expression: '!= mega-v7.1', isVersion: true, isMegaVersion: true },
  { value: 'i5_V4a', expression: 'i5_V4' },
  { value: 'i5_V4a', expression: 'i5_V5' },
  { value: 'i5_V4a', expression: '!= i5_V5' },
  { value: 'i5_V4a', expression: '~ V5' },
  { value: 'i5_V4a', expression: '~ V4' },
  { value: 'i5_V4a', expression: '!~ V4' },
  { value: 'i5_V4', expression: 'i5_V5 || i5_V4' },
  { value: 'i5_V4', expression: '~ i5 && ~ V4' },
  { value: 'i5_V4', expression: '~ i5 && ~ V5' },
  { value: 'i5_V4', expression: '~ i5 && !~ V5' },
  { value: 'i5_V4', expression: '!~ i5 && !~ V4' },
]
for (const { value, expression, isVersion, isMegaVersion } of items) {
  console.log(
    `"${value}" - "${expression}"`,
    isVersion ? deviceVersionMatches(value, expression, isMegaVersion) : valueMatches(value, expression)
  )
}
*/