import { stringCompare } from '@stellacontrol/utilities'

export const actions = {
  /**
   * Loads the data required by the building dashboard
   * @param {String} id Place identifier
   * @param {String} organizationId Organization identifier
   * @returns {{place, organization}}
   * @description Organization identifier is required in case {@link id}
   * does not represent an actual place but a special place such as stock or shared devices.
   * In such case, {@link Place} is created dynamically, and every place requires {@link organizationId}
   */
  async initializeBuildingDashboard ({ dispatch, commit, getters }, { id, organizationId }) {
    // Fetch the organization and place
    const organization = getters.organizations.find(o => o.id === organizationId)
    const place = await dispatch('getPlace', { id, organizationId })

    if (organization && place) {
      // Fetch security guardian for the viewed organization
      const organizationGuardian = await dispatch('getGuardian', { principal: organization, force: true })
      const rootOrgId = getters.organizations.find(organization => !organization.parentOrganizationId)?.id

      // Fetch the devices to show in the dashboard
      // Remove decomissioned devices
      let devices = getters.devices.filter(d => !d.isDecommissioned)
      if (place.isSharedPlace) {
        // Devices shared with organization
        devices = devices.filter(device => device.isShared && organizationId === (device.ownerId || rootOrgId))
      } else if (place.isNoPlace) {
        // Stock devices, not assigned to any place
        devices = devices.filter(device => !device.placeId && !device.isShared && organizationId === (device.ownerId || rootOrgId))
      } else {
        // Devices assigned to a specific place
        devices = devices.filter(device => device.placeId === place.id && !device.isShared)
      }

      // Sort by serial
      devices.sort((a, b) => stringCompare(a.serialNumber, b.serialNumber, false))

      commit('initializeBuildingDashboard', { organization, organizationGuardian, place, devices })
    }

    return { place, organization }
  },

  /**
   * Selects or deselect a device for actions, commands etc.
   * @param {Device } device Device to select or deselect
   * @param {Boolean} isSelected Selection status
   */
  buildingSelectDevice ({ commit }, { device, isSelected } = {}) {
    commit('buildingSelectDevice', { device, isSelected })
  },

  /**
 * Starts fast-sampling devices in the building
 * @param {String} name View or component which has triggered the fast sampling
 * @param {Array[Device]} devices Devices to fast-sample
 * @param {Number} fastSamplingDuration Duration of the fast-sampling
 */
  async buildingStartFastSampling ({ dispatch }, { name, devices, fastSamplingDuration }) {
    await dispatch('startFastSampling', { name, devices, fastSamplingDuration })
    await dispatch('fastSamplingCountdown', { name, devices, fastSamplingDuration })
  }
}
