import { parseEnum, safeParseInt } from '@stellacontrol/utilities'
import { PlanItemType } from './plan-item'
import { PlanRectangle } from './plan-rectangle'
import { PlanBackgroundStyle, PlanLineStyle } from '../styles'

/**
 * Antenna types
 */
export const AntennaType = {
  Yagi: 'yagi',
  TwinYagi: 'twin-yagi',
  IndoorOmni: 'dome',
  OutdoorOmni: 'dome-outdoor',
  WallPanel: 'panel',
  CeilingPanel: 'panel-ceiling',
  Laser: 'laser'
}

/**
 * Antenna names
 */
export const AntennaName = {
  [AntennaType.Yagi]: 'Yagi',
  [AntennaType.TwinYagi]: 'Twin Yagi',
  [AntennaType.IndoorOmni]: 'Indoor Omni',
  [AntennaType.OutdoorOmni]: 'Outdoor Omni',
  [AntennaType.WallPanel]: 'Wall Panel',
  [AntennaType.CeilingPanel]: 'Ceiling Panel',
  [AntennaType.Laser]: 'Laser'
}

/**
 * Antenna parameters per type
 */
export const AntennaParameters = {
  [AntennaType.Yagi]: { description: 'Yagi', radius: 18, gain: 9, beam: 50, blobWidth: 900, blobHeight: 450 },
  [AntennaType.TwinYagi]: { description: 'Twin Yagi', radius: 18, gain: 9, beam: 50, blobWidth: 1400, blobHeight: 450 },
  [AntennaType.IndoorOmni]: { description: 'Indoor Omni', radius: 18, gain: 2, beam: 360 },
  [AntennaType.OutdoorOmni]: { description: 'Outdoor Omni', radius: 18, gain: 2, beam: 360 },
  [AntennaType.WallPanel]: { description: 'Wall Panel', radius: 18, gain: 5, beam: 90, blobWidth: 850, blobHeight: 600 },
  [AntennaType.CeilingPanel]: { description: 'Ceiling Panel', radius: 24, gain: 6, beam: 360 },
  [AntennaType.Laser]: { description: 'Laser', radius: 18, gain: 20, beam: 5 }
}

/**
 * Antenna defaults
 */
const AntennaDefaults = {
  [AntennaType.Yagi]: {
    width: 90,
    height: 40,
    radius: undefined,
    backgroundStyle: new PlanBackgroundStyle({ color: '#72ff60' }),
  },

  [AntennaType.TwinYagi]: {
    width: 230,
    height: 40,
    radius: undefined,
    backgroundStyle: new PlanBackgroundStyle({ color: '#72ff60' }),
  },

  [AntennaType.IndoorOmni]: {
    radius: 30,
    backgroundStyle: new PlanBackgroundStyle({ color: '#72ff60' }),
    lineStyle: new PlanLineStyle({ color: '#000000' }),
  },

  [AntennaType.OutdoorOmni]: {
    width: 20,
    height: 70,
    backgroundStyle: new PlanBackgroundStyle({ color: '#72ff60' }),
  },

  [AntennaType.WallPanel]: {
    width: 50,
    height: 70,
    backgroundStyle: new PlanBackgroundStyle({ color: '#72ff60' }),
  },

  [AntennaType.CeilingPanel]: {
    width: 50,
    height: 70,
    backgroundStyle: new PlanBackgroundStyle({ color: '#72ff60' }),
  },

  [AntennaType.Laser]: {
    width: 120,
    height: 180,
    radius: 100,
    backgroundStyle: new PlanBackgroundStyle({ color: '#72ff60' }),
  }
}

/**
 * Antenna
 */
export class PlanAntenna extends PlanRectangle {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Creates an antenna
   * @param {Object} data Item data
   * @returns {PlanAntenna}
   */
  static createAntenna (data = {}) {
    return new PlanAntenna({
      ...data,
      antenna: {
        ...(data.antenna || {}),
        type: data.antennaType || AntennaType.IndoorOmni
      }
    })
  }

  /**
   * Object defaults
   */
  get defaults () {
    const { antenna } = this
    // General defaults
    const defaults = {
      ...super.defaults,
      radiationStrength: 100,
      lineStyle: new PlanLineStyle({ width: 2 })
    }

    // Antenna-specific defaults
    const antennaDefaults = AntennaDefaults[antenna?.type]
    if (antennaDefaults) {
      Object.assign(defaults, antennaDefaults)
    }

    return defaults
  }

  normalize () {
    this.antenna = {
      type: parseEnum(AntennaType, this.antenna?.type, AntennaType.Yagi)
    }
    super.normalize()
    const { defaults } = this
    this.radiationStrength = safeParseInt(this.radiationStrength, defaults.radiationStrength)
    this.clearPorts()
    this.addPort()
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Remove runtime properties
    delete result.ports

    // Remove fixed styles
    delete result.backgroundStyle
    delete result.lineStyle
    if (!this.radius) delete result.radius

    return result
  }

  /**
   * Human-friendly representation of the item, useful for the UI
   * @param {Boolean} details If true, detailed description is returned
   * @returns {String}
  */
  // eslint-disable-next-line no-unused-vars
  toHumanString (details) {
    let label = `${AntennaName[this.antennaType]} antenna`
    return label
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Antenna
  }

  /**
  * Antenna details
  * @type {Object}
  */
  antenna

  /**
   * Antenna radiation radius
   * @type {Number}
   */
  radiationStrength

  /**
   * Checks whether the antenna has a default radiation strength
   * @type {Boolean}
   */
  get hasDefaultStrength () {
    return this.radiationStrength === this.defaults.radiationStrength
  }

  /**
   * Antenna type
   * @type {AntennaType}
   */
  get antennaType () {
    return this.antenna?.type
  }
  set antennaType (value) {
    if (!this.antenna) {
      this.antenna = {}
    }
    if (this.antenna.type !== value) {
      this.antenna.type = value
      this.refresh()
    }
  }

  /**
   * Automatically generated label
   * @type {String}
   */
  get autoLabel () {
    return `${AntennaName[this.antennaType]} Antenna`
  }

  /**
   * Antenna parameters
   * @type {Object}
   */
  get parameters () {
    return AntennaParameters[this.antennaType]
  }

  /**
   * Indicates that antenna can only be used as external.
   * Notice that some antennae can be used in both environments!
   * @type {Boolean}
   */
  get isExternalAntenna () {
    return this.isAntennaType(AntennaType.OutdoorOmni)
  }

  /**
   * Indicates whether antenna is Yagi
   * @type {Boolean}
   */
  get isYagi () {
    return this.antenna?.type === AntennaType.Yagi
  }

  /**
   * Indicates whether antenna is Twin Yagi
   * @type {Boolean}
   */
  get isTwinYagi () {
    return this.antenna?.type === AntennaType.TwinYagi
  }

  /**
   * Indicates whether antenna is Indoor Omni
   * @type {Boolean}
   */
  get isIndoorOmni () {
    return this.antenna?.type === AntennaType.IndoorOmni
  }

  /**
   * Indicates whether antenna is Outdoor Omni
   * @type {Boolean}
   */
  get isOutdoorOmni () {
    return this.antenna?.type === AntennaType.OutdoorOmni
  }

  /**
   * Indicates whether antenna is Panel
   * @type {Boolean}
   */
  get isPanel () {
    return this.antenna?.type === AntennaType.WallPanel || this.antenna?.type === AntennaType.CeilingPanel
  }

  /**
   * Indicates whether antenna is Wall Panel
   * @type {Boolean}
   */
  get isWallPanel () {
    return this.antenna?.type === AntennaType.WallPanel
  }

  /**
   * Indicates whether antenna is Ceiling Panel
   * @type {Boolean}
   */
  get isCeilingPanel () {
    return this.antenna?.type === AntennaType.CeilingPanel
  }

  /**
   * Indicates whether antenna is Laser
   * @type {Boolean}
   */
  get isLaser () {
    return this.antenna?.type === AntennaType.Laser
  }

  /**
   * Checks whether the antenna is omni-directional
   * @type {Boolean}
   */
  get isOmnidirectionalAntenna () {
    return this.parameters?.beam === 360
  }

  /**
   * Checks whether item represents a directional antenna
   * @type {Boolean}
   */
  get isDirectionalAntenna () {
    return this.parameters?.beam !== 360
  }

  /**
   * Resizes the circle
   * @param {Number} width New width
   * @param {Number} height New height
   */
  resize (width, height) {
    if (this.canResize) {
      if (this.isIndoorOmni) {
        this.radius = width == null ? this.radius : width
      } else {
        super.resize(width, height)
      }

    }
  }

  /**
   * Resizes the circle by the specified delta
   * @param {Number} width New width
   * @param {Number} height New height
   */
  resizeBy (width, height) {
    if (this.canResize) {
      if (this.isIndoorOmni) {
        if (width != null) {
          this.resize(this.radius + width)
        }
        if (height != null) {
          this.resize(this.radius + height)
        }
      } else {
        super.resizeBy(width, height)
      }
    }
  }

  /**
   * Refreshes the antenna after changing type -
   * creates antenna ports, updates sizes etc.
   */
  refresh () {
    const { antennaType } = this
    const antennaDefaults = AntennaDefaults[antennaType]
    Object.assign(this, antennaDefaults)
  }

  /**
   * Determine signal gain/loss at the item
   * @param {PlanLayout} layout Plan layout
   * @returns {Number}
   */
  getGain (layout) {
    if (layout) {
      return 2
    }
    return 0
  }

  /**
   * Indicates whether item details can be collected and displayed to the user
   * @type {Boolean}
   */
  get showDetails () {
    return true
  }

  /**
   * Returns a list of various details about the item.
   * The entries have the following structure, with `icon` and `order` being optional.
   * Property `category` can be used to group the entries when displaying.
   * `{ category, key, label, description, value, unit, icon, order }`
   * @param {PlanLayout} layout Plan layout
   * @param {PlanHierarchy} hierarchy Equipment hierarchy
   * @returns {Array}
   */
  getDetails (layout, hierarchy) {
    if (layout && hierarchy) {
      const items = super.getDetails(layout, hierarchy)
      const cableStats = layout.getCableStats(this, hierarchy)

      if (cableStats) {
        const { parameters } = this
        const { length, input, output, details } = cableStats
        items.push(
          {
            category: 'Summary',
            key: 'name',
            label: 'Name',
            description: 'Antenna name',
            value: parameters.description
          },
          {
            category: 'Summary',
            key: 'gain',
            label: 'Gain',
            description: 'Antenna gain',
            value: parameters.gain,
            unit: 'dBi'
          },
          {
            category: 'Summary',
            key: 'beam',
            label: 'Beam Pattern',
            description: 'Antenna beam pattern',
            value: parameters.beam === 360 ? 'Omnidirectional' : `${parameters.beam}°`
          },
          input != null
            ? {
              category: 'Signal',
              key: 'input',
              label: 'Incoming Signal',
              description: 'The signal coming into the repeater from external antenna',
              value: input,
              unit: 'dB',
              isAdvancedMode: true
            }
            : null,
          length != null
            ? {
              category: 'Signal',
              key: 'length',
              label: 'Total Cable Length',
              description: 'Total length of cables between the antenna and the repeater',
              value: length || 1,
              unit: 'm',
              isAdvancedMode: true
            }
            : null,
          output != null
            ? {
              category: 'Signal',
              key: 'output',
              label: 'Outgoing Signal',
              description: 'The signal outgoing from the antenna',
              value: output,
              unit: 'dB',
              isAdvancedMode: true
            }
            : null
        )

        if (details != null) {
          items.push(...details.map(d => ({
            category: 'Details',
            key: `${d.type}-${d.subtype}`,
            label: [d.label, d.length > 0 ? `${d.length}m` : ''].join(' '),
            description: d.description,
            value: d.input != null
              ? d.input.toFixed(2)
              : (d.output?.toFixed(2) || ''),
            unit: d.unit || (d.gain != null ? 'dB' : ''),
            isAdvancedMode: true
          })))
        }
      }

      return items.filter(d => d)
    }
  }
}
