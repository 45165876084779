import { Log } from '@stellacontrol/utilities'
import { createState } from './device-transmission.state'

/**
 * Stores job days
 */
export function storeJobDays (state, { jobDays } = {}) {
  state.jobDays = jobDays
}

/**
 * Stores device models where remote firmware updates can be executed
 */
export function storeUpdateableDeviceModels (state, { models = [], nonUpdateable = [] } = {}) {
  state.deviceModels = models
  state.nonUpdateable = nonUpdateable
}

/**
 * Stores firmwares available to current organization
 */
export function storeDeviceFirmwares (state, { firmwares = [] } = {}) {
  state.firmwares = firmwares
}

/**
 * Stores the updated firmware in state
 */
export function storeDeviceFirmware (state, { firmware } = {}) {
  if (firmware && firmware.id) {
    const { firmwares } = state
    const index = firmwares.findIndex(f => f.id === firmware.id)
    if (index > -1) {
      firmwares[index] = firmware
    } else {
      firmwares.push(firmware)
    }
  }
}

/**
 * Removes the specified firmware from state
 */
export function removeDeviceFirmware (state, { firmware } = {}) {
  if (firmware) {
    const { firmwares } = state
    const index = firmwares.findIndex(f => f.id === firmware.id)
    if (index > -1) {
      firmwares.splice(index, 1)
    }
    // Remove any upload jobs related to this firmware
    state.jobs = state.jobs.filter(job => job.payloadId !== firmware.id)
  }
}

/**
 * Grants access to firmware to the specified organizations
 * @param firmware Firmware to grant access to
 * @param organizations Organizations which should have access to the firmware
 */
export function grantAccessToDeviceFirmware (state, { firmware: { id } = {}, organizations = [] }) {
  if (id && organizations.length > 0) {
    const firmware = state.firmwares.find(f => f.id === id)
    if (firmware) {
      for (const organization of organizations) {
        firmware.grantOrganization(organization)
      }
    }
  }
}

/**
 * Revokes access to firmware to the specified organizations
 * @param firmware Firmware to revoke access to
 * @param organizations Organizations whose access to the firmware is to be revoked
 */
export function revokeAccessToDeviceFirmware (state, { firmware: { id } = {}, organizations = [] }) {
  if (id && organizations.length > 0) {
    const firmware = state.firmwares.find(f => f.id === id)
    if (firmware) {
      for (const organization of organizations) {
        firmware.revokeOrganization(organization)
      }
    }
  }
}

/**
 * Stores upload jobs initiated by the current organization
 */
export function storeUploadJobs (state, { jobs = [] } = {}) {
  state.jobs = jobs
}

/**
 * Stores the updated upload job in state
 */
export function storeUploadJob (state, { job } = {}) {
  if (job && job.id) {
    const { jobs } = state
    const index = jobs.findIndex(f => f.id === job.id)
    if (index > -1) {
      jobs[index] = job
    } else {
      jobs.push(job)
    }
  }
}

/**
 * Initializes retrieval of job status
 */
export function getUploadStatus (state) {
  state.lastStatusCheck = new Date()
}

/**
 * Stores the current status of an upload job
 */
export function storeUploadStatus (state, { status: { id, updatedAt, status, progress, retryAttempts, retryInterval } = {} } = {}) {
  if (id && status) {
    const { jobs } = state
    const job = jobs.find(f => f.id === id)
    if (job) {
      job.assign({
        updatedAt,
        status,
        progress,
        retryAttempts,
        retryInterval
      })
    }
  }
}

/**
 * Removes the specified upload job from state
 */
export function removeUploadJob (state, { job } = {}) {
  if (job) {
    const { jobs } = state
    const index = jobs.findIndex(f => f.id === job.id)
    if (index > -1) {
      jobs.splice(index, 1)
    }
  }
}

/**
 * Marks the specified upload job for retry
 */
export function retryUploadJob (state, { job: status } = {}) {
  const job = state.jobs.find(f => f.id === status.id)
  if (job) {
    job.updateStatus(status)
  }
}

/**
 * Starts polling device status periodically
 * @param {String} name Name of a view or process which has initiated the polling
 * @param {Clock} clock Polling clock
 * @param {Number} interval Polling interval in slow mode, in seconds
 */
export function watchUploadStatus ({ polling }, { name, clock, interval }) {
  if (clock) {
    polling[name] = { name, clock }
    Log.debug(`[${name}] Polling device uploads every ${interval}s`)
  }
}

/**
 * Stops polling device status
 * @param {String} name Name of a view or process which has initiated the polling.
 * If not specified, all processes are stopped.
 */
export function unwatchUploadStatus ({ polling }, { name } = {}) {
  const keys = name
    ? [name]
    : Object.keys(polling)

  for (const key of keys) {
    const { clock } = polling[key] || {}
    if (clock) {
      clock?.stop()
      Log.debug(`[${key}] Stopped polling device uploads`)
    }
    delete polling[key]
  }
}

/**
 * Toggles visibility of firmwares inside the specified group
 * @param {Object} group Firmware group to toggle
 */
export function toggleFirmwareGroup (state, { group } = {}) {
  state.expandedFirmwareGroups[group.id] = !state.expandedFirmwareGroups[group.id]
}

/**
 * Groups firmwares list by specified property
 * @param {DeviceFirmwareGroupBy} groupBy Grouping property
 */
export function groupFirmwaresBy (state, { groupBy } = {}) {
  state.groupFirmwaresBy = groupBy
}

/**
 * Resets the state to original shape.
 * @description
 * Presence of this mutation is obligatory on stores,
 * to ensure purging of any user-bound data when
 * user logs in and out and in. Otherwise expect
 * unexpected side effects with data of the previously
 * logged in user appearing at places.
 */
export function reset (state) {
  // Retain polling, as it will be cleared separately
  const { polling } = state
  Object.assign(state, createState({ polling }))
}