import * as PlanActions from './plan-actions'
import { PlanAction } from './plan-action'

/**
 * Returns the class of the specified plan action
 * @param {Object} data Action data
 * @returns {Function} Action constructor
 */
export function getPlanActionClass (data) {
  if (!data) throw new Error('Action data is required')
  const actionClass = Object.values(PlanActions).find(c => c.action === data?.action)
  if (!actionClass) throw new Error(`Unsupported plan action [${data?.action || 'unknown'}]`)
  return actionClass
}

/**
 * Creates a plan action initialized with the specified data
 * @param {Object} data Action data
 * @returns {PlanAction} Plan action
 */
export function createPlanAction (data) {
  if (!data) throw new Error('Action data is required')
  const actionClass = getPlanActionClass(data)
  if (actionClass) {
    const action = new actionClass(data)
    return action
  }
}


/**
 * Executes the specified plan action
 * @param {PlanAction|String} action Action definition or action name
 * @param {PlanRenderer} renderer Plan renderer
 * @param {Array[PlanItem]} items Plan items to execute the action on
 * @param {Number} point Item point to act on
 * @param {Point} position Position at which the action should be executed
 * @param {Object} options Other action options
 * @param {Boolean} noHistory If true, the action will not be added to history - useful if action is executed as result of UNDO
 * @param {Boolean} noChangeNotification If true, change notification is not sent.
 * This is useful when adding items which require more than just point-and-click,
 * such as connectors, lines, polygons etc.
 * @returns {any} Action result
 */
export async function executePlanAction ({ action, renderer, items, point, position, noHistory, noChangeNotification, ...options } = {}) {
  if (!action) throw new Error('Action is required')
  if (!renderer) throw new Error('Renderer is required')

  action = (action instanceof PlanAction)
    ? action
    : createPlanAction({
      action: action.toString(),
      layout: renderer.layout,
      floor: renderer.floor,
      items,
      point,
      position
    })

  items = items ? items.filter(item => item != null) : null
  if (action.requiresItems && (!items || items.length === 0)) return

  const yes = action.confirmation
    ? await renderer.confirm(action.confirmation)
    : true

  if (yes) {
    const args = action.args || {}

    // Close the menu which triggered the action unless instructed otherwise
    const keepMenu = args.keepMenu || options.keepMenu
    if (!keepMenu) {
      renderer.hideContextMenus()
    }

    // Store the action in the history BEFORE executing it,
    // so that we can undo later
    const { layout, floor } = renderer
    const parameters = { point, position, noChangeNotification, ...args, ...options }
    if (!noHistory) {
      renderer.history.push({
        action,
        layout,
        floor,
        parameters,
        items: action.getUndoItems(layout, items)
      })
    }

    // Execute the action
    const result = await action.execute({ renderer, items, ...parameters })
    if (action.requiresRefresh) {
      await renderer.refresh(action.preserveSelection)
      if (!noChangeNotification) {
        renderer.changed({ action })
      }
    }

    renderer.log(`[ACTION:${action.name.toString()}]`, { action, result })

    return result
  }
}
