import { Note } from '@stellacontrol/model'
import { createState } from './attachments.state'

export const mutations = {
  /**
   * Stores attachments filter
   * @param {Organization} organization Organization to which the attachments belong
   * @param {String} filter Text filter for the files
   * @param {Number} age File age, in days
   */
  filterAttachments (state, { organization, filter, age } = {}) {
    if (organization !== undefined) {
      state.filesView.organization = organization
      state.filesView.attachments = []
    }
    if (filter !== undefined) {
      state.filesView.filter = filter
    }
    if (age != undefined) {
      state.filesView.age = age
    }
  },

  /**
   * Stores attachments owned by the specified organization
   * @param {Organization} organization Organization to which the attachments belong
   * @param {Array[Organization]} owners All owners of attachments
   * @returns {Promise<Array[Attachment]>}
   */
  storeAttachments (state, { organization, attachments, owners } = {}) {
    state.filesView.organization = organization
    state.filesView.attachments = attachments || []
    state.filesView.owners = owners
  },

  /**
   * Selects/deselects the specified attachment
   * @param {Attachment} attachment  Attachment to select/deselect
   * @param {Boolean} isSelected Selection status
   */
  selectAttachment (state, { attachment: { id }, isSelected = true } = {}) {
    const attachment = state.filesView.attachments.find(a => a.id === id)
    if (attachment) {
      attachment.isSelected = isSelected
    }
  },

  /**
   * Deletes the specified attachment
   * @param {Attachment} attachment Attachment to delete
   */
  deleteAttachment (state, { attachment } = {}) {
    state.filesView.attachments = state.filesView.attachments.filter(a => a.id !== attachment?.id)
  },

  /**
   * Sorts the attachments by the specified order
   * @param {String} sortOrder Sort order
   */
  sortAttachments (state, { sortOrder } = {}) {
    state.filesView.sortOrder = sortOrder
  },

  /**
   * Expands/collapses the specified folder or a group of files inside the folder
   * @param {AttachmentFolder} folder Folder
   * @param {String} group Group to expand, collapse. Specify 'all' to toggle all groups in the folder.
   * @param {Boolean} allGroups If true, toggle all groups in the folder
   * @param {Boolean} expanded Required state. If not specified, the current state is toggled to opposite state
   */
  toggleAttachmentFolder (state, { folder, group, allGroups, expanded } = {}) {
    if (group || allGroups) {
      if (allGroups) {
        for (const { name } of folder.groups) {
          state.filesView.collapsedGroups[name] = expanded == null ? !state.filesView.collapsedGroups[name] : !expanded
        }
      } else {
        state.filesView.collapsedGroups[group] = expanded == null ? !state.filesView.collapsedGroups[group] : !expanded
      }
    } else if (folder) {
      state.filesView.expandedFolders[folder.id] = expanded == null ? !state.filesView.expandedFolders[folder.id] : expanded
    }
  },

  /**
   * Updates the attachment note
   * @param {Attachment} attachment Attachment
   * @param {Note} note Note to update
   * @param {User} user User updating the note
   */
  saveAttachmentNote (state, { attachment: { id }, note, user } = {}) {
    const attachment = (state.filesView.attachments || []).find(a => a.id === id)
    if (attachment && note) {
      Note.update(attachment, note, user)
    }
  },

  /**
   * Removes a note from device
   * @param {Attachment} attachment Attachment
   * @param {Note} note Removed note
   * @param {User} user User removing the note
   */
  removeAttachmentNote (state, { attachment: { id }, note, user } = {}) {
    const attachment = (state.filesView.attachments || []).find(a => a.id === id)
    if (attachment && note) {
      Note.remove(attachment, note, user)
    }
  },

  /**
 * Starts polling attachments periodically
 * @param name Name of a view or process which has initiated the polling
 * @param clock Polling clock
 */
  watchAttachments (state, { name, clock }) {
    const { polling } = state.filesView
    polling[name] = { name, clock }
  },

  /**
   * Stops polling attachments
   * @param name Name of a view or process which has initiated the polling.
   * If not specified, all processes are stopped.
   */
  unwatchAttachments (state, { name } = {}) {
    const { polling } = state.filesView
    if (name) {
      delete polling[name]
    } else {
      for (const name of Object.keys(polling)) {
        delete polling[name]
      }
    }
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
