<script>
/**
 * Placeholder for CSS styles for UI notification popup
 */
export default {
}
</script>

<template>
</template>

<style lang="scss">
/* NOTIFICATIONS --------------------------------------------------------------------------------------- */
.notification {

  &.popup {
    min-width: 550px;
    text-align: center;
  }

  .notification-content.progress {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    min-width: 300px;

    i {
      font-size: 28px;
      margin-right: 12px;
    }
  }
}

@media screen and (width <=640px) {
  .notification {

    &.popup {
      min-width: 100vw;
      width: 100vw;
    }
  }

  .q-notifications__list {
    margin-bottom: 0;
  }

  .q-notification {
    border-radius: 0;
    min-width: 100vw;
    width: 100vw;
  }
}
</style>