<script>
import { CellMixin } from './cell-mixin'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    // Returns true if editing notes is possible
    canEditNotes () {
      return this.canUse('edit-device-notes')
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="text" :class="{ clickable: canEditNotes }">
    <span>
      {{ clip(item.recentNote, 50) }}
    </span>
    <q-popup-proxy v-if="canEditNotes" ref="notesPopup">
      <div class="notes-popup q-pa-md">
        <sc-device-notes :popup="true" :devices="[item.device]" :create-new-note="!item.device.hasNotes" @cancel="() => $refs.notesPopup.hide()">
        </sc-device-notes>
      </div>
    </q-popup-proxy>
  </div>
</template>

<style scoped lang="scss">
.notes-popup {
  width: 600px;
  min-height: 320px;
  overflow: auto;
}
</style>