<script>
import { getDurationString } from '@stellacontrol/utilities'
import { AlertType, isTrendAlert, isScheduleAlert, DeviceBandIdentifier } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { isAlertSupported } from '@stellacontrol/alerts'
import AlertConfigurationGeneral from './alert-configuration-general.vue'
import AlertConfigurationTrend from './alert-configuration-trend.vue'
import AlertConfigurationSchedule from './alert-configuration-schedule.vue'
import AlertConfigurationScheduleSimple from './alert-configuration-schedule-simple.vue'
import AlertConfigurationDeviceOffline from './alert-configuration-device-offline.vue'
import AlertConfigurationBandShutdown from './alert-configuration-band-shutdown.vue'
import AlertConfigurationOscillation from './alert-configuration-oscillation.vue'
import AlertConfigurationTemperature from './alert-configuration-temperature.vue'
import AlertConfigurationPoorNetwork from './alert-configuration-poor-network.vue'
import AlertConfigurationDeviceReboots from './alert-configuration-device-reboots.vue'
import AlertConfigurationDownlinkSignalWeak from './alert-configuration-downlink-signal-weak.vue'
import AlertConfigurationContinuousUplink from './alert-configuration-continuous-uplink.vue'
import AlertConfigurationTooManyMessages from './alert-configuration-too-many-messages.vue'
import AlertConfigurationFeedback from './alert-configuration-feedback.vue'
import AlertConfigurationReducedGain from './alert-configuration-reduced-gain.vue'
import AlertConfigurationPortSense from './alert-configuration-portsense.vue'
import AlertConfigurationSustainedReducedPower from './alert-configuration-sustained-reduced-power.vue'
import AlertConfigurationNotAvailable from './alert-configuration-not-available.vue'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Edited alert configuration
    modelValue: {
      required: true
    },
    // Default configuration for the alert
    defaultConfiguration: {
    },
    // Device whose alert configurations are edited
    device: {
    },
    // Last known status of the device
    status: {
    },
    // Indicates whether we're editing configuration for a batch of devices
    isBatch: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      configuration: null,
      originalConfiguration: null,
      AlertType,
      DeviceBandIdentifier
    }
  },

  components: {
    'sc-alert-configuration-general': AlertConfigurationGeneral,
    'sc-alert-configuration-trend': AlertConfigurationTrend,
    'sc-alert-configuration-schedule': AlertConfigurationSchedule,
    'sc-alert-configuration-schedule-simple': AlertConfigurationScheduleSimple,
    'sc-alert-configuration-device-offline': AlertConfigurationDeviceOffline,
    'sc-alert-configuration-band-shutdown': AlertConfigurationBandShutdown,
    'sc-alert-configuration-oscillation': AlertConfigurationOscillation,
    'sc-alert-configuration-temperature': AlertConfigurationTemperature,
    'sc-alert-configuration-poor-network': AlertConfigurationPoorNetwork,
    'sc-alert-configuration-device-reboots': AlertConfigurationDeviceReboots,
    'sc-alert-configuration-downlink-signal-weak': AlertConfigurationDownlinkSignalWeak,
    'sc-alert-configuration-continuous-uplink': AlertConfigurationContinuousUplink,
    'sc-alert-configuration-too-many-messages': AlertConfigurationTooManyMessages,
    'sc-alert-configuration-feedback': AlertConfigurationFeedback,
    'sc-alert-configuration-reduced-gain': AlertConfigurationReducedGain,
    'sc-alert-configuration-portsense': AlertConfigurationPortSense,
    'sc-alert-configuration-sustained-reduced-power': AlertConfigurationSustainedReducedPower,
    'sc-alert-configuration-not-available': AlertConfigurationNotAvailable
  },

  computed: {
    // Edited alert type
    alertType () {
      const { configuration } = this
      return configuration?.alertType
    },

    // Determines whether edited alert type is muted
    isMuted () {
      return !this.configuration?.isEnabled
    },

    // Determines whether the user can edit the configuration
    canEdit () {
      return this.canUse('alerts-configure')
    },

    // Determines whether the configuration is actually editable
    isEditable () {
      return this.canEdit && !this.isMuted
    },

    // Determines whether alert schedule can be edited.
    canEditSchedule () {
      return this.configuration?.schedule && this.configuration?.canEditSchedule
    }
  },

  methods: {
    isTrendAlert,
    isScheduleAlert,
    getDurationString,

    // Checks whether alert is supported on the selected device
    isNotSupported () {
      const { alertType, configuration, device, status } = this
      return !isAlertSupported(alertType, configuration, device, status)
    }
  },

  emit: [
    'update:model-value'
  ],

  watch: {
    // Watch changes in model, store locally
    modelValue () {
      this.configuration = this.modelValue?.clone()
      this.originalConfiguration = this.modelValue?.clone()
    },

    // Watch changes in configuration, mark as modified
    // if different than default configuration
    configuration: {
      handler (current) {
        current.isModified = !current.sameAs(this.defaultConfiguration)
        const isChanged = !current.sameAs(this.originalConfiguration)
        if (isChanged) {
          this.$emit('update:model-value', current)
        }
      },
      deep: true
    }
  },

  created () {
    this.configuration = this.modelValue?.clone()
    this.originalConfiguration = this.modelValue?.clone()
  }
}
</script>

<template>
  <main v-if="configuration">
    <template v-if="isNotSupported()">
      <sc-alert-configuration-not-available :configuration="configuration"
        :default-configuration="defaultConfiguration" :device="device" :isBatch="isBatch">
      </sc-alert-configuration-not-available>
    </template>
    <template v-else>
      <div class="alert-configuration" :class="{ readonly: !isEditable }">
        <sc-alert-configuration-general :configuration="configuration"
          :default-configuration="defaultConfiguration" :device="device" :isBatch="isBatch">
          <!-- Alert descriptions -->
          <template #title>
            <div v-if="alertType === AlertType.DeviceOffline">
              Alert will be triggered after the device was offline for at least
              {{ getDurationString(configuration.parameters.noHeartbeatFor) }}.
              When device is offline, it could either mean that the mains power
              was removed or the ethernet connection has failed.
            </div>

            <div v-if="alertType === AlertType.BandShutdown">
              A band will shut down due to very high RF power.
              This is to protect the cellular network.
              Add manual attenuation to prevent this in the future.
              If it keeps happening, the repeater should be checked
              by a qualified technician.
            </div>

            <div v-if="alertType === AlertType.TemperatureExceeded">
              Alert will be triggered when the PCB temperature exceeds
              {{ configuration.parameters.temperature }}°C.
            </div>

            <div v-if="alertType === AlertType.BatteryInUse">
              Alert will be triggered when mains power is removed from the repeater.
            </div>

            <div v-if="alertType === AlertType.DeviceReboots">
              Alert will be triggered when repeater experiences more than
              {{ Math.min(
                configuration.parameters.hardwareRebootCount,
                configuration.parameters.softwareRebootCount) }}
              automatic reboots within
              {{ getDurationString(configuration.trend) }}.
              There are several reasons why this might happen.
              One could be faulty mains power, another could be faulty
              configuration of the device.
            </div>

            <div v-if="alertType === AlertType.SustainedReducedPower">
              Alert will be triggered if average signal power to any band drops by
              {{ configuration.parameters.powerReduction }}dB or more,
              as compared to average signal power determined
              when device has been put into use.
            </div>

            <div v-if="alertType === AlertType.BandOscillation">
              When an oscillation or power surge occurs, the amplifier
              will permanently reduce the gain. This is to protect the network.
              Alert will be triggered when oscillation occurs on one
              or more bands, accompanied by sustained reduction of power by
              {{ configuration.parameters.mean_dw[DeviceBandIdentifier.Band07] }}dB.
            </div>

            <div v-if="alertType === AlertType.PortSense">
              This alert will trigger when fault is detected on any of the repeater ports.
              First the port has to be ON for at least
              {{ getDurationString(configuration.parameters.faultDuration) }}.
              After this time, the alert will trigger when the port turns off,
              and stays off for at least another
              {{ getDurationString(configuration.parameters.faultDuration) }}.
            </div>

            <div v-if="alertType === AlertType.ContinuousUplink">
              Alert will be triggered when uplink amplifiers remain permanently
              switched on during hours when there are usually no people in the building.
              If this happens, it can indicate that amplifiers pick up an unwanted signal.
              They need to be checked by authorized technician.
            </div>

            <div v-if="alertType === AlertType.TooManyMessages">
              Alert will be triggered when too many messages are sent by a device:
              <ul>
                <li>During the last minute - more than
                  {{ configuration.parameters.maxMessagesPerMinute }}
                </li>
                <li>
                  During the last hour - more than
                  {{ configuration.parameters.maxMessagesPerHour }}
                </li>
                <li>
                  During the last day - more than
                  {{ configuration.parameters.maxMessagesPerDay }}
                </li>
              </ul>
            </div>

            <div v-if="alertType === AlertType.Feedback">
              Alert will be triggered when feedback is reported on one or more bands.
            </div>

            <div v-if="alertType === AlertType.ReducedGain">
              Alert will be triggered when reduced gain is reported on one or more bands.
            </div>

          </template>

          <!-- Alert details -->
          <template #subtitle>
            <div v-if="alertType === AlertType.ContinuousUplink">
            </div>

            <div v-if="alertType === AlertType.Feedback">
              If the external and internal antenna are installed too close to each other,
              feedback can occur and the system will not perform optimally.
              The antennas should be checked by authorized technician.
            </div>

            <div v-if="alertType === AlertType.ReducedGain">
              If the repeater experiences very high RF power,
              it may add semi-permanent attenuation to the system.
              As a result, the band may have excess attenuation on it.
              The solution here is to add some manual attenuation to the band,
              just enough to stop it from happening.
            </div>

            <div v-if="isScheduleAlert(alertType) && !canEditSchedule" class="q-mt-sm">
              The alert is checked during the following hours: {{ configuration.schedule }}
            </div>

          </template>
        </sc-alert-configuration-general>

        <!-- alert editors -->
        <sc-alert-configuration-trend v-if="isTrendAlert(alertType)" :configuration="configuration"
          :default-configuration="defaultConfiguration" :device="device" :isBatch="isBatch">
        </sc-alert-configuration-trend>

        <template v-if="isScheduleAlert(alertType)">
          <!-- Detailed schedule editor, allowing multiple time slots during the day
          <sc-alert-configuration-schedule
            v-if="canEditSchedule"
            :configuration="configuration" :default-configuration="defaultConfiguration"
            :device="device" :isBatch="isBatch">
          </sc-alert-configuration-schedule>
          -->

          <!-- Simple schedule editor for selecting a single slot during the day -->
          <sc-alert-configuration-schedule-simple v-if="canEditSchedule"
            :configuration="configuration" :default-configuration="defaultConfiguration"
            :device="device" :isBatch="isBatch">
          </sc-alert-configuration-schedule-simple>
        </template>

        <sc-alert-configuration-device-offline v-if="alertType === AlertType.DeviceOffline"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-device-offline>

        <sc-alert-configuration-band-shutdown v-if="alertType === AlertType.BandShutdown"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-band-shutdown>

        <sc-alert-configuration-temperature v-if="alertType === AlertType.TemperatureExceeded"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-temperature>

        <sc-alert-configuration-device-reboots v-if="alertType === AlertType.DeviceReboots"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-device-reboots>

        <sc-alert-configuration-continuous-uplink v-if="alertType === AlertType.ContinuousUplink"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-continuous-uplink>

        <sc-alert-configuration-too-many-messages v-if="alertType === AlertType.TooManyMessages"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-too-many-messages>

        <sc-alert-configuration-feedback v-if="alertType === AlertType.Feedback"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-feedback>

        <sc-alert-configuration-reduced-gain v-if="alertType === AlertType.ReducedGain"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-reduced-gain>

        <sc-alert-configuration-sustained-reduced-power
          v-if="alertType === AlertType.SustainedReducedPower" :configuration="configuration"
          :default-configuration="defaultConfiguration" :device="device" :isBatch="isBatch">
        </sc-alert-configuration-sustained-reduced-power>

        <sc-alert-configuration-portsense if="alertType === AlertType.PortSense"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-portsense>

        <!-- OBSOLETE ALERT TYPES -->
        <sc-alert-configuration-poor-network v-if="alertType === AlertType.PoorNetwork"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-poor-network>

        <sc-alert-configuration-oscillation v-if="alertType === AlertType.BandOscillation"
          :configuration="configuration" :default-configuration="defaultConfiguration"
          :device="device" :isBatch="isBatch">
        </sc-alert-configuration-oscillation>

        <sc-alert-configuration-downlink-signal-weak
          v-if="alertType === AlertType.DownlinkSignalWeak" :configuration="configuration"
          :default-configuration="defaultConfiguration" :device="device" :isBatch="isBatch">
        </sc-alert-configuration-downlink-signal-weak>
      </div>
    </template>
  </main>
</template>

<style scoped lang="scss">
main {
  display: flex;
  flex-direction: column;
}

.readonly {
  color: #606060 !important;
}
</style>
