import { Log } from '@stellacontrol/utilities'
import { Permission } from '@stellacontrol/model'
import { Features } from '@stellacontrol/security'

/**
 * Helper service containing logic for running the permissions editor
 * where principal's permissions are edited
 */
export class Permissions {
  /**
   * Initializes the guardian with a feature tree
   * @param features Feature tree
   * @param principal Principal in whose context the permissions will be resolved.
   * @param {Environment} environment Execution environment (DEV, PROD etc)
   * @param benefactor Principal who is granting the permissions
   * @param benefactorGuardian Benefactor's security guardian
   * @description It is required that principal and benefactor are both fully populated
   * and have all details of their owner principals - organization, profile etc., all with
   * their respective permissions ready for inspection.
   */
  constructor (features, principal, environment, benefactor, benefactorGuardian) {
    if (!features) throw new Error('Features are required')
    if (!principal) throw new Error('Principal is required')
    if (!benefactor) throw new Error('Benefactor is required')
    if (!benefactorGuardian) throw new Error('Benefactor guardian is required')

    this.featureTree = features
    this.principal = principal
    this.environment = environment
    this.benefactor = benefactor
    this.benefactorGuardian = benefactorGuardian
    this.name = `${principal.type} guardian`.toUpperCase()

    this.initialize(features)
  }

  /**
   * Initializes the permissions service
   */
  initialize () {
    const { featureTree, principal, environment } = this

    // Create the hierarchy of applicable permissions
    this.features = new Features(featureTree, principal, environment)
    this.permissionTree = createPermissionTree(principal, principal.getPermissions(), this.features.root, this.permissionDictionary)
    this.permissionDictionary = createPermissionDictionary(this.permissionTree)
    this.permissions = createPermissionList(this.permissionDictionary, this.features)
    this.topLevelPermissions = this.permissions.filter(p => p && p.level === 1)

    this.__filter = ''
    this.hideUnmatchedPermissions = true

    this.apply()
  }

  /**
   * Principal in whose context the permissions will be resolved
   * @type {Principal}
   */
  principal

  /**
  * All application features
  * @type {Features}
  */
  featureTree

  /**
  * Features applicable to the principal
  * @type {Features}
  */
  features

  /**
   * Current environment
   * @type {Environment}
   */
  environment

  /**
   * Principal permissions
   * @type {Array[Permission]}
   */
  permissions = []

  /**
   * Top-level permissions
   * @type {Array[Permission]}
   */
  topLevelPermissions = []

  /**
   * Principal who is granting the permissions
   * @type {Principal}
   */
  benefactor

  /**
   * Benefactor's security guardian
   * @type {Guardian}
   */
  benefactorGuardian

  /**
   * Currently selected permission
   * @type {Permission}
   */
  selectedPermission

  /**
  * List of permissions which can be edited by the benefactor
  * @type {Array[Permission]}
  */
  get editablePermissions () {
    return this.permissions.filter(p => this.isAvailableForEditing(p))
  }

  /**
   * Traverses the tree of permissions,
   * running the specified handler on each permission.
   * If handler returns truthy value on a node,
   * traverse will not traverse that node recursively.
   * @param permission Permission to traverse
   * @param handler Handler to run on each permission
   */
  traverse (permission, handler) {
    if (!handler(permission)) {
      if (permission.hasChildren) {
        for (const child of permission.permissions) {
          this.traverse(child, handler)
        }
      }
    }
  }

  /**
   * Returns a permission
   * @param {Permission|String} value Permission instance or feature name
   * @param {Boolean} throwIfNotFound Throws if permission not found
   * @returns {Permission}
   */
  getPermission (value = '', throwIfNotFound = true) {
    const permission = value instanceof Permission ? value : this.permissionDictionary[value.toString()]
    if (!permission && throwIfNotFound) throw new Error(`Unknown permission ${value}`)
    return permission
  }

  /**
   * Indicates that feature can be used by the principal.
   * Feature has to be enabled and applicable for the current principal.
   * Then, if feature is marked as secure, it must be explicitly permitted.
   * Regardless of that, the whole parent chain leading to it must be permitted.
   * @param {Permission|String} value Permission instance or feature name
   * @returns {Boolean}
   */
  canUse (value) {
    if (this.principal.isSuperOrganization) {
      return true
    }

    if (this.principal.isSuperAdministrator) {
      return true
    }

    const permission = this.getPermission(value, false)

    let result = false
    if (permission) {
      if (permission.feature.isRequirement) {
        result = !permission.implicit && permission.canUse
      } else {
        result = permission.feature.isSecure ? permission.canUse : true
      }
      if (result && permission.parent && permission.parent.level > 0) {
        result = this.canUse(permission.parent)
      }
    }

    return result
  }

  /**
   * Sets the value of `canUse` flag for the specified permission,
   * updates a list of granted permissions in the principal.
   * @param {Permission} permission Permission to set
   * @param {Boolean} value Flag value
   * @description If permission is now granted, expand it if was collapsed
   * so the user knows that he has now access to underlying child permissions.
   * If denied, unselect to hide any currently visible editors and also deny
   * granting this permission to others.
   */
  setCanUse (permission, value) {
    if (permission && this.isEditable(permission)) {
      permission.canUse = Boolean(value)
      if (value) {
        permission.isCollapsed = false
      } else {
        this.deselectPermission()
      }
      this.apply()
    }
  }

  /**
   * Sets the value of `defaultValue` flag for the specified permission,
   * @param {Permission} permission Permission to set
   * @param {Boolean} value Flag value
   */
  setDefaultValue (permission, value) {
    if (permission && this.isEditable(permission)) {
      permission.defaultValue = Boolean(value)
    }
  }

  /**
   * Sets the value of `canUse` flag for all permissions,
   * optionally those only which match the predicate.
   * Updates a list of granted permissions in the principal.
   * @param {Boolean} value Flag value
   * @param {Function<String, boolean>} predicate Optional predicate for filtering the permissions to assign to.
   */
  setCanUseAll (value, predicate) {
    for (const permission of this.permissions) {
      if (!predicate || predicate(permission)) {
        permission.canUse = Boolean(value)
        if (value) {
          // If can use, expand to reveal the children
          permission.isCollapsed = false
        }
      }
    }
    this.deselectPermission()
    this.apply()
  }

  /**
   * Returns a set of permissions currently applicable and
   * granted to the principal, by traversing the permissions tree and
   * querying what has been granted
   * @returns {Array[Permission]}
   */
  getPrincipalPermissions () {
    const permissions = []
    this.traverse(this.permissionTree, permission => {
      if (this.canUse(permission)) {
        const { feature: { isSecure }, canUse, context } = permission
        if ((isSecure && canUse) || (!isSecure && context)) {
          permissions.push(new Permission(permission))
        }
      }
    })
    return permissions
  }

  /**
   * Updates permissions of the current principal
   */
  apply () {
    this.principal.permissions = this.getPrincipalPermissions()
  }

  /**
   * Indicates whether the item should be hidden in the UI.
   * It happens, if any of the parents is collapsed,
   * or if item is not secure and none of its children are visible.
   * @param {Permission|String} value Permission instance or feature name
   * @param {Boolean} recursive Indicates that we're recursively checking children, so don't go back to parent
   * @returns {Boolean}
   * @description
   * This behaviour changes when filter has been applied
   * to the permission tree. In this case item will
   * be also hidden if it doesn't match the filter,
   * nor does any of its children.
   */
  isHidden (value, recursive) {
    const permission = this.getPermission(value)

    // Hide if any of the parents is collapsed or not applicable
    let isHidden = false
    if (!recursive) {
      let { parent } = permission
      while (!isHidden && parent) {
        if (parent.isCollapsed) {
          isHidden = true
        } else {
          parent = parent.parent
        }
      }
    }

    // Hide if no secure features in this branch are currently visible
    // and there's nothing else to edit in the feature, like context
    if (!isHidden) {
      let secureFeatures = this.getSecureFeaturesIn(permission)
      isHidden = !permission.feature.context && secureFeatures.length === 0
    }

    // Hide if feature is not applicable due to other conflicting
    // permissions having been granted
    if (!isHidden) {
      const { notApplicableWhen = [] } = permission
      if (notApplicableWhen.length > 0) {
        isHidden = notApplicableWhen.every(p => this.canUse(p))
        // Make sure that the feature does not remain granted, while hidden
        if (isHidden) {
          permission.canUse = false
        }
      }
    }

    // Check whether feature requires benefactor
    // to have a specific permission level, to be visible
    if (!isHidden) {
      const { visibleFor = [] } = permission.feature || []
      if (visibleFor.length > 0) {
        isHidden = !visibleFor.includes(this.benefactor.organization.level)
      }
    }

    // If filtering the tree, check further.
    // Hide the permission if it doesn't match the filter,
    // and none of the children does
    if (!isHidden && this.filter && this.hideUnmatchedPermissions && !permission.matchesFilter) {
      isHidden = permission.hasChildren ? !permission.somePermissions(p => p.matchesFilter) : true
    }

    if (!isHidden && permission.hasChildren) {
      isHidden = permission.permissions.every(p => this.isHidden(p, true))
    }

    return isHidden
  }

  /**
   * Indicates that permission is enabled for editing.
   * Permission is editable if:
   * - all of its parents are implicitly or explicitly granted
   * - all of the required permissions are implicitly or explicitly granted
   * - benefactor's required level
   * - etc.
   * @param {Permission|String} value Permission instance or feature name
   * @returns {Boolean}
   */
  isEditable (value) {
    const permission = this.getPermission(value)

    let isEditable = true

    const { parent } = permission
    if (parent && parent.level > 0) {
      isEditable = this.canUse(parent)
    }

    // Check if whether other permissions must be enabled,
    // for this feature to become editable
    if (isEditable) {
      const { requiredPermissions = [] } = permission
      if (requiredPermissions.length > 0) {
        isEditable = requiredPermissions.every(p => this.canUse(p))
      }
    }

    // Check whether feature requires benefactor
    // to have a specific permission level, to be able to edit
    if (isEditable) {
      const { grantedBy = [] } = permission
      if (grantedBy.length > 0) {
        isEditable = this.benefactor.organization.isSuperOrganization || grantedBy.includes(this.benefactor.organization.level)
      }
    }

    // If permission is a requirement and principal is granted with that requirement,
    // they should not be able to deny the requirement from their children!
    if (isEditable && permission.feature.isRequirement) {
      const mustUse = this.benefactorGuardian.mustUse(permission.featureName)
      isEditable = !mustUse
    }

    return isEditable
  }


  /**
   * Indicates that permission is available for editing at all.
   * This is slightly different than `isEditable`, as here we only check
   * external factors such as:
   * - other required permissions granted
   * - benefactor's required level
   * - etc.
   * @param {Permission|String} value Permission instance or feature name
   * @returns {Boolean}
   */
  isAvailableForEditing (value) {
    const permission = this.getPermission(value)

    let isEditable = true

    // Check if whether other permissions must be enabled,
    // for this feature to become editable
    if (isEditable) {
      const { requiredPermissions = [] } = permission
      if (requiredPermissions.length > 0) {
        isEditable = requiredPermissions.every(p => this.canUse(p))
      }
    }

    // Check whether feature requires benefactor
    // to have a specific permission level, to be able to edit
    if (isEditable) {
      const { grantedBy = [] } = permission
      if (grantedBy.length > 0) {
        isEditable = grantedBy.includes(this.benefactor.organization.level)
      }
    }

    // If permission is a requirement and principal is granted with that requirement,
    // they should not be able to deny the requirement from their children!
    if (isEditable && permission.feature.isRequirement) {
      const mustUse = this.benefactorGuardian.mustUse(permission.featureName)
      isEditable = !mustUse
    }

    return isEditable
  }

  /**
   * Recursively returns all secure features in the specified permission
   * @param {Permission|String} value Permission instance or feature name
   * @param {Array} items Collection to collect the children
   * @returns {Array[Feature]}
   */
  getSecureFeaturesIn (value, items = []) {
    const permission = this.getPermission(value)
    if (permission.feature.isSecure) {
      items.push(permission.feature)
    }
    for (const child of permission.permissions || []) {
      this.getSecureFeaturesIn(child, items)
    }
    return items
  }

  /**
   * Checks if all top-level permissions are now expanded
   * @type {Boolean}
   */
  get allExpanded () {
    return this.topLevelPermissions.every(p => !(p.hasChildren && p.isCollapsed))
  }

  /**
   * Checks if all top-level permissions are now collapsed
   * @type {Boolean}
   */
  get allCollapsed () {
    return this.topLevelPermissions.every(p => !p.hasChildren || p.isCollapsed)
  }

  /**
   * Expands all permissions
   */
  expandAll () {
    for (const permission of this.permissions) {
      if (permission.hasChildren) {
        permission.isCollapsed = false
      }
    }
  }

  /**
   * Collapses all permissions
   */
  collapseAll () {
    for (const permission of this.permissions) {
      if (permission.hasChildren) {
        permission.isCollapsed = true
      }
    }
  }

  /**
   * Toggles visibility of top-level permissions
   */
  toggleAll () {
    const { allCollapsed } = this
    for (const permission of this.permissions) {
      if (permission.hasChildren) {
        permission.isCollapsed = !allCollapsed
      }
    }
  }

  /**
   * Toggles visibility of permissions under the specified one
   * @type {Permission}
   */
  togglePermission (permission) {
    if (permission.hasChildren) {
      permission.isCollapsed = !permission.isCollapsed
    }
  }

  /**
   * Returns true if permission is selectable,
   * which is when it's secure and currently editable
   * @param {Permission} permission Permission to check
   * @returns {Boolean}
   */
  canBeSelected (permission) {
    return permission &&
      (permission.feature.isSecure || permission.feature.context) &&
      this.isEditable(permission)
  }

  /**
   * Marks the permission as selected, unmarks other currently selected ones
   * @param {Permission} permission Permission to select
   */
  selectPermission (permission) {
    this.deselectPermission()
    if (permission && this.canBeSelected(permission)) {
      permission.isSelected = true
      this.selectedPermission = permission
    }
  }

  /**
   * Deselects the currently selected permission
   */
  deselectPermission () {
    if (this.selectedPermission) {
      this.selectedPermission.isSelected = false
    }
    this.selectedPermission = null
  }

  /**
   * Text filter
   * @type {String}
   */
  get filter () {
    return (this.__filter || []).join(' ')
  }

  /**
   * Returns true if permissions are currently filtered
   * @type {Boolean}
   */
  get hasFilter () {
    return (this.__filter || []).length > 0
  }

  /**
   * Assigns a permission filter
   * @param {String} value Filter text
   */
  set filter (value) {
    const hadFilter = this.hasFilter
    this.__filter = (value || '').toLowerCase().split(' ')

    // If first applied first time, expand all nodes
    // so the user can see how his filter is being applied.
    if (this.hasFilter && !hadFilter) {
      this.expandAll()
    }

    this.__filterKeywords = this.__filter.map(t => (t || '').trim()).filter(t => t)
    this.applyFilter(this.__filterKeywords)
  }

  /**
   * Filters the permissions by the specified text
   * @param {Array[String]} keywords Keywords to find. There could be more than one
   * keyword specified. In this case we do a fuzzy search for occurrence
   * of all keywords, not necessarily next to each other.
   */
  applyFilter (keywords) {
    if (keywords?.length > 0) {
      for (const permission of this.permissions) {
        permission.clearFilter()
        permission.matchesFilter = keywords.every(text =>
          permission.searchText.includes(text))
      }
    }
  }

  /**
   * Returns permission description.
   * Normally this will be just feature description,
   * although some features have different description depending on the subject
   * to which they're granted. For example, persistent-sessions permission
   * on organization level is described as 'Allow permanent sessions for some users'
   * while on user level, where permission is actually applied, it is described
   * as 'Allow permanent sessions for this user'
   * @param {Permission} permission Permission whose description to return
   * @returns {String}
   */
  getDescription (permission) {
    const { feature: { description, descriptions } } = permission
    const { type } = this.principal || {}
    if (descriptions && descriptions[type]) {
      return descriptions[type]
    } else {
      return description
    }
  }

  /**
   * Returns permission description with indicated filter text
   * @param {Permission} permission Permission whose description to return
   * @param {String} style CSS style to apply to the indicated filter text
   * @returns {String}
   */
  getDescriptionWithFilter (permission, style) {
    let description = this.getDescription(permission)
    if (this.__filterKeywords && permission.matchesFilter) {
      for (const keyword of this.__filterKeywords) {
        const regex = new RegExp(`(${keyword})`, 'ig')
        const replacement = style ? `<span style="${style}">$1</span>` : '<strong>$1</strong>'
        description = description.replace(regex, replacement)
      }
    }
    return description
  }
}

/**
 * Recursively converts a feature to a permission for that feature.
 * Used to build permission trees
 * @param {Principal} principal Principal owning the permission
 * @param {Dictionary<string, Permission>} principalPermissions Dictionary of permissions currently granted to the principal
 * @param {Feature} feature Feature
 * @param {Dictionary<string, Permission>} previousPermissions Dictionary of previously granted permissions, used to detect whether
 * a feature was previously unavailable but now became available
 * @param {Boolean} recurse If true, feature tree will be traversed recursively
 * @returns {Permission} Root permission
 */
function createPermissionTree (principal, principalPermissions, feature, previousPermissions, recurse = true) {
  if (!principal) throw new Error('Principal is required')
  if (!feature) throw new Error('Feature is required')

  const { id: principalId, type: principalType } = principal
  if (!principalType) throw new Error('Principal type is required')

  // Recursively create child permissions
  const permissions = recurse && feature.hasChildren
    ? feature.features
      // filter out not applicable features
      .filter(childFeature => childFeature.isApplicable)
      // recurse
      .map(childFeature => createPermissionTree(principal, principalPermissions, childFeature, previousPermissions, true))
    : undefined

  // Create permission, set its initial state as per principal's current permissions.
  // If feature was previously not available but just became available,
  // check if it is permitted by default and mark as CanUse if so
  const newlyAvailableFeature = previousPermissions && !previousPermissions[feature.name]
  const existingPermission = principalPermissions[feature.name] ||
    (newlyAvailableFeature
      ? { canUse: principal.getDefaultPermission(feature) }
      : undefined
    )
  const permission = new Permission({
    ...existingPermission,
    feature,
    principalId,
    permissions
  })

  return permission
}
/**
 * Creates a dictionary of all permissions from a permission tree
 * @param {Permission} permission Root permission
 * @param {Permission} parent Parent permission
 * @param {Number} index Permission index in the parent
 * @returns {Dictionary<string, Permission>} Permission dictionary
 */
function createPermissionDictionary (permission, parent, index = 1) {
  permission.level = parent ? parent.level + 1 : 0
  permission.index = (parent ? (parent.index || '0000') + '.' : '') + index.toString().padStart(4, '0')
  permission.parent = parent
  permission.isCollapsed = false

  const dictionary = {
    [permission.feature.name]: permission
  }

  // Recursively collect all permissions
  if (permission.hasChildren) {
    let childIndex = 1
    for (const child of permission.permissions) {
      Object.assign(dictionary, createPermissionDictionary(child, permission, childIndex++))
    }
  }

  return dictionary
}

/**
 * Creates a list of all permissions from a permission dictionary
 * @param {Dictionary<string, Permission>} dictionary Permission dictionary
 * @param {Features} features Features
 * @returns {Array[Permission]}
 */
function createPermissionList (dictionary, features) {
  const permissions = Object
    .values(dictionary)
    .filter(permission =>
      // Filter out root
      permission && permission.level > 0)
    .map(permission => {
      // Add runtime properties
      permission.isCollapsed = false
      permission.matchesFilter = false
      permission.searchText = (permission.feature.description || '').toLowerCase()

      // Determine permissions which must already be granted,
      // for this permission to be grantable
      const feature = features.getFeature(permission.featureName)
      if (!feature) {
        Log.warn('Permission found for a non-existing feature', permission.featureName)
        return
      }

      const { requires = [], notApplicableWhen = [], grantedBy = [] } = feature
      if (requires.length > 0) {
        permission.requiredPermissions = requires.map(name => dictionary[name])
        permission.requiredPermissionsText = permission.requiredPermissions
          .map(p => p && p.feature.description)
          .filter(t => t)
          .join(', ')
      }

      // Determine permissions which must not be granted,
      // for this permission to be grantable
      if (notApplicableWhen.length > 0) {
        permission.notApplicableWhen = notApplicableWhen.map(name => dictionary[name])
      }

      // Determine which level the benefactor user must have,
      // to be able to grant this permission
      if (grantedBy.length > 0) {
        permission.grantedBy = grantedBy
      }

      return permission
    }).
    filter(p => p)

  // Sort by ID to enforce proper rendering sequence
  permissions.sort((a, b) => a.index.localeCompare(b.index))
  return permissions
}
