<script>
import { mapState, mapGetters } from 'vuex'
import { isToday, isYesterday } from 'date-fns'
import { formatDateTime, formatTime, pluralize, getConciseDurationString } from '@stellacontrol/utilities'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  computed: {
    ...mapState({
      // Age of the alerts to display
      age: state => state.deviceDashboard.lastAlertsAge,
      // Recently retrieved alerts of monitored devices
      lastAlerts: state => state.deviceStatus.alerts
    }),

    ...mapGetters([
      'toLocalDateTime'
    ]),

    items () {
      const { lastAlert, alerts, age } = this
      const items = [
        { text: 'Alerts', class: { label: true } }
      ]

      if (lastAlert) {
        // Returns alert time in user-friendly format
        const alertTimeText = alert => {
          const alertTime = this.toLocalDateTime(alert.time)
          const alertTimeText = isToday(alertTime)
            ? formatTime(alertTime)
            : (isYesterday(alertTime) ? `Yesterday, ${formatTime(alertTime)}` : formatDateTime(alertTime, 'd MMM, HH:mm:ss'))
          return alertTimeText
        }

        const otherAlerts = alerts.filter(alert => alert.id !== lastAlert.id)
        const tooltip = otherAlerts.length > 0
          ? `
            <ul>
              ${[lastAlert, ...otherAlerts].map(alert => `
              <li>
                <i>${alertTimeText(alert)}</i>
                <br>
                ${alert.message}
              </li>
            `).join('\n')}
            </ul>
          `
          : ''

        items.push(
          {
            text: alertTimeText(lastAlert),
            class: { small: true }
          },
          {
            text: lastAlert.message,
            class: { small: true },
            tooltip
          }
        )

        if (otherAlerts.length > 0) {
          items.push({
            text: `${otherAlerts.length} more ${pluralize(otherAlerts.length, 'alert')} ...`,
            class: { small: true, muted: true },
            tooltip
          })
        }


      } else {
        items.push({
          text: `No alerts during the last ${getConciseDurationString(age)}`,
          class: { small: true }
        })
      }
      return items
    },

    // Recently logged alerts
    alerts () {
      return this.lastAlerts[this.device.serialNumber] || []
    },

    // Most recent alert
    lastAlert () {
      return this.alerts[0]
    },

    // Widget icon
    icon () {
      const { device } = this
      return device.canTriggerAlerts ? 'notifications_active' : 'notifications_off'
    },

    // Color of the widget icon
    color () {
      const { device, lastAlert } = this
      return device.canTriggerAlerts
        ? (lastAlert ? 'deep-orange-6' : 'light-green-7')
        : 'grey-5'
    }
  }
}
</script>

<template>
  <sc-widget-text v-if="device?.canTriggerAlerts" iconMargin="0" :icon="icon" :iconColor="color"
    :items="items" min-width="195px" max-width="300px">
  </sc-widget-text>
</template>

<style lang="scss" scoped></style>
