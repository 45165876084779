/**
 * Checks whether the specified text is valid JSON
 * @param {String} s Text to check
 * @returns {Boolean}
 */
export function isJSON (s) {
  if (s) {
    try {
      JSON.parse(s.toString())
      return true
    } catch {
      return false
    }
  }
}

/**
 * Pretty-print the data as JSON
 * @param {any} data Data to pretty-print
 * @param {Number} indentation JSON indentation. If `null`, JSON will be unindented
 * @param {String} errorText If specified and input cannot be parsed as JSON, this error text will be returned,
 * otherwise an exception will be thrown
 * @returns {String}
 */
export function toJSON (data, indentation = 2, errorText) {
  if (data == null) {
    return errorText
  }

  try {
    const instance = (typeof data === 'string')
      ? JSON.parse(data)
      : data

    const json = JSON.stringify(instance, null, indentation)
    return json

  } catch (error) {
    if (errorText) {
      return errorText
    } else {
      throw error
    }
  }
}

/**
 * Tries parsing the data as JSON
 * @param {any} data Data to parse
 * @param {any} defaultValue Optional default value to return if data cannot be parsed.
 * If not specified, the data will be returned
 * @returns {any}
 */
export function tryParseJSON (data, defaultValue) {
  if (data == null) {
    return data
  }

  try {
    return JSON.parse(data)
  } catch {
    return defaultValue === undefined ? data : defaultValue
  }
}
