import { safeParseInt } from '@stellacontrol/utilities'
import { hasLegacyFirmware, getDeviceFamily, productTypeToDeviceType, GeoCoordinates } from '@stellacontrol/model'

/**
 * Properties related to device identity
 */
export class DeviceStatusIdentity {
  /**
   * Initializes the instance
   * @param serialNumber Device serial number
   * @param mega Live device data retrieved from Shadow API
   * @param data Initial values of the properties
   */
  constructor (serialNumber, mega, data = {}) {
    this.serialNumber = serialNumber
    Object.assign(this, data)
    if (mega) {
      this.parse(mega)
    }
  }

  /**
   * Creates an instance populated with deserialized data
   */
  static from (data = {}) {
    const status = new DeviceStatusIdentity()
    Object.assign(status, data)
    return status
  }

  /**
   * MEGA message version
   * @type {String}
   */
  megaVersion

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * Device type
   * @type {DeviceType}
   */
  type

  /**
   * Product type
   * @type {String}
   */
  productType

  /**
   * Device model
   * @type {String}
   */
  model

  /**
   * Device model as used by reseller.
   * Resellers can introduce alternative naming for device models.
   * @type {String}
   */
  resellerModel

  /**
   * Device communication protocol
   * @type {MessageProtocol}
   */
  protocol

  /**
   * Device market
   * @type {DeviceMarket}
   */
  market

  /**
   * Port count
   * @type {Number}
   */
  portCount

  /**
   * Device bands
   * @type {String}
   */
  bands

  /**
   * Firmware version
   * @type {String}
   */
  firmwareVersion

  /**
   * Long firmware version
   * @type {String}
   */
  firmwareVersionLong

  /**
   * Hardware version
   * @type {String}
   */
  hardwareVersion

  /**
   * EEPROM version
   * @type {String}
   */
  eepromVersion

  /**
   * Indicates a legacy firmware < 7.2.0.0
   * @type {Boolean}
   */
  get isLegacyFirmware () {
    return hasLegacyFirmware(this)
  }

  /**
   * Device family
   * @type {DeviceFamily}
   */
  family

  /**
   * Device location
   * @type {String}
   */
  location

  /**
   * Device geographic coordinates
   * @type {GeoCoordinates}
   */
  coordinates

  /**
   * Device is in SHIP mode
   * @type {Boolean}
   */
  isShip

  /**
   * Parses raw MEGA record received from the device
   * @param {Object} mega MEGA record
   */
  parse (mega = {}) {
    this.megaVersion = mega['@version']
    if (!this.megaVersion) return

    this.productType = mega['product_type']
    this.type = productTypeToDeviceType(mega['product_type'])
    this.model = mega['product_model']
    this.market = mega['market']
    this.resellerModel = mega['product_model_alt']
    this.portCount = safeParseInt(mega['product_ports'])
    this.bands = mega['product_bands']
    this.firmwareVersion = mega['@version_git']
    this.firmwareVersionLong = mega['@version_git_long'] || mega['@version_git']
    this.hardwareVersion = mega['hw_version']
    this.eepromVersion = mega['eeprom_version']
    this.family = getDeviceFamily({
      serialNumber: this.serialNumber,
      model: mega['product_model'],
      megaVersion: mega['@version']
    })
    this.location = mega['location'] && mega['location'] !== '-' ? mega['location'] : undefined
    this.coordinates = GeoCoordinates.fromLatLon(mega['latitude'], mega['longitude'])
    this.isShip = Boolean(mega['ship'] || mega['_ship_setaws'])
  }

  /**
   * Updates the specified device with the parsed status
   * @param {Device} device Device to update with this status
   */
  update (device) {
    if (device) {
      const {
        firmwareVersion,
        firmwareVersionLong,
        hardwareVersion,
        eepromVersion,
        megaVersion,
        family,
        location,
        type,
        model,
        protocol,
        resellerModel,
        portCount,
        bands,
        isShip
      } = this

      // Make sure to preserve the already known values,
      // if they're absent for whatever reason in the received status
      Object.assign(device, {
        megaVersion,
        firmwareVersion: firmwareVersion || device.firmwareVersion,
        firmwareVersionLong: firmwareVersionLong || device.firmwareVersionLong || device.firmwareVersion,
        hardwareVersion: hardwareVersion || device.hardwareVersion,
        eepromVersion: eepromVersion || device.eepromVersion,
        family: family || getDeviceFamily(device),
        location: location || device.location,
        type: type || device.type,
        model: model || device.model,
        protocol: protocol || device.protocol,
        resellerModel: resellerModel || device.resellerModel,
        portCount: portCount || device.portCount,
        bands: bands || device.bands,
        isShip
      })
    }
  }


  /**
   * Determines whether device identity has changed
   * as compared to the specified device
   * @param {Device} device Device to be potentially updated
   * @returns {Boolean}
   */
  isDifferentThan (device) {
    if (device) {
      const { type, model, resellerModel, firmwareVersion, firmwareVersionLong, hardwareVersion, eepromVersion, megaVersion, portCount, location } = this
      let changedProperty

      if (type != null && device.type !== type) changedProperty = 'type'
      else if (model != null && device.model !== model) changedProperty = 'model'
      else if (resellerModel != null && device.resellerModel !== resellerModel) changedProperty = 'resellerModel'
      else if (firmwareVersion != null && device.firmwareVersion !== firmwareVersion) changedProperty = 'firmwareVersion'
      else if (firmwareVersionLong != null && device.firmwareVersionLong !== firmwareVersionLong) changedProperty = 'firmwareVersionLong'
      else if (hardwareVersion != null && device.hardwareVersion !== hardwareVersion) changedProperty = 'hardwareVersion'
      else if (eepromVersion != null && device.eepromVersion !== eepromVersion) changedProperty = 'eepromVersion'
      else if (megaVersion != null && device.megaVersion !== megaVersion) changedProperty = 'megaVersion'
      else if (portCount != null && device.portCount !== portCount) changedProperty = 'portCount'
      else if (location != null && device.location !== location) changedProperty = 'location'

      if (changedProperty) {
        return {
          changedProperty,
          fromValue: device[changedProperty],
          toValue: this[changedProperty]
        }
      } else {
        return {}
      }
    }
  }
}
