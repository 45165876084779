import { Routes as inventoryRoutes } from '../views/inventory'
import { Routes as installationsRoutes } from '../views/installations'
import { Routes as buildingDashboardRoutes } from '../views/building-dashboard'
import { Routes as deviceDashboardRoutes } from '../views/device-dashboard'
import { Routes as placesRoutes } from '../views/places'
import { Routes as floorPlanRoutes } from '../views/floor-plans'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...inventoryRoutes,
  ...installationsRoutes,
  ...buildingDashboardRoutes,
  ...deviceDashboardRoutes,
  // TODO: OBSOLETE, REMOVE WHEN READY
  ...floorPlanRoutes,
  ...placesRoutes,
]

/**
 * Enumeration of route names in this applet
 */
export const AppletRoute = {
  Inventory: 'inventory',
  AddToInventory: 'inventory-add',
  Installations: 'installations',
  BuildingDashboard: 'building-dashboard',
  Building: 'building',
  DeviceDashboard: 'device-dashboard',
  // TODO: OBSOLETE, REMOVE WHEN READY
  FloorPlans: 'floor-plans',
  FloorPlan: 'floor-plan',
  Place: 'place',
  Places: 'places'
}
