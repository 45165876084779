import UAParser from 'ua-parser-js'
import { Log, createClock, delay } from '@stellacontrol/utilities'
import { dispatchParallel } from '@stellacontrol/client-utilities'
import { initializeAPI, CommonAPI, SecurityAPI, CommonPushClient } from '@stellacontrol/client-api'
import { Bug, BugLocation, AuditItem, ApplicationFlags } from '@stellacontrol/model'
import { Views } from '../../views'
import { Converter as MarkdownConverter } from 'showdown'

/**
 * Application actions
 */
export const actions = {
  /**
   * Initialize the application
   * @param context Application context
   */
  async initializeApplication ({ commit, dispatch, getters }, context) {
    const { client, key, api, loggingLevel } = context
    Log.initialize(loggingLevel)

    commit('clientInitializing')

    // Retrieve client configuration
    CommonAPI.initialize({ url: api, client, key })
    const { configuration, flags } = await CommonAPI.getClientConfiguration()
    if (!configuration) {
      throw new Error('Client configuration could not be retrieved')
    }

    const { environment, origin } = configuration
    commit('initializeClient', { environment, origin, configuration, flags })
    commit('applicationFlagsChanged', { flags })
    window.Configuration = configuration

    // Initialize API clients
    await initializeAPI(configuration)

    // Initialize views
    await dispatch('initializeViews', { views: Views })

    // Ready for action!
    Log.info(`${getters.application.description} v.${getters.application.version} initialized [${environment.toUpperCase()}]`)
    if (getters.isDevelopmentEnvironment) {
      Log.debug('Configuration', configuration)
    }

    // Make a few things public for debugging purposes
    window.StellaControl = {
      log: Log,
      application: getters.application,
      environment,
      origin,
      configuration
    }

    commit('clientInitialized')
  },

  /**
   * Triggered when new user session has started
   * @param session Session details
   */
  async sessionStarted ({ dispatch }, { session } = {}) {
    if (session) {
      await dispatch('initializeMenu')
      await dispatch('initializeData')
      await dispatch('watchApplicationUpdates')
    }
  },

  /**
   * Loads and initializes application data after the succesful login.
   * Triggered AFTER {@link sessionStarted}, should fetch and prepare
   * everything that the application needs to continue.
   * @description
   * Implement `dataInitialized` mutation in other stores to massage
   * and post-process the data, for example to create hierarchies,
   * recreate relationships between objects etc.
   */
  async initializeData ({ dispatch }) {
    await dispatchParallel([
      'requireOrganizationProfiles',
      'requireOrganizations',
      'requireUsers',
      'requirePlaces',
      'requireDefaultAlertConfigurations'
    ])

    await dispatch('requireDevices')
    await dispatch('dataInitialized')
    await dispatch('initializeServiceManagement')
  },

  async dataInitialized ({ commit, getters }) {
    const { organizationProfiles, organizations, pricelist, pricelists, places } = getters
    commit('dataInitialized', { organizationProfiles, organizations, pricelist, pricelists, places })
  },

  /**
   * Signals ending of the current user session
   */
  async clearSession ({ dispatch }) {
    await dispatch('clearMenu')
  },

  /**
   * On route changes log the navigation
   */
  async navigationStarted ({ getters }, { to }) {
    const { isLoggedIn, currentUser: actor } = getters
    if (isLoggedIn && actor) {
      const { name: page, fullPath: url } = to
      const auditItem = AuditItem.forPageView({ actor, page, url })
      CommonAPI
        .audit(auditItem)
        .catch(() => { })
    }
  },

  /**
   * Creates bug report from specified exception
   * @param error exception
   */
  async createBug ({ getters }, { error } = {}) {
    if (error) {
      const { currentUser, previousRoute = {} } = getters
      const ua = new UAParser()
      const path = previousRoute.fullPath
      const { origin } = new URL(window.location.toString())
      const url = `${origin}/#${path}`

      const bug = Bug.fromError({
        location: BugLocation.FrontEnd,
        user: currentUser,
        platform: ua.getResult(),
        error,
        url
      })

      return bug
    }
  },

  /**
   * Submits a bug report with the specified exception
   * @param {Error} error Exception to report
   */
  async submitBugReport ({ dispatch }, { error } = {}) {
    let bug = await dispatch('createBug', { error })
    if (bug) {
      try {
        bug = await CommonAPI.submitBugReport({ bug })
        return bug
      } catch (e) {
        Log.error('Now, this sounds a bit mad, but another exception happened while submitting your bug report :-(')
        Log.exception(e)
      }
    }
  },

  /**
   * Sends the specified bug report
   * @param {Bug} bug Bug report to send
   */
  async sendBugReport (_, { bug } = {}) {
    if (bug) {
      try {
        bug = await CommonAPI.sendBugReport({ bug })
        return bug
      } catch (e) {
        Log.error('Now, this sounds a bit mad, but another exception happened while submitting your bug report :-(')
        Log.exception(e)
      }
    }
  },

  /**
   * Retrieves a bug report
   * @param {String} id Identifier of a bug report to retrieve
   */
  async getBugReport (_, { id } = {}) {
    return CommonAPI.getBugReport({ id })
  },

  /**
   * Retrieves documents available to the current user,
   * optionally matching the specified category,
   * @param {String} category Category of documents to retrieve
   * @param {String} subcategory Optional subcategory of document to retrieve
   * @returns {Array[Document]} Documents matching the criteria
   */
  async getDocuments ({ getters }, { category, subcategory } = {}) {
    const { configuration: { help: { documents = [] } }, guardian, currentOrganization } = getters
    const profile = currentOrganization.profile.name
    const level = currentOrganization.profile.level

    const items = documents
      .filter(d => d)
      .filter(d => category ? d.category === category : true)
      .filter(d => subcategory ? d.subcategory === subcategory : true)
      .filter(d => (d.levels || []).length > 0 ? d.levels.includes(level) : true)
      .filter(d => (d.profiles || []).length > 0 ? d.profiles.includes(profile) : true)
      .filter(d => guardian.canUseAny(d.permissions))

    return items
  },

  /**
   * Retrieves the first document available to the current user,
   * optionally matching the specified category
   * @param {String} category Optional category of document to retrieve
   * @param {String} subcategory Optional subcategory of document to retrieve
   * @returns {Document} Document matching the criteria
   */
  async getDocument ({ dispatch }, { category, subcategory } = {}) {
    const documents = await dispatch('getDocuments', { category, subcategory })
    return (documents || [])[0]
  },

  /**
   * Returns true if there are documents available to the current user,
   * optionally matching the specified category,
   * @param {String} category Optional category of document to check
   * @param {String} subcategory Optional subcategory of document to check
   * @returns {Boolean}
   */
  async hasDocuments ({ dispatch }, { category, subcategory } = {}) {
    const documents = await dispatch('getDocuments', { category, subcategory })
    return documents.length > 0
  },

  /**
   * Returns true if there is a document available to the current user,
   * optionally matching the specified category,
   * @param {String} category Optional category of document to check
   * @param {String} subcategory Optional subcategory of document to check
   * @returns {Boolean}
   */
  async hasDocument ({ dispatch }, { category, subcategory } = {}) {
    const document = await dispatch('getDocument', { category, subcategory })
    return Boolean(document)
  },

  /**
   * Retrieves videos available to the current user,
   * optionally matching the specified category,
   * @param {String} category Category of videos to retrieve
   */
  async getVideos ({ getters }, { category } = {}) {
    const { configuration: { help: { videos = [] } }, guardian, currentOrganization } = getters
    const profile = currentOrganization.profile.name
    const level = currentOrganization.profile.level

    return videos
      .filter(d => category ? d.category === category : true)
      .filter(d => (d.levels || []).length > 0 ? d.levels.includes(level) : true)
      .filter(d => (d.profiles || []).length > 0 ? d.profiles.includes(profile) : true)
      .filter(d => guardian.canUseAny(d.permissions))
  },

  /**
   * Returns true if there are videos available to the current user,
   * optionally matching the specified category,
   * @param category Category of videos to retrieve
   */
  async hasVideos ({ dispatch }, { category } = {}) {
    const videos = await dispatch('getVideos', { category })
    return videos.length > 0
  },

  /**
   * Retrieves the specified terms-and-conditions document
   * @param id Identifier of a document to retrieve
   */
  async getTermsAndConditions ({ getters }, { id } = {}) {
    if (getters.configuration) {
      const termsAndConditions = getters.configuration.security.termsAndConditions.items
      const terms = termsAndConditions.find(i => id ? i.id === id : i.isDefault)
      if (terms) {
        const { url } = terms
        const response = await fetch(url, { cache: 'no-store' })
        if (response.ok) {
          const text = await response.text()
          const converter = new MarkdownConverter()
          const html = converter.makeHtml(text)

          return {
            terms,
            text,
            html
          }
        }
      }
    }
  },

  /**
   * User approves terms and conditions
   */
  async approveTermsAndConditions ({ dispatch, getters }, { terms } = {}) {
    const { currentUser, currentOrganization } = getters
    if (currentUser && currentOrganization && terms) {
      const name = `terms-and-conditions-approved-${terms.id}`
      const value = true
      await dispatch('storeUserPreference', { name, value })
      return SecurityAPI.approveTermsAndConditions({
        id: terms.id,
        name: terms.name,
        time: new Date()
      })
    }
  },

  /**
   * Watches various application updates,
   * such as platform software update, customer announcements,
   * application feature flag changes etc.
   */
  async watchApplicationUpdates ({ state, getters, commit, dispatch }) {
    // Poll for platform software updates
    if (state.applicationUpdate.clock) {
      state.applicationUpdate.clock.stop()
    }
    const clock = createClock({ name: 'application-updates', frequency: 60000 })
    commit('watchApplicationUpdates', { clock })
    clock.addAlert({ name: 'application-updates', interval: 1 })
    clock.addAlertListener('application-updates', async () => await dispatch('checkIfApplicationUpdated'))
    await clock.runAlert('application-updates')
    await clock.start()

    // Subscribe to PUSH messages with application flags updates
    // Create push listener for common application messages
    const listener = new CommonPushClient()
    commit('addPushListener', { listener, name: 'application' })
    const { configuration } = getters
    if (listener && configuration.services.push.endpoints.common) {
      await listener.initialize(configuration.services.push)
      const subscribed = await listener.subscribeToApplicationFlags({
        onMessage: message => {
          const flags = new ApplicationFlags(message.data)
          commit('applicationFlagsChanged', { flags })
        }
      })
      if (!subscribed) {
        commit('removePushListener', { listener, name: 'application' })
        Log.warn('Could not subscribe to application flags')
        return
      }
    }
  },

  /**
   * Stops polling for application updates,
   * telling user to refresh when update has been deployed
   */
  async unwatchApplicationUpdates ({ state, commit }) {
    if (state.applicationUpdate.clock) {
      commit('unwatchApplicationUpdates')
    }
  },

  /**
   * Checks whether application has been updated in the meantime
   */
  async checkIfApplicationUpdated ({ commit, getters }) {
    const { isLoggedIn, isNewVersionAvailable } = getters
    if (isLoggedIn) {
      // Get application version unless waiting now for reload
      if (!isNewVersionAvailable) {
        try {
          const response = await fetch('package.json', { cache: 'no-store' })
          if (response.ok) {
            const pkg = await response.json()
            if (pkg && pkg.version && getters.pkg && getters.pkg.version !== pkg.version) {
              Log.debug(`Application has been updated from v.${getters.pkg.version} to v${pkg.version}`)
              await delay(15000)
              commit('applicationUpdated', { version: pkg.version })
            }
          }
        } catch (error) {
          Log.error('Error checking for application updates')
          Log.exception(error)
        }
      }
    }
  }
}
