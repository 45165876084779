<script>
import { mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const dialog = 'building-picker'

export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  data () {
    return {
      dialog,
      // Dialog title
      title: 'Select a building',
      // OK button label
      okLabel: 'OK',
      // Cancel button label
      cancelLabel: 'Cancel',
      // Buildings to select from
      buildings: [],
      // Selected building
      building: null,
      // List of buildings filtered by user's entry
      filteredList: []
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      this.building = null

      // Pass on initial data
      this.buildings = this.data.buildings
      this.filteredList = [...this.buildings]
      this.title = this.data.title || this.title
      this.okLabel = this.data.okLabel || this.okLabel
      this.cancelLabel = this.data.cancelLabel || this.cancelLabel

      if (this.buildings.length === 1) {
        this.building = this.buildings[0]
      }
    },

    // Validates and OKs the dialog
    async ok () {
      const { building } = this
      const data = { building }
      this.dialogOk({ dialog, data })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    },

    // Filter function
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.filteredList = [...this.buildings]
        })
        return
      }

      update(() => {
        const input = val.toLowerCase()
        this.filteredList = this.buildings.filter(building => building.name.toLowerCase().includes(input))
      })
    }
  }
}

</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form class="form">

      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-lg">
        <div>
          <q-select v-model="building" :options="filteredList" label="Enter the building name"
            option-value="name" option-label="name" map-options color="teal" outlined square
            options-selected-class="text-deep-orange" class="building-select" use-input
            @filter="filterFn">
          </q-select>
        </div>

        <footer class="row q-mt-xl">
          <q-space></q-space>
          <q-btn :label="cancelLabel" unelevated class="q-mr-md" @click="cancel()">
          </q-btn>
          <q-btn :label="okLabel" unelevated class="primary" :disabled="!building" @click="ok()">
          </q-btn>
        </footer>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  .title {
    font-size: 18px;
  }

  .building-select {
    min-width: 350px;
  }

  footer {
    .q-btn {
      min-width: 80px;
    }
  }
}
</style>