import { PlannerMode, PlanFloors } from '@stellacontrol/planner'
import { createState } from './planner.state'

/**
 * Stores plans of the current organization
 * @param {Array[Plan]} plans Plans to store
 */
export function storePlans (state, { plans = [] } = {}) {
  state.plans = plans
}

/**
 * Stores a plan
 * @param {Plan} plan Plan to store
 */
export function storePlan (state, { plan } = {}) {
  if (plan) {
    const index = state.plans.findIndex(item => item.id === plan.id)
    if (index === -1) {
      state.plans.push(plan)
    } else {
      state.plans[index] = plan
    }
    if (state.plan?.id === plan.id) {
      state.plan = plan
    }
  }
}

/**
 * Selects the plan for editing
 * @param {Plan} plan Plan to edit
 * @param {String} floorId Floor to edit
 */
export function editPlan (state, { plan, floorId } = {}) {
  state.plan = plan
  if (state.plan) {
    floorId = floorId || state.floorId || PlanFloors.CrossSection
    state.floorId = plan.layout.hasFloor(floorId) ? floorId : PlanFloors.CrossSection
  } else {
    state.floorId = null
  }
}

/**
 * Selects the plan floor for editing
 * @param {String} floorId Floor identifier
 */
export function editPlanFloor (state, { floorId } = {}) {
  if (state.plan) {
    state.floorId = floorId || PlanFloors.CrossSection
  } else {
    state.floorId = null
  }
}

/**
 * Resets the state to original shape.
 * @description
 * Presence of this mutation is obligatory on stores,
 * to ensure purging of any user-bound data when
 * user logs in and out and in. Otherwise expect
 * unexpected side effects with data of the previously
 * logged in user appearing at places.
 */
export function reset (state) {
  Object.assign(state, createState())
}

export function resetPlans (state) {
  Object.assign(state, createState())
}

/**
 * Sets the planner editing mode
 * @param {PlannerMode} mode Planner editing mode
 */
export function setPlannerMode (state, { mode } = {}) {
  state.mode = mode || PlannerMode.Regular
}

/**
 * Maximizes the plan editor / restores to normal view
 * @param {Boolean} isMaximized Planner view size
 */
export function setPlannerView (state, { isMaximized } = {}) {
  state.isMaximized = isMaximized
}

/**
 * Stores the items recently selected on the plan
 * @param {Array[PlanItem]} items Selected items
 */
export function storePlanSelection (state, { items = [] } = {}) {
  state.recentlySelectedItems = items.map(item => item.id)
}
