<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { Device, getDeviceLabel } from '@stellacontrol/model'
import { DevicePanelTabMixin } from '../device-panel-tab-mixin'

export default {
  mixins: [
    Secure,
    FormMixin,
    DevicePanelTabMixin
  ],

  data () {
    return {
      // Composite device, representing all selected devices
      // for the purpose of editing alert configuration
      composite: null,
      // Configurations of selected devices
      configurations: [],
      // Last known status of the device
      status: null,
      // Toggle states of alert configuration panes
      expandedConfigurations: {},
      // Indicates that some device configurations were changed and can now be saved
      isModified: false,
      // Pending saves
      pendingSave: []
    }
  },

  computed: {
    ...mapState({
      // Recent status of devices
      deviceStatus: state => state.deviceStatus.devices || {}
    }),

    ...mapGetters([
      'isBusyWith',
      'isNavigating',
      'currentOrganization',
      'defaultAlertConfigurations',
      'isAlertPermitted'
    ]),

    // Checks whether we're loading the data
    isLoading () {
      return this.isBusyWith('load')
    },

    // Checks whether we're saving the data
    isSaving () {
      return this.isBusyWith('save')
    },

    // If viewing organization requires premium subscriptions,
    // returns `true` when some of selected devices don't have active
    // premium subscription for `alerts` feature
    noActiveSubscription () {
      const { currentOrganizationGuardian } = this
      if (currentOrganizationGuardian.requiresPremiumSubscription('alerts')) {
        return this.devices.some(device => !device.canUse('alerts'))
      } else {
        return false
      }
    },

    // Checks whether any of the devices are currently unsold
    devicesUnsold () {
      const { devices } = this
      return devices.some(device => !device.isSold)
    },

    // Checks whether devices have the same owner
    devicesHaveSameOwner () {
      const { devices } = this
      return devices.every(device => device.ownerId == devices[0].ownerId)
    },

    // Checks whether Alert Permission is granted to current organization
    hasAlertsPermission () {
      return this.canUse('alerts')
    },

    // Checks whether alerts can be monitored at all on this device
    devicesCanTriggerAlerts () {
      const { devices } = this
      return devices.every(device => device.canTriggerAlerts)
    },

    // Checks whether alerts can be configured on this device
    // by the currently logged in user:
    // - Devices must be owned by the same owner
    // - Devices must be able to trigger alerts
    // - Organization must have permissions to use alerts
    // - Owner must have active premium service enabling alerts,
    //   or premium service must not be required by the viewing organization
    alertsAvailable () {
      return this.devicesHaveSameOwner &&
        !this.devicesUnsold &&
        !this.noActiveSubscription &&
        this.hasAlertsPermission &&
        this.devicesCanTriggerAlerts
    },

    // Indicates whether current user is allowed to enable and disable the alerts
    canMute () {
      return this.canUse('alerts-configure')
    },

    // Indicates whether current user is allowed to configure the alerts
    canConfigure () {
      return this.canUse('alerts-configure')
    },

    // Returns true if all alerts are currently disabled
    allMuted () {
      return this.configurations.every(configuration => !configuration.isEnabled)
    },

    // Returns true if device configurations have been changed,
    // compared to the defaults
    hasModifiedConfigurations () {
      return this.configurations.some(configuration => configuration.isModified)
    }
  },

  methods: {
    ...mapActions([
      'busy',
      'done',
      'getDeviceAlertConfigurations',
      'saveDeviceAlertConfigurations'
    ]),

    getDeviceLabel,

    async initialize () {
      await this.busy({ action: 'load ' })
      // Get alert configurations for the selected devices
      this.isModified = false
      if (this.alertsAvailable) {
        const { device } = this
        if (device) {
          this.configurations = await this.getDeviceAlertConfigurations({ device })
          this.composite = new Device(this.device)
          this.isModified = this.isBatch
          this.status = this.isBatch
            ? null
            : this.deviceStatus[device.serialNumber]
        }
      }
      // In batch mode always allow saving, too much hassle to resolve diffs
      await this.done()
    },

    // Resets device configurations to defaults
    reset () {
      this.isModified = true
      this.configurations = Object
        .values(this.defaultAlertConfigurations)
        .map(c => c.clone())
    },

    // Toggles status of all alerts between enabled and disabled
    async toggle (isEnabled) {
      this.isModified = true
      this.configurations = this.configurations.map(configuration => {
        configuration.isEnabled = isEnabled
        return configuration.clone()
      })
      await this.save()
    },

    // Update edited configuration
    async update (configuration) {
      if (configuration) {
        const index = this.configurations.findIndex(c => c.alertType === configuration.alertType)
        if (index > -1) {
          const isModified = configuration.wasCustomized(this.configurations[index])
          if (isModified) {
            this.isModified = true
            this.configurations[index] = configuration
            this.save()
          }
        }
      }
    },

    // Saves changes to alert configurations
    async save () {
      if (await this.validate()) {
        // Get configurations to save
        const { devices, configurations, pendingSave } = this
        const configurationsToSave = configurations.map(c => c.clone())

        // If there's already a save in progress, leave.
        // It will be executed sequentially.
        pendingSave.push(configurationsToSave)
        if (pendingSave.length > 1) {
          return
        }

        // Save all queued configurations
        let savedConfigurations
        while (pendingSave.length > 0) {
          const configurations = pendingSave[0]
          savedConfigurations = await this.saveDeviceAlertConfigurations({
            configurations,
            devices,
            silent: true
          })
          pendingSave.splice(0, 1)
        }

        // Update the UI
        for (const configuration of configurations) {
          const savedConfiguration = savedConfigurations.findIndex(c => c.alertType === configuration.alertType)
          const index = this.configurations.findIndex(c => c.alertType === configuration.alertType)
          if (savedConfiguration && index > -1) {
            this.configurations[index] = configuration
          }
        }

        this.isModified = false
      }
    }
  },

  watch: {
    device () {
      this.initialize()
    }
  },

  async created () {
    this.initialize()
  }
}
</script>

<template>
  <main class="device-alerts">
    <div v-if="isLoading && !isNavigating">
      <q-inner-loading :showing="true">
        <span class="text-gray-9 title q-mb-md">
          Loading alert configurations of {{ devicesLabel }} ...
        </span>
        <q-spinner-gears size="48px" color="grey-8"></q-spinner-gears>
      </q-inner-loading>
    </div>

    <q-form v-else-if="alertsAvailable" class="form" ref="form">

      <div class="toolbar row items-center q-mb-md">
        <span>
          Configure alerts
        </span>
        <q-space></q-space>
        <q-btn v-if="canMute" :label="allMuted ? 'Unmute All' : 'Mute All'" no-caps no-wrap dense
          unelevated :ripple="false" @click="toggle(allMuted)">
          <sc-tooltip v-if="allMuted">
            Click to reactivate all previously muted alerts
          </sc-tooltip>
          <sc-tooltip v-else>
            Click to mute all alerts for {{ devicesLabel }}.
            You will no longer receive alert
            notifications.
          </sc-tooltip>
        </q-btn>
        <q-btn v-if="canConfigure" :disabled="!hasModifiedConfigurations" label="Reset to defaults"
          no-caps no-wrap dense unelevated :ripple="false" class="q-ml-sm" @click="reset()">
          <sc-tooltip>
            Click to reset alert configurations
            of {{ devicesLabel }} back to default values.
          </sc-tooltip>
        </q-btn>
      </div>

      <div class="alerts">
        <sc-alert-configuration :device="composite" :isBatch="devices.length > 1" :status="status"
          :configurations="configurations" :expandedConfigurations="expandedConfigurations"
          @update="update">
        </sc-alert-configuration>
      </div>

    </q-form>

    <q-banner v-else class="bg-orange-5 row items-center">
      <div v-if="devicesUnsold">
        Unsold devices are not monitored for alerts.
      </div>
      <div v-else-if="!devicesHaveSameOwner">
        Batch alert configuration is available only
        when all devices belonging to the same owner.
      </div>
      <div v-else-if="!hasAlertsPermission">
        Your organization does not have permission to use alerts.
      </div>
      <div v-else-if="!devicesCanTriggerAlerts">
        Alerts functionality is not available on {{ devicesLabel }}.
      </div>
      <div v-else-if="noActiveSubscription">
        Premium services are not activated on {{ devicesLabel }}.
      </div>
      <div v-else>
        Alert monitoring is not available for this device.
      </div>
    </q-banner>
  </main>
</template>

<style lang="scss" scoped>
.device-alerts {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .toolbar {
      flex: 0;
      display: flex;
      flex-direction: row;
    }

    .alerts {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      border: solid #afb5d6 1px;
    }
  }
}
</style>
