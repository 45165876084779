import { Log, runBatch, processBatch, wait } from '@stellacontrol/utilities'
import { Notification, Confirmation } from '@stellacontrol/client-utilities'
import { getDevicesDescription } from '@stellacontrol/model'
import { DeviceCommands, DeviceCommand, DeviceCommandClient, FastSamplingSpeed } from '@stellacontrol/devices'

export const actions = {
  /**
   * Sends a command to the specified devices
   * @param {DeviceCommand} command Command to send, a DeviceCommand instance
   * @param {Array[Device]} devices List of devices to send the command to
   * @param {Dictionary<String, DeviceStatus>} status Devices' status, needed to determine whether some commands are applicable
   * @param {Number} batchSize Maximal amount of devices to poll in one query, when retrieving status of multiple devices
   * @param {Number} pause Pause between batches, in ms
   * @param {Boolean|String|Function} notificationExecuting Notification to show to the user before executing the command.
   * Set to true to show default notification.
   * Set to string or function returning string, to show custom notification.
   * Otherwise no notification is shown.
   * The function will receive devices and command as parameters.
   * @param {Boolean|String|Function} notificationExecuted Notification to show to the user after executing the command.
   * @param {Boolean|String|Function} notificationFailed Notification to show to the user after executing the command.
   * @param {Boolean} silent Turns off all UI notitications, regardless of the above
   * @returns {Array[DeviceCommandResult]} List of results of sending the command to each device
   */
  async sendDeviceCommand ({ commit, getters, dispatch }, { command, devices = [], status = {}, batchSize = 10, pause = 0, notificationExecuting, notificationExecuted, notificationFailed, silent } = {}) {
    if (
      !command ||
      !canSendDeviceCommand({ commit, getters, dispatch }, { command, devices, status, batchSize, pause, notificationExecuting, notificationExecuted, notificationFailed, silent })
    ) return

    // Find command definition
    const definition = getters.getDeviceCommandDefinition(command.name)
    if (!definition) throw new Error(`Command definition not found for [${command.name}]`)

    // Filter out only these devices to which the command is applicable
    devices = devices.filter(device =>
      device && device.canReceiveCommands && getters.isDeviceCommandAvailable(definition, [device], status))
    if (devices.length === 0) return

    // Prepare notification messages
    const identification = getDevicesDescription(devices)
    const confirmationMessage = (definition.conditions.confirmation || '')
      .replace('${identification}', identification)
    const sendingMessage = notificationExecuting === true
      ? `Sending command ${definition.label.toUpperCase()} to ${identification}`
      : (typeof notificationExecuting === 'function' ? notificationExecuting(devices, command) : undefined)
    const sentMessage = notificationExecuted === true
      ? `Command ${definition.label.toUpperCase()} executed`
      : (typeof notificationExecuted === 'function' ? notificationExecuted(devices, command) : undefined)
    const failedMessage = notificationFailed === true
      ? `Command ${definition.label.toUpperCase()} failed`
      : (typeof notificationFailed === 'function' ? notificationFailed(devices, command) : undefined)

    // Ask if confirmation is required
    const description = definition.description ? `\n\n${definition.description}.` : ''
    const yes = confirmationMessage
      ? await Confirmation.ask({ message: `${confirmationMessage}${description}` })
      : true
    if (!yes) {
      return
    }

    const hideProgress = Notification.progress({ message: sendingMessage, silent: silent || !sendingMessage })

    // If only simulated devices, wait a little otherwise this will be instantenous
    // and notifications won't be visible
    if (devices.every(d => d.isSimulatedDevice)) {
      await wait(2000)
    }

    const commandService = new DeviceCommandClient()
    const results = await processBatch(
      {
        items: devices,
        handler: async batch => {
          const results = await commandService.sendCommand(batch, command) || []
          const succeeded = results.filter(r => r.success)
          const failed = results.filter(r => r.error)
          if (succeeded.length > 0) {
            commit('deviceCommandSent', {
              devices: succeeded.map(({ id, serialNumber }) => ({ id, serialNumber })),
              command,
              results: succeeded
            })
            Log.debug(`Command [${command.name}] sent`, succeeded)
          }
          if (failed.length > 0) {
            commit('deviceCommandFailed', {
              devices: failed.map(({ id, serialNumber }) => ({ id, serialNumber })),
              command,
              results
            })
            Log.debug(`Command [${command.name}] failed`, failed)
          }
          return results
        },
        batchSize,
        pause
      }
    )

    // Start fast sampling for a while, if the command requires it
    if (definition.conditions.watchStatus) {
      await dispatch('startFastSampling', { devices, silent })
    } else // Ping the devices, if the command requires it
      if (definition.conditions.pingStatus) {
        await dispatch('pingDevice', { devices, silent })
      }

    hideProgress()
    const succeeded = results.filter(r => r.success)
    const failed = results.filter(r => r.error)
    if (succeeded.length > 0) {
      Notification.success({ message: sentMessage, silent: silent || !sentMessage })
    }
    if (failed.length > 0) {
      Notification.error({ message: failedMessage, silent: silent || !failedMessage })
    }

    return results || []
  },

  /**
   * Sends PING command to the specified devices
   * @param devices List of devices to send the command to
   * @param silent If true, no notifications are shown to the user about the progress of the command.
   */
  async pingDevice ({ dispatch }, { devices = [], silent = true }) {
    const command = DeviceCommand.ping()
    return dispatch('sendDeviceCommand', { devices, command, silent })
  },

  /**
   * Sends START_FAST_SAMPLING command to the specified devices
   * @param devices List of devices to send the command to
   * @param fastSamplingSpeed Fast sampling speed
   * @param fastSamplingDuration Fast sampling duration
   * @param silent If true, no notifications are shown to the user about the progress of the command.
   */
  async startFastSampling ({ dispatch }, { devices, fastSamplingSpeed = FastSamplingSpeed.OneSecond, fastSamplingDuration = 60, silent = true }) {
    if (devices?.length > 0) {
      Log.debug(`Fast sampling enabled for ${fastSamplingDuration}s`, devices)
      const command = DeviceCommand.startFastSampling(fastSamplingSpeed, fastSamplingDuration)
      return dispatch('sendDeviceCommand', { devices, command, silent })
    }
  },

  /**
   * Sends RECALIBRATE command to the specified devices
   * @param devices List of devices to send the command to
   * @param silent If true, no notifications are shown to the user about the progress of the command.
   */
  async recalibrate ({ dispatch }, { devices = [], silent = true }) {
    const command = DeviceCommand.recalibrate()
    await dispatch('sendDeviceCommand', { devices, command, silent })
  },

  /**
   * Turns off all bands of the specified devices.
   * We need to create send this command one by one,
   * as devices might have different bands
   * @param device Single device to turn off the bands
   * @param devices List of devices to turn off the bands
   * @param silent If true, no notifications are shown to the user about the progress of the command.
   */
  async shutdownBands ({ dispatch }, { devices = [], device, silent = true }) {
    devices = device ? [...devices, device] : devices
    if (devices.length === 0) return

    const query = devices
      .map(device => {
        const command = DeviceCommand.shutdownBands(device)
        return () => dispatch('sendDeviceCommand', { devices: [device], command, silent })
      })

    const results = await runBatch(query)
    return results
  },

  /**
   * Turns on all bands of the specified devices
   * We need to create send this command one by one,
   * as devices might have different bands
   * @param device Single device to turn on the bands
   * @param devices List of devices to turn on the bands
   * @param silent If true, no notifications are shown to the user about the progress of the command.
   */
  async turnOnBands ({ dispatch }, { devices = [], device, silent = true }) {
    devices = device ? [...devices, device] : devices
    if (devices.length === 0) return

    const query = devices
      .map(device => {
        const command = DeviceCommand.turnOnBands(device)
        return () => dispatch('sendDeviceCommand', { devices: [device], command, silent })
      })

    const results = await runBatch(query)
    return results
  }
}

// Applies side-effect before sending the command and returns whether the command is allowed to be sent
function canSendDeviceCommand (access, description) {
  switch (description.command.name) {
    case DeviceCommands.StartFastSampling:
      return canSendFastSamplingCommand(access, description)
    default:
      return true
  }
}

// Makes sure that the user has enough fast-sampling slots, register the devices as fast-sampling with a timeout
function canSendFastSamplingCommand ({ getters, commit }, { command, devices }) {
  if (
    getters.availableFastSamplingSlots >= devices.length ||
    getters.guardian.isSuperAdministrator
  ) {
    commit('startFastSamplingCountdown', { devices, fastSamplingDuration: command.parameters.FAST_SAMPLING_WINDOW_SECS })
    return true
  } else {
    const maxSlots = getters.getStatusWatchSettings('building-dashboard').fastSamplingMaxCount
    Notification.warning({ message: `You can use fast sampling on max. ${maxSlots} devices at a time` })
    return false
  }
}