import { Log, delay, countString, distinctValues } from '@stellacontrol/utilities'
import { Notification, Confirmation } from '@stellacontrol/client-utilities'
import { getDeviceLabel, getDevicesDescription, DeviceRegionDescription, AuditItem, AuditAction } from '@stellacontrol/model'
import { DeviceSettingsClient, DeviceStatusClient, DeviceRegionSwitcher } from '@stellacontrol/devices'
import { CommonAPI } from '@stellacontrol/client-api'
import { getMegaParameter, getMegaParameterLabel } from '@stellacontrol/mega'

export const actions = {
  /**
   * Updates device settings
   * @param {Device} device Device to update
   * @param {Array[String]} parameters Shadow parameters to update
   * @param {Number} retry Number of attempts to update the settings, if they're not reconciled at first attempt
   * @param {Number} retryInterval Interval between update attempts, in milliseconds
   * @param {Boolean} peekStatus If true, we're peeking device status for a while, to confirm that update has been applied
   * @param {Boolean} silent If true, no UI notifications are displayed
   * @emits deviceSettingsUpdated Mutation to notify about the updated device settings.
   */
  async updateDeviceSettings ({ dispatch, getters }, { device, parameters = {}, retry = 1, retryInterval = 1000, silent = false, peekStatus = true } = {}) {
    if (!device) throw new Error('Device is required')

    let isError
    let notify

    try {
      notify = await dispatch('busy', { message: 'Updating device settings ...', silent })
      const deviceDescription = getDeviceLabel(device)
      const parameterDescription = distinctValues(Object
        .keys(parameters)
        .map(name => getMegaParameterLabel(name, device)))
        .join(', ')

      const settingsService = new DeviceSettingsClient()

      // If ship mode is being changed, clear any custom value for rebalance frequency,
      // as device will enforce new rebalance frequency, appropriate for the current mode
      if ('_ship_setaws' in parameters) {
        await settingsService.clearSettings(device, ['_timer_long_mins'])
      }

      // Apply new settings
      const { result, error } = await settingsService.updateSettings(device, parameters, retry, retryInterval) || {}

      // Watch status updates for a while, so we can receive feedback about the changed settings
      if (peekStatus) {
        await dispatch('peekDeviceStatus', { device })
      }

      if (error) {
        isError = true
        Log.error(`[${deviceDescription}] Could not change ${parameterDescription}`, error)

      } else if (result) {
        const details = `[${deviceDescription}] ${parameterDescription} has been changed`
        const changes = Object.entries(parameters).map(([name, value]) => `${name}: ${value}`).join('\n')
        Log.details(device.serialNumber, 'Settings updated', parameters)

        const auditItem = AuditItem.forDevice({
          actor: getters.currentUser,
          action: AuditAction.ConfigureDevice,
          device,
          details,
          changes
        })
        CommonAPI.audit(auditItem)
      }

      notify()

      return { result, error }

    } finally {
      await dispatch('done', {
        message: isError ? undefined : 'New settings were sent to device.',
        details: isError ? undefined : 'It may take a while before they\'re applied.',
        error: isError ? 'Device settings could not be changed' : undefined,
        silent
      })
    }
  },

  /**
   * Clears customized device settings (desired shadow)
   * @param {Device} device Device to reset
   * @param {Array[String]} parameters Shadow parameters to clear. If not specified, all custom (desired) values in device shadow will be cleared
   * @param {Boolean} peekStatus If true, we're peeking device status for a while, to confirm that update has been applied
   * @param {Boolean} silent If true, no confirmations or UI notifications
   * @param {String} confirmation Custom confirmation message to ask
  */
  async clearCustomDeviceSettings ({ dispatch }, { device, parameters, peekStatus = true, confirmation, silent = false } = {}) {
    if (!device) throw new Error('Device is required')

    let isError
    let notify

    try {
      const deviceDescription = getDeviceLabel(device)
      const parameterDescription = distinctValues((parameters || [])
        .map(name => getMegaParameterLabel(name, device)))
        .join(', ')
      confirmation = confirmation ||
        (parameterDescription
          ? `Return control of ${parameterDescription} back to the device?`
          : 'Return control of all settings back to the device?')
      const yes = silent ? true : await Confirmation.ask({ message: confirmation })

      if (yes) {
        notify = await dispatch('busy', { message: 'Updating device settings ...', silent })
        const settingsService = new DeviceSettingsClient()
        const { result, error } = await settingsService.clearSettings(device, parameters)

        if (peekStatus) {
          await dispatch('peekDeviceStatus', { device })
        }

        if (error) {
          const message = parameterDescription
            ? `[${deviceDescription}] Could not clear custom value of ${parameterDescription}`
            : `[${deviceDescription}] Could not clear custom values on ${deviceDescription}`
          Log.error(message, error)
          isError = true

        } else {
          const message = parameterDescription
            ? `[${deviceDescription}] Control of ${parameterDescription} has been returned back to the device`
            : `[${deviceDescription}] Control of all settings has been returned back to the device`
          Log.debug(message)
        }

        notify()
        return { result, error }

      }

    } finally {
      if (isError) {
        await dispatch('done', { error: 'Control could not be returned back to the device' })
      } else {
        await dispatch('done', { message: 'Control has been returned back to the device' })
      }
    }
  },

  /**
   * Copies applicable device settings from one device to another
   * @param {Device} source Source device
   * @param {Device} target Target device
   * @param {Array[String]} parameters List of parameters to copy. Band parameters can be specified without band suffixes, eg. just `_shutdown`.
   * If not specified explicitly, a default list of parameter is used which includes all band settings, LPAS-related settings, LCD settings
   * and message frequency.
   * @param {Boolean} peekStatus If true, we're peeking device status for a while, to confirm that update has been applied
   * @param {String} confirmation Confirmation message to ask
   */
  async copyDeviceSettings ({ dispatch }, {
    source,
    target,
    parameters = [
      '_mgn_dw',
      '_shutdown',
      '_timer_long_mins',
      '_tft_hostname',
      '_ship_setaws',
      '_ship_auto_switchoff',
      '_ship_auto_switchoff_led_level',
      '_ship_check_bands_every',
      '_pin',
      '_lcd_dim',
      '_lcd_lock',
      '_dl_atten_group'
    ],
    peekStatus = true,
    confirmation,
    silent = false } = {}) {

    const yes = silent || !confirmation
      ? true
      : await Confirmation.ask({ message: confirmation })

    if (yes) {
      // Make sure that only parameters actually applicable
      // to the target device are included
      const applicableParameters = parameters
        .map(name => getMegaParameter(name, target))
        .filter(parameter => parameter.isApplicable)
        .map(p => p.name)

      const statusService = new DeviceStatusClient()
      const sourceSettings = (await statusService.getDeviceStatus({ devices: [source] }) || [])[0]
      const targetSettings = (await statusService.getDeviceStatus({ devices: [target] }) || [])[0]
      const settings = {}

      if (sourceSettings?.reported && targetSettings?.reported) {
        for (const key in sourceSettings.reported) {
          const isApplicable = applicableParameters.some(s => key.startsWith(s))
          const currentValue = sourceSettings.custom[key] == null ? sourceSettings.reported[key] : sourceSettings.custom[key]
          const isDifferent = targetSettings.reported[key] != currentValue
          if (isApplicable && isDifferent) {
            settings[key] = currentValue
          }
        }
      }

      if (Object.keys(settings).length > 0) {
        await dispatch('updateDeviceSettings', {
          device: target,
          parameters: settings,
          silent: true
        })

        if (peekStatus) {
          await dispatch('peekDeviceStatus', { device: target })
        }

      }
    }
  },

  /**
   * Resets all custom settings on the specified bands of the device
   * @param {Device} device Device to reset
   * @param {Boolean} clearShadow If true, the reset settings are then cleared from the device shadow
   * @param {Number} batchSize Maximal amount of devices to poll in one query, when resetting multiple devices
   * @param {Boolean} peekStatus If true, we're peeking device status for a while, to confirm that update has been applied
   * @param {String} confirmation Custom confirmation message to ask
   */
  async resetDeviceBands ({ dispatch }, { device, confirmation, clearShadow, peekStatus = true, silent = false } = {}) {
    if (!device) throw new Error('Device is required')

    let message
    let notify

    try {
      const deviceDescription = getDeviceLabel(device)
      confirmation = confirmation || `Reset bands on ${deviceDescription} to factory settings?`
      const yes = silent ? true : await Confirmation.ask({ message: confirmation })

      if (yes) {
        notify = await dispatch('busy', { message: 'Updating device settings ...', silent })

        const settingsService = new DeviceSettingsClient()
        const { result, error } = await settingsService.resetSettings(device, clearShadow)

        if (peekStatus) {
          await dispatch('peekDeviceStatus', { device })
        }

        if (error) {
          message = `[${deviceDescription}] Could not reset bands to factory values`
          Log.error(message, error)
        } else {
          message = `[${deviceDescription}] Bands have been reset to factory values`
          Log.debug(message)
        }

        notify()
        return result
      }

    } finally {
      await dispatch('done', { message })
    }
  },

  /**
   * Assigns PIN and other lock screen settings on the specified device
   * @param {Device} device Device to configure
   * @param {String} PIN Device PIN to assign
   * @param {Boolean} peekStatus If true, we're peeking device status for a while, to confirm that update has been applied
   */
  async lockDevice ({ dispatch }, { device, pin, peekStatus = true, silent = false } = {}) {
    if (!device) throw new Error('Device is required')
    if (!pin) throw new Error('PIN is required')

    const deviceDescription = getDeviceLabel(device)
    const message = `Change lock screen settings on ${deviceDescription}?`
    const yes = silent ? true : await Confirmation.ask({ message })

    if (yes) {
      try {
        await dispatch('busy', { message: 'Updating device settings ...', silent })
        const settingsService = new DeviceSettingsClient()
        const parameters = { '_pin': pin }
        const { result, error } = await settingsService.updateSettings(device, parameters)

        if (peekStatus) {
          await dispatch('peekDeviceStatus', { device })
        }

        if (error) {
          const message = `[${deviceDescription}] Could not change lock screen settings`
          Notification.error({ message, silent })
          Log.error(message, error)
        } else {
          const message = `[${deviceDescription}] Lock screen settings have been changed`
          Log.debug(message)
          Notification.success({ message, silent })
        }

        return result

      } finally {
        await dispatch('done', { message })
      }
    }
  },

  /**
   * Makes sure that device settings are reconciled with the device shadow
   * @param {Device} device Device to reconcile
   * @param {Boolean} peekStatus If true, we're peeking device status for a while, to confirm that update has been applied
   * @returns {Promise<DeviceSettings>} Device settings after eventual reconciliation
   */
  async reconcileDeviceSettings ({ dispatch }, { device, peekStatus = true } = {}) {
    if (!device) throw new Error('Device is required')

    const settingsService = new DeviceSettingsClient()
    const { result, error, warning } = await settingsService.reconcileSettings(device) || {}

    if (peekStatus) {
      await dispatch('peekDeviceStatus', { device })
    }

    if (error) Log.error(`[${device.serialNumber}]: ${error}`)
    else if (warning) Log.warn(`[${device.serialNumber}]: ${warning}`)
    else if (result) Log.debug(`[${device.serialNumber}]: ${result.message}`)
    return result
  },

  /**
   * Assigns PIN and other lock screen settings on the specified devices
   * @param {Array[Device]} devices Devices to configure
   * @param {String} pin Device PIN to assign
   * @param {Number} lcdBrightness Device LCD brightness level
   * @param {Number} lcdMode Device LCD opeation mode
   * @param {Boolean} peekStatus If true, we're peeking device status for a while, to confirm that update has been applied
   */
  async lockDevices ({ dispatch }, { devices, pin = '', lcdBrightness, lcdMode, peekStatus = true, silent = false } = {}) {
    if (!devices || !(devices.length > 0)) throw new Error('Devices are required')

    const deviceDescription = getDevicesDescription(devices)
    const message = `Change lock screen settings on ${deviceDescription}?`
    const yes = silent ? true : await Confirmation.ask({ message })

    if (yes) {
      const settingsService = new DeviceSettingsClient()
      const parameters = {}

      if (pin.trim()) {
        parameters['_pin'] = pin
      }
      if (lcdBrightness != null) {
        parameters['_lcd_dim'] = lcdBrightness
      }
      if (lcdMode != null) {
        parameters['_lcd_lock'] = lcdMode
      }

      if (Object.keys(parameters).length > 0) {
        const { result, error } = await settingsService.updateSettingsMany(devices, parameters)

        if (peekStatus) {
          await dispatch('peekDeviceStatus', { devices })
        }

        if (error) {
          const message = `[${deviceDescription}] Could not change lock screen settings`
          Notification.error({ message, silent })
          Log.error(message, error)
        } else {
          const message = `[${deviceDescription}] Lock screen settings have been changed`
          Log.debug(message)
          Notification.success({ message, silent })
        }
        return result
      }
    }
  },

  /**
   * Indicates that we're in process of changing region of the specified devices
   * @param {Device} device Device whose region is currently being changed
   * @param {Array[Device]} devices Alternatively a list of devices whose region is currently being changed
   * @param {DeviceRegion} region Region to assign devices to
   * @param {Boolean} peekStatus If true, we're peeking device status for a while, to confirm that update has been applied
   * @param {Boolean} silent If true, no notifications will be shown
   * @param {Boolean} confirm If true, user has to confirm before proceeding
   */
  async changeRegionOfDevices ({ commit, dispatch }, { device, devices = [], region, peekStatus = true, silent, confirm } = {}) {
    if (!region) return

    // Take connected devices and actual boards only
    devices = (device ? [device] : (devices || []))
      .flatMap(d => d.isMultiDevice ? d.parts || [] : [d])
      .filter(d => d.isConnectedDevice)

    if (devices.length === 0) return

    const regionDescription = DeviceRegionDescription[region]
    let message = `Change RF region to ${regionDescription}?`
    const yes = silent ? true : await Confirmation.ask({ message, confirm })

    if (yes) {
      const device = devices[0]
      message = devices.length === 1
        ? `${device.serialNumber}: Changing RF Region to ${regionDescription} ...`
        : `Changing RF Region to ${regionDescription} ...`

      await dispatch('busy', { message, silent })
      commit('startChangingRegionOfDevices', { devices, region })

      const service = new DeviceRegionSwitcher()
      const results = await service.setRegionMany(
        devices,
        region,
        ({ devices, step, message, results }) => {
          Log.debug(`RF Region change in progress, ${countString(devices, 'device')} [${step}] ${message || ''}`, results)
        },
        ({ devices, step, error, results }) => {
          Log.warn(`RF Region change failed for ${countString(devices, 'device')} at [${step}]`, results)
          Log.warn(error.message)
        })

      const succeeded = (results || []).filter(r => !r.error)
      const failed = (results || []).filter(r => r.error)
      const errors = failed
        .map(item => `${item.device.serialNumber}: ${item.error.message}`)
        .join('\n')
      const failedMessage = failed.length === 0
        ? ''
        : `Changing RF Region to ${regionDescription} failed:\n ${errors}`
      const succeededMessage = succeeded.length === 0
        ? ''
        : `RF region has been changed to ${regionDescription}`

      await delay(3000)

      if (failedMessage) {
        await dispatch('done', { error: failedMessage, silent })
      } else {
        await dispatch('done', { message: succeededMessage, silent })
      }

      if (peekStatus) {
        await dispatch('peekDeviceStatus', { device })
      }

      commit('finishChangingRegionOfDevices', { failed })

      return results
    }
  }
}
