<script>
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  props: {
    counters: {
    }
  },

  computed: {
    // Indicates whether any counters are available
    hasCounters () {
      const { counters: { month, day, hour } = {} } = this
      return (month != null || day != null || hour != null)
    },

    // Items to display
    items () {
      const { counters: { month, day, hour } = {} } = this
      if (month != null && day != null && hour != null) {
        const counters = [
          { label: 'Last 31 Days', value: month },
          { label: 'Last Day', value: day },
          { label: 'Last Hour', value: hour }
        ]
        const items = [
          { text: 'Messages', class: { label: true } },
          ...counters
            .filter(c => c.value != null)
            .map(c => ({
              text: `
              <div class="counter-item">
                <span>${c.label}</span>
                <span>${c.value}</span>
              </div>
            `
            }))
        ]
        return items
      }
      return []
    },

    // Widget icon
    icon () {
      return 'bar_chart'
    },

    // Color of the widget icon
    color () {
      return 'blue-7'
    }
  }
}
</script>

<template>
  <sc-widget-text v-if="hasCounters" class="widget-message-counters" :icon="icon" :iconColor="color"
    iconMargin="0" :items="items">
  </sc-widget-text>
</template>

<style lang="scss" scoped>
:deep(.counter-item) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

:deep(.counter-item span:nth-child(1)) {
  font-size: 14px;
}

:deep(.counter-item span:nth-child(2)) {
  color: #165d9c;
  font-size: 14px;
}
</style>
