import { isToday, subDays, differenceInDays } from 'date-fns'
import { AlertsAPI } from '@stellacontrol/client-api'
import { Log, createClock } from '@stellacontrol/utilities'
import { AlertStatisticsPartKey } from '@stellacontrol/model'

/**
 * Triggered when user session has been succesfully started
 * Initializes the ALERTS view
 */
export async function sessionStarted ({ commit, dispatch, getters }) {
  // Retrieve previously stored filter
  const organizationId = await dispatch('getUserPreference', { name: 'alert-view-filter-organization', defaultValue: getters.currentOrganization.id })
  // If TO was TODAY the last time, just keep showing the same number
  // of recent days instead of loading hard dates, otherwise the user
  // will have to manually change the date range each day
  const recent = await dispatch('getUserPreference', { name: 'alert-view-filter-recent' })
  const from = recent
    ? subDays(new Date(), recent)
    : await dispatch('getUserPreference', { name: 'alert-view-filter-from', converter: value => value ? new Date(value) : new Date() })
  const to = recent
    ? new Date()
    : await dispatch('getUserPreference', { name: 'alert-view-filter-to', converter: value => value ? new Date(value) : new Date() })
  const all = await dispatch('getUserPreference', { name: 'alert-view-filter-all' })
  const part = await dispatch('getUserPreference', { name: 'alert-view-filter-part' })
  const filter = await dispatch('getUserPreference', { name: 'alert-view-filter-details' })

  commit('filterAlertStatistics', { organizationId, from, to, all })
  commit('filterAlertStatisticsDetails', { part, filter })
}

/**
 * On route changes stop any refreshing of the currently displayed statistics
 */
export async function navigationStarted ({ dispatch }) {
  dispatch('stopRefreshAlertStatistics')
}

/**
 * Applies new filter to the alert statistics
 * @param {Organization} organization Organization whose alert statistics to return
 * @param {Date} from Period start
 * @param {Date} to Period end
 * @param {Boolean} all Include all child organizations in the statistics
 */
export async function filterAlertStatistics ({ dispatch, commit, getters, state }, { organization, from, to, all } = {}) {
  const { statisticsFilter: filter, part, refresh } = state

  // If no organization selected yet, assume the current organization
  let organizationId
  if (organization) {
    organizationId = organization.id
  } else if (!state.statisticsFilter.organizationId) {
    organizationId = getters.currentOrganization.id
  }

  const autoRefresh = refresh.clock != null
  commit('filterAlertStatistics', { organizationId, from, to, all })

  await dispatch('storeUserPreferences', {
    items: [
      { name: 'alert-view-filter-organization', value: filter.organizationId },
      { name: 'alert-view-filter-from', value: filter.from?.getTime() },
      { name: 'alert-view-filter-to', value: filter.to?.getTime() },
      { name: 'alert-view-filter-recent', value: isToday(filter.to) ? differenceInDays(filter.to, filter.from) : 0 },
      { name: 'alert-view-filter-all', value: filter.all },
      { name: 'alert-view-filter-part', value: part },
      { name: 'alert-view-filter-details', value: filter.detailsFilter[part] }
    ]
  })

  const frequency = getters.isProductionEnvironment ? 600 : 30
  await dispatch('loadAlertStatistics', { autoRefresh, frequency })

}

/**
 * Applies new filters to the details of alert statistics
 * @param {AlertStatisticsPart} part Alert statistics part to load
 * @param {String} filter Filter to apply
 */
export async function filterAlertStatisticsDetails ({ commit, dispatch, state }, { part, filter } = {}) {
  const partChanged = part && state.part !== part
  commit('filterAlertStatisticsDetails', { part, filter })

  await dispatch('storeUserPreferences', {
    items: [
      { name: 'alert-view-filter-part', value: part },
      { name: 'alert-view-filter-details', value: filter }
    ]
  })

  if (partChanged) {
    await dispatch('loadAlertStatisticsDetails', {})
  }
}

/**
 * Retrieves alert statistics using the current filter
 * @param {Organization} organization Organization whose alert statistics to return
 * @param {Date} from Period start
 * @param {Date} to Period end
 * @param {Boolean} all Include all child organizations in the statistics
 * @param {Boolean} autoRefresh If true, automatic refreshing will commence
 * @param {Boolean} isRefreshing Indicates that the call was due to autorefresh, thus avoid UI notifications
 */
export async function loadAlertStatistics ({ dispatch, commit, state, getters }, { autoRefresh, isRefreshing } = {}) {
  try {
    commit('suspendRefreshingAlertStatistics')
    const { part, statisticsFilter: { organizationId, from, to, all } } = state
    const organization = { id: organizationId }

    if (organizationId && from && to) {
      await dispatch('busy', { action: isRefreshing ? 'refresh' : 'load-statistics' })
      const statistics = await AlertsAPI.getAlertStatistics({ organization, from, to, all })
      commit('storeAlertStatistics', { statistics, isRefreshing })
      if (part != null && !isRefreshing) {
        await dispatch('loadAlertStatisticsDetails', { part })
      }
      if (autoRefresh) {
        const frequency = getters.isProductionEnvironment ? 60 * 30 : 30
        await dispatch('refreshAlertStatistics', { frequency: frequency || state.refresh.frequency })
      }
      commit('resumeRefreshingAlertStatistics')
    }
  } catch (error) {
    Log.error('Error loading alert statistics')
    Log.exception(error)
    commit('storeAlertStatisticsError', { error })
  } finally {
    await dispatch('done')
  }
}

/**
 * Retrieves the details of the recently viewed alert statistics
 * @param {Boolean} isRefreshing Indicates that the call was due to details autorefresh, thus avoid UI notifications
 */
export async function loadAlertStatisticsDetails ({ dispatch, commit, state, getters }, { isRefreshing } = {}) {
  try {
    const { part, statistics, statisticsFilter: { organizationId, from, to, all } } = state
    const organization = { id: organizationId }
    if (part && statistics) {
      await dispatch('busy', { action: isRefreshing ? 'refresh' : 'load-part' })
      commit('suspendRefreshingAlertStatistics')
      commit('storeAlertStatisticsPart', { part })
      const statistics = await AlertsAPI.getAlertStatistics({ organization, from, to, all, details: true, parts: part })
      if (statistics) {
        // Filter out devices which currently don't have active premium subscription for Alerts!
        const { currentOrganizationGuardian, devices } = getters
        hideProtectedAlertStatistics(currentOrganizationGuardian, statistics, devices)
        commit('storeAlertStatisticsDetails', { part, statistics, isRefreshing })
      }
      commit('resumeRefreshingAlertStatistics')
    }
  } catch (error) {
    Log.error('Error loading alert statistics')
    Log.exception(error)
    commit('storeAlertStatisticsError', { error })
  } finally {
    await dispatch('done')
  }
}

/**
 * Starts automatic refreshing of the currently viewed alert statistics
 * @param {Number} frequency Alert statistics will be automatically refreshed at specified frequency (in seconds)
 */
export async function refreshAlertStatistics ({ dispatch, commit, state }, { frequency = 60 } = {}) {
  dispatch('stopRefreshAlertStatistics')
  if (frequency > 0) {
    const clock = createClock()
    clock.addAlert({ name: 'refresh', interval: frequency })
    clock.addAlertListener('refresh', async () => {
      const { part } = state
      await dispatch('loadAlertStatistics', { isRefreshing: true })
      if (part) {
        await dispatch('loadAlertStatisticsDetails', { isRefreshing: true })
      }
    })
    commit('refreshAlertStatistics', { clock, frequency })
    clock.start()
  }
}

/**
 * Stops automatic refreshing of the currently viewed alert statistics
 */
export async function stopRefreshAlertStatistics ({ commit }) {
  commit('refreshAlertStatistics')
}

/**
 * Triggered after alert has been deleted, refreshes the statistics
 */
export async function alertsDeleted ({ dispatch, state, getters }) {
  if (getters.currentRouteName === 'alerts') {
    const { part } = state
    await dispatch('loadAlertStatistics', { isRefreshing: true })
    if (part) {
      await dispatch('loadAlertStatisticsDetails', { isRefreshing: true })
    }
  }
}


/**
 * Removes data about devices without active ALERTS premium service
 * from alert statistics
 * @param {Guardian} guardian Viewing organization's guardian
 * @param {Array[AlertStatistics]} statistics Alerts statistics per organization
 * @param {Devices} devices All devices available to current organization
 */
function hideProtectedAlertStatistics (guardian, statistics, devices) {
  if (!devices) return
  if (!statistics) return

  const allowedDevices = devices
    .filter(d => d.canUse('alerts'))
    .reduce((all, { serialNumber }) => ({ ...all, [serialNumber]: true }), {})

  for (const organizationStatistics of statistics) {
    // If viewing alert details, hide alerts to which the organization does not have permissions
    if (organizationStatistics.details.alerts) {
      organizationStatistics.details.alerts = organizationStatistics.details.alerts.filter(item =>
        guardian.isAlertPermitted(item.alertType))
      organizationStatistics.counters.alerts = organizationStatistics.details.alerts.length
    }

    if (guardian.requiresPremiumSubscription('alerts')) {
      for (const part of Object.values(AlertStatisticsPartKey)) {
        // Hide details associated with devices which don't have active PS subscription
        let partDetails = organizationStatistics.details[part]
        if (partDetails) {
          partDetails = partDetails.map(item => {
            item.isPermitted = Boolean(allowedDevices[item.serialNumber])
            return item
          })
        }
        organizationStatistics.details[part] = partDetails
      }
    }
  }
}
