import { getDurationString, clone } from '@stellacontrol/utilities'
import { applyDeviceRules } from '@stellacontrol/mega'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Edited configuration of a specific alert type
    configuration: {
      type: Object,
      required: true
    },
    // Default configuration for a specific alert type
    defaultConfiguration: {
      type: Object,
      required: true
    },
    // If true, more advanced configuration values will be shown,
    // usually accessible only to super administrator
    advancedConfiguration: {
      type: Boolean,
      default: false
    },
    // Device in whose context the configuration is edited
    device: {
      type: Object
    },
    // Last known device status
    status: {
      type: Object
    },
    // Indicates whether we're editing configuration for a batch of devices
    isBatch: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    // Applied configuration, after resolving device-specific rules
    appliedConfiguration () {
      const { configuration, device } = this
      if (configuration && device) {
        const appliedConfiguration = configuration.when
          ? applyDeviceRules(device, clone(configuration), configuration.when)
          : configuration
        return appliedConfiguration
      }
    },

    // Edited alert type
    alertType () {
      return this.configuration?.alertType
    },

    // Determines whether edited alert type is supported on the selected device
    isSupported () {
      return !this.appliedConfiguration?.isSupported
    },

    // Determines whether edited alert type is muted
    isMuted () {
      return !this.configuration?.isEnabled
    },

    // Determines whether the user can edit the configuration
    canEdit () {
      return this.canUse('alerts-configure')
    },

    // Determines whether the configuration is actually editable
    isEditable () {
      return this.canEdit && !this.isMuted
    },

    // Duration formatter
    duration () {
      return frequency => {
        const duration = getDurationString(frequency)
        if (duration) {
          if (!duration.endsWith('s') || duration.includes(' ')) {
            return duration
          } else {
            return ''
          }
        }
      }
    },

    /**
     * Checks if specified property of the configuration is
     * different than the default value
     * @param property Property name or expression returning the value
     * @param format Optional function formatting the value into human-readable message
     */
    isNotDefault () {
      return (property, format) => {
        const value = typeof property === 'function'
          ? property(this.configuration)
          : this.configuration[property]
        const defaultValue = typeof property === 'function'
          ? property(this.defaultConfiguration)
          : this.defaultConfiguration[property]
        if (value != defaultValue) {
          return format ? format(defaultValue) : `Default threshold is ${defaultValue}.`
        } else {
          return ''
        }
      }
    },

    /**
     * Checks if specified property of the configuration
     * has been modified, as compared to the parent or defaults
     * @param property Property name or expression returning the value
     * @param format Optional function formatting the value into human-readable message
     */
    isModified () {
      return (property, format) => {
        const { defaultConfiguration } = this
        const value = typeof property === 'function'
          ? property(this.configuration)
          : this.configuration[property]
        const parentValue = typeof property === 'function'
          ? property(defaultConfiguration)
          : (defaultConfiguration || {})[property]
        if (value != parentValue) {
          return format ? format(parentValue) : `Default value is ${parentValue}.`
        } else {
          return ''
        }
      }
    }
  }
}
