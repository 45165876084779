import { Log } from '@stellacontrol/utilities'
import { EntityType } from '@stellacontrol/model'
import { PushClient } from './push-client'

/**
 * Push notification client for Planner API
 */
export class PlannerPushClient extends PushClient {
  constructor () {
    super()
    this.name = 'planner'
  }

  /**
   * Push services configuration
   * @type {Object}
   */
  configuration

  /**
   * Initializes the push client
   * @param {Object} configuration Push services configuration
   */
  initialize (configuration) {
    if (!configuration) throw new Error('Push services configuration is required')
    if (!configuration.endpoints) throw new Error('Push services endpoint configuration is required')
    if (!configuration.endpoints.planner) throw new Error('Planner Push Server endpoint is required')

    this.configuration = configuration
  }

  /**
   * Checks whether the push client has been initialized
   * @returns {Boolean}
   */
  get isInitialized () {
    return Boolean(this.configuration)
  }

  /**
   * Subscribes to plan updates
   * @param {Array[Plan]} plans Plans to whose updates to subscribe
   * @param {Function<Plan>} onMessage Handler called when plan update arrives
   * @param {Function<Error>} onError Optional custom handler for websocket errors
   * @param {Number} reconnectAttempts If greater than zero, the connection will be automatically restored when lost, until reconnection attempts run out
   * @param {Number} reconnectInterval Interval between reconnect attempts, in milliseconds
   * @returns {PlannerPushClient} Client instance if succesfully subscribed to plan updates
   */
  async subscribeToPlanUpdates ({ plans, onMessage, onError, reconnectAttempts = PushClient.RECONNECT_INFINITE, reconnectInterval = PushClient.RECONNECT_INTERVAL } = {}) {
    if (!this.isInitialized) throw new Error('Planner Push Client is not initialized')
    if (!onMessage) throw new Error('Plan update handler is required')
    if (!(plans?.length > 0)) return

    // Subscribe to updates of the specified plans only
    const url = `${this.configuration.endpoints.planner}/push/subscribe`
    const delay = 1000
    const message = {
      items: plans.map(({ id }) => ({
        dataType: EntityType.Plan,
        entityType: EntityType.Plan,
        entityId: id
      }))
    }

    const subscribed = await this.subscribe({
      url,
      message,
      delay,
      onMessage,
      onError,
      authenticate: true,
      reconnectAttempts,
      reconnectInterval
    })

    if (subscribed) {
      Log.debug(`[${url}] Subscribed to plan updates`)
      return this
    }
  }
}
