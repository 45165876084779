<script>
import { mapGetters, mapActions } from 'vuex'
import { Tag, TagCategory } from '@stellacontrol/model'

export default {
  props: {
    devices: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'configuration'
    ]),

    // Tags applicable to devices
    availableTags () {
      return Object
        .entries(this.configuration.entities.tag.items)
        .filter(([name, tag]) => name && tag.categories.includes('device'))
        .map(([name, tag]) => ({ name, ...tag }))
    }
  },

  methods: {
    ...mapActions([
      'addDeviceTag',
      'removeDeviceTag'
    ]),

    // Returns true if tag is currently set for all the selected devices
    isOn ({ name, isPrivate }) {
      return this.devices.every(device => device.hasTag(new Tag({
        name,
        userId: isPrivate ? this.currentUser.id : undefined
      })))
    },

    // Toggles the specified tag on all devices
    async toggle ({ name, isPrivate }) {
      const tag = new Tag({
        category: TagCategory.Device,
        name,
        userId: isPrivate ? this.currentUser.id : undefined
      })
      const isOn = this.isOn({ name, isPrivate })
      for (const device of this.devices) {
        if (isOn) {
          await this.removeDeviceTag({ device, tag })
        } else {
          await this.addDeviceTag({ device, tag })
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="row q-gutter-sm">
      <q-btn v-for="tag of availableTags" :key="tag.name"
        round size="10px"
        :outline="!isOn(tag)"
        :icon="isOn(tag) ? tag.iconOn : tag.iconOff"
        :color="isOn(tag) ? tag.colorOn : tag.colorOff"
        @click.stop="toggle(tag)">
        <sc-tooltip :text="tag.label">
        </sc-tooltip>
      </q-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>