import Konva from 'konva'
import { Log } from '@stellacontrol/utilities'

/**
 * Async wrapper for Konva.Image.fromURL
 * @param {String} url URL of the image to load
 * @returns {Promise<Konva.Image>}
 */
export function createImageShape (url) {
  if (!url) {
    Log.error('Plan image URL is required', url)
    throw new Error('Plan image URL is required')
  }

  return new Promise((resolve, reject) => {
    Konva.Image.fromURL(
      url,
      image => resolve(image),
      error => {
        Log.error(`Error loading plan image [${url}}]`)
        reject(error)
      }
    )
  })
}