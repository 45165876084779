<script>
import { mapGetters } from 'vuex'
import { sameArrays, formatDate, getUTCDelta, Period, TimeDirection } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import ParameterChart from './parts/parameter-chart.vue'
import ParameterSelector from './parts/parameter-selector.vue'
import ExtrasSelector from './parts/extras-selector.vue'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Chart rendering options
    options: {
      type: Object,
      default: () => { }
    },
    // Custom component title
    title: {
    },
    // Currently viewed device
    device: {
    },
    // Selected period
    period: {
    },
    // Maximal period length, in days
    maxDays: {
      default: 1
    },
    // Parameters to show
    parameters: {
      default: () => []
    },
    // Extras to show, such as alerts, firmware updates etc.
    extras: {
      default: () => []
    },
    // Visibility of the top toolbar
    showToolbar: {
      type: Boolean,
      default: true
    },
    // Visibility of filter
    showFilter: {
      type: Boolean,
      default: true
    },
    // If filter is displayed, determines whether to show it inline with the chart,
    // or on top of the chart as a floating popup
    floatingFilter: {
      type: Boolean,
      default: false
    },
    // Show the parameters selector
    showParametersSelector: {
      type: Boolean,
      default: true
    },
    // Show the extras selector
    showExtrasSelector: {
      type: Boolean,
      default: true
    },
    // Show the label explaining the current timezone
    showTimezoneLabel: {
      type: Boolean,
      default: true
    },
    // Visibility of the reload button
    showReload: {
      type: Boolean,
      default: true
    },
    // Initial visibility of the filter
    filterCollapsed: {
    },
    // Maximal height of the chart panel.
    // If not specified, the chart stretches vertically.
    maxHeight: {
    }
  },

  components: {
    'sc-parameter-selector': ParameterSelector,
    'sc-extras-selector': ExtrasSelector,
    'sc-parameter-chart': ParameterChart
  },

  data () {
    return {
      Period,
      TimeDirection,

      // Chart component
      chart: null,
      // Indicates whether the component is still initializing
      isInitializing: true,
      // Indicates whether the chart is currently populating
      isPopulating: false,
      // Indicates whether the filter is now expanded
      isFilterExpanded: true
    }
  },

  computed: {
    ...mapGetters([
      'currentTimezone',
      'isProductionEnvironment'
    ]),

    // Default title
    defaultTitle () {
      const { period } = this
      if (period) {
        return (period.oneDay)
          ? `History | ${formatDate(period.from, 'dd MMMM')}`
          : `History | ${formatDate(period.from, 'dd MMMM')} - ${formatDate(period.to, 'dd MMMM')}`
      }
    },

    // Indicates whether the filter panel should be displayed
    isFilterVisible () {
      return this.showFilter && !this.isInitializing
    },

    // Indicates whether the chart should be displayed
    isChartVisible () {
      return !this.isInitializing
    },

    // Delta between current time and UTC time
    utcDelta () {
      return getUTCDelta()
    },

    // User-friendly description of the viewer's timezone
    currentTimezoneLabel () {
      const { currentTimezone } = this
      if (currentTimezone) {
        return `Event times are according to ${currentTimezone.value}`
      }
    },

    // Timezone of the current organization
    // Checks if settings are correct and the chart can be populated
    canPopulate () {
      const { isPopulating, device, period, parameters, extras } = this
      return !isPopulating &&
        device &&
        period?.isValid &&
        (parameters?.length > 0 || extras?.length > 0) &&
        this.$refs.chart
    },

    // Chart container style
    chartContainerStyle () {
      const style = {}
      return style
    },

    // Determines whether the collapse button is visible
    isFilterToggleButtonVisible () {
      return this.filterCollapsed != null
    }
  },

  emits: [
    'initialized',
    'filter'
  ],

  watch: {
    // Period changed, reload the chart
    period (newValue, currentValue) {
      if (newValue?.sameAs(currentValue)) return
      this.update()
    },

    // Parameters changed, reload the chart
    parameters (newValue, oldValue) {
      if (sameArrays(newValue, oldValue)) return
      this.update()
    },

    // Extras changed, reload the chart
    extras (newValue, oldValue) {
      if (sameArrays(newValue, oldValue)) return
      this.update()
    }
  },

  methods: {
    // Triggered when filter changed
    async filterChanged ({ period, parameters, extras } = {}) {
      this.$emit('filter', { period, parameters, extras })
    },

    // Triggered when chart has been initialized
    chartInitialized () {
      this.$emit('initialized')
    },

    // Loads chart data and populates the chart
    async populate () {
      if (this.canPopulate) {
        this.isPopulating = true
        try {
          await this.$refs.chart.populate()

        } finally {
          if (this.isInitializing) {
            // Signal first-time initialization
            this.isInitializing = false
            this.$emit('initialized')
          }
          this.isPopulating = false
        }
      }
    },

    // Updates the chart after parameters have changed
    update () {
      if (!this.isInitializing) {
        this.$nextTick(async () => {
          await this.populate()
        })
      }
    },

    // Resets chart zoom
    resetZoom () {
      this.$refs.chart?.resetZoom()
    },

    // Hides the floating filter
    hideFloatingFilter () {
      if (this.floatingFilter && this.isFilterExpanded === true) {
        this.isFilterExpanded = false
      }
    }
  },

  created () {
    this.isFilterExpanded = !this.filterCollapsed
  }
}
</script>

<template>
  <div class="device-history-panel" :class="{ 'filter-collapsed': !isFilterExpanded }"
    @click="hideFloatingFilter()">

    <!-- Top toolbar -->
    <header class="toolbar row items-center no-wrap justify-between q-pa-sm" v-if="showToolbar">
      <div class="title text-bold">
        {{ title == null ? defaultTitle : title }}
      </div>
      <div class="title-mobile text-bold">
        History
      </div>
      <div class="row items-center no-wrap">
        <!-- Reload button -->
        <q-btn v-if="showReload" class="button button-reload q-mr-sm" icon="refresh" dense
          unelevated @click="populate()">
          <sc-tooltip>
            Refresh the data
          </sc-tooltip>
        </q-btn>

        <!-- Period selector -->
        <sc-date-range-buttons :model-value="period"
          @update:model-value="period => filterChanged({ period })">
        </sc-date-range-buttons>

        <!-- Filter toggle button -->
        <q-btn v-if="isFilterToggleButtonVisible" class="q-ml-md" icon="settings"
          @click.stop="isFilterExpanded = !isFilterExpanded" dense flat round color="grey-7">
          <sc-tooltip>
            {{ isFilterExpanded ? 'Hide filters' : 'Show filters' }}
          </sc-tooltip>
        </q-btn>
      </div>
    </header>

    <!-- Content-->
    <main class="content">
      <!-- Filter -->
      <div class="filter-container-background" :class="{ visible: isFilterExpanded }">
      </div>

      <aside v-if="isFilterVisible" class="filter-container"
        :class="{ floating: floatingFilter, visible: isFilterVisible }" v-show="isFilterExpanded"
        @click.stop>

        <q-btn class="button-filter-close" size="md" round flat dense icon="close" color="grey-7"
          @click="hideFloatingFilter()">
        </q-btn>

        <div class="filter-parameters q-mb-lg" v-show="showParametersSelector">
          <sc-parameter-selector :model-value="parameters" :device="device"
            @update:model-value="parameters => filterChanged({ parameters })">
          </sc-parameter-selector>
        </div>

        <div class="filter-extras" v-show="showExtrasSelector">
          <sc-extras-selector :model-value="extras" :device="device"
            @update:model-value="extras => filterChanged({ extras })">
          </sc-extras-selector>
        </div>
      </aside>

      <div class="chart-container" :style="chartContainerStyle">
        <main class="chart">
          <sc-parameter-chart ref="chart" :device="device" :period="period" :parameters="parameters"
            :extras="extras" :options="options" @initialized="chartInitialized()">
          </sc-parameter-chart>
        </main>
        <footer class="chart-footer" v-if="showTimezoneLabel">
          <span v-if="currentTimezone && utcDelta !== 0" class="text-body2 text-grey-7">
            {{ currentTimezoneLabel }}
          </span>
          <span v-else-if="!currentTimezone && utcDelta !== 0" class="text-body2 text-grey-7">
            <span>
              The events are shown using Universal Coordinated Time
            </span>
            <span v-if="utcDelta != 0">
              which is {{ duration(utcDelta) }}
              {{ utcDelta > 0 ? 'earlier' : 'later' }}
              than your local time
            </span>
          </span>
        </footer>
      </div>
    </main>
  </div>
</template>

<style lang='scss' scoped>
.device-history-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  --filter-width: 200px;

  .loading {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  header.toolbar {
    display: flex;
    flex-direction: row;
    flex: 0;

    .title {
      font-size: 16px;
      color: #2a2a2a;
    }

    .title-mobile {
      display: none;
    }

    .button {
      width: 28px;
      font-size: 12px;
    }
  }

  main.content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;

    .filter-container-background {
      display: none;
    }

    .filter-container {
      flex: 0;
      flex-basis: var(--filter-width);
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 4px 4px 4px 8px;

      .title {
        position: unset;
        left: unset;
        top: unset;
        display: block;
      }

      .button-filter-close {
        display: none;
      }

      &.floating {
        position: absolute;
        top: 0;
        left: 8px;
        bottom: 8px;
        width: calc(var(--filter-width) + 80px);
        z-index: 1;
        background-color: #f8f8f8;
        padding: 8px;
        box-shadow: 0 0 6px #d4d4d4;
        border: solid #c8c8c8 1px;

        .button-filter-close {
          display: block;
          position: absolute;
          right: 8px;
          top: 8px;
        }
      }
    }

    &.filter-collapsed {
      .title {
        display: block;
        left: 16px;
        top: 8px;
      }

      .filter-container {
        flex-basis: 5px;
        padding: 0;

        .filter-period,
        .filter-parameters,
        .filter-extras {
          overflow: hidden;
          width: 0;
        }
      }
    }

    .chart-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .chart {
        flex-basis: 500px;
      }

      .chart-footer {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: 20px;
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 640px) {
  .device-history-panel {
    --filter-width: 175px;

    header.toolbar {
      .title {
        display: none;
      }

      .title-mobile {
        display: block;
      }

      .button-reload {
        display: none;
      }
    }

    main.content {
      .filter-container-background {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.2;

        &.visible {
          display: block;
        }
      }

      .filter-container.floating {
        position: fixed;
        z-index: 2;
        left: 5vw;
        top: 10vh;
        bottom: unset;
        right: unset;
        width: 90vw;
        height: 85vh;
        border-radius: 4px;
        background-color: white;
      }
    }
  }
}
</style>
