<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { EntityType, MessageType, AttachmentFolder } from '@stellacontrol/model'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { FilesMixin } from './files-mixin'
import ToolbarComponent from './components/toolbar.vue'
import FolderComponent from './components/folder.vue'
import PreviewComponent from './components/preview.vue'
import NotesComponent from './components/file-notes.vue'
import LinkAttachmentsComponent from './components/link-attachments.vue'
import FilePrintDialog from './components/file-print-dialog.vue'

const name = 'files'

export default {
  mixins: [
    ViewMixin,
    FilesMixin
  ],

  components: {
    'sc-files-toolbar': ToolbarComponent,
    'sc-folder': FolderComponent,
    'sc-file-preview': PreviewComponent,
    'sc-file-notes': NotesComponent,
    'sc-link-attachments': LinkAttachmentsComponent,
    'sc-file-print-dialog': FilePrintDialog
  },

  data () {
    return {
      // View name
      name
    }
  },

  computed: {
    ...mapState({
      // All devices available to the current organization
      devices: state => state.devices.devices || [],
      // Selected organization
      organization: (state, getters) => state.attachments.filesView.organization || getters.currentOrganization,
      // Attachment types to show
      showOnly: state => state.attachments.filesView.showOnly || [],
      // Indicates whether any files have been loaded
      hasFiles: state => state.attachments.filesView.attachments.length > 0,
      // Files belonging to the selected organization
      files: state => state.attachments.filesView.attachments || [],
      // Sort order
      sortOrder: state => state.attachments.filesView.sortOrder,
      // File filter
      filter: state => state.attachments.filesView.filter,
      // File age
      age: state => state.attachments.filesView.age,
      // Expanded folders
      expandedFolders: state => state.attachments.filesView.expandedFolders,
      // Expanded file groups
      collapsedGroups: state => state.attachments.filesView.collapsedGroups
    }),

    ...mapGetters([
      'isBusyWith',
      'isDevelopmentEnvironment',
      'organizations',
      'allPlaces'
    ]),

    // Checks whether we're now loading the files of the selected organization
    isLoadingFiles () {
      return this.isBusyWith('load-files')
    },

    // File folders
    folders () {
      const { files, showOnly } = this

      // Apply the attachment type filters if specified
      const typeMatches = (a) => {
        if (showOnly.length === 0) return true
        return showOnly.some(({ type, dataType, entityType, deviceType, reference }) => {
          return (type === undefined || a.type === type) &&
            (dataType === undefined || a.dataType === dataType) &&
            (entityType === undefined || a.entityType === entityType) &&
            (deviceType === undefined || a.deviceType === deviceType) &&
            (reference === undefined || a.reference === reference)
        })
      }

      // Create folders for:
      // - Building-related documents
      // - Network scans
      const placeDocuments = files
        .filter(f => f.entityType === EntityType.Place)
        .filter(f => typeMatches(f))

      const ttResults = files
        .filter(f => f.entityType === EntityType.Device && f.reference === MessageType.Scan)
        .filter(f => typeMatches(f))

      const folders = [
        new AttachmentFolder({
          id: 'place',
          label: 'Building Documents',
          icon: 'home',
          files: placeDocuments
        }),
        new AttachmentFolder({
          id: 'ttscan',
          label: 'Scan Results',
          icon: 'radar',
          files: ttResults
        })
      ].filter(f => !f.isEmpty)

      return folders
    },

    // Files in the folder which are selected
    selectedFiles () {
      return this.folders.flatMap(folder => folder.files.filter(file => file.isSelected))
    },

    // Indicates that some files in the folder are selected
    hasSelectedFiles () {
      return this.selectedFiles.length > 0
    }
  },

  methods: {
    ...mapActions([
      'filterAttachments',
      'loadAttachments',
      'selectAttachment',
      'selectAttachments',
      'sortAttachments',
      'filterAttachments',
      'toggleAttachmentFolder',
      'watchAttachments',
      'unwatchAttachments',
      'linkAttachment'
    ]),

    // Loads the files of the specified organization
    async populate ({ organization } = {}) {
      organization = organization || this.currentOrganization
      await this.filterAttachments({ organization })
      await this.loadAttachments({ organization })
      await this.watchAttachments()
    },

    // Toggles the specified file folder or file group inside the folder  or sets them to the specified state
    toggleFolder ({ folder, group, allGroups, expanded }) {
      this.toggleAttachmentFolder({ folder, group, allGroups, expanded })
    },

    // Toggles the specified files
    selectFiles ({ files, isSelected }) {
      this.selectAttachments({ attachments: files, isSelected })
    },

    // Sorts the files
    sortFiles ({ sortOrder }) {
      this.sortAttachments({ sortOrder })
    },

    // Filters the files
    async filterFiles ({ filter, age }) {
      // Check if serials entered, and if they belong to a different organisation.
      // If so, select that organisation first, then continue with filtering the files.
      if (filter) {
        const serials = filter.split(' ')
        const owner = this.devices.filter(d => serials.includes(d.serialNumber)).map(d => d.ownerId)[0]
        if (owner && owner !== this.organization.id) {
          const organization = this.organizations.find(o => o.id === owner)
          if (organization) {
            this.filterAttachments({ organization })
            await this.populate({ organization })
          }
        }
      }

      this.filterAttachments({ filter, age })
    },

    // Links the specified files to another organization and place
    async linkFiles ({ files }) {
      const attachments = files.filter(file => file.canLinkToPrincipal && file.isScan)
      if (attachments.length === 0) return

      const { isOk, data } = await this.showDialog({
        dialog: 'link-attachments',
        data: { attachments }
      })

      const principal = (isOk && data)
        ? this.allPlaces.find(p => p.id === data.placeId)
        : null
      if (!principal) return

      await this.busy({ message: 'Processing files ... ' })
      try {
        for (const attachment of attachments) {
          await this.linkAttachment({
            attachment,
            principal,
            allowEdit: true,
            allowDelete: true,
            removeExistingLinks: true
          })
        }
        await this.done({
          message: attachments.length === 1
            ? `The file has been assigned to ${principal.name}`
            : `${attachments.length} files have been assigned to ${principal.name}`
        })
      } finally {
        await this.done()
      }
    }
  },

  watch: {
    // Triggered when file list has changed,
    // retains the previous selection intact
    files (current, previous) {
      // Find files which were previously selected and are still present in the file list
      const selected = previous.filter(file => file.isSelected && current.some(f => f.id === file.id))
      // Mark them as selected
      this.selectAttachments({ attachments: selected, isSelected: true })
    }
  },

  async beforeUnmount () {
    await this.unwatchAttachments()
  },

  async created () {
    const { organization } = this
    await this.populate({ organization })
  }
}

</script>

<template>
  <sc-view :name="name">
    <template #header>
      <div class="toolbar q-pa-md bg-white">
        <sc-files-toolbar :isEnabled="!isLoadingFiles" :organization="organization"
          :sortOrder="sortOrder" :filter="filter" :age="age" :selectedFiles="selectedFiles"
          @filter="populate" @remove-files="removeFiles" @sort-files="sortFiles"
          @filter-files="filterFiles" @print-files="printFiles" @link-files="linkFiles">
        </sc-files-toolbar>
      </div>
    </template>

    <div v-if="isLoadingFiles" class="loading">
      <q-inner-loading :showing="true">
        <span class="text-gray-9 title q-mb-md">
          Loading files ...
        </span>
        <q-spinner-gears size="48px" color="grey-8">
        </q-spinner-gears>
      </q-inner-loading>
    </div>

    <div v-else-if="hasFiles" class="folders">
      <sc-folder v-for="folder of folders" :key="folder.id" :folder="folder" :sortOrder="sortOrder"
        :filter="filter" :age="age" :expandedFolders="expandedFolders"
        :collapsedGroups="collapsedGroups" @toggle="toggleFolder" @select="selectFiles"
        @download="downloadFile" @preview="previewFile" @remove="removeFiles" @print="printFiles">
      </sc-folder>
    </div>

    <div v-else class="folders items-center justify-center">
      {{ organization.name }} does not have any files
    </div>

    <sc-file-preview>
    </sc-file-preview>

    <sc-file-notes>
    </sc-file-notes>

    <sc-link-attachments>
    </sc-link-attachments>

    <sc-file-print-dialog>
    </sc-file-print-dialog>

  </sc-view>
</template>

<style lang='scss' scoped>
.loading {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.folders {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .toolbar {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-items: stretch;
  }
}
</style>
