import { numberCompare } from '@stellacontrol/utilities'
import { DeviceBandIdentifier, bandLabelToIdentifier, getBandFrequency } from '@stellacontrol/model'

const DeRatingFactor = {
  [DeviceBandIdentifier.Band07]: 0.6,
  [DeviceBandIdentifier.Band08]: 0.6,
  [DeviceBandIdentifier.Band09]: 0.6,
  [DeviceBandIdentifier.Band18]: 0.6,
  [DeviceBandIdentifier.Band21]: 1.1,
  [DeviceBandIdentifier.Band26]: 1.1
}

const CableParameters = {
  SD240: { gain: -0.248 },
  SD400: { gain: -0.212 },
  SD600: { gain: -0.132 },
  SD900: { gain: -0.088 }
}

/**
 * Extracts the surplus power results from the specified scan
 * @param {Attachment} attachment
 * @returns {Object}
 */
export function getSurplusPower (attachment) {
  if (!attachment?.isScan) return
  if (!attachment.data?.results) return
  const { surplusPowerResults: items } = attachment.data.results
  if (!(items?.length > 0)) return

  // Get individual cell results,
  // Resolve operator, get timing metrics,
  // Check whether they're for the scanning cell or neighboring cell,
  const results = items
    .map(({ bandNumber, timeOffsetMs, timeTakenMs, signalPower, surplusPower }) => {
      const key = `${bandNumber}-${timeOffsetMs}`
      const bandLabel = `B${bandNumber}`
      const band = bandLabelToIdentifier(bandLabel)
      const lossPerM = Math.abs(CableParameters.SD400.gain)
      const cableLength = Math.round(Math.max(1.0, surplusPower / (lossPerM * DeRatingFactor[band])))

      return {
        key,
        band,
        bandLabel,
        timeOffsetMs,
        timeTakenMs,
        signalPower,
        surplusPower,
        cableLength
      }
    })

  // Sort by band number
  results.sort((a, b) => -numberCompare(
    getBandFrequency(a.band),
    getBandFrequency(b.band)
  ))

  return results
}
