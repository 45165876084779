import { PlanFloors } from '@stellacontrol/planner'
import PlannerView from './planner.vue'
import { resolvePlacePlan, resolvePlan } from './planner.resolve'

export const Routes = [
  {
    name: 'building-plan',
    view: 'plan',
    path: '/building/:id/plan',
    component: PlannerView,

    async beforeEnter (to, from, next) {
      const plan = await resolvePlacePlan({ from, to })
      if (plan) {
        next({
          name: 'plan',
          params: {
            id: plan.id,
            floorId: PlanFloors.CrossSection
          }
        })
      } else {
        next(from)
      }
    }
  },

  {
    name: 'plan',
    path: '/plan/:id/:floorId',
    component: PlannerView,

    async beforeEnter (to, from, next) {
      if (await resolvePlan({ from, to })) {
        next()
      }
    }
  }
]
