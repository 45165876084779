<script>
import { mapActions } from 'vuex'
import { PrincipalType } from '@stellacontrol/model'
import { Permissions } from './permissions'
import { ContextComponents } from './context'
import PropagatePermissionDialog from './propagate-permission.vue'

const columns = [
  { name: 'name', label: 'Feature', field: row => row },
  { name: 'permission', label: 'Is allowed', field: row => row },
]

/**
 * Reusable permission editor, which can be used to edit permissions
 * of organization profiles, organizations, users etc.
 *
 * FUNCTIONALITY:
 * - Displays a list of permissions
 * - Permission description and details are shown, can be hidden with `Hide Details` action
 * - Hierarchy of permissions is visualised with simple nesting
 * - Expanding and collapsing of permissions with children, all can be collapsed to top level with `Collapse` action
 * - Support for container permissions, which themselves cannot be granted but only contain other permissions
 * - Permission cannot be granted if one of its secure parents isn't granted.
 *   Such permissions are rendered as disabled.
 * - Permissions for disabled features do not appear at all
 * - Permissions which require a different principal level do not appear at all
 * - Filtering by text, with color indication of the matching text
 * - Some permission have additional context, displayed in a side editor.
 *   Example: permission to create organizations requires a list of profiles which are available when creating a child organization,
 *            otherwise resellers would quickly start creating super-organizations if they could ;-)
 * - When feature becomes available as a result of change of user level or organization profile, we can mark some features
 *   as granted by default, by setting their `default` property to `true`. This way `users` feature will be automatically granted
 *   when only we make user an administrator. Of course, the feature can be then taken away from the user.
 */
export default {
  components: {
    ...ContextComponents,
    'sc-propagate-permission-dialog': PropagatePermissionDialog
  },

  props: {
    // Principal whose permissions are being edited
    principal: {
      type: Object,
      required: true
    },

    // Permissions of the principal
    permissions: {
      type: Permissions,
      required: true
    },

    // If true, the permission tree will be initially collapsed to top-level permissions
    collapsed: {
      type: Boolean,
      value: false
    },

    // If true, editing of default permission values is allowed,
    // for template-like principals such as organization profile
    canEditDefaults: {
      type: Boolean,
      value: false
    }
  },

  data () {
    return {
      columns,
      showDetails: true,
      falseValue: false,
      trueValue: true,
      changedPermission: null,
      permissionGrantedWarning: null,
      permissionRevokedWarning: null
    }
  },

  computed: {
    // Indicates that the currently edited principal is a super organization
    // so it can do anything by default
    isSuperOrganization () {
      return this.principal.isSuperOrganization
    },

    // Indicates that the currently edited principal is an administrator user
    // so his permissions are the same as those of his organization
    isAdministrator () {
      return this.principal.isAdministrator
    },

    // Indicates that the currently edited principal is organization profile
    isOrganizationProfile () {
      return this.principal.isOrganizationProfile
    },

    // Indicates whether the permissions can be edited for the principal
    permissionsAreEditable () {
      return !(this.isSuperOrganization || this.isAdministrator)
    },

    // Currently selected permission
    selectedPermission () {
      return this.permissions.selectedPermission
    },

    // Currently selected permission for context editor
    contextPermission () {
      return this.permissions.selectedPermission
    },

    // Returns true if current principal
    // is an organization allowed to have children
    canHaveChildren () {
      return this.principal.type === PrincipalType.Organization && this.permissions.canUse('child-organizations')
    },

    // Editor for the currently edited permission context
    contextEditor () {
      const { currentOrganization, feature: { context } = {} } = this.contextPermission || {}
      const { name: editorName = 'none', description, level, subject } = context || {}
      const exists = editorName !== 'none' &&
        (!level || level.includes(currentOrganization.level)) &&
        (!subject || subject.includes(currentOrganization.type))

      return {
        exists,
        name: editorName,
        description,
        component: `sc-permissions-context-${editorName}`
      }
    },

    // Checks whether the specified permission should be hidden
    isPermissionHidden () {
      return permission => this.permissions.isHidden(permission)
    },

    // Checks whether the specified permission should be editable
    isPermissionEditable () {
      return permission => this.permissions.isEditable(permission)
    },

    // Checks if specified permission can be propagated to profile members
    canPropagatePermission () {
      return permission => permission &&
        this.principal.type === 'organization-profile' &&
        this.isPermissionEditable(permission) &&
        permission.canUse
    }
  },

  methods: {
    ...mapActions([
      'showDialog',
      'busy',
      'done',
      'updatePermissions'
    ]),

    // Toggles permission details visibility
    toggleDetails () {
      this.showDetails = !this.showDetails
    },

    // Triggered when permission context was edited.
    // Store the context editor input in the permission.
    contextChanged () {
      this.permissions.apply()
    },

    // Triggered when permission has been granted or denied
    permissionChanged (permission, value) {
      const { permissions, isOrganizationProfile } = this
      this.selectPermission(permission)
      this.changedPermission = permission
      permissions.setCanUse(permission, value)

      // Show hints when editing organization profiles
      if (isOrganizationProfile) {
        if (value && this.permissionGrantedWarning == null) {
          this.permissionGrantedWarning = true
          if (this.permissionRevokedWarning) {
            this.permissionRevokedWarning = null
          }
        } else if (!value && this.permissionRevokedWarning == null) {
          this.permissionRevokedWarning = true
          if (this.permissionGrantedWarning) {
            this.permissionGrantedWarning = null
          }
        }
      }
    },

    // Selects the specified permission
    selectPermission (permission) {
      this.permissions.selectPermission(permission)
    },

    // Shows dialog for propagating the permission to profile members
    async propagatePermission (permission) {
      const { principal, canPropagatePermission, showDialog } = this
      if (canPropagatePermission(permission)) {
        const { isOk, data } = await showDialog({ dialog: 'propagate-permission', data: { principal, permission } })
        if (isOk && data) {
          await this.busy({ message: 'Updating permissions, please wait ... ' })
          // Grant to the profile
          await this.updatePermissions({ principal, permissions: [{ ...permission, canUse: true }] })
          // Grant to the selected members of the profile
          for (const id of data.grant) {
            await this.updatePermissions({ principal: { id }, permissions: [{ ...permission, canUse: true }] })
          }
          // Revoke from the selected members of the profile
          for (const id of data.revoke) {
            await this.updatePermissions({ principal: { id }, permissions: [{ ...permission, canUse: false }] })
          }
          await this.done({ message: 'Permissions of the selected organizations have been updated' })
          this.permissionGrantedWarning = null
          this.permissionRevokedWarning = null
        }
      }
    }
  },

  created () {
    // Initially collapse all
    if (this.collapsed) {
      this.permissions.toggleAll()
    }
  }
}
</script>

<template>
  <div class="permissions q-pl-md q-pb-md q-pr-md q-pt-sm">
    <header v-if="permissionsAreEditable">
      <div class="toolbar">
        <div class="buttons">
          <q-btn no-caps no-wrap dense unelevated :ripple="false" class="q-mr-sm" label="Check all"
            icon="check_box" @click="permissions.setCanUseAll(true)">
          </q-btn>
          <q-btn no-caps no-wrap dense unelevated :ripple="false" class="q-mr-sm"
            label="Uncheck all" icon="check_box_outline_blank"
            @click="permissions.setCanUseAll(false)">
          </q-btn>
          <q-btn no-caps no-wrap dense unelevated :ripple="false" class="q-mr-sm"
            :label="showDetails ? 'Hide details' : 'Show details'"
            :icon="showDetails ? 'notes' : 'short_text'" @click="toggleDetails()">
          </q-btn>
          <q-btn no-caps no-wrap dense unelevated :ripple="false" class="q-mr-sm"
            :label="permissions.allCollapsed ? 'Expand' : 'Collapse'"
            :icon="permissions.allCollapsed ? 'chevron_right' : 'expand_more'"
            @click="permissions.toggleAll()">
          </q-btn>
        </div>

        <div class="search">
          <q-input class="input-filter col-6" outlined clearable clear-icon="close"
            v-model.lazy="permissions.filter" debounce="500" label="Search" dense icon="search"
            bg-color="white">
            <template v-slot:prepend>
              <q-icon name="search"></q-icon>
            </template>
          </q-input>
        </div>
      </div>

      <div class="toolbar-right q-ml-lg">
      </div>
    </header>

    <main v-if="permissionsAreEditable" class="q-pt-sm">
      <div class="editor-inner">
        <!-- Permission tree -->
        <div class="permission-tree">
          <q-table flat dense separator="cell" hide-bottom row-key="id"
            :rows="permissions.permissions" :columns="columns" :pagination="{ rowsPerPage: 0 }">

            <template v-slot:header>
              <q-tr v-if="canEditDefaults">
                <th class="feature">
                  <span class="text-bold text-grey-9">
                    Application feature
                  </span>
                </th>

                <th class="propagate">
                </th>

                <th class="can-use">
                  <div>
                    <span class="text-bold text-grey-9">
                      {{ principal.type === 'organization-profile' ? 'Available' : 'Allowed' }}
                    </span>
                    <q-icon name="help" color="grey-6" size="18px" class="q-ml-xs">
                    </q-icon>
                    <sc-tooltip v-if="principal.type === 'organization-profile'"
                      :text="`Indicates whether the feature can be granted, when organization is assigned to ${principal.name} profile`" />
                    <sc-tooltip v-else
                      :text="`Indicates whether the feature is permitted to ${principal.name}`" />
                  </div>
                </th>

                <th class="default-value">
                  <div>
                    <span class="text-bold text-grey-9">
                      Default
                    </span>
                    <q-icon name="help" color="grey-6" size="18px" class="q-ml-xs">
                    </q-icon>
                    <sc-tooltip v-if="principal.type === 'organization-profile'"
                      :text="`Default value for permission, when organization is assigned to ${principal.name} profile`" />
                  </div>
                </th>
              </q-tr>
              <q-tr v-else style="display:none;">
                <th colspan="3"></th>
              </q-tr>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props" :key="props.row.id" :class="{
                collapsible: props.row.hasChildren,
                hidden: isPermissionHidden(props.row),
                disabled: !isPermissionEditable(props.row),
                selected: props.row.isSelected
              }" @click="selectPermission(props.row)">

                <!-- FEATURE NAME -->
                <q-td class="feature"
                  :style="{ 'padding-left': `${8 + (props.row.level - 1) * 30}px` }"
                  @click="permissions.togglePermission(props.row)">

                  <div class="text" style="overflow:hidden">
                    <label v-html="
                  permissions.filter && props.row.matchesFilter
                    ? permissions.getDescriptionWithFilter(props.row, 'color: blue')
                    : permissions.getDescription(props.row)">
                    </label>
                    <q-icon v-if="props.row.hasChildren" class="toggle"
                      :name="props.row.isCollapsed ? 'chevron_right' : 'expand_more'">
                    </q-icon>
                  </div>
                  <div class="details" v-if="showDetails">
                    {{ props.row.feature.details }}
                  </div>
                  <div class="details"
                    v-if="showDetails && props.row.requiredPermissionsText && !isPermissionEditable(props.row)">
                    Requires: <b>{{ props.row.requiredPermissionsText }}</b>
                  </div>
                </q-td>

                <q-td class="propagate">
                  <div class="button" v-if="canPropagatePermission(props.row)">
                    <q-btn dense unelevated label="Grant ..."
                      @click="propagatePermission(props.row)">
                      <sc-tooltip>
                        Grant the permission to organizations in this profile
                      </sc-tooltip>
                    </q-btn>
                  </div>
                </q-td>

                <!-- CAN USE checkbox -->
                <q-td class="can-use" auto-width>
                  <div v-if="props.row.feature.isSecure">
                    <q-checkbox v-if="isPermissionEditable(props.row)"
                      :model-value="props.row.canUse" :dense="!showDetails" color="indigo-5"
                      :ripple="false"
                      @update:model-value="value => permissionChanged(props.row, value)">
                    </q-checkbox>
                    <q-checkbox v-else :model-value="props.row.canUse" color="grey-5" keep-color
                      :dense="!showDetails" :disable="true">
                    </q-checkbox>
                  </div>
                  <div v-else>
                    <q-icon v-if="props.row.feature.context" name="more_horiz" size="sm">
                    </q-icon>
                  </div>
                </q-td>

                <!-- DEFAULT VALUE checkbox -->
                <q-td class="default-value" auto-width v-if="canEditDefaults">
                  <div v-if="props.row.feature.isSecure && isPermissionEditable(props.row)">
                    <q-checkbox v-if="props.row.canUse" v-model="props.row.defaultValue"
                      :dense="!showDetails" color="indigo-5" :ripple="false"
                      @update:model-value="value => permissions.setDefaultValue(props.row, value)">
                    </q-checkbox>
                    <q-checkbox v-else v-model="falseValue" color="grey-5" keep-color
                      :dense="!showDetails" :disable="true">
                    </q-checkbox>
                  </div>
                </q-td>
              </q-tr>
            </template>

          </q-table>
        </div>

        <!-- Permission context editor -->
        <div class="permission-context q-ml-lg" :class="{ visible: contextEditor.exists }">
          <component :is="contextEditor.component" :name="contextEditor.name"
            :title="contextEditor.description" :principal="principal"
            :permission="contextPermission" :permissions="permissions" @context="contextChanged">
          </component>
        </div>
      </div>
    </main>

    <main v-if="!permissionsAreEditable" class="row items-center justify-center" style="flex: 1;">
      <label class="text-subtitle1 q-ml-md" style="margin-bottom: 16px;">
        {{
          isSuperOrganization
          ? 'Super organization has all possible permissions'
          : isAdministrator
          ? 'Administrator has all permissions'
          : 'You cannot edit these permissions'
        }}
      </label>
      <q-icon :name="isSuperOrganization || isAdministrator ? 'mood' : 'error_outline'" size="xl" color="green-7">
      </q-icon>
    </main>

    <footer v-if="permissionsAreEditable && changedPermission" class="q-pt-sm">
      <q-banner v-if="permissionRevokedWarning" inline-actions class="bg-orange-8 text-white">
        Permission {{ uppercase(changedPermission.feature.description) }} will no longer be
        available to any organizations assigned to this profile
        <template v-slot:action>
          <q-btn flat label="Dismiss" color="yellow-2" @click="permissionRevokedWarning = false">
          </q-btn>
        </template>
      </q-banner>
      <q-banner v-if="permissionGrantedWarning" inline-actions class="bg-orange-8 text-white">
        Permission {{ uppercase(changedPermission.feature.description) }} must now be granted
        individually to any organization assigned to this profile
        <template v-slot:action>
          <q-btn flat label="Dismiss" color="yellow-2" @click="permissionGrantedWarning = false">
          </q-btn>
        </template>
      </q-banner>
    </footer>
  </div>

  <sc-propagate-permission-dialog></sc-propagate-permission-dialog>

</template>

<style lang="scss" scoped>
.permissions {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  >header {
    flex: 0;
    display: flex;
    flex-direction: row;

    .toolbar {
      flex: 3;
      display: flex;
      flex-direction: row;


      .buttons {
        flex-basis: 470px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .search {
        flex: 1;
      }
    }

    .toolbar-right {
      flex: 1;
    }
  }

  >main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .editor-inner {
      flex: 1;
      display: flex;
      flex-direction: row;
      overflow: hidden;

      .permission-tree {
        flex: 3;
        display: flex;
        flex-direction: column;
        overflow: auto;
        border: solid silver 1px;
      }

      .permission-context {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        border: solid transparent 1px;

        &.visible {
          border: solid silver 1px;
        }
      }
    }
  }

  >footer {
    flex: 0;
  }

  .q-tr {
    &.hidden {
      display: none;
    }

    &.collapsible {}

    &.selected {
      .q-td {
        background-color: #eff1fa;
      }
    }

    th {
      font-weight: normal;
      text-align: left;
      background-color: #f3f5fd;
      font-size: 14px;

      &.feature {
        padding-left: 8px;
      }

      &.can-use {
        text-align: center;

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }

      &.propagate {
        text-align: center;
        border-left: none;
      }

      &.default-value {
        text-align: center;
        padding-left: 15px;

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .q-td {
      padding: 6px;

      strong.filter-text {
        color: blue;
      }

      &.feature {
        .text {
          font-size: 14px;
        }

        .toggle {
          font-size: 24px;
        }

        .details {
          font-size: 12px;
          color: #505050;
          white-space: normal;
        }
      }

      &.propagate {
        width: 125px;
        border-left: none;

        .button {
          display: none;
        }
      }

      &.can-use {
        text-align: center;
        width: 85px;
      }

      &.default-value {
        text-align: center;
        width: 85px;
      }
    }

    &.disabled {
      .q-td {
        color: grey;

        strong.filter-text {
          color: blue;
        }
      }
    }

    &:hover {
      .q-td {
        &.propagate {
          .button {
            display: block;
          }
        }
      }
    }
  }
}
</style>