import { Log, padLeft } from '@stellacontrol/utilities'

/**
 * Pretty-prints the specified error, using source-mapped stack
 * @param {Error} error Error to print
 */
export async function printError (error) {
  // If network error, print as-is
  let printed = false
  if (!Log.isClientRequestError(error)) {
    const consumer = await getSourceMapConsumer()
    if (consumer) {
      // Find error position in original source file
      const { source: sourceFile, line, column } = consumer.originalPositionFor({
        source: error.fileName,
        line: error.lineNumber,
        column: error.columnNumber
      }) || {}

      // Print error including chunk of code surrounding the error position
      if (sourceFile && line != null && column != null) {
        const chunk = []
        const print = [
          `ERROR: ${error.message}`,
          `in ${sourceFile} at ${line}:${column}`
        ]
        if (error.bugReportId) {
          print.push(`Bug# ${error.bugReportId}`)
        }
        const source = consumer.sourceContentFor(sourceFile) || ''
        const sourceLines = source.split('\n')

        if (sourceLines.length > 0) {
          Log.error('-'.repeat(80))
          const fromLine = Math.max(0, line - 5)
          const toLine = Math.min(line + 5, sourceLines.length - 1)
          for (let i = fromLine; i <= toLine; i++) {
            const row = `${padLeft(i.toString(), 6)}: ${i === line ? '> ' : '  '}${sourceLines[i]}`
            chunk.push(row)
            print.push(row)
          }
          print.push('-'.repeat(80))
        }

        Log.error(print.join('\n'))

        // Enrich the error, UI might want to show it too
        error.details = {
          bundle: {
            fileName: error.fileName,
            line: error.lineNumber,
            column: error.columnNumber
          },
          source: {
            fileName: sourceFile,
            chunk,
            line,
            column
          },
          print: print.join('\n')
        }

        printed = true
      }
    }
  }

  // Fallback - raw error print
  if (!printed) {
    if (error.message) {
      Log.exception(error)
    } else {
      Log.error(error)
    }
  }

  return error
}

/**
 * Initialized source map consumer instance
 */
let sourceMapConsumer

/**
 * Returns an initialized source map consumer
 */
async function getSourceMapConsumer () {
  if (!window.sourceMap) {
    Log.warn('Mozilla source-map library is not loaded.')
    Log.warn('Please check https://github.com/mozilla/source-map for details.')
    return
  }

  if (!sourceMapConsumer) {
    if (window.sourceMap?.SourceMapConsumer?.initialize) {
      window.sourceMap.SourceMapConsumer.initialize({
        'lib/mappings.wasm': 'mappings.wasm'
      })
      const response = await fetch('index.js.map')
      const sourceMap = await response.text()
      sourceMapConsumer = await new window.sourceMap.SourceMapConsumer(sourceMap)
    } else {
      Log.warn('window.sourceMap.SourceMapConsumer.initialize not found', window.sourceMap)
    }
  }

  return sourceMapConsumer
}
