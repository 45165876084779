<script>
import { mapActions } from 'vuex'
import { FormMixin, DialogMixin } from '@stellacontrol/client-utilities'

const dialog = 'plan-snapshot'

export default {
  mixins: [
    FormMixin,
    DialogMixin
  ],

  data () {
    return {
      dialog,
      plan: null,
      name: '',
      description: ''
    }
  },

  computed: {
    // Validates the input
    isValid () {
      const { name } = this
      return name && name.trim() && this.nameIsUnique(name) === true
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      const { data: { plan, description, name } } = this
      this.plan = plan
      this.name = name || plan.name || plan.place?.name || ''
      this.description = description || ''
      this.title = `Create a snapshot of the ${plan.name || '' } plan`
    },

    // Validates and OKs the dialog
    ok () {
      const { plan, name, description } = this
      if (name.trim()) {
        this.dialogOk({ dialog, data: { plan, name, description } })
      }
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    },


    // Validation rule which checks whether the snapshot name is already in use
    nameIsUnique (name) {
      name = name.trim()
      const { plan } = this
      const isUnique = !(plan.snapshots || []).some(s => s.name === name)
      return isUnique || 'Snapshot name must be unique'
    },
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form class="form" ref="form">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-lg">
        <div>
          <q-input v-model="name" dense square outlined maxlength="255" v-focus lazy-rules label="Name"
            :rules="[rules.required('Name is required'), name => nameIsUnique(name)]">
          </q-input>
        </div>

        <div>
          <q-input class="description" v-model="description" type="textarea" dense square outlined label="Details"
            bg-color="white">
          </q-input>
        </div>

        <div class="row q-mt-xl">
          <q-space></q-space>
          <q-btn label="Cancel" unelevated class="q-mr-md" @click="cancel()" />
          <q-btn label="Create" unelevated class="primary" :disabled="!isValid"
            @click="ok()" />
        </div>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 16px;
}

.form {
  min-width: 500px;
}
</style>