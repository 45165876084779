import OPERATORS from '../../assets/plmn.json'

/**
 * Calculates the signal level for the specified value, within the given range
 * @param {Number} value Signal value
 * @param {Number} min Minimal allowed value
 * @param {Number} bad Value below which the signal is seen as 'bad'
 * @param {Number} good Value above which the signal is seen as 'good'
 * @param {Number} max Maximal allowed value
 * @returns {Object} Object with the following properties:
 *  `level`: signal level within range of `0..100`
 *  `range`: qualification of the signal level: `good`, `normal` or `bad`
 */
export function getSignalLevel (value, min, bad, good, max) {
  const normalized = Math.min(max, Math.max(min, value))
  const level = Math.min(100, Math.round(100 * (normalized - min) / (max - min)))
  const range = normalized < bad
    ? 'bad'
    : (normalized > good ? 'good' : 'normal')
  return { level, range }
}

/**
 * Resolves the mobile operator using the specified PLMN value
 * and operators lookup table reported by the device
 * @param {String} plmn PLMN to resolve to operator
 * @param {Dictionary<string, string>} operators Operators lookup table reported by the device, optional
 * @description If operator not found in the {@link operators} lookup, we're using the public database of PLMN
 * @returns {String} Operator name or {@link plmn} value if no match found
 */
export function plmnToOperator (plmn, operators) {
  let operator

  if (plmn) {
    if (operators && operators[plmn] && operators[plmn] !== '-') {
      operator = operators[plmn]
    } else {
      operator = OPERATORS[plmn]
    }
  }

  return operator || plmn
}
