<script>
import { getSurplusPower } from '@stellacontrol/devices'

/**
 * Surplus Power Scan
 */
export default {
  props: {
    // Section collapse status, dictionary
    collapsed: {
      type: Object,
      default: () => { }
    },
    // Operators
    operators: {
      default: () => { }
    },
    // Data file
    file: {
    },
    // Surplus power scan results
    items: {
      default: () => []
    }
  },

  computed: {
    // Surplus power entries to display
    entries () {
      const { file } = this
      const entries = getSurplusPower(file)
      return entries
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    // Toggles a section
    toggle ({ section }) {
      this.$emit('toggle', { section })
    },

    // Populates the view
    populate () {
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <main>
    <table class="item">
      <thead>
        <tr>
          <th class="band">Band</th>
          <th class="signal-power">Signal Power (dBm)</th>
          <th class="surplus-power">Surplus Power (dB)</th>
          <th class="cable-length">Est.Cable Length (m)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry of entries" :key="entry.key">
          <td class="band">
            {{ entry.bandLabel }}
          </td>
          <td class="signal-power">
            {{ entry.signalPower }}
          </td>
          <td class="surplus-power">
            {{ entry.surplusPower }}
          </td>
          <td class="cable-length">
            {{ entry.cableLength }}
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<style lang="scss" scoped>
main {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  flex-wrap: wrap;
  width: 700px;

  table {
    border-collapse: collapse;

    tr {

      td,
      th {
        border: solid #0000001f 1px;
        text-align: left;
        vertical-align: top;
        padding: 8px;
        height: 38px;
        text-wrap: nowrap;
      }

      td.band {
        min-width: 100px;
      }

      th.signal-power,
      th.surplus-power,
      th.cable-length {
        text-align: right;
      }

      td.signal-power,
      td.surplus-power,
      td.cable-length {
        text-align: right;
      }

      &:hover {
        td {
          background-color: #fafafa;

          &.operator {
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>
