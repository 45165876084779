/**
 * Returns a hash of a string.
 * @param {String} s String to calculate the hash for
 * @return {Number} Hash code of the given string
 * @description A fast and simple 64-bit (or 53-bit) string hash function with decent collision resistance.
 * Fork of cyrb53 hash function at github.com/bryc (public domain),
 * inspired by MurmurHash2/3, but with a focus on speed/simplicity.
 */
export function hash (s, seed = 0) {
  if (s == null) {
    return s
  }

  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < s.length; i++) {
    ch = s.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

  const [x2, x1] = [h2 >>> 0, h1 >>> 0]
  return x2.toString(36).padStart(7, '0') + x1.toString(36).padStart(7, '0')
}
