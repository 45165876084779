/* eslint-disable no-console */

import { colorize } from './console'
import { isNodeJS } from './platform'
import { EventListener } from './event-listener'
import { padLeft, padRight } from './string'

/**
 * Log levels
 */
export const LogLevel = {
  ALL: 'all',
  DEBUG: 'debug',
  WARN: 'warn',
  INFO: 'info',
  ERROR: 'error',
  NONE: 'none'
}

/**
 * Log level weights, useful for filtering out
 * events below certain level
 */
export const LogLevelWeight = {
  [LogLevel.ALL]: 5,
  [LogLevel.DEBUG]: 4,
  [LogLevel.WARN]: 3,
  [LogLevel.INFO]: 2,
  [LogLevel.ERROR]: 1,
  [LogLevel.NONE]: 0
}

/**
 * Counter frequencies
 */
export const CounterFrequency = {
  Minute: 'minute',
  FiveMinutes: 'five-minutes',
  Quarter: 'quarter',
  Hour: 'hour',
  SixHours: 'six-hours',
  TwelveHours: 'twelve-hours',
  Day: 'day'
}

/**
 * Log service
 */
export class LogService extends EventListener {
  constructor ({ level, name, enabled, timestamp } = {}) {
    super()
    this.name = name || 'Log'
    this.initialize(level || LogLevel.INFO)
    if (enabled === false) {
      this.disable()
    }
    if (timestamp === true) {
      this.timestampOn()
    }
  }

  /**
   * Indicates that we're using {@link update} to print in the same line.
   * @type {Boolean}
   */
  __inline

  /**
   * Filter for {@link details} messages,
   * assigned with {@link filter} method.
   * @type {String|Array[String]|RegExp|Boolean}
   */
  __detailsFilter

  /**
   * Regex filters to suppress messages.
   * Useful to filter out noise when debugging very specific problems.
   */
  __messagesFilter = {
    /**
     * @type {RegExp} If specified, only messages matching the expression will be printed out
     */
    includeMessages: null,
    /**
     * @type {RegExp} If specified, only messages matching the expression will be printed out
     * If specified, only messages not matching the expression will be printed out
     */
    excludeMessages: null
  }

  /**
   * Log name
   * @type {String}
   */
  name

  /**
   * Timers, where time profiling results are stored
   * @type {Dictionary<String, Object>}
   */
  timers = {}

  /**
   * Counters, where count profiling results are stored
   * @type {Dictionary<String, Object>}
   */
  counters = {}

  /**
   * Messages to ignore, per log level
   * @type {Dictionary<String, Array[String]>}
   */
  ignored = {
    [LogLevel.ERROR]: [
      // Ignore excessive logging of failed HTTP requests
      'ECONNREFUSED'
    ]
  }

  /**
 * Returns a list of supported events
 * @returns {Array[String]}
 */
  get events () {
    return [
      'info',
      'warn',
      'error',
      'exception',
      'debug',
      'trace'
    ]
  }

  /**
   * Initializes the log
   */
  initialize (level, timestamp = false) {
    return this.enable(level, timestamp)
  }

  /**
   * Enable the log, optionally changing the current log level
   * @param level New log level to assign, optional
   * @param timestamp If true, messages will be prefixed with timestamp
   */
  enable (level, timestamp = false) {
    this._enabled = true
    this._level = level || this._level
    this._timestamp = timestamp
  }

  /**
   * Disable the log
   */
  disable () {
    this._enabled = false
  }

  /**
   * Turns timestamps on
   */
  timestampOn () {
    this.timestamp = true
  }

  /**
   * Turns timestamps off
   */
  timestampOff () {
    this.timestamp = false
  }

  /**
   * Gets/sets log enable status
   * @type {Boolean}
   */
  get enabled () {
    return this._enabled
  }

  set enabled (value) {
    if (value) {
      this.enable(this._level)
    } else {
      this.disable()
    }
  }

  /**
   * Gets/sets log timestamp status
   * @type {Boolean}
   */
  get timestamp () {
    return this._timestamp
  }

  set timestamp (value) {
    this._timestamp = value
  }

  /**
   * Gets/sets current log level
   * @type {LogLevel}
   */
  get level () {
    return this._level
  }

  set level (value) {
    const level = value || this._level
    if (level !== this._level) {
      this._level = level
    }
  }

  /**
   * Calls console function
   * @param {String} fn Name of console function to call
   * @param {Array} args Arguments to pass to the console function
   */
  console (fn, ...args) {
    if (fn && typeof console[fn] === 'function') {
      if (this.__inline) {
        const p = this.getProcess()
        if (p) {
          p.stdout.write('\n')
        }
        this.__inline = false
      }
      console[fn](...args)
      return true
    }
  }

  /**
   * Returns current process, when in NodeJS
   */
  getProcess () {
    return (typeof process !== 'undefined') ? process : undefined
  }

  /**
   * Returns true if can log a message at specified level
   * or can log at all
   * @param {LogLevel} level Level to check, optional
   * @returns {Boolean} True if logging is enabled and, if {@link level} is specified,
   * whether the current log level matches it
   */
  canLog (level) {
    return this._enabled && (!level || LogLevelWeight[this._level] >= LogLevelWeight[level])
  }

  /**
   * Checks if the specified error message should be ignored.
   * Some messages are ignored by hard rules specified in {@link ignored} list.
   * Other messages can be filtered out by temporary filter set with {@link filterMessages}.
   * @param {String} message Error message
   */
  isMessageIgnored (message, level) {
    if (!this.canLog(level)) return true
    if (level) {
      // Check against specific messages on that level
      if ((this.ignored[level] || []).some(item => message?.toString().includes(item))) return true
    }
    // Check the general message filters
    return !this.matchesFilter(message)
  }

  /**
   * Adds the specified message to the list of messages ignored at specified log level
   * @param {String} message Error message to ignore
   * @param {LogLevel} level Log level at which the message should be ignored
   */
  ignore (message, level) {
    if (message != null && level) {
      if (!this.ignored[level]) {
        this.ignored[level] = []
      }
      this.ignored[level].push(message.toString())
    }
  }

  /**
   * Prepares a timestamp
   * @returns {String} Timerstamp
   */
  getTimestamp () {
    return new Date().toISOString().substring(0, 19).replace('T', ' ')
  }

  /**
   * Builds a string containing call stack from the specified exception
   * @param {Error} error Error
   * @returns {String}
   */
  getStack (error) {
    if (error?.stack) {
      // Discard useless fastify stack, vue stack, network error stacks
      let stack = error.stack
      const discard = ['at preHandlerCallback', 'vue.min.js', 'Network error']
      for (const item of discard) {
        const index = error.stack.indexOf(item)
        const length = index === -1 ? error.stack.length : index + item.length
        stack = stack.substring(0, length)
      }
      return stack
    }
  }

  /**
   * Checks if error represents a network request error
   * @param {Error} error Error
   * @returns {Boolean}
   */
  isRequestError (error) {
    return (error && error.isNetworkError && error.request)
  }

  /**
   * Returns error message representing a network request error
   * @param {Error} error Error with request details
   * @returns {String}
   */
  getRequestError (error) {
    if (this.isRequestError(error)) {
      const { method, url, params } = error.request
      const { status, statusText } = error.response || {}
      const query = params
        ? Object.entries(params).map(([key, value]) => `${key}=${value || ''}`).join('&')
        : ''
      const requestMessage = `Request failed: ${method.toUpperCase()} ${url}${query === '' ? '' : '?'}${query}`
      const responseMessage = status ? `HTTP ${status} ${statusText || ''}\n${error.message}` : error.message
      return `${requestMessage}\n${responseMessage}`
    }
  }

  /**
   * Prepares a message to log
   * @param {String} text Message to prepare
   * @param {String} prefix Optional prefix to add, unless already present
   * @returns {String} Message to log
   */
  formatMessage (text, prefix) {
    if (text == null) return
    let message = text.toString()
    if (prefix && !message.startsWith(prefix)) {
      message = prefix + message
    }
    if (this.timestamp) {
      message = `${this.getTimestamp()} ${message}`
    }
    return message
  }

  /**
   * Outputs stack trace
   * @param {String} message Message to log, may contain color tags
   */
  trace (message = '') {
    if (!this.isMessageIgnored(message, LogLevel.DEBUG)) {
      let msg = this.formatMessage(message)
      if (isNodeJS) msg = colorize(msg)
      this.console('trace', msg)
      this.emit('trace', { message })
    }
  }

  /**
   * Logs an info message
   * @param {String} message Message to log, may contain color tags
   * @param {Array} data Data to log
   */
  info (message = '', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.INFO)) {
      let msg = this.formatMessage(message)
      if (isNodeJS) msg = colorize(msg)
      hasData(data) ? this.console('info', msg, ...data) : this.console('info', msg)
      this.emit('info', { message, data })
    }
  }

  /**
   * Logs a debug message
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  debug (message = '', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.DEBUG)) {
      const msg = this.formatMessage(message)
      hasData(data) ? this.console('info', msg, ...data) : this.console('info', msg)
      this.emit('debug', { message, data })
    }
  }

  /**
   * Logs a warning message
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  warn (message = '', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.WARN)) {
      let msg = this.formatMessage(message)
      if (isNodeJS) msg = colorize(`<yellow>${msg}</yellow>`)
      hasData(data) ? this.console('warn', msg, ...data) : this.console('warn', msg)
      this.emit('warn', { message, data })
    }
  }

  /**
   * Logs an error message
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  error (message = '', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.ERROR)) {
      let msg = this.formatMessage(message)
      if (isNodeJS) msg = colorize(`<red>${msg}</red>`)
      hasData(data) ? this.console('error', msg, ...data) : this.console('error', msg)
      this.emit('error', { message, data })
    }
  }

  /**
   * Logs an error message with exclamation mark !
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  fail (message = '', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.ERROR)) {
      let msg = this.formatMessage(message, '! ')
      if (isNodeJS) msg = colorize(`<red>${msg}</red>`)
      hasData(data) ? this.console('error', msg, ...data) : this.console('error', msg)
      this.emit('error', { message, data })
    }
  }

  /**
   * Logs an exception
   * @param {Error} e Exception to log
   * @param {Array} data Data to log
   */
  exception (error = { message: 'Unknown error ' }, ...data) {
    if (this.isMessageIgnored(error.message, LogLevel.ERROR)) return
    if (error.isLogged) return

    if (this.canLog(LogLevel.ERROR)) {
      let msg = this.formatMessage(error.message)
      if (isNodeJS) msg = colorize(`<red>${msg}</red>`)

      if (this.isRequestError(error)) {
        const message = this.getRequestError(error)
        this.error(message)
        this.emit('error', { message, data })
      } else {
        hasData(data) ? this.console('error', msg, ...data) : this.console('error', msg)
        this.emit('exception', { error, data })
      }

      if (error.stack) {
        const stack = this.getStack(error)
        this.console('error', stack)
      }

      // Mark as logged
      error.isLogged = true
    }
  }

  /**
   * Logs an info message with value indicated with distinctive color
   * @param {String} message Message to log, may contain color tags
   * @param {any} value Value to log
   * @param {Array} data Data to log
   */
  value (message = '', value, ...data) {
    if (!this.isMessageIgnored(message, LogLevel.INFO)) {
      if (value != null) {
        let msg = `${this.formatMessage(message)}: ${isNodeJS ? colorize(`<cyan>${value.toString()}</cyan>`) : value.toString()}`
        hasData(data) ? this.console('info', msg, ...data) : this.console('info', msg)
      } else {
        let msg = this.formatMessage(message)
        hasData(data) ? this.console('info', msg, ...data) : this.console('info', msg)
      }
      this.emit('info', {
        message: `${message}: ${value}`,
        data
      })
    }
  }

  /**
   * Logs a success message
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  success (message = '', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.INFO)) {
      let msg = this.formatMessage(message)
      if (isNodeJS) msg = colorize(`<green>${msg}</green>`)
      hasData(data) ? this.console('info', msg, ...data) : this.console('info', msg)
      this.emit('info', { message, data })
    }
  }

  /**
   * Logs a highlighted message
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  highlight (message = '', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.INFO)) {
      let msg = this.formatMessage(message)
      if (isNodeJS) msg = colorize(`<cyan>${msg}</cyan>`)
      hasData(data) ? this.console('info', msg, ...data) : this.console('info', msg)
      this.emit('info', { message, data })
    }
  }

  /**
   * Logs a warning-colored message but not a warning.
   * Useful to attract attention in logs to things which
   * should not be seen as warning
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  important (message = '', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.INFO)) {
      let msg = this.formatMessage(message)
      if (isNodeJS) msg = colorize(`<magenta>${msg}</magenta>`)
      hasData(data) ? this.console('info', msg, ...data) : this.console('info', msg)
      this.emit('info', { message, data })
    }
  }

  /**
   * Logs an info message preceded with a specified prefix
   * @param {String} message Message to log
   * @param {String} prefix Prefix to add to the message
   * @param {Array} data Data to log
   */
  prefix (message = '', prefix = '*', ...data) {
    if (!this.isMessageIgnored(message, LogLevel.INFO)) {
      const msg = this.formatMessage(message, prefix + ' ')
      hasData(data) ? this.console('info', msg, ...data) : this.console('info', msg)
      this.emit('info', { message, data })
    }
  }

  /**
   * Logs an info message preceded with a check mark √
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  check (message = '', ...data) {
    this.prefix(message, '√', ...data)
  }

  /**
   * Logs an info message preceded with a tick mark *
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  bullet (message = '', ...data) {
    this.prefix(message, '*', ...data)
  }

  /**
   * Logs an info message preceded with a dash mark -
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  dash (message = '', ...data) {
    this.prefix(message, '-', ...data)
  }

  /**
   * Logs an info message preceded with a hash mark #
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  hash (message = '', ...data) {
    this.prefix(message, '#', ...data)
  }

  /**
   * Logs an info message preceded with an exclamation mark !
   * @param {String} message Message to log
   * @param {Array} data Data to log
   */
  exclamation (message = '', ...data) {
    this.prefix(message, '!', ...data)
  }

  /**
   * Logs data dump
   * @param {Object} data Data to log
   */
  data (data) {
    if (hasData(data)) {
      if (this.canLog(LogLevel.DEBUG)) {
        if (data) {
          this.console('dir', data)
        }
      }
    }
  }

  /**
   * Logs a line
   * @param {String} ch Line character
   * @param {Number} length Line length
   */
  line (ch = '-', length = 80) {
    const message = ch.repeat(length)
    if (!this.isMessageIgnored(message, LogLevel.INFO)) {
      this.console('info', message)
    }
  }

  /**
   * Breaks the line
   */
  lineBreak () {
    this.console('info', '')
  }

  /**
   * Breaks the line
   */
  empty () {
    this.lineBreak()
  }

  /**
   * Logs messages as a line of aligned columns
   * @param {Array[String]} messages Messages to log
   * @param {Array[String|Number]} columns Column definitions.
   * The number specifies column width. If number is negative,
   * the column will be right-aligned.
   * @param {LogLevel} level Log level at which the message should be logged
   * @param {String} padCharacter Character with which the columns should be padded
   */
  columns (messages = [], columns = [], level = LogLevel.INFO, padCharacter = ' ') {
    if (!this.canLog(level)) return
    if (messages == null || columns == null) return

    for (let i = 0; i < messages.length; i++) {
      let field = (messages[i] || '').toString().trim()
      if (isNodeJS) field = colorize(field)
      const width = columns[i]
      if (width == null) {
        messages[i] = `${field}${padCharacter}`
      } else {
        const right = width < 0
        messages[i] = right
          ? padLeft(field, Math.abs(width), padCharacter)
          : padRight(field, width, padCharacter)
      }
    }
    const message = messages.join('')
    this.console(level.toLowerCase(), this.formatMessage(message))
    this.emit(level.toLowerCase(), { message })
    return message.trimEnd()
  }

  /**
   * Returns a timer with the specified name
   * @param {String} name
   * @returns {Object} Timer data
   */
  getTimer (name) {
    return this.timers[name]
  }

  /**
   * Indicates that timer messages should be printed out only
   * when timer is ended with {@link timeEnd}.
   * This can be handy if there are paraller timers running,
   * to minimize mixing of timings of various processes
   * @type {Boolean}
   */
  timerPrintAtEnd

  /**
   * Starts a timer used to track duration of operations
   * @param {String} name Timer name
   * @param {String} message Message to log
   * @param {Array[Object]} data Data to log
   * @returns {Date} Date and time when timer has been started
   */
  timeStart (name, message, ...data) {
    if (name) {
      const now = new Date()
      const timestamp = now.toISOString().substring(11, 19)
      const step = {
        message: `🕛 ${name} STARTED ${timestamp} ${message || '--------------------------'}`,
        data
      }
      this.timers[name] = {
        start: now,
        previous: null,
        current: now,
        totalDuration: 0,
        stepDuration: 0,
        steps: [step]
      }
      if (!this.timerPrintAtEnd) {
        this.highlight(step.message, ...data)
      }
      return now
    }
  }

  /**
   * Logs time since last tick of the specified timer
   * @param {String} name Timer name
   * @param {String} message Message to log
   * @param {Array[Object]} data Data to log
   * @returns {Date} Date and time when timer has logged
   */
  timeLog (name, message, ...data) {
    const timer = this.timers[name]
    if (timer) {
      const now = new Date()
      timer.totalDuration = now - timer.start
      timer.stepDuration = now - timer.current
      timer.previous = timer.current
      timer.current = now
      const step = {
        message: `🕔 ${name} [${padLeft(timer.stepDuration, 5)}ms / ${padLeft(timer.totalDuration, 5)}ms] ${message || ''}`,
        data
      }
      timer.steps.push(step)
      if (!this.timerPrintAtEnd) {
        this.highlight(step.message, ...data)
      }
    }
  }

  time (name, message, ...data) {
    return this.timeLog(name, message, ...data)
  }

  /**
   * Stops a previously started timer
   * @param {String} name Timer name
   * @param {String} message Message to log
   * @param {Array[Object]} data Data to log
   * @returns {Date} Date and time when timer has been stopped
   */
  timeEnd (name, message, ...data) {
    const timer = this.timers[name]
    if (timer) {
      const now = new Date()
      const timestamp = now.toISOString().substring(11, 19)
      timer.totalDuration = now - timer.start
      timer.stepDuration = now - timer.current
      timer.previous = timer.current
      timer.current = now
      const step = {
        message: `🕘 ${name} [${padLeft(timer.stepDuration, 5)}ms / ${padLeft(timer.totalDuration, 5)}ms] FINISHED ${timestamp} ${message || ''}`,
        data
      }
      timer.steps.push(step)
      if (this.timerPrintAtEnd) {
        for (const step of timer.steps) {
          this.highlight(step.message, ...step.data)
        }
      } else {
        this.highlight(step.message, ...data)
      }
      delete this.timers[name]
      return now
    }
  }

  /**
   * Starts a counter used to track frequency of operations
   * @param {String} name Counter name
   * @param {CounterFrequency} name Counter frequency
   * @returns {Object} Counter details
   */
  counterStart (name, frequency = CounterFrequency.Minute) {
    if (name) {
      const now = new Date()
      const counter = {
        name,
        start: now,
        end: null,
        duration: 0,
        frequency,
        count: 0,
        average: 0,
        min: 0,
        max: 0,
        memory: {
          start: process.memoryUsage().heapUsed,
          end: null
        },
        ranges: []
      }
      this.counters[name] = counter
      this.info(`# ${name.toUpperCase()} counter started | mem ${counter.memory.start} b`)
      return counter
    }
  }

  /**
   * Returns a counter with the specified name
   * @param {String} name
   * @returns {Object} Counter data
   */
  getCounter (name) {
    return this.counters[name]
  }

  /**
   * Logs counter tick
   * @param {String} name Counter name
   * @param {Number} count Number to add to the counter
   * @returns {Object} Counter details
   */
  counterTick (name, count = 1) {
    const counter = this.counters[name]
    if (counter) {
      const now = new Date()
      const timestamp = now.getTime()
      const label = counter.frequency === CounterFrequency.Day
        ? now.toISOString().substring(0, 10)
        : now.toISOString().substring(11, 19)

      let key = ''
      switch (counter.frequency) {
        case CounterFrequency.Minute:
          key = Math.floor(timestamp / 60000)
          break
        case CounterFrequency.FiveMinutes:
          key = Math.floor(timestamp / (5 * 60000))
          break
        case CounterFrequency.Quarter:
          key = Math.floor(timestamp / (15 * 60000))
          break
        case CounterFrequency.Hour:
          key = Math.floor(timestamp / 3600000)
          break
        case CounterFrequency.SixHours:
          key = Math.floor(timestamp / (6 * 3600000))
          break
        case CounterFrequency.TwelveHours:
          key = Math.floor(timestamp / (12 * 3600000))
          break
        case CounterFrequency.Day:
          key = Math.floor(timestamp / (24 * 3600000))
          break
        default:
          throw new Error(`Unsupported counter frequency ${counter.frequency}`)
      }

      counter.count = counter.count + count

      let range = counter.ranges.find(range => range.key === key)
      if (range) {
        range.count = range.count + count
      } else {
        range = { key, label, count, memory: process.memoryUsage().heapUsed }
        const previousRange = counter.ranges[counter.ranges.length - 1]
        counter.ranges.push(range)
        if (previousRange) {
          const memoryDelta = range.memory - previousRange.memory
          this.info(`# ${name.toUpperCase()} counter | ${previousRange.label} | #${previousRange.count} | mem ${range.memory} b / ${memoryDelta > 0 ? '+' : ''}${memoryDelta} b`)
        }
      }

      return counter
    }
  }

  /**
   * Stops running counter
   * @param {String} name Counter name
   * @param {String} message Message to log
   * @returns {Object} Counter details
   */
  counterEnd (name) {
    const counter = this.counters[name]
    if (counter) {
      const lastRange = counter.ranges[counter.ranges.length - 1]
      if (lastRange) {
        this.info(`# ${name}|${lastRange.label} finished`, lastRange.count)
      }
      const now = new Date()
      counter.end = now
      counter.duration = (now.getTime() - counter.start.getTime())
      counter.memory.end = process.memoryUsage().heapUsed
      this.info(`# ${name.toUpperCase()} counter finished`)
      this.info(`  * count:    ${counter.count}`)
      this.info(`  * duration: ${counter.duration} ms`)
      this.info(`  * memory:   ${counter.memory.end} b, used ${counter.memory.end - counter.memory.start} b`)
      for (const { label, count } of Object.values(counter.ranges)) {
        this.info(`  * ${label}: ${count}`)
      }

      delete this.counters[name]
    }
  }

  /**
   * Writes out a log message and remains on the same line.
   * @param {String} message Message to print
   * @param {Number} length Message length, useful when subsequent messages are shorter,
   * as it enforces clearing subsequent characters.
   * @description Useful for things such as running counters, progress bars etc.
   * Works only in NodeJS, while in the browser it will simply run {@link log}.
   */
  update (message = '', length = 40) {
    if (this.isMessageIgnored(message, LogLevel.INFO)) return

    const p = this.getProcess()
    if (p) {
      const s = colorize(message.toString())
      p.stdout.write(s)
      if (length > s.length) {
        p.stdout.write(' '.repeat(length - s.length))
      }
      p.stdout.write('\r')
      this.__inline = true
    } else {
      this.console('info', message)
    }
  }

  /**
   * Logs a details message.
   * Details messages are categorized. They are not filtered
   * by the crude means of {@link level} but by enabling/disabling
   * categories such as serial number etc. using {@link include} method.
   * @param {String} category Message category
   * @param {String} message Message to log
   * @param {any} Data Additional data to log
   */
  details (category, message, data) {
    if (this.canLogDetails(category)) {
      const msg = this.formatMessage(`[${category}] ${message}`)
      hasData(data) ? this.console('info', msg, data) : this.console('info', msg)
    }
  }

  /**
   * Defines a filter for logging {@link details}
   * @param {String|Array[String]|RegExp|Boolean} value Filter value: a single category,
   * a list of categories or regular expression for filtering the categories.
   * Boolean values will simply include or exclude all details messages.
   */
  filterDetails (value) {
    this.__detailsFilter = value
    if (value !== null && value !== false) {
      if (value === true) {
        this.info('LOG: Detailed logging is ON')
      } else {
        this.info('LOG: Detailed logging is ON for', value)
      }
    } else {
      this.info('LOG: Detailed logging is OFF')
    }
  }

  /**
   * Checks whether the specified message category can be logged with {@link details} function.
   * @param {String} category Message category
   * @returns {Boolean}
   */
  canLogDetails (category) {
    if (!this.canLog()) return false
    if (!category) return false
    const { __detailsFilter: filter } = this
    if (!filter) return false

    if (filter === true) return true
    if (filter === false) return false
    if (Array.isArray(filter)) return filter.includes(category)
    if (filter instanceof RegExp) return Boolean(category.match(filter))
    return category === filter.toString()
  }

  /**
   * Sets a regex filter to suppress messages.
   * Useful to filter out noise when debugging very specific problems.
   * Call without parameters to clear any existing filters
   * @param {RegExp} includeMessages If specified, only messages matching the expression will be printed out
   * @param {RegExp} excludeMessages If specified, only messages not matching the expression will be printed out
   */
  filterMessages (includeMessages, excludeMessages) {
    this.__messagesFilter.includeMessages = includeMessages
    this.__messagesFilter.excludeMessages = excludeMessages
  }

  /**
   * Checks whether the specified message text matches the message filters
   * previously set with {@link filterMessages}
   * @param {String} message Message
   * @returns {Boolean}
   */
  matchesFilter (message) {
    const { includeMessages, excludeMessages } = this.__messagesFilter
    if (includeMessages) {
      if (!message?.toString().match(includeMessages)) return false
    }
    if (excludeMessages) {
      if (message?.toString().match(excludeMessages)) return false
    }
    return true
  }
}

/**
 * Default instance
 */
export const Log = new LogService()


/**
 * Checks if data array contains anything
 * @param {Array} data
 * @returns {Boolean}
 */
function hasData (data) {
  if (data != null) {
    if (Array.isArray(data)) return data.some(item => item != null)
    if (typeof data === 'string') return data.trim() !== ''
    return Object.keys(data).length > 0
  }
}
