import { AlertType } from './alert-type'

/**
 * User-friendly labels of alert types
 */
export const AlertLabel = {
  [AlertType.DeviceOffline]: 'Device offline',
  [AlertType.BandShutdown]: 'Band shut down',
  [AlertType.TemperatureExceeded]: 'Temperature exceeded',
  [AlertType.SustainedReducedPower]: 'Sustained reduced power',
  [AlertType.DeviceReboots]: 'Device rebooting',
  [AlertType.ContinuousUplink]: 'Continuous uplink',
  [AlertType.TooManyMessages]: 'Too many messages',
  [AlertType.Feedback]: 'Feedback on band',
  [AlertType.ReducedGain]: 'Reduced gain',
  [AlertType.PortSense]: 'Port Sense',
  [AlertType.BatteryInUse]: 'Mains power lost',
  // Obsolete alert types
  [AlertType.DownlinkSignalWeak]: 'Sustained reduced power',
  [AlertType.PoorNetwork]: 'Poor network',
  [AlertType.BandOscillation]: 'Oscillation on band'
}

/**
 * User-friendly descriptions of alert types
 */
export const AlertDescription = {
  [AlertType.DeviceOffline]: 'Device was offline',
  [AlertType.BandShutdown]: 'Band shut down',
  [AlertType.TemperatureExceeded]: 'PCB temperature exceeded',
  [AlertType.SustainedReducedPower]: 'Sustained reduced power',
  [AlertType.DeviceReboots]: 'Device rebooted many times',
  [AlertType.ContinuousUplink]: 'Continuous uplink detected',
  [AlertType.TooManyMessages]: 'Too many messages sent',
  [AlertType.Feedback]: 'Feedback on band',
  [AlertType.ReducedGain]: 'Reduced gain on band',
  [AlertType.PortSense]: 'PortSense event detected',
  [AlertType.BatteryInUse]: 'Mains power is lost',
  // Obsolete alert types
  [AlertType.DownlinkSignalWeak]: 'Sustained reduced power',
  [AlertType.PoorNetwork]: 'Poor network connection',
  [AlertType.BandOscillation]: 'Oscillation detected'
}

/**
 * All alert descriptions
 */
export const AlertDescriptions = Object
  .values(AlertType)
  .map(type => ({
    type,
    description: AlertDescription[type]
  }))

/**
 * Returns a description of alert
 * @param {AlertType} alertType Alert type
 * @returns {String} User-friendly description of the alert type
 */
export function getAlertDescription (alertType) {
  return AlertDescription[alertType]
}
