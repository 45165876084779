<script>
import { getScanNeighbourCells } from '@stellacontrol/devices'
import Indicator from './indicator.vue'

/**
 * Live Scan (Neigbour Cells)
 */
export default {
  props: {
    // Section collapse status, dictionary
    collapsed: {
      type: Object,
      default: () => { }
    },
    // Operators
    operators: {
      default: () => { }
    },
    // Data file
    file: {
    },
    // Cells results
    items: {
      default: () => []
    }
  },

  components: {
    'sc-tt-indicator': Indicator
  },

  data () {
    return {
      // Status of cell groups.
      // Initially only the first one, serving cells, are expanded
      groupExpanded: [
        true,
        true
      ]
    }
  },

  computed: {
    // Cell groups to display
    groups () {
      const { file } = this
      return getScanNeighbourCells(file)
    },

    // Determines the operator label
    operatorName () {
      return cell => !cell.operator || cell.operator === '-'
        ? cell.plmn
        : cell.operator
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    // Toggles a section
    toggle ({ section }) {
      this.$emit('toggle', { section })
    },

    // Populates the view
    populate () {
    },

    // Toggles visibility of a cell group
    toggleGroup (index) {
      this.groupExpanded[index] = !this.groupExpanded[index]
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <main>
    <div class="group" v-for="(group, index) in groups" :class="{ expanded: groupExpanded[index] }">
      <q-banner dense class="banner bg-grey-2 q-pl-md q-pt-sm">
        <div class="row items-center">
          <span class="text-bold">
            {{ group.name }}
          </span>
          <q-space>
          </q-space>
        </div>
      </q-banner>
      <table v-if="groupExpanded[index]">
        <thead>
          <tr>
            <th>Operator</th>
            <th>Band</th>
            <th>Power</th>
            <th>Quality</th>
            <th>PCI</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="operator in Object.keys(group.cells)" :key="operator">
            <tr v-for="(cell, index) in group.cells[operator]" :key="cell.key"
              :class="{ serving: cell.isServing }">
              <td>{{ index === 0 ? operatorName(cell) : '' }}</td>
              <td>{{ cell.bandLabel }}</td>
              <td class="indicator">
                <sc-tt-indicator :value="cell.signalPower" range="signalPower"></sc-tt-indicator>
              </td>
              <td class="indicator">
                <sc-tt-indicator :value="cell.signalQuality"
                  range="signalQuality"></sc-tt-indicator>
              </td>
              <td>{{ cell.physicalCellId }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </main>
</template>

<style lang="scss" scoped>
main {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  flex-wrap: wrap;
  width: 50%;

  .group {
    .banner {
      width: 100%;
      border: solid #0000001f 1px;
    }

    &.expanded {
      .banner {
        border-bottom: none;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        &:hover {
          background-color: #fafafa;
        }

        td,
        th {
          border: solid #0000001f 1px;
          text-align: left;
          padding: 8px;
          height: 38px;
        }

        th:nth-child(1) {
          padding-left: 16px;
          font-weight: bold;
        }

        th:nth-child(3),
        th:nth-child(4),
        th:nth-child(5) {
          text-align: right;
        }

        td:nth-child(1) {
          padding-left: 16px;
          font-weight: bold;
        }

        td:nth-child(2),
        td:nth-child(5) {
          width: 80px;
        }

        td:nth-child(5) {
          text-align: right;
        }

        td.indicator {
          padding: 0;
          text-align: right;
          width: 120px;
        }
      }
    }
  }
}
</style>
