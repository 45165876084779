<script>
import { mapActions } from 'vuex'
import { formatDate, cleanup } from '@stellacontrol/utilities'
import { DateRange } from '@stellacontrol/model'
import { DeviceAuditEventType } from '@stellacontrol/devices'
import { isMegaParameterApplicable } from '@stellacontrol/mega'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  data () {
    return {
      // Indicates whether we're initializing the widget
      isInitializing: true,
      // Indicates whether the user can see the history of the device
      canSeeHistory: false,
      // Selected period
      period: DateRange.today(),
      // Maximal amount of days which user can select
      maxDays: 61,
      // Parameters to show
      parameters: ['uptime'],
      // Extras to show, such as alerts, firmware updates etc.
      extras: [
        DeviceAuditEventType.Alert,
        DeviceAuditEventType.SettingsChanged,
        DeviceAuditEventType.CommandSent,
        DeviceAuditEventType.FirmwareUpdated
      ],
      // Chart options
      options: {
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      }
    }
  },

  computed: {
    // Widget title
    widgetTitle () {
      const { period } = this
      if (period) {
        return (period.oneDay)
          ? `History | ${formatDate(period.from, 'dd MMMM')}`
          : `History | ${formatDate(period.from, 'dd MMMM')} - ${formatDate(period.to, 'dd MMMM')}`
      }
    }
  },

  methods: {
    ...mapActions([
      'getDeviceHistoryPreferences',
      'storeDeviceHistoryPreferences'
    ]),

    async populate () {
      this.canSeeHistory = await this.checkIfHistoryAvailable()

      if (this.canSeeHistory) {
        // Restore the last used filter
        // We ignore the saved value for extras, as we hide these options in device history widget
        // for simplicity and just show all events!
        const { period, parameters } = await this.getDeviceHistoryPreferences(this)
        this.period = period
        this.parameters = parameters

        this.$nextTick(async () => {
          if (this.$refs.history) {
            // Load the chart
            await this.$refs.history.populate()
          }
          this.isInitializing = false
        })

      } else {
        this.isInitializing = false
      }
    },

    // Checks if history is available for the viewed device
    async checkIfHistoryAvailable () {
      const { device, currentOrganizationGuardian, guardian, status } = this
      if (device && isMegaParameterApplicable('history', device, status)) {
        if (guardian && currentOrganizationGuardian) {
          const isDeviceAllowed = currentOrganizationGuardian.requiresPremiumSubscription('history-graph')
            ? guardian.canDeviceUse('history-graph', device.serialNumber, currentOrganizationGuardian)
            : currentOrganizationGuardian.canUse('history-graph')
          return isDeviceAllowed
        }
      }
    },

    // Triggered when filter has changed
    async filterChanged ({ period, parameters, extras } = {}) {
      // Check what's changed
      const changes = cleanup(
        {
          period: (period && this.period != period) ? period : undefined,
          parameters: (parameters && this.parameters != parameters) ? parameters : undefined,
          extras: (extras && this.extras != extras) ? extras : undefined
        },
        { emptyArrays: false }
      )

      // Store the changes
      if (Object.entries(changes).length > 0) {
        for (const [key, value] of Object.entries(changes)) {
          this[key] = value
        }

        if (!this.isInitializing) {
          await this.storeDeviceHistoryPreferences(changes)
        }
      }
    },
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <div class="widget widget-device-history">
    <sc-device-history ref="history" :title="widgetTitle" :device="device" :period="period"
      :maxDays="maxDays" max-height="500px" :parameters="parameters" :extras="extras"
      :options="options" floating-filter filter-collapsed="true" :show-timezone-label="false"
      @filter="values => filterChanged(values)">
    </sc-device-history>
  </div>
</template>

<style scoped lang="scss">
.widget-device-history {
  height: 500px;
  background-color: white;
}

/* Layout adjustments for small screens */
@media screen and (max-width: 640px) {}
</style>
