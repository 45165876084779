import Konva from 'konva'
import { DeviceType } from '@stellacontrol/model'
import { PlanItemType, PlanLineStyle, PlanTextStyle, PlanBackgroundStyle, AntennaType, SplitterType, CableType } from '@stellacontrol/planner'
import { Shape } from './shape'

import svgDeviceRepeater from './icons/device/repeater.svg'
import svgDeviceAmplifier from './icons/device/lineamp.svg'
import svgSplitterTwoWay from './icons/splitter/splitter-two-way.svg'
import svgSplitterThreeWay from './icons/splitter/splitter-three-way.svg'
import svgSplitterFourWay from './icons/splitter/splitter-four-way.svg'
import svgTapperTwoWay from './icons/splitter/tapper-two-way.svg'
import svgAntennaIndoorOmni from './icons/antenna/omni-indoor.svg'
import svgAntennaOutdoorOmni from './icons/antenna/omni-outdoor.svg'
import svgAntennaYagi from './icons/antenna/yagi.svg'
import svgAntennaLaser from './icons/antenna/laser.svg'
import svgAntennaPanelWall from './icons/antenna/panel.svg'
import svgAntennaPanelCeiling from './icons/antenna/panel-ceiling.svg'
import svgCableSD240 from './icons/cable/sd240.svg'
import svgCableSD400 from './icons/cable/sd400.svg'
import svgCableSD600 from './icons/cable/sd600.svg'
import svgCableSD900 from './icons/cable/sd900.svg'
import svgCableCustom from './icons/cable/custom.svg'
import svgPigtail from './icons/pigtail.svg'
import svgNConnector from './icons/n-connector.svg'

const equipmentIcons = {
  [DeviceType.Repeater]: svgDeviceRepeater,
  [DeviceType.LineAmp]: svgDeviceAmplifier,
  [SplitterType.SplitterTwoWay]: svgSplitterTwoWay,
  [SplitterType.SplitterThreeWay]: svgSplitterThreeWay,
  [SplitterType.SplitterFourWay]: svgSplitterFourWay,
  [SplitterType.TapperTwoWay]: svgTapperTwoWay,
  [AntennaType.IndoorOmni]: svgAntennaIndoorOmni,
  [AntennaType.OutdoorOmni]: svgAntennaOutdoorOmni,
  [AntennaType.Laser]: svgAntennaLaser,
  [AntennaType.WallPanel]: svgAntennaPanelWall,
  [AntennaType.CeilingPanel]: svgAntennaPanelCeiling,
  [AntennaType.Yagi]: svgAntennaYagi,
  [CableType.SD240]: svgCableSD240,
  [CableType.SD400]: svgCableSD400,
  [CableType.SD600]: svgCableSD600,
  [CableType.SD900]: svgCableSD900,
  [CableType.Custom]: svgCableCustom,
  ['pigtail']: svgPigtail,
  ['n-connector']: svgNConnector,
}

/**
 * Plan legend
 * @param {PlanLegend} item Plan item details
 */
export class LegendShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Legend
  }

  /**
   * Sub-shapes making part of the shape
   * @type {Dictionary<string, Konva.Shape}
   */
  shapes = {}

  get defaults () {
    return {
      ...super.defaults,
      width: 500,
      height: 180,
      equipment: {
        x: 15,
        y: 15
      },
      power: {
        x: 290,
        y: 15,
      },
      disclaimer: {
        visible: false,
        x: 15,
        y: 15,
        height: 60
      },
      backgroundStyle: new PlanBackgroundStyle({
        color: 'white'
      }),
      lineStyle: new PlanLineStyle({
        color: '#a0a0a0',
        width: 1
      }),
      textStyle: new PlanTextStyle({
        size: 14
      }),
      dbRanges: [
        { color: 'red', from: -50, to: -60 },
        { color: 'yellow', from: -60, to: -70 },
        { color: 'green', from: -70, to: -100 },
        { color: 'lightblue', from: -100, to: -120 },
      ]
    }
  }

  /**
   * Maximal number of distinct equipment items to count.
   * This must match the maximal amount of items returned by {@link PlanLayout} `getEquipment` method!
   * @type {Number}
   */
  get maxEquipmentItems () {
    return 15
  }

  /**
   * Creates all shapes making up the legend
   */
  createShapes () {
    super.createShapes()

    const { defaults, shapes } = this
    const { width, height, backgroundStyle, lineStyle } = defaults

    shapes.box = new Konva.Rect({
      width,
      height,
      fill: backgroundStyle.color,
      stroke: lineStyle.color,
      strokeWidth: lineStyle.width,
      dash: lineStyle.dash,
      cornerRadius: lineStyle.radius
    })

    Object.assign(shapes, this.createEquipmentShapes())
    Object.assign(shapes, this.createPowerShapes())
    Object.assign(shapes, this.createDisclaimerShapes())

    this.content.add(...Object.values(shapes))
  }

  createEquipmentShapes () {
    const shapes = {}

    const { defaults } = this
    const { equipment, textStyle } = defaults

    const { x, y } = equipment

    shapes.titleEquipment = new Konva.Text({
      x,
      y,
      text: 'Equipment',
      fill: textStyle.color,
      fontFamily: textStyle.fontFamily,
      fontSize: 16,
      fontStyle: 'bold'
    })

    shapes.noEquipment = new Konva.Text({
      x,
      y: y + 30,
      text: 'The plan is empty',
      fill: textStyle.color,
      fontFamily: textStyle.font,
      fontSize: textStyle.size
    })

    for (let i = 0; i < this.maxEquipmentItems; i++) {
      const item = new Konva.Group({
        x: x + 2,
        y: x + 30 + i * 40,
        visible: false
      })
      shapes[`item-${i}`] = item
    }

    return shapes
  }

  createPowerShapes () {
    const shapes = {}

    const { defaults } = this
    const { power, textStyle, backgroundStyle, lineStyle, dbRanges } = defaults

    const { x, y } = power

    shapes.separatorPower = new Konva.Rect({
      x: power.x - 15,
      y: 0,
      width: 0,
      height: 0,
      fill: backgroundStyle.color,
      stroke: lineStyle.color,
      strokeWidth: 1
    })

    shapes.titlePower = new Konva.Text({
      x,
      y,
      text: 'LTE B20 (dBm)',
      fill: textStyle.color,
      fontFamily: textStyle.fontFamily,
      fontSize: 16,
      fontStyle: 'bold'
    })

    dbRanges.map(({ color, from, to }, index) => {
      shapes[`icon-${index}`] = new Konva.Rect({
        x: x + 2,
        y: y + 30 + index * 30,
        stroke: 'silver',
        strokeWidth: 1,
        fill: color,
        width: 20,
        height: 20
      })
      shapes[`label-${index}`] = new Konva.Text({
        x: x + 35,
        y: y + 34 + index * 30,
        text: `${from} to ${to}`,
        fill: textStyle.color,
        fontFamily: textStyle.font,
        fontSize: textStyle.size
      })
    })

    return shapes
  }

  createDisclaimerShapes () {
    const shapes = {}

    const { defaults } = this
    const { disclaimer, textStyle, backgroundStyle, lineStyle } = defaults
    const { x, y, visible } = disclaimer

    if (!visible) return

    shapes.separatorDisclaimer = new Konva.Rect({
      x: 0,
      y: 0,
      width: defaults.width,
      height: 0,
      fill: backgroundStyle.color,
      stroke: lineStyle.color,
      strokeWidth: 1
    })

    shapes.disclaimer = new Konva.Text({
      x,
      y,
      width: defaults.width - 20,
      height: defaults.disclaimer.height,
      text: 'Total cable lengths are only a minimum estimate based on your design. We strongly recommend ordering at least 25% more than the calculated length.',
      fill: 'maroon',
      fontFamily: textStyle.font,
      fontSize: textStyle.size
    })

    return shapes
  }

  /**
   * Call to remove the shape from the layer
   */
  destroy () {
    super.destroy()
    this.shapes = {}
  }

  get box () {
    return this.content.getChildren
  }

  /**
   * Renders the legend shape
   */
  render (renderer) {
    super.render(renderer)

    const { maxEquipmentItems, shapes, defaults } = this
    const { textStyle } = defaults

    // Render the dynamic equipment legend.
    // Power legend is fixed and does not change after creation
    const equipment = renderer.layout.getEquipment(renderer.items)
    const hasEquipment = equipment.length > 0
    let itemCount = 0

    if (hasEquipment) {
      shapes.noEquipment.visible(false)
      for (let i = 0; i < maxEquipmentItems; i++) {
        const { label, count, unit, type, subtype } = equipment[i] || {}
        const key = `item-${i}`
        const item = shapes[key]
        if (!item) continue

        const hasCount = count != null && count > 0
        item.visible(hasCount)
        if (hasCount) {
          itemCount++

          item.destroyChildren()

          const countShape = new Konva.Text({
            x: 55,
            y: 14,
            width: 70,
            fill: textStyle.color,
            fontFamily: textStyle.font,
            fontSize: textStyle.size,
            text: `${count.toString()}${unit || ''}`
          })

          const labelShape = new Konva.Text({
            x: 112,
            y: 14,
            fill: textStyle.color,
            fontFamily: textStyle.font,
            fontSize: textStyle.size,
            text: label
          })

          const iconData = this.getEquipmentIcon(type, subtype, true)
          Konva.Image.fromURL(iconData, (image) => {
            item.add(image)
          })

          item.add(countShape, labelShape)
        }
      }
    } else {
      shapes.noEquipment.visible(true)
    }

    // Align element positions
    shapes.box.height(Math.max(defaults.height, itemCount * 40 + 60))
    shapes.separatorPower?.height(shapes.box.height())

    if (shapes.disclaimer) {
      shapes.separatorPower?.height(shapes.box.height() - defaults.disclaimer.height)
      if (itemCount > 0) {
        shapes.box.height(Math.max(defaults.height, 50 + itemCount * 40) + defaults.disclaimer.height)
        shapes.disclaimer.y(shapes.box.height() - defaults.disclaimer.height + defaults.disclaimer.y)
        shapes.disclaimer.visible(true)
      } else {
        shapes.disclaimer.visible(false)
      }
      shapes.separatorDisclaimer.y(shapes.separatorPower.height())
    }
  }

  /**
   * Returns an icon representing the specified type of equipment
   * @param {PlanItemType} type Equipment type, such as `device`, `antenna`, `splitter` etc.
   * @param {String} subtype Equipment subtype, such as `repeater`, `omni`, `splitter-two-way` etc.
   * @param {Boolean} asDataUrl If true, the image is returned as data URL
   * @returns {String}
   */
  getEquipmentIcon (type, subtype, asDataUrl) {
    if (type) {
      const svg = equipmentIcons[subtype]
      return asDataUrl
        ? `data:image/svg+xml;${svg}`
        : svg
    }
  }
}
