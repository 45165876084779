<script>
import { mapGetters, mapActions } from 'vuex'
import { DocumentCategory } from '@stellacontrol/model'
import { ViewMixin, TabsMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import About from './components/about.vue'
import Videos from './components/videos.vue'
import FAQ from './components/faq.vue'
import Pricing from './components/pricing.vue'
import TermsAndConditions from './components/terms-and-conditions.vue'
import FirmwareHistory from './components/firmware-history.vue'

const name = 'help'

/**
 * Help view
 */
export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    Secure
  ],

  components: {
    'sc-help-videos': Videos,
    'sc-help-faq': FAQ,
    'sc-help-pricing': Pricing,
    'sc-terms-and-conditions': TermsAndConditions,
    'sc-firmware-history': FirmwareHistory,
    'sc-about': About
  },

  data () {
    return {
      name,
      // Tabs visibility
      tabs: {
        videos: false,
        faq: false,
        terms: false,
        pricing: false,
        firmware: false,
        about: false
      }
    }
  },

  computed: {
    ...mapGetters([
      'myTermsAndConditions',
      'currentRoute'
    ]),

    visibleTabs () {
      return Object
        .entries(this.tabs)
        .filter(item => item[1])
        .map(item => item[0])
    }
  },

  methods: {
    ...mapActions([
      'hasVideos',
      'hasDocument'
    ])
  },

  async created () {
    this.tabs.videos = await this.hasVideos()
    // FAQ disabled until it gets a proper update
    // this.tabs.faq = await this.hasDocument({ category: DocumentCategory.FAQ })
    this.tabs.faq = false
    this.tabs.terms = Boolean(this.myTermsAndConditions)
    this.tabs.pricing = await this.hasDocument({ category: DocumentCategory.Pricing })
    this.tabs.firmware = await this.hasDocument({ category: DocumentCategory.FirmwareHistory })
    this.tabs.about = true
    const tab = this.visibleTabs.find(tab => this.currentRoute.query.tab === tab) || this.visibleTabs[0]
    this.selectTab(tab)
  }
}
</script>

<template>
  <sc-view :name="name">

    <!-- Mobile title -->
    <teleport to="#topbar-items">
      <span class="place-label text-white text-body1">
        {{ getViewTitle(name) }}
      </span>
    </teleport>

    <sc-tabs v-if="tabs.videos" :model-value="viewTab" @update:model-value="tab => selectTab(tab)"
      :tabs="viewTabs" outside-arrows mobile-arrows>
      <q-tab class="tab-videos" name="videos" icon="tv" label="Video Tutorials" :ripple="false">
      </q-tab>
      <q-tab v-if="tabs.faq" class="tab-faq" name="faq" icon="help_outline"
        label="Frequently Asked Questions" :ripple="false">
      </q-tab>
      <q-tab v-if="tabs.pricing" class="tab-pricing" name="pricing" icon="paid"
        label="Service Prices" :ripple="false">
      </q-tab>
      <q-tab v-if="tabs.terms" class="tab-terms" name="terms" icon="policy"
        label="Terms and Conditions" :ripple="false">
      </q-tab>
      <q-tab v-if="tabs.firmware" class="firmware-history" name="firmware-history" icon="list"
        label="Firmware History" :ripple="false">
      </q-tab>
      <q-tab v-if="tabs.about" class="about" name="about" icon="info" label="About" :ripple="false">
      </q-tab>
    </sc-tabs>

    <sc-tab-panels v-if="isLoggedIn && viewTab" v-model="viewTab" :tabs="viewTabs">
      <q-tab-panel v-if="tabs.videos" name="videos" class="tab-panel-videos">
        <div class="videos">
          <sc-help-videos></sc-help-videos>
        </div>
      </q-tab-panel>

      <q-tab-panel v-if="tabs.faq" name="faq" class="tab-panel-faq">
        <div class="faq">
          <sc-help-faq></sc-help-faq>
        </div>
      </q-tab-panel>

      <q-tab-panel v-if="tabs.pricing" name="pricing" class="tab-panel-pricing">
        <div class="pricing">
          <sc-help-pricing></sc-help-pricing>
        </div>
      </q-tab-panel>

      <q-tab-panel v-if="tabs.terms" name="terms" class="tab-panel-terms">
        <div class="terms">
          <sc-terms-and-conditions></sc-terms-and-conditions>
        </div>
      </q-tab-panel>

      <q-tab-panel v-if="tabs.terms" name="firmware-history" class="tab-panel-firmware-history">
        <div class="firmware-history">
          <sc-firmware-history></sc-firmware-history>
        </div>
      </q-tab-panel>

      <q-tab-panel v-if="tabs.terms" name="about" class="tab-panel-about">
        <div class="about">
          <sc-about></sc-about>
        </div>
      </q-tab-panel>

    </sc-tab-panels>

    <div v-else class="help-not-available row items-center justify-center">
      Help is not available
    </div>

  </sc-view>
</template>

<style lang='scss' scoped>
.videos,
.faq,
.pricing,
.terms,
.firmware {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.help-not-available {
  flex: 1;
  height: 100%;
}
</style>
