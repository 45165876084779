import { distinctItems } from '@stellacontrol/utilities'
import { PlanItemName, PlanItemType } from '@stellacontrol/planner'
import { PlanAction, PlanActions } from './plan-action'
import { AddItemAction } from './add-item'

/**
 * Removes the specified items from the plan
 */
export class RemoveItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.RemoveItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    const { isBatch, items } = this
    // Don't count connectors when showing messages
    const count = items.filter(item => !item.isConnector).length
    return isBatch ? `Delete ${count} items` : 'Delete'
  }

  /**
   * Action tooltip
   * @type {String}
   */
  get tooltip () {
    const { item, items } = this
    const count = items.filter(item => !item.isConnector).length
    return count === 1
      ? `Delete the ${PlanItemName[item.type].toLowerCase()}`
      : 'Delete the selected items'
  }

  /**
   * Indicates a potentially destructive action
   * @type {String}
   */
  get warning () {
    return true
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'delete'
  }

  /**
   * Action icon color
   * @type {String}
   */
  get color () {
    return 'deep-orange-4'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Use this to indicate that action requires refresh
   * of the plan stage area after completion
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * Indicates that the action can be executed on a batch of items
   * @type {Boolean}
   */
  get allowBatch () {
    return true
  }

  /**
   * User has to confirm the action
   * @type {String}
   */
  get confirmation () {
    const { isBatch, items } = this
    const count = items.filter(item => !item.isConnector).length
    if (isBatch) {
      return `Remove the selected ${count} items from the plan?`
    } else {
      return null
    }
  }

  /**
   * Removes the specified plan item
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item Item to remove
   */
  async removeItem (renderer, item) {
    if (!renderer) throw new Error('Renderer is required')
    if (!item) return
    if (!item.canRemove) return

    // Get the item and all its related items that have to go with it
    const { layout } = renderer
    const itemsToDelete = distinctItems([
      ...layout.getItemAndConnectors(item),
      ...layout.getConnectorGroup(item),
      // Do not remove the plug if there are other cables are going into the same plug!
      ...layout
        .getConnectorPlugs(item)
        .filter(plug => layout.getConnectorCount(plug) === 1)
    ], 'id')

    for (const item of itemsToDelete) {
      layout.removeItem(item)
    }

    layout.refreshRisers()

    // Notify all layers about the removed items.
    // They might want to re-render something related to it, for example radiation patterns around antennae.
    for (const item of itemsToDelete) {
      this.render({ renderer, item })
    }
  }

  /**
   * Renders the removed item out of the stage
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item Item to remove
   */
  async render ({ renderer, item }) {
    if (!renderer) throw new Error('Renderer is required')
    if (!item) return

    // Notify all layers about the removed items.
    // They might want to re-render something related to it, for example radiation patterns around antennae.
    renderer.notifyLayers(layer => layer.itemRemoved(item))
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  async execute ({ renderer, items } = {}) {
    if (renderer && items) {
      const itemsToRemove = items.filter(item => item.canRemove)
      for (const item of itemsToRemove) {
        await this.removeItem(renderer, item)
      }

      // Re-evaluate legend elements to show accurate equipment counts
      await renderer.deselect()
      await renderer.refreshLegends()
      await renderer.refreshEquipmentHierarchy()
    }
  }

  /**
   * Returns all items to take a snapshot of, in order to be able to undo the action.
   * Some actions impact other items, apart from those directly passed to the action.
   * For example, when deleting a repeater, all connectors leading to it will be deleted as well.
   * Therefore, action removing items should override this method, to return these additional items as well.
   * @param {PlanLayout} layout Plan layout
   * @param {Array[PlanItem]} items Items on which the action is executed
   * @returns {Array[PlanItem]} All items ultimately impacted by the action
   */
  getUndoItems (layout, items) {
    return items?.flatMap(item => layout.getItemAndConnectors(item))
  }

  /**
   * Undoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
   */
  async undo ({ renderer, data }) {
    await super.undo({ renderer, data })

    const { layout } = renderer
    const items = data.items.filter(i => i.type !== PlanItemType.Cable)
    const connectors = data.items.filter(i => i.type === PlanItemType.Cable)

    // Restore deleted items
    for (const { id, floorId } of items) {
      if (layout.getItem(id)) continue
      const item = data.getItemSnapshot(id)

      if (item) {
        const floor = layout.getFloor(floorId)
        const action = new AddItemAction()
        await action.execute({ renderer, items: [item], floor, select: false })
      }
    }

    // Restore deleted connectors
    for (const { id, floorId } of connectors) {
      if (layout.getItem(id)) continue
      const connector = data.getItemSnapshot(id)

      if (connector) {
        const floor = layout.getFloor(floorId)
        const action = new AddItemAction()
        await action.execute({ renderer, items: [connector], floor, select: false })
        // Link the restored connector to its items
        connector.start.item = layout.getItem(connector.start.itemId)
        connector.end.item = layout.getItem(connector.end.itemId)
      }
    }

    await renderer.refresh()
  }
}
