import { safeParseInt } from '@stellacontrol/utilities'
import { PlanAction, PlanActions } from './plan-action'

/**
 * Sets the scaling of the equipment on the plan
 */
export class SetEquipmentScaleAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetEquipmentScale
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Set equipment scale'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Number} scale Items scale
   */
  execute ({ renderer, scale } = {}) {
    if (renderer) {
      if (scale != null) {
        renderer.setScale(scale)
      }
    }
  }
}

/**
 * Toggles visibility of the background grid
 */
export class ToggleGridAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ToggleGrid
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Toggle grid'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} isVisible Indicates whether grid should be visible
   */
  execute ({ renderer, isVisible } = {}) {
    if (renderer) {
      renderer.layout.showGrid = isVisible
      renderer.backgroundLayer.refreshGrid()
      renderer.changed()
    }
  }
}

/**
 * Sets the background grid size
 */
export class SetGridSizeAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetGridSize
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Set grid size'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Number} size Grid size
   */
  execute ({ renderer, size } = {}) {
    if (renderer) {
      const minGridSize = 10
      renderer.layout.gridSize = Math.max(minGridSize, safeParseInt(size, minGridSize))
      renderer.backgroundLayer.refreshGrid()
      renderer.changed()
    }
  }
}

/**
 * Toggles visibility of cable lengths
 */
export class ToggleCableLengthsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ToggleCableLengths
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Toggle cable lengths'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} mode Cable lengths viewing mode
   */
  execute ({ renderer, mode } = {}) {
    if (renderer) {
      renderer.layout.cableLengths = mode
      renderer.refreshCables()
      renderer.changed()
    }
  }
}

/**
 * Sets the default cable type for the plan
 */
export class SetDefaultCableTypeAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetDefaultCableType
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Set default cable type'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanCableType} cableType Default cable type to use when adding new cables to the plan
   */
  execute ({ renderer, cableType } = {}) {
    if (renderer) {
      renderer.layout.cableType = cableType
      renderer.changed()
    }
  }
}

/**
 * Toggles equipment tag visibility
 */
export class ToggleTagsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ToggleTags
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Toggle tags'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} isVisible Indicates whether equipment tags should be visible
   */
  execute ({ renderer, isVisible } = {}) {
    if (renderer) {
      renderer.layout.showTags = isVisible == null ? !renderer.layout.showTags : Boolean(isVisible)
      renderer.refresh()
      renderer.changed()
    }
  }
}
