/**
 * Device operating modes
 */
export const DeviceOperatingMode = {
  /**
   * Normal operations
   */
  Normal: 0,
  /**
   * Scanning (modem devices, TT)
   */
  Scanning: 1
}

/**
 * Human-friendly device type names
 */
export const DeviceOperatingModeName = {
  [DeviceOperatingMode.Normal]: 'Normal',
  [DeviceOperatingMode.Scanning]: 'Scanning'
}
