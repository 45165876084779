<script>
import { PlanPalette, PlanLayers } from '@stellacontrol/planner'
import { PlanActions } from '../../../renderer/actions'
import LayerProperties from './layer-properties'

export default {
  mixins: [
    LayerProperties
  ],

  data () {
    return {
      // Palette configuration
      palette: PlanPalette
    }
  },

  computed: {
    id () {
      return PlanLayers.Radiation
    },

    radiationStrength () {
      return this.radiation?.strength
    },

    radiationStrengthLabel () {
      return `${this.radiation?.strength}%`
    },

    // Items to show in the item palette
    paletteItems () {
      return this.palette?.radiationLayerItems || []
    },

    // Palette item which the user has selected
    itemToAdd () {
      return this.renderer?.itemToAdd
    },

    // Checks if the specified item is currently selected
    isSelected () {
      return item => this.itemToAdd?.id === item.id
    },

    // Determines whether user can clear transparent colors.
    // Currently not available, as it would require access to the original image,
    // maybe will be implemented in future
    canClearTransparency () {
      return false && this.background.hasTransparentColors
    },

    // Determines whether user can set the antenna strength.
    // For this, floor map scale is required
    canSetAntennaStrength () {
      const { floor } = this
      return floor && !floor.isCrossSection && floor.hasMapScale
    },

    // Determines whether there are any walls defined
    hasWalls () {
      return this.floor?.hasWalls
    }
  },

  emits: [
    'action',
    'start-adding-item',
    'stop-adding-item'
  ],

  methods: {
    // Passes the action requested in the properties
    executeAction (details) {
      this.$emit('action', details)
    },

    // Toggles adding of the specified item
    addItem (item) {
      const { id, itemToAdd } = this
      const alreadyAdding = itemToAdd?.type === item.type && itemToAdd.key === item.key
      if (alreadyAdding) {
        this.$emit('stop-adding-item')
      } else {
        this.$emit('action', {
          action: PlanActions.LockWalls,
          isLocked: false,
          // No saving yet, only after the wall has been finished
          noChangeNotification: true
        })
        this.$emit('start-adding-item', { item, layer: id })
      }
    },

    // Toggles the lock on walls and yards
    toggleLockWalls (isLocked) {
      this.$emit('action', { action: PlanActions.LockWalls, isLocked })
    },

    // Clears all walls and yards
    clearWalls () {
      this.$emit('action', { action: PlanActions.ClearWalls })
    },

    // Starts/stops selection of transparent colors for the background image
    setTransparency () {
      this.$emit('action', { action: PlanActions.SetBackgroundTransparency })
    },

    // Clears the transparent colors for the background image
    clearTransparency () {
      this.$emit('action', { action: PlanActions.ClearBackgroundTransparency })
    },

    // Sets the global radiation strength
    setRadiationStrength (strength) {
      this.$emit('action', { action: PlanActions.SetRadiationStrength, strength })
    },
  }
}
</script>

<template>
  <main class="radiation-layer">
    <header class="q-mb-sm text-bold">
      Define Building Walls
    </header>

    <section class="row q-pr-sm q-pt-sm">
      <template v-for="item in paletteItems.filter(i => i.isEnabled)">
        <q-btn dense unelevated stack no-caps size="13px" :ripple="false" :flat="!isSelected(item)"
          class="button q-mr-sm" :class="{ selected: isSelected(item) }" :label="item.label"
          :icon="item.icon || palette.icons[item.type]" @click="addItem(item)"
          :disable="!enabled || (item.key === 'wall' && hasWalls)"
          v-if="item.key !== 'yard' || hasWalls">
        </q-btn>
      </template>
      <q-btn dense unelevated stack no-caps size="md" class="button" icon="close"
        label="Reset" :ripple="false" @click="clearWalls()" :disable="radiation.lockWalls"
        v-if="hasWalls">
        <sc-tooltip>
          {{ radiation.lockWalls ? 'Unlock the walls to reset' : 'Remove all walls and yards' }}
        </sc-tooltip>
      </q-btn>
    </section>

    <section class="q-mt-md">
      <div class="q-mt-sm" v-if="hasWalls">
        <q-checkbox dense label="Lock the walls" :model-value="radiation.lockWalls"
          @update:model-value="value => toggleLockWalls(value)">
          <sc-tooltip>
            Uncheck to allow editing the perimeter of the building
          </sc-tooltip>
        </q-checkbox>
      </div>
    </section>

    <header class="q-mt-md q-mb-sm text-bold" v-if="canSetAntennaStrength">
      Set Antenna Strength ({{ radiationStrength }}%)
    </header>
    <section class="q-mt-xs q-mb-md q-pb-sm">
      <div class="q-pl-sm q-pr-md">
        <q-slider :model-value="radiationStrength"
          @update:model-value="value => setRadiationStrength(value)" :min="20" :max="500" :step="10"
          :label-value="radiationStrengthLabel">
        </q-slider>
      </div>
    </section>

    <header class="q-mt-md q-mb-sm text-bold" v-if="background.hasImage">
      Set Background Transparency
    </header>
    <section class="q-mt-xs q-mb-md q-pb-sm" v-if="background.hasImage">
      <div class="row items-center q-mt-sm">
        <q-btn dense unelevated stack no-caps size="13px" class="button"
          :class="{ selected: renderer.isSettingTransparentColors }"
          :icon="renderer.isSettingTransparentColors ? 'check' : 'add'"
          label="Add transparent areas" :ripple="false" @click="setTransparency()">
        </q-btn>
        <q-btn v-if="canClearTransparency" dense unelevated stack no-caps size="md"
          class="button q-ml-sm" icon="close" label="Reset" :ripple="false"
          @click="clearTransparency()">
        </q-btn>
      </div>
    </section>

  </main>
</template>

<style lang="scss" scoped>
main.radiation-layer {
  padding: 10px 10px 15px 10px;
  user-select: none;

  .button {
    color: #0f0f0f;
    background-color: #e8e8e8;
    min-width: 80px;

    &.selected {
      color: white;
      background-color: #3949ab;
    }
  }
}
</style>
