import { isNumber } from '../number'

/**
 * Point
 */
export class Point {
  constructor ({ x, y, z, ...data } = {}) {
    this.x = x
    this.y = y
    this.z = z
    Object.assign(this, data)
  }

  /**
   * Creates a point with coordinates of another point
   * @param {Point} point
   * @returns {Point}
   */
  static from (point) {
    return new Point(point)
  }

  /**
   * Creates a point at position `(0,0)`
   * @returns {Point}
   */
  static get Zero () {
    return new Point({ x: 0, y: 0 })
  }

  /**
   * Creates a point at position `(0,0,0)`
   * @returns {Point}
   */
  static get Zero3D () {
    return new Point({ x: 0, y: 0, z: 0 })
  }

  /**
   * Point X coordinate
   * @type {Number}
   */
  x

  /**
   * Point Y coordinate
   * @type {Number}
   */
  y

  /**
   * Point Z coordinate
   * @type {Number}
   */
  z

  /**
   * Creates a deep copy of the point
   * @type {Point}
   */
  copy () {
    return Point.from(this)
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    if (result.x == null) delete result.x
    if (result.y == null) delete result.y
    if (result.z == null) delete result.z
    return result
  }

  /**
   * Determines whether point coordinates are specified
   * @type {Boolean}
   */
  get isDefined () {
    return this.x != null && this.y != null
  }

  /**
   * Determines whether point coordinates are empty or at zero
   * @type {Boolean}
   */
  get isZero () {
    return !this.x &&
      !this.y &&
      !this.z
  }

  /**
   * Checks whether the point has the same coordinates as the specified one
   * @param {Point} point Point to compare to
   * @returns {Boolean}
   */
  sameAs (point) {
    if (point) {
      const { x, y, z } = point
      return this.x === x &&
        this.y === y &&
        this.z === z
    }
  }

  /**
   * String representation of coordinates
   * @returns {String}
   */
  toString () {
    const { x, y, z } = this
    return `(${[x, y, z].filter(c => c != null).join(',')})`
  }

  /**
   * String representation of coordinates
   * @returns {String}
   */
  toCoordinateString () {
    return this.toString()
  }

  /**
   * Returns point coordinates as array
   * @returns {Array[Number]}
   */
  toArray () {
    return [this.x, this.y, this.z]
      .filter(c => c != null && !isNaN(c))
  }

  /**
   * Sets the coordinates of the point
   * @param {Point} point
   * @returns {Point} Modified instance
   */
  moveTo (point) {
    const { x, y, z } = point || {}
    this.x = x == null ? this.x : x
    this.y = y == null ? this.y : y
    this.z = z == null ? this.z : z
    return this
  }

  /**
   * Rotates the coordinates by the given angle on a flat plane
   * @param {Number} angle Rotation angle in degrees, from `0` to `360`
   * @param {Point} origin Optional origin of the rotation. If not specified, rotation is performed against `(0,0)`
   * @param {Boolean} round If true, result coordinates are rounded up to the nearest integers
   * @returns {Point} Modified instance
   */
  rotate (angle, origin, round = true) {
    const { x, y } = this
    const angleInRadians = angle * (Math.PI / 180)
    if (origin == null) {
      this.x = x * Math.cos(angleInRadians) - y * Math.sin(angleInRadians)
      this.y = x * Math.sin(angleInRadians) + y * Math.cos(angleInRadians)
    } else {
      // Translate the point to the origin
      const translated = {
        x: this.x - origin.x,
        y: this.y - origin.y
      }

      // Convert angle from degrees to radians
      const angleInRadians = angle * (Math.PI / 180)

      // Perform the rotation
      const rotatedX = translated.x * Math.cos(angleInRadians) - translated.y * Math.sin(angleInRadians)
      const rotatedY = translated.x * Math.sin(angleInRadians) + translated.y * Math.cos(angleInRadians)

      // Translate the rotated point back to its original position
      this.x = rotatedX + origin.x
      this.y = rotatedY + origin.y
    }

    if (round) {
      this.x = Math.round(this.x)
      this.y = Math.round(this.y)
    }

    return this
  }

  /**
   * Moves the point by the specified value
   * @param {Point|Number} delta
   * @returns {Point} Modified instance
   */
  moveBy (delta) {
    delta = isNumber(delta) ? { x: delta, y: delta, z: delta } : delta
    const { x, y, z } = delta || {}
    this.x = x == null ? this.x : this.x + x
    this.y = y == null ? this.y : this.y + y
    this.z = z == null ? this.z : this.z + z
    return this
  }

  /**
   * Scales the coordinates by the specified factor
   * @param {Point|Number} scale Scale factor, `1` means no change.
   * Use a number to specify the same scale factor for all axes, or {@link Point} to specify a scale for each axis individually.
   * @returns {Point} Modified instance
   */
  scale (scale) {
    scale = isNumber(scale) ? { x: scale, y: scale, z: scale } : scale
    const { x, y, z } = scale || {}
    this.x = (x != null && this.x != null) ? this.x * scale.x : this.x
    this.y = (y != null && this.y != null) ? this.y * scale.y : this.y
    this.z = (z != null && this.z != null) ? this.z * scale.z : this.z
    return this
  }

  /**
   * Scales the coordinates by the reverse of the specified factor
   * @param {Point|Number} scale Scale factor, `1` means no change.
   * Use a number to specify the same scale factor for all axes, or {@link Point} to specify a scale for each axis individually.
   * @returns {Point} Modified instance
   */
  scaleReverse (scale) {
    scale = isNumber(scale) ? { x: scale, y: scale, z: scale } : scale
    const { x, y, z } = scale || {}
    this.x = (x != null && x !== 0 && this.x != null) ? this.x / scale.x : this.x
    this.y = (y != null && y !== 0 && this.y != null) ? this.y / scale.y : this.y
    this.z = (z != null && z !== 0 && this.z != null) ? this.z / scale.z : this.z
    return this
  }

  /**
   * Rounds the coordinates
   * @returns {Point} Modified instance
   */
  round () {
    this.x = this.x != null ? Math.round(this.x) : this.x
    this.y = this.y != null ? Math.round(this.y) : this.y
    this.z = this.z != null ? Math.round(this.z) : this.z
    return this
  }

  /**
   * Returns the opposite of the point
   * @returns {Point}
   */
  opposite () {
    return new Point({
      x: this.x == null ? null : -this.x,
      y: this.y == null ? null : -this.y,
      z: this.z == null ? null : -this.z,
    })
  }

  /**
   * Calculates the delta between this and the specified point
   * @param {Point} from Point to calculate the delta with
   * @returns {Point}
   */
  delta (from) {
    const { x, y, z } = from || {}
    return new Point({
      x: (x != null && this.x != null) ? this.x - x : undefined,
      y: (y != null && this.y != null) ? this.y - y : undefined,
      z: (z != null && this.z != null) ? this.z - z : undefined
    })
  }

  /**
   * Calculates the distance from the specified point
   * @param {Point} from Point to calculate the distance from
   * @returns {Number}
   */
  distance (from) {
    const { x, y, z } = from || { x: 0, y: 0, z: 0 }
    return Math.sqrt(
      Math.pow((x || 0) - (this.x || 0), 2) +
      Math.pow((y || 0) - (this.y || 0), 2) +
      Math.pow((z || 0) - (this.z || 0), 2))
  }

  /**
   * Checks whether the distance from the specified point is smaller or equal to the specified value
   * @param {Point} point Point to check
   * @param {Number} distance Distance to check
   */
  isNearby (point, distance = 0) {
    return this.distance(point) <= distance
  }
}

