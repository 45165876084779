import { createState } from './building-dashboard.state'

export const mutations = {
  /**
   * Stores the data for the building dashboard
   * @param {Organization} organization Organization to which the building belongs
   * @param {OrganizationGuardian} organizationGuardian Organization's guardian
   * @param {Place} place Place to show on the dashboard
   * @param {Array[Device]} devices Devices to show on the dashboard
   */
  initializeBuildingDashboard (state, { organization, organizationGuardian, place, devices }) {
    state.organization = organization
    state.organizationGuardian = organizationGuardian
    state.place = place
    state.devices = devices
  },

  /**
   * Selects or deselect a device for actions, commands etc.
   * @param {Device } device Device to select or deselect
   * @param {Boolean} isSelected Selection status
   */
  buildingSelectDevice (state, { device: { id }, isSelected } = {}) {
    const device = state.devices.find(d => d.id === id)
    if (device) {
      const index = state.selectedDevices.findIndex(d => d.id === id)
      if (isSelected && index === -1) {
        state.selectedDevices.push(device)
      }

      if (!isSelected && index > -1) {
        state.selectedDevices.splice(index, 1)
      }
    }
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
