<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DeviceConnectionStatus, DeviceConnectionStatusBackgroundColor, DeviceRegion, DeviceRegionDescription, getDeviceLabel } from '@stellacontrol/model'
import { sortItems, cleanup } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceCommands } from '@stellacontrol/devices'
import DeviceLeds from '../widgets/device-leds.vue'
import DeviceBands from '../widgets/device-bands.vue'

export default {
  mixins: [
    Secure
  ],

  components: {
    'sc-widget-device-leds': DeviceLeds,
    'sc-widget-device-bands': DeviceBands
  },

  props: {
    // Device displayed in the widget
    device: {
      required: true
    },

    // Master device, if device displayed in the widget is part of a multi-device
    masterDevice: {
    },

    // Building to which the device belongs
    place: {
    },

    // Recent alerts triggered by the device
    alerts: {
    },

    // Organization in whose context the device is displayed
    organization: {
      required: true
    },

    // Alert configurations for the device
    alertConfigurations: {
    },

    // If `true`, we show compact view, with just a single row of leds representing the device
    // If `false`, we show rows of detailed LEDs for each band
    compact: {
      default: true
    },

    // Indicates that device can be selected
    isSelectable: {
      default: false
    },

    // Indicates that device is now selected
    isSelected: {
      default: false
    },

    // Indicates that we're able to perform edit actions
    // such as changing device location etc.
    isEditable: {
      default: false
    },

    // Indicates that device is now busy, so certain actions should
    // become unavailable for a while.
    isBusy: {
      default: false
    },

    // Indicates that device live status can be displayed
    isLiveStatusAllowed: {
      default: true
    },

    // Explanation why device live status cannot be displayed
    liveStatusDetails: {
    },

    // Alternative link for the device serial number.
    // If not specified, the link will lead to device dashboard.
    cardLink: {
    },

    // Maximum number of bands for any live device
    // Passed to device cards to unify the layout if devices have varying numbers of bands
    maxBands: {
      default: 6
    }
  },

  data () {
    return {
      DeviceCommands,
      DeviceRegion,
      DeviceRegionDescription,
      // Custom location, for popup editor
      customLocation: '',
      // Required to show popups
      popup: true
    }
  },

  computed: {
    ...mapState({
      // Indicates whether device region is currently being changed
      isChangingRegion: state => state.deviceSettings.changingRegionOfDevices.length > 0,
      changingRegionOf: state => state.deviceSettings.changingRegionOfDevices,
      changingRegionTo: state => state.deviceSettings.changingRegionTo,
      // All devices available to the current organization
      devices: state => state.devices.devices || [],
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices || {},
      // Status watch clock ticks, used to enforce refreshing of labels depending on recent status
      ticks: state => state.deviceStatus.ticks,
    }),

    ...mapGetters([
      'currentRoute',
      'isSmallScreen'
    ]),


    // Status of the viewed device
    status () {
      const { device, deviceStatus, activeDevicePart, ticks } = this
      if (device && ticks) {
        if (device.isMultiDevice) {
          return deviceStatus[activeDevicePart?.serialNumber]
        } else {
          return deviceStatus[device.serialNumber]
        }
      }
    },

    // Firmware update status of the viewed device
    updateStatus () {
      const device = this.devices.find(d => d.id === this.device.id)
      return device?.updateStatus
    },

    // Device label to display
    deviceLabel () {
      const { device, device: { isConnectedDevice }, canUse, place } = this
      const showRegion = isConnectedDevice && canUse('device-management-region-change') && place && place.hasRegion
      return `${getDeviceLabel(device)} ${showRegion ? '(' + device.modelRegion.toUpperCase() + ')' : ''}`
    },

    // Device band count
    bandCount () {
      const { device: { bands } } = this
      return bands ? bands.length : 0
    },

    // Boards making up a multi-device
    deviceParts () {
      const parts = this.device.isMultiDevice
        ? this.devices.filter(d => d.partOf === this.device.id)
        : []
      return parts
        ? sortItems(parts, part => part.model)
        : []
    },

    // Board of a multi-device which was active the most recently
    activeDevicePart () {
      const { deviceParts, deviceStatus } = this

      // Get device boards, their status age and their RF state
      const boards = deviceParts
        .map(({ serialNumber }) => {
          const status = deviceStatus[serialNumber]
          return {
            serialNumber,
            age: status?.timings?.statusAge,
            isRFOff: status?.connection?.isRFOff
          }
        })
        .filter(i => i.age != null)

      // Get the most recently active board with RF on.
      // If none has RF on, get the most recently active board in general
      const all = sortItems(boards, i => i.age)
      const active = sortItems(boards.filter(i => !i.isRFOff), i => i.age)
      const recent = active[0] || all[0]

      // Return the matching part
      const part = deviceParts.find(p => p.serialNumber === recent?.serialNumber)
      return part
    },

    // Route to which the user goes on clicking the card link
    cardRoute () {
      const { device, cardLink } = this
      return cardLink ||
      {
        name: 'device-dashboard',
        params: {
          serialNumber: device.serialNumber
        }
      }
    },

    // The visuals
    cardClass () {
      const { compact, isSelectable, isSelected, bandCount, device, deviceParts } = this
      return {
        compact,
        extended: !compact,
        selectable: isSelectable,
        selected: isSelected,
        'multi-device': device.isMultiDevice,
        'no-parts': deviceParts.length === 0,
        [`bands-${bandCount}`]: true
      }
    },

    backgroundColors () {
      return DeviceConnectionStatusBackgroundColor
    },

    backgroundColor () {
      const { status, backgroundColors, isLiveStatusAllowed } = this
      if (isLiveStatusAllowed && status?.connection) {
        return backgroundColors[status.connection.status]
      } else {
        return backgroundColors[DeviceConnectionStatus.Unknown]
      }
    },

    deviceCardStyle () {
      const { backgroundColor, maxBands, compact, status, isLiveStatusAllowed } = this
      // Enforce a fixed card height so that dashboards with devices
      // with varying band counts are still lined up neatly
      const cardHeight = compact ? 120 : (maxBands * 30 + 74)
      const borderColor = (isLiveStatusAllowed && status?.hasDeviceConnected) ? backgroundColor : undefined
      return cleanup({
        'background-color': backgroundColor,
        'border-color': borderColor,
        'height': `${cardHeight}px`
      })
    },

    // Indicates whether user has access to the device dashboard
    canViewDashboard () {
      return !(this.isBusy || !this.device.isConnectedDevice || this.cannotUse('device-dashboard'))
    },

    // Indicates whether device bands should be displayed
    canSeeBands () {
      const { device, isLiveStatusAllowed } = this
      return this.canUse('device-bands') &&
        device.isConnectedDevice &&
        !device.isMultiDevice &&
        isLiveStatusAllowed
    },

    // Indicates whether the device can be selected
    canSelect () {
      return !this.isBusy && this.isSelectable
    },

    // Indicates whether user can manage firmware updates for this device
    canManageFirmware () {
      return this.canUse('can-manage-firmware')
    },

    // Indicates whether user can send commands to this device
    canSendCommands () {
      return this.canUse('device-management') && this.isSelectable
    },

    // Indicates whether user can edit notes
    canEditNotes () {
      const { isEditable, isBusy, device, canUse } = this
      return isEditable &&
        !isBusy &&
        !device.isShared &&
        canUse('device-management')
    },

    // Indicates whether device region icon should be visible.
    // We show this, when displaying the insides of a multi-device,
    // or showing multi-device on a place dashboard
    showDeviceRegion () {
      const { device, masterDevice } = this
      return masterDevice || device.isMultiDevice
    },

    // Indicates whether region of the specified device is currently being changed
    isChangingRegionOf () {
      return device => this.changingRegionOf.find(d => d.serialNumber === device.serialNumber)
    },

    // Returns true if device model supports the specified region
    modelRegionIs () {
      return value => {
        const { device, activeDevicePart } = this
        if (device) {
          // For multi-device return the model of the recently active board
          if (device.isMultiDevice) {
            return activeDevicePart?.modelRegion === value
          } else {
            // For simple device just deduct from model
            return device.modelRegion === value
          }
        }
      }
    },

    // Returns true if device region in settings matches the device region determined by model.
    // If device region is not known yet (status hasn't been fetched), we simply check the model region.
    deviceRegionIs () {
      return value => {
        const { device, status, deviceStatus, activeDevicePart } = this
        if (device?.isMultiDevice) {
          // For multi-device check the region of the currently active board
          const activePartStatus = deviceStatus[activeDevicePart?.serialNumber]
          return activePartStatus?.connection?.region === value
        } else {
          return status?.connection?.region === value
        }
      }
    },

    // Checks whether there are any alerts visible for the device
    hasAlerts () {
      return this.alerts?.length > 0
    },

    // Alerts popup title
    alertsTitle () {
      const { device, alerts } = this
      if (alerts?.length > 0) {
        return `Recent alerts reported by ${device.acronym} ${device.serialNumber}`
      }
    }
  },

  methods: {
    ...mapActions([
      'setDeviceCustomLocation',
      'unlinkDevices'
    ]),

    // Breaks lines of text with HTML break
    breakLines (text) {
      return (text || '').replace(/\n/g, '<br>')
    },

    // Marks the specified device as selected
    select () {
      const { device, isSelected } = this
      this.$emit('select', { device, isSelected: !isSelected })
    },

    // Returns link to part dashboard
    getPartLink (part) {
      return {
        name: 'device-dashboard',
        params: {
          serialNumber: part.serialNumber
        }
      }
    }
  }
}
</script>

<template>
  <div class="device-card" :class="cardClass" :style="deviceCardStyle" @click="select()">

    <div class="label title q-pl-xs">
      <router-link class="item-link" :to="canViewDashboard ? cardRoute : currentRoute" @click.stop>
        <div class="model">
          {{ device.model }}
        </div>

        <div class="row items-center no-wrap">
          <div class="serial">
            {{ device.serialNumber }}
          </div>

          <q-space></q-space>

          <div class="firmware row items-center no-wrap"
            v-if="!device.isMultiDevice && device.firmwareVersionLong">
            <div class="label q-mr-xs row items-center no-wrap text-orange-8"
              v-if="updateStatus?.inProgress">
              <q-icon name="change_circle" class="rotate-reverse q-mr-xs" color="orange-8"
                size="14px"></q-icon>
              {{ updateStatus.firmwareVersion }} {{ updateStatus.progress }}%
              <sc-tooltip>
                Updating from {{ device.firmwareVersionLong }}
                to {{ updateStatus.firmwareVersion }} ...
              </sc-tooltip>
            </div>

            <div class="label q-mr-xs row items-center no-wrap text-orange-8 firmware-update"
              v-else-if="updateStatus?.isScheduled">
              <q-icon name="schedule" class="q-mr-xs" color="orange-8" size="14px"></q-icon>
              {{ updateStatus.firmwareVersion }}

              <sc-tooltip>
                Pending update from {{ device.firmwareVersionLong }}
                to {{ updateStatus.firmwareVersion }}
              </sc-tooltip>
            </div>

            <div v-else class="label q-mr-xs">
              {{ device.firmwareVersionLong }}
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <!-- Compact view, showing a single row of LEDs each representing a band -->
    <template v-if="compact">
      <!-- Single-board LEDs -->
      <sc-widget-device-leds
        v-if="canUse('device-bands') && device.isConnectedDevice && !device.isMultiDevice && isLiveStatusAllowed"
        :device="device" :compact="compact" bg-color="transparent">
      </sc-widget-device-leds>

      <!-- Multi-board LEDs -->
      <div v-if="canUse('device-bands') && device.isMultiDevice" class="device-parts">
        <div class="row items-start" v-for="part in deviceParts">
          <router-link class="part-label item-link q-mr-xs" :to="getPartLink(part)" @click.stop>
            {{ part.model }}
            <sc-tooltip :text="`Go to ${part.model} ${part.serialNumber} dashboard`" />
          </router-link>

          <sc-widget-device-leds :device="part" :compact="compact" bg-color="transparent"
            v-if="isLiveStatusAllowed">
          </sc-widget-device-leds>
        </div>
        <div v-if="deviceParts.length === 0" class="part-label text-orange-8">
          Boards not assigned yet
        </div>
      </div>

    </template>

    <!-- Extended view, showing detailed LEDs for each band -->
    <template v-else>
      <!-- Information about the reason why user cannot see the live status -->
      <div v-if="!isLiveStatusAllowed" class="status-details">
        {{ liveStatusDetails }}
      </div>

      <!-- Single-board bands -->
      <sc-widget-device-bands embedded v-if="canSeeBands" :device="device" bg-color="transparent">
      </sc-widget-device-bands>

      <!-- Multi-board bands -->
      <div v-if="canUse('device-bands') && device.isMultiDevice" class="device-parts">
        <template v-if="deviceParts.length === 0">
          <span class="part-label text-orange-8">
            No boards assigned yet.
          </span>
        </template>
        <template v-else>
          <div v-for="part in deviceParts" class="part-label">
            <div class="row items-center" style="padding-right: 4px;">
              <span>
                {{ part.model }}
              </span>
              <q-space></q-space>
              <span>
                {{ device.firmwareVersionLong }}
              </span>
            </div>

            <div class="row items-center">
              <sc-widget-device-leds :compact="true" :device="part" bg-color="transparent">
              </sc-widget-device-leds>
            </div>
          </div>
        </template>
      </div>
    </template>

    <div class="footer row items-center no-wrap q-pl-xs">
      <div class="device-region" v-if="showDeviceRegion">
        <q-icon v-if="isChangingRegionOf(device)" name="change_circle" class="rotate-reverse"
          color="orange-7" size="22px">
        </q-icon>
        <div v-else class="region-icons">
          <sc-icon-flag-eu v-if="deviceRegionIs(DeviceRegion.EMEA)" :width="18" :height="18">
          </sc-icon-flag-eu>
          <sc-icon-flag-usa v-if="deviceRegionIs(DeviceRegion.USA)" :width="18" :height="18">
          </sc-icon-flag-usa>
        </div>
      </div>

      <q-icon v-if="hasAlerts" class="device-alerts" size="20px" color="orange-9" name="error"
        @click.stop>
        <sc-alert-popup :alerts="alerts" :showSerialNumber="false" :title="alertsTitle">
        </sc-alert-popup>
      </q-icon>

      <div class="label location clip q-px-xs" @click.stop>
        <span>
          {{ device.isShared && device.owner ? device.owner.name + ' ' : '' }}
          {{ device.customLocation || device.location || '' }}
          <sc-tooltip v-if="isEditable">
            Location: {{ device.customLocation }}<br>
            Click to edit ...
          </sc-tooltip>
          <sc-tooltip v-else-if="device.customLocation">
            {{ device.customLocation }}
          </sc-tooltip>
        </span>
        <q-popup-edit v-if="isEditable && !isBusy" buttons self="bottom left" label-set="Save"
          v-slot="scope" :model-value="device.customLocation"
          :title="`Enter custom location of ${device.acronym} ${device.serialNumber}`"
          @save="location => setDeviceCustomLocation({ device, location })">
          <q-input autofocus max-length="255" v-model="scope.value"
            :label="device.location ? `Reported by device: ${device.location}` : 'Enter custom device location'"
            @keyup.enter="scope.set">
          </q-input>
        </q-popup-edit>
      </div>

      <q-space></q-space>

      <q-icon v-if="!isBusy && canEditNotes" class="device-notes" size="22px"
        :name="device.hasNotes ? 'comment' : 'mode_comment'"
        :color="device.hasNotes ? 'indigo-6' : (isSelected ? 'grey-1' : 'grey-6')" @click.stop>

        <sc-tooltip v-if="!isSmallScreen"
          :text="device.hasNotes ? 'Click to edit notes' : 'Click to add notes'">
        </sc-tooltip>

        <q-popup-proxy v-if="canEditNotes" :cover="isSmallScreen" ref="notesPopup">
          <div class="notes-popup q-pa-md">
            <sc-device-notes :popup="true" :devices="[device]" :create-new-note="!device.hasNotes"
              @cancel="() => $refs.notesPopup.hide()">
            </sc-device-notes>
          </div>
        </q-popup-proxy>
      </q-icon>
    </div>

    <q-checkbox v-if="canSelect" class="device-selector" size="xs" color="indigo-4" keep-color
      :model-value="isSelected" @update:model-value="() => select()" @click.stop>
    </q-checkbox>

  </div>
</template>

<style lang="scss" scoped>
.alert-banner-max-width-height {
  max-width: 350px;
  max-height: 350px;
  overflow-y: auto;
}

.device-card {
  --padding: 6px;
  --title-font-size: 15px;
  --subtitle-font-size: 14px;
  --subtitle-gap: 2px;
  width: 190px;
  min-width: 190px;
  border: solid #e0e0e0 2px;
  border-radius: 4px;
  background-color: #f8f8f8;
  position: relative;
  transition: all 0.1s ease-out;
  padding: var(--padding);

  &.selectable {
    cursor: pointer;

    &:hover {
      border: solid #7f829a 2px !important;
    }

    &.selected {
      border: solid #4a56b6 2px !important;
    }
  }

  .title {
    overflow: hidden;

    .model {
      font-size: var(--title-font-size);
      font-weight: bold;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      padding-bottom: var(--subtitle-gap);
    }

    .serial {
      font-size: var(--subtitle-font-size);
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }

    .firmware {
      font-size: var(--subtitle-font-size);
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
  }

  .status-details {
    padding: 4px;
    font-size: 12px;
  }

  .footer {
    position: absolute;
    bottom: 4px;
    left: 4px;
    right: 8px;

    .label {
      font-size: 11px;
      overflow: hidden;
      text-overflow: clip;

      &.location {
        cursor: pointer;
        max-width: 130px;
      }

      &.firmware-update {
        flex-basis: 100%;
      }
    }
  }

  .device-parts {
    margin: 0 0 4px 4px;

    .part-label {
      font-size: 12px;
      margin-bottom: 4px;
    }
  }

  .device-commands {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .device-selector {
    position: absolute;
    top: 2px;
    right: 2px;
  }
}

.notes-popup {
  width: 600px;
  min-height: 320px;
  overflow: auto;
  background-color: white;
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .device-card {
    width: auto;
    min-width: auto;

    --title-font-size: 15px;
    --subtitle-font-size: 12px;
    --subtitle-gap: 5px;
  }

  .notes-popup {
    width: 100vw;
    height: 100vw;
  }
}

@media screen and (width <=420px) {
  .device-card {
    --padding: 4px;
  }
}

@media screen and (width <=360px) {
  .device-card {
    --padding: 4px;
    --title-font-size: 14px;
    --subtitle-font-size: 11px;
    --subtitle-gap: 6px;
  }
}
</style>
