import { dispatch, dispatchSequential, isRouteDataChanged } from '@stellacontrol/client-utilities'

/**
 * Actions to be executed when resolving organizations route
 * @param from Source route
 * @param to Target route
 */
export async function resolveOrganizations () {
  await dispatch('initializeOrganizationsView')
  return true
}

/**
 * Actions to be executed when resolving organization route
 * @param from Source route
 * @param to Target route
 */
export async function resolveOrganization ({ from, to }) {
  if (!isRouteDataChanged(from, to)) {
    return true
  }

  await dispatchSequential(
    'requireOrganizationProfiles',
    'requireOrganizations',
    'requireUsers'
  )

  const parentOrganization = to.query.parent
    ? await dispatch('getOrganization', { id: to.query.parent })
    : undefined

  const { id } = to.params
  const data = id === 'new'
    ? await dispatch('newOrganization', { parentOrganization })
    : await dispatch('getOrganization', { id })

  // Do not allow editing yourself, unless you're a super organization!
  const currentOrganization = await dispatch('getCurrentOrganization')
  if (currentOrganization.id === id && !currentOrganization.isSuperOrganization) {
    return false
  }

  if (data) {
    const title = id === 'new' ? 'New Customer' : `Customer: ${data.name}`
    await dispatch('setRouteData', { from, to, data, title })
    return true
  }
}
