<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    // Name of the list controlled by this component
    name: {
      type: String,
      required: true
    },
    // Label to display inside the search box
    label: {
      type: String,
      default: 'Search'
    },
    // If true, the container will be transparent and assume parent's background color
    transparent: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      filter: ''
    }
  },

  computed: {
    ...mapGetters([
      'listFilter',
      'list',
    ]),

    listState () {
      return this.list(this.name)
    }
  },

  emits: [
    'filter'
  ],

  methods: {
    ...mapActions([
      'setListState'
    ]),

    applyFilter () {
      const { name } = this
      const filter = this.filter ? this.filter.trim() : ''
      this.setListState({ name, filter })
      this.$emit('filter', { filter })
    }
  },

  created () {
    // Get the last filter from the grid state
    this.filter = this.listFilter(this.name) || ''
  }
}

</script>

<template>
  <div class="list-filter row items-center justify-between no-wrap" :class="{ transparent }">
    <slot name="filter" v-bind:data="listState">
      <q-input class="input-filter" outlined clearable clear-icon="close" dense :label="label"
        icon="search" bg-color="white" :debounce="500" v-model="filter"
        @update:model-value="() => applyFilter()">
        <template v-slot:prepend>
          <q-icon name="search"></q-icon>
        </template>
      </q-input>
    </slot>
    <slot>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.list-filter {
  padding: 15px 15px 15px 15px;
  background-color: #F2F2F2;
  border-bottom: 1px solid #DFE3EA;

  &.transparent {
    background-color: transparent;
  }

  .input-filter {
    min-width: 30%;
    width: 100%;
    max-width: 650px;
  }

  .inner {
    flex: 1;
  }
}
</style>
