import { createState } from './devices.state'
import { Note, TagCategory } from '@stellacontrol/model'

export const mutations = {
  /**
   * Stores devices in the state
   * @param devices Devices to be stored
   * @param currentOrganization Current organization
   */
  storeDevices (state, { devices = [], currentOrganization } = {}) {
    state.devices = devices || []
    state.hasDevices = true
    state.refresh = false

    // Find devices directly belonging to the current organization
    state.ownDevices = state.devices.filter(device => device.owner && device.owner.id === currentOrganization.id)
    state.hasOwnDevices = true
    state.recentlyAddedDevices = []
  },

  /**
   * Stores devices hierarchy in the state
   * @param hierarchy Devices hierarchy to be stored
   */
  storeDevicesHierarchy (state, { hierarchy = {} } = {}) {
    state.hierarchy = hierarchy || {}
  },

  /**
   * Stores organization's own devices in the state
   * @param devices Devices to be stored
   */
  storeOwnDevices (state, { devices = [] } = {}) {
    state.ownDevices = devices || []
    state.hasOwnDevices = true
    state.refresh = false
  },

  /**
   * Stores devices shared with the organization
   * @param devices Devices to be stored
   */
  storeSharedDevices (state, { devices = [] } = {}) {
    state.sharedDevices = devices || []
    state.hasSharedDevices = true
  },

  /**
   * Stores all known device types, firmwares, hardwares, models etc.
   */
  storeDeviceTypes (state, { types, models, firmwares, hardwares } = {}) {
    state.deviceTypes.types = types || state.deviceTypes.types
    state.deviceTypes.models = models || state.deviceTypes.models
    state.deviceTypes.firmwares = firmwares || state.deviceTypes.firmwares
    state.deviceTypes.hardwares = hardwares || state.deviceTypes.hardwares
  },

  /**
   * Invalidates devices available to the current organization,
   * so that the next call to `requireDevices` will reload them
   */
  invalidateDevices (state) {
    state.devices = null
    state.hasDevices = false
    state.refresh = true
  },

  /**
   * Assigns alert profiles accessible to the current organization
   * to their respective devices. Triggered by `getMyAlertProfiles` action,
   * usually called when initializing a view which needs access to profiles.
   * @param alertProfiles Alert profiles to store
   * @param subscribedAlertProfiles Alert profiles to which the organization has subscribed
   */
  storeMyAlertProfiles (state, { alertProfiles = [], subscribedAlertProfiles = {}, inflateFullAlertProfiles } = {}) {
    state.myAlertProfiles = alertProfiles

    for (const device of state.devices || []) {
      let subscribedProfile = subscribedAlertProfiles[device.serialNumber]
      if (subscribedProfile !== undefined) {
        if (inflateFullAlertProfiles && subscribedProfile !== null) {
          // Optionally assign all details of the subscribed profile,
          // just verify whether the profile actually exists amongst user's profiles!
          const profileId = subscribedProfile.id
          subscribedProfile = alertProfiles.find(p => p.id === profileId)
          if (!subscribedProfile) {
            const identifiers = alertProfiles.map(p => p.id).join(', ')
            throw new Error(`Alert profile [${profileId}] not found. Available are: [${identifiers}]`)
          }
        }
        device.alertProfile = subscribedProfile
      }
    }
  },

  /**
   * Clears the list of recently created devices
   */
  clearRecentlyAddedDevices (state) {
    state.recentlyAddedDevices = []
  },

  /**
   * Add a device to the list of recently created devices
   */
  deviceAddedToInventory (state, { device } = {}) {
    if (device) {
      state.recentlyAddedDevices.push(device)
    }
  },

  /**
   * Updates a device
   * @param device Device to be updated
   */
  updateDevice (state, { device } = {}) {
    if (device) {
      updateDevice(state.devices, device)
    }
  },

  /**
   * Sets device comments
   * @param {Device} device Device whose custom comments are to be set
   * @param {String} comments Comments text
   */
  setDeviceComments (state, { device: { id }, comments } = {}) {
    const device = state.devices.find(d => d.id === id)
    if (device) {
      device.comments = comments
    }
  },

  /**
   * Triggered when device has been swapped
   * @param {Device} device Device to be swapped
   * @param {Device} replaceWith Replacement device
   */
  swapDevice (state, { device, replaceWith } = {}) {
    if (device && replaceWith) {
      updateDevice(state.devices, device)
      updateDevice(state.devices, replaceWith)
    }
  },

  /**
   * Triggered when device has been sold
   * @param {Device} device Device sold
   */
  sellDevice () {
  },

  /**
   * Triggered when device data has been refreshed
   * @param {Device} device Device
   */
  // eslint-disable-next-line no-unused-vars
  deviceRefreshed (state, { device } = {}) {
  },

  /**
   * Updates a batch of devices
   * @param devices Devices to be updated
   */
  updateDevices (state, { devices = [] } = {}) {
    for (const device of devices) {
      updateDevice(state.devices, device)
    }
  },

  /**
   * Adds device tag
   * @param device Device to add the tag to
   * @param tag Tag to add
   */
  addDeviceTag (state, { device, tag }) {
    const deviceToTag = (state.devices || []).find(d => d.id === device.id)
    if (deviceToTag) {
      const { name, userId } = tag
      if (!deviceToTag.hasTag({ name, userId })) {
        deviceToTag.addTag({ name, userId, category: TagCategory.Device })
      }
    }
  },

  /**
   * Removes device tag
   * @param device Device to removes the tag from
   * @param tag Tag to remove
   */
  removeDeviceTag (state, { device, tag }) {
    const deviceToTag = (state.devices || []).find(d => d.id === device.id)
    if (deviceToTag) {
      const { name, userId } = tag
      if (deviceToTag.hasTag({ name, userId })) {
        deviceToTag.removeTag({ name, userId })
        updateDevice(state.devices, device)
      }
    }
  },

  /**
   * Updates a device note
   * @param {Device} device Device
   * @param {Note} note Note to update
   * @param {User} user User updating the notes
   */
  saveDeviceNote (state, { device: { id }, note, user } = {}) {
    const device = (state.devices || []).find(d => d.id === id)
    if (device && note) {
      Note.update(device, note, user)
      updateDevice(state.devices, device)
    }
  },

  /**
   * Removes a note from device
   * @param {Device} device Device
   * @param {Note} note Note to remove
   * @param {User} user User removing the notes
   */
  removeDeviceNote (state, { device: { id }, note, user } = {}) {
    const device = (state.devices || []).find(d => d.id === id)
    if (device && note) {
      Note.remove(device, note, user)
      updateDevice(state.devices, device)
    }
  },

  /**
   * Marks a device as assigned to the specified place
   * @param device Device to set the place
   * @param place Place to assign to. If none, device will be unassigned from its current place
   */
  setDevicePlace (state, { place, device: { id } } = {}) {
    const device = (state.devices || []).find(d => d.id === id)
    if (device) {
      device.place = place ? place : undefined
      device.placeId = place ? place.id : undefined
    }
  },

  /**
   * Sets device location
   * @param device Device whose custom location is to be set
   * @param location Location details
   * @param customLocation Custom location details
   */
  setDeviceLocation (state, { device: { id }, location, customLocation } = {}) {
    const device = (state.devices || []).find(d => d.id === id)
    if (device) {
      if (location !== undefined) {
        device.location = location
      }
      if (customLocation !== undefined) {
        device.customLocation = customLocation
      }
    }
  },

  /**
   * Triggered when upload job update is received.
   * Updates upload status on any corresponding devices.
   * @param {UploadJob} job Updated job
   */
  storeUploadJob (state, { job = {} } = {}) {
    const device = (state.devices || []).find(d => d.id === job.deviceId)
    if (device) {
      device.updateUploadStatus(job)
    }
  },

  /**
   * Updates devices with the modified alert profile subscriptions.
   * @param subscribedAlertProfiles Alert profiles to which the organization has subscribed
   * @description BROADCAST from alertProfiles store
   */
  updateAlertProfileSubscriptions (state, { subscribedAlertProfiles = {} } = {}) {
    for (const device of state.devices || []) {
      const subscribedProfile = subscribedAlertProfiles[device.serialNumber]
      if (subscribedProfile !== undefined) {
        device.alertProfile = subscribedProfile
      }
    }
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}

/**
 * Updates a device in the collection
 * @param {Array[Device]} devices Devices collection
 * @param {Device} device Device to be updated
 */
function updateDevice (devices, device) {
  if (devices && device) {
    const i = devices.findIndex(d => d.id === device.id)
    if (i === -1) {
      devices.push(device)
    } else {
      devices[i] = device
    }
  }
}
