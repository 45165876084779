/**
 * Plan states
 */
export const PlanState = {
  /**
   * Renderer is currently initializing
   */
  Initializing: 'initializing',
  /**
   * Renderer is currently busy rendering
   */
  Rendering: 'rendering',
  /**
   * User is able to edit the plan
   */
  Editing: 'editing',
  /**
   * Plan is now being saved
   */
  Saving: 'saving',
  /**
   * Floor image is being loaded from external storage
   */
  LoadingImage: 'loading-image'
}
