<script>
import { mapGetters, mapActions } from 'vuex'
import { formatTime } from '@stellacontrol/utilities'
import { Notification } from '@stellacontrol/client-utilities'
import { DeviceFlags } from '@stellacontrol/model'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  data () {
    return {
      logBundle: null,
      showAdvancedOptions: false
    }
  },

  computed: {
    ...mapGetters([
      'isProductionEnvironment'
    ]),

    // Determines whether the widget should be shown.
    // Don't, if device owner is not required to purchase premium services,
    // or when looking at device part - for these, their parent device carries the subscription.
    isVisible () {
      return this.isSuperAdministrator &&
        !this.isProductionEnvironment
    },

    // Buttons to display in the widget
    buttons () {
      const { ticks, device: { flags, serialNumber, acronym }, logBundle, showAdvancedOptions } = this
      if (ticks >= 0 && flags) {
        const buttons = [
          {
            name: 'blockStatus',
            icon: 'podcasts',
            color: flags.blockStatus ? 'red-7' : 'green-7',
            tooltip: flags.blockStatus
              ? 'The platform is not processing status messages from the device<br>Click to resume.'
              : 'The device is receiving status messages from the device.<br>Click to suspend.',
            notification: `Processing ${acronym} ${serialNumber} status ${flags.blockSettings ? 'have been resumed' : 'have been suspended'}.`,
            isVisible: showAdvancedOptions
          },
          {
            name: 'blockSettings',
            icon: 'settings',
            color: flags.blockSettings ? 'red-7' : 'green-7',
            tooltip: flags.blockSettings
              ? 'Any settings sent to the device will be discarded<br>Click to resume.'
              : 'The device is receiving settings from the platform.<br>Click to suspend.',
            notification: `Processing ${acronym} ${serialNumber} settings has been ${flags.blockSettings ? 'resumed' : 'suspended'}.`,
            isVisible: showAdvancedOptions
          },
          {
            name: 'blockCommands',
            icon: 'bolt',
            color: flags.blockCommands ? 'red-7' : 'green-7',
            tooltip: flags.blockCommands
              ? 'Any commands sent to the device will be discarded<br>Click to resume.'
              : 'The device is receiving commands from the platform.<br>Click to suspend.',
            break: true,
            notification: `Processing ${acronym} ${serialNumber} commands has been ${flags.blockCommands ? 'resumed' : 'suspended'}.`,
            isVisible: showAdvancedOptions
          },
          {
            name: 'debug',
            icon: flags.isDebugging ? 'stop_circle' : 'play_circle',
            iconClass: { 'pulse': flags.isDebugging },
            color: flags.isDebugging ? 'red-7' : 'green-6',
            tooltip: flags.isDebugging
              ? `Device messages are logged until ${formatTime(flags.debug)}<br>Click to stop logging.`
              : 'Device messages are not logged.<br>Click to start logging.',
            notification: `${acronym} ${serialNumber} debugging has been ${flags.isDebugging ? 'stopped' : 'started'}.`
          },
          {
            name: 'profile',
            icon: 'schedule',
            color: flags.isDebugging ? 'blue-7' : 'grey-5',
            tooltip: flags.isDebugging
              ? `Message processing speed is logged until ${formatTime(flags.profile)}<br>Click to stop logging.`
              : 'Message processing speed is not logged.<br>Click to start logging.',
            isVisible: showAdvancedOptions
          },
          {
            name: 'download-log',
            isHidden: !logBundle?.url,
            href: logBundle?.url,
            icon: 'download_for_offline',
            color: 'blue-7',
            tooltip: logBundle ? `Click to download the recently logged data, <br>collected at ${formatTime(logBundle.createdAt)}` : '',
            notification: `${acronym} ${serialNumber} log has been stored in your Downloads folder.`
          }
        ]
        return buttons.filter(b => b.isVisible !== false)

      } else {
        return []
      }
    },

    // Widget icon color
    iconColor () {
      const { device: { flags } } = this
      if (flags.isDebugging) return 'red-8'
      return 'indigo-6'
    }
  },

  methods: {
    ...mapActions([
      'setDeviceFlags',
      'startDeviceLogging',
      'stopDeviceLogging',
      'getDeviceLogBundle'
    ]),

    // Loads the most recent log bundle
    async populate () {
      const { device } = this
      if (device) {
        this.logBundle = await this.getDeviceLogBundle({ device })
      } else {
        this.logBundle = null
      }
    },

    // Toggles the specified flag
    async toggleFlag ({ name, notification }) {
      const { device } = this
      const flags = new DeviceFlags(device.flags)

      // Enable debugging, optionally with profiling
      if (name === 'debug' || name === 'profile') {
        if (flags.isDebugging) {
          this.logBundle = await this.stopDeviceLogging({ device })
        } else {
          this.logBundle = null
          await this.startDeviceLogging({ device, profile: name === 'profile' })
        }
      } else if (name !== 'download-log') {
        // Other flags
        flags.toggle(name)
        await this.setDeviceFlags({ device, flags })
      }

      // Show notification if any
      if (notification) {
        Notification.success({ message: notification })
      }
    }
  },

  watch: {
    device () {
      this.populate()
    }
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <div v-if="isVisible" class="widget mini-widget widget-device-flags">
    <div class="row items-center text-bold no-wrap">
      <q-icon size="32px" name="bug_report" :color="iconColor">
      </q-icon>
      <span class="q-ml-sm">
        Logging
      </span>

      <q-space>
      </q-space>

      <template v-for="button in buttons">
        <q-btn :icon="button.icon" :class="button.iconClass" :color="button.color" round flat dense size="14px"
          @click="toggleFlag(button)" :href="button.href" v-if="!button.isHidden">
          <sc-tooltip :text="button.tooltip">
          </sc-tooltip>
        </q-btn>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
