/**
 * PortSense work modes
 */
export const PortSenseMode = {
  NoInjection: 0,
  InjectDownlinkVoltage: 1,
  InjectUplinkVoltage: 2,
  InjectBothVoltages: 3,
  // Alternative naming, from LEDs perspective
  AntennaLEDsOff: 0,
  AntennaLEDsOn: 3
}
