/* eslint-disable @stylistic/js/array-element-newline */
// Array linebreak rule disabled here:
// We have a lot of coordinate arrays where, for better readability, we prefer to have two items (x,y) per row.
// This breaks the eslint array linebreak rule, but it's not possible to tweak the rule for this specific scenario.
import Konva from 'konva'
import { Point, Points } from '@stellacontrol/utilities'
import { PlanItemType, PlanPortType, PlanLocation, AntennaType, PlanBackgroundStyle, PlanSplitter } from '@stellacontrol/planner'
import { moveToTop } from '../utilities/shapes'
import { Shape } from './shape'
import { ShapeLayout } from './shape-layout'

/**
 * Antennae
 * @param {PlanAntenna} item Plan item details
 */
export class AntennaShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Antenna
  }

  __layout

  /**
   * Shape layout
   * @type {ShapeLayout}
   */
  get layout () {
    const { item, item: { antennaType } } = this

    const shapes = {
      [AntennaType.IndoorOmni]: { x: 0, y: 0, radius: 30 },
      [AntennaType.OutdoorOmni]: { x: 0, y: 0, width: 20, height: 70 },
      [AntennaType.WallPanel]: { x: 0, y: 0, width: 50, height: 70 },
      [AntennaType.CeilingPanel]: { x: 0, y: 0, width: 50, height: 70 },
      [AntennaType.Yagi]: { x: 0, y: 0, width: 90, height: 40 },
      [AntennaType.TwinYagi]: { x: 0, y: 0, width: 230, height: 40 },
      [AntennaType.Laser]: { x: 0, y: 0, radius: 100 },
    }

    const shape = shapes[antennaType]

    if (item.isCircular) {
      if (shape.radius !== item.radius) {
        throw new Error(`[${antennaType}] Item dimensions here are different than in the item definition`)
      }
    } else {
      if (shape.width !== item.width || shape.height !== item.height) {
        throw new Error(`[${antennaType}] Item dimensions here are different than in the item definition`)
      }
    }

    // Return the existing layout if unchanged
    if (this.__layout && this.__layout.antennaType === antennaType) {
      return this.__layout
    }

    // Prepare the shape layout
    let layout

    if (item.antennaType === AntennaType.IndoorOmni) {
      // Indoor Omni
      layout = new ShapeLayout({
        antennaType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Center,
            shape: { x: 0, y: 0, radius: 8 }
          }
        ]
      })

    } else if (item.antennaType === AntennaType.OutdoorOmni) {
      // Outdoor Omni
      layout = new ShapeLayout({
        antennaType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Bottom,
            shape: { x: 10, y: 76 }
          }
        ]
      })

    } else if (item.antennaType === AntennaType.CeilingPanel || item.antennaType === AntennaType.WallPanel) {
      // Panel Antennae
      layout = new ShapeLayout({
        antennaType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Center,
            shape: { x: 25, y: 35, radius: 8 }
          }
        ]
      })

    } else if (item.antennaType === AntennaType.Yagi) {
      // Yagi Antenna
      layout = new ShapeLayout({
        antennaType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Left,
            shape: { x: -6, y: 20 }
          }
        ]
      })

    } else if (item.antennaType === AntennaType.TwinYagi) {
      // Twin Yagi Antenna
      layout = new ShapeLayout({
        antennaType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Center,
            shape: { x: 115, y: 20 }
          }
        ]
      })

    } else if (item.antennaType === AntennaType.Laser) {
      // Laser Antenna
      layout = new ShapeLayout({
        antennaType,
        shape,
        ports: [
          {
            id: PlanPortType.In,
            location: PlanLocation.Left,
            shape: { x: -106, y: 0 }
          }
        ]
      })

    } else {
      throw new Error(`Unsupported antenna type [${antennaType}]`)
    }

    this.__layout = layout
    return layout
  }

  // Used to detect a change in antenna type,
  // when ports need to be re-created
  __antennaType
  get antennaType () {
    return this.__antennaType
  }

  get defaults () {
    const defaults = {
      ...super.defaults,
      ports: {
        ...super.defaults.ports,
        taken: {
          backgroundStyle: new PlanBackgroundStyle({ color: '#404040' }),
        }
      }
    }

    return defaults
  }

  /**
   * Shape representing the antenna background
   * @type {Konva.Shape}
   */
  backgroundShape

  /**
   * Shape representing the antenna
   * @type {Konva.Shape}
   */
  chassisShape

  /**
   * Main shape in the {@link content}, such as device chassis etc.
   * @type {Konva.Shape}
   */
  get main () {
    return this.chassisShape
  }

  /**
   * Additional shapes inside the antennae
   * @type {Array[Konva.Shape]}
   */
  innerShapes

  /**
   * Indicates that antenna is external
   * @type {Boolean}
   */
  get isExternal () {
    return this.__isExternal || this.item.isExternalAntenna
  }

  /**
   * Some antennae can be used as internal and external.
   * In such case, this property is evaluated during render
   * and if true, it overrides rules defined in {@link isExternal} property
   */
  __isExternal

  /**
   * Indicates that antenna is internal
   * @type {Boolean}
   */
  get isInternal () {
    return !this.isExternal
  }

  /**
   * Creates all shapes making up the antenna
   */
  createShapes () {
    super.createShapes()

    const { item, item: { port } } = this
    this.ports = undefined
    this.innerShapes = []
    this.__antennaType = item.antennaType

    // Antennas rendered using SVG images need an additional background element so they're selectable
    this.backgroundShape = new Konva.Rect()

    // Antennas rendered using SVG images get their chassisShape created later,
    // in `render` function, only after the SVG image has been retrieved
    this.chassisShape = undefined
    if (item.isYagi || item.isTwinYagi) {
      this.chassisShape = new Konva.Line({
        closed: true,
        lineJoin: 'round',
        lineCap: 'round'
      })

    } else if (item.isIndoorOmni) {
      this.chassisShape = new Konva.Circle()
      this.ports = [
        new Konva.Circle({ id: port.id, lineJoin: 'round' })
      ]

    } else if (item.isOutdoorOmni) {
      this.chassisShape = new Konva.Rect()

    } else if (item.isPanel) {
      this.chassisShape = new Konva.Rect()
      this.ports = [
        new Konva.Circle({ id: port.id, lineJoin: 'round' })
      ]

    } else if (item.isLaser) {
      this.chassisShape = new Konva.Arc()
    }

    if (!this.ports) {
      this.ports = [
        new Konva.Rect({ id: port.id, lineJoin: 'round' })
      ]
    }

    this.innerShapes = []

    if (item.isIndoorOmni) {
      this.innerShapes = [
        new Konva.Wedge({ id: 'wedge-top' }),
        new Konva.Wedge({ id: 'wedge-bottom' })
      ]
    }

    if (item.isOutdoorOmni) {
      this.innerShapes = [
        new Konva.Rect({ id: 'cap' })
      ]
    }

    if (item.isYagi) {
      this.innerShapes = [
        new Konva.Line({
          id: 'wedge-left',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        }),
        new Konva.Line({
          id: 'wedge-right',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        })
      ]
    }

    if (item.isTwinYagi) {
      this.innerShapes = [
        new Konva.Rect({
          id: 'splitter',
        }),
        new Konva.Rect({
          id: 'splitter-port-left',
        }),
        new Konva.Rect({
          id: 'splitter-port-right',
        }),
        new Konva.Line({
          id: 'l-wedge-left',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        }),
        new Konva.Line({
          id: 'l-wedge-right',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        }),
        new Konva.Line({
          id: 'r-wedge-left',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        }),
        new Konva.Line({
          id: 'r-wedge-right',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        })
      ]
    }

    if (item.isPanel) {
      this.innerShapes = [
        new Konva.Line({
          id: 'wedge-left',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        }),
        new Konva.Line({
          id: 'wedge-right',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        }),
        new Konva.Line({
          id: 'wedge-arrow-left',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        }),
        new Konva.Line({
          id: 'wedge-arrow-right',
          closed: true,
          lineJoin: 'round',
          lineCap: 'round'
        })
      ]
    }

    if (item.isLaser) {
      this.innerShapes = [
        new Konva.Rect({ id: 'stick' }),
        new Konva.Ellipse({ id: 'receiver' })
      ]
    }

    this.content.add(this.backgroundShape)
    if (this.chassisShape) {
      this.content.add(this.chassisShape)
    }
    this.content.add(
      ...this.ports,
      ...this.innerShapes
    )
  }

  /**
   * Returns coordinates of the item tag.
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Point}
   */
  getTagPosition ({ renderer }) {
    if (!renderer) throw new Error('Renderer is required')

    const position = super.getTagPosition({ renderer })
    const { item } = this
    if (item.isOutdoorOmni) {
      position.moveBy({ x: -5, y: -12 })
    }
    if (item.isLaser) {
      position.moveBy({ x: 5, y: 10 })
    }
    return position
  }

  /**
   * Reassigns z-indices of shapes
   */
  reindexShapes () {
    const shapes = [
      this.backgroundShape,
      this.chassisShape,
      this.label,
      ...this.innerShapes,
      ...this.ports,
      this.tag
    ]

    for (const shape of shapes) {
      moveToTop(shape)
    }
  }

  /**
   * Indicates whether shape needs to be (partially) recreated,
   * i.e. after changing the antenna type
   * @type {Boolean}
   */
  get requiresRefresh () {
    return super.requiresRefresh ||
      this.__antennaType !== this.item.antennaType
  }

  /**
   * Renders the antenna shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  async render (renderer) {
    const { item } = this
    this.__isExternal = renderer.layout.isExternalAntenna(item)

    super.render(renderer)

    switch (item.antennaType) {
      case AntennaType.Yagi:
        await this.renderYagi(renderer)
        break
      case AntennaType.TwinYagi:
        await this.renderTwinYagi(renderer)
        break
      case AntennaType.IndoorOmni:
        await this.renderIndoorOmni(renderer)
        break
      case AntennaType.OutdoorOmni:
        await this.renderOutdoorOmni(renderer)
        break
      case AntennaType.WallPanel:
      case AntennaType.CeilingPanel:
        await this.renderPanel(renderer)
        break
      case AntennaType.Laser:
        await this.renderLaser(renderer)
        break
      default:
        throw new Error(`Unsupported antenna type [${item.antenna.type}]`)
    }

    this.reindexShapes()
  }

  /**
   * Renders the Yagi antenna shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderYagi (renderer) {
    if (!renderer) return

    const { chassisShape, item, innerShapes } = this
    const { backgroundStyle, lineStyle } = item

    const bounds = item.getBounds(renderer.isCrossSection)
    const shrink = 10

    chassisShape.fill(backgroundStyle.color)
    chassisShape.stroke(lineStyle.color)
    chassisShape.tension(0)
    chassisShape.points(this.toCoordinates([
      Point.Zero.moveBy({ y: 2 }),
      Point.Zero.moveBy({ x: 2 }),
      Point.Zero.moveBy({ x: item.width - 2, y: shrink - 2 }),
      Point.Zero.moveBy({ x: item.width, y: shrink }),
      Point.Zero.moveBy({ x: item.width, y: item.height - shrink }),
      Point.Zero.moveBy({ x: item.width - 2, y: item.height - shrink + 2 }),
      Point.Zero.moveBy({ x: 2, y: item.height }),
      Point.Zero.moveBy({ y: item.height - 2 }),
    ]))

    const wedgeLeft = innerShapes[0]
    const wedgeRight = innerShapes[1]

    wedgeLeft.fill(lineStyle.color)
    wedgeLeft.stroke(lineStyle.color)
    wedgeLeft.strokeWidth(lineStyle.width)
    wedgeLeft.points(this.toCoordinates([
      Point.Zero.moveBy({ x: 2, y: 2 }),
      Point.Zero.moveBy({ x: Math.round(bounds.width / 3), y: Math.round(bounds.height / 2) }),
      Point.Zero.moveBy({ x: 2, y: bounds.height - 2 })
    ]))

    wedgeRight.fill(lineStyle.color)
    wedgeRight.stroke(lineStyle.color)
    wedgeRight.strokeWidth(lineStyle.width)
    wedgeRight.points(this.toCoordinates([
      Point.Zero.moveBy({ x: bounds.width - 2, y: shrink }),
      Point.Zero.moveBy({ x: Math.round(bounds.width / 3), y: Math.round(bounds.height / 2) }),
      Point.Zero.moveBy({ x: bounds.width - 2, y: bounds.height - shrink })
    ]))
  }

  /**
   * Renders the Twin Yagi antenna shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderTwinYagi (renderer) {
    if (!renderer) return

    const { chassisShape, item, innerShapes } = this
    const { backgroundStyle, lineStyle } = item

    chassisShape.fill(backgroundStyle.color)
    chassisShape.stroke(lineStyle.color)
    chassisShape.tension(0)
    const chassisPoints = Points.fromArray([
      0, 10,
      90, 0,
      90, 15,
      100, 15,
      100, 5,
      130, 5,
      130, 15,
      140, 15,
      140, 0,
      230, 10,
      230, 30,
      140, 40,
      140, 25,
      130, 25,
      130, 35,
      100, 35,
      100, 25,
      90, 25,
      90, 40,
      0, 30,
      0, 10
    ])
    chassisShape.points(chassisPoints.toArray())

    const [splitter, splitterPortLeft, splitterPortRight, leftAntenna_WedgeLeft, leftAntenna_WedgeRight, rightAntenna_WedgeLeft, rightAntenna_WedgeRight] = innerShapes

    const { defaults: splitterDefaults } = new PlanSplitter()
    const portStyle = this.getPortStyle()
    splitter.position({ x: 100, y: 5 })
    splitter.size({ width: 30, height: 30 })
    splitter.fill(splitterDefaults.backgroundStyle.color)
    splitter.stroke(splitterDefaults.lineStyle.color)
    splitter.strokeWidth(splitterDefaults.lineStyle.width)

    splitterPortLeft.position({ x: 90, y: 15 })
    splitterPortLeft.size({ width: 10, height: 10 })
    splitterPortLeft.fill(portStyle.backgroundStyle.color)
    splitterPortLeft.stroke(portStyle.lineStyle.color)
    splitterPortLeft.strokeWidth(portStyle.lineStyle.width)

    splitterPortRight.position({ x: 130, y: 15 })
    splitterPortRight.size({ width: 10, height: 10 })
    splitterPortRight.fill(portStyle.backgroundStyle.color)
    splitterPortRight.stroke(portStyle.lineStyle.color)
    splitterPortRight.strokeWidth(portStyle.lineStyle.width)

    leftAntenna_WedgeLeft.fill(lineStyle.color)
    leftAntenna_WedgeLeft.stroke(lineStyle.color)
    leftAntenna_WedgeLeft.strokeWidth(lineStyle.width)
    leftAntenna_WedgeLeft.points([
      0, 10,
      60, 20,
      0, 30
    ])

    leftAntenna_WedgeRight.fill(lineStyle.color)
    leftAntenna_WedgeRight.stroke(lineStyle.color)
    leftAntenna_WedgeRight.strokeWidth(lineStyle.width)
    leftAntenna_WedgeRight.points([
      60, 20,
      90, 0,
      90, 40
    ])

    rightAntenna_WedgeLeft.fill(lineStyle.color)
    rightAntenna_WedgeLeft.stroke(lineStyle.color)
    rightAntenna_WedgeLeft.strokeWidth(lineStyle.width)
    rightAntenna_WedgeLeft.points([
      140, 0,
      170, 20,
      140, 40
    ])

    rightAntenna_WedgeRight.fill(lineStyle.color)
    rightAntenna_WedgeRight.stroke(lineStyle.color)
    rightAntenna_WedgeRight.strokeWidth(lineStyle.width)
    rightAntenna_WedgeRight.points([
      170, 20,
      230, 10,
      230, 30
    ])
  }

  /**
   * Renders the Indoor Omni antenna shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderIndoorOmni (renderer) {
    if (!renderer) return

    const { chassisShape, item } = this
    const { radius, backgroundStyle, lineStyle } = item

    chassisShape.radius(radius)
    chassisShape.fill(backgroundStyle.color)
    chassisShape.stroke(lineStyle.color)
    chassisShape.strokeWidth(2)
    chassisShape.dash(lineStyle.dash)

    const wedgeTop = this.content.findOne('#wedge-top')
    const wedgeBottom = this.content.findOne('#wedge-bottom')
    wedgeTop.x(0)
    wedgeTop.y(0)
    wedgeTop.radius(radius)
    wedgeTop.angle(90)
    wedgeTop.fill('black')
    wedgeTop.rotation(-135)
    wedgeBottom.x(0)
    wedgeBottom.y(0)
    wedgeBottom.radius(radius)
    wedgeBottom.angle(90)
    wedgeBottom.fill('black')
    wedgeBottom.rotation(45)
  }

  /**
   * Renders the Outdoor Omni antenna shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderOutdoorOmni (renderer) {
    if (!renderer) return

    const { chassisShape, item } = this
    const { backgroundStyle, lineStyle } = item

    const cap = this.content.findOne('#cap')
    const { width, height } = item
    chassisShape.width(width)
    chassisShape.height(height)
    chassisShape.fill(backgroundStyle.color)
    chassisShape.stroke(lineStyle.color)
    chassisShape.strokeWidth(lineStyle.width)
    chassisShape.dash(lineStyle.dash)

    cap.x(-3)
    cap.y(-10)
    cap.width(width + 6)
    cap.height(10)
    cap.fill(backgroundStyle.color)
    cap.stroke(lineStyle.color)
    cap.strokeWidth(lineStyle.width)
    cap.dash(lineStyle.dash)
  }

  /**
   * Renders the Panel antenna shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderPanel (renderer) {
    if (!renderer) return
    const { chassisShape, item, innerShapes } = this
    const { backgroundStyle, lineStyle, isOmnidirectionalAntenna } = item

    chassisShape.width(item.width)
    chassisShape.height(item.height)
    chassisShape.fill(backgroundStyle.color)
    chassisShape.stroke(lineStyle.color)
    chassisShape.strokeWidth(lineStyle.width)
    chassisShape.dash(lineStyle.dash)
    chassisShape.lineJoin('round')
    chassisShape.lineCap('round')
    chassisShape.cornerRadius(3)

    const wedgeLeft = innerShapes[0]
    const wedgeRight = innerShapes[1]
    const arrowLeft = innerShapes[2]
    const arrowRight = innerShapes[3]

    const bounds = item.getBounds(renderer.isCrossSection)

    wedgeLeft.fill(lineStyle.color)
    wedgeLeft.stroke(lineStyle.color)
    wedgeLeft.strokeWidth(lineStyle.width)
    wedgeLeft.points(this.toCoordinates([
      Point.Zero.moveBy({ x: 2, y: 2 }),
      Point.Zero.moveBy({ x: Math.round(bounds.width / 2), y: Math.round(bounds.height / 2) }),
      Point.Zero.moveBy({ x: 2, y: bounds.height - 2 })
    ]))

    wedgeRight.fill(lineStyle.color)
    wedgeRight.stroke(lineStyle.color)
    wedgeRight.strokeWidth(lineStyle.width)
    wedgeRight.points(this.toCoordinates([
      Point.Zero.moveBy({ x: bounds.width - 2 }),
      Point.Zero.moveBy({ x: Math.round(bounds.width / 2), y: Math.round(bounds.height / 2) }),
      Point.Zero.moveBy({ x: bounds.width - 2, y: bounds.height - 2 })
    ]))

    const arrowSize = 12
    const arrowMargin = 6

    if (isOmnidirectionalAntenna) {
      arrowLeft.fill(lineStyle.color)
      arrowLeft.stroke(lineStyle.color)
      arrowLeft.strokeWidth(lineStyle.width)
      arrowLeft.points(this.toCoordinates([
        Point.Zero.moveBy({ x: -arrowMargin, y: Math.round(bounds.height / 2) - arrowSize }),
        Point.Zero.moveBy({ x: -arrowMargin - arrowSize, y: Math.round(bounds.height / 2) }),
        Point.Zero.moveBy({ x: -arrowMargin, y: Math.round(bounds.height / 2) + arrowSize }),
      ]))
    }

    arrowRight.fill(lineStyle.color)
    arrowRight.stroke(lineStyle.color)
    arrowRight.strokeWidth(lineStyle.width)
    arrowRight.points(this.toCoordinates([
      Point.Zero.moveBy({ x: bounds.width + arrowMargin, y: Math.round(bounds.height / 2) - arrowSize }),
      Point.Zero.moveBy({ x: bounds.width + arrowMargin + arrowSize, y: Math.round(bounds.height / 2) }),
      Point.Zero.moveBy({ x: bounds.width + arrowMargin, y: Math.round(bounds.height / 2) + arrowSize }),
    ]))
  }

  /**
   * Renders the Laser antenna shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderLaser (renderer) {
    if (!renderer) return

    const { item, chassisShape } = this
    const { lineStyle, radius } = item

    chassisShape.outerRadius(radius)
    chassisShape.innerRadius(radius - lineStyle.width)
    chassisShape.clockwise(true)
    chassisShape.angle(-90)
    chassisShape.rotation(-135)
    chassisShape.fill(lineStyle.color)
    chassisShape.stroke(lineStyle.color)
    chassisShape.strokeWidth(lineStyle.width)

    const stick = this.content.findOne('#stick')
    const receiver = this.content.findOne('#receiver')

    stick.stroke(lineStyle.color)
    stick.strokeWidth(lineStyle.width * 2)
    stick.width(radius / 3)
    stick.height(lineStyle.width)
    stick.x(-radius + 1)

    receiver.stroke(lineStyle.color)
    receiver.strokeWidth(lineStyle.width * 3)
    receiver.radius(({ x: radius / 8, y: 6 }))
    receiver.x(-radius + stick.width() + radius / 8)
  }

  /**
   * Triggered when shape is being transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   * @returns {Boolean}
   */
  async transforming ({ renderer, scale, rotation }) {
    super.transforming({ renderer, scale, rotation })

    const { item } = this
    if (item.isIndoorOmni) {
      item.radius = Math.round(item.radius * scale.x)
    }

    // Re-render the shape
    await this.render(renderer)

    return true
  }
}
