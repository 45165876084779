<script>
import { sortItems, sameArrays } from '@stellacontrol/utilities'
import { getBandLabel, getDeviceBandIdentifiers } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { getMegaParameters, getMegaParameter, expandMegaParameter } from '@stellacontrol/mega'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Initial value
    modelValue: {
    },

    // Observed device
    device: {
    }
  },

  data () {
    return {
      // All MEGA parameters available for the history graphs
      parameters: [],
      // Tree nodes
      nodes: [],
      // Parameter currently selected in the tree
      current: null,
      // All parameters selected for the chart
      selected: []
    }
  },

  computed: {
    // Checks whether the specified parameter belongs to advanced parameters
    // Those are hidden from normal users with history=false flag in mega.json
    isAdvancedParameter () {
      return name => {
        const parameter = getMegaParameter(name)
        return parameter.history === false
      }
    }
  },

  methods: {
    // Populates the selector with all parameters which can be selected into the chart.
    // Band parameters are expanded.
    // We allow only parameter explicitly marked with `history` attribute.
    // For super-organization we allow all parameters with `store` attribute.
    populate () {
      const { device, isSuperOrganization } = this
      this.parameters = []
      this.nodes = []

      if (!device) return

      // Get parameters which are permitted on the history graphs
      this.parameters = getMegaParameters(device)
        .filter(parameter => parameter.isAvailableOnHistoryGraphs(isSuperOrganization))

      const items = this.parameters
        .map(parameter => {
          const { name, label, isBandParameter } = parameter
          if (isBandParameter) {
            // Expand band parameter to detailed bands,
            // skip bands which the device does not have
            const deviceBands = getDeviceBandIdentifiers(device) || []
            const bandParameters = expandMegaParameter(parameter.name, device)
              .filter(parameter => deviceBands.some(id => parameter.band === id))

            if (bandParameters.length > 0) {
              return {
                name,
                label,
                isBandParameter: true,
                children: bandParameters
                  .map(p => ({ ...p, label: getBandLabel(p.band, device?.family) }))
                  .filter(p => p.label !== 'N/A')
              }
            }
          } else {
            return {
              name,
              label,
              isBandParameter: false
            }
          }
        }).filter(p => p)

      const regular = items.filter(p => !this.isAdvancedParameter(p.name))
      const advanced = {
        name: 'other',
        label: 'Other parameters',
        isBandParameter: false,
        selectable: false,
        noTick: true,
        children: items.filter(p => this.isAdvancedParameter(p.name))
      }

      const nodes = sortItems(regular, 'label')

      this.nodes = [
        ...nodes,
        advanced.children.length > 0 ? advanced : null
      ].filter(p => p)
    },

    // Checks if the specified parameter is currently selected
    isSelected (name) {
      return this.selected.includes(name)
    },

    // Checks if all parameters under the specified band parameter are currently selected
    allSelected (name) {
      const parameters = expandMegaParameter(name)
      return parameters.every(({ name }) => this.selected.includes(name))
    },

    // Selects/deselects the specified parameter.
    // If band parameter has been (de)selected,
    // (de)select all underlying parameters accordingly
    select (name, status) {
      const { selected } = this
      const parameters = expandMegaParameter(name)
      for (const parameter of parameters) {
        const index = selected.indexOf(parameter.name)
        if (status && index === -1) {
          this.selected = [...this.selected, parameter.name]
        } else if (index > -1) {
          this.selected = this.selected.filter(p => p !== parameter.name)
        }
      }
    }
  },

  watch: {
    // If device has been changed, remove selected parameters
    // which are no longer applicable to the newly selected device
    device () {
      this.populate()
      this.selected = this.selected.filter(name => this.parameters.some(p => p.isParameter(name)))
    },

    // Triggered when parameters selected by user
    selected (newValue, oldValue) {
      if (sameArrays(newValue, oldValue)) return
      this.$emit('update:model-value', newValue || [])
    },

    // Triggered when model changes
    modelValue (newValue, oldValue) {
      if (sameArrays(newValue, oldValue)) return
      this.selected = [...(newValue || [])]
      this.populate()
    }
  },

  created () {
    this.selected = [...(this.modelValue || [])]
    this.populate()
  }
}
</script>

<template>
  <div>
    <div class="q-mb-sm text-bold">
      Device parameters:
    </div>

    <q-tree ref="tree" color="indigo-6" control-color="indigo-6" :nodes="nodes" node-key="name"
      label-key="label" children-key="children" no-connectors no-nodes-label=" "
      no-results-label=" " :duration="0" :default-expand-all="false" tick-strategy="leaf"
      v-model:selected="current" v-model:ticked="selected">
    </q-tree>

  </div>

</template>

<style scoped lang="scss">

</style>