<script>
import { DeviceStatusHealth } from '@stellacontrol/devices'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  data () {
    return {
      actions: [
        { name: 'copy', label: 'Copy to clipboard', icon: 'content_copy', color: 'indigo-6', permissions: [] },
      ]
    }
  },

  computed: {
    health () {
      return this.status?.health
    },

    hasTemperature () {
      return this.health?.hasTemperature
    },

    temperature () {
      return this.health?.temperature
    },

    temperatureThreshold () {
      const { hasTemperature } = this
      return hasTemperature ? DeviceStatusHealth.Threshold.Temperature : undefined
    },

    textColor () {
      const { temperature, temperatureThreshold } = this
      if (temperature <= temperatureThreshold.warning) return 'text-blue-8'
      if (temperature <= temperatureThreshold.alert) return 'text-orange-8'
      return 'text-red-8'
    },

    iconColor () {
      const { temperature, temperatureThreshold } = this
      if (temperature <= temperatureThreshold.warning) return '#1976d2'
      if (temperature <= temperatureThreshold.alert) return '#f57c00'
      return '#d32f2f'
    },

    items () {
      const { health, textColor } = this
      if (health && health.temperature != null) {
        const { temperature } = health
        const values = [
          { label: 'PCB', value: `${temperature}°C`, color: textColor }
        ]
        const items = [
          { text: 'Temperature', class: { 'label': true } },
          ...values.map(c => ({
            text: `
              <div class="temp-item">
                <span>${c.label}</span>
                <span class="${c.color || 'text-blue-8'}">
                  ${c.value}
                  </span>
              </div>
            `
          }))
        ]
        return items
      }
    }
  }
}
</script>

<template>
  <sc-widget-text v-if="hasTemperature" :items="items" v-bind="$props">
    <template #icon>
      <div class="icon-container">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="-20px" width="54" height="80"
          viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"
          transform="translate(-10 -20)">
          <g>
            <g>
              <path
                d="M341.333,288.593V85.333C341.333,38.205,303.128,0,256,0s-85.333,38.205-85.333,85.333v203.259
                C144.48,312.03,128,346.091,128,384c0,70.693,57.308,128,128,128s128-57.307,128-128C384,346.091,367.52,312.03,341.333,288.593z
                M256,469.333c-47.128,0-85.333-38.205-85.333-85.333c0-24.637,10.441-47.492,28.455-63.615l14.212-12.72V85.333
                c0-23.564,19.103-42.667,42.667-42.667s42.667,19.102,42.667,42.667v222.332l14.212,12.72
                c18.014,16.123,28.455,38.977,28.455,63.615C341.333,431.128,303.128,469.333,256,469.333z"
                :fill="iconColor" />
            </g>
          </g>
          <g>
            <g>
              <rect x="234.667" y="170.667" width="42.667" height="256" :fill="iconColor" />
            </g>
          </g>
          <g>
            <g>
              <circle cx="256" cy="384" r="64" :fill="iconColor" />
            </g>
          </g>
        </svg>
      </div>
    </template>
  </sc-widget-text>
</template>

<style lang="scss" scoped>
.icon-container {
  overflow: hidden;
  height: 64px;
  width: 40px;
  position: relative;

  svg {
    position: absolute;
    top: 8px;
  }
}

:deep(.temp-item) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

:deep(.temp-item span:nth-child(1)) {
  font-size: 14px;
}

:deep(.temp-item span:nth-child(2)) {
  font-size: 14px;
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .icon-container {
    svg {
      top: -8px;
      left: -12px;
      transform: scale(0.75);
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  :deep(.temp-item) {
    justify-content: flex-start;
    gap: 8px;
  }
}
</style>
