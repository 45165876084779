import { Note, TagCategory } from '@stellacontrol/model'
import { createState } from './organizations.state'

export const mutations = {
  /**
   * Initialize the organizations view before navigating
   * @param collapsedOrganizations Identifiers of organizations collapsed in the tree
   */
  initializeOrganizationsView (state, { collapsedOrganizations = [] }) {
    state.collapsedOrganizations = collapsedOrganizations
  },

  sessionStarted (state, { currentOrganization } = {}) {
    state.currentOrganization = currentOrganization
  },

  sessionEnded (state) {
    state.currentOrganization = null
  },

  /**
   * Stores organizations in the state
   * @param {Array[Organization]} organizations Organizations to store
   */
  storeOrganizations (state, { organizations = [] } = {}) {
    state.items = organizations
  },

  /**
   * Called after the initial application data has been loaded.
   * Used to massage and post-process the data, for example to create hierarchies,
   * recreate relationships between objects etc.
   */
  dataInitialized (state, { organizationProfiles, pricelist } = {}) {
    state.items = state.items.map(organization => {
      // Link organizations to their profiles
      organization.profile = organizationProfiles.find(p => p.id === organization.profileId)
      // Link organization's premium subscriptions to services
      for (const subscription of organization.wallet?.subscriptions || []) {
        subscription.service = pricelist.services.find(s => s.id === subscription.serviceId)
      }
      return organization
    })
  },

  /**
   * Stores all organizations in the state
   * @param items Organizations to store
   */
  storeAllOrganizations (state, { items = [] } = {}) {
    state.allItems = items
  },

  /**
   * Stores an organization in the state
   * @param organization Organization to store
   */
  storeOrganization (state, { organization } = {}) {
    if (organization) {
      saveOrganization(state.items, organization)
      saveOrganization(state.allItems, organization)
      state.lastUpdated = organization
    }
  },

  /**
   * Removes an organization from the state
   * @param organization Organization to remove
   */
  removeOrganization (state, { organization } = {}) {
    if (organization) {
      removeOrganization(state.items, organization)
      removeOrganization(state.allItems, organization)
    }
  },

  /**
   * A place has been removed from organization, update
   * @param id Identifier of the removed place
   * @param organization Organization where place has been removed
   */
  removeOrganizationPlace (state, { id: placeId, organization: { id } } = {}) {
    const organization = getOrganization(state.items || [], id)
    if (organization && placeId) {
      const index = (organization.places || []).findIndex(p => p.id === placeId)
      if (index > -1) {
        organization.places.splice(index, 1)
      }
    }
  },

  /**
   * Notification about edited user.
   * We need to update the user in the organization where he belongs to
   * @param user Stored user
   */
  storeUser (state, { user } = {}) {
    updateUser(state.items, user)
    updateUser(state.allItems, user)
  },

  /**
   * Notification about removed user.
   * We need to update the user in the organization where he belongs to
   * @param user Removed user
   */
  removeUser (state, { user = {} } = {}) {
    removeUser(state.items, user)
    removeUser(state.allItems, user)
  },

  /**
   * Notification about update of organization profile.
   * All organizations belonging to it need to be updated accordingly.
   * @param organizationProfile Updated organization profile
   */
  storeOrganizationProfile (state, { organizationProfile } = {}) {
    updateOrganizationProfile(state.items, organizationProfile)
    updateOrganizationProfile(state.allItems, organizationProfile)
  },

  /**
   * Adds organization tag
   * @param organization Organization to add the tag to
   * @param tag Tag to add
   */
  addOrganizationTag (state, { organization, tag }) {
    const organizationToTag = (state.items || []).find(item => item.id === organization.id)
    if (organizationToTag) {
      const { name, userId } = tag
      if (!organizationToTag.hasTag({ name, userId })) {
        organizationToTag.addTag({ name, userId, category: TagCategory.Organization })
      }
    }
  },

  /**
   * Removes organization tag
   * @param organization Organization to removes the tag from
   * @param tag Tag to remove
   */
  removeOrganizationTag (state, { organization, tag }) {
    const organizationToTag = (state.items || []).find(item => item.id === organization.id)
    if (organizationToTag) {
      const { name, userId } = tag
      if (organizationToTag.hasTag({ name, userId })) {
        organizationToTag.removeTag({ name, userId })
        saveOrganization(state.items, organization)
      }
    }
  },

  /**
  * Updates a Organization note
  * @param organization Organization
  * @param note Note to update
  * @param user User updating the notes
  */
  saveOrganizationNote (state, { organization: { id }, note, user } = {}) {
    const organization = (state.items || []).find(item => item.id === id)
    if (organization && note) {
      Note.update(organization, note, user)
      saveOrganization(state.items, organization)
    }
  },

  /**
   * Removes a note from Organization
   * @param organization Organization
   * @param note Note
   * @param user User removing the notes
   */
  removeOrganizationNote (state, { organization: { id }, note, user } = {}) {
    const organization = (state.items || []).find(item => item.id === id)
    if (organization && note) {
      Note.remove(organization, note, user)
      saveOrganization(state.items, organization)
    }
  },

  /**
   * Sets organization language and/or country
   * @param {Organization} organization Organization to update
   * @param {String} countryCode ISO 3166 Alpha-2 country code
   * @param {String} languageCode ISO 639-2 language code
   * @param {String} timezone Name of country timezone, as per https://www.iana.org/time-zones
   */
  setLocale (state, { organization: { id }, countryCode, languageCode, timezone } = {}) {
    const organization = (state.items || []).find(item => item.id === id)
    if (organization) {
      organization.countryCode = countryCode || organization.countryCode
      organization.languageCode = languageCode || organization.languageCode
      organization.timezone = timezone || organization.timezone
    }
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}

/**
 * Finds an index of an organization in the collection
 * @param items Items to scan
 * @param id Organization identifier
 */
function getOrganizationIndex (items, id) {
  return id && items ? items.findIndex(item => item.id === id) : -1
}

/**
 * Finds an organization in the collection
 * @param items Items to scan
 * @param id Organization identifier
 */
function getOrganization (items, id) {
  return id && items ? items.find(item => item.id === id) : -1
}

/**
 * Saves an organization in the specified list
 * @param items List of organizations
 * @param organization Organization to save
 */
function saveOrganization (items, organization) {
  if (items && organization) {
    const index = getOrganizationIndex(items, organization.id)
    if (index === -1) {
      items.push(organization)
    } else {
      items[index] = organization
    }
  }
}

/**
 * Removes an organization from the specified list
 * @param items List of organizations
 * @param organization Organization to remove
 */
function removeOrganization (items, organization) {
  if (items && organization) {
    const index = getOrganizationIndex(items, organization.id)
    if (index !== -1) {
      items.splice(index, 1)
    }
  }
}

/**
 * Updates user in the organization it belongs to
 * @param {Organization} organizations Organizations to update
 * @param {User} user User to update
 */
function updateUser (organizations, user) {
  if (user && organizations) {
    const organization = getOrganization(organizations, user.organizationId)
    if (organization) {
      organization.users = organization.users || []
      const i = organization.users.findIndex(u => u.id === user.id)
      if (i === -1) {
        organization.users.push(user)
      } else {
        organization.users[i] = user
      }
    }
  }
}

/**
 * Removes user from the organization where it currently belongs
 * @param {Organization} organizations Organizations to update
 * @param {User} user User to remove
 */
function removeUser (organizations, user) {
  if (user && organizations) {
    const organization = getOrganization(organizations, user.organizationId)
    if (organization) {
      organization.users = organization.users || []
      const i = organization.users.findIndex(u => u.id === user.id)
      if (i > -1) {
        organization.users.splice(i, 1)
      }
      if (user.isAdministrator && organization.administrators) {
        const ai = organization.administrators.findIndex(a => a.id === user.id)
        if (ai > -1) {
          organization.administrators.splice(ai, 1)
        }
      }
    }
  }
}

/**
 * Updates organization profile in all organizations belonging to it
 * @param organizations Organizations
 * @param organizationProfile Updated organization profile
 */
function updateOrganizationProfile (organizations, organizationProfile) {
  if (organizations && organizationProfile) {
    for (const organization of organizations) {
      if (organization.profileId === organizationProfile.id) {
        organization.profile = organizationProfile
      }
    }
  }
}
