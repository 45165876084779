/**
 * Entities collected in the application
 */
export const EntityType = {
  User: 'user',
  Organization: 'organization',
  OrganizationProfile: 'profile',
  Device: 'device',
  DeviceStatus: 'devicestatus',
  DeviceSnapshot: 'devicesnapshot',
  Place: 'place',
  // TODO Obsolete, remove
  FloorPlan: 'floor-plan',
  Plan: 'plan',
  AlertOccurrence: 'alert-occurrence',
  Notification: 'notification',
  Pricelist: 'pricelist',
  PremiumService: 'premium-service',
  PremiumServiceSubscription: 'premium-service-subscription',
  Dashboard: 'dashboard',
  Widget: 'widget',
  DeviceFlags: 'device-flags',
  ApplicationFlags: 'application-flags'
}

/**
 * User-friendly entity names
 */
export const EntityDescription = {
  [EntityType.User]: 'User',
  [EntityType.Organization]: 'Customer',
  [EntityType.OrganizationProfile]: 'Customer Profile',
  [EntityType.Device]: 'Device',
  [EntityType.DeviceStatus]: 'Device Status',
  [EntityType.DeviceSnapshot]: 'Device Snapshot',
  [EntityType.Place]: 'Building',
  [EntityType.FloorPlan]: 'Floor Plan',
  [EntityType.AlertOccurrence]: 'Alert Occurrence',
  [EntityType.Notification]: 'Notification',
  [EntityType.Pricelist]: 'Pricelist',
  [EntityType.PremiumService]: 'Premium Service',
  [EntityType.PremiumServiceSubscription]: 'Premium Service Subscription',
  [EntityType.Dashboard]: 'Dashboard',
  [EntityType.Widget]: 'Dashboard Widget',
  [EntityType.DeviceFlags]: 'Device Flags',
  [EntityType.ApplicationFlags]: 'Application Flags'
}
