/* eslint-disable */
import { APIClient } from './api-client'
import { Plan } from '@stellacontrol/model'

/**
 * StellaPlanner API client
 */
export class PlannerAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'Planner'
  }

  /**
   * Retrieves the specified plan
   * @param {String} id Plan identifier
   * @returns {Promise<Plan>}
   */
  async getPlan ({ id }) {
    try {
      const url = this.endpoint('plan', id)
      const { plan } = await this.request({ url })
      return this.asPlan(plan)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves a plan of the specified building
   * @param {String} id Building identifier
   * @param {Boolean} create If plan for the building does not exist, create it now
   * @returns {Promise<Plan>}
   */
  async getBuildingPlan ({ id, create }) {
    try {
      const url = this.endpoint('plan', 'place', id)
      const params = { create }
      const { plan } = await this.request({ url, params })
      return this.asPlan(plan)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves plans of an organization
   * @param {Organization} organization Organization whose plans to retrieve
   * @returns {Promise<Array[Plan]>}
   */
  async getPlans ({ organization }) {
    return []
  }

  /**
   * Saves a plan
   * @param {Plan} plan Plan to save
   * @returns {Promise<Plan>} Saved plan
   */
  async savePlan ({ plan }) {
    try {
      const { id } = plan
      const method = id == null ? 'post' : 'put'
      const url = this.endpoint('plan', id)
      const data = {
        ...plan,
        organization: undefined,
        place: undefined
      }
      const { plan: savedPlan } = await this.request({ method, url, data })
      return this.asPlan(savedPlan)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Marks the plan as being edited
   * @param {Plan} plan Plan to mark as edited
   * @param {Boolean} finish If `true`, the user has finished editing the plan
   * @returns {Promise<Plan>} Plan marked as edited
   */
  async editPlan ({ plan, finish }) {
    try {
      const { id } = plan
      const method = 'put'
      const url = this.endpoint('plan', id, 'edit')
      const data = { finish }
      const { plan: editedPlan } = await this.request({ method, url, data })
      return editedPlan

    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the specified plan
   * @param {Plan} plan Floor plan to delete
   * @returns {Promise<Plan>} Deleted plan
   */
  async deletePlan ({ plan: { id } }) {
    try {
      const method = 'delete'
      const url = this.endpoint('plan', id)
      const { plan } = await this.request({ method, url })
      return this.asPlan(plan)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Converts the specified data item
   * received from API to Plan instance
   * @param {Object} item Data item
   * @returns {Plan} Instance initialized with the content of the data item
   */
  asPlan (item) {
    if (item) {
      const plan = new Plan(item)
      return plan
    }
  }

  /**
   * Converts the specified data items
   * received from API to Plan instances
   * @param {Array} items Data items
   * @returns {Array[Plan]} Instances initialized with the content of the data items
   */
  asPlans (items = []) {
    return items.map(item => this.asPlan(item))
  }
}
