<script>
import '@stellacontrol/common-ui'
import { mapActions, mapGetters } from 'vuex'
import { sortOrganizations, OrganizationSortOrder } from '@stellacontrol/model'
import Panel from './panel'

export default {
  mixins: [
    Panel
  ],

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    // Returns true if notes panel is visible for the current user
    isVisible () {
      return this.canUse('edit-device-notes') ||
      this.device?.hasNotesOf(this.currentUser)
    },

    // Widget title
    title () {
      return 'Notes'
    },

    // All organizations to which notes can be addressed.
    // We include:
    // - child organizations
    // We ignore:
    // - guest organizations
    recipients () {
      const { organizations, currentOrganization } = this
      return sortOrganizations(organizations, OrganizationSortOrder.Rank, { currentOrganization })
        .filter(o => o)
        .filter(o => o.id !== currentOrganization.id && !o.isGuestOrganization)
    },

    // Returns true if editing notes is possible
    canEditNotes () {
      return this.canUse('edit-device-notes')
    }
  },

  methods: {
    ...mapActions([
      'saveDeviceNote',
      'removeDeviceNote'
    ]),

    async updateNote ({ note }) {
      const { device } = this
      await this.saveDeviceNote({ device, note })
    },

    async removeNote ({ note }) {
      const { device } = this
      await this.removeDeviceNote({ device, note })
    },
  }
}
</script>

<template>
  <div v-if="isVisible" class="widget panel-widget device-notes">
    <sc-notes :title="title" add-label="Note" :notes="device.notes" :entity="device" :recipients="recipients"
      :popup="false" :creator="currentUser"
      :edit-mode="canEditNotes ? (isSuperAdministrator ? 'all' : 'own') : 'none'" category="device"
      @update="updateNote" @remove="removeNote">
    </sc-notes>
  </div>
</template>

<style lang="scss" scoped>
.device-notes {
  max-height: 305px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}
</style>
