import { parseBoolean, safeParseInt } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'

/**
 * Plan radiation map layout
 */
export class PlanRadiation extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      lockWalls: false,
      strength: 100
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.lockWalls = parseBoolean(this.lockWalls, defaults.lockWalls)
    this.strength = safeParseInt(this.strength, defaults.strength)
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = {
      ...this
    }
    return this.clearDefaults(result)
  }

  /**
   * Locks walls and yards in place.
   * To modify or delete them, lock must be disabled.
   * This is to prevent accidental amendments to the radiation layer,
   * which is normally fixed and defined early during design
   * @type {Boolean}
   */
  lockWalls

  /**
   * If true, radiation in the areas outside the
   * defined building perimeter is masked
   * @type {Boolean}
   */
  get maskOutside () {
    return Boolean(this.lockWalls)
  }
}
