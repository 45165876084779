<script>
import { endOfDay, isToday, addDays } from 'date-fns'
import { DateFormats, parseDate, formatDate, countString, Period, TimeDirection } from '@stellacontrol/utilities'
import { DateRange } from '@stellacontrol/model'
import Buttons from './date-range-buttons.vue'

export default {
  components: {
    'sc-date-range-buttons': Buttons
  },

  props: {
    /**
     * Initial value
     */
    modelValue: {
      required: true
    },

    // Quick-selection periods
    periods: {
      type: Array,
      default: () => ([
        { type: Period.Day, count: 1, days: 1, label: 'Select today' },
        { type: Period.Day, count: 3, days: 3, label: 'Select the last 3 days' },
        { type: Period.Day, count: 7, days: 7, label: 'Select the last 7 days' },
        { type: Period.Day, count: 30, days: 30, label: 'Select the last 30 days' }
      ])
    },

    /**
     * Calendar popup title
     */
    popupTitle: {
      type: String,
      default: 'Select start and end date'
    },

    /**
     * Label displayed in the FROM text input
     */
    labelFrom: {
      type: String,
      default: 'From'
    },

    /**
     * Label displayed in the TO text input
     */
    labelTo: {
      type: String,
      default: 'To'
    },

    /**
     * Date format
     */
    format: {
      type: String,
      default: DateFormats.date.toUpperCase()
    },

    /**
     * First day of week, Monday by default
     */
    firstDayOfWeek: {
      type: Number,
      default: 1
    },

    /**
     * If true, today will be selected as default date range
     */
    defaultToday: {
      type: Boolean,
      default: true
    },

    /**
     * If true, the inputs are stacked vertically
     */
    vertical: {
      type: Boolean,
      default: false
    },

    /**
     * If true, shows the extended input with navigation buttons
     */
    extended: {
      type: Boolean,
      default: false
    },

    /**
     * Lower bound of the possible date selection, inclusive
     */
    min: {
      type: Date,
      default: null
    },

    /**
     * Upper bound of the possible date selection, inclusive
     */
    max: {
      type: Date,
      default: endOfDay(new Date())
    },

    /**
     * Maximal duration of the selected period, in days
     */
    maxLength: {
    },

    /**
     * Additional validation rules
     */
    rules: {
      type: Array
    },

    lazyRules: {
      type: Boolean,
      default: false
    },

    /**
     * Quasar q-date properties
     */
    minimal: {
      type: Boolean,
      default: false
    },

    todayButton: {
      type: Boolean,
      default: true
    },

    /**
     * Quasar q-input properties
     */
    square: {
      type: Boolean,
      default: true
    },

    outlined: {
      type: Boolean,
      default: true
    },

    dense: {
      type: Boolean,
      default: true
    },

    readonly: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    bgColor: {
      type: String
    },

    inputStyle: {
      type: Object,
      default: () => ({
        width: '110px'
      })
    }
  },

  data () {
    return {
      console,
      Period,
      TimeDirection,

      // Selected date range
      range: null,
      // Range length
      period: Period.Day
    }
  },

  computed: {
    // Text representation of the FROM date
    textFrom () {
      const { range } = this
      return formatDate(range?.from)
    },

    // Text representation of the TO date
    textTo () {
      const { range } = this
      return formatDate(range?.to)
    },

    // Determines whether the calendar popup should be shown in minimal mode
    isPopupMinimal () {
      return !this.popupTitle || this.minimal === true
    },

    // Label describing the length of a period by which the user would jump,
    // when pressing BACK or FORWARD buttons
    periodJump () {
      return direction => {
        const { range, period } = this
        if (range) {
          if (period === Period.Day && range.length <= 1) {
            if (isToday(range.from)) {
              return direction === TimeDirection.Back ? 'Yesterday' : 'Tomorrow'
            } else {
              return direction === TimeDirection.Back ? 'Previous day' : 'Next day'
            }

          } else if (period === Period.Week) {
            return direction === TimeDirection.Back ? 'Previous week' : 'Next week'

          } else if (period === Period.Month) {
            return direction === TimeDirection.Back ? 'Previous month' : 'Next month'

          } else {
            return `${countString(range.length, 'day')} ${direction === TimeDirection.Back ? 'back' : 'forward'}`
          }
        }
        return ''
      }
    },

    // Determines whether the specified period has been selected by the user
    isPeriodSelected () {
      return period => this.period === period.type &&
        this.range.length === period.days
    }
  },

  methods: {
    // Applies the defined range limits such as min/max date and range span
    applyLimits (range) {
      if (!range) return

      const { min, max, maxLength } = this

      if (min && range.from < min) {
        range.date = min
      }

      if (max && range.to > max) {
        range.to = max
      }

      if (range.from > range.to) {
        range.from = range.to
      }

      if (range.to < range.from) {
        range.to = range.from
      }

      if (maxLength > 0 && range.length > maxLength) {
        range.to = addDays(range.from, maxLength)
      }

      return range
    },

    // Checks if the specified date from q-date component is valid
    // for the user to select it
    isValidDate (value) {
      const { min, max } = this
      const date = parseDate(value)
      if (min && date < min) {
        return false
      }
      if (max && date > max) {
        return false
      }
      return true
    },

    /**
     * Called when dates are selected in date picker,
     * stores the selected dates in the model
     * and updates the text fields
     */
    setRange ({ range, emit = true } = {}) {
      this.range = this.applyLimits(range)
      if (emit) {
        this.$emit('update:model-value', this.range)
      }
    },

    // Clears the input
    clear () {
      this.setRange()
    },

    /**
     * Checks if the specified value is a valid date.
     * Empty input is treaded as valid - if you want to enforce
     * the input, use an additional `required` rule
     */
    isDate (value = '') {
      if (value) {
        const invalidDate = Quasar.date.extractDate('', this.format)
        const date = Quasar.date.extractDate(value, this.format)
        return date && date.toString() !== invalidDate.toString()
      } else {
        return true
      }
    }
  },

  watch: {
    modelValue (range) {
      if (range) {
        this.setRange({ range, emit: false })
      }
    }
  },

  created () {
    const { modelValue, defaultToday } = this
    const range = modelValue || (defaultToday ? DateRange.today() : null)
    this.setRange({ range, emit: false })
  }
}
</script>

<template>
  <main :class="{ vertical }" v-bind="{ ...$attrs }">
    <section class="dates">
      <q-input :model-value="textFrom" :square="square" :outlined="outlined" :dense="dense"
        :disabled="disabled" :label="labelFrom"
        :rules="[value => isDate(value) || 'Invalid date', ...rules || []]" :lazy-rules="lazyRules"
        debounce="500" hide-bottom-space :bg-color="bgColor" readonly
        :inputStyle="{ ...inputStyle, 'padding-right': '4px' }">
      </q-input>

      <q-input :model-value="textTo" :square="square" :outlined="outlined" :dense="dense"
        :disabled="disabled" :label="labelTo"
        :rules="[value => isDate(value) || 'Invalid date', ...rules || []]" :lazy-rules="lazyRules"
        debounce="500" hide-bottom-space :bg-color="bgColor" readonly
        :inputStyle="{ ...inputStyle, 'padding-right': '4px' }">
      </q-input>

      <sc-date-range-buttons v-if="!extended" :model-value="modelValue" @update:model-value="range => setRange({ range })"
        :popup-title="popupTitle" :format="format" :firstDayOfWeek="firstDayOfWeek"
        :min="min" :max="max" :max-length="maxLength" :minimal="minimal" :today-button="todayButton"
        :square="square" :outlined="outlined" :dense="dense" calendar-only>
      </sc-date-range-buttons>
    </section>

    <section class="buttons" v-if="extended">
      <sc-date-range-buttons :model-value="modelValue" @update:model-value="range => setRange({ range })"
        :popup-title="popupTitle" :format="format" :firstDayOfWeek="firstDayOfWeek"
        :min="min" :max="max" :max-length="maxLength" :minimal="minimal" :today-button="todayButton"
        :square="square" :outlined="outlined" :dense="dense">
      </sc-date-range-buttons>
    </section>

  </main>
</template>

<style lang="scss" scoped>
:deep(.q-field__control::before) {
  border-style: solid !important;
}

:deep(.q-date__header) {
  height: 54px;
}

:deep(.q-date__header-title) {
  display: none;
}

:deep(.q-date__header-subtitle.q-date__header-link) {
  opacity: 1;
}

main {
  display: flex;
  flex-direction: column;
  gap: 8px;

  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;

    &.buttons {
      gap: 0;

      .left,
      .right {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }

      .button-period {
        width: 28px;
        font-size: 12px;
      }

      .button-calendar {
        width: 28px;
        font-size: 12px;
      }

      .button-jump {
        width: 22px;
        font-size: 12px;
      }
    }
  }

  &.vertical {
    section.dates {
      flex-direction: column;
      align-items: start;
    }
  }
}
</style>
