import InstallationsView from './installations.vue'
import { dispatch, isSameRoute } from '@stellacontrol/client-utilities'
import { resolve } from './installations.resolve'

export const Routes = [
  // Installations view
  {
    name: 'installations',
    path: '/installations',
    component: InstallationsView,

    async beforeEnter (to, from, next) {
      // Check whether we should restore the last visited route under Installations.
      const restoreLastRoute = to.query['restore']
      if (restoreLastRoute) {
        const route = await dispatch('getLastBuildingsRoute') || to
        if (!isSameRoute(route, to)) {
          return next(route)
        }
      }

      // Check if any filter is specified in the query,
      // store and reload
      const filter = to.query['filter']
      const exact = to.query['exact']
      if (filter?.trim()) {
        await dispatch('filterBuildings', {
          filter: decodeURIComponent(filter),
          exact
        })
        return next({ name: 'installations', query: null })
      }

      // Otherwise proceed to the originally requested route
      const { redirectTo } = await resolve({ from, to }) || {}
      if (redirectTo) {
        next(redirectTo)
      } else {
        dispatch('storeBuildingsRoute', { route: to })
        next()
      }
    }
  }
]
