<script>
import Konva from 'konva'
import { mapActions } from 'vuex'
import { Log, Point } from '@stellacontrol/utilities'
import { Confirmation, Notification } from '@stellacontrol/client-utilities'
import { PlanLineStyle, PlanLineType } from '@stellacontrol/planner'

export default {
  props: {
    // Edited plan
    plan: {
      required: true
    },
    // Images to edit
    images: {
    },
    // Use when in development, to skip uploading of the images to S3
    development: {
    }
  },

  data () {
    return {
      // Indicates that the view has been initialized
      isInitialized: false,
      // Edited images
      edited: [],
      // Indicates that we're now loading an image to be edited
      isLoadingImage: false,
      // Edited image
      image: null,
      // Rendering stage
      stage: null,
      // Indicates that mouse cursor is inside the edited image
      imageHover: false,
      // Properties for setting the map scale
      mapScale: {
        // Image for which the scale is being set
        image: null,
        // Start point of the map scale line
        start: null,
        // End point of the map scale line
        end: null,
        // Style for the map scale line
        style: {
          color: 'red',
          width: 3,
          dash: PlanLineStyle.getLineDash(PlanLineType.Dashed, 3)
        }
      },
      // Image being dragged
      draggedImage: null
    }
  },

  computed: {
    // Image tooltip
    getImageTooltip () {
      return image => {
        if (image) {
          return `${image.description}`
        }
      }
    },

    // CSS style for the image thumbnail
    getImageThumbnail () {
      return (image) => {
        if (image) {
          return {
            'background-size': 'cover',
            'background-image': `url(${image.reference})`
          }
        }
      }
    },

    // Image canvas style
    getCanvasStyle () {
      if (this.isLoadingImage) {
        return {
          visibility: 'hidden'
        }
      }
    },

    // Checks whether the specified image is selected for editing
    isImageSelected () {
      return image => image?.hash === this.image?.hash
    },

    // Returns style for the map scale line
    mapScaleStyle () {
      return image => image.mapScale > 0
        ? { width: `${image.mapScale}px` }
        : {}
    }
  },

  methods: {
    ...mapActions([
      'removePlanImage'
    ]),

    // Notifies about the edited images
    async changed (updateData) {
      const { image } = this
      const data = image && updateData
        ? await this.getImageData()
        : undefined
      this.$emit('changed', { image, data })
    },

    // Notifies about the removed image
    async removed (image) {
      this.$emit('removed', { image })
    },

    // Removes the specified image from the image list
    async removeImage (image) {
      if (image) {
        // Dispose of the data URL
        URL.revokeObjectURL(image.reference)

        // Remove from the list
        const { plan } = this
        const index = this.edited.findIndex(i => i.hash === image.hash)
        this.edited = this.edited.filter(i => i.hash !== image.hash)

        // If removed the currently edited image, show predecessor
        if (image === this.image) {
          this.image = this.edited[index - 1]
        }

        // Remove from the external storage
        this.removePlanImage({ image, plan })

        this.removed(image)
      }
    },

    // Moves the specified image before the given one
    moveImageTo (image, to) {
      const source = this.edited.findIndex(i => i.hash === image.hash)
      const target = this.edited.findIndex(i => i.hash === to.hash)
      const index = target > source ? target + 1 : target
      this.edited = [
        ...this.edited.slice(0, index).filter(i => i.hash !== image.hash),
        image,
        ...this.edited.slice(index).filter(i => i.hash !== image.hash)
      ]
      this.changed()
    },

    // Selects the specified image for editing
    async selectImage (image) {
      if (this.image != image) {
        this.isLoadingImage = true
        await this.loadImage(image)
      }
    },

    // Loads the selected image into canvas
    loadImage (image) {
      return new Promise((resolve) => {

        this.isLoadingImage = true

        // Get rid of the previous image
        if (this.stage) {
          this.stage.destroy()
        }

        // Render the image inside the canvas
        Konva.pixelRatio = window.devicePixelRatio
        const imageUrl = image.reference
        const container = this.$refs.canvasContainer
        const stage = new Konva.Stage({ container, width: 1000, height: 1000 })
        const layer = new Konva.Layer({ id: 'layer' })

        const { mapScale } = this
        const scale = new Konva.Arrow({
          id: 'scale',
          visible: false,
          dash: mapScale.style.dash,
          stroke: mapScale.style.color,
          strokeWidth: mapScale.style.width,
          pointerAtBeginning: true,
          pointerAtEnding: true,
          pointerLength: 0,
          pointerWidth: 10
        })
        layer.add(scale)

        stage.add(layer)
        this.stage = stage

        // Wire up layer events, for drawing map scales, setting transparency etc.
        layer.on('click', e => this.clickImageHandler(e.evt, image))
        layer.on('mousemove', e => this.moveOverImageHandler(e.evt, image))

        const imageFailed = error => {
          Log.error(`The image ${imageUrl} could not be loaded`)
          Log.exception(error)
          this.isLoadingImage = false
          resolve()
        }

        const imageLoaded = async i => {
          // Takes care of obscure race condition, when user chose to cancel or proceed
          // while image was still loading
          if (!this.isInitialized) return

          const { stage } = this
          const { width, height } = i.size()

          image.width = width
          image.height = height
          image.scale = 100

          stage.size({ width, height })
          i.id('image')

          // Make the image draggable, but prevent from going left,
          // and too far right - so there's always something to grab onto!
          i.listening(true)
          i.draggable(true)
          i.on('dragmove', () => {
            const scale = image.scale / 100
            const actualWidth = image.width * scale
            const actualHeight = image.height * scale
            const margin = 50
            if (i.x() > 0) {
              i.x(0)
            }
            if (i.y() > 0) {
              i.y(0)
            }
            if (i.x() < -actualWidth + margin) {
              i.x(-actualWidth + margin)
            }
            if (i.y() < -actualHeight + margin) {
              i.y(-actualHeight + margin)
            }
            image.x = i.x() / scale
            image.y = i.y() / scale
          })

          layer.add(i)
          this.fitImageToContainer(image)
          this.applyFilters(image)

          image.content = await this.getImageData()
          this.isLoadingImage = false
          this.image = image

          resolve()
        }

        Konva.Image.fromURL(imageUrl, imageLoaded, imageFailed)
      })
    },

    // Returns the image element on the canvas
    getImageElement () {
      const layer = this.stage.findOne('#layer')
      const element = layer.findOne('#image')
      return element
    },

    // Returns `true` if image element exists on the canvas
    hasImageElement () {
      return this.getImageElement() != null
    },

    // Returns the map scale element on the canvas
    getMapScaleElement () {
      const layer = this.stage.findOne('#layer')
      const element = layer.findOne('#scale')
      return element
    },

    // Fits the image to the container width
    fitImageToContainer (image) {
      const imageElement = this.getImageElement()
      const container = this.$refs.imageContainer

      if (container && imageElement) {
        const containerWidth = container.offsetWidth
        const scale = Math.round(100 * (containerWidth / image.width)) / 100
        imageElement.scale({ x: scale, y: scale })
        imageElement.position({ x: 0, y: 0 })
        image.scale = Math.round(scale * 100)
      }
    },

    // Zooms in/out
    zoomImage (image, delta) {
      const imageElement = this.getImageElement()
      if (!imageElement) return

      if (delta == null) {
        image.scale = 100
      } else {
        image.scale = Math.min(500, Math.max(10, image.scale + delta))
        // Round up to the nearest multiply of 10
        image.scale = 10 * (
          delta > 0
            ? Math.ceil(image.scale / 10)
            : Math.floor(image.scale / 10)
        )
      }

      const scale = image.scale / 100
      imageElement.scale({ x: scale, y: scale })
    },

    // Rotates the image
    rotateImage (image) {
      const imageElement = this.getImageElement()
      if (!imageElement) return

      if (image) {
        // image.rotation = image.rotation + 90
        // if (image.rotation >= 360) {
        image.rotation = 0
        // }
      }
    },

    // Toggles a filter on the image
    filterImage (filter, on, value) {
      const imageElement = this.getImageElement()
      if (!imageElement) return

      let filters = Array.from(imageElement.filters() || [])
      const { length } = filters

      if (on && !filters.includes(filter)) {
        filters.push(filter)
      } else if (!on) {
        filters = filters.filter(f => f !== filter)
      }

      if (length !== filters.length) {
        imageElement.cache()
        imageElement.filters(filters)
      }

      // Assign filter value
      if (on && value != null) {
        const fields = {
          [Konva.Filters.Brighten]: 'brightness',
          [Konva.Filters.Contrast]: 'contrast'
        }
        const field = fields[filter]
        if (field) {
          imageElement[field](value)
        }
      }
    },

    // Makes the image black and white
    desaturateImage (image, value) {
      if (value != null) {
        image.bw = value
      }
      this.filterImage(Konva.Filters.Grayscale, image.bw)
    },

    // Sets the contrast of the image
    setImageContrast (image, value) {
      if (value != null) {
        image.contrast = value
      }
      if (image.contrast === 0) {
        this.filterImage(Konva.Filters.Contrast, false)
      } else {
        this.filterImage(Konva.Filters.Contrast, true, image.contrast)
      }
    },

    // Sets the brightness of the image
    setImageBrightness (image, value) {
      if (value != null) {
        image.brightness = value
      }
      if (image.brightness === 0) {
        this.filterImage(Konva.Filters.Brighten, false)
      } else {
        this.filterImage(Konva.Filters.Brighten, true, image.brightness / 100)
      }
    },

    // Applies all currently set filters to the image
    applyFilters (image) {
      this.desaturateImage(image)
      this.setImageContrast(image)
      this.setImageBrightness(image)
    },

    /**
     * Returns the data for the currently edited images, after applying filters
     * @param {Object} options Export options, as per https://konvajs.org/api/Konva.Image.html#toDataURL__anchor
     * @returns {Promise<Uint8Array>}
     */
    async getImageData ({
      x,
      y,
      width,
      height,
      mimeType = 'image/png',
      quality = 1,
      pixelRatio = 1,
      imageSmoothingEnabled = false
    } = {}) {
      const { image } = this
      const imageElement = this.getImageElement()
      if (!(image && imageElement)) return

      if (imageElement.visible()) {
        const blob = await imageElement.toBlob({
          x: x || imageElement.x(),
          y: y || imageElement.y(),
          width: width || imageElement.width(),
          height: height || imageElement.height(),
          mimeType,
          quality,
          pixelRatio,
          imageSmoothingEnabled
        })

        const buffer = await blob.arrayBuffer()
        return new Uint8Array(buffer)
      }
    },

    // Starts setting the map scale
    async startMapScale (image) {
      if (image) {
        this.mapScale.image = image
        this.mapScale.start = null
        this.mapScale.end = null
      }
    },

    // Finishes setting the map scale
    async finishMapScale () {
      if (this.mapScale.image) {
        // Finish setting map scale
        const { image, start, end } = this.mapScale
        if (start && end) {
          // Ask for length of the selected line
          const length = await Confirmation.prompt({
            title: 'Length in meters',
            message: 'What is the length of the line in meters?',
            number: true,
            min: 1,
            max: 1000,
            step: 0.1
          })

          if (length > 0) {
            // When calculating distance between scale points,
            // take into consideration the current image scale
            const distance = start.distance(end) / (image.scale / 100)
            const mapScale = Math.round(distance / length)

            // Ask whether to apply the same scale to all other floors
            const applyToAll = await Confirmation.ask({
              title: 'Apply to all floors?',
              message: 'Apply the same map scale to all other floors?'
            })
            if (applyToAll) {
              for (const image of this.edited) {
                image.mapScale = mapScale
              }
            } else {
              image.mapScale = mapScale
            }

            Notification.success({
              title: image.description,
              message: `Map scale has been set to 1m = ${image.mapScale}px`
            })
          }
        }

        this.stopMapScale()
        this.changed()
      }
    },

    // Cancels setting the map scale
    async stopMapScale () {
      this.mapScale.image = null
      this.mapScale.start = null
      this.mapScale.end = null

      const scale = this.getMapScaleElement()
      scale.points([])
      scale.visible(false)
    },

    // Sets the point of the currently drawn map scale line
    async setMapScalePoint ({ image, x, y, finish }) {
      if (image) {
        const { mapScale } = this

        if (mapScale.start) {
          const scale = this.getMapScaleElement()
          mapScale.end = Point.from({ x, y })
          scale.points([
            ...mapScale.start.toArray(),
            ...mapScale.end.toArray()
          ])
          if (!scale.visible()) {
            scale.visible(true)
            scale.moveToTop()
          }
          if (finish) {
            this.finishMapScale()
          }
        } else {
          this.mapScale.start = Point.from({ x, y })
        }
      }
    },

    // Triggered when mouse wheel is turned,
    // allows scaling the image if event happened while mouse pointer was inside the image
    mouseWheelHandler (e) {
      const { image, imageHover } = this
      if (image && imageHover) {
        e.preventDefault()
        this.zoomImage(image, e.deltaY < 0 ? 10 : -10)
        return
      }
    },

    // Triggered when mouse is moved inside the image.
    // Combined with right-mouse click, it allows panning the image.
    moveOverImageHandler (event, image) {
      // If drawing map scale, update the end point
      if (this.mapScale.start) {
        event.preventDefault()
        this.setMapScalePoint({ image, x: event.offsetX, y: event.offsetY })
        return
      }
    },

    // Triggered when image is clicked
    clickImageHandler (event, image) {
      // When in map scale mode, collect scale points
      if (image && this.mapScale.image === image) {
        event.preventDefault()
        const finish = this.mapScale.start != null
        this.setMapScalePoint({ image, x: event.offsetX, y: event.offsetY, finish })
        return
      }
    },

    // Image dragging started
    imageDrag (event, image) {
      this.draggedImage = image
      if (image) {
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.setData('text/plain', image.hash)
      }
    },

    // Image is being dragged over another image
    imageDragOver (event, image) {
      const { draggedImage } = this
      if (image && draggedImage) {
        if (image && image.hash !== draggedImage.hash) {
          event.preventDefault()
        }
      }
    },

    // Image is dropped over another image, reorder
    imageDrop (event, image) {
      const { draggedImage } = this
      if (image && draggedImage) {
        if (image && image.hash !== draggedImage.hash) {
          event.preventDefault()
          this.moveImageTo(draggedImage, image)
        }
      }
    }
  },

  emits: [
    'changed',
    'removed'
  ],

  watch: {
    images (images = []) {
      this.edited = [...images]
      this.$nextTick(() => {
        this.selectImage(this.edited[0])
      })
    }
  },

  mounted () {
    this.edited = [...this.images]
    this.$nextTick(() => {
      this.isInitialized = true
      this.selectImage(this.edited[0])
      const { canvasContainer } = this.$refs
      canvasContainer.addEventListener('wheel', this.mouseWheelHandler, { passive: false })
    })
  },

  beforeUnmount () {
    this.isInitialized = false
  }

}
</script>

<template>
  <div class="editing">
    <aside class="images">
      <div class="image" v-for="image in edited" :key="image.hash"
        :class="{ selected: isImageSelected(image) }" :title="getImageTooltip(image)"
        @click.stop="selectImage(image)" draggable="true"
        @dragstart="event => imageDrag(event, image)"
        @dragenter="event => imageDragOver(event, image)"
        @dragover="event => imageDragOver(event, image)" @drop="event => imageDrop(event, image)">
        <div class="title">
          <span class="image-name">
            {{ image.floorName || image.description }}
          </span>
          <span class="delete-button">
            <q-btn flat round dense icon="close" color="red-7" size="sm"
              @click.stop="removeImage(image)"></q-btn>
          </span>
        </div>
        <div class="thumbnail" :style="getImageThumbnail(image)">
        </div>
      </div>
    </aside>

    <div class="preview">
      <div class="image-details bg-indigo-6 text-white" v-if="image">
        <template v-if="mapScale.image">
          <!-- Controls for setting map scale -->
          <q-icon name="straighten" color="white" class="q-ml-sm q-mr-sm" size="24px"></q-icon>
          <span>
            Click on two points to select a distance.
          </span>
          <q-btn flat dense label="Cancel" text-color="orange" class="q-ml-md" size="14px"
            @click.stop="stopMapScale()"></q-btn>
        </template>

        <template v-else>
          <!-- Controls for zooming in and out -->
          <q-icon name="info" color="white" class="q-ml-sm q-mr-sm" size="24px"></q-icon>
          <span>
            {{ image.description }}
          </span>
          <q-space></q-space>
          <q-btn round flat dense icon="zoom_out" text-color="white" class="q-ml-md" size="16px"
            @click="zoomImage(image, -10)">
            <sc-tooltip>Zoom out</sc-tooltip>
          </q-btn>
          <span>
            <q-btn flat dense :label="`${image.scale}%`" text-color="white" size="14px"
              style="width: 50px;" @click="fitImageToContainer(image)">
              <sc-tooltip>Fit to container</sc-tooltip>
            </q-btn>
          </span>
          <q-btn round flat dense icon="zoom_in" text-color="white" size="16px"
            @click="zoomImage(image, 10)" class="q-mr-sm">
            <sc-tooltip>Zoom in</sc-tooltip>
          </q-btn>
        </template>
      </div>

      <div ref="imageContainer" class="image-container" @mouseenter="imageHover = true"
        @mouseleave="imageHover = false">

        <!-- image preview -->
        <div ref="canvasContainer" class="image-canvas" @contextmenu="e => e.preventDefault()"
          :style="getCanvasStyle">
        </div>

        <!-- image scale -->
        <div v-if="!isLoadingImage && image?.mapScale" class="map-scale"
          :style="mapScaleStyle(image)">
          1m
        </div>

        <!-- image loading indicator -->
        <div v-if="isLoadingImage" class="image-loading text-grey-7 text-h6">
          <sc-busy title="Loading ..." size="sm"></sc-busy>
        </div>

      </div>
    </div>

    <div class="properties">
      <template v-if="image">
        <q-input label="Floor name" dense outlined square v-model="image.floorName"></q-input>

        <q-btn label="Black/White" icon="invert_colors" align="left" no-caps unelevated
          class="q-mt-md" :class="image.bw ? 'primary' : undefined"
          @click="desaturateImage(image, !image.bw); changed(true);"></q-btn>
        <q-btn label="Set Scale" icon="straighten" no-caps unelevated class="q-mt-sm" align="left"
          @click="mapScale.image ? stopMapScale() : startMapScale(image)"
          :color="mapScale.image ? 'indigo-8' : undefined"></q-btn>
        <q-btn label="Rotate" icon="refresh" no-caps unelevated class="q-mt-sm" align="left"
          @click="rotateImage(image); changed(true);"></q-btn>

        <q-slider v-model="image.brightness" class="q-mt-sm q-mb-md" :min="-100" :max="100" label
          switch-label-side :label-value="'Brightness: ' + image.brightness + '%'" label-always
          @update:model-value="setImageBrightness(image)" @change="changed(true)"></q-slider>
        <q-slider v-model="image.contrast" class="q-mt-lg q-mb-lg" :min="-100" :max="100" label
          switch-label-side :label-value="'Contrast: ' + image.contrast + '%'" label-always
          @update:model-value="setImageContrast(image)" @change="changed(true)"></q-slider>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.editing {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .images {
    flex-basis: 150px;
    gap: 5px;
    overflow: hidden;
    overflow-y: auto;

    .image {
      width: 140px;
      height: 140px;
      margin: 0px 0px 10px 0px;
      border: solid #23295c 1px;
      background-color: #e8e8e8;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        border-color: #3b47b6;
      }

      &.selected {
        margin: 0 0 10px 0;
        border-color: #3b47b6;
        border-width: 2px;
      }

      .title {
        flex-basis: 34px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        background-color: #c0c0c0;
        border-bottom: solid #808080 1px;
        padding: 4px;
        overflow: hidden;

        .image-name {
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .delete-button {}
      }

      .thumbnail {
        flex: 1;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .preview {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    border: solid #23295c 1px;
    position: relative;
    overflow: hidden;

    .image-details {
      flex-basis: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .image-container {
      flex: 1;
      position: relative;
      overflow: hidden;

      .image-canvas {
        position: absolute;
        z-index: 1;
      }

      .map-scale-selector {
        position: absolute;
        z-index: 2;
      }

      .map-scale {
        position: absolute;
        z-index: 3;
        left: 10px;
        top: 10px;
        height: 12px;
        border-left: solid black 2px;
        border-right: solid black 2px;
        border-bottom: solid black 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        color: black;
      }

      .image-loading {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

    }
  }

  .properties {
    flex: 0;
    flex-basis: 150px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
}
</style>