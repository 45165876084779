import { delay, countString, Point, distinctItems } from '@stellacontrol/utilities'
import { Notification } from '@stellacontrol/client-utilities'
import { PlanFloors } from '@stellacontrol/planner'
import { PlanAction, PlanActions } from './plan-action'
import { AddItemAction } from './add-item'
import { PlanClipboard } from '../utilities/clipboard'

/**
 * Duplicates the specified items
 */
export class DuplicateItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.DuplicateItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    const { isBatch, count } = this
    return isBatch ? `Duplicate ${count} items` : 'Duplicate'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'splitscreen'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  async execute ({ renderer, items } = {}) {
    if (renderer && items) {
      const copy = new CopyItemsAction({ silent: true })
      await copy.execute({ renderer, items })

      const sourceBounds = renderer.selection.bounds
      const position = sourceBounds.rightTop.moveBy({ x: 20 })
      const paste = new PasteItemsAction({
        message: `${countString(items, 'shape')} duplicated`,
      })
      const pastedItems = await paste.execute({
        renderer, position,
        clear: true
      })

      await renderer.selectItems({
        items: pastedItems,
        showMenu: true
      })
    }
  }
}

/**
 * Copies the specified items to the clipboard
 */
export class CopyItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.CopyItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    const { isBatch, count } = this
    return isBatch ? `Copy ${count} items` : 'Copy'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'content_copy'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  execute ({ renderer, items } = {}) {
    // Ignore some items such as auto-created plugs and partial cables.
    items = items.filter(i => i.canCopyPaste)
    if (!(renderer && items?.length > 0)) return

    // Include connectors only when both of the copied ends are included amongst the copied items.
    let itemsToCopy = items.filter(i =>
      !i.isConnector ||
      (items.find(({ id }) => id === i.start.itemId) && items.find(({ id }) => id === i.end.itemId)))

    if (itemsToCopy.length === 0) return

    // Get the boundaries of the copied item
    const { layout } = renderer
    const bounds = {
      [PlanFloors.CrossSection]: layout.crossSection.getItemsBounds(items)
    }
    const floors = distinctItems(
      itemsToCopy.map(i => i.floorId).filter(floorId => floorId != null),
      'floorId'
    )
    for (const floorId of floors) {
      const floor = layout.getFloor(floorId)
      const floorItems = itemsToCopy.filter(i => i.floorId === floorId)
      bounds[floorId] = floor.getItemsBounds(floorItems)
    }

    // Place the items in the clipboard, remembering their original position
    const sourcePosition = renderer.selection.position || renderer.currentPosition
    PlanClipboard.write(itemsToCopy, sourcePosition, bounds)

    // Indicate that there is content in the clipboard
    renderer.copy()

    if (!(this.message || this.silent)) {
      Notification.success({
        message: `${countString(itemsToCopy, 'shape')} copied`,
        details: 'Press CTRL+V to paste'
      })
    }

    // If building wall was copied, de-select it now
    if (itemsToCopy.some(i => i.isWall || i.isYard)) {
      renderer.deselect()
    }
  }

  /**
   * Verifies whether the specified action can be undone.
   * Override in descendants to prevent specific actions from participating in UNDO history.
   * @param {Object} parameters Action parameters
   * @returns {Boolean}
   */
  // eslint-disable-next-line no-unused-vars
  canUndo (parameters) {
    return false
  }
}

/**
 * Pastes specified items from the clipboard
 */
export class PasteItemsAction extends PlanAction {
  constructor (data = {}) {
    super(data)
  }

  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.PasteItems
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return super.label || 'Paste'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'content_paste'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Position at which the action should be executed
   * @param {Boolean} clear If `true`, the clipboard is cleared after the paste
   * @returns {Array[PlanItem]} Pasted items
   */
  async execute ({ renderer, position, clear } = {}) {
    if (renderer) {
      const { layout, isCrossSection } = renderer

      // Get clipboard content
      const { items, position: sourcePosition, bounds } = PlanClipboard.read() || {}
      if (!(items && position && sourcePosition)) return

      // Get connectors - they can be added only after all other elements have been pasted
      const connectors = items.filter(item => item.isConnector)
      const equipment = items.filter(item => !item.isConnector)

      // Get the delta between the source position of the pasted items
      // and the target position of the paste action
      const pasteDelta = position.delta(sourcePosition)

      // Paste the equipment at the location where action was executed,
      // in the right sequence - first the equipment, then the connectors
      for (const group of [equipment, connectors]) {
        for (const item of group) {
          // If pasting on cross-section, add the item on the same floor where its source item was
          item.clearTag()
          const floor = isCrossSection
            ? layout.getFloor(item.floorId) || renderer.selectedFloor
            : renderer.selectedFloor

          // If pasting building wall, remove the existing wall
          // from the floor, as there can only be one wall
          if (item.isWall) {
            const existingWall = floor.findItem(item => item.isWall)
            if (existingWall) {
              layout.removeItem(existingWall)
              renderer.notifyLayers(layer => layer.itemRemoved(existingWall))
            }
          }

          // Add the pasted item
          const action = new AddItemAction({ items: [item] })
          action.execute({ renderer, floor, items: [item] })

          // Prevent overlap with the copied source
          const preventOverlap = !(item.isWall || item.isYard)
          if (preventOverlap) {
            // Get bounding rectangle for the pasted items:
            // - on the cross-section view
            // - on the floor where the item belongs (this only includes the pasted items which are on the same floor)
            const crossSectionBounds = bounds[PlanFloors.CrossSection]
            const floorBounds = bounds[item.floorId]
            // Move the item on the floor where it belongs
            if (item.showOnFloor) {
              // If we're pasting on the floor, shift by the paste delta.
              // If we're pasting on cross-section, cross-section coordinates don't apply here on the floor.
              // In such case shift by the selection bounds to avoid overlap
              const shiftBy = (isCrossSection && floorBounds)
                ? Point.from({ x: floorBounds.width + 20, y: 0 })
                : pasteDelta
              item.moveBy(shiftBy, false)
            }

            // Move the item on the cross-section
            if (item.showOnCrossSection) {
              // If we're pasting on the cross-section, shift by the paste delta.
              // If we're pasting on the floor, floor coordinates don't apply here on the floor.
              // But at least shift by the item bounds to avoid overlap
              const shiftBy = (isCrossSection || !crossSectionBounds)
                ? pasteDelta
                : Point.from({ x: crossSectionBounds.width + 20, y: 0 })
              item.moveBy(shiftBy, true)
            }
          }
        }
      }

      // TODO: Check if item is not outside the canvas bounds, adjust coordinates if so

      // TODO: Check if items inside their floor bounds, move to other floors if necessary

      // Re-evaluate legend elements to show accurate equipment counts
      await delay(200)
      await renderer.deselect()
      await renderer.refreshLegends()
      await renderer.refreshEquipmentHierarchy()
      await renderer.refresh()

      // Mark newly added items as selected
      await renderer.selectItems({ items, showMenu: false })

      // Indicate that content has been pasted
      renderer.paste()

      if (clear) {
        PlanClipboard.clear()
      }

      return items
    }
  }
}
