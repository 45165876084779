<script>
import { mapActions, mapGetters } from 'vuex'
import { capitalize } from '@stellacontrol/utilities'
import { getPlaceLabel } from '@stellacontrol/model'
import { ViewMixin, TabsMixin, EditorMixin } from '@stellacontrol/client-utilities'
import { Secure } from '../../components/secure-component'
import OrganizationPlaceGeneral from './organization-place-general.vue'
import OrganizationPlaceDevices from './organization-place-devices.vue'
import { resolveOrganizationPlace } from './organization-place.resolve'

const name = 'organization-place'

/**
 * Place editor
 */
export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    EditorMixin,
    Secure
  ],

  components: {
    'sc-organization-place-general': OrganizationPlaceGeneral,
    'sc-organization-place-devices': OrganizationPlaceDevices
  },

  data () {
    return {
      name,
      tab: 'general'
    }
  },

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    title () {
      const { data } = this
      if (data.isNew) {
        return `New ${getPlaceLabel(data.placeType)} in ${data.organization.name}`
      } else {
        return `${capitalize(getPlaceLabel(data.placeType))} ${data.name}`
      }
    },

    forms () {
      return [
        'general'
      ]
    },

    // Breadcrumbs must allow navigation back to the owner organization, places tab
    breadcrumbs () {
      const { organizations, data: { organization }, getViewTitle } = this

      if (organization) {
        const parent = organizations.find(o => o.id === organization.id)
        const breadcrumbs = [
          {
            name: 'home',
            title: getViewTitle('home')
          },
          {
            name: 'organizations',
            title: getViewTitle('organizations')
          },
          parent
            ? {
              name: 'organization',
              title: parent.name,
              route: 'organization',
              query: { tab: 'places' },
              params: { id: parent.id }
            }
            : undefined,
          {
            title: getViewTitle('building')
          }
        ]
        return breadcrumbs
      }
    }
  },

  methods: {
    ...mapActions([
      'saveOrganizationPlace',
      'editOrganization',
      'goBack'
    ]),

    // Saves the place and navigates back to where the user came from
    async save () {
      if (await this.validate()) {
        const { data: place } = this
        const { organization } = place
        await this.saveOrganizationPlace({ place, organization })
        await this.editOrganization({ organization, tab: 'places' })
        return true

      } else {
        await this.selectTab('general')
      }
    },

    async cancel () {
      await this.goBack()
    }
  },

  // Reload data on navigation to another organization
  async beforeRouteUpdate (to, from, next) {
    if (await resolveOrganizationPlace({ from, to })) {
      next()
    }
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" :breadcrumbs="breadcrumbs" v-if="isLoggedIn">

    <template #toolbar>
      <div class="row q-gutter-xs">
        <q-btn label="Save" color="primary" @click="save()"></q-btn>
        <q-btn flat label="Close" color="indigo-6" @click="cancel()"></q-btn>
      </div>
    </template>

    <sc-tabs v-model="tab">
      <q-tab name="general" icon="create" label="Details" :ripple="false"></q-tab>
      <q-tab name="devices" v-if="!data.isNew" icon="router" label="Devices" :ripple="false"></q-tab>
    </sc-tabs>

    <sc-tab-panels v-model="tab">
      <q-tab-panel name="general">
        <sc-organization-place-general :data="data" ref="general"></sc-organization-place-general>
      </q-tab-panel>
      <q-tab-panel name="devices" v-if="!data.isNew">
        <sc-organization-place-devices :data="data" ref="devices"></sc-organization-place-devices>
      </q-tab-panel>
    </sc-tab-panels>

  </sc-view>
</template>

<style lang='scss' scoped>
</style>
