import { Log, wait, removeQuery, getQueryParameters } from '@stellacontrol/utilities'
import { Confirmation, dispatchParallel, Storage } from '@stellacontrol/client-utilities'
import { CommonAPI, SecurityAPI, startAPISession, clearAPISession, APISession } from '@stellacontrol/client-api'
import { Preference } from '@stellacontrol/model'
import { Guardian, LoginError } from '@stellacontrol/security'
import { LoadingBar, Notification } from '@stellacontrol/client-utilities'
import { Viewport } from '@stellacontrol/client-utilities'

// Pages from which navigation cannot start, when user
// loads the initial page
const notHomePages = [
  '/error',
  '/wip',
  '/empty',
  '/login',
  '/not-authorized'
]

export const actions = {
  /**
   * Initializes the UI router
   * @param router UI router
   * @param routes Routes for the UI router
   */
  initializeRouter ({ dispatch, getters }, { router } = {}) {
    if (!router) throw new Error('Router is not specified')

    router.beforeEach(async (to, from, next) => {
      // If route is secure, make sure there's active session
      // and user has the required permissions
      const targetRoute = router.getRoutes().find(r => r.name === to.name)
      const isSecureRoute = (targetRoute?.meta || {})?.isSecure

      if (isSecureRoute) {
        const { isLoggedIn, canUseAll } = getters
        const { permissions } = isSecureRoute.meta || {}

        if (!isLoggedIn) {
          // Attempts to continue an existing session if present in session storage
          const sessionToken = loadSessionToken()
          const { session } = sessionToken
            ? await dispatch('authenticate', { sessionToken })
            : {}

          if (session) {
            await dispatch('startSession', { session })

          } else {
            // No existing session, go to login but remember
            // where the user wanted to go originally!
            Log.debug('Not logged in yet, redirecting to login page ...')
            const redirectTo = to.fullPath && to.fullPath !== '' && to.fullPath !== '/'
              ? to.fullPath
              : null
            const query = redirectTo
              ? { redirectTo }
              : null
            return next({ path: '/login', query })
          }
        }

        // If session is OK, check the route permissions
        if (permissions) {
          if (!canUseAll(permissions)) {
            // If user does not have the necessary permissions, show not-authorized page instead
            return next({ path: '/not-authorized' })
          }
        }
      }

      next()
    })
  },

  /**
   * Initialize the security applet
   */
  async initializeApplet () {
    // Set up web request loading bar
    LoadingBar.initialize({ color: 'purple', size: '3px', position: 'bottom' })
  },

  /**
   * Attempts to authenticate the specified user and password
   * @param userName User name
   * @param password User password
   * @param sessionToken Alternative to user name and password - an existing session token
   * @param reCaptchaToken reCaptcha token obtained from Google reCaptcha service during interactive login
   * @param silent If true, no notifications will be displayed to the user
   */
  async authenticate ({ commit, dispatch, getters }, { userName, password, sessionToken, reCaptchaToken, silent } = {}) {
    if ((userName && password) || sessionToken) {
      // If user credentials are specified, login with username and password
      // otherwise try fetching the same session
      Log.debug('Attempting login', userName ? `as ${userName} ...` : 'under the existing session ...')
      commit('tryLogin')

      const isMobile = Viewport.isMobilePhone
      let session, message, reason
      try {
        const loginResult = (userName && password)
          ? await SecurityAPI.login({ name: userName, password, reCaptchaToken, isMobile })
          : await SecurityAPI.relogin({ token: sessionToken })
        session = loginResult?.session
        message = loginResult?.message || 'Unknown error'
        reason = loginResult?.reason


        if (session) {
          // Pass user credentials and session to all APIs
          startAPISession(session)

          // Prepare permisssions guardian, store in the state
          await dispatchParallel(['getFeatures', 'getPricelists'])
          const { features, pricelist, environment } = getters
          const { user, organization, token } = session
          const guardian = new Guardian({
            features,
            principal: user,
            pricelist,
            environment
          })
          const organizationGuardian = new Guardian({
            features,
            principal: organization,
            pricelist,
            environment
          })
          commit('loginSuccess', { session, guardian, organizationGuardian })

          // Store session ID in cookie, so it can survive page reloads
          saveSessionToken(token)

          // Set up periodic ping to keep the session alive
          if (guardian.canUse('permanent-sessions')) {
            await dispatch('startSessionRefresh')
          }

          const { loggedInAsMaster, masterUser, masterOrganization } = getters
          if (loggedInAsMaster) {
            Log.info(`Master user [${masterUser.name}:${masterOrganization.name}] logged in as [${user.fullName}:${organization.name}]`)
          } else {
            Log.info(`User [${user.name}:${organization.name}] logged in`)
          }
          Log.debug('User', user)
          Log.debug('Organization', organization)
          Log.debug('Guardian', guardian)
          Log.debug('Session', { ...session, token: '***' })

          return { session }

        } else {
          throw new Error(message)
        }
      } catch (error) {
        if (userName) {
          Log.debug(`Error authenticating ${userName}`, message || error.message)
          if (getters.isDevelopmentEnvironment) {
            Log.error(error)
          }
          if (reason !== LoginError.UserDisabled) {
            Notification.warning({ message: 'Login failed', details: userName, silent })
          }
        } else {
          Log.debug(userName ? `Error authenticating ${userName}` : 'User session has expired', message)
        }
        commit('loginError', { message })

        return { reason, message }
      }
    }
  },

  /**
   * Logs in to the specified organization on behalf of its admin
   * @param {Organization} organization Child organization to log in to
   * @param {User} user Optional user to impersonate. If not specified, the primary administrator of the child organization will be impersonated.
   * @param {Boolean} confirm If true, user must confirm logging in
   */
  async loginToOrganization ({ commit, dispatch, getters }, { organization, user, confirm }) {
    if (!organization) return

    // Require proper permissions if logging in to child organizations
    const { masterOrganization, guardian, currentRoute } = getters
    const backToMasterOrganization = masterOrganization && organization.id === masterOrganization.id
    if (!backToMasterOrganization) {
      if (!guardian.canUse('login-to-child-organizations')) {
        throw new Error(`You don't have permissions to log in to ${organization.name}`)
      }
    }

    const yes = confirm
      ? await Confirmation.ask({ message: `Login as ${user ? user.name : 'administrator'} to ${organization.name}?` })
      : true

    if (yes) {
      const KEY_LOGINAS_ORIGIN = 'login-as-origin'
      const { currentUser: masterUser, currentOrganization: masterOrganization } = getters
      let session, message

      try {
        const loginResult = await SecurityAPI.loginAs({ organization, user, noReturn: backToMasterOrganization })
        session = (loginResult || {}).session
        message = (loginResult || {}).message
      } catch (error) {
        message = error.message
        Log.exception(error)
      }

      if (session) {
        // Remember the page on which we are now, so we can come back to the exact place
        // from which we logged into the other organization
        const currentUrl = currentRoute.href
        if (!backToMasterOrganization) {
          Storage.put(KEY_LOGINAS_ORIGIN, currentUrl)
        }

        // Navigate away to empty page, simulate redirecting so that menus are hidden.
        // This is necessary to prevent UI from breaking, when current session is being disposed of.
        await commit('redirectToUrl', { url: 'login-as' })
        await dispatch('gotoEmpty')
        await wait(100)

        // Pass user credentials and session to all APIs
        startAPISession(session)

        // Prepare permisssions guardian, store in the state
        const { features, pricelist, environment } = getters
        const { user, organization, token } = session
        const guardian = new Guardian({
          features,
          principal: user,
          pricelist,
          environment
        })
        commit('loginSuccess', { session, guardian })

        // Store session ID in cookie, so it can survive page reloads
        saveSessionToken(token)

        // Set up periodic ping to keep the session alive
        if (guardian.canUse('permanent-sessions')) {
          await dispatch('startSessionRefresh')
        }

        if (backToMasterOrganization) {
          Log.info(`Master user [${user.fullName}] logged in back to [${organization.name}]`)
        } else {
          Log.info(`Master user [${masterUser.name}:${masterOrganization.name}] logged in as [${user.fullName}:${organization.name}]`)
        }
        Log.debug('User', user)
        Log.debug('Organization', organization)
        Log.debug('Guardian', guardian)

        // Full reload, to properly initialize everything.
        if (backToMasterOrganization) {
          // If returning from logged-in as, return back to where we came from, or back to home page
          const origin = Storage.get(KEY_LOGINAS_ORIGIN) || '#/'
          Storage.clear(KEY_LOGINAS_ORIGIN)
          await dispatch('reload', { message: `Returning to ${organization.name} ...`, url: `/${origin}` })
        } else {
          // Otherwise go to home page
          await dispatch('redirectToHome', { instant: false, message: `Logging in to ${organization.name} ...` })
        }

        return session
      } else {
        Log.debug(`Error logging into ${organization.name}`, message)
        Notification.warning({ message: `Error logging into ${organization.name}` })
      }
    }
  },

  /**
   * Launches a periodic ping to the security service
   * to ensure that session is alive. Only used when user
   * has permission for permanent sessions.
   */
  async startSessionRefresh ({ dispatch, getters }) {
    if (!pingTimer) {
      const { configuration: { security: { sessionPolicy } } } = getters
      if (!(sessionPolicy.expiresAfter > 60)) {
        return
      }

      // Clear any running intervals
      if (pingTimer) {
        window.clearInterval(pingTimer)
        pingTimer = null
      }

      // Set up interval for refreshing sessions
      const pingInterval = 1000 * (sessionPolicy.expiresAfter / 2)
      pingTimer = window.setInterval(
        async () => {
          if (getters.isLoggedIn) {
            // Suppress the AJAX loading bar, we don't want it flashing all the time
            LoadingBar.disable()
            const token = await SecurityAPI.ping()
            LoadingBar.enable()
            // If session has been succesfully extended, tell APIs to use the new token
            if (token) {
              startAPISession({ token })
              saveSessionToken(token)
            } else {
              // Otherwise go back to login
              clearAPISession()
              await dispatch('gotoLogin')
            }
          }
        }, pingInterval)

      Log.debug('Permanent session enabled')
    }
  },

  /**
   * Ensures that user has logged in on a correct website.
   * If not, redirect to the right one, as assigned to user's organization
   */
  async ensureCorrectWebsite ({ getters, dispatch }) {
    const { currentOrganization, currentUser, configuration, environment } = getters
    if (!(currentOrganization && currentUser)) return

    let result = true

    // Identify site where visitor came from.
    // Site can be associated with one or more actual hosts.
    const origin = new URL(window.location).hostname
    const originSite = configuration.sites.find(site => site.name === origin)

    // Determine the site to which organization has been assigned
    const organizationSite = configuration.sites.find(site => site.name === currentOrganization.site)

    // Determine whether organization is assigned to a fixed site
    // which should be now enforced
    const enforceSite = originSite && organizationSite && !currentOrganization.isSuperOrganization

    if (enforceSite) {
      // Check whether visitor came from the site to which he's been assigned
      const isCorrectSite = organizationSite === originSite
      if (!isCorrectSite) {
        // Find correct site under the same environment
        const siteUrl = organizationSite.environments[environment].url
        const loginUrl = `${siteUrl}/#login`
        Log.warn(`User ${currentUser.name} has opened incorrect website`)
        Log.warn(`Redirecting ${currentUser.name} to ${siteUrl} ...`)
        await dispatch('clearSession')
        await dispatch('redirectToUrl', { url: loginUrl, message: `Redirecting to ${siteUrl}` })
        result = false
      }
    }

    return result
  },

  /**
   * Initializes new user session.
   * Implement the same action in applications or applets using security,
   * so that they can initialize themselves properly once the session has started.
   * @param session Session details
   */
  async startSession ({ dispatch, getters }, { session, redirectTo } = {}) {
    if (session) {
      // Set up auth error handler to be called when API calls
      // fail with authorization errors, to capture expired
      // sessions and redirect users back to login page.
      startAPISession(session, async (error) => {
        if (SecurityAPI.isAuthenticationError(error)) {
          await dispatch('endSession')
          await dispatch('startSession')
        }
      })

      // Initialize the applets
      await dispatch('initializeApplet')

      // Signal started session
      await dispatch('sessionStarted', { session })

      // Proceed away from login page, but ...
      // If user has logged in through a site which is not the one
      // to which his organization has been assigned, redirect
      // to the correct website!
      // DO NOT DO THIS, when master user has logged in on behalf of his child organization,
      // as master might have free access to other websites
      if (!session.masterUser) {
        if (await dispatch('ensureCorrectWebsite')) {
          // Go to home page if any of the special pages have been reloaded,
          // such as error, empty etc.
          const { isApplet, userPreferences, isSmallScreen } = getters
          const requestedPath = redirectTo || (window.location.hash || '').substring(1)
          if (requestedPath) {
            if (requestedPath === '/' || notHomePages.includes(requestedPath)) {
              // On mobile phone navigate straight to the installations dashboard (if permitted)
              const canStartWithInstallations = !isApplet && getters.canUse('installations')
              const defaultHome = canStartWithInstallations ? 'installations' : 'home'
              if (isSmallScreen) {
                await dispatch('gotoRoute', { name: defaultHome })
              } else {
                // Navigate to the preferred start page if no other route explicitly specified
                await dispatch('gotoRoute', {
                  name: userPreferences.ui.startPage || defaultHome
                })
              }
            } else {
              // Navigate to the originally requested page
              const path = removeQuery(requestedPath)
              const query = getQueryParameters(requestedPath)
              await dispatch('gotoRoute', { path, query })
            }
          }
        }
      }
    }
  },

  /**
   * Triggered when user session has been succesfully started
   */
  async sessionStarted ({ commit, getters }) {
    const { currentUser, currentOrganization, session } = getters
    commit('sessionStarted', { currentUser, currentOrganization, session })
  },

  /**
   * Clears the current user session
   */
  async clearSession ({ commit, dispatch, getters }) {
    const { currentUser, session } = getters

    // Clear application state
    await dispatch('reset')

    if (currentUser && session) {
      // Stop session refresh ping
      if (pingTimer) {
        window.clearInterval(pingTimer)
        pingTimer = null
      }

      // Remove session token from session storage
      deleteSessionToken()

      // Notify the server about finished session
      try {
        await SecurityAPI.logout({ name: currentUser.name, sessionToken: session.token })
        Log.debug(`${currentUser.name} logged out`)

      } catch (error) {
        // Ignore auth and network errors, probably session just expired
        if (!(SecurityAPI.isAuthenticationError(error) || SecurityAPI.isNetworkError(error))) {
          Log.exception(error)
        }
      } finally {
        clearAPISession()
      }
    }

    commit('sessionEnded')
  },

  /**
   * Clears the current user session, redirects to login
   */
  async endSession ({ commit, dispatch, getters }) {
    const { currentUser, session } = getters
    if (currentUser && session) {
      commit('loggingOut')
      await dispatch('gotoEmpty')
      await wait(100)
      await dispatch('clearSession')
      await dispatch('gotoLogin')
      await wait(100)
      commit('loggedOut')
      Log.debug('SESSION ENDED', currentUser.name)
    }
  },

  /**
   * Updates the current session user,
   * i.e. after the user has edited his profile
   * @param user User to store in the session
   */
  updateSessionUser ({ commit, getters }, { user } = {}) {
    if (user && getters.currentUser && getters.currentUser.id === user.id) {
      commit('updateSessionUser', { user })
    }
  },

  /**
   * Returns a security guardian for the specified principal
   * @param {Principal} principal Principal whose guardian is required
   * @param {Boolean} force If true, all principal data is retrieved anew from the API
   * @returns {Promise<Guardian>}
   */
  async getGuardian ({ commit, getters }, { principal, force } = {}) {
    if (!principal) throw new Error('Principal is required')

    if (force) {
      principal = await SecurityAPI.getPrincipal({ id: principal.id, withDetails: true })
    } else {
      // Make sure all parent principals are there
      if (principal.isOrganization) {
        const profile = getters.organizationProfiles.find(p => p.id === principal.profileId)
        principal.profile = profile
      }
      if (principal.isUser && principal.organization) {
        const profile = getters.organizationProfiles.find(p => p.id === principal.organization.profileId)
        principal.organizations.profile = profile
      }
    }

    const { features, pricelist, environment } = getters
    const guardian = new Guardian({
      features,
      principal,
      pricelist,
      environment
    })
    commit('storeGuardianOf', { principal, guardian })
    return guardian
  },

  /**
   * Returns security guardian for the current organization
   * @param {Boolean} force If true, all principal data is retrieved anew from the API
   * @returns {Promise<Guardian>}
   */
  async getCurrentOrganizationGuardian ({ getters }, { force } = {}) {
    if (force) {
      const { features, pricelist, environment } = getters
      const principal = await SecurityAPI.getPrincipal({ id: getters.currentOrganizationId, withDetails: true })
      const guardian = new Guardian({
        features,
        principal,
        pricelist,
        environment
      })
      return guardian
    } else {
      return getters.organizationGuardian
    }
  },

  /**
   * Returns security guardian for the current user, as resolved during login
   * @returns {Promise<Guardian>}
   */
  async getCurrentGuardian ({ getters }) {
    return getters.guardian
  },

  /**
   * Checks whether user can use the specified feature
   * @param feature Name of the feature to check
   */
  canUse ({ getters }, feature) {
    return getters.canUse(feature)
  },

  /**
   * Checks whether user can use all the specified features
   * @param features Names of features to check
   */
  canUseAll ({ getters }, features = []) {
    return getters.canUseAll(features)
  },

  /**
   * Checks whether user can use any of the specified features
   * @param features Names of features to check
   */
  canUseAny ({ getters }, features = []) {
    return getters.canUseAny(features)
  },

  /**
   * Checks whether user cannot use the specified feature
   * @param feature Name of the feature to check
   */
  cannotUse ({ getters }, feature) {
    return getters.canUse(feature)
  },

  /**
   * Checks whether user cannot use some or all the specified features
   * @param features Names of features to check
   */
  cannotUseAll ({ getters }, features = []) {
    return getters.canUseAll(features)
  },

  /**
   * Checks whether user cannot use any of the specified features
   * @param features Names of features to check
   */
  cannotUseAny ({ getters }, features = []) {
    return getters.canUseAny(features)
  },

  /**
   * Returns a specified preference owned by the given principal
   * @param {String} name Preference name
   * @param {Principal} principal Preference owner
   * @returns {Preference}
   */
  async getPreferenceOf ({ getters }, { name, principal }) {
    const { isLoggedIn } = getters
    if (isLoggedIn) {
      return CommonAPI.getPreference({ name, principal })
    }
  },

  /**
   * Returns preferences owned by the given principal
   * @param {Principal} principal Preference owner
   * @param {String} prefix Preference prefix, used to return only some of the preferences
   * @returns {Array[Preference]}
   */
  async getPreferencesOf ({ getters }, { principal, prefix }) {
    const { isLoggedIn } = getters
    if (isLoggedIn) {
      return CommonAPI.getPreferences({ principal, prefix })
    }
  },

  /**
   * Returns a specified preference owned by the given principal
   * @param {Preference} preference Preference to save
   * @param {Principal} principal Preference owner
   * @returns {Preference} Saved preference
   */
  async savePreferenceOf ({ getters }, { preference, principal }) {
    const { isLoggedIn } = getters
    if (isLoggedIn) {
      return CommonAPI.savePreference({ preference, principal })
    }
  },

  /**
   * Returns all preferences of a current user
   */
  async getUserPreferences ({ getters }) {
    return getters.currentUser.preferences
  },

  /**
   * Returns a specified preference of a current user
   * @param {String} name Name of the preference to return
   * @param {Function} converter Optional converter for the returned preference
   * @param {any} defaultValue Optional default value to return, if preference not specified yet
   * @returns {any} User preference
   */
  async getUserPreference ({ getters }, { name, defaultValue, converter } = {}) {
    const { currentUser } = getters
    if (currentUser) {
      const preference = currentUser.preferences[name]
      if (preference == null) {
        return defaultValue
      } else {
        return converter ? converter(preference) : preference
      }
    } else {
      return defaultValue
    }
  },

  /**
   * Returns all preferences of a current organization
   * @returns {Dictionary<string, any>} Organization preferences
   */
  async getOrganizationPreferences ({ getters }) {
    return getters.currentOrganization.preferences
  },

  /**
   * Returns a specified preference of a current organization
   * @param {String} name Name of the preference to return
   * @param {Function} converter Optional converter for the returned preference
   * @param {any} defaultValue Optional default value to return, if preference not specified yet
   * @returns {any} Organization preference
   */
  async getOrganizationPreference ({ getters }, { name, defaultValue, converter } = {}) {
    const preference = getters.currentOrganization.preferences[name]
    if (preference == null) {
      return defaultValue
    } else {
      return converter ? converter(preference) : preference
    }
  },

  /**
   * Returns all global preferences
   * @returns {Dictionary<string, any>} Organization preferences
   */
  async getGlobalPreferences ({ getters }) {
    return getters.globalPreferences
  },

  /**
   * Returns a specified global preference
   * @param {String} name Name of the preference to return
   * @param {Function} converter Optional converter for the returned preference
   * @param {any} defaultValue Optional default value to return, if preference not specified yet
   * @returns {any} Global preference
   */
  async getGlobalPreference ({ getters }, { name, defaultValue, converter } = {}) {
    const preference = getters.globalPreferences[name]
    if (preference == null) {
      return defaultValue
    } else {
      return converter ? converter(preference) : preference
    }
  },

  /**
   * Stores preference of the current user
   * @param name Preference name
   * @param value Preference value
   */
  async storeUserPreference ({ commit, getters }, { name, value } = {}) {
    const { isLoggedIn, currentUser } = getters
    if (name && isLoggedIn) {
      const preference = Preference.from(name, value, currentUser)
      await CommonAPI.savePreference({ preference, principal: currentUser })
      commit('storeUserPreference', { name, value })
    }
  },

  /**
   * Stores preferences of the current user
   * @param items List of preferences, specified as { name, value }
   */
  async storeUserPreferences ({ commit, getters }, { items = [] } = {}) {
    const { isLoggedIn, currentUser } = getters
    if (items.length > 0 && isLoggedIn) {
      const preferences = items.map(({ name, value }) => Preference.from(name, value, currentUser))
      await CommonAPI.savePreferences({ preferences, principal: currentUser })
      commit('storeUserPreferences', { items })
    }
  },

  /**
   * Stores preference of the current organization
   * @param name Preference name
   * @param value Preference value
   */
  async storeOrganizationPreference ({ commit, getters }, { name, value } = {}) {
    const { isLoggedIn, currentOrganization } = getters
    if (name && isLoggedIn) {
      const preference = Preference.from(name, value, currentOrganization)
      await CommonAPI.savePreference({ preference, principal: currentOrganization })
      commit('storeOrganizationPreference', { name, value })
    }
  },

  /**
   * Stores preferences of the current organization
   * @param items List of preferences, specified as { name, value }
   */
  async storeOrganizationPreferences ({ commit, getters }, { items = [] } = {}) {
    const { isLoggedIn, currentOrganization } = getters
    if (items.length > 0 && isLoggedIn) {
      const preferences = items.map(({ name, value }) => Preference.from(name, value, currentOrganization))
      await CommonAPI.savePreferences({ preferences, principal: currentOrganization })
      commit('storeOrganizationPreferences', { items })
    }
  },

  /**
   * Stores the specified global preference
   * @param name Preference name
   * @param value Preference value
   */
  async storeGlobalPreference ({ commit, getters }, { name, value } = {}) {
    if (name && getters.isLoggedIn) {
      const preference = Preference.from(name, value)
      await CommonAPI.savePreference({ preference })
      commit('storeGlobalPreference', { name, value })
    }
  },

  /**
   * Stores the specified global preferences
   * @param items List of preferences, specified as { name, value }
   */
  async storeGlobalPreferences ({ commit, getters }, { items = [] } = {}) {
    if (items.length > 0 && getters.isLoggedIn) {
      const preferences = items.map(({ name, value }) => Preference.from(name, value))
      await CommonAPI.savePreferences({ preferences })
      commit('storeGlobalPreferences', { items })
    }
  },

  /**
   * Returns true if approval of terms and conditions is required
   * and there are terms-and-conditions documents applicable to current organization
   */
  async termsAndConditionsApply ({ getters }) {
    return getters.currentOrganizationProfile &&
      getters.currentOrganizationProfile.termsAndConditionsId &&
      getters.termsAndConditions &&
      getters.termsAndConditions.requireApproval &&
      (getters.termsAndConditions.items || []).length > 0
  },

  /**
   * Sets organization language and/or country
   * @param {Organization} organization Organization to update
   * @param {String} countryCode ISO 3166 Alpha-2 country code
   * @param {String} languageCode ISO 639-2 language code
   * @param {String} timezone Name of country timezone, as per https://www.iana.org/time-zones
   * @returns {Promise<Organization>} The updated organization
   */
  async setLocale ({ commit }, { organization, countryCode, languageCode, timezone } = {}) {
    if (organization && (countryCode != null || languageCode != null || timezone != null)) {
      const updatedOrganization = await SecurityAPI.setLocale({ organization, countryCode, languageCode, timezone })
      if (updatedOrganization) {
        commit('setLocale', { organization, countryCode, languageCode, timezone })
      }
    }
  },

  /**
   * Updates the specified permissions of a principal
   * @param {Principal} principal Principal to update permissions
   * @param {Array[Permission]} permissions Permissions to grant or deny
   * @returns {Promise<Principal>} The updated principal
   */
  async updatePermissions (_, { principal, permissions } = {}) {
    if (principal && permissions?.length > 0) {
      await SecurityAPI.updatePermissions({ principal, permissions })
    }
  }
}

/**
 * Session ping timer
 */
let pingTimer

/**
 * Retrieves the session token from local storage
 */
function loadSessionToken () {
  return window.localStorage.getItem(APISession.tokenKey)
}

/**
 * Saves a session token in local storage
 * @param token Token to save
 */
function saveSessionToken (token) {
  window.localStorage.setItem(APISession.tokenKey, token)
}

/**
 * Removes the session token from local storage
 */
function deleteSessionToken () {
  window.localStorage.removeItem(APISession.tokenKey)
}
