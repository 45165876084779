import { DateRange } from '@stellacontrol/model'
import { DeviceAuditEventType } from '@stellacontrol/devices'

/**
 * Retrieves device history preferences
 * @param {DateRange} period Default period
 * @param {Array[String]} parameters Default parameters
 * @param {Array[String]} extras Default extras
 */
export async function getDeviceHistoryPreferences ({ dispatch }, { period, parameters, extras } = {}) {
  period = await dispatch('getUserPreference', {
    name: 'device-history-period',
    defaultValue: period || DateRange.today().toString(),
    converter: value => DateRange.fromString(value) || DateRange.today()
  })

  parameters = await dispatch('getUserPreference', {
    name: 'device-history-parameters',
    defaultValue: parameters || ['uptime']
  })

  extras = await dispatch('getUserPreference', {
    name: 'device-history-extras',
    defaultValue: extras || [
      DeviceAuditEventType.Alert,
      DeviceAuditEventType.SettingsChanged,
      DeviceAuditEventType.CommandSent,
      DeviceAuditEventType.FirmwareUpdated
    ]
  })

  return { period, parameters, extras }
}

/**
 * Stores the device history preferences
 * @param {DateRange} period Filter period
 * @param {Array[String]} parameters Filter parameters
 * @param {Array[String]} extras Filter extras
 */
export async function storeDeviceHistoryPreferences ({ dispatch }, { period, parameters, extras } = {}) {
  const items = [
    period ? { name: 'device-history-period', value: period.toString() } : null,
    parameters ? { name: 'device-history-parameters', value: parameters } : null,
    extras ? { name: 'device-history-extras', value: extras } : null
  ].filter(i => i)

  if (items.length > 0) {
    await dispatch('storeUserPreferences', { items })
  }
}

/**
 * Shows the history of the specified device
 * @param {Device} device Device to show
 * @param {DateRange} period Filter period to store, optional.
 * If not specified, the previously shown period will be displayed.
 * @param {Array[String]} parameters Filter parameters to show, optional.
 * If not specified, the previously shown parameters will be displayed.
 * @param {Array[String]} extras Filter extras to show, optional.
 * If not specified, the previously shown extras will be displayed.
 * @param {Boolean} peek By default changes to filters will be stored
 * and reused the next time the device history is shown.
 * Specify `false` for quick peeks at history without persisting the filters.
 * @returns {Object} The filter selected in the dialog: `{ period, parameters, extras }`
 */
export async function showDeviceHistory ({ dispatch }, { device, period, parameters, extras, peek = false } = {}) {
  const filter = await dispatch('getDeviceHistoryPreferences', { period, parameters, extras })

  // Show the dialog
  const { data } = await dispatch('showDialog', {
    dialog: 'device-history',
    data: {
      device,
      ...filter
    }
  })

  if (data && !peek) {
    const { period, parameters, extras } = data
    await dispatch('storeDeviceHistoryPreferences', { period, parameters, extras })
  }

  return { period, parameters, extras }
}
