<script>
import { getStore } from '../store/get-store'
import { mapState, mapGetters, mapActions } from 'vuex'

const defaultErrorMessage = `
  An unexpected error occurred.<br>
  We're sorry for the inconvenience.<br>
  Please try again later.
`

/**
 * Unhandled error view
 */
export default {
  data () {
    return {
      showDetails: false,
      isSendingBugReport: false
    }
  },

  computed: {
    ...mapState({
      application: state => state.client.application,
      error: state => state.client.error,
      isInitialized: state => state.client.isInitialized
    }),

    ...mapGetters([
      'isLoggedIn',
      'isApplet',
      'isDevelopmentEnvironment',
      'guardian'
    ]),

    message () {
      return (this.error ? this.error.message : '') || defaultErrorMessage
    },

    exception () {
      return this.error ? this.error.exception : undefined
    },

    details () {
      let result = 'No other details are available'
      const { exception } = this

      if (exception) {
        const { details, message } = exception
        if (details) {
          result = `${message} in ${details.source.fileName} at ${details.source.line}:${details.source.column}`
        } else {
          result = message
        }

        return result
      }

      return result
    },

    // Bug report identifier
    bugReportId () {
      const { exception } = this
      return exception ? exception.bugReportId : undefined
    },

    // Can go to login page ONLY if the application has been initialized at all.
    // If even that has failed, i.e. when API is dead, we just stay here.
    canGoToLogin () {
      return this.isInitialized
    },

    // Checks whether current user can submit a bug report
    canSendBugReport () {
      const { isLoggedIn, guardian, isApplet, isDevelopmentEnvironment } = this
      return isLoggedIn &&
        guardian &&
        guardian.canUse('bug-reports') &&
        !(isApplet || isDevelopmentEnvironment)
    },

    // Checks whether current user can view the bug report
    canViewBugReport () {
      const { isLoggedIn, guardian } = this
      return isLoggedIn && guardian && guardian.isSuperAdministrator
    }
  },

  methods: {
    ...mapActions([
      'busy',
      'done',
      'submitBugReport',
      'gotoHome',
    ]),

    toggleDetails () {
      if (this.details) {
        this.showDetails = !this.showDetails
      }
    },

    async submit () {
      const { canSendBugReport, exception } = this
      if (canSendBugReport) {
        this.isSendingBugReport = true
        this.busy({ message: 'Please wait while we’re sending a bug report to system administrator.' })
        await this.submitBugReport({ error: exception })
        this.isSendingBugReport = false
        this.done({ message: 'Bug report has been submitted', details: 'Thank you!' })
      }
    }
  },

  async mounted () {
    this.submit()
  },

  async beforeUnmount () {
    // Clear the error when leaving the error view
    const store = getStore()
    await store.dispatch('recover')
  }
}

</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="column items-center justify-center">
        <div class="content">
          <div class="q-pb-lg text-h5">
            {{ application.name }}
          </div>

          <div class="message" @click="toggleDetails()">

            <transition name="slide" mode="out-in">
              <div>
                <div key="details" v-if="showDetails">
                  <div v-html="details" class="text-h6 text-weight-light">
                  </div>
                  <div v-if="bugReportId" class="q-mt-sm text-h6 text-weight-light">
                    Reference:
                    <router-link v-if="canViewBugReport"
                      :to="{ name: 'bug-report', params: { id: bugReportId } }">
                      {{ bugReportId }}
                    </router-link>
                    <span v-else>
                      {{ bugReportId }}
                    </span>
                  </div>
                </div>

                <div key="message" v-if="!showDetails">
                  <span v-html="message" class="message text-weight-light">
                  </span>
                </div>
              </div>
            </transition>

          </div>

          <div class="back row row q-pt-lg" v-if="!isSendingBugReport">
            <q-btn @click="gotoHome()" label="Close" unelevated class="button-close"></q-btn>
          </div>


        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" scoped>
.content {
  width: 450px;

  .message {
    font-size: 20px;
  }

  .button-close {
    min-width: 120px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity .2s
}

.slide-enter,
.slide-leave-active {
  opacity: 0
}

@media screen and (width <=1024px) {
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .message {
      font-size: 15px;
      text-align: center;
    }
  }
}
</style>
