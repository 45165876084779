
/**
 * Subscriber list columns.
 * Sorting is disabled as this is a hierarchical view.
 */
const columns = [
  { name: 'hierarchy', label: 'Customer', field: 'name', sortable: false, align: 'left', style: 'width: 200px' },
  { name: 'tokens', label: 'Tokens', field: 'balance', sortable: false, align: 'right', style: 'width: 150px' },
  { name: 'devices', label: 'Devices', field: 'devices', sortable: false, align: 'right', style: 'width: 150px', stats: true },
  { name: 'active', label: 'Premium Devices', field: 'activeDevices', sortable: false, align: 'right', style: 'width: 100px', stats: true },
  { name: 'pending', label: 'Pending Devices', field: 'pendingDevices', sortable: false, align: 'right', style: 'width: 100px', stats: true },
  { name: 'standard', label: 'Standard Devices', field: 'standardDevices', sortable: false, align: 'right', style: 'width: 100px', stats: true },
  { name: 'expired', label: 'Expired Devices', field: 'expiredDevices', sortable: false, align: 'right', style: 'width: 100px', stats: true },
  { name: 'actions', label: '', align: 'right', sortable: false }
]


export function createState (initialState) {
  return {
    /**
     * Subscriber list columns
     */
    columns,
    /**
    * Viewed customer details
    */
    customer: null,
    /**
     * Customer's security guardian, needed to check their permissions
     */
    guardian: null,
    /**
     * Devices available to the customer
     */
    devices: [],
    /**
     * Customer's wallet
     */
    wallet: null,
    /**
     * Pass initial state
     */
    ...initialState
  }
}

export const state = createState()

export const getters = {
}
