/**
 * Supported alert types
 */
export const AlertType = {
  /**
   * Alert triggered when device goes offline and is no longer reporting its status
   */
  DeviceOffline: 'device-offline',
  /**
   * Alert triggered when band shuts down automatically due to technical problems such as overpower
   */
  BandShutdown: 'band-shutdown',
  /**
   * Alert triggered when device temperature exceeds the maximal allowed value
   */
  TemperatureExceeded: 'temperature-exceeded',
  /**
   * Alert triggered when device has rebooted too many times
   */
  DeviceReboots: 'device-reboots',
  /**
   * Alert triggered when one or more bands experience sustained reduced power
   */
  SustainedReducedPower: 'sustained-reduced-power',
  /**
   * Alert triggered when uplink is too active on one or more bands
   */
  ContinuousUplink: 'continuous-uplink',
  /**
   * Alert triggered when device is sending too many messages to the platform
   * (the dreaded ping-pong situation)
   */
  TooManyMessages: 'too-many-messages',
  /**
   * Feedback detected on band
   */
  Feedback: 'feedback',
  /**
   * Reduced gain detected on band
   */
  ReducedGain: 'clamp',
  /**
   * Port Sense detected a faulty antenna connection
   */
  PortSense: 'portsense',
  /**
   * Alert triggered when device battery is in use
   */
  BatteryInUse: 'battery-in-use',
  /**
   * OBSOLETE: Alert triggered when network connection is unreliable
   */
  PoorNetwork: 'poor-network',
  /**
  * OBSOLETE: Alert triggered when oscillation has reached the maximal allowed value on one or more bands
  */
  BandOscillation: 'band-oscillation',
  /**
  * OBSOLETE: Alert triggered when one or more bands experience sustained reduced power
  */
  DownlinkSignalWeak: 'downlink-signal-weak'
}

/**
 * List of all alert types, in order as we like to see them listed in the UI
 */
export const AlertTypes = [
  AlertType.DeviceOffline,
  AlertType.BandShutdown,
  AlertType.TemperatureExceeded,
  AlertType.DeviceReboots,
  AlertType.ReducedGain,
  AlertType.ContinuousUplink,
  AlertType.TooManyMessages,
  AlertType.Feedback,
  AlertType.PortSense,
  AlertType.PoorNetwork,
  AlertType.SustainedReducedPower,
  AlertType.BandOscillation,
  AlertType.DownlinkSignalWeak,
  AlertType.BatteryInUse
]

/**
 * Alert types by priority, from the most critical to the least important one
 */
export const AlertTypePriority = [
  AlertType.Feedback,
  AlertType.BandShutdown,
  AlertType.SustainedReducedPower,
  AlertType.ReducedGain,
  AlertType.BatteryInUse,
  AlertType.ContinuousUplink,
  AlertType.DeviceOffline,
  AlertType.TemperatureExceeded,
  AlertType.PortSense,
  AlertType.DeviceReboots,
  AlertType.TooManyMessages,
  // Obsolete alert types
  AlertType.DownlinkSignalWeak,
  AlertType.PoorNetwork,
  AlertType.BandOscillation,
]

/**
 * Alert type bitmask flags
 */
export const AlertTypeFlags = {
  [AlertType.DeviceOffline]: 1,
  [AlertType.BandShutdown]: 2,
  [AlertType.TemperatureExceeded]: 4,
  [AlertType.DeviceReboots]: 8,
  [AlertType.SustainedReducedPower]: 16,
  [AlertType.ContinuousUplink]: 64,
  [AlertType.TooManyMessages]: 128,
  [AlertType.Feedback]: 256,
  [AlertType.ReducedGain]: 512,
  [AlertType.PortSense]: 1024,
  [AlertType.PoorNetwork]: 2048,
  [AlertType.BandOscillation]: 4096,
  [AlertType.DownlinkSignalWeak]: 8192,
  [AlertType.BatteryInUse]: 16384
}

/**
 * Returns true if specified alert type represent a trend-based
 * alert, which requires a block of data collected over time
 * to determine whether alert situation has arisen
 * @param {AlertType} alertType Alert type to check
 * @returns {Boolean}
 */
export function isTrendAlert (alertType) {
  return [
    AlertType.DownlinkSignalWeak,
    AlertType.PoorNetwork,
    AlertType.DeviceReboots,
    AlertType.BandOscillation,
    AlertType.SustainedReducedPower
  ].includes(alertType)
}

/**
 * Returns true if specified alert type is
 * triggered by band parameters
 * @param {AlertType} alertType Alert type to check
 * @returns {Boolean}
 */
export function isBandAlert (alertType) {
  return [
    AlertType.BandOscillation,
    AlertType.BandShutdown,
    AlertType.ReducedGain,
    AlertType.Feedback,
    AlertType.ContinuousUplink,
    AlertType.DownlinkSignalWeak,
    AlertType.SustainedReducedPower
  ].includes(alertType)
}

/**
 * Returns true if specified alert type represent a schedule-based
 * alert, which requires a block of data collected during the specified schedule
 * and evaluated at the end of it, to determine whether alert situation has arisen
 * @param {AlertType} alertType Alert type to check
 * @returns {Boolean}
 */
export function isScheduleAlert (alertType) {
  return [
    AlertType.ContinuousUplink,
    AlertType.ReducedGain,
    AlertType.Clamp,
    AlertType.Feedback
  ].includes(alertType)
}

/**
 * Returns true if specified alert type is only available
 * to super organization, and not to any other customer
 * @param {AlertType} alertType Alert type to check
 * @returns {Boolean}
 */
export function isInternalAlert (alertType) {
  return [
    AlertType.TooManyMessages,
    AlertType.PortSense,
    AlertType.ReducedGain
  ].includes(alertType)
}

/**
 * Returns true if specified alert type is obsolete
 * and should no longer be accessible to end users
 * @param {AlertType} alertType Alert type to check
 * @returns {Boolean}
 */
export function isObsoleteAlert (alertType) {
  return [
    AlertType.DownlinkSignalWeak,
    AlertType.PoorNetwork,
    AlertType.BandOscillation
  ].includes(alertType)
}

/**
 * Active alert types, which are currently being checked
 * @type {Array[AlertType]}
 */
export const ActiveAlertTypes = AlertTypes.filter(alertType => !isObsoleteAlert(alertType))

/**
 * Obsolete alert types, which are no longer being checked
 * @type {Array[AlertType]}
 */
export const ObsoleteAlertTypes = AlertTypes.filter(alertType => isObsoleteAlert(alertType))

/**
 * Converts a list of alert types to integer
 * with alert type flags turned on
 * @param {Array[AlertType]} alertTypes Alert types
 * @returns {Number} Flags representing the specified alert types
 */
export function alertTypesToFlags (alertTypes) {
  if (alertTypes != null) {
    return alertTypes.reduce((flags, alertType) => flags | AlertTypeFlags[alertType], 0)
  }
}

/**
 * Converts alert types flags
 * to list of alert types
 * @param {Number} flags Selected alert types
 * @returns {Array[AlertType]} List of alert types selected in the flags
 */
export function flagsToAlertTypes (flags) {
  if (flags != null && flags >= 0) {
    return AlertTypes
      .reduce((alertTypes, alertType) => flags & AlertTypeFlags[alertType] ? [...alertTypes, alertType] : alertTypes, [])
  }
}