import { Log } from '@stellacontrol/utilities'
import { PrincipalType, Organization, OrganizationProfile, User, Feature, Session, Wallet, Permission } from '@stellacontrol/model'
import { APIClient } from './api-client'
import { APISession } from '../api-session'

/**
 * Security API client
 */
export class SecurityAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'Security'
  }

  /**
   * Pings the security API.
   * Used to refresh the security token during user inaction,
   * to keep the session alive.
   * @returns Refreshed session token
   */
  async ping () {
    try {
      const url = this.endpoint('security', 'ping')
      const { sessionToken } = await this.request({ url, progress: false, retry: false })
      return sessionToken
    } catch (error) {
      try {
        this.handleError(error)
      } catch (error) {
        // If ping failed with 401, this means our token has truly expired.
        // This can happen when computer has been in sleep mode for longer time,
        // or network wasn't available for whatever reason. In such case we
        // just need to log out, so we return nothing but throw no error either.
        const mustLogout = this.isAuthenticationError(error) || this.isNetworkError(error)
        if (!mustLogout) {
          throw error
        }
      }
    }
  }

  /**
   * Retrieves application features hierarchy
   */
  async getFeatures () {
    try {
      const url = this.endpoint('feature')
      const { features } = await this.request({ url })
      return this.asFeature(features)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Retrieves the specified principal
   * @param id Principal identifier
   * @param withDetails If true, all details are retrieved as well, such as permissions, profiles etc.
   */
  async getPrincipal ({ id, withDetails } = {}) {
    try {
      const url = this.endpoint('principal', id)
      let { principal } = await this.request({ url, params: { details: withDetails } })

      if (principal) {
        switch (principal.type) {
          case PrincipalType.User:
            principal = this.asUser(principal)
            break
          case PrincipalType.Organization:
            principal = this.asOrganization(principal)
            break
          case PrincipalType.OrganizationProfile:
            principal = this.asOrganizationProfile(principal)
            break
        }
        if (principal.wallet) {
          principal.wallet = new Wallet(principal.wallet)
        }
      }

      return principal

    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the list of organization profiles available for the current user
   * @param withPermissions If true, profile permissions are also retrieved
   */
  async getOrganizationProfiles ({ withPermissions } = {}) {
    try {
      const url = this.endpoint('organization-profile')
      const { organizationProfiles } = await this.request({ url, params: { permissions: withPermissions } })
      return this.asOrganizationProfiles(organizationProfiles)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Retrieves the specified organization profile
   * @param id Profile identifier
   * @param withDetails If true, all details are retrieved as well, such as organizations assigned to the profile, permissions etc.
   * @param withParents If true, organization profile creator is retrieved
   */
  async getOrganizationProfile ({ id, withDetails, withParents } = {}) {
    try {
      const url = this.endpoint('organization-profile', id)
      const { organizationProfile } = await this.request({ url, params: { details: withDetails, parents: withParents } })
      return this.asOrganizationProfile(organizationProfile)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Checks if the specified organization profile exists
   * @param name Organization profile name
   */
  async organizationProfileExists ({ name } = {}) {
    try {
      const url = this.endpoint('organization-profile', name, 'exists')
      const { id, exists } = await this.request({ url }) || {}
      return { id, exists }
    } catch (error) {
      this.handleError(error)
      return false
    }
  }

  /**
   * Returns organizations assigned to the specified organization profile
   * @param {String} id Organization profile identifier
   * @param {Boolean} withPermissions If true, permissions of these organizations are retrieved
   * @returns {Promise<Array[Organization]>}
   */
  async getOrganizationProfileMembers ({ id, withPermissions } = {}) {
    try {
      const url = this.endpoint('organization-profile', id, 'members')
      const { organizations } = await this.request({ url, params: { permissions: withPermissions } })
      return this.asOrganizations(organizations)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Updates the specified organization profile
   * @param data Profile to save
   */
  async saveOrganizationProfile ({ organizationProfile } = {}) {
    try {
      const data = {
        ...organizationProfile,
        creator: undefined,
        updater: undefined,
        owner: undefined,
        organizations: undefined,
      }
      const { id } = data
      const method = id == null ? 'post' : 'put'
      const url = this.endpoint('organization-profile', id)
      const { organizationProfile: savedOrganizationProfile } = await this.request({ method, url, data })
      return this.asOrganizationProfile(savedOrganizationProfile)
    } catch (error) {
      // Let the UI handle validation errors
      if (this.isBadRequestError(error)) {
        return this.getErrorResponse(error)
      } else {
        this.handleError(error)
      }
    }
  }

  /**
   * Deletes the specified organization profile
   * @param data Profile to delete
   */
  async deleteOrganizationProfile ({ organizationProfile: { id } } = {}) {
    try {
      const method = 'delete'
      const url = this.endpoint('organization-profile', id)
      const { organizationProfile } = await this.request({ method, url })
      return this.asOrganizationProfile(organizationProfile)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the list of organizations
   * @param {String} parentOrganizationId If specified, only child organizations
   * of the specified reseller organization are retrieved
   * @returns {Promise<Array[Organization]>}
   */
  async getOrganizations ({ parentOrganizationId } = {}) {
    try {
      const url = parentOrganizationId
        ? this.endpoint('organization', parentOrganizationId, 'organization')
        : this.endpoint('organization')
      const { organizations } = await this.request({ url })
      return this.asOrganizations(organizations)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Retrieves the specified organization
   * @param id Organization identifier
   * @param withDetails If true, all details are retrieved as well, such as organization permissions etc.
   * @param withParents If true, the creator, organization profile and parent organizations are retrieved, optionally with their all details
   * @param withChildOrganizations If true, a list of child organizations is retrieved
   * @param withPlaces If true, a list of places belonging to the organization is retrieved
   */
  async getOrganization ({ id, withDetails, withParents, withChildOrganizations, withPlaces } = {}) {
    try {
      const url = this.endpoint('organization', id)
      const { organization } = await this.request({
        url,
        params: {
          details: withDetails,
          parents: withParents,
          children: withChildOrganizations,
          places: withPlaces
        }
      })
      return this.asOrganization(organization)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Checks if the specified organization exists
   * @param name Organization name
   */
  async organizationExists ({ name } = {}) {
    try {
      const url = this.endpoint('organization', name, 'exists')
      const { id, exists } = await this.request({ url }) || {}
      return { id, exists }
    } catch (error) {
      this.handleError(error)
      return false
    }
  }

  /**
   * Updates the specified organization
   * @param data Organization to update
   */
  async updateOrganization ({ organization } = {}) {
    try {
      // Cleanup some runtime properties, no need to transfer them over the wire,
      // their identifiers are sufficient
      const data = { ...organization }
      delete data.profile
      delete data._profile
      delete data.parentOrganization
      delete data.parentOrganizations
      delete data.organizations
      delete data.places
      delete data.wallet
      delete data.administrators
      delete data.users
      delete data.creator
      delete data.updater
      delete data.preferences
      delete data.tags
      delete data.notes

      const { id } = data
      const method = 'put'
      const url = this.endpoint('organization', id)
      const { organization: savedOrganization } = await this.request({ method, url, data })
      return this.asOrganization(savedOrganization)
    } catch (error) {
      // Let the UI handle validation errors
      if (this.isBadRequestError(error)) {
        return this.getErrorResponse(error)
      } else {
        this.handleError(error)
      }
    }
  }

  /**
   * Creates a new organization
   * @param data Organization to create
   * @param createAdministratorAccount If true, administrator account will be automatically created
   * @returns A dictionary with organisation and created admin user
   */
  async createOrganization ({ organization, createAdministratorAccount = false } = {}) {
    try {
      // Cleanup some runtime properties, no need to transfer them over the wire,
      // their identifiers are sufficient
      const data = { ...organization }
      delete data.profile
      delete data.parentOrganization
      delete data.parentOrganizations
      delete data.organizations
      delete data.places
      delete data.wallet
      delete data.administrators
      delete data.users
      delete data.creator
      delete data.updater
      delete data.preferences
      delete data.tags
      delete data.notes

      const method = 'post'
      const url = this.endpoint('organization')
      const params = { createAdministratorAccount }
      const { organization: savedOrganization, user: savedUser } = await this.request({ method, url, data, params })
      return {
        organization: this.asOrganization(savedOrganization),
        user: this.asUser(savedUser)
      }
    } catch (error) {
      // Let the UI handle validation errors
      if (this.isBadRequestError(error)) {
        return this.getErrorResponse(error)
      } else {
        this.handleError(error)
      }
    }
  }

  /**
   * Deletes the specified organization
   * @param data Organization to delete
   */
  async deleteOrganization ({ organization: { id } } = {}) {
    try {
      const method = 'delete'
      const url = this.endpoint('organization', id)
      const { organization } = await this.request({ method, url })
      return this.asOrganization(organization)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the list of users
   * @param organizationId If specified, only users
   * of the specified organization are retrieved
   */
  async getUsers ({ organizationId } = {}) {
    try {
      const url = organizationId
        ? this.endpoint('organization', organizationId, 'user')
        : this.endpoint('user')
      const { users } = await this.request({ url })
      return this.asUsers(users)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Retrieves the specified user
   * @param id User identifier
   * @param withDetails If true, all details are retrieved as well, such as user permissions etc.
   * @param withParents If true, the creator, user profile and parent users are retrieved, optionally with their all details
   */
  async getUser ({ id, withDetails, withParents } = {}) {
    try {
      const url = this.endpoint('user', id)
      const { user } = await this.request({ url, params: { details: withDetails, parents: withParents } })
      return this.asUser(user)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns user by name
   * @param name User name
   * @param withDetails If true, all details are retrieved as well, such as user permissions etc.
   * @param withParents If true, the creator, user profile and parent users are retrieved, optionally with their all details
   */
  async getUserByName ({ name, withDetails, withParents } = {}) {
    try {
      const url = this.endpoint('user', 'name', name)
      const { user } = await this.request({ url, params: { details: withDetails, parents: withParents } }) || {}
      return this.asUser(user)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Checks if the specified user exists
   * @param name User name
   */
  async userExists ({ name } = {}) {
    try {
      const url = this.endpoint('user', name, 'exists')
      const { id, exists } = await this.request({ url }) || {}
      return { id, exists }
    } catch (error) {
      this.handleError(error)
      return false
    }
  }

  /**
   * Updates the specified user
   * @param data User to save
   */
  async saveUser ({ user } = {}) {
    try {
      // Cleanup some runtime properties, no need to transfer them over the wire,
      // their identifiers are sufficient
      const data = { ...user }
      delete data.organization

      const { id } = data
      const method = id == null ? 'post' : 'put'
      const url = this.endpoint('user', id)
      const { user: savedUser } = await this.request({ method, url, data })
      return this.asUser(savedUser)
    } catch (error) {
      // Let the UI handle validation errors
      if (this.isBadRequestError(error)) {
        return this.getErrorResponse(error)
      } else {
        this.handleError(error)
      }
    }
  }

  /**
   * Deletes the specified user
   * @param data User to delete
   */
  async deleteUser ({ user: { id } } = {}) {
    try {
      const method = 'delete'
      const url = this.endpoint('user', id)
      const { user } = await this.request({ method, url })
      return this.asUser(user)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Authenticates the user, returns session containing user details,
   * principals and their permissions, session identifier etc.
   * @param {String} name User name
   * @param {String} password Password
   * @param {Boolean} isMobile if the user is logging from a mobile device
   * @param {String} reCaptchaToken reCaptcha token obtained from Google reCaptcha service during interactive login
   * @param {Boolean} short Indicates that a short-duration session token is needed, for a few calls at most
   * @returns {Session} Session details
   */
  async login ({ name, password, reCaptchaToken, isMobile, short } = {}) {
    try {
      const url = this.endpoint('security', 'login')
      const method = 'post'
      const data = {
        name,
        password,
        reCaptchaToken,
        isMobile,
        short
      }

      const { session, message } = await this.request({ url, method, data, retry: false, authorization: false })
      const result = { session: this.asSession(session), message }

      return result

    } catch (error) {
      if (this.isAuthenticationError(error)) {
        const { message, reason } = this.getErrorData(error, 'Login failed') || { message: 'Login failed', reason: 'unknown' }
        return { message, reason }
      } else {
        this.handleError(error)
      }
    }
  }

  /**
   * Logs in to the specified organization on behalf of its admin
   * @param {Organization} organization Child organization to log in to
   * @param {User} user Optional user to impersonate. If not specified, the primary administrator of the child organization will be impersonated.
   * @param {Boolean} noReturn If true, returning back to the currently logged in organization
   * won't be possible, full logout/login will be required instead
   */
  async loginAs ({ organization, user, noReturn } = {}) {
    try {
      const url = this.endpoint('security', 'login', 'as')
      const method = 'post'
      const data = {
        organizationId: organization.id,
        organizationUserId: user?.id,
        noReturn
      }

      const { session, message } = await this.request({ url, method, data, retry: false })
      const result = { session: this.asSession(session), message }

      return result
    } catch (error) {
      if (this.isAuthenticationError(error)) {
        const { message, reason } = this.getErrorData(error, 'Login failed') || { message: 'Login failed', reason: 'unknown' }
        return { message, reason }
      } else {
        this.handleError(error)
      }
    }
  }

  /**
   * Reauthenticates the existing session
   * @param {String} token Session token
   */
  async relogin ({ token } = {}) {
    try {
      const url = this.endpoint('security', 'relogin')
      const method = 'post'
      const headers = await APISession.getAuthorizationHeaders(token)
      const data = {
        token: headers['Authorization']
      }

      const { session, message } = await this.request({ url, method, data, headers, retry: false })
      const result = { session: this.asSession(session), message }

      return result
    } catch (error) {
      if (this.isAuthenticationError(error)) {
        const { message, reason } = this.getErrorData(error, 'Login failed') || { message: 'Login failed', reason: 'unknown' }
        return { message, reason }
      } else {
        this.handleError(error)
      }
    }
  }

  /**
   * Checks whether the current user is still logged in
   */
  async isLoggedIn () {
    try {
      const url = this.endpoint('login')
      await this.request({ url, params: { noTokenRefresh: true } })
    } catch (error) {
      if (this.isAuthenticationError(error)) {
        return false
      } else {
        this.handleError(error)
      }
    }
  }

  /**
   * Closes user session
   * @param name User name
   * @param sessionToken Session token
   */
  async logout ({ name, sessionToken } = {}) {
    try {
      const url = this.endpoint('security', 'login')
      const method = 'delete'
      const data = { name, sessionToken }
      const { message } = await this.request({ url, method, data, retry: false })
      return { message }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Re-sends user invitation email
   * @param id User identifier
   */
  async inviteUser ({ id } = {}) {
    try {
      const url = this.endpoint('security', 'invite-user', id)
      const method = 'put'
      const data = { id }
      const { user } = await this.request({ url, method, data })
      return this.asUser(user)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Activates user account
   * @param token Activation request token
   * @param user User details
   * @param sendEmail If true, e-mail is sent to the user, notifying him that the account has been activated
   */
  async activateUser ({ token, user, sendEmail } = {}) {
    try {
      const url = this.endpoint('security', 'activate-user', token)
      const method = 'put'
      const data = { user, sendEmail }
      const { user: activatedUser } = await this.request({ url, method, data })
      return this.asUser(activatedUser)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * User approves terms and conditions
   * @param {String} id Identifier of the Terms and Conditions document approved by the user
   * @param {String} name Name of the Terms and Conditions document
   * @param {Date} time Time when user has approved the Terms and Conditions
   */
  async approveTermsAndConditions ({ id, name, time } = {}) {
    try {
      const url = this.endpoint('security', 'approve-terms-and-conditions')
      const method = 'put'
      const data = { id, name, time }
      const result = await this.request({ url, method, data })
      return result
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the specified security request if it's still valid
   * @param type Request type
   * @param token Request token
   */
  async getSecurityRequest ({ type, token } = {}) {
    try {
      const url = this.endpoint('security', 'request', type, token)
      const method = 'get'
      const result = await this.request({ url, method }) || {}
      return result.request
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Resets user password
   * @param idOrName User identifier or name
   * @param sendEmail If true, e-mail is sent to the user, asking him to set his new password
   * @param reCaptchaToken reCaptcha token obtained from Google reCaptcha service during interactive login
   */
  async resetPassword ({ idOrName, sendEmail, reCaptchaToken } = {}) {
    try {
      const url = this.endpoint('security', 'reset-password', idOrName)
      const method = 'put'
      const data = { sendEmail, reCaptchaToken }
      const { success, token, mailSent, pendingRequest, timeToExpire, message } = await this.request({ url, method, data, retry: false })
      return { success, token, mailSent, pendingRequest, timeToExpire, message }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Changes user password
   * @param token Password change token
   * @param password New password
   * @param reCaptchaToken reCaptcha token obtained from Google reCaptcha service during interactive login
   */
  async changePassword ({ token, password, reCaptchaToken } = {}) {
    try {
      const url = this.endpoint('security', 'change-password', token)
      const method = 'put'
      const data = { password, reCaptchaToken }
      const { mailSent, message } = await this.request({ url, method, data, retry: false })
      return { mailSent, message }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Verfies Google reCaptcha token
   * @param token Token to verify
   * @param secret Secret key for token verification
   */
  async validateReCaptcha ({ token, secret } = {}) {
    if (!token) throw new Error('Token is required')
    if (!secret) throw new Error('Secret key is required')

    const url = 'https://www.google.com/recaptcha/api/siteverify'

    try {
      const data = `secret=${secret}&response=${token}`
      const options = {
        url,
        method: 'post',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data
      }

      const response = await this.rawRequest(options)
      const { success, challenge_ts, hostname, score, action, 'error-codes': errors = [] } = response
      const error = errors.length === 0 ? undefined : errors.join(', ')

      return {
        success,
        challenge_ts,
        hostname,
        score,
        action,
        error
      }

    } catch (error) {
      Log.error('reCaptcha token verification failed with error')
      Log.error('reCaptcha endpoint:', url)
      Log.exception(error)
      return { error: error.message }
    }
  }

  /**
   * Sets organization language and/or country
   * @param {Organization} organization Organization to update
   * @param {String} countryCode ISO 3166 Alpha-2 country code
   * @param {String} languageCode ISO 639-2 language code
   * @param {String} timezone Name of country timezone, as per https://www.iana.org/time-zones
   * @returns {Promise<Organization>} The updated organization
   */
  async setLocale ({ organization, countryCode, languageCode, timezone } = {}) {
    try {
      const url = this.endpoint('organization', organization.id, 'locale')
      const method = 'put'
      const data = { countryCode, languageCode, timezone }
      const result = await this.request({ url, method, data })
      return this.asOrganization(result?.organization)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Updates the specified permissions of a principal
   * @param {Principal} principal Principal to update permissions
   * @param {Array[Permission]} permissions Permissions to grant or deny
   * @returns {Promise<Principal>} The updated principal
   */
  async updatePermissions ({ principal, permissions } = {}) {
    if (principal && permissions?.length > 0) {
      try {
        const url = this.endpoint('permissions', principal.id)
        const method = 'put'
        const data = {
          principal: { id: principal.id },
          permissions: permissions.map(p => new Permission(p).getCore())
        }
        const result = await this.request({ url, method, data })
        return result?.principal
      } catch (error) {
        this.handleError(error)
      }
    } else {
      return principal
    }
  }

  /**
   * Converts the specified data item to Feature instance
   * @param item Data item
   */
  asFeature (item) {
    if (item) {
      const features = (item.features || []).map(feature => this.asFeature(feature))
      return new Feature({ ...item, features })
    }
  }

  /**
   * Converts the specified data item OrganizationProfile instance
   * @param item Data item
   */
  asOrganizationProfile (item) {
    if (item) {
      return new OrganizationProfile(item)
    }
  }

  /**
   * Converts the specified data items to OrganizationProfile instance
   * @param items Data items
   */
  asOrganizationProfiles (items = []) {
    return items.map(item => this.asOrganizationProfile(item))
  }

  /**
   * Converts the specified data item Organization instance
   * @param item Data item
   */
  asOrganization (item) {
    if (item) {
      return new Organization(item)
    }
  }

  /**
   * Converts the specified data items to Organization instance
   * @param items Data items
   */
  asOrganizations (items = []) {
    return items.map(item => this.asOrganization(item))
  }

  /**
   * Converts the specified data item User instance
   * @param item Data item
   */
  asUser (item) {
    if (item) {
      return new User(item)
    }
  }

  /**
   * Converts the specified data items to User instance
   * @param items Data items
   */
  asUsers (items = []) {
    return items.map(item => this.asUser(item))
  }

  /**
   * Converts the specified data item to Session instance
   * @param item Data item
   */
  asSession (item) {
    if (item) {
      return new Session({ ...item })
    }
  }
}
