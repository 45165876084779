<script>
import { isMegaParameterApplicable, getMegaValue } from '@stellacontrol/mega'
import { range } from '@stellacontrol/utilities'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  data () {
    return {
    }
  },

  computed: {
    identity () {
      return this.status?.identity
    },

    health () {
      return this.status?.health
    },

    isAvailable () {
      const { device, status, health } = this
      return isMegaParameterApplicable('status_portsense', device, status) && health?.hasPortSense
    },

    externalAntenna () {
      return this.health?.externalAntenna
    },

    internalAntenna () {
      return this.health?.internalAntenna || []
    },

    // Enumeration of device ports
    ports () {
      return [...range(0, this.identity?.portCount || 0)]
    },

    portSenseMode () {
      const { device, status } = this
      if (status) {
        const { label } = getMegaValue('_portsense_mode', status.mega, device, status)
        return label
      }
      return ''
    },

    items () {
      return [
        { text: 'Port Sense', class: { 'label': true } },
      ]
    },

    // Determines whether the external antenna should be visible
    externalAntennaVisible () {
      const { device, status } = this
      return isMegaParameterApplicable('status_portsense_upstream', device, status)
    }
  }
}
</script>

<template>
  <sc-widget-text v-if="isAvailable" v-bind="$props" :items="items">
    <template #toolbar>
      <div class="row items-center q-ml-sm q-mr-sm">
        <div class="dot" :class="{ 'on': internalAntenna[index] }" v-for="index in ports">
          <span>
            {{ index + 1 }}
          </span>
          <sc-tooltip v-if="internalAntenna[index]">
            Internal antenna {{ index + 1 }} is connected
          </sc-tooltip>
          <sc-tooltip v-else>
            Internal antenna {{ index + 1 }} is not connected
            or there is a fault with the antenna, cable or connectors.
          </sc-tooltip>
        </div>
        <q-icon v-if="externalAntennaVisible" class="antenna" name="podcasts"
          :color="externalAntenna ? 'green-6' : 'gray-6'" size="24px">
          <sc-tooltip v-if="externalAntenna">
            External antenna is connected
          </sc-tooltip>
          <sc-tooltip v-else>
            The external antenna is not connected or there is a fault
            with the antenna, cable or connectors.
          </sc-tooltip>
        </q-icon>
      </div>
      <div class="q-mt-md q-ml-sm q-mr-md mode" v-if="portSenseMode">
        PortSense {{ portSenseMode.toLowerCase() }}
      </div>
    </template>
  </sc-widget-text>
</template>

<style lang="scss" scoped>
.antenna {
  cursor: pointer;
}

.dot {
  width: 23px;
  height: 23px;
  border-radius: 11px;
  margin-right: 6px;
  background-color: #636363;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  cursor: pointer;

  &.on {
    background-color: #2ECD6F;
    color: black;
  }

  &.error {
    background-color: #cc2222;
  }
}

.mode {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #2a2a2a;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
