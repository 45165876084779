import { parseDate, clone } from '@stellacontrol/utilities'
import { EntityType } from '../common/entity-type'
import { Assignable } from '../common/assignable'
import { AlertType } from './alert-type'

/**
 * Coonfiguration of alert type,
 * optionally in context of a specific organization, place or device.
 * @description Alert configurations are globally specified
 * per alert type. These can be further customized on the level
 * of owner organization, place where device is assigned,
 * and ultimately for each device individually.
 */
export class AlertConfiguration extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(
      {
        ...data,
        trend: data.trend || 0,
        schedule: data.schedule || '',
        probingFrequency: data.probingFrequency || 0,
        createdAt: data.createdAt || new Date(),
        isEnabled: data.isEnabled == null ? true : Boolean(data.isEnabled),
        isSuspended: data.isSuspended == null ? false : Boolean(data.isSuspended),
        isObsolete: data.isObsolete == null ? false : Boolean(data.isObsolete),
        canRepeat: data.canRepeat == null ? false : Boolean(data.canRepeat),
        parameters: { ...(data.parameters || {}) }
      },
      {
        createdAt: parseDate
      }
    )
  }

  // Properties of alert configuration which are present on all alert types
  static GeneralProperties = [
    'isEnabled',
    'isSuspended',
    'isObsolete',
    'isSupported',
    'canRepeat',
    'frequency',
    'canEditFrequency',
    'canEditSchedule',
    'allowedFrequencies',
    'trend',
    'trendMin',
    'trendMax',
    'probingFrequency',
    'schedule',
    'checkAlertDuringPeriod',
    'when'
  ]

  // Properties of alert configuration which are NOT editable by the end user, but are defined through configuration files instead.
  // Typically these are min-max boundaries used in alert configuration editor.
  static ReadonlyProperties = [
    'isSuspended',
    'isObsolete',
    'canRepeat',
    'isSupported',
    'canEditFrequency',
    'canEditSchedule',
    'allowedFrequencies',
    'trend',
    'trendMin',
    'trendMax',
    'probingFrequency',
    'checkAlertDuringPeriod',
    'when'
  ]

  // Alert-specific parameters which are NOT editable by the end user
  static ReadonlyParameters = [
    // device-offline
    'noHeartbeatForMin',
    'noHeartbeatForMax',
    'noHeartbeatForIntervals',
    'ignoreOlderThan',
    // temperature-exceeded
    'temperature',
    'minTemperature',
    'maxTemperature',
    // device-reboots
    'hardwareRebootCount',
    'softwareRebootCount',
    'minRebootCount',
    'maxRebootCount',
    // continuous-uplink
    'maxUplink',
    // portsense
    'minFaultDuration',
    'maxFaultDuration',
    'faultDuration',
    // sustained-reduced-power
    'statistics',
    'powerReductionValues',
    // too-many-messages
    'maxMessagesPerDay',
    'maxMessagesPerHour',
    'maxMessagesPerMinute',
    // poor-network
    'errorCount',
    'minErrorCount',
    'maxErrorCount',
    // band-oscillation
    // downlink-signal-weak
    'osc_max',
    'mean_dw'
  ]

  // Obsolete parameters which should be cleaned from configuration at nearest save
  static ObsoleteParameters = ['monitoredPeriod']

  normalize () {
    super.normalize()
    if (!this.parameters) {
      this.parameters = {}
    }
    for (const key of AlertConfiguration.ObsoleteParameters) {
      delete this.parameters[key]
    }
  }

  /**
   * Alert configuration identifier
   * @type {String}
   */
  id

  /**
   * Alert type
   * @type {AlertType}
   */
  alertType

  /**
   * Date and time when alert configuration has been saved
   * @type {Date}
   */
  createdAt

  /**
   * User which configured the alert
   * @type {String}
   */
  createdBy

  /**
   * Device to which the alert configuration applies
   * @type {String}
   */
  deviceId

  /**
   * Identifier of the entity to which configuration applies
   * @type {String}
   */
  get entityId () {
    return this.deviceId
  }

  /**
   * Type of the entity to which configuration applies
   * @type {EntityType}
   */
  get entityType () {
    return EntityType.Device
  }

  /**
   * Indicates that alert temporarily should not be monitored at all
   * @type {Boolean}
   */
  isEnabled

  /**
   * Indicates that alert should not be monitored at all,
   * but it will still be visible in the alert configuration UI
   * @type {Boolean}
   */
  isSuspended

  /**
   * Indicates that alert has been obsoleted, so it should not be checked
   * and shouln't be visible in the alert configuration UI
   * @type {Boolean}
   */
  isObsolete

  /**
   * Used to mark the alert as supported or not.
   * This is typically used in combination with alert applicability rules
   * specified in {@link when} property
   * @type {Boolean}
   */
  isSupported

  /**
   * If true, the alert will be triggered repeatedly at specified {@link frequency}
   * if detected anomaly persists. Otherwise the alert will be triggered
   * only once, then again only when anomaly disappears and reappears again
   * @type {Boolean}
   */
  canRepeat

  /**
   * Frequency at which the alert should be reported for the same device, in seconds.
   * If alert is detected more frequently within the same time window,
   * we don't want to be bothered
   * @type {Number}
   */
  frequency
  frequencyMin
  frequencyMax

  /**
   * Indicates whether the alert frequency is configurable by end user
   * @type {Boolean}
   */
  canEditFrequency

  /**
   * Alert frequencies to choose from
   * @type {Array[Number]}
   */
  allowedFrequencies

  /**
   * Length of time, in seconds, for which historic values relevant for the alert
   * should be retained. Used by alerts which are based on trends or deltas
   * rather than just on an absolute threshold value of a signal
   * @type {Number}
   */
  trend
  trendMin
  trendMax

  /**
   * Frequency of collecting data points for the trend, optional.
   * Often it doesn't have to be as frequent as alert checking frequency.
   * @type {Number}
   */
  probingFrequency

  /**
   * Alert schedule, string representation of DaySchedule instance
   * @type {String}
   */
  schedule

  /**
   * Indicates whether the schedule is configurable by end user
   * @type {Boolean}
   */
  canEditSchedule

  /**
   * If true, indicates that alert is checked continuously during the selected period.
   * By default the alert is checked only at the end of the selected period,
   * using data collected during the selected period.
   * @type {Boolean}
   */
  checkAlertDuringPeriod

  /**
   * Alert applicability rules, similar to MEGA parameter applicability rules.
   * Using them we can modify alert defaults or conditionally disable the alert
   * for certain types of devices, firmware versions and other rules.
   * @type {Object}
   */
  when

  /**
   * Other alert-specific settings and threshold parameters
   * @type {Object}
   */
  parameters

  // Parameters which should be persisted in the database
  get storedParameters () {
    if (this.parameters) {
      const parameters = { ...this.parameters }
      if (parameters) {
        for (const key of AlertConfiguration.ReadonlyParameters) {
          delete parameters[key]
        }
      }
      return parameters
    }
  }

  /**
   * Indicates that alert configuration has been modified
   * and is no longer same as default
   * @type {Boolean}
   */
  isModified

  /**
   * Clones the configuration
   * @returns {AlertConfiguration}
   */
  clone () {
    const configuration = new AlertConfiguration(JSON.parse(JSON.stringify(this)))
    return configuration
  }

  /**
   * Sets the configuration with values from another configuration
   * @param {AlertConfiguration} source Alert configuration to assign the values from
   */
  assignFrom (source) {
    if (source) {
      for (const property of AlertConfiguration.GeneralProperties) {
        this[property] = source[property]
      }
      if (source.parameters) {
        this.parameters = clone(source.parameters)
      }
    }
  }

  /**
   * Checks whether configuration has the same values as the specified one.
   * Only user-editable values are considered
   * @param {AlertConfiguration} source Source configuration to compare with
   * @returns {Boolean} `true` if configuration has the same values as the specified one
   */
  sameAs (source) {
    if (source) {
      // Compare general properties
      for (const property of AlertConfiguration.GeneralProperties) {
        if (this[property] == null) continue
        // Skip not applicable read-only properties
        if (AlertConfiguration.ReadonlyProperties.includes(property)) continue
        if (this[property] != source[property]) {
          return false
        }
      }

      // Compare alert-specific parameters
      if (source.parameters) {
        if (!this.parameters) {
          return false
        }

        for (const [parameter, value] of Object.entries(this.parameters || {})) {
          // Skip not applicable read-only parameters
          if (AlertConfiguration.ReadonlyParameters.includes(parameter)) continue
          // Compare objects
          if (value != null && Object.keys(value).length > 0) {
            // Compare compound parameters
            for (const parameterProperty of Object.keys(value)) {
              const parameterValue = (this.parameters[parameter] || {})[parameterProperty]
              const sourceValue = (source.parameters[parameter] || {})[parameterProperty]
              if (parameterValue != sourceValue) {
                return false
              }
            }
          } else {
            // Compare simple parameters
            if (this.parameters[parameter] != source.parameters[parameter]) {
              return false
            }
          }
        }
      }

      return true
    }
  }

  /**
   * Removes those values which are read-only therefore should not be persisted in custom configurations table.
   * In the past, we didn't take care of this and some of these values were persisted, along with values
   * which are editable by end user. This function cleans this up.
   * @returns {AlertConfiguration} Cleaned up alert configuration
   */
  cleanup () {
    const { parameters } = this
    this.applyRanges()

    // Cleanup readonly general properties
    for (const property of AlertConfiguration.ReadonlyProperties) {
      delete this[property]
    }

    // Cleanup readonly parameters
    if (parameters) {
      for (const property of AlertConfiguration.ReadonlyParameters) {
        delete parameters[property]
      }

      // Go recursive into parameters
      for (const parameter of Object.values(parameters)) {
        if (parameter != null && Object.values(parameter).length > 0) {
          for (const property of AlertConfiguration.ReadonlyParameters) {
            delete parameter[property]
          }
        }
      }
    }

    return this
  }

  /**
   * Applies minimal and maximal values to those values for which such ranges are defined in the configuration
   * @param {AlertConfiguration} ranges Source of range values to compare with. If not specified, the instance itself is used.
   * @returns {AlertConfiguration} Alert configuration with ranges applied to configuration values
   */
  applyRanges (ranges) {
    const { alertType, parameters } = this
    ranges = ranges || this

    if (ranges.isSuspended) {
      this.isSuspended = ranges.isSuspended
    }

    if (ranges.canEditFrequency && !ranges.allowedFrequencies?.length > 0 && !ranges.allowedFrequencies.includes(this.frequency)) {
      this.frequency = ranges.allowedFrequencies[0]
    }

    if (ranges.trendMin > this.trend) {
      this.trend = ranges.trendMin
    }
    if (ranges.trendMax < this.trend) {
      this.trend = ranges.trendMax
    }

    // Use probing frequency from defaults, it's no longer editable in the UI
    if (ranges.probingFrequency > 0) {
      this.probingFrequency = ranges.probingFrequency
    }

    if (alertType === AlertType.DeviceOffline) {
      const { noHeartbeatForMin, noHeartbeatForMax, noHeartbeatForIntervals } = ranges.parameters || {}
      if (noHeartbeatForMin > parameters.noHeartbeatFor) {
        parameters.noHeartbeatFor = noHeartbeatForMin
      }
      if (noHeartbeatForMax < parameters.noHeartbeatFor) {
        parameters.noHeartbeatFor = noHeartbeatForMax
      }
      if (noHeartbeatForIntervals?.length > 0 && !noHeartbeatForIntervals.includes(parameters.noHeartbeatFor)) {
        parameters.noHeartbeatFor = noHeartbeatForIntervals[0]
      }
    }

    if (alertType === AlertType.TemperatureExceeded) {
      const { minTemperature, maxTemperature } = ranges.parameters || {}
      if (minTemperature > parameters.temperature) {
        parameters.temperature = minTemperature
      }
      if (maxTemperature < parameters.temperature) {
        parameters.temperature = maxTemperature
      }
    }

    if (alertType === AlertType.DeviceReboots) {
      const { minRebootCount, maxRebootCount } = ranges.parameters || {}
      if (minRebootCount > parameters.hardwareRebootCount) {
        parameters.hardwareRebootCount = minRebootCount
      }
      if (maxRebootCount < parameters.hardwareRebootCount) {
        parameters.hardwareRebootCount = maxRebootCount
      }
      if (minRebootCount > parameters.softwareRebootCount) {
        parameters.softwareRebootCount = minRebootCount
      }
      if (maxRebootCount < parameters.softwareRebootCount) {
        parameters.softwareRebootCount = maxRebootCount
      }
    }

    if (alertType === AlertType.TooManyMessages) {
      const { maxMessageCount } = parameters
      if (maxMessageCount < 0) {
        parameters.maxMessageCount = 0
      }
    }

    if (alertType === AlertType.SustainedReducedPower) {
      const { powerReduction, powerReductionValues } = parameters
      if (powerReductionValues && !powerReductionValues.includes(powerReduction)) {
        parameters.powerReduction = powerReductionValues[0]
      }
    }

    return this
  }

  /**
   * Applies custom values to alert configuration
   * @param {AlertConfiguration} source Custom alert configuration
   * @returns {AlertConfiguration} New alert configuration with custom values applied
   */
  customize (source) {
    const configuration = this.clone()

    if (source) {
      const overrideProperty = (name, instance, source) => {
        if (source && instance) {
          if (source[name] != null && source[name] != instance[name]) {
            instance[name] = source[name]
          }
        }
      }

      for (const property of AlertConfiguration.GeneralProperties) {
        overrideProperty(property, configuration, source)
      }

      if (source.parameters) {
        if (!configuration.parameters) {
          configuration.parameters = {}
        }
        for (const [parameter, value] of Object.entries(source.parameters || {})) {
          if (Array.isArray(value) || typeof value === 'string') {
            // Override array and string parameters
            overrideProperty(parameter, configuration.parameters, source.parameters)
          } else if (value != null && Object.keys(value).length > 0) {
            // Override compound parameters
            for (const parameterProperty of Object.keys(value)) {
              overrideProperty(parameterProperty, configuration.parameters[parameter], source.parameters[parameter])
            }
          } else {
            // Override simple parameters
            overrideProperty(parameter, configuration.parameters, source.parameters)
          }
        }
      }
    }

    configuration.applyRanges()
    return configuration
  }
}
