import { getStore } from '../get-store'

/**
 * Returns store getters, or the value of a specific getter
 * @param {String} getter Getter name to return, optional
 * @returns {Object|any} All getters or the value of a specific getter
 */
export function getters (getter) {
  const store = getStore()
  if (getter) {
    return store.getters[getter]
  } else {
    return store.getters
  }
}
