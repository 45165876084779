import { sortItems, toBase26, fromBase26 } from '@stellacontrol/utilities'
import { DeviceType, DeviceTypeName, DeviceAcronym } from '@stellacontrol/model'
import { PlanItemType, PlanItemName } from '../items/plan-item'
import { PlanPortType } from '../items/plan-port'
import { AntennaName } from '../items/plan-antenna'
import { SplitterName } from '../items/plan-splitter'
import { CableName } from '../items/plan-cable'

/**
 * Service class to create the equipment hierarchy from equipment and connectors
 */
export class PlanHierarchyBuilder {
  /**
   * Returns equipment hierarchy build around repeaters
   * @param {PlanLayout} layout Plan layout
   * @returns {Array}
   */
  static create (layout) {
    const { items } = layout
    for (const item of items) {
      item.root = undefined
    }

    const connectors = items.filter(i => i.is(PlanItemType.Cable || PlanItemType.Connector) && !i.partOf)
    const equipment = items.filter(item => item.isOnItemLayer && item.isEquipment && !item.isConnector)
    const repeaters = equipment.filter(i => i.isDeviceType(DeviceType.Repeater))
    const lineamps = equipment.filter(i => i.isDeviceType(DeviceType.LineAmp))
    const rootItems = [
      ...repeaters,
      ...lineamps.filter(lineamp => !this.hasConnectedItem(lineamp, PlanPortType.In, connectors))
    ]
    const childItems = equipment.filter(i => !rootItems.find(ri => ri.id === i.id))

    const linked = []
    const notLinked = []

    for (const item of childItems) {
      const { parent, connector } = this.getParentOf(item, connectors)
      const child = this.getHierarchyItem({ item, parent, connector })
      if (parent) {
        linked.push(child)
      } else {
        notLinked.push(child)
      }
    }

    // Create hierarchy root entries: for all repeaters, and all lineamps which aren't linked to a repeater yet
    const hierarchy = rootItems.map(item => {
      // Assign subsequent tag to the root repeaters
      if (item.isRepeater && item.tag == null) {
        item.tag = this.getNextTag(layout)
        item.tagIndex = 1
      }
      // Create a root hierarchy item
      const hierarchyItem = this.getHierarchyItem({
        item: item,
        items: linked,
        root: item
      })
      return hierarchyItem
    })

    // Add node for all equipment which not yet linked to any root item
    if (notLinked.length > 0) {
      const item = this.getHierarchyItem({ item: {} })
      item.descendants = notLinked
      hierarchy.push(item)
    }

    // Assign repeater's tag to all items under it
    const indices = repeaters.reduce((all, repeater) => ({ ...all, [repeater.id]: 1 }), {})
    const setRoot = (hierarchyItem, root) => {
      for (const descendant of hierarchyItem.descendants || []) {
        const item = childItems.find(i => i.id === descendant.id)
        if (item) {
          // Remember the parent repeater to which the item belongs
          item.root = root.id
          if (item.canHaveTag && root.isRepeater && root.tag) {
            item.tag = root.tag
            item.tagIndex = ++indices[root.id]
          } else {
            item.tag = undefined
            item.tagIndex = undefined
          }
        }
        setRoot(descendant, root)
      }
    }

    for (const root of hierarchy) {
      setRoot(root, root)
    }

    // Clear tags in all orphans
    for (const item of notLinked) {
      item.root = undefined
      item.tag = undefined
    }

    // Convert to `PlanHierarchy` instance
    const planHierarchy = PlanHierarchy.from(sortItems(hierarchy, item => {
      if (!item.id) return 1000
      return item.descendants.length ? -1 : 1
    }))

    planHierarchy.devices = [...repeaters, ...lineamps]

    return planHierarchy
  }

  /**
   * Finds an item to which the specified item is linked with a connector outgoing from the specified port,
   * and the connector connecting to it
   * @param {PlanItem} item Item where connector goes out
   * @param {PlanPortType} portType Port where the connector goes out
   * @param {Array[PlanConnector]} connectors All connectors on the plan
   * @returns {Object}
   */
  static getConnectedItem (item, portType, connectors) {
    const connector = connectors.find(c => c.isConnectedTo(item, portType))
    if (connector) {
      const parent = connector.getOtherItem(item)
      return { connector, parent }
    }
    return {}
  }

  /**
   * Checks whether there exists an item to which the specified item is linked
   * with a connector outgoing from the specified port,
   * and the connector connecting to it
   * @param {PlanItem} item Item where connector goes out
   * @param {PlanPortType} portType Port where the connector goes out
   * @param {Array[PlanConnector]} connectors All connectors on the plan
   * @returns {Boolean}
   */
  static hasConnectedItem (item, portType, connectors) {
    const { parent } = PlanHierarchyBuilder.getConnectedItem(item, portType, connectors)
    return parent != null
  }

  /**
   * Returns a parent item of the specified equipment item and connector by which it is linked to the item.
   * There are restrictions on what a parent may be:
   * - LineAmp can be entered from repeater via external port
   * - Antenna can be entered from repeater/any port, lineamp/internal ports, splitter/outgoing ports
   * - Splitter can be entered from repeater/internal ports, lineamp/internal ports, splitter/outgoing ports
   * @param {PlanItem} item Item to find the nearest parent for
   * @param {Array[PlanConnector]} connectors All connectors on the plan
   * @returns {Object}
   */
  static getParentOf (item, connectors) {
    if (item.isDeviceType(DeviceType.LineAmp)) {
      const { parent, connector } = this.getConnectedItem(item, PlanPortType.In, connectors)
      return { parent, connector }
    }

    if (item.isAntenna) {
      const { parent, connector } = this.getConnectedItem(item, PlanPortType.In, connectors)
      return { parent, connector }
    }

    if (item.isSplitter) {
      const { parent, connector } = this.getConnectedItem(item, PlanPortType.In, connectors)
      return { parent, connector }
    }

    return {}
  }

  /**
   * Returns the basic data of an equipment item to store in the hierarchy
   * @param {PlanItem|PlanHierarchyItem} item Item
   * @param {PlanItem} parent Parent item
   * @param {PlanHierarchyItem} root Hierarchy root
   * @param {PlanHierarchyItem} ancestor Hierarchy ancestor of the item
   * @param {PlanConnector} connector Connector between the item and its parent
   * @param {Array[PlanHierarchyItem]} items All hierarchy items
   * @return {PlanHierarchyItem}
   */
  static getHierarchyItem ({ item, root, parent, ancestor, connector, items }) {
    const { id, index, type, tag, label, serialNumber, equipmentType, deviceType, antennaType, splitterType, cableType } = item

    const hierarchyItem = item instanceof PlanHierarchyItem
      ? item
      : new PlanHierarchyItem({
        id,
        item,
        index,
        type,
        tag,
        label,
        serialNumber,
        connector,
        equipmentType,
        deviceType,
        antennaType,
        splitterType,
        cableType
      })

    // Assign the root identifier and parent identifier
    if (root) {
      hierarchyItem.rootId = root.id
    }

    if (parent) {
      hierarchyItem.parent = parent
      hierarchyItem.parentId = parent.id
    }

    if (ancestor) {
      hierarchyItem.ancestor = ancestor
    }

    if (type === PlanItemType.Device || type === PlanItemType.Splitter) {
      hierarchyItem.descendants = items
        ? items
          .filter(child => child.parentId === id)
          .map(child => this.getHierarchyItem({ item: child, items, root, ancestor: hierarchyItem }))
        : []
      hierarchyItem.descendants = sortItems(hierarchyItem.descendants, 'index')
    }

    return hierarchyItem
  }

  /**
   * Determines the next available tag
   * @param {PlanLayout} layout Plan layout
   * @returns {String}
   */
  static getNextTag (layout) {
    const repeaters = layout.items.filter(i => i.isRepeater)
    const tags = repeaters
      .map(r => r.tag)
      .filter(tag => Boolean(tag?.trim()))
    tags.sort()
    const lastTag = tags[tags.length - 1]

    if (lastTag) {
      return toBase26(fromBase26(lastTag) + 1)
    } else {
      return 'A'
    }
  }
}

/**
 * Plan hierarchy
 */
export class PlanHierarchy extends Array {
  /**
   * All devices on the plan
   * @type {Array[PlanDevice]}
   */
  devices
}

/**
 * Item in the plan hierarchy
 */
export class PlanHierarchyItem {
  constructor (data = {}) {
    Object.assign(this, data)

    const { type, parent, connector, label, serialNumber, equipmentType, deviceType, antennaType, splitterType, cableType, ancestor } = data

    if (parent && connector) {
      this.parentId = parent.id
      this.connectorId = connector.id
    }

    if (deviceType) {
      this.deviceType = deviceType
      this.label = serialNumber ? `${DeviceAcronym[deviceType]} ${serialNumber}` : DeviceTypeName[deviceType]
    } else if (antennaType) {
      this.antennaType = antennaType
      this.label = AntennaName[antennaType]
    } else if (splitterType) {
      this.splitterType = splitterType
      this.label = SplitterName[splitterType]
    } else if (cableType) {
      this.cableType = cableType
      this.label = CableName[cableType]
    } else {
      this.label = 'Other'
    }

    this.equipmentType = deviceType || antennaType || splitterType || cableType || equipmentType
    this.label = (this.label || label || '').trim() || PlanItemName[type]
    this.ancestor = ancestor
  }

  /**
   * Item identifier
   * @type {String}
   */
  id

  /**
   * Item details
   * @type {PlanItem}
   */
  item

  /**
   * Indicates that the item represent a a repeater
   * @type {Boolean}
   */
  get isRepeater () {
    return this.item?.isRepeater
  }

  /**
   * Item index on the floor, increased monotonically as items are being added,
   * used to ensure that item keeps proper sequential number as items are being added and deleted
   * @type {Number}
   */
  index

  /**
   * Item label
   * @type {String}
   */
  label

  /**
   * Item type
   * @type {PlanItemType}
   */
  type

  /**
   * Item tag
   * @type {String}
   */
  tag

  /**
   * Equipment type - device type, splitter type, antenna type or cable type
   * @type {String}
   */
  equipmentType

  deviceType
  splitterType
  antennaType
  cableType

  /**
   * Identifier of the root item
   * @type {String}
   */
  rootId

  /**
   * Parent item
   * @type {PlanItem}
   */
  parent

  /**
   * Identifier of the parent item
   * @type {String}
   */
  parentId

  /**
   * Connector between the item and the parent
   * @type {PlanConnector}
   */
  connector

  /**
   * Identifier of the connector between the item and the parent
   * @type {String}
   */
  connectorId

  /**
   * Hierarchy ancestor
   * @type {PlanHierarchyItem}
   */
  ancestor

  /**
   * Hierarchy descendants
   * @type {Array[PlanHierarchyItem]}
   */
  descendants

  /**
   * Returns all descendants of the hierarchy item, including those nested ones
   * @type {Array[PlanHierarchyItem]}
   */
  get allDescendants () {
    const { descendants } = this
    if (descendants) {
      return [
        ...descendants,
        ...descendants.flatMap(d => d.allDescendants)
      ]
    } else {
      return []
    }
  }

  /**
   * Traverses the hierarchy and calls the handler on each of the items
   * If handler returns something, the traversal stops and returns this value as a result
   * @param {Function<PlanHierarchyItem, any>} handler Handler to call on traversed hierarchy items
   * @returns {any}
   */
  traverse (handler) {
    if (handler) {
      let result = handler(this)
      if (!result) {
        for (const child of this.descendants || []) {
          result = child.traverse(handler)
          if (result) break
        }
      }
      return result
    }
  }

  /**
   * Traverses the hierarchy and calls the predicate on each of the items,
   * returns the item for which the predicate returned `true`.
   * @param {Function<PlanHierarchyItem, Boolean>} predicate Predicate to check on traversed hierarchy items
   * @returns {PlanHierarchyItem}
   */
  findDescendant (predicate) {
    return this.traverse(i => predicate(i) ? i : null)
  }

  toJSON () {
    const result = { ...this }
    delete result.item
    delete result.parent
    delete result.root
    delete result.connector
    return result
  }
}
