import { PlanItemType } from '../items/plan-item'

/**
 * Edit modes available while user is editing the plan
 */
export const PlanEditMode = {
  /**
   * Selecting colors for background image transparency
   */
  SelectingTransparentColors: 'selecting-transparent-color',
  /**
   * Drawing building walls
   */
  DrawingWalls: 'drawing-walls',
  /**
   * Changing building walls
   */
  EditingWalls: 'editing-walls',
  /**
   * Adding simple single-click items such as devices, antennas etc
   */
  AddingItems: 'adding-items',
  /**
   * Setting the map scale
   */
  DrawingMapScale: 'drawing-map-scale',
  /**
   * Drawing a ruler
   */
  DrawingRuler: 'drawing-ruler',
  /**
   * Drawing a multi-point line
   */
  DrawingLine: 'drawing-line',
  /**
   * Drawing a polygon
   */
  DrawingPolygon: 'drawing-polygon',
  /**
   * Drawing a cable between items
   */
  DrawingCable: 'drawing-cable'
}

/**
 * Determines the edit mode based on the specified plan item
 * @param {PlanItem} item Plan item
 * @param {Boolean} isNew Indicates that this is a newly added item
 * @returns {PlanEditMode}
 */
export function planItemToEditMode ({ item, isNew } = {}) {
  if (item) {
    // Special cases
    if (item.type === PlanItemType.Polygon) {
      if (item.key === 'wall' || item.key === 'yard') return PlanEditMode.DrawingWalls
    }

    // Generic cases
    const editModes = {
      [PlanItemType.Line]: PlanEditMode.DrawingLine,
      [PlanItemType.Polygon]: PlanEditMode.DrawingPolygon,
      [PlanItemType.Cable]: PlanEditMode.DrawingCable,
      [PlanItemType.Ruler]: PlanEditMode.DrawingRuler,
    }

    let editMode = editModes[item.type]
    if (!editMode && isNew) {
      editMode = PlanEditMode.AddingItems
    }

    return editMode
  }
}
