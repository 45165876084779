import { capitalize, getTimestamp } from '@stellacontrol/utilities'
import { ListViewMode, Confirmation } from '@stellacontrol/client-utilities'
import { DeviceAPI } from '@stellacontrol/client-api'
import { Place } from '@stellacontrol/model'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initialize the organizations store
   */
  async initializeApplet ({ dispatch }) {
    // Initialize lists
    await dispatch('initializeList', [
      { name: 'organization-places', sortBy: 'name', viewMode: ListViewMode.Normal },
      { name: 'place-devices', sortBy: 'acronym', sortDescending: true, viewMode: ListViewMode.Normal }
    ])
  },

  /**
   * Retrieves the specified place with all details such as devices, creator, etc.
   * @param id Place identifier
   */
  async getOrganizationPlace ({ dispatch }, { organization, id } = {}) {
    if (!organization) throw new Error('Organization is required')
    if (!id) throw new Error('Place identifier is required')

    await dispatch('loading')
    const place = await DeviceAPI.getPlace({
      id,
      withDetails: true
    })
    place.organization = organization
    await dispatch('done')
    return place
  },

  /**
   * Returns a new place in organization
   * @param organization Owner organization
   * @param place Initial properties of the place
   */
  async newOrganizationPlace ({ dispatch }, { organization, place } = {}) {
    if (!organization) throw new Error('Organization is required')

    await dispatch('loading')

    // Create new place
    const data = new Place({
      ...place,
      newId: getTimestamp(),
      organization,
      organizationId: organization.id
    })
    await dispatch('done')
    return data
  },

  /**
   * Checks whether the specified place exists in organization
   * @param organization Owner organization
   * @param name Place name
   */
  async organizationPlaceExists (_, { organization, name } = {}) {
    if (!organization) throw new Error('Organization is required')
    if (!name) throw new Error('Place name is required')

    const { id, exists } = await DeviceAPI.placeExists({ organization, name })
    return { id, exists }
  },

  /**
   * Edits a new place in organization
   * @param organization Owner organization
   */
  async createOrganizationPlace ({ dispatch }, { organization }) {
    if (!organization) throw new Error('Organization is required')
    await dispatch('gotoRoute', {
      name: AppletRoute.OrganizationPlace,
      params: {
        id: 'new',
        organizationId: organization.id
      }
    })
  },

  /**
   * Edits an existing place in organization
   * @param organization Owner organization
   * @param id Place to edit
   */
  async editOrganizationPlace ({ dispatch }, { id, organization } = {}) {
    if (!organization) throw new Error('Organization is required')
    if (!id) throw new Error('Place identifier is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.OrganizationPlace,
      params: {
        id,
        organizationId: organization.id
      }
    })
  },

  /**
   * Saves an organization place
   * @param {Place} place Place to save
   * @param {Organization} organization Owner organization
   * @param {Boolean} silent If true, no notifications will be shown
   */
  async saveOrganizationPlace ({ dispatch }, { place, organization, silent } = {}) {
    if (!place) throw new Error('Place is required')
    if (!organization) throw new Error('Organization is required')
    if (!place.organizationId) throw new Error('Organization identifier is required')

    const placeType = place.placeType || 'place'
    await dispatch('busy', { message: `Saving ${placeType} ${place.name} ...`, data: place, silent })
    const { place: savedPlace, error } = await DeviceAPI.savePlace({ place })
    if (savedPlace) {
      await dispatch('done', { message: `${capitalize(placeType)}  ${savedPlace.name} has been saved`, silent })
    } else {
      await dispatch('done', { error, silent })
    }
  },

  /**
   * Removes a place from organization
   * @param confirm If true, user has to confirm
   * @param id place to remove
   * @param silent If true, no notifications will be shown
   */
  async removeOrganizationPlace ({ commit, dispatch }, { confirm = true, id, organization, silent } = {}) {
    if (!organization) throw new Error('Organization is required')
    if (!id) throw new Error('Place identifier is required')

    const place = await DeviceAPI.getPlace({ id, withDetails: true })
    if (!place) throw new Error(`Place ${id} no longer exists`)
    const placeType = place.placeType || 'place'

    const message = place.hasDevices
      ? `Are you sure you want to delete this ${placeType}?
        It has ${place.devices.length} device${place.devices.length === 1 ? '' : 's'} assigned.
        They will not be deleted, but they will be unlinked from the ${placeType}.`
      : `Delete ${placeType} ${place.name}?`
    const yes = await Confirmation.ask({ title: 'Delete', message, confirm })

    if (yes) {
      await dispatch('busy', { message: `Deleting ${placeType} ${place.name} ...`, data: place, silent })
      const result = await DeviceAPI.deletePlace({ place })
      await dispatch('done', { message: `${capitalize(placeType)}  ${place.name} has been deleted`, silent })
      commit('removeOrganizationPlace', { id, organization })
      return result
    }
  }
}
