<script>
import { mapGetters } from 'vuex'
import { sortItems } from '@stellacontrol/utilities'
import { Secure } from '../../secure-component'
import { ContextEditor } from './context-editor'

/**
 * Permission context editor for granting access to organization profiles
 * to resellers. Resellers can use only the explicitly granted profiles
 * when adding new customers.
 */
export default {
  mixins: [
    ContextEditor,
    Secure
  ],

  computed: {
    ...mapGetters([
      'globalOrganizationProfiles',
      'availableOrganizationProfiles'
    ]),

    // Profiles which can be permitted to organization
    profiles () {
      const { principal, availableOrganizationProfiles } = this

      // When reseller edits a profile, he should be proposed
      // to grant only his own profiles, or those explicitly made available to him.
      // Super-organization will have all profiles available
      let profiles = availableOrganizationProfiles
        // ... no organization can create another super-organization.
        .filter(profile => principal.isSuperOrganization || !profile.isSuperOrganization)
        // ... no organization can create organizations with the same profile as itself.
        .filter(profile => profile.id !== principal.id)

      profiles = sortItems(profiles, profile => profile.fullName)

      return profiles.map(({ id, name, fullName, level, isShared }) => ({
        id,
        name,
        fullName,
        level,
        isShared,
        isSelected: this.isProfileSelected({ id }),
      }))
    },

    isProfileSelected () {
      return profile => (this.permission.context || []).includes(profile.id)
    }
  },

  methods: {
    // Selects/unselects a profile
    toggleProfile (id, value) {
      if (!this.permission.context) {
        this.permission.context = []
      }

      const index = this.permission.context.indexOf(id)
      const isSelected = index > -1
      if (value && !isSelected) {
        this.permission.context.push(id)
      }
      if (!value && isSelected) {
        this.permission.context.splice(index, 1)
      }
      this.$emit('context', this.permission)
    }
  },

  created () {
    // Cleanup profiles which no longer exist
    const { profiles, permission: { context = [] } = {} } = this
    this.permission.context = context.filter(id => profiles.find(p => p.id === id))
  }
}
</script>

<template>
  <div v-if="permissions.canUse('child-organizations')">
    <div class="bg-indigo-7 text-white q-pa-md">
      {{ principal.fullName || 'Reseller' }}
      can use these customer profiles,
      when creating their customers:
    </div>

    <div class="q-pa-sm">
      <q-list dense class="q-mt-sm">
        <q-item v-for="profile in profiles" :key="profile.id">
          <q-item-section side>
            <q-checkbox dense v-model="profile.isSelected"
              @update:model-value="value => toggleProfile(profile.id, value)"></q-checkbox>
          </q-item-section>
          <q-item-section>
            <q-item-label>
              {{ profile.fullName }} {{ profile.isShared ? '' : '(private)' }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>

    <div class="text-grey-8 q-pa-md">
      You can also grant a permission to the {{ lowercase(principal.name || 'customer') }},
      to allow them creating additional customer profiles.
    </div>

  </div>
</template>

<style lang="scss" scoped>

</style>
