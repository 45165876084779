<script>
import { mapActions, mapGetters } from 'vuex'
import { FormMixin, DefaultListActions } from '@stellacontrol/client-utilities'
import { Secure } from '../../components/secure-component'

/**
 * Users of the edited organization
 */
export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: [
    'data'
  ],

  computed: {
    ...mapGetters([
      'usersInOrganizationColumns',
      'canLoginAsUser'
    ]),

    users () {
      return this.data.users || []
    },

    hasUsers () {
      return this.users.length > 0
    },

    // True if maximal allowed number of users in the organization has been reached
    hasReachedUsersLimit () {
      return this.users.length >= 50
    },

    // Menu item actions
    actions () {
      return [
        ...DefaultListActions,
        {
          name: 'login-as',
          icon: 'login',
          label: 'Login as user',
          isVisible: user => this.canLoginAsUser(user)
        }
      ]
    }
  },

  methods: {
    ...mapActions([
      'editUser',
      'createUser',
      'removeUser',
      'loginToOrganization'
    ]),

    // Adds user to organization
    addUserToOrganization () {
      this.$emit('addUserToOrganization')
    },

    async executeAction (user, action) {
      switch (action.name) {
        case 'edit':
          await this.editUser({ user })
          break
        case 'delete':
          await this.removeUser({ user, confirm: true })
          break
        case 'login-as':
          return this.loginAs(user)
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    },

    // Determines the title of the actions popup
    getActionsTitle (user) {
      return user.name
    },

    // Logs into the user's organization and impersonate the user
    loginAs (user) {
      if (this.canLoginAsUser(user)) {
        return this.loginToOrganization({
          user,
          organization: this.data,
          confirm: !this.isSuperAdministrator
        })
      }
    }
  }
}

</script>

<template>
  <div class="container">
    <div class="header">
      <div class="row items-center">
        <q-btn label="Add User" icon="add" unelevated :disabled="hasReachedUsersLimit"
          @click="addUserToOrganization()" />
        <q-space></q-space>
        <span class="text-deep-orange-14" v-if="hasReachedUsersLimit">
          {{ capitalize(data.name) }} has the maximal allowed number of user accounts (50)
        </span>
        <span class="q-mb-md" v-if="!hasUsers">
          You can add here users who will be allowed to log into {{ data.name }}
        </span>
      </div>
    </div>

    <div class="table q-mt-sm" v-if="hasUsers">
      <sc-list name="organization-users" row-key="id" :columns="usersInOrganizationColumns"
        :items="users" :actions="actions" :actionsTitle="getActionsTitle" @action="executeAction">

        <template v-slot:body-cell-fullName="props">
          <q-td :props="props">
            <router-link class="item-link" :to="{ name: 'user', params: { id: props.row.id } }">
              {{ props.row.fullName }}
              <sc-tooltip :text="`Edit ${props.row.fullName}`" />
            </router-link>
          </q-td>
        </template>

      </sc-list>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0;
  }

  .table {
    flex: 1;
    overflow: auto;
    border-top: solid silver 1px;
  }
}
</style>
