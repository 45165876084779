<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'

const name = 'home'

/**
 * Home view
 */
export default {
  mixins: [
    Secure
  ],

  data () {
    return {
      name
    }
  },

  computed: {
    ...mapState({
      announcements: state => state.announcements.newAnnouncements
    }),

    ...mapGetters([
      'application',
      'configuration',
      'isInitialized',
      'isReloading',
      'isRedirecting',
      'isLoggedIn',
      'isLoggingOut',
      'isSmallScreen'
    ]),

    // View title
    title () {
      return `Welcome to ${this.application.name}`
    },

    // Indicates whether view is visible
    isViewVisible () {
      return this.isLoggedIn && !(this.isRedirecting || this.isReloading)
    },

    // Indicates whether there are any announcements to display
    hasNewAnnouncements () {
      return this.announcements?.find(a => !a.isAcknowledged)
    }
  },

  methods: {
    ...mapActions([
    ])
  },

  created () {
  }
}
</script>

<template>
  <sc-view :name="name" :title="title" v-if="isViewVisible">
    <!-- On small screen show the application title inside the top bar -->
    <teleport v-if="isSmallScreen" to="#topbar-items">
      <div class="row items-center no-wrap q-pl-md text-h6 text-white">
        {{ application.name }}
      </div>
    </teleport>

    <div class="home q-pa-lg q-gutter-md row"
      v-if="!(isReloading || isRedirecting || isLoggingOut)">

      <section v-if="hasNewAnnouncements" class="q-pa-lg announcements">
        <header class="text-h5 q-mb-md">Announcements</header>
        <main>
          <section>
            <sc-announcement-list :announcements="announcements" :allow-acknowledge="true"
              :separator="true" :showDate="true">
            </sc-announcement-list>
          </section>
        </main>
      </section>

    </div>

  </sc-view>
</template>

<style lang='scss' scoped>
.home {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .announcements {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    max-width: 70%;

    >header {
      flex: 0;
    }

    >main {
      flex: 1;
      overflow: auto;

      >section {
        border: solid #0000001f 1px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
