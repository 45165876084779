import { differenceInDays, startOfDay, endOfDay, subDays, addDays, isSameDay } from 'date-fns'
import { safeParseInt, parseDate, isDate } from '@stellacontrol/utilities'

/**
 * Special identifier representing TODAY date
 */
const TODAY = 'today'

/**
 * Date range
 */
export class DateRange {
  /**
   * Creates a date range representing today
   * @returns {DateRange}
   */
  static today () {
    return new DateRange({ recent: 0 })
  }

  /**
   * Creates a date range representing recent number of days
   * @param {Number} recent Number of recent days
   * @returns {DateRange}
   */
  static recent (recent) {
    return new DateRange({ recent })
  }

  /**
   * Creates a date range
   * @param {Date} from Range start
   * @param {Date} to Range end
   * @returns {DateRange}
   */
  static from (from, to) {
    return new DateRange({ from, to })
  }

  /**
   * Deserializes date range from string
   * @param {String} value Serialized date range
   * @param {DateRange} defaultValue Default value to return if input value could not be deserialized to {@link DateRange}
   * @returns {DateRange}
   */
  static fromString (value, defaultValue) {
    if (value) {
      if (value === TODAY) {
        return DateRange.today()

      } else {
        const recent = safeParseInt(value)
        if (recent != null) {
          return DateRange.recent(recent)

        } else {
          const parts = value.split(' - ')
          const from = parseDate(parts[0].trim())
          const to = parseDate(parts[1].trim())
          if (isDate(from) && isDate(to)) {
            return DateRange.from(from, to)
          }
        }
      }
    }

    return defaultValue
  }

  /**
   * Initializes the {@link DateRange} instance with specified values
   */
  constructor ({ from, to, recent, min, max } = {}) {
    if (from && typeof from === 'string') {
      from = parseDate(from)
    }
    if (to && typeof to === 'string') {
      to = parseDate(to)
    }

    this.recent = recent
    this.min = min ? (min === TODAY ? min : startOfDay(parseDate(min))) : undefined
    this.max = max ? (max === TODAY ? max : endOfDay(parseDate(max))) : undefined

    if (this.min > this.max) {
      this.min = startOfDay(this.max)
    }

    if (!(this.recent >= 0)) {
      this.from = from
      this.to = to
    }
  }

  __from
  __to

  /**
   * Date range start
   * @type {Date}
   */
  get from () {
    if (this.recent >= 0) {
      return startOfDay(subDays(this.to, this.recent))
    } else {
      return this.__from
    }
  }

  /**
   * Assigns date range start
   * @param {Date} value Value to assign.
   * Ignored if {@link recent} is specified.
   * Capped if {@link minDate} is specified.
   */
  set from (value) {
    const { minDate, maxDate, recent } = this
    if (value == null) {
      this.__from = undefined

    } else if (!(recent >= 0)) {
      if (minDate && value < minDate) {
        value = minDate
      }
      if (maxDate && value > maxDate) {
        value = maxDate
      }
      this.__from = startOfDay(value)
    }
  }

  /**
   * Date range end
   * @type {Date}
   */
  get to () {
    if (this.recent >= 0) {
      return endOfDay(new Date())
    } else {
      return this.__to
    }
  }

  /**
   * Assigns date range end
   * @param {Date} value Value to assign.
   * Ignored if {@link recent} is specified.
   * Capped if {@link maxDate} is specified.
   */
  set to (value) {
    const { minDate, maxDate, recent } = this
    if (value == null) {
      this.__to = undefined
    } else if (!(recent >= 0)) {
      if (minDate && value < minDate) {
        value = minDate
      }
      if (maxDate && value > maxDate) {
        value = maxDate
      }
      this.__to = endOfDay(value)
    }
  }

  /**
   * Indicates that date range should be always filled with recent period
   * such as today, last 3 days, last week, last months
   * @type {Number}
   */
  recent

  /**
   * Returns true if TODAY range is selected
   * @type {Boolean}
   */
  get isToday () {
    return this.recent === 0
  }

  /**
   * Assigns the specified date range
   * @param {Date} from Start date
   * @param {Date} to End date
   * @returns {DateRange} This instance
   */
  setRange (from, to) {
    delete this.recent
    this.from = from
    this.to = to
  }

  /**
   * Minimal date which the range can start from.
   * Can be specified as date or special identifier 'today'
   * which dynamically resolves to current date
   * @type {Date|String}
   */
  min

  /**
   * Current value of {@link min} property
   * @type {Date}
   */
  get minDate () {
    return this.min === TODAY ? startOfDay(new Date()) : this.min
  }

  /**
   * Maximal date which the range can end at.
   * Can be specified as date or special identifier 'today'
   * which dynamically resolves to current date
   * @type {Date|String}
   */
  max

  /**
   * Current value of {@link max} property
   * @type {Date}
   */
  get maxDate () {
    return this.max === TODAY ? endOfDay(new Date()) : this.max
  }

  /**
   * Returns true if date range is valid
   * @type {Boolean}
   */
  get isValid () {
    return this.from &&
      this.to &&
      !isNaN(this.from.getDate()) &&
      !isNaN(this.to.getDate()) &&
      this.to >= this.from
  }

  /**
   * Returns true if date range is empty
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.from && this.to)
  }

  /**
   * Returns true if date range is the same as the specified one
   * @param {DateRange} range Date range to compare to
   * @returns {Boolean}
   */
  sameAs (range) {
    return this.from?.toISOString() === range?.from?.toISOString() &&
      this.to?.toISOString() === range?.to?.toISOString()
  }

  /**
   * Date range length in days
   * @type {Number}
   */
  get length () {
    if (this.isValid) {
      return differenceInDays(this.to, this.from) + 1
    }
  }

  /**
   * Indicates that date range is within the same day
   * @type {Boolean}
   */
  get oneDay () {
    if (this.isValid) {
      return isSameDay(this.to, this.from)
    }
  }

  /**
   * Clears the date range
   */
  clear () {
    this.from = undefined
    this.to = undefined
  }

  /**
   * Returns new date range which is before the current by specified number of days
   * @param {Number} days Number of days to move backwards
   * @returns {DateRange}
   */
  back (days) {
    if (days >= 0) {
      const { from, to, min, max } = this
      return new DateRange({
        from: subDays(from, days),
        to: subDays(to, days),
        min,
        max
      })
    }
  }

  /**
   * Returns new date range which is after the current by specified number of days
   * @param {Number} days Number of days to move backwards
   * @returns {DateRange}
   */
  forward (days) {
    if (days >= 0) {
      const { from, to, min, max } = this
      return new DateRange({
        from: addDays(from, days),
        to: addDays(to, days),
        min,
        max
      })
    }
  }

  /**
   * Serializes date range to string
   * @returns {String}
   */
  toString () {
    if (this.isValid) {
      if (this.isToday) {
        return TODAY
      } else if (this.recent > 0) {
        return this.recent.toString()
      } else {
        return `${this.from.toISOString()} - ${this.to.toISOString()}`
      }
    } else {
      return ''
    }
  }

  /**
   * Prepares data for serialization to JSON
   * @returns {Object}
   */
  toJSON () {
    const { from, to, recent } = this
    const result = recent == null
      ? { ...this, from, to }
      : { ...this, recent }
    delete result.__from
    delete result.__to
    return result
  }
}
