<script>
import Konva from 'konva'
import { mapActions } from 'vuex'
import { Log, Point, Rectangle, hash as getHash, sortItems } from '@stellacontrol/utilities'
import { Confirmation, Notification } from '@stellacontrol/client-utilities'
import { PlanLineStyle, PlanLineType } from '@stellacontrol/planner'
import { PlanEvent } from '../../renderer/events/plan-event'
import { ImagePart } from './image-part'
import StepMixin from './step-mixin'

export default {
  mixins: [
    StepMixin
  ],

  props: {
    // Images to edit
    images: {
    }
  },

  data () {
    return {
      // Indicates that the view has been initialized
      isInitialized: false,
      // Edited images
      edited: [],
      // Indicates that we're now loading an image to be edited
      isLoadingImage: false,
      // Edited image
      image: null,
      // Rendering stage
      stage: null,
      // Indicates that mouse cursor is inside the edited image
      imageHover: false,
      // Properties for setting the map scale
      mapScale: {
        // Image for which the scale is being set
        image: null,
        // Start point of the map scale line
        start: null,
        // End point of the map scale line
        end: null,
        // Style for the map scale line
        style: {
          color: 'red',
          width: 3,
          dash: PlanLineStyle.getLineDash(PlanLineType.Dashed, 3)
        }
      },
      // Properties for cropping the image
      crop: {
        // Image to crop
        image: null,
        // Start of the currently drawn crop area
        start: null
      },
      // Image being dragged
      draggedImage: null
    }
  },

  computed: {
    // Determines whether the image can be deleted
    canDelete () {
      return image => image && this.edited.length > 1
    },

    // Image tooltip
    getImageTooltip () {
      return image => {
        if (image) {
          return `${image.description}`
        }
      }
    },

    // CSS style for the image thumbnail
    getImageThumbnail () {
      return (image) => {
        if (image) {
          return {
            'background-size': 'cover',
            'background-image': `url(${image.reference})`
          }
        }
      }
    },

    // Image canvas style
    getCanvasStyle () {
      if (this.isLoadingImage) {
        return {
          visibility: 'hidden'
        }
      }
    },

    // Checks whether the specified image is selected for editing
    isImageSelected () {
      return image => image?.hash === this.image?.hash
    },

    // Determines whether we're drawing the map scale
    isDrawingMapScale () {
      return this.mapScale.image != null
    },

    // Returns style for the map scale line
    mapScaleStyle () {
      return image => image.mapScale > 0
        ? { width: `${image.mapScale}px` }
        : {}
    },

    // Determines whether we're cropping the image
    isCropping () {
      return this.crop.image != null
    },

    // Determines whether the currently loaded image has any crop areas defined
    isImageCropped () {
      return (this.image?.cropAreas || []).length > 0
    }
  },

  methods: {
    ...mapActions([
      'removePlanImage'
    ]),

    // Initializes the component
    initialize () {
      this.isInitialized = true

      const { edited } = this
      for (let index = 0; index < edited.length; index++) {
        const image = edited[index]
        const { hash, floorName, description, reference } = image
        image.parts = [
          new ImagePart({ index, hash, floorName, description, reference })
        ]
      }

      const canvasContainer = this.getCanvasContainer()
      canvasContainer.addEventListener('wheel', this.mouseWheelHandler, { passive: false })

      this.selectImage(edited[0])
    },

    // Notifies about the edited images
    async changed (updateData) {
      const { image } = this
      const parts = image && updateData
        ? await this.getImageParts()
        : undefined
      this.$emit('changed', { image, parts })
    },

    // Notifies about the removed image
    async removed (image) {
      this.$emit('removed', { image })
    },

    // Removes the specified image from the image list
    async removeImage (image) {
      this.stopEditing()

      if (image) {
        // Dispose of the data URL
        URL.revokeObjectURL(image.reference)

        // Remove from the list
        const { plan } = this
        const index = this.edited.findIndex(i => i.hash === image.hash)
        this.edited = this.edited.filter(i => i.hash !== image.hash)

        // If removed the currently edited image, show predecessor
        if (image === this.image) {
          this.image = this.edited[index - 1]
        }

        // Remove from the external storage
        this.removePlanImage({ image, plan })

        this.removed(image)
      }
    },

    // Moves the specified image before the given one
    moveImageTo (image, to) {
      this.stopEditing()

      const source = this.edited.findIndex(i => i.hash === image.hash)
      const target = this.edited.findIndex(i => i.hash === to.hash)
      const index = target > source ? target + 1 : target
      this.edited = [
        ...this.edited.slice(0, index).filter(i => i.hash !== image.hash),
        image,
        ...this.edited.slice(index).filter(i => i.hash !== image.hash)
      ]

      this.edited.forEach((image, index) => image.sortOrder = index)

      this.changed()
    },

    // Selects the specified image for editing
    async selectImage (image) {
      if (this.isCropping) {
        await this.finishCropping()
      }

      this.stopEditing()

      if (this.image != image) {
        this.isLoadingImage = true
        await this.loadImage(image)
      }
    },

    // Loads the selected image into canvas
    loadImage (image) {
      this.stopEditing()

      return new Promise((resolve) => {

        this.isLoadingImage = true

        // Get rid of the previous image
        if (this.stage) {
          this.stage.destroy()
        }

        // Render the image inside the canvas
        Konva.pixelRatio = window.devicePixelRatio
        const imageUrl = image.reference
        const imageContainer = this.getImageContainer()
        const canvasContainer = this.getCanvasContainer()
        const stage = new Konva.Stage({
          container: canvasContainer,
          width: 1000,
          height: 1000,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high'
        })
        const layer = new Konva.Layer({
          id: 'layer',
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high'
        })

        const { mapScale } = this
        const scale = new Konva.Arrow({
          id: 'scale',
          visible: false,
          dash: mapScale.style.dash,
          stroke: mapScale.style.color,
          strokeWidth: mapScale.style.width,
          pointerAtBeginning: true,
          pointerAtEnding: true,
          pointerLength: 0,
          pointerWidth: 10
        })
        layer.add(scale)

        stage.add(layer)
        this.stage = stage

        // Wire up stage events, for image panning etc.
        stage.on('mousemove', (e) => {
          if (PlanEvent.isRightButton(e)) {
            imageContainer.scrollBy(-e.evt.movementX * 2, -e.evt.movementY * 2)
          }
        })

        // Wire up layer events, for drawing map scales, setting transparency etc.
        layer.on('click', e => this.clickImageHandler(e.evt, image))
        layer.on('mousemove', e => this.moveOverImageHandler(e.evt, image))

        const imageFailed = error => {
          Log.error(`The image ${imageUrl} could not be loaded`)
          Log.exception(error)
          this.isLoadingImage = false
          resolve()
        }

        const imageLoaded = async i => {
          // Takes care of obscure race condition, when user chose to cancel or proceed
          // while image was still loading
          if (!this.isInitialized) return

          const { stage } = this
          const { width, height } = i.size()

          image.width = width
          image.height = height
          image.scale = 100

          stage.size({ width, height })
          i.id('image')

          i.listening(true)
          // Make the image draggable, but prevent from going left,
          // and too far right - so there's always something to grab onto!
          // i.draggable(true)
          i.on('dragmove', () => {
            const scale = image.scale / 100
            const actualWidth = image.width * scale
            const actualHeight = image.height * scale
            const margin = 50
            if (i.x() > 0) {
              i.x(0)
            }
            if (i.y() > 0) {
              i.y(0)
            }
            if (i.x() < -actualWidth + margin) {
              i.x(-actualWidth + margin)
            }
            if (i.y() < -actualHeight + margin) {
              i.y(-actualHeight + margin)
            }
            image.x = i.x() / scale
            image.y = i.y() / scale
          })

          layer.add(i)

          this.applyRotation(image)
          this.fitImageToContainer(image)
          this.applyFilters(image)
          this.renderCropAreas(image)

          this.image = image
          image.parts = await this.getImageParts()
          this.isLoadingImage = false

          resolve()
        }

        Konva.Image.fromURL(imageUrl, imageLoaded, imageFailed)
      })
    },

    // Returns the layer element on the canvas
    getLayer () {
      const layer = this.stage?.findOne('#layer')
      return layer
    },

    // Returns HTML container for the image
    getImageContainer () {
      return this.$refs.imageContainer
    },

    // Returns HTML container for the image canvas
    getCanvasContainer () {
      return this.$refs.canvasContainer
    },

    // Returns the image element on the canvas
    getImageElement () {
      const layer = this.getLayer()
      const element = layer?.findOne('#image')
      return element
    },

    // Returns `true` if image element exists on the canvas
    hasImageElement () {
      return this.getImageElement() != null
    },

    // Returns the map scale element on the canvas
    getMapScaleElement () {
      const layer = this.getLayer()
      const element = layer?.findOne('#scale')
      return element
    },

    // Changes the name of the floor
    changeFloorName (image, floorName) {
      image.floorName = floorName
      this.changed()
    },


    // Sets the image scale, adapts the canvas container to contain it in entirety
    setImageScale (image, scale) {
      const imageElement = this.getImageElement()
      const canvasContainer = this.getCanvasContainer()
      const { stage } = this
      const layer = this.getLayer()

      if (canvasContainer && imageElement && layer && image) {
        // Rescale the image
        image.scale = Math.round(scale * 100)
        layer.scale({ x: scale, y: scale })
        imageElement.position({ x: 0, y: 0 })

        // Adapt stage size to the image, mind the rotation
        const isOnTheSide = image.rotation === 90 || image.rotation === 270
        const width = Math.round((isOnTheSide ? image.height : image.width) * scale)
        const height = Math.round((isOnTheSide ? image.width : image.height) * scale)

        stage.size({ width, height })
      }
    },

    // Fits the image to the container width
    fitImageToContainer (image) {
      const imageContainer = this.getImageContainer()
      const imageElement = this.getImageElement()

      if (imageContainer && imageElement) {
        const containerWidth = imageContainer.offsetWidth
        const scale = Math.round(100 * (containerWidth / image.width)) / 100
        this.setImageScale(image, scale)
      }
    },

    // Zooms in/out
    zoomImage (image, delta) {
      const imageElement = this.getImageElement()
      if (!imageElement) return

      let scale
      if (delta == null) {
        scale = 100

      } else {
        scale = Math.min(500, Math.max(10, image.scale + delta))
        // Round up to the nearest multiply of 10
        scale = 10 * (
          delta > 0
            ? Math.ceil(scale / 10)
            : Math.floor(scale / 10)
        )
      }

      this.setImageScale(image, scale / 100)
    },

    // Rotates the image
    rotateImage (image) {
      this.stopEditing()

      const imageElement = this.getImageElement()
      if (!imageElement) return

      if (image) {
        image.rotation = image.rotation + 90
        if (image.rotation >= 360) {
          image.rotation = 0
        }
        this.applyRotation(image)
      }
    },

    // Applies rotation of the image
    applyRotation (image) {
      const imageElement = this.getImageElement()
      if (!imageElement) return

      if (image) {

        if (image.rotation === 90) {
          imageElement.offsetY(imageElement.height())
          imageElement.offsetX(0)
          imageElement.rotation(90)

        } else if (image.rotation === 180) {
          imageElement.offsetX(imageElement.width())
          imageElement.offsetY(imageElement.height())
          imageElement.rotation(180)

        } else if (image.rotation === 270) {
          imageElement.offsetX(imageElement.width())
          imageElement.offsetY(0)
          imageElement.rotation(270)

        } else {
          imageElement.offsetX(0)
          imageElement.offsetY(0)
          imageElement.rotation(0)
        }

        this.setImageScale(image, image.scale / 100)
      }
    },

    // Checks if the specified filter is applied
    isFilterApplied (filter) {
      const imageElement = this.getImageElement()
      if (!imageElement) return
      let filters = Array.from(imageElement.filters() || [])
      return filters.includes(filter)
    },

    // Toggles a filter on the image
    filterImage (filter, on, value) {
      this.stopEditing()

      const imageElement = this.getImageElement()
      if (!imageElement) return

      let filters = Array.from(imageElement.filters() || [])
      const { length } = filters

      if (on && !filters.includes(filter)) {
        filters.push(filter)
      } else if (!on) {
        filters = filters.filter(f => f !== filter)
      }

      // Order the filters properly
      const order = {
        [Konva.Filters.Grayscale]: 1,
        [Konva.Filters.Brighten]: 2,
        [Konva.Filters.Contrast]: 3
      }
      filters = sortItems(filters, filter => order[filter])

      if (length !== filters.length) {
        imageElement.cache()
        imageElement.filters(filters)
      }

      // Assign filter value
      if (on && value != null) {
        const fields = {
          [Konva.Filters.Brighten]: 'brightness',
          [Konva.Filters.Contrast]: 'contrast'
        }
        const field = fields[filter]
        if (field) {
          imageElement[field](value)
        }
      }
    },

    // Makes the image black and white
    desaturateImage (image, value) {
      this.stopEditing()

      if (value != null) {
        image.bw = value
      }
      this.filterImage(Konva.Filters.Grayscale, image.bw)
    },

    // Sets the contrast of the image,
    // compensates with brightness to increase blacks saturation
    setImageContrast (image, value) {
      this.stopEditing()

      if (value != null) {
        image.contrast = value
        image.brightness = -value
      }

      if (image.contrast === 0) {
        this.filterImage(Konva.Filters.Contrast, false)
      } else {
        this.filterImage(Konva.Filters.Contrast, true, image.contrast)
      }

      if (image.brightness === 0) {
        this.filterImage(Konva.Filters.Brighten, false)
      } else {
        this.filterImage(Konva.Filters.Brighten, true, image.brightness / 100)
      }
    },

    // Applies all currently set filters to the image
    applyFilters (image) {
      this.desaturateImage(image)
      this.setImageContrast(image)
    },

    // Stops all ongoing editing
    stopEditing () {
      this.stopMapScale()
      this.stopCropping()
    },

    // Starts setting the map scale
    async startMapScale (image) {
      this.stopEditing()

      if (image) {
        this.mapScale.image = image
        this.mapScale.start = null
        this.mapScale.end = null
      }
    },

    // Finishes setting the map scale
    async finishMapScale () {
      if (this.isDrawingMapScale) {
        // Finish setting map scale
        const { image, start, end } = this.mapScale
        if (start && end) {
          // Ask for length of the selected line
          const length = await Confirmation.prompt({
            title: 'Length in meters',
            message: 'What is the length of the line in meters?',
            number: true,
            min: 1,
            max: 1000,
            step: 0.1
          })

          if (length > 0) {
            // When calculating distance between scale points,
            // take into consideration the current image scale
            const distance = start.distance(end) / (image.scale / 100)
            const mapScale = Math.round(distance / length)

            // Ask whether to apply the same scale to all other floors
            const applyToAll = await Confirmation.ask({
              title: 'Apply to all floors?',
              message: 'Apply the same map scale to all other floors?'
            })
            if (applyToAll) {
              for (const image of this.edited) {
                image.mapScale = mapScale
              }
            } else {
              image.mapScale = mapScale
            }

            Notification.success({
              title: image.description,
              message: `Map scale has been set to 1m = ${image.mapScale}px`
            })
          }
        }

        this.stopEditing()
        this.changed()
      }
    },

    // Cancels setting the map scale
    async stopMapScale () {
      this.mapScale.image = null
      this.mapScale.start = null
      this.mapScale.end = null

      const scale = this.getMapScaleElement()
      if (scale) {
        scale.points([])
        scale.visible(false)
      }
    },

    // Sets the point of the currently drawn map scale line
    async setMapScalePoint ({ image, x, y, finish }) {
      if (image) {
        const { mapScale } = this

        if (mapScale.start) {
          const scale = this.getMapScaleElement()
          mapScale.end = Point.from({ x, y })
          scale.points([
            ...mapScale.start.toArray(),
            ...mapScale.end.toArray()
          ])
          if (!scale.visible()) {
            scale.visible(true)
            scale.moveToTop()
          }
          if (finish) {
            this.finishMapScale()
          }
        } else {
          this.mapScale.start = Point.from({ x, y })
        }
      }
    },

    // Starts cropping
    async startCropping (image, clear) {
      this.stopEditing()

      if (image) {
        // Remove previous cropping areas
        if (clear) {
          image.cropAreas = []
          this.renderCropAreas(image)
        }

        // Start cropping the image
        this.crop.image = image
      }
    },

    // Adds new cropped area to the image
    async cropArea (image, rectangle) {
      const { isCropping, crop } = this
      if (image && isCropping && rectangle) {
        image.cropAreas = crop.image.cropAreas || []
        image.cropAreas.push(rectangle)
        this.renderCropAreas(image)

        // Remove the crop marker
        const layer = this.getLayer()
        const shape = layer.findOne('#crop')
        shape?.destroy()
        crop.start = null

        this.changed(true)
      }
    },

    // Renders the selected cropped areas
    async renderCropAreas (image) {
      if (!image) return

      const layer = this.getLayer()

      // Clean previously rendered shapes
      const previous = layer.find('.crop')
      for (const shape of previous) {
        shape.destroy()
      }

      // Render current image's crop areas
      const areas = image.cropAreas || []
      for (const area of areas) {
        const { x, y, width, height } = area
        const shape = new Konva.Rect({
          x,
          y,
          width,
          height,
          fill: 'orangered',
          opacity: 0.25,
          name: 'crop'
        })

        layer.add(shape)
        shape.moveToTop()
      }
    },

    // Renders the currently drawn cropped area
    async updateCropArea ({ image, x, y }) {
      const { crop, isCropping } = this

      if (isCropping && image && crop.start) {
        const layer = this.getLayer()
        const end = Point.from({
          x: x == null ? crop.start.x : x,
          y: y == null ? crop.start.y : y
        })
        const rectangle = Rectangle
          .fromPoints([crop.start, end])
          .normalize()
        let shape = layer.findOne('#crop')
        if (!shape) {
          shape = new Konva.Rect({
            id: 'crop',
            fill: 'orangered',
            opacity: 0.15
          })
          layer.add(shape)
        }

        shape.position(rectangle)
        shape.size(rectangle)
        shape.moveToTop()
      }
    },

    // Undoes the last added crop area
    async undoLastCrop () {
      const { crop } = this

      const length = crop.image?.cropAreas?.length || 0
      if (length > 0) {
        crop.image.cropAreas = crop.image.cropAreas.slice(0, length - 1)
        this.renderCropAreas(crop.image)
      }
    },

    // Finishes cropping
    async finishCropping () {
      const { crop } = this
      this.stopCropping(false)
      this.renderCropAreas(crop.image)
    },

    // Cancels cropping
    async stopCropping (cancel = true) {
      const { crop } = this

      if (crop.image && cancel) {
        crop.image.cropAreas = null
        this.renderCropAreas(crop.image)
      }

      crop.image = null
      crop.start = null

      // Remove the crop marker
      const layer = this.getLayer()
      const shape = layer?.findOne('#crop')
      shape?.destroy()
    },

    // Triggered when mouse wheel is turned,
    // allows scaling the image if event happened while mouse pointer was inside the image
    mouseWheelHandler (e) {
      const { image, imageHover } = this
      if (image && imageHover) {
        PlanEvent.cancelEvent(e)
        this.zoomImage(image, e.deltaY < 0 ? 10 : -10)
        return
      }
    },

    // Triggered when image is clicked
    clickImageHandler (e, image) {
      if (!image) return

      const { stage, isDrawingMapScale, isCropping, mapScale, crop } = this

      // Get event point, in 1:1 scale coordinates
      const layer = this.getLayer()
      const point = Point
        .from(stage.getPointerPosition())
        .scaleReverse(layer.scale())

      // When in map scale mode, collect scale points
      if (isDrawingMapScale && PlanEvent.isLeftButton(e)) {
        PlanEvent.cancelEvent(e)
        const finish = mapScale.start != null
        this.setMapScalePoint({
          image,
          x: point.x,
          y: point.y,
          finish
        })
        return
      }

      // When in crop mode, draw crop rectangles
      if (isCropping && PlanEvent.isLeftButton(e)) {
        PlanEvent.cancelEvent(e)
        if (crop.start) {
          // Mark the end of the crop area
          const rectangle = Rectangle
            .fromPoints([crop.start, point])
            .normalize()
          this.cropArea(image, rectangle)
        } else {
          // Mark the beginning of the crop area
          this.crop.start = point
        }
        return
      }
    },

    // Triggered when mouse is moved inside the image.
    // Combined with right-mouse click, it allows panning the image.
    moveOverImageHandler (e, image) {
      const { stage, isDrawingMapScale, isCropping, mapScale, crop } = this

      // Get event point, in 1:1 scale coordinates
      const layer = this.getLayer()
      const point = Point
        .from(stage.getPointerPosition())
        .scaleReverse(layer.scale())

      // If drawing map scale, update the end point
      const { x, y } = point
      if (isDrawingMapScale && mapScale.start) {
        PlanEvent.cancelEvent(e)
        this.setMapScalePoint({ image, x, y })
        return
      }

      // If cropping and start point selected, draw the temporary rectangle
      if (isCropping && crop.start) {
        PlanEvent.cancelEvent(e)
        this.updateCropArea({
          image,
          x: point.x,
          y: point.y
        })
        return
      }
    },

    // Image dragging started
    imageDrag (event, image) {
      this.draggedImage = image
      if (image) {
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.setData('text/plain', image.hash)
      }
    },

    // Image is being dragged over another image
    imageDragOver (e, image) {
      const { draggedImage } = this
      if (image && draggedImage) {
        if (image && image.hash !== draggedImage.hash) {
          PlanEvent.cancelEvent(e)
        }
      }
    },

    // Image is dropped over another image, reorder
    imageDrop (e, image) {
      const { draggedImage } = this
      if (image && draggedImage) {
        if (image && image.hash !== draggedImage.hash) {
          PlanEvent.cancelEvent(e)
          this.moveImageTo(draggedImage, image)
        }
      }
    },

    /**
     * Returns the data for the currently edited image, after applying filters.
     * The function will return an array of images in two formats - binary blob and Base64 data URL.
     * Usually the array will contain just one element, unless there's more than one crop area
     * on the image, in which case the original image is to be sliced into a number of separate images.
     * @param {Object} options Export options, as per https://konvajs.org/api/Konva.Image.html#toDataURL__anchor
     * @returns {Promise<Array[{ data: Uint8Array, dataURL: String }]>}
     */
    async getImageParts ({
      mimeType = 'image/png',
      quality = 1,
      pixelRatio = 1,
      imageSmoothingEnabled = false
    } = {}) {
      const parts = []
      const { image } = this
      const layer = this.getLayer()
      const imageElement = this.getImageElement()
      if (!(image && imageElement)) return
      if (!imageElement.visible()) return

      try {
        this.isLoadingImage = true

        // Set scale to 1:1 to preserve quality
        const scale = layer.scale()
        layer.scale({ x: 1, y: 1 })

        // If crop areas defined, export these,
        // otherwise take the entire image.
        // When determining dimensions of the captured image, mind the rotation!
        const isCropped = image.cropAreas?.length > 0
        const isOnTheSide = image.rotation === 90 || image.rotation === 270
        const { x, y } = imageElement.position()
        const size = imageElement.size()
        const width = isOnTheSide ? size.height : size.width
        const height = isOnTheSide ? size.width : size.height

        const areas = isCropped
          ? image.cropAreas
          : [Rectangle.from({ x, y, width, height })]
        const hasMultipleAreas = areas.length > 1

        let index = 0
        for (const area of areas) {
          const blob = await imageElement.toBlob({
            x: area.x,
            y: area.y,
            width: area.width,
            height: area.height,
            mimeType,
            quality,
            pixelRatio,
            imageSmoothingEnabled
          })
          const data = new Uint8Array(await blob.arrayBuffer())

          const dataURL = await imageElement.toDataURL({
            x: area.x,
            y: area.y,
            width: area.width,
            height: area.height,
            mimeType,
            quality,
            pixelRatio,
            imageSmoothingEnabled
          })

          const description = image.description
          const floorName = hasMultipleAreas
            ? `${image.floorName}-${index + 1}`
            : image.floorName
          const hash = hasMultipleAreas
            ? getHash(`${this.index}-${this.name}-${this.floorName}`)
            : image.hash
          const part = new ImagePart({ index, hash, floorName, description, isCropped, data, dataURL })

          parts.push(part)
          index++
        }

        // Restore the scale
        layer.scale(scale)

        return parts

      } finally {
        this.isLoadingImage = false
      }
    },

    // Triggered when going forward
    async goForward () {
      // Accept any ongoing cropping
      if (this.isCropping) {
        await this.finishCropping()
      }

      return true
    },

    // Keyboard event handler
    async onKeyDown (e) {
      const isCtrl = PlanEvent.isCtrlKey(e)
      // Undo/Redo
      if (isCtrl) {
        switch (e.key) {
          case 'z':
            if (this.isCropping) {
              this.undoLastCrop()
            }
            PlanEvent.cancelEvent(e)
            break
        }
      }
    }
  },

  emits: [
    'changed',
    'removed'
  ],

  watch: {
    images (images = []) {
      this.edited = [...images]
      this.$nextTick(() => {
        this.selectImage(this.edited[0])
      })
    }
  },

  mounted () {
    this.edited = [...this.images]
    this.$nextTick(() => {
      this.initialize()
      window.addEventListener('keydown', this.onKeyDown)
    })
  },

  beforeUnmount () {
    window.removeEventListener('keydown', this.onKeyDown)
    this.isInitialized = false
  }

}
</script>

<template>
  <div class="editing">
    <aside class="images">
      <div class="image" v-for="image in edited" :key="image.hash"
        :class="{ selected: isImageSelected(image) }" :title="getImageTooltip(image)"
        @click.stop="selectImage(image)" draggable="true"
        @dragstart="event => imageDrag(event, image)"
        @dragenter="event => imageDragOver(event, image)"
        @dragover="event => imageDragOver(event, image)" @drop="event => imageDrop(event, image)">
        <div class="title">
          <span class="image-name">
            {{ image.floorName || image.description }}
          </span>
          <span class="delete-button" v-if="canDelete(image)">
            <q-btn flat round dense icon="close" color="red-7" size="sm"
              @click.stop="removeImage(image)"></q-btn>
          </span>
        </div>
        <div class="thumbnail" :style="getImageThumbnail(image)">
        </div>
      </div>
    </aside>

    <div class="preview">
      <div class="image-details bg-indigo-6 text-white" v-if="image">
        <template v-if="isDrawingMapScale">
          <!-- Controls for setting map scale -->
          <q-icon name="straighten" color="white" class="q-ml-sm q-mr-sm" size="24px"></q-icon>
          <span>
            Click on two points to select a distance.
          </span>
          <q-btn flat dense label="Cancel" text-color="orange" class="q-ml-md" size="14px"
            @click.stop="stopMapScale()"></q-btn>
        </template>

        <template v-if="isCropping">
          <!-- Controls for cropping -->
          <q-icon name="crop_free" color="white" class="q-ml-sm q-mr-sm" size="24px"></q-icon>
          <span>
            Click to mark areas to be cropped into floors.
          </span>
          <q-btn unelevated dense label="Cancel" text-color="orange-2" class="q-ml-md bg-indigo-4"
            size="14px" @click.stop="stopCropping()"></q-btn>
          <q-btn unelevated dense label="Finish" text-color="white" class="q-ml-sm bg-indigo-4"
            size="14px" @click.stop="finishCropping()"></q-btn>
          <span class="q-ml-md" v-if="isImageCropped">
            Press CTRL+Z to undo the last added area
          </span>
        </template>

        <template v-else>
          <!-- Controls for zooming in and out -->
          <q-icon name="info" color="white" class="q-ml-sm q-mr-sm" size="24px"></q-icon>
          <span>
            {{ image.description }}
          </span>
          <q-space></q-space>
          <q-btn round flat dense icon="zoom_out" text-color="white" class="q-ml-md" size="16px"
            @click="zoomImage(image, -10)">
            <sc-tooltip>Zoom out</sc-tooltip>
          </q-btn>
          <span>
            <q-btn flat dense :label="`${image.scale}%`" text-color="white" size="14px"
              style="width: 50px;" @click="fitImageToContainer(image)">
              <sc-tooltip>Fit to container</sc-tooltip>
            </q-btn>
          </span>
          <q-btn round flat dense icon="zoom_in" text-color="white" size="16px"
            @click="zoomImage(image, 10)" class="q-mr-sm">
            <sc-tooltip>Zoom in</sc-tooltip>
          </q-btn>
        </template>
      </div>

      <div ref="imageContainer" class="image-container" @mouseenter="imageHover = true"
        @mouseleave="imageHover = false">

        <!-- image preview -->
        <div ref="canvasContainer" class="image-canvas" @contextmenu="e => e.preventDefault()"
          :style="getCanvasStyle">
        </div>

        <!-- image scale -->
        <div v-if="!isLoadingImage && image?.mapScale" class="map-scale"
          :style="mapScaleStyle(image)">
          1m
        </div>

        <!-- process indicators -->
        <div v-if="isLoadingImage" class="image-loading text-grey-7 text-h6">
          <sc-busy title="Loading ..." size="sm"></sc-busy>
        </div>

      </div>
    </div>

    <div class="properties">
      <template v-if="image">
        <q-input label="Floor name" dense outlined square :model-value="image.floorName"
          @update:model-value="name => changeFloorName(image, name)" debounce="500"
          @keydown.enter.prevent>
        </q-input>

        <q-btn label="Black/White" icon="invert_colors" align="left" no-caps unelevated
          class="q-mt-md" :class="image.bw ? 'primary' : undefined"
          @click="desaturateImage(image, !image.bw); changed(true);"></q-btn>

        <q-btn label="Set Scale" icon="straighten" no-caps unelevated class="q-mt-sm" align="left"
          @click="isDrawingMapScale ? stopMapScale() : startMapScale(image)"
          :color="isDrawingMapScale ? 'indigo-8' : undefined"></q-btn>

        <div class="buttons-crop q-mt-sm"
          :class="{ 'image-cropped': isImageCropped && !isCropping }">
          <q-btn label="Crop" icon="crop_free" no-caps unelevated class="button-crop" align="left"
            @click="isCropping ? finishCropping() : startCropping(image)"
            :color="isCropping ? 'indigo-8' : undefined">
          </q-btn>
          <q-btn class="button-crop-clear q-ml-xs button" unelevated no-wrap square icon="close"
            color="red-1" text-color="red-8" @click="stopCropping(true)">
            <sc-tooltip text="Cancel the cropped areas" nowrap></sc-tooltip>
          </q-btn>
        </div>

        <q-btn label="Rotate" icon="refresh" no-caps unelevated class="q-mt-sm" align="left"
          @click="rotateImage(image); changed(true);"></q-btn>

        <q-slider v-model="image.contrast" class="q-mt-lg q-mb-lg" :min="0" :max="100" label
          debounce="500" switch-label-side :label-value="'Contrast: ' + image.contrast + '%'"
          label-always @update:model-value="setImageContrast(image)"
          @change="changed(true)"></q-slider>

      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.editing {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .images {
    flex-basis: 150px;
    gap: 5px;
    overflow: hidden;
    overflow-y: auto;

    .image {
      width: 140px;
      height: 140px;
      margin: 0px 0px 10px 0px;
      border: solid #23295c 1px;
      background-color: #e8e8e8;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        border-color: #3b47b6;
      }

      &.selected {
        margin: 0 0 10px 0;
        border-color: #3b47b6;
        border-width: 2px;
      }

      .title {
        flex-basis: 34px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        background-color: #c0c0c0;
        border-bottom: solid #808080 1px;
        padding: 4px;
        overflow: hidden;

        .image-name {
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .thumbnail {
        flex: 1;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .preview {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    border: solid #23295c 1px;
    position: relative;
    overflow: hidden;

    .image-details {
      flex-basis: 44px;
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .image-container {
      flex: 1;
      position: relative;
      overflow: auto;

      .image-canvas {
        position: absolute;
        z-index: 1;
      }

      .map-scale-selector {
        position: absolute;
        z-index: 2;
      }

      .map-scale {
        position: absolute;
        z-index: 3;
        left: 10px;
        top: 10px;
        height: 12px;
        border-left: solid black 2px;
        border-right: solid black 2px;
        border-bottom: solid black 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        color: black;
      }

      .image-loading {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

    }
  }

  .properties {
    flex: 0;
    flex-basis: 150px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .buttons-crop {
      display: flex;
      flex-direction: column;

      .button-crop-clear {
        display: none;
      }

      &.image-cropped {
        flex-direction: row;
        align-items: center;

        .button-crop {
          width: 105px;
        }

        .button-crop-clear {
          display: block;
        }
      }
    }
  }
}
</style>