import IconProgress from './icon-progress.vue'
import IconFlagEU from './icon-flag-eu.vue'
import IconFlagUSA from './icon-flag-usa.vue'
import RoundIcon from './round-icon.vue'
import RoundIconButton from './round-icon-button.vue'

export default [
  { tag: 'sc-icon-progress', component: IconProgress },
  { tag: 'sc-icon-flag-eu', component: IconFlagEU },
  { tag: 'sc-icon-flag-usa', component: IconFlagUSA },
  { tag: 'sc-round-icon', component: RoundIcon },
  { tag: 'sc-round-icon-button', component: RoundIconButton }
]
