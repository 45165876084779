import { capitalize } from '@stellacontrol/utilities'

/**
 * Organization levels
 */
export const OrganizationLevel = {
  /**
   * Super organization
   */
  SuperOrganization: 'super-organization',
  /**
   * Master organization
   */
  ResellerOrganization: 'reseller-organization',
  /**
   * Regular organization
   */
  Organization: 'organization',
  /**
   * Guest organization
   */
  GuestOrganization: 'guest-organization',
  /**
   * Shipping company
   */
  ShipppingCompany: 'shippping-company'
}

/**
 * Human-friendly organization level names
 */
export const OrganizationLevelNames = {
  [OrganizationLevel.Organization]: 'Customer',
  [OrganizationLevel.ResellerOrganization]: 'Reseller',
  [OrganizationLevel.SuperOrganization]: 'Master',
  [OrganizationLevel.GuestOrganization]: 'Guest',
  [OrganizationLevel.ShipppingCompany]: 'Shipping Company'
}

/**
 * Human-friendly short organization level names
 */
export const OrganizationLevelShortNames = {
  [OrganizationLevel.Organization]: 'Customer',
  [OrganizationLevel.ResellerOrganization]: 'Reseller',
  [OrganizationLevel.SuperOrganization]: 'Master',
  [OrganizationLevel.GuestOrganization]: 'Guest',
  [OrganizationLevel.ShipppingCompany]: 'Shipping Company'
}

/**
 * Organization level weights, for sorting etc.
 */
export const OrganizationLevelWeight = {
  [OrganizationLevel.SuperOrganization]: 5,
  [OrganizationLevel.ResellerOrganization]: 4,
  [OrganizationLevel.Organization]: 3,
  [OrganizationLevel.GuestOrganization]: 2,
  [OrganizationLevel.ShipppingCompany]: 1
}

// Organization icons, representing its level
export const OrganizationIcons = {
  [OrganizationLevel.SuperOrganization]: 'stars',
  [OrganizationLevel.ResellerOrganization]: 'build',
  [OrganizationLevel.Organization]: 'account_box',
  [OrganizationLevel.GuestOrganization]: 'visibility',
  [OrganizationLevel.ShipppingCompany]: 'local_shipping'
}

// Available custom organization icons
export const CustomOrganizationIcons = [
  'account_box',
  'person_outline',
  'business',
  'maps_home_work',
  'corporate_fare',
  'apartment',
  'build',
  'cottage',
  'construction',
  'stars',
  'dining',
  'food_bank',
  'night_shelter',
  'storefront',
  'supervisor_account',
  'health_and_safety',
  'sports_soccer',
  'spa',
  'garage',
  'local_shipping',
  'directions_boat_filled',
  'local_airport',
  'subway',
  'architecture'
]

// Organization colors, representing its level
export const OrganizationColors = {
  [OrganizationLevel.SuperOrganization]: 'deep-purple-6',
  [OrganizationLevel.ResellerOrganization]: 'green-6',
  [OrganizationLevel.Organization]: 'indigo-5',
  [OrganizationLevel.GuestOrganization]: 'blue-grey-5',
  [OrganizationLevel.ShipppingCompany]: 'brown-5'
}

/**
 * Returns default icon representing organization level
 * @param organization Organization
 * @param defaultIcon Default icon to return, if organization is not specified
 */
export function getDefaultOrganizationIcon (organization, defaultIcon = 'business') {
  const icon = organization ? OrganizationIcons[organization.level] : undefined
  return icon || defaultIcon
}

/**
 * Returns custom icon representing organization level
 * @param organization Organization
 */
export function getOrganizationIcon (organization) {
  const { icon, profile = {} } = organization || {}
  return icon || profile.icon || OrganizationIcons[organization.level] || 'business'
}

/**
 * Returns color representing organization level
 * @param organization Organization
 * @param defaultColor Default color to return, if organization is not specified
 */
export function getOrganizationColor (organization, defaultColor = 'grey') {
  return organization ? OrganizationColors[organization.level] : defaultColor
}

/**
 * Returns a user friendly description of organization level
 * @param organization Organization
 * @param defaultName Default text to return, if organization is not specified
 */
export function getOrganizationLevelName (organization, defaultName = '') {
  return organization ? OrganizationLevelNames[organization.level] : defaultName
}

/**
 * Returns a user friendly description of organization
 * @param organization Organization
 * @param withLevel If true, organization level is added as prefix
 */
export function getOrganizationDescription (organization, withLevel) {
  if (organization) {
    return withLevel
      ? `${capitalize(OrganizationLevelShortNames[organization.level])} ${organization.name}`
      : organization.name
  }
}
