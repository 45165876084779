import { Point } from './point'
import { Size } from './size'
import { isNumber } from '../number'
import { isInsideRectangle, rectanglesOverlap } from './utilities'

/**
 * Rectangle
 */
export class Rectangle {
  constructor (data) {
    let right, bottom, width, height

    if (data) {
      Object.assign(this, data)
    }

    if (data != null && ((data.left != null && data.top != null) || (data.x != null && data.y != null))) {
      const left = data.left != null ? data.left : data.x
      const top = data.top != null ? data.top : data.y
      right = data.right
      bottom = data.bottom
      width = right != null ? right - left : data.width
      height = bottom != null ? bottom - top : data.height
      Object.assign(this, { left, top, width, height })
    }

    // If right/bottom is specified instead of sizes, deduct sizes
    if (this.width == null && right != null) {
      this.width = right - this.left
    }
    if (this.height == null && bottom != null) {
      this.height = bottom - this.top
    }

    // If width or height is negative, revert the coordinates
    if (width < 0) {
      this.left = this.left + width
      this.width = -this.width
    }
    if (height < 0) {
      this.top = this.top + height
      this.height = -this.height
    }
  }

  /**
   * Creates a rectangle at position `(0,0)`
   * @returns {Point}
   */
  static get Zero () {
    return new Rectangle({ x: 0, y: 0, width: 0, height: 0 })
  }

  /**
   * Creates a rectangle with bounds of another rectangle
   * @param {Rectangle} rectangle
   * @returns {Rectangle}
   */
  static from (rectangle) {
    return new Rectangle(rectangle)
  }

  /**
   * Creates a rectangle with bounds of another rectangle,
   * but centered at that rectangle's coordinates
   * @param {Rectangle} rectangle
   * @param {Boolean} round If true, the coordinates are rounded to the nearest integer
   * @returns {Rectangle}
   */
  static centeredAt (rectangle, round = true) {
    const result = new Rectangle(rectangle).moveBy({
      x: -rectangle.width / 2,
      y: -rectangle.height / 2
    })

    if (round) {
      result.x = Math.round(result.x)
      result.y = Math.round(result.y)
    }

    return result
  }

  /**
   * Creates a rectangle with the specified dimensions
   * @param {Size} size
   * @returns {Rectangle}
   */
  static dimensions (size) {
    return new Rectangle({
      x: 0,
      y: 0,
      ...size
    })
  }

  /**
   * Creates the smallest rectangle containing all the specified points
   * @param {Array[Rectangle]} points
   */
  static fromPoints (points) {
    if (points) {
      let x, y, xmax, ymax
      for (const point of points.filter(p => p)) {
        if (x == null || point.x < x) {
          x = point.x
        }
        if (xmax == null || point.x > xmax) {
          xmax = point.x
        }
        if (y == null || point.y < y) {
          y = point.y
        }
        if (ymax == null || point.y > ymax) {
          ymax = point.y
        }
      }
      return new Rectangle({
        x,
        y,
        width: xmax - x + 1,
        height: ymax - y + 1
      })
    }
  }

  /**
   * Creates the smallest rectangle containing all the specified rectangles
   * @param {Array[Rectangle]} rectangles
   */
  static fromRectangles (rectangles) {
    if (rectangles) {
      const points = rectangles
        .flatMap(r => r?.points)
        .filter(p => p)
      return Rectangle.fromPoints(points)
    }
  }

  /**
   * Creates a deep copy of the rectangle
   * @type {Rectangle}
   */
  copy () {
    return Rectangle.from(this)
  }

  /**
   * Rectangle left coordinate
   * @type {Number}
   */
  left

  /**
   * Alternative way to get the left coordinate
   * @type {Number}
   */
  get x () {
    return this.left
  }
  set x (value) {
    this.left = value
  }

  /**
   * Rectangle top coordinate
   * @type {Number}
  */
  top

  /**
   * Alternative way to get the top coordinate
   * @type {Number}
   */
  get y () {
    return this.top
  }
  set y (value) {
    this.top = value
  }

  /**
   * Rectangle width
   * @type {Number}
   */
  width

  /**
   * Rectangle height
   * @type {Number}
   */
  height

  /**
   * Returns rectangle size
   * @type {Size}
   */
  get size () {
    const { width, height } = this
    return new Size({ width, height })
  }

  /**
 * Serializes the item to JSON
 * @returns {Object}
 */
  toJSON () {
    const result = { ...this }
    if (result.left == null) delete result.left
    if (result.top == null) delete result.top
    if (result.width == null) delete result.width
    if (result.height == null) delete result.height
    return result
  }

  /**
   * String representation of the rectangle, using dimensions
   * @returns {String}
   */
  toString () {
    const { left, top, width, height } = this
    return `(${[left, top, width, height].filter(c => c != null).join(',')})`
  }

  /**
   * String representation of the rectangle, using coordinates
   * @returns {String}
   */
  toCoordinateString () {
    const { left, top, right, bottom } = this
    return `(${[left, top, right, bottom].filter(c => c != null).join(',')})`
  }

  /**
   * Normalizes the rectangle, by changing negative dimensions
   * to coordinates and flipping coordinates to growing order
   * @returns {Rectangle} Modified rectangle
   */
  normalize () {
    const { width, height } = this
    if (width < 0) {
      this.x = this.x + width
      this.width = -width
    }
    if (height < 0) {
      this.y = this.y + height
      this.height = -height
    }
    return this
  }

  /**
   * Determines whether rectangle coordinates and sizes are specified
   * @type {Boolean}
   */
  get isDefined () {
    return this.left != null && this.top != null && this.width != null && this.height != null
  }

  /**
   * Rectangle's right coordinate
   * @type {Number}
   */
  get right () {
    const { left, width } = this
    if (left != null && width != null) {
      return left + width
    }
  }
  set right (value) {
    const { left } = this
    if (left != null) {
      this.width = value - left
    }
  }

  /**
   * Rectangle's bottom coordinate
   * @type {Number}
   */
  get bottom () {
    const { top, height } = this
    if (top != null && height != null) {
      return top + height
    }
  }
  set bottom (value) {
    const { top } = this
    if (top != null) {
      this.height = value - top
    }
  }

  /**
   * Rectangle's left-top corner
   * @type {Point}
   */
  get leftTop () {
    const { left, top } = this
    if (left != null && top != null) {
      return new Point({ x: left, y: top })
    }
  }

  /**
   * Rectangle's left-bottom corner
   * @type {Point}
   */
  get leftBottom () {
    const { left, bottom } = this
    if (left != null && bottom != null) {
      return new Point({ x: left, y: bottom })
    }
  }

  /**
   * Rectangle's right-top corner
   * @type {Point}
   */
  get rightTop () {
    const { right, top } = this
    if (right != null && top != null) {
      return new Point({ x: right, y: top })
    }
  }

  /**
   * Rectangle's right-bottom corner
   * @type {Point}
   */
  get rightBottom () {
    const { right, bottom } = this
    if (right != null && bottom != null) {
      return new Point({ x: right, y: bottom })
    }
  }

  /**
   * Coordinates of rectangle's center
   * @type {Point}
   */
  get center () {
    const { left, top, width, height } = this
    if (left != null && top != null && width != null && height != null) {
      const x = left + width / 2
      const y = top + height / 2
      return new Point({ x, y })
    }
  }

  /**
   * Checks whether the rectangle has the same coordinates and dimensions as the specified one
   * @param {Rectangle} rectangle Rectangle to compare to
   * @returns {Boolean}
   */
  sameAs (rectangle) {
    if (rectangle) {
      const { x, y, width, height } = rectangle
      return this.x === x &&
        this.y === y &&
        this.width === width &&
        this.height === height
    }
  }

  /**
   * Changes the rectangle position to relative, by moving `(x,y)` point to `(0,0)`
   * @type {Rectangle}
   */
  relative () {
    this.x = 0
    this.y = 0
    return this
  }

  /**
   * Coordinates of rectangle's center, relative to the top-left point
   * @type {Point}
   */
  get middle () {
    const { width, height } = this
    if (width != null && height != null) {
      const x = width / 2
      const y = height / 2
      return new Point({ x, y })
    }
  }

  /**
   * Returns all points of the rectangle in clockwise order starting with top-left point
   * @type {Array[Point]}
   */
  get points () {
    return [
      this.leftTop,
      this.rightTop,
      this.rightBottom,
      this.leftBottom
    ]
  }

  /**
   * Sets the coordinates of the rectangle
   * @param {Point} point New coordinates of the rectangle
   * @returns {Rectangle} Modified instance
   */
  moveTo (point) {
    if (!point) throw new Error('Point is required')
    const { x, y } = point
    this.x = x == null ? this.x : x
    this.y = y == null ? this.y : y
    return this
  }

  /**
   * Sets the coordinates of the rectangle
   * so that it's centered at the specified point
   * @param {Point|Size} value Coordinates of the centre of the rectangle or size of a rectangle in which to center
   * @param {Number} percent Percentage of the dimension at which to center the rectangle
   * @returns {Rectangle} Modified instance
   * @description Examples of use
   *    centerAt({ x: 100, y: 200 }) will place the rectangle center at `(100,200)`
   *    centerAt({ x: 100 }) will place the rectangle center at `(100)` and leave the `y` coordinate intact
   *    centerAt({ width: 100 }) will place the rectangle center at `(50)` and leave the `y` coordinate intact
   *    centerAt({ height: 200 }, 25) will place the rectangle center's `y` coordinate at `25%` of the height `200`, thus `50`
   */
  centerAt (value, percent = 50) {
    if (!value) throw new Error('Point or size is required')
    const { x, y, width, height } = value

    if (x != null) {
      this.x = x - this.width / 2
    } else if (width != null && percent != null) {
      this.x = (width * (percent / 100)) - (this.width / 2)
    }

    if (y != null) {
      this.y = y - this.height / 2
    } else if (height != null && percent != null) {
      this.y = (height * (percent / 100)) - (this.height / 2)
    }

    return this
  }

  /**
   * Sets the coordinates of the rectangle
   * so that it's positioned above the specified `y` coordinate
   * @param {Number} y Coordinate above which to place the rectangle
   * @returns {Rectangle} Modified instance
   */
  moveAbove (y) {
    if (y == null) throw new Error('Y coordinate is required')
    const { height } = this
    this.y = y - height
    return this
  }

  /**
   * Sets the coordinates of the rectangle
   * so that it's positioned below the specified `y` coordinate
   * @param {Number} y Coordinate below which to place the rectangle
   * @returns {Rectangle} Modified instance
   */
  moveBelow (y) {
    if (y == null) throw new Error('Y coordinate is required')
    this.y = y
    return this
  }

  /**
   * Sets the coordinates of the rectangle
   * so that it's positioned to the left of the specified `x` coordinate
   * @param {Number} x Coordinate to the left of which to place the rectangle
   * @returns {Rectangle} Modified instance
   */
  moveBefore (x) {
    if (x == null) throw new Error('Y coordinate is required')
    const { width } = this
    this.x = x - width
    return this
  }

  /**
   * Sets the coordinates of the rectangle
   * so that it's positioned to the right of the specified `x` coordinate
   * @param {Number} x Coordinate to the right of which to place the rectangle
   * @returns {Rectangle} Modified instance
   */
  moveAfter (x) {
    if (x == null) throw new Error('Y coordinate is required')
    this.x = x
    return this
  }

  /**
   * Moves the coordinates of the rectangle
   * @param {Point} delta New coordinates of the rectangle
   * @returns {Rectangle} Modified instance
   */
  moveBy (delta) {
    if (!delta) throw new Error('Point is required')
    const { x, y } = delta
    this.x = x == null ? this.x : this.x + x
    this.y = y == null ? this.y : this.y + y
    return this
  }

  /**
   * Rounds the coordinates and size
   * @returns {Rectangle} Modified instance
   */
  round () {
    this.x = this.x != null ? Math.round(this.x) : this.x
    this.y = this.y != null ? Math.round(this.y) : this.y
    this.width = this.width != null ? Math.round(this.width) : this.width
    this.height = this.height != null ? Math.round(this.height) : this.height
    return this
  }

  /**
   * Rotates the rectangle by the given angle on a flat plane
   * @param {Number} angle Rotation angle in degrees, from `0` to `360`
   * @param {Point} origin Optional origin of the rotation. If not specified, rotation is performed against `(0,0)`
   * @param {Boolean} round If true, result coordinates are rounded up to the nearest integers
   * @returns {Rectangle} Modified instance
   */
  rotate (angle, origin, round = true) {
    const leftTop = Point.from(this.leftTop).rotate(angle, origin, round)
    const rightBottom = Point.from(this.rightBottom).rotate(angle, origin, round)

    this.left = leftTop.x
    this.top = leftTop.y
    this.right = rightBottom.x
    this.bottom = rightBottom.y

    return this
  }

  /**
   * Sets the size of the rectangle
   * @param {Rectangle} size New size of the rectangle
   * @returns {Rectangle} Modified instance
   */
  setSize (rectangle) {
    if (!rectangle) throw new Error('Size is required')
    const { width, height } = rectangle
    this.width = width == null ? this.width : width
    this.height = height == null ? this.height : height
    return this
  }

  /**
   * Sets the bounds of the rectangle
   * @param {Rectangle} size New bounds of the rectangle
   * @returns {Rectangle} Modified instance
   */
  setBounds (rectangle) {
    if (!rectangle) throw new Error('Size is required')
    this.moveTo(rectangle)
    return this.setSize(rectangle)
  }

  /**
   * Scales the rectangle by the specified factor
   * @param {Point|Number} scale Scale factor on `x` and `y` axis, where `1` means no change
   * @param {Boolean} coordinates If true, also coordinates are scaled
   * @returns {Rectangle} Modified instance
   */
  scale (scale, coordinates) {
    scale = isNumber(scale) ? { x: scale, y: scale, z: scale } : scale
    const { x, y } = scale || {}
    this.width = (x != null && this.width != null) ? this.width * scale.x : this.width
    this.height = (y != null && this.height != null) ? this.height * scale.y : this.height
    if (coordinates) {
      this.x = (x != null && this.x != null) ? this.x * scale.x : this.x
      this.y = (y != null && this.y != null) ? this.y * scale.y : this.y
    }
    return this
  }

  /**
   * Scales the coordinates by the reverse of the specified factor
   * @param {Point|Number} scale Scale factor, `1` means no change.
   * Use a number to specify the same scale factor for all axes, or {@link Point} to specify a scale for each axis individually.
   * @param {Boolean} coordinates If true, also coordinates are scaled
   * @returns {Point} Modified instance
   */
  scaleReverse (scale, coordinates) {
    scale = isNumber(scale) ? { x: scale, y: scale, z: scale } : scale
    const { x, y, z } = scale || {}
    const reverse = {
      x: x === 0 ? x : 1 / x,
      y: y === 0 ? y : 1 / y,
      z: z === 0 ? z : 1 / z
    }
    return this.scale(reverse, coordinates)
  }

  /**
   * Grows the rectangle by the specified size or margin
   * @param {Size|Margin} value Size or margin to grow by
   * @returns {Rectangle} Modified instance
   */
  growBy (value) {
    if (value) {
      const { width, height, left, top, right, bottom } = value

      if (width != null || height != null) {
        // Grow by size
        this.width = width == null ? this.width : this.width + width
        this.height = height == null ? this.height : this.height + height

      } else if (left != null || top != null || right != null || bottom != null) {
        // Grow by margins
        this.x = left == null ? this.x : this.x - left
        this.y = top == null ? this.y : this.y - top
        this.width = this.width + (left || 0) + (right || 0)
        this.height = this.height + (top || 0) + (bottom || 0)
      }
    }

    return this
  }

  /**
   * Determines whether point is inside the rectangle
   * @param {Point|Rectangle} item Point or rectangle to check
   * @returns {Boolean}
   */
  contains (item) {
    return isInsideRectangle(item, this)
  }

  /**
   * Determines whether the rectangle overlaps with another one
   * @param {Rectangle} rectangle Rectangle to check
   * @returns {Boolean}
   */
  overlapsWith (rectangle) {
    return rectanglesOverlap(this, rectangle)
  }
}

