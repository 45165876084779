<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { sortItems, pluralize, countString } from '@stellacontrol/utilities'
import { ViewMixin, Notification, Confirmation } from '@stellacontrol/client-utilities'
import { Place, getPlaceIcon, getPlaceDescription, DeviceType, DeviceRegions, DeviceRegion, DeviceRegionDescription } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceCommands, DefaultDeviceCommands } from '@stellacontrol/devices'
import { isAlertSupported } from '@stellacontrol/alerts'
import { resolve } from './building-dashboard.resolve'
import { DashboardWidgets } from '../device-dashboard/widgets'

const name = 'building-dashboard'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    ...DashboardWidgets
  },

  data () {
    return {
      name,
      // Indicates whether the dashboard is initialized
      isInitialized: false,
      // Indicates device status initialization in progress
      startingStatusWatch: false,
      // Available device commands
      DeviceCommands,
      DefaultDeviceCommands,
      // Device region items
      DeviceRegion,
      DeviceRegions,
      DeviceRegionDescription
    }
  },

  computed: {
    ...mapGetters([
      'availableFastSamplingSlots',
      'isFastSamplingDevice',
      'getStatusWatchSettings',
      'isSmallScreen',
      'organizations'
    ]),

    ...mapState({
      // Organization to which the building belongs
      organization: state => state.buildingDashboard.organization,
      // Viewed organization's guardian
      organizationGuardian: state => state.buildingDashboard.organizationGuardian,
      // Building displayed on the dashboard
      place: state => state.buildingDashboard.place,
      // Devices which belong to the building
      devices: state => state.buildingDashboard.devices,
      // All devices
      allDevices: state => state.devices.devices,
      // All places
      allPlaces: state => state.places.items,
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices || {},
      // Devices which belong to the building
      showParts: state => state.buildingDashboard.showParts,
      // Selected device ids
      selectedDeviceIds: state => state.buildingDashboard.selectedDevices,
      // Alert configurations
      alertConfigurations: state => state.buildingDashboard.alertConfigurations,
      // Network scans associated with the place
      scans: state => state.buildingDashboard.scans,
      // Alerts triggered by devices in the building
      alerts: state => state.buildingDashboard.alerts,
      // Status watch clock ticks, used to enforce refreshing of labels such as remaining fast sampling time
      ticks: state => state.deviceStatus.ticks,
      // The details of the ongoing fast-sampling
      fastSampling: state => state.deviceStatus.fastSampling[name],
      // Indicates whether device region is currently being changed
      isChangingRegion: state => state.deviceSettings.changingRegionOfDevices.length > 0,
    }),

    // Indicates whether the current place represents stock
    isStock () {
      return this.place.id === Place.ID_STOCK
    },

    // Checks whether to inform the user about limited functionality
    // for stock devices
    showStockDevicesWarning () {
      return this.isStock && this.devices.length !== 0
    },

    // Buildings of the viewed organization
    organizationPlaces () {
      const { id } = this.organization
      return this.allPlaces.filter(p => p.organizationId === id)
    },

    // Devices to show on the dashboard.
    // By default do not show individual device boards which are parts of multi-board devices.
    visibleDevices () {
      return this.devices
        .filter(device => this.showParts
          ? !device.isMultiDevice
          : !device.partOf)
    },

    // All device boards on the dashboard - multi-devices have been expanded to their boards
    visibleBoards () {
      // Take all visible devices
      const { visibleDevices } = this
      const boards = [...visibleDevices.filter(d => !d.isMultiDevice)]

      // Expand multi-devices into boards
      for (const multi of visibleDevices.filter(d => d.isMultiDevice)) {
        const parts = this.deviceParts(multi) || []
        boards.push(...parts)
      }

      return boards
    },

    // Returns a list of serial numbers of all devices on the current dashboard
    allSerialNumbers () {
      return this.visibleDevices.map(d => d.serialNumber).filter(s => s)
    },

    // Devices sorted by serial number, repeaters first
    sortedDevices () {
      const { visibleDevices } = this
      return [
        ...sortItems(visibleDevices.filter(d => d.type === DeviceType.Repeater), 'serialNumber'),
        ...sortItems(visibleDevices.filter(d => d.type !== DeviceType.Repeater), 'serialNumber')
      ]
    },

    // Returns a list of devices which are permitted to be monitored for live status.
    // If customer is on a premium plan, this requires the `live-status` subscription.
    // We take into consideration device boards - for multi-devices we will monitor their parts.
    monitoredDevices () {
      const { organizationGuardian, visibleBoards, currentOrganizationGuardian } = this
      if (!currentOrganizationGuardian) {
        return []
      }

      // Check whether they have the necessary permissions / premium services
      const devices = currentOrganizationGuardian.requiresPremiumSubscription('live-status')
        ? visibleBoards.filter(({ serialNumber }) => organizationGuardian.canDeviceUse('live-status', serialNumber, currentOrganizationGuardian))
        : (currentOrganizationGuardian.canUse('live-status') ? visibleBoards : [])

      // Return only actual communicating boards
      return devices.filter(d => d.isConnectedDevice)
    },

    // Indicates whether any devices can be monitored live
    hasMonitoredDevices () {
      return this.monitoredDevices.length > 0
    },

    // Devices which aren't monitored but can be still asked for status
    notMonitoredDevices () {
      return this.visibleDevices
        .filter(d => !this.monitoredDevices.some(ld => ld.id === d.id))
        .filter(d => d.isConnectedDevice)
    },

    // Devices currently selected in the place
    selectedDevices () {
      return this.monitoredDevices.filter(d =>
        this.selectedDeviceIds.some(s => s.id === d.id))
    },

    // Indicates whether all devices are selected in the place
    allDevicesSelected () {
      return this.monitoredDevices.length &&
        this.monitoredDevices.every(d => this.isDeviceSelected(d))
    },

    // Indicates whether any devices are selected
    hasSelectedDevices () {
      return this.selectedDevices.length > 0
    },

    // Checks whether the specified device is currently selected
    isDeviceSelected () {
      return device => this.selectedDevices.some(d => d.id === device.id)
    },

    // Indicates whether the device should be selectable
    isSelectable () {
      return device => device != null
    },

    // Indicates whether the device can be edited
    isEditable () {
      return device => device != null && this.isAdministrator
    },

    // Commands available for the device
    commands () {
      const commands = [...DefaultDeviceCommands]
      if (this.selectedDevices.length > 1) {
        const index = commands.findIndex(element => element.name === DeviceCommands.ToggleBands)
        if (index !== -1) {
          commands.splice(index, 1)
        }
      }
      return commands
    },

    // Checks whether the user can edit the building
    // Disabled as per SCL-93
    canEditPlace () {
      return false
    },

    // Checks whether the user can assign any selected devices to a building.
    // Available only when we're viewing the STOCK place.
    // To assign devices to a regular building, use the + button in the dashboard.
    canMoveDevicesToBuilding () {
      const { place, organizationPlaces, currentOrganization, selectedDevices } = this
      return place.isStock &&
        organizationPlaces.length > 0 &&
        (place.organizationId === currentOrganization.id || this.canUse('child-places')) &&
        !selectedDevices.some(d => !d.isStockDevice) &&
        this.canUse('edit-installations')
    },

    // Checks whether the user can move any selected devices back to stock.
    // Available only when we're viewing regular place.
    canMoveDevicesToStock () {
      const { place, organizationPlaces, currentOrganization, selectedDevices } = this
      return place.isRealPlace &&
        organizationPlaces.length > 0 &&
        (place.organizationId === currentOrganization.id || this.canUse('child-places')) &&
        !selectedDevices.some(d => d.isStockDevice) &&
        this.canUse('edit-installations')
    },

    // Checks whether the user can open the building plan
    canOpenPlan () {
      const { place, currentOrganization } = this
      return !this.isStock && this.canUse('planner') &&
        (place.organizationId === currentOrganization.id || this.canUse('child-floor-plans'))
    },

    // Checks whether the user can edit the building plan
    canEditPlan () {
      return this.canOpenPlan && this.canUse('planner-edit-plans')
    },

    // Checks whether the user can edit the building notes
    canEditNotes () {
      const { place, currentOrganization } = this
      return !this.isStock &&
        this.canUse('edit-installations') &&
        (place.organizationId === currentOrganization.id || this.canUse('child-places'))
    },

    // Icon for place notes, indicating whether there are file attachments as well
    notesIcon () {
      return this.place.attachmentCount
        ? 'attachment'
        : this.place.notes.length ? 'comment' : 'mode_comment'
    },

    // Indicates whether the user can send commands to devices
    canSendCommands () {
      return this.canUse('device-management') &&
        this.commands.length > 0
    },

    // Indicates whether user can change region of the selected devices.
    // Conditions:
    // * Permission device-management-region-change has been granted
    // * Place is selected
    // * Place is flagged with 'hasRegion'
    // * There are devices in place
    // * ... all place devices are selected
    canChangeRegion () {
      const { canUse, place, visibleDevices } = this
      const devices = visibleDevices.filter(d => d.isConnectedDevice && d.isMultiRegionDevice)
      return canUse('device-management-region-change') &&
        place &&
        place.hasRegion &&
        devices.length > 0
    },

    // Boards making up a multi-device
    deviceParts () {
      return device => {
        const { allDevices } = this
        const parts = device.isMultiDevice
          ? allDevices.filter(d => d.partOf === device.id)
          : undefined
        return parts
          ? sortItems(parts, part => part.model)
          : undefined
      }
    },

    // Finds the board which is currently on
    activeDevicePart () {
      return (parts) => {
        const { deviceStatus } = this
        return parts.find(part => {
          const status = deviceStatus[part.serialNumber]
          return status != null &&
            status.connection.region &&
            !status.connection.isRFOff
        })
      }
    },

    // Indicates whether live status can be monitored for the specified device
    canSeeLiveStatus () {
      return device => {
        return !this.getLiveStatusDetails(device)
      }
    },

    // Explanation why device live status cannot be displayed
    getLiveStatusDetails () {
      return device => {
        const { currentOrganizationGuardian, organizationGuardian, deviceStatus } = this
        const parts = this.deviceParts(device) || []
        const hasParts = parts.length > 0
        const board = device.isMultiDevice
          ? this.activeDevicePart(parts) || parts[0]
          : device

        if (board && currentOrganizationGuardian) {
          const status = deviceStatus[board.serialNumber]

          if (board.isMultiDevice && !hasParts) {
            return 'Status not available'
          }
          if (board.isNonConnectedDevice && !board.isMultiDevice) {
            return 'Non-connected device'
          }
          if (status?.hasNeverConnected) {
            return 'Device has never connected'
          }
          if (currentOrganizationGuardian?.requiresPremiumSubscription('live-status')) {
            return !organizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian) &&
              'No premium services active'
          } else {
            return !currentOrganizationGuardian?.canUse('live-status') &&
              'Not authorized to see live status'
          }
        }
      }
    },

    // Returns alert configurations for the specified device
    getAlertConfigurations () {
      return device => {
        const { configurations } = (this.alertConfigurations || []).find(c => c.device.serialNumber === device.serialNumber) || {}
        return configurations
      }
    },

    // Returns alerts triggered by the specified device
    getDeviceAlerts () {
      return device => {
        const { monitoredDevices, alerts, alertConfigurations, currentOrganizationGuardian: guardian, deviceStatus } = this

        // Determine whether the user can see alerts at all
        const canSeeAlerts = device != null &&
          device.canTriggerAlerts &&
          monitoredDevices.some(d => d.serialNumber === device.serialNumber) &&
          alertConfigurations != null
        if (!canSeeAlerts) {
          return
        }

        // Filter out alerts visible to the user
        const { configurations } = (alertConfigurations || []).find(c => c.device.serialNumber === device.serialNumber) || {}
        const status = deviceStatus[device.serialNumber]
        const deviceAlerts = (alerts || [])
          .filter(alert => alert.device && alert.device.serialNumber === device.serialNumber)
          .filter(alert => guardian.canReceiveAlert(alert.alertType))
          .filter(alert => {
            const configuration = configurations.find(c => c.alertType === alert.alertType)
            if (configuration != null) {
              return isAlertSupported(alert.alertType, configuration, device, status)
            }
          })

        return deviceAlerts
      }
    },

    // Maximum number of bands for any live device
    // Passed to device cards to unify the layout if devices have varying numbers of bands
    maxBands () {
      return Math.max(...this.visibleDevices.map(d => d.isConnectedDevice ? d.bandCount : 1), 1)
    },

    // Devices which can be potentially added to this place
    devicesToAdd () {
      const { place, allDevices, isStock } = this
      // Rules as per https://stelladoradus.atlassian.net/browse/SCL-741
      // - Only devices from the same organization can be moved.
      // - Only stock devices can be moved to a building.
      // - Only non-stock devices can be moved back to stock.
      // To move between buildings, go to inventory, this is deemed too dangerous for casual user.
      const devices = isStock
        ? allDevices.filter(d => d.ownerId === place.organizationId && !d.isStockDevice)
        : allDevices.filter(d => d.ownerId === place.organizationId && d.isStockDevice)
      return devices
    },

    // Indicates whether user can add devices to a building
    canAddDeviceToBuilding () {
      return this.canUse('edit-installations') &&
        this.canUse('set-device-place') &&
        this.devicesToAdd.length > 0
    },

    // Indicates whether fast-sampling is allowed for any of the devices in the building
    canFastSample () {
      const devices = this.sortedDevices.filter(device => this.canSeeLiveStatus(device))
      return devices.length > 0 && this.statusWatchSettings.fastSamplingSpeed !== 'off'
    },

    // Indicates whether there are any network scans associated with the building
    // that the user can see
    canShowScans () {
      const { place, isStock, scans } = this
      return place &&
        !isStock &&
        scans?.length > 0
    },

    // Devices which are currently eligible for fast-sampling,
    // either from the current selection or from all devices in the building
    devicesEligibleForFastSampling () {
      const { canSendCommands, sortedDevices, selectedDevices, availableFastSamplingSlots } = this

      if (!canSendCommands) return []

      const devices = (selectedDevices.length > 0 ? selectedDevices : sortedDevices)
        .filter(device => this.canSeeLiveStatus(device))
        .filter(device => !this.isFastSamplingDevice(device))

      if (devices.length >= availableFastSamplingSlots) {
        return selectedDevices.length > 0 ? [] : devices.slice(0, availableFastSamplingSlots)
      }

      return devices
    },

    // The remaining time of the ongoing fast sampling
    remainingFastSamplingTime () {
      if (this.ticks > 0) {
        return this.fastSampling?.remaining
      }
    },

    // Indicates that we're currently fast-sampling some devices in the building
    isFastSampling () {
      return this.remainingFastSamplingTime > 0
    },

    // Label to show on the fast-sampling button
    fastSamplingLabel () {
      return this.isFastSampling
        ? this.remainingFastSamplingTime
        : this.statusWatchSettings.fastSamplingDuration
    },

    // Status watch settings for the current view
    statusWatchSettings () {
      return this.getStatusWatchSettings(name)
    },

    // View breadcrumbs
    breadcrumbs () {
      const { organization, place, getViewTitle, organizations } = this
      if (!(organization && place)) {
        return []
      }

      // Find a distributor of the current organization.
      // If parent is super-organization, there's no distributor.
      const { parentOrganizationId } = organization
      const distributor = parentOrganizationId != null
        ? organizations.find(item => item.id === parentOrganizationId && !item.isSuperOrganization)
        : null

      let breadcrumbs = [
        {
          name: 'home',
          title: getViewTitle('home')
        },
        {
          name: 'installations',
          title: getViewTitle('installations'),
          route: 'installations'
        },
        distributor
          ? {
            name: 'installations',
            title: distributor.name,
            route: 'installations',
            query: {
              filter: distributor.name,
              exact: true
            }
          }
          : null,
        {
          name: 'installations',
          title: organization.name,
          route: 'installations',
          query: {
            filter: organization.name,
            exact: true
          }
        },
        {
          title: place.name
        }
      ]

      return breadcrumbs
    }
  },

  methods: {
    ...mapActions([
      'busy',
      'initializeBuildingDashboard',
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus',
      'suspendWatchingDeviceStatus',
      'resumeWatchingDeviceStatus',
      'watchUploadStatus',
      'unwatchUploadStatus',
      'gotoInventory',
      'showPlaceNotes',
      'goBack',
      'gotoRoute',
      'openInventoryAction',
      'buildingSelectDevice',
      'startFastSampling',
      'fastSamplingCountdown',
      'showDialog',
      'setDevicePlace',
      'removeDevicesFromPlace',
      'changeRegionOfDevices',
    ]),

    getPlaceIcon,

    // Populates the dashboard
    async populate () {
      this.isInitialized = false

      const { organization, place } = this
      if (organization && place) {
        this.watchStatus()
      }
      this.isInitialized = true
    },

    // Starts watching for device status
    async watchStatus () {
      if (!this.startingStatusWatch) {
        this.startingStatusWatch = true
        try {
          await this.unwatchStatus()

          // Start watching live status of permitted devices
          if (this.hasMonitoredDevices) {
            await this.watchDeviceStatus({ name, devices: this.monitoredDevices })
            await this.watchUploadStatus({ name, interval: this.statusWatchSettings.fastSamplingDuration })
          }

          // Fetch most-recent status of remaining devices
          await this.getLiveStatus({
            devices: this.notMonitoredDevices
          })

        } finally {
          this.startingStatusWatch = false
        }
      }
    },

    // Stops watching the device status
    async unwatchStatus () {
      await this.unwatchDeviceStatus({ name })
      await this.unwatchUploadStatus({ name })
    },

    // Suspends watching the device status
    async suspendWatchStatus () {
      if (!this.hasMonitoredDevices) return
      this.suspendWatchingDeviceStatus({ name })
      this.unwatchUploadStatus({ name })
    },

    // Resumes watching the device status
    async resumeWatchStatus () {
      if (!this.hasMonitoredDevices) return
      this.resumeWatchingDeviceStatus({ name })
      this.watchUploadStatus({ name, interval: this.statusWatchSettings.fastSamplingDuration })
    },

    // Opens organization devices (or current selection) in inventory
    showDevicesInInventory () {
      this.gotoInventory({
        selection: this.allSerialNumbers,
        filterBySelection: true
      })
    },

    // Toggle device selection
    toggleDevice (device, isSelected) {
      if (this.isSelectable(device)) {
        this.buildingSelectDevice({ device, isSelected })
      }
    },

    // Starts fast sampling of all or selected devices
    async startDeviceFastSampling () {
      const {
        devicesEligibleForFastSampling: devices,
        statusWatchSettings: { fastSamplingDuration }
      } = this

      if (devices.length > 0) {
        await this.startFastSampling({ name, devices, fastSamplingDuration })
        await this.fastSamplingCountdown({ name, devices, fastSamplingDuration })
        Notification.success({
          message: `Live mode started for ${fastSamplingDuration}s`
        })
      }
    },

    // Goes back to installations dashboard
    close () {
      this.gotoRoute({ name: 'installations' })
    },

    // Adds devices to the viewed building.
    // If building is stock, we're removing devices from their current places.
    async addDeviceToBuilding () {
      const { isStock, place, allPlaces, devicesToAdd: devices } = this

      const title = isStock ? 'Move device back to stock' : `Move device to ${place.name}`
      const { isOk, data } = await this.showDialog({
        dialog: 'device-picker',
        data: { title, devices }
      })

      if (isOk) {
        const { device } = data

        if (isStock) {
          // Move the device from its current place back to stock
          const place = allPlaces.find(p => p.id === device.placeId)
          await this.removeDevicesFromPlace({ devices: [device], place })

        } else {
          // Move the device to place
          await this.setDevicePlace({ device, place })

          // Activate any pending premium services present on the device
          const { premiumServiceId, isPremiumServiceNotStarted } = device
          if (premiumServiceId && isPremiumServiceNotStarted) {
            await this.startDeviceSubscriptions({
              devices: [device],
              startsAt: new Date(),
              details: `Premium service has been activated because the device has been assigned to ${place.name}`,
              silent: true,
            })
          }
        }

        // Reload the dashboard and reinitialize status watch
        await this.initializeBuildingDashboard({ id: this.place.id, organizationId: this.organization.id })
        await this.unwatchStatus()
        await this.watchStatus()
      }
    },

    // Adds the selected stock devices to a building
    async moveDevicesToBuilding () {
      const { organization, organizationPlaces, place, selectedDevices: devices = [] } = this
      if (!place.isStock) return
      if (!devices.every(d => d.isStockDevice)) return

      const devicesLabel = devices.length === 1
        ? devices[0].serialNumber
        : countString(devices, 'stock device')
      const title = `Move ${devicesLabel} to building`

      const { isOk, data } = await this.showDialog({
        dialog: 'building-picker',
        data: {
          title,
          buildings: organizationPlaces
        }
      })

      if (isOk) {
        const { building } = data

        const hideNotification = Notification.progress({
          message: `Moving the ${pluralize(devices, 'device')} to ${getPlaceDescription(building)}, please wait ...`
        })

        for (const device of devices) {
          // Move the device to place
          await this.setDevicePlace({ device, place: building, silent: true })

          // Activate any pending premium services present on the device
          const { premiumServiceId, isPremiumServiceNotStarted } = device
          if (premiumServiceId && isPremiumServiceNotStarted) {
            await this.startDeviceSubscriptions({
              devices: [device],
              startsAt: new Date(),
              details: `Premium service has been activated because the device has been assigned to ${building.name}`,
              silent: true,
            })
          }
        }

        // Reload the dashboard and reinitialize status watch
        hideNotification()
        await this.initializeBuildingDashboard({ id: place.id, organizationId: organization.id })
        await this.unwatchStatus()
        await this.watchStatus()
      }
    },

    // Moves the selected stock devices back to stock
    async moveDevicesToStock () {
      const { organization, place, selectedDevices: devices = [] } = this
      if (place.isStock) return
      if (devices.some(d => d.isStockDevice)) return

      const devicesLabel = devices.length === 1
        ? devices[0].serialNumber
        : 'the selected devices'
      const message = `Move ${devicesLabel} to back to stock?`

      const yes = await Confirmation.ask({ message })
      if (yes) {
        const building = Place.createStock({ organizationId: organization.id })
        const hideNotification = Notification.progress({
          message: `Moving the ${pluralize(devices, 'device')} back to stock, please wait ...`
        })

        for (const device of devices) {
          await this.setDevicePlace({ device, place: building, silent: true })
        }

        // Reload the dashboard and reinitialize status watch
        hideNotification()
        await this.initializeBuildingDashboard({ id: place.id, organizationId: organization.id })
        await this.unwatchStatus()
        await this.watchStatus()
      }
    },

    // Changes the region
    async setDeviceRegion (region) {
      const { canChangeRegion, visibleBoards: devices } = this
      if (canChangeRegion) {
        await this.changeRegionOfDevices({ devices, region, confirm: true })
      }
    },

    // Shows network scans associated with the building
    async showScans () {
      const { place, canShowScans, scans } = this
      if (!canShowScans) return

      const title = `${place.name} Scans`
      await this.showDialog({
        dialog: 'files-dialog',
        data: {
          title,
          files: scans
        }
      })
    }
  },

  async created () {
    await this.populate()
  },

  // Reload the dashboard on navigation to another place
  async beforeRouteUpdate (to, from, next) {
    // Stop any running status subscriptions
    await this.unwatchDeviceStatus()

    // Load the data of the new organization and place
    const { redirectTo } = await resolve({ from, to }) || {}

    // Re-initialize the dashboard
    await next(redirectTo)
    if (!redirectTo) {
      await this.populate()
    }
  }
}
</script>

<template>
  <sc-view :name="name" :breadcrumbs="breadcrumbs">

    <!-- Desktop mode toolbar -->
    <template #toolbar>
      <q-btn v-if="canEditPlan || canOpenPlan" dense unelevated icon="category" label="Plan"
        :to="{ name: 'building-plan', params: { id: place.id } }">
        <sc-tooltip>
          {{ canEditPlan ? 'Edit building plan' : 'Open building plan' }}
        </sc-tooltip>
      </q-btn>

      <q-btn v-if="canEditNotes" dense unelevated :icon="notesIcon" label="Notes"
        textColor="indigo-5" @click.stop="showPlaceNotes({ place })">
      </q-btn>


      <q-btn v-if="canShowScans" dense unelevated icon="radar" label="Scans" textColor="indigo-5"
        @click.stop="showScans()">
      </q-btn>

      <q-btn v-if="canEditPlace" dense unelevated icon="edit" label="Details"
        :to="{ name: 'place', params: { id: place.id } }">
        <sc-tooltip>
          Edit the building
        </sc-tooltip>
      </q-btn>

      <q-btn v-if="canMoveDevicesToBuilding" :disable="selectedDevices.length === 0" dense
        unelevated icon="sym_o_trackpad_input" label="Assign to building"
        @click="moveDevicesToBuilding()">
        <sc-tooltip>
          Assign stock devices to a building
        </sc-tooltip>
      </q-btn>

      <q-btn v-if="canMoveDevicesToStock" :disable="selectedDevices.length === 0" dense unelevated
        icon="sym_o_trackpad_input" label="Move to stock" @click="moveDevicesToStock()">
        <sc-tooltip>
          Move devices back to stock
        </sc-tooltip>
      </q-btn>

      <q-btn-dropdown v-if="canChangeRegion" :disabled="isChangingRegion" dense unelevated
        label="Region" :ripple="false">
        <q-list>
          <q-item v-for="r in DeviceRegions" clickable v-close-popup @click="setDeviceRegion(r)">
            <q-item-section side>
              <sc-icon-flag-eu v-if="r === DeviceRegion.EMEA" :width="24" :height="20">
              </sc-icon-flag-eu>
              <sc-icon-flag-usa v-if="r === DeviceRegion.USA" :width="24" :height="20">
              </sc-icon-flag-usa>
              <q-icon v-if="r === DeviceRegion.Off" name="stop_circle" color="grey-6" size="24px">
              </q-icon>
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ DeviceRegionDescription[r] }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <q-btn-dropdown v-if="canSendCommands" unelevated icon="sym_o_more_horiz" label="Commands"
        :ripple="false" :disable="selectedDevices.length === 0">
        <sc-tooltip nowrap>
          Send commands to devices
        </sc-tooltip>
        <sc-device-commands :show-header="false" :commands="commands" :devices="selectedDevices">
        </sc-device-commands>
      </q-btn-dropdown>

      <q-btn class="button-live" v-if="canFastSample" unelevated
        :label="`Live ${fastSamplingLabel}`" :disable="isFastSampling" icon="play_arrow"
        :color="isFastSampling ? 'green-5' : undefined" :ripple="false"
        @click="startDeviceFastSampling()">
        <sc-tooltip nowrap v-if="isFastSampling">
          Fast sampling is ON, remaining time {{ remainingFastSamplingTime }}s
        </sc-tooltip>
        <sc-tooltip nowrap v-else>
          Turn on fast sampling for {{ statusWatchSettings.fastSamplingDuration }}s
        </sc-tooltip>
      </q-btn>

    </template>

    <!-- When in mobile mode, show buttons inside the topbar -->
    <teleport v-if="isSmallScreen" to="#topbar-items">
      <span class="place-label q-mr-sm text-white">
        {{ place.name }}
      </span>

      <q-space>
      </q-space>

      <div class="row items-center no-wrap">
        <q-btn v-if="canFastSample" flat dense round @click="startDeviceFastSampling()"
          :disable="!hasSelectedDevices || isFastSampling">
          <div class="row items-center no-wrap">
            <div class="fast-sampling-indicator"
              :class="{ 'disabled': !hasSelectedDevices, 'active': isFastSampling, 'pulse': isFastSampling }">
              {{ fastSamplingLabel }}
            </div>
          </div>
        </q-btn>

        <sc-round-icon-button v-if="canSendCommands" icon="sym_o_more_horiz" bg-color="indigo-6"
          :color="hasSelectedDevices ? 'white' : 'indigo-3'" :disable="!hasSelectedDevices"
          class="q-ml-sm">
          <q-popup-proxy>
            <div class="command-menu">
              <sc-device-commands :show-header="false" :commands="commands"
                :devices="selectedDevices">
              </sc-device-commands>
            </div>
          </q-popup-proxy>
        </sc-round-icon-button>

        <sc-round-icon-button v-if="canEditNotes" icon="description" bg-color="indigo-6"
          @click.stop="showPlaceNotes({ place })" class="q-ml-md">
        </sc-round-icon-button>

        <sc-round-icon-button icon="arrow_back" bg-color="indigo-6" @click="close()"
          class="q-ml-md">
        </sc-round-icon-button>
      </div>
    </teleport>

    <q-banner v-if="showStockDevicesWarning" class="bg-orange-6 row items-center">
      <q-icon color="white" name="new_releases" class="q-mr-xs" size="sm">
      </q-icon>
      <span class="text-subtitle2">
        To unlock all features move
        {{ pluralize(devices, 'the device', 'the devices') }}
        to building
      </span>
    </q-banner>

    <div class="devices" v-if="sortedDevices.length > 0 || canAddDeviceToBuilding">
      <template v-for="device in sortedDevices">
        <sc-widget-device-card dense :device="device" :place="place" :organization="organization"
          :alerts="getDeviceAlerts(device)" :compact="false" :is-editable="isEditable(device)"
          :is-selectable="isSelectable(device)" :is-selected="isDeviceSelected(device)"
          :is-live-status-allowed="canSeeLiveStatus(device)"
          :live-status-details="getLiveStatusDetails(device)" :max-bands="maxBands"
          @select="({ device, isSelected }) => toggleDevice(device, isSelected)">
        </sc-widget-device-card>
      </template>
      <div class="button-add-to-place row items-center" v-if="canAddDeviceToBuilding">
        <q-btn icon="add" flat @click="addDeviceToBuilding()" size="lg" :ripple="false">
        </q-btn>
        <sc-tooltip>
          Move devices
          {{ isStock ? 'back to stock' : `to ${place.name}` }}
        </sc-tooltip>
      </div>
    </div>

    <div class="no-devices q-pa-md" v-if="sortedDevices.length === 0 && !canAddDeviceToBuilding">
      There are no devices in {{ place.name }}.
    </div>

    <sc-band-selector-dialog></sc-band-selector-dialog>
    <sc-device-picker-dialog></sc-device-picker-dialog>
    <sc-building-picker-dialog></sc-building-picker-dialog>
    <sc-document-upload-dialog></sc-document-upload-dialog>
    <sc-band-toggle-dialog></sc-band-toggle-dialog>
    <sc-files-dialog></sc-files-dialog>
  </sc-view>

</template>

<style lang="scss" scoped>
.button-live {
  width: 120px;
}

.devices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  align-content: flex-start;
}

.context-menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.place-label {
  margin: 0;
  padding: 0 0 0 2px;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
  color: #272727;
  margin-bottom: 10px;
}

.button-add-to-place {
  width: 190px;
  min-height: 200px;
  border: dashed #d5d5d5 2px;
  border-radius: 4px;

  .q-btn {
    width: 100%;
    height: 100%;
  }
}

/* Fast sampling indicator for mobile view */
.fast-sampling-indicator {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: #ffffff;
  color: #242c5b;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;

  &.disabled {
    background-color: #cdd5ff;
  }

  &.active {
    background-color: #6aff6a;
  }
}

/* Layout adjustments for small screens */
@media screen and (width <=1024px) {
  .devices {
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .place-label {
    font-size: 16px;
    margin-bottom: unset;
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (width <=920px) {
  .devices {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (width <=640px) {
  .devices {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
  }
}
</style>
