import { PlanItem, PlanItemType } from './plan-item'
import { PlanPadding, PlanLineStyle } from '../styles'

/**
 * Text
 */
export class PlanText extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Text
  }

  /**
   * Creates a new generic text
   * @param {Object} data Initial data
   * @returns {PlanText}
   */
  static create (data = {}) {
    return new PlanText({
      width: 75,
      height: 34,
      label: 'Abc 123',
      ...data
    })
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      padding: PlanPadding.P8,
      lineStyle: PlanLineStyle.Single
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.padding = this.customize(this.padding, PlanPadding, defaults.padding)
  }

  /**
   * Text padding, used when {@link border} and/or {@link background} is specified
   * @type {PlanPadding}
   */
  padding

  /**
   * Returns the number of lines of the text
   * @type {Number}
   */
  get lineCount () {
    return (this.label || '').split('\n').length
  }

  /**
   * Indicates whether to automatically adjust the font size
   * @type {Boolean}
   */
  get autoAdjustFontSize () {
    return true
  }
}
