<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { LogLevel, sortItemsReverse, ISODateTimeRegex, formatDateTime } from '@stellacontrol/utilities'
import { AttachmentDataTypeLabel } from '@stellacontrol/model'
import { isDeviceCommandApplicable, isMegaParameterApplicable } from '@stellacontrol/mega'
import { DeviceCommand, DeviceCommands } from '@stellacontrol/devices'
import Panel from './panel'

export default {
  mixins: [
    Panel
  ],

  data () {
    return {
      results: [],
      AttachmentDataTypeLabel
    }
  },

  computed: {
    ...mapState({
      // Network scans collected by the device
      scans: state => state.deviceDashboard.scans,
      // Errors registered when receiving scan files (usually parsing issues)
      scanErrors: state => state.deviceDashboard.scanErrors,
    }),

    ...mapGetters([
      'toLocalDateTime'
    ]),

    // Determines whether the scans panel is visible.
    // Here we only show scans collected by modem-equipped repeaters.
    // Seemingly odd that we don't show them for test tool devices,
    // but it would be useless. The same test tool will be used countless
    // times with different buildings and owners, and the list would contain
    // completely unrelated scans. Network scans are to be viewed in context of a building.
    isVisible () {
      const { device, status } = this
      return !device.isTestTool &&
        this.canUse('device-tt-scans') &&
        isMegaParameterApplicable('can_send_scans', device, status)
    },

    // Returns true if device scan results are available
    hasScanResults () {
      return this.scans?.length > 0
    },

    // Determines whether the user can request a scan
    canRequestScan () {
      const { device, status } = this
      return this.canUse('device-management-scan') &&
        isDeviceCommandApplicable(DeviceCommands.RequestDeepCellScan, device, status)
    },

    // Scan groups
    groups () {
      const { scans, scanErrors } = this
      const automatic = sortItemsReverse((scans || []).filter(s => s.scan?.wasAutomatic), 'createdAt')
      const manual = sortItemsReverse((scans || []).filter(s => !s.scan?.wasAutomatic), 'createdAt')
      const errors = sortItemsReverse((scanErrors || []), 'createdAt')
      return [
        { label: 'Manual Scans', scans: manual, isEmpty: manual.length === 0 },
        { label: 'Automatic Scans', scans: automatic, isEmpty: automatic.length === 0 },
        { label: 'Failed Scans', scans: errors, isEmpty: errors.length === 0 },
      ].filter(group => !group.isEmpty)
    },

    // Checks whether the item represents a failed scan
    isFailedScan () {
      return item => {
        return item.level === LogLevel.ERROR
      }
    },

    // Returns a note describing the scan
    getScanNote () {
      return item => {
        // Return the error message if failed scan
        if (this.isFailedScan(item)) {
          return 'Error processing the scan'
        }

        // Custom note added later by user takes precedence.
        // Some notes received from devices have just the serial and the time - remove the serial and time.
        const { device } = this
        const note = (item.raw.note || item.note || '')
          .replace(device.serialNumber, '')
          .replace('@', '')
          .replace(ISODateTimeRegex, '')
          .trim()

        return note || AttachmentDataTypeLabel[item.type]
      }
    },

    // Returns a tooltip for the scan
    getScanTooltip () {
      return item => {
        // Return the error message if failed scan
        if (this.isFailedScan(item)) {
          return this.isSuperAdministrator ? item.message : 'The scan could not be processed'
        }

        return this.getScanNote(item)
      }
    },

    // Returns a time of the scan
    getScanTime () {
      return item => {
        return formatDateTime(this.toLocalDateTime(item.createdAt), 'dd/MM/yyyy HH:mm')
      }
    }
  },

  methods: {
    ...mapActions([
      'getAttachment',
      'previewAttachment',
      'sendDeviceCommand'
    ]),

    // Displays preview of the scan
    async previewScan (item) {
      if (this.isFailedScan(item)) return

      const attachment = await this.getAttachment({
        id: item.id,
        withContent: true
      })

      if (attachment?.canPreview || attachment?.canPreviewInFrame) {
        this.previewAttachment({ attachment })
      }
    },

    // Runs a network scan
    async runScan () {
      const { device, deviceStatus: status } = this
      const devices = [device]
      const command = DeviceCommand.create(DeviceCommands.RequestDeepCellScan)
      await this.sendDeviceCommand({ command, devices, status })
    }
  }
}
</script>

<template>
  <div v-if="isVisible" class="widget panel-widget scans">

    <div class="header row items-center text-bold">
      <q-icon size="26px" name="radar" color="indigo-6">
      </q-icon>
      <span class="q-ml-sm">
        Network Scans
      </span>
      <q-space></q-space>
      <q-btn v-if="canRequestScan" unelevated dense icon="add" label="Scan" no-caps
        @click="runScan()">
        <sc-tooltip>
          Run network scan
        </sc-tooltip>
      </q-btn>
    </div>

    <div class="items q-mt-sm" v-if="hasScanResults">
      <template v-for="group in groups">
        <header class="q-ml-sm">
          {{ group.label }} ({{ group.scans.length }})
        </header>

        <section class="q-mt-xs q-ml-xs q-mb-sm">
          <a class="item item-link"
            v-for="(item, index) in group.scans" :key="index" @click="previewScan(item)">

            <q-badge rounded outline
              :color="isFailedScan(item) ? 'red' : (item.wasOutdoorScan ? 'blue' : 'green')">
              <span class="badge">
                {{ isFailedScan(item) ? '!' : (item.wasOutdoorScan ? 'O' : 'I') }}
              </span>
              <sc-tooltip>
                {{ item.wasOutdoorScan ? 'Outdoor scan' : 'Indoor scan' }}
              </sc-tooltip>
            </q-badge>

            <span class="note">
              {{ getScanNote(item) }}
              <sc-tooltip>
                {{ getScanTooltip(item) }}
              </sc-tooltip>
            </span>

            <span class="time">
              {{ getScanTime(item) }}
            </span>
          </a>
        </section>
      </template>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.scans {
  max-height: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  justify-content: start;

  .items {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;

    >header {
      font-size: 13px;
      font-weight: bold;
      overflow: hidden;
    }

    >section {
      padding-right: 8px;
      padding-bottom: 8px;
      overflow: hidden;

      &:last-child {
        padding-bottom: 0;
      }

      .item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 6px 0 6px 4px;
        font-size: 13px;
        overflow: hidden;

        &:hover {
          background-color: #fafafa;
        }

        .badge {
          font-family: monospace;
        }

        .note {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 8px;
          max-width: 198px;
        }

        .time {
          white-space: nowrap;
          padding-left: 5px;
        }
      }
    }
  }
}
</style>
