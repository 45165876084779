import Konva from 'konva'
import { PlanItemType } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Ellipse
 */
export class EllipseShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.content = new Konva.Ellipse()
  }

  static get type () {
    return PlanItemType.Ellipse
  }

  render (renderer) {
    super.render(renderer)
    const { content: shape, item } = this
    if (shape && item) {
      const { lineStyle, backgroundStyle } = item
      // Konva reverts the radii somehow ...
      shape.radiusY(item.radius.x)
      shape.radiusX(item.radius.y)
      shape.stroke(item.lineStyle.color)
      shape.strokeWidth(lineStyle.width)
      shape.fill(backgroundStyle.color)
      this.applyFilters()
    }
  }

  /**
   * Triggered when shape is being transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Shape position after the transformation
   * @param {PlanScale} scale Scale of the shape after the transformation
   * @param {Number} rotation Shape rotation after the transformation
   * @returns {Boolean}
   */
  // eslint-disable-next-line no-unused-vars
  async transforming ({ renderer, position, scale, rotation }) {
    return false
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Shape position after the transformation
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   * @returns {Boolean}
   */
  async transformed ({ renderer, position, scale, rotation }) {
    super.transformed({ renderer, position, scale, rotation })
    const { item } = this

    // Scale the radius
    if (scale) {
      item.radius.x = Math.round(item.radius.x * scale.x)
      item.radius.y = Math.round(item.radius.y * scale.y)
    }

    // Adjust the position
    if (position) {
      item.setCoordinates(position, renderer.isCrossSection)
    }

    // Re-render the shape
    await this.render(renderer)

    return true
  }
}
