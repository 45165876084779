<script>
import { SplitterType, SplitterName } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import BackgroundProperties from './background-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-background-properties': BackgroundProperties
  },

  data () {
    return {
      SplitterType
    }
  },

  computed: {
    // Available splitter types
    splitterTypes () {
      return Object
        .values(SplitterType)
        .map(value => ({ value, label: SplitterName[value] }))
    }
  }
}

</script>

<template>
  <main class="plan-item-properties">
    <section>
      <label>
        Splitter Type
      </label>
      <q-select outlined square dense emit-value map-options :options="splitterTypes"
        :style="styles.select.wide" :model-value="getValue('splitterType', SplitterType.TwoWaySplitter)"
        @update:model-value="value => setProperties({ 'splitterType': value })">
      </q-select>
    </section>
  </main>

  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction" :can-edit-label="isAdvancedMode">
  </sc-item-properties>

  <sc-background-properties v-if="isAdvancedMode" :renderer="renderer" :items="items" @action="executeAction">
  </sc-background-properties>
</template>

<style lang="scss" scoped></style>
