import Konva from 'konva'
import { Layer } from './layer'
import { Size } from '@stellacontrol/utilities'

/**
 * Base layer renderer
 */
export class BaseLayer extends Layer {
  constructor (data) {
    super({ ...data, isTrueLayer: true })
  }

  /**
   * White background
   * @type {Konva.Rectangle}
   */
  white

  /**
   * Creates own shapes of the layer
   * @return {Promise<Array[Shape|Konva.Shape]>}
   */
  async createOwnShapes () {
    this.destroy(this.white)

    this.white = new Konva.Rect({
      draggable: false,
      listening: false,
      fill: 'white',
      x: 0,
      y: 0
    })

    this.resizeBackground()

    return [this.white]
  }

  /**
   * Makes sure that background shape completely fills the canvas
   */
  resizeBackground () {
    const { white, floor: { dimensions, margin } } = this
    if (white) {
      const { width, height } = Size
        .from(dimensions)
        .growBy(margin)
        .round()

      white.width(width)
      white.height(height)
    }
  }

  /**
   * Renders the layer
   * @returns {Promise}
   */
  async render () {
    await super.render()
    this.resizeBackground()
  }

  /**
   * Redraws the layer
   * @returns {Promise}
  */
  async refresh () {
    await super.refresh()
    this.resizeBackground()
  }
}
