/**
 * Checks whether the coordinates of the specified mouse event
 * are inside the boundaries of the specified element
 * @param {MouseEvent} event Event to check
 * @param {HTMLElement} element HTML element
 * @returns {Boolean}
 */
export function isEventInElement (event, element) {
  if (event && element) {
    const rect = element.getBoundingClientRect()
    const { clientX: x, clientY: y } = event

    if (x < rect.left || x >= rect.right) {
      return false
    }

    if (y < rect.top || y >= rect.bottom) {
      return false
    }

    return true
  }
}
