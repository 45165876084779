<script>
import { sameArrays } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceAuditEventType, DeviceAuditEventDescription } from '@stellacontrol/devices'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Initial value
    modelValue: {
    },

    // Observed device
    device: {
    }
  },

  data () {
    return {
      DeviceAuditEventDescription,

      // Names of extras selected for the chart
      selected: []
    }
  },

  computed: {
    // All extras which can be selected into the chart
    // Remove those to which the org has no permissions.
    extras () {
      const { canUse } = this
      const extras = [
        canUse('alerts') ? DeviceAuditEventType.Alert : null,
        canUse('device-configuration') ? DeviceAuditEventType.SettingsChanged : null,
        canUse('device-configuration') ? DeviceAuditEventType.CommandSent : null,
        canUse('device-management-firmware') ? DeviceAuditEventType.FirmwareUpdated : null
      ]
      return extras.filter(e => e)
    },

    // Icons representing extras
    icons () {
      const icons = {
        [DeviceAuditEventType.Alert]: 'notifications',
        [DeviceAuditEventType.SettingsChanged]: 'settings',
        [DeviceAuditEventType.CommandSent]: 'wifi_tethering',
        [DeviceAuditEventType.FirmwareUpdated]: 'memory'
      }
      return icons
    },

    // Determines whether to show decimation button
    showDecimate () {
      if (this.isSuperAdministrator) {
        // Hidden for the time being ...
        // Decimation needs to be look at again.
        return false
      }
    }
  },

  methods: {
    // Checks if the specified extra is currently selected
    isSelected (name) {
      return this.selected.includes(name)
    },

    // Selects/deselects the specified extra
    select (name, status) {
      const index = this.selected.indexOf(name)
      if (status) {
        if (index === -1) {
          this.selected = [...this.selected, name]
        }
      } else {
        this.selected = this.selected.filter(item => item !== name)
      }
    }
  },

  watch: {
    // Triggered when selection changes
    selected (newValue, oldValue) {
      // Filter out those which are not allowed by current permissions
      newValue = newValue.filter(value => this.extras.includes(value))
      if (!sameArrays(newValue, oldValue)) {
        this.$emit('update:model-value', this.selected)
      }
    },

    // Triggered when model changes
    modelValue (newValue, oldValue) {
      if (!sameArrays(newValue, oldValue)) {
        this.selected = [...(newValue || [])]
      }
    }
  },

  created () {
    // Filter out those which are not allowed by current permissions
    const { modelValue, extras } = this
    this.selected = [...(modelValue || [])].filter(e => extras.includes(e))
  }
}
</script>

<template>
  <div v-if="extras.length > 0">
    <label class="q-mb-md text-bold">
      Device events:
    </label>

    <div v-for="extra in extras">
      <q-checkbox size="md" :label="DeviceAuditEventDescription[extra]" :checked-icon="icons[extra]"
        :unchecked-icon="icons[extra]" :color="isSelected(extra) ? 'green-7' : 'blue-grey-2'"
        keep-color :model-value="isSelected(extra)"
        @update:model-value="status => select(extra, status)">
      </q-checkbox>
    </div>

    <div v-if="showDecimate">
      <q-checkbox size="md" label="Optimize for long periods"
        :model-value="!isSelected('nodecimate')"
        @update:model-value="status => select('nodecimate', !status)">
      </q-checkbox>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>