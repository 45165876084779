<script>
import { mapActions, mapGetters } from 'vuex'
import { capitalize } from '@stellacontrol/utilities'
import { getPlaceLabel } from '@stellacontrol/model'
import { ViewMixin, EditorMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const name = 'place'

/**
 * Place editor
 */
export default {
  mixins: [
    ViewMixin,
    EditorMixin,
    Secure
  ],


  data () {
    return {
      name,
      initialOrganizationId: null
    }
  },

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    forms () {
      return [
        'place'
      ]
    },

    // Edited place
    place () {
      return this.data
    },

    // Organization to which the place belongs
    organization () {
      return this.organizations.find(o => o.id === this.place.organizationId) || this.currentOrganization
    },

    // Indicates that we're adding a place in another organization, not ours
    inAnotherOrganization () {
      return this.place.organizationId !== this.currentOrganization.id
    },

    // Indicates that we're moving the place to another organization
    movingToAnotherOrganization () {
      return !this.place.isNew && this.place.organizationId !== this.initialOrganizationId
    },

    // Custom view title
    title () {
      const { place, inAnotherOrganization } = this
      const { placeType, name, isNew, organization } = place
      return isNew
        ? `New ${getPlaceLabel(placeType, 'place')} ${inAnotherOrganization ? 'in ' + organization.name : ''}`
        : `${capitalize(getPlaceLabel(placeType, 'place'))} ${name}`
    },

    // View breadcrumbs
    breadcrumbs () {
      const { organization, place, getViewTitle } = this

      const breadcrumbs = [
        {
          name: 'home',
          title: getViewTitle('home')
        },
        {
          name: 'installations',
          title: getViewTitle('installations'),
          route: 'installations'
        },
        {
          name: 'installations',
          title: organization.name,
          route: 'installations',
          query: {
            filter: organization.name
          }
        },
        {
          title: place.isNew ? 'New Building' : place.name
        }
      ]

      return breadcrumbs
    }
  },

  methods: {
    ...mapActions([
      'savePlace',
      'goBack',
      'gotoRoute'
    ]),

    getPlaceLabel,

    // Saves the place and navigates back to where the user came from
    async save (goBack) {
      if (await this.validate()) {
        const { place, movingToAnotherOrganization } = this
        if (await this.savePlace({ place, movingToAnotherOrganization })) {
          if (goBack) {
            await this.goBack()
          }
        }
        return true
      }
    },

    async validate () {
      return this.place?.name != null
    },

    async cancel () {
      await this.goBack()
    }
  },

  created () {
    this.initialOrganizationId = this.place.organizationId
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" :breadcrumbs="breadcrumbs">

    <template #toolbar>
      <div class="row q-gutter-sm">
        <q-btn label="Save" class="primary" unelevated @click="save(true)"></q-btn>
        <q-btn label="Close" unelevated @click="cancel()"></q-btn>
      </div>
    </template>

    <q-banner v-if="inAnotherOrganization && !movingToAnotherOrganization" class="bg-orange-5">
      Warning!
      You are {{ place.isNew ? 'adding' : 'editing' }}
      a {{ getPlaceLabel(place.placeType) }}
      in another organization:
      <b>{{ organization.name }}</b>
    </q-banner>

    <q-banner v-if="movingToAnotherOrganization" class="bg-orange-5">
      Warning!
      You are moving the place to another organization:
      <b>{{ organization.name }}</b>
    </q-banner>

    <div class="row">
      <div class="col-12 col-md-9 col-lg-6 q-pa-md q-gutter-sm">
        <sc-place-editor :place="place"></sc-place-editor>
      </div>
    </div>

  </sc-view>
</template>

<style lang='scss' scoped></style>
