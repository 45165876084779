<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ViewMixin, TabsMixin, EditorMixin } from '@stellacontrol/client-utilities'
import { SecurityRules } from '@stellacontrol/security'
import UserGeneral from './user-general.vue'
import UserPermissions from './user-permissions.vue'
import ChangePassword from './change-password.vue'
import { Secure, Permissions } from '../../components'
import { resolveUser } from './user.resolve'

const name = 'user'

/**
 * User editor
 */
export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    EditorMixin,
    Secure
  ],

  components: {
    'sc-user-general': UserGeneral,
    'sc-user-permissions': UserPermissions,
    'sc-change-password': ChangePassword
  },

  data () {
    return {
      name,
      permissions: null
    }
  },

  computed: {
    // Data of the created or updated organization profile
    ...mapState({
      lastUpdated: state => state.users.lastUpdated
    }),

    ...mapGetters([
      'environment',
      'features',
      'organizations',
      'isSmallScreen',
      'canLoginAsUser'
    ]),

    forms () {
      return [
        'general'
      ]
    },

    // View title
    title () {
      if (this.data.isNew) {
        return `New User in ${this.data.organization.name} Organization`
      } else {
        return this.data.name
      }
    },

    // Returns true if editing user of a selected organization
    isCustomerUser () {
      const { currentOrganization, data } = this
      return data && currentOrganization && data.organizationId !== currentOrganization.id
    },

    // The selected user level
    userLevel () {
      return this.data.level
    },

    // Returns true if came here from organization view
    fromOrganizationRoute () {
      return (this.fromRoute || {}).name === 'organization' || (this.toRoute || {}).query.organizationId
    },

    // Returns identifier of organization where we came from
    fromOrganizationId () {
      return (this.fromRoute || {}).params.id || (this.toRoute || {}).query.organizationId
    },

    // Returns true if currently logged in user is allowed
    // to edit permissions of the edited user
    canEditPermissions () {
      const { isReadOnly } = this
      const { canEditPermissions } = SecurityRules.canEditPermissions(this.currentUser, this.data)
      return !isReadOnly && canEditPermissions
    },

    // Returns true if currently logged in user is allowed
    // to change password of the edited user
    canChangePassword () {
      return !this.data.isNew && this.isAdministrator
    },

    // Indicates whether user receive password reset email
    canResetPassword () {
      return this.canChangePassword && !this.data.isIntegration
    },

    // Indicates whether user can be invited to activate the account
    canInvite () {
      return this.canChangePassword && !this.data.isIntegration
    },

    // Checks whether we can log in as the edited user
    canLoginAs () {
      return this.canLoginAsUser(this.data)
    },

    /**
     * Determines whether user data is read-only
     * for the viewer
     */
    isReadOnly () {
      const { isAdministrator, isSuperAdministrator } = this.currentUser
      const { isIntegration } = this.data

      if (isIntegration) {
        return !isSuperAdministrator
      } else {
        return !isAdministrator
      }
    },

    // View breadcrumbs.
    // If editing user of another organization, breadcrumbs must allow navigation
    // back to the owner organization, instead of my organization
    breadcrumbs () {
      const { organizations, fromOrganizationRoute, fromOrganizationId, getViewTitle } = this

      if (fromOrganizationRoute && fromOrganizationId) {
        const parent = organizations.find(o => o.id === fromOrganizationId)
        const breadcrumbs = [
          {
            name: 'home',
            title: getViewTitle('home')
          },
          {
            name: 'organizations',
            title: getViewTitle('organizations')
          },
          parent
            ? {
              name: 'organization',
              title: parent.name,
              route: 'organization',
              query: { tab: 'users' },
              params: { id: parent.id }
            }
            : undefined,
          {
            name: 'user',
            title: getViewTitle('user')
          }
        ]
        return breadcrumbs
      }
    }
  },

  methods: {
    ...mapActions([
      'showDialog',
      'saveUser',
      'editUser',
      'editUsers',
      'editOrganization',
      'goBack',
      'resetPassword',
      'changePassword',
      'inviteUser',
      'gotoRoute',
      'loginToOrganization'
    ]),

    // Determines whether the specified tab can be selected.
    isTabVisible (tab) {
      let result = true
      switch (tab) {
        case 'permissions':
          result = this.canEditPermissions
          break
      }
      return result
    },

    // Populates the view
    populate () {
      const { data: { isNew } } = this

      this.reset()
      if (isNew) {
        this.selectTab('general')
      }
    },

    // Saves changes
    async save () {
      if (this.isReadonly) {
        return
      }

      // Return to general tab to validate inputs
      if (await this.validate()) {
        // Make sure the permissions have been stored in the user
        this.permissions.apply()
        // Save
        const { isNew } = this.data
        await this.saveUser({ user: this.data })
        if (isNew) {
          await this.editUser({ user: this.lastUpdated })
        }
        return true

      } else {
        await this.selectTab('general')
      }
    },

    // Cancels changes and goes back to the previous view
    async cancel () {
      if (this.isCustomerUser) {
        await await this.editOrganization({ organization: this.data.organization, tab: 'users' })
      } else {
        await this.editUsers()
      }
    },

    // Resets the view to original shape,
    // as when another organization is loaded
    async reset () {
      // Switch to default tab if one specified in URL has become invisible
      this.ensureTab()
      // Instantiate the permissions controller and populate with features
      // and permissions of the currently edited principal
      this.permissions = new Permissions(this.features, this.data, this.environment, this.currentUser, this.guardian)
    },

    // Logs into the user's organization and impersonate the user
    loginAs () {
      const { canLoginAs, data: user } = this
      if (canLoginAs) {
        return this.loginToOrganization({
          user,
          organization: user.organization,
          confirm: !this.isSuperAdministrator
        })
      }
    }
  },

  watch: {
    userLevel () {
      const { data, permissions } = this
      // When editing new user and level changed to `regular`, pre-select all permissions
      if (permissions && !data.isAdministrator) {
        permissions.setCanUseAll(true)
      }
    }
  },

  async created () {
    await this.populate()
  },

  // Reload data on navigation to another user
  async beforeRouteUpdate (to, from, next) {
    if (await resolveUser({ from, to })) {
      this.reset()
      next()
    }
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" v-if="isLoggedIn" :breadcrumbs="breadcrumbs">

    <!-- When in mobile mode, show buttons inside the topbar -->
    <teleport v-if="isSmallScreen" to="#topbar-items">
      <span class="text-body1 q-mx-sm text-white">
        {{ title }}
      </span>

      <q-space>
      </q-space>
      <div class="row items-center no-wrap q-gutter-sm">
        <q-btn outline style="color: white" icon="arrow_back"
          @click="gotoRoute({ name: 'users' })" />
      </div>
    </teleport>

    <template #toolbar>
      <div class="q-gutter-xs">
        <q-btn label="Change Password" unelevated no-caps v-if="canChangePassword"
          @click="changePassword({ user: data })">
          <sc-tooltip :text="`Set a new password for ${data.fullName}`"></sc-tooltip>
        </q-btn>
        <q-btn label="Reset Password" no-caps unelevated v-if="canResetPassword"
          @click="resetPassword({ user: data })">
          <sc-tooltip
            :text="`Send e-mail with password reset instructions to ${data.fullName}`"></sc-tooltip>
        </q-btn>
        <q-btn label="Invite User" no-caps unelevated v-if="canInvite"
          @click="inviteUser({ user: data })">
          <sc-tooltip :text="`Send account activation e-mail to ${data.fullName}`"></sc-tooltip>
        </q-btn>
        <q-btn label="Log in as user" v-if="canLoginAs" unelevated no-caps @click="loginAs()">
        </q-btn>
        <q-btn label="Save" class="primary q-ml-lg" unelevated @click="save()"
          v-if="!isReadOnly"></q-btn>
        <q-btn label="Close" unelevated @click="cancel()"></q-btn>
      </div>
    </template>

    <sc-tabs :model-value="viewTab" @update:model-value="tab => selectTab(tab)" :tabs="viewTabs">
      <q-tab name="general" icon="create" label="Details" :ripple="false"></q-tab>
      <q-tab name="permissions" v-if="isTabVisible('permissions')" icon="security"
        label="Permissions" :ripple="false"></q-tab>
    </sc-tabs>

    <sc-tab-panels :model-value="viewTab" :tabs="viewTabs">
      <q-tab-panel name="general">
        <sc-user-general :data="data" :permissions="permissions" ref="general"></sc-user-general>
      </q-tab-panel>
      <q-tab-panel v-if="canEditPermissions" name="permissions">
        <sc-user-permissions :data="data" :permissions="permissions"
          ref="permissions"></sc-user-permissions>
      </q-tab-panel>
    </sc-tab-panels>

    <sc-change-password></sc-change-password>
  </sc-view>
</template>
