import { safeParseInt, safeParseFloat } from '@stellacontrol/utilities'
import { PortSenseMode } from './portsense-mode'

/**
 * Details of other vital parameters of a device
 */
export class DeviceStatusHealth {
  /**
   * Initializes the instance
   * @param serialNumber Device serial number
   * @param mega Live device data retrieved from Shadow API
   * @param data Initial values of the properties
   */
  constructor (serialNumber, mega, data = {}) {
    this.serialNumber = serialNumber
    Object.assign(this, data)
    if (mega) {
      this.parse(mega)
    }
  }

  /**
   * Creates am instance populated with deserialized data
   */
  static from (data = {}) {
    const status = new DeviceStatusHealth()
    Object.assign(status, data)
    return status
  }

  /**
   * MEGA message version
   * @type {String}
   */
  megaVersion

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * Last registered temperature of a device
   * @type {Number}
   */
  temperature

  /**
   * Indicates whether temperature of a device is available
   * @type {Boolean}
   */
  hasTemperature

  /**
   * Indicates whether PortSense indicator is available
   * @type {Boolean}
   */
  hasPortSense

  /**
   * Current work mode of the PortSense feature
   * @type {Number}
   */
  portSenseMode

  /**
   * Determines whether the antenna LEDs are on.
   * If PortSense feature is not enabled, `undefined` is returned.
   * @type {Boolean?}
   */
  get antennaLEDsOn () {
    return this.hasPortSense
      ? (this.portSenseMode === PortSenseMode.AntennaLEDsOn)
      : undefined
  }

  /**
   * Status of external antenna on the device, as reported by PortSense indicator
   * @type {Boolean}
   */
  externalAntenna

  /**
   * Status of internal antennae, as reported by PortSense indicator
   * @type {Array[Boolean]}
   */
  internalAntenna

  /**
   * Indicates whether device is currently running on battery power
   * @type {Boolean}
   */
  batteryInUse

  /**
   * Number of TCP errors since last reboot
   * @type {Number}
   */
  tcpErrorCount

  /**
   * Number of hardware reboots since last reset
   * @type {Number}
   */
  hardwareRebootCount

  /**
   * Number of software reboots since last reset
   * @type {Number}
   */
  softwareRebootCount

  /**
   * Fan speed level
   * @type {Number}
   */
  fanSpeed

  /**
   * Fan frequency in Hz
   * @type {Number}
   */
  fanFrequency

  /**
   * Desired temperature for the fan to keep
   * @type {Number}
   */
  desiredTemperature

  /**
   * LCD health
   * @type {Boolean}
   */
  lcdOK

  /**
   * LCD brightness
   * @type {Number}
   */
  lcdBrightness

  /**
   * LCD working mode
   * @type {Number}
   */
  lcdMode

  /**
   * Test Tool usage statistics
   * @type {Dictionary<String, any>}
   */
  ttUsage

  /**
   * Determines health of the device
   * @param mega Raw mega
   */
  parse (mega = {}) {
    this.megaVersion = mega['@version']
    if (!this.megaVersion) return
    this.temperature = safeParseFloat(mega['temperature'])
    this.hasTemperature = this.temperature != null
    this.batteryInUse = Boolean(mega['battery'])
    this.tcpErrorCount = safeParseInt(mega['error_count'])
    this.hardwareRebootCount = safeParseInt(mega['reboot_count_hw'])
    this.softwareRebootCount = safeParseInt(mega['reboot_count_sw'])

    // Fan status
    this.fanSpeed = safeParseInt(mega['fan_speed_setting'])
    this.fanFrequency = safeParseInt(mega['fan_speed_hz'])
    this.desiredTemperature = safeParseInt(mega['_fan_temp'])

    // LCD status
    this.lcdMode = safeParseInt(mega['_lcd_lock'])
    this.lcdBrightness = safeParseInt(mega['_lcd_dim'])
    this.lcdOK = safeParseInt(mega['lcd_broken']) != 1

    // PortSense flags informing about the status of the internal and external antennas
    this.hasPortSense = Boolean(mega['has_portsense'])
    const portSenseFlags = (mega['status_portsense'] || '').split(',').map(flag => Boolean(safeParseInt(flag, 0)))
    this.externalAntenna = Boolean(portSenseFlags[0])
    this.internalAntenna = portSenseFlags.slice(1, portSenseFlags.length - 1)
    this.portSenseMode = mega['_portsense_mode']

    // Parse TestTool usage stats
    const ttUsage = {
      deviceOn: mega['tt_usage_switchon'],
      spectrum: mega['tt_usage_spectrum'],
      cellSurvey: mega['tt_usage_cellsurvey'],
      neighbourCells: mega['tt_usage_neighbours'],
      beacon: mega['tt_usage_beacon'],
      cableTest: mega['tt_usage_cabletest'],
      agcTest: mega['tt_usage_agctest'],
      diagnostics: mega['tt_usage_diagnostics'],
      fullSurvey: mega['tt_usage_fullsurvey'],
      help: mega['tt_usage_help'],
      settings: mega['tt_usage_settings'],
      fullSurveySetup: mega['tt_usage_fullsurveysetup'],
      fwUpdatePrompt: mega['tt_usage_fwupdateprompt']
    }

    if (Object.values(ttUsage).some(v => v != null)) {
      this.ttUsage = ttUsage
    }
  }

  /**
   * Updates the specified device with the parsed status
   * @param device Device to update with this status
   */
  update (device) {
    if (device) {
      // lint-disable-line no-empty
    }
  }
}

// Default alert threshholds
DeviceStatusHealth.Threshold = {
  // Temperature alert thresholds
  Temperature: {
    warning: 50,
    alert: 75
  }
}

