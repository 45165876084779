<script>
export default {
  props: {
    // Section name
    name: {
      type: String,
      required: true
    },
    // Section title
    title: {
      type: String,
      required: true
    },
    // Indicates a sub-section within a section
    subsection: {
      type: Boolean,
      default: false
    },
    // Indicates whether the section can be collapsed/expanded,
    // or rather it should remain open at all times
    collapsible: {
      type: Boolean,
      default: false
    },
    // Section collapse status, dictionary
    collapsed: {
      type: Object,
      default: () => { }
    }
  },

  computed: {
    // Section header class
    headerClass () {
      const { subsection, name } = this
      const color = subsection ? 'bg-grey-2' : 'bg-indigo-1'
      return {
        [name]: true,
        [color]: true
      }
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    // Toggles a section
    toggle () {
      const { collapsible, name: section } = this
      if (collapsible) {
        this.$emit('toggle', { section })
      }
    }
  }
}

</script>

<template>
  <section :class="{ subsection }">
    <q-banner class="header" :class="headerClass" :dense="subsection" @click="toggle()">
      <header class="row items-center">
        <span>
          {{ title }}
        </span>
        <q-space>
        </q-space>
        <slot name="toolbar">
        </slot>
        <q-icon v-if="collapsible" color="indigo-6" :name="collapsed[name] ? 'chevron_right' : 'expand_more'"
          size="sm" class="q-ml-md">
        </q-icon>
      </header>
    </q-banner>

    <main v-if="!collapsed[name]">
      <slot>
      </slot>
    </main>
  </section>
</template>

<style lang="scss" scoped>
section {
  .header {
    cursor: pointer;
  }

  margin-bottom: 1px;
}
</style>
