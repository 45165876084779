import { mapState } from 'vuex'
import { PlannerMode } from '@stellacontrol/planner'
import styles from '../item-properties/input-styles'

export default {
  props: {
    // Plan renderer
    renderer: {
    },

    // Edited layer
    layer: {
    },

    // List of currently selected items
    selectedItems: {
      default: () => []
    },

    // Indicates whether the component is now enabled for editing
    enabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      // Default styles for controls
      styles,
    }
  },

  computed: {
    ...mapState({
      // Edited plan
      plan: state => state.planner.plan,
      // Planner editing mode
      mode: state => state.planner.mode
    }),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },

    // Determines whether the planner allows only a limited feature set
    isRegularMode () {
      return this.mode === PlannerMode.Regular
    },

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.mode === PlannerMode.ReadOnly
    },

    // Items on the layer
    items () {
      const { layer, renderer } = this
      return layer ? renderer.getLayerItems(layer) : []
    },

    // Indicates whether there are any items
    hasItems () {
      return this.items?.length > 0
    },

    // Layout
    layout () {
      return this.renderer.layout
    },

    // Floor
    floor () {
      return this.renderer.floor
    },

    // Background properties
    background () {
      return this.renderer.floor.background
    },

    // Radiation properties
    radiation () {
      return this.renderer.floor.radiation
    },
  },

  emits: [
    'action'
  ],

}
