<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  methods: {
    ...mapMutations([
      'selectInventoryItem'
    ]),

    select (value, event = {}) {
      const { item } = this
      const isSelected = Boolean(value)
      const multiSelect = Boolean(event.shiftKey)
      this.selectInventoryItem({ item, isSelected, multiSelect })
      this.$emit('item-selected', { item, isSelected, multiSelect })
    }
  }
}
</script>

<template>
  <q-checkbox
    dense
    color="indigo-5"
    :model-value="item.isSelected"
    @update:model-value="(value, event) => select(value, event)">
  </q-checkbox>
</template>

<style>
</style>