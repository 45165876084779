<script>
import { mapActions } from 'vuex'
import { formatDateTime } from '@stellacontrol/utilities'
import { AntennaName, AntennaOrientationName, Note } from '@stellacontrol/model'
import { getScanSelectedOperators } from '@stellacontrol/devices'
import { Secure } from '@stellacontrol/security-ui'

/**
 * Scan Summary
 */
export default {
  mixins: [
    Secure
  ],

  props: {
    // Section collapse status, dictionary
    collapsed: {
      type: Object,
      default: () => { }
    },
    // Data file
    file: {
    }
  },

  data () {
    return {
      // Tree nodes
      nodes: [],

      // Scan note
      note: null
    }
  },

  computed: {
    // Device
    device () {
      return this.file?.entity
    },

    // Scan results
    results () {
      return this.file?.data?.results
    },

    // Indicates whether any scan results are available
    hasResults () {
      return this.results != null
    },

    // Scan summary
    summary () {
      const { file, device, canEditNotes } = this
      const { isTestTool } = device || {}
      const { antennaOrientation, antennaType, wasOutdoorScan, wasRepeaterPresent, distanceFromRepeater, notes } = this.results
      const places = file.hasLinks
        ? file.links.map(l => `${l.organization.name} - ${l.principal.name}`).join(', ')
        : null

      const items = [
        { label: 'Device', value: device?.serialNumber },
        { label: 'Firmware Version', value: device?.getFirmwareVersion() },
        { label: 'Place', value: places },
        { label: 'Date', value: formatDateTime(file.createdAt) }
      ]

      if (isTestTool) {
        items.push(...[
          { label: 'Location', value: wasOutdoorScan ? 'Outdoor' : 'Indoor' },
          { label: 'Antenna Type', value: AntennaName[antennaType] || '-' },
          { label: 'Antenna Direction', value: AntennaOrientationName[antennaOrientation] || '-' },
          { label: 'Repeater Status', value: wasRepeaterPresent ? 'On' : 'Off' },
          { label: 'Distance from Internal Antennas', value: wasRepeaterPresent ? `${distanceFromRepeater}m` : null }
        ])
      }

      if (!canEditNotes) {
        items.push({ label: 'Notes', value: notes })
      }

      return items.filter(item => item.value != null)
    },

    // Scanned operators
    operators () {
      return Object
        .entries(this.results?.operatorNames)
        .map(([id, name]) => ({ id, name }))
    },

    // Scanned operators
    selectedOperators () {
      const { file } = this
      return getScanSelectedOperators(file)
    },

    // All operators
    allOperators () {
      const { file } = this
      return Object.values(file.operatorNames)
    },

    // Checks whether the operator was selected for cell scan
    operatorSelected () {
      return operator => this.selectedOperators?.includes(operator)
    },

    // Checks whether any operator was selected for cell scan
    anyOperatorSelected () {
      return this.selectedOperators?.length > 0
    },

    // Determines whether the current user can edit notes
    canEditNotes () {
      return this.canUse('edit-device-notes')
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    ...mapActions([
      'saveAttachmentNote'
    ]),

    // Toggles a section
    toggle ({ section }) {
      this.$emit('toggle', { section })
    },

    // Populates the view
    populate () {
      this.note = this.file.notes[0]?.text || ''
    },

    // Saves the attachment note
    async saveNote (text) {
      const { file: attachment } = this
      const note = attachment?.notes[0] || new Note()
      note.text = text
      await this.saveAttachmentNote({ attachment, note })
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <div class="summary" v-if="hasResults">
    <table class="scan">
      <tr v-for="item in summary">
        <td>
          {{ item.label }}
        </td>
        <td>
          {{ item.value }}
        </td>
      </tr>

      <tr v-if="anyOperatorSelected">
        <td>
          Operators
        </td>
        <td>
          <div v-for="operator in operators" class="row">
            <q-checkbox readonly :model-value="operatorSelected(operator.name)" size="q-sm" dense
              class="q-mr-sm">
            </q-checkbox>
            <span>
              {{ operator.name }}
            </span>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="canEditNotes" class="notes">
      <q-input type="textarea" square outlined class="note" label="Notes and remarks" v-model="note"
        @update:model-value="value => saveNote(value)" debounce="1000">
      </q-input>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.summary {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  gap: 8px;

  .notes {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    margin-right: 8px;

    .note {
      flex: 1;
    }

    :deep(.q-field__control) {
      flex: 1;
      height: 100%;
      width: 100%;
    }
  }

  table {
    border-collapse: collapse;

    tr:hover {
      background-color: #fafafa;
    }

    td,
    th {
      min-width: 120px;
      border: solid #0000001f 1px;
      text-align: left;
      vertical-align: middle;
      padding: 8px;
    }
  }
}
</style>
