<script>
import { mapGetters, mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'

export default {
  name: 'ApplicationUser',

  mixins: [
    Secure
  ],

  props: {
    // Indicates whether navigation links etc. are enabled
    enabled: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters([
      'loggedInAsMaster',
      'masterUser',
      'currentUser',
      'currentOrganization',
      'masterOrganization'
    ]),

    avatarTooltip () {
      const { masterUser, masterOrganization } = this
      if (masterUser && masterOrganization) {
        return `You've logged as ${masterUser.name} from ${masterOrganization.name}. Click here to go back to your organization.`
      }
    },

    avatarIcon () {
      if (this.loggedInAsMaster) {
        return 'arrow_back'
      }
      if (this.currentOrganization.icon) {
        return this.currentOrganization.icon
      }
      return this.currentUser.isAdministrator ? 'supervisor_account' : 'person'
    }
  },

  methods: {
    ...mapActions([
      'loginToOrganization',
      'collapseSidebar',
      'gotoEmpty'
    ]),

    // Logs in back to master organization,
    // from which master user has logged in on behalf of the current organization
    async backToResellerOrganization () {
      const { loggedInAsMaster, masterOrganization } = this
      if (loggedInAsMaster) {
        await this.gotoEmpty()
        await this.collapseSidebar({ persistent: false })
        await this.loginToOrganization({
          organization: masterOrganization,
          confirm: false
        })
      }
    }
  }
}
</script>

<template>
  <main>
    <div class="content full">
      <div class="row items-center no-wrap">
        <div class="avatar">
          <q-avatar v-if="loggedInAsMaster" size="32px" text-color="white" color="orange-7"
            :icon="avatarIcon" @click="backToResellerOrganization()" style="cursor: pointer">
            <sc-tooltip anchor="bottom right" max-width="300px">
              Return to master organization
            </sc-tooltip>
          </q-avatar>

          <router-link v-else-if="enabled" :to="{ name: 'login' }">
            <q-avatar size="32px" text-color="white" :icon="avatarIcon" color="indigo-6">
            </q-avatar>
            <sc-tooltip text="Log out" nowrap></sc-tooltip>
          </router-link>

          <div v-else>
            <q-avatar size="32px" text-color="white" :icon="avatarIcon" color="indigo-6">
            </q-avatar>
          </div>
        </div>

        <div class="user">
          <label name="user">
            <router-link v-if="enabled" :to="{ name: 'my-account' }"
              class="text-subtitle1 user-link">
              {{ currentUser.fullName || currentUser.name }}
            </router-link>
            <span v-else class="text-subtitle1 user-link">
              {{ currentUser.fullName || currentUser.name }}
            </span>
          </label>

          <label name="organization" class="text-indigo-3 text-subtitle1">
            {{ currentOrganization.name }}
            <sc-tooltip v-if="!currentOrganization.profile.isRegularOrganization">
              {{ currentOrganization.profile.fullName }}
            </sc-tooltip>
          </label>
        </div>
      </div>
    </div>

    <div class="content compact">
    </div>

  </main>
</template>

<style lang="scss" scoped>
main {
  flex: 0;
  display: flex;
  flex-direction: column;

  .content {
    flex: 0;
    display: flex;
    flex-direction: column;

    .avatar {
      margin-right: 12px;
    }

    .user {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .user {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.full {
      display: flex;
    }

    &.compact {
      display: none;
    }

    .user-link {
      text-decoration: none;
      color: white;

      &:hover {
        color: #9fa8da;
      }
    }
  }
}
</style>