import { TagCategory, Note } from '@stellacontrol/model'
import { createState } from './device-dashboard.state'

export const mutations = {
  /**
   * Initializes the Device Dashboard view
   * @param {Organization} organization Organization to which the viewed device belongs
   * @param {OrganizationGuardian} organizationGuardian Organization's guardian
   * @param {Place} place Place to which the viewed device belongs
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   * @param {PremiumService} pendingPremiumService Pending premium service associated with the device
   * @param {Array[AlertConfiguration]} alertConfigurations Alert configurations for the viewed device
   */
  initializeDeviceDashboard (state, { organization, organizationGuardian, place, device, devicePart, pendingPremiumService, alertConfigurations } = {}) {
    state.organization = organization
    state.organizationGuardian = organizationGuardian
    state.place = place
    state.device = device
    state.devicePart = devicePart
    state.pendingPremiumService = pendingPremiumService
    state.alertConfigurations = alertConfigurations
    state.scans = null
  },

  /**
   * Stores the scan results sent by device
   * @param {Device} device Device
   * @param {Array[Attachment]} scans Scan results
   * @param {Array} scanErrors Scan errors
   */
  storeDeviceScanResults (state, { device, scans = [], scanErrors = [] } = {}) {
    if (!device) return
    if (state.device?.id !== device?.id) return

    state.scans = scans
    state.scanErrors = scanErrors
  },

  /**
   * Stores the modified alert configurations of the device
   * @param {Device} device Device
   * @param {Array[AlertConfiguration]} alertConfigurations Alert configurations to update
   */
  storeDeviceAlertConfigurations (state, { device, alertConfigurations } = {}) {
    if (device?.serialNumber === state.device?.serialNumber) {
      state.alertConfigurations = alertConfigurations
    }
  },

  /**
   * Starts watching device scan results
   * @param {Clock} clock Watch clock
   */
  watchDeviceScanResults (state, { clock } = {}) {
    state.scansWatcher?.stop()
    state.scansWatcher = clock
  },

  /**
   * Stops watching device scan results
   */
  unwatchDeviceScanResults (state) {
    state.scansWatcher?.stop()
    state.scansWatcher = null
  },

  /**
   * Updates a device note
   * @param {Device} device Device
   * @param {Note} note Note to update
   * @param {User} user User updating the notes
   */
  saveDeviceNote (state, { device: { id }, note, user } = {}) {
    if (state.device?.id === id && note) {
      Note.update(state.device, note, user)
    }
  },

  /**
   * Removes a note from device
   * @param {Device} device Device
   * @param {Note} note Note to remove
   * @param {User} user User removing the notes
   */
  removeDeviceNote (state, { device: { id }, note, user } = {}) {
    if (state.device?.id === id && note) {
      Note.remove(state.device, note, user)
    }
  },

  /**
   * Adds device tag
   * @param {Device} device Device to add the tag to
   * @param {Tag} tag Tag to add
   */
  addDeviceTag (state, { device: { id }, tag }) {
    if (state.device?.id === id) {
      const { name, userId } = tag
      if (!state.device.hasTag({ name, userId })) {
        state.device.addTag({ name, userId, category: TagCategory.Device })
      }
    }
  },

  /**
   * Removes device tag
   * @param {Device} device Device to removes the tag from
   * @param {Tag} tag Tag to remove
   */
  removeDeviceTag (state, { device: { id }, tag }) {
    if (state.device?.id === id) {
      const { name, userId } = tag
      if (state.device.hasTag({ name, userId })) {
        state.device.removeTag({ name, userId })
      }
    }
  },

  /**
   * Triggered when attachment note is updated.
   * If note is related to a scan received from the device, update the device notes accordingly.
   * @param {Attachment} attachment Attachment
   * @param {Note} note Note to update
   */
  saveAttachmentNote (state, { attachment: { id }, note } = {}) {
    for (const scan of state.scans || []) {
      if (scan.id === id) {
        scan.raw.setNote(note)
      }
    }
  },

  /**
   * Triggered when attachment note is updated.
   * If note is related to a scan received from the device, update the device notes accordingly.
   * @param {Attachment} attachment Attachment
   * @param {Note} note Removed note
   * @param {User} user User removing the note
   */
  removeAttachmentNote (state, { attachment: { id }, note } = {}) {
    for (const scan of state.scans || []) {
      if (scan.id === id) {
        scan.raw.removeNote(note)
      }
    }
  },

  reset (state) {
    Object.assign(state, createState())
  }
}
