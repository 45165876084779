import { isEnum, parseDate } from '@stellacontrol/utilities'
import { Entity } from '../common'
import { DeviceSIMProvider } from './device-sim-provider'

/**
 * Device SIM card
 */
export class DeviceSIM extends Entity {
  constructor (data = {}) {
    super()
    this.assign(data, {
      registeredAt: parseDate,
      activatedAt: parseDate,
      deactivatedAt: parseDate
    })
    if (!isEnum(DeviceSIMProvider, this.provider)) throw new Error(`Invalid SIM provider ${this.provider}`)
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
  }

  /**
   * SIM Provider
   * @type {DeviceSIMProvider}
   */
  provider

  /**
   * SIM batch code (BIC)
   * @type {String}
   */
  batch

  /**
   * Internal SIM identifier in the provider's system
   * @type {String}
   */
  id

  /**
   * SIM number
   * @type {String}
   */
  ICCID

  /**
   * All provider-specific details of the SIM card
   * @type {Object}
   */
  details

  /**
   * Date and time of SIM registration
   * @type {Date}
   */
  registeredAt

  /**
   * Identifier of user who registered the SIM
   * @type {String}
   */
  registeredBy

  /**
   * Indicates whether the SIM has been registered
   * @type {Boolean}
   */
  get isRegistered () {
    return this.registeredAt != null
  }

  /**
   * Date and time of SIM activation
   * @type {Date}
   */
  activatedAt

  /**
   * Identifier of user who activated the SIM
   * @type {String}
   */
  simActivatedBy

  /**
   * Date and time of SIM deactivation
   * @type {Date}
   */
  deactivatedAt

  /**
   * Identifier of user who deactivated the SIM
   * @type {String}
   */
  deactivatedBy

  /**
   * Indicates whether the SIM has been activated
   * @type {Boolean}
   */
  get isActive () {
    return this.activatedAt != null && this.deactivatedAt == null
  }

  /**
   * Indicates whether the SIM has been deactivated
   * @type {Boolean}
   */
  get isInactive () {
    return this.activatedAt == null || this.deactivatedAt != null
  }

  /**
   * Date and time when endpoint associated with the device and SIM has been enabled
   * @type {Date}
   */
  endpointEnabledAt

  /**
   * Identifier of user who enabled the endpoint
   * @type {String}
   */
  endpointEnabledBy

  /**
   * Date and time when endpoint has been disabled
   * @type {Date}
   */
  endpointDisabledAt

  /**
   * Identifier of user who disabled the endpoint
   * @type {String}
   */
  endpointDisabledBy

  /**
   * Indicates whether the endpoint is enabled
   * @type {Boolean}
   */
  get isEndpointEnabled () {
    return this.endpointEnabledAt != null && this.endpointDisabledAt == null
  }

  /**
   * Indicates whether the endpoint is disabled
   * @type {Boolean}
   */
  get isEndpointDisabled () {
    return this.endpointEnabledAt == null || this.endpointDisabledAt != null
  }

  /**
   * Service profile for the device
   * @type {String}
   */
  serviceProfile

  /**
   * Tariff profile for the device
   * @type {String}
   */
  tariffProfile
}
