import { capitalize } from '@stellacontrol/utilities'
import { ListViewMode, Confirmation, Notification, dispatchParallel } from '@stellacontrol/client-utilities'
import { DeviceAPI, SecurityAPI, CommonAPI } from '@stellacontrol/client-api'
import { Organization, Tag, Tags, TagCategory, NoteCategory } from '@stellacontrol/model'
import { SecurityRules } from '@stellacontrol/security'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initialize the organizations store during applet initialization
   */
  async initializeApplet ({ dispatch }) {
    // Initialize lists
    await dispatch('initializeList', [
      { name: 'organizations', viewMode: ListViewMode.Normal },
      { name: 'organization-users', sortBy: 'name', viewMode: ListViewMode.Normal },
      { name: 'child-organizations', sortBy: 'name', viewMode: ListViewMode.Normal },
      { name: 'organization-devices', sortBy: 'name', viewMode: ListViewMode.Normal }
    ])
  },

  /**
   * Initialize the organizations view before navigating
   */
  async initializeOrganizationsView ({ commit, dispatch }) {
    await dispatchParallel([
      'requireOrganizationProfiles',
      'requireOrganizations',
      'requireDevices',
      'requireOrganizations',
      'requireUsers'
    ])

    const collapsedOrganizations = await dispatch('getUserPreference', {
      name: 'organizations-collapsed',
      defaultValue: []
    })

    commit('initializeOrganizationsView', { collapsedOrganizations })
  },

  /**
   * Retrieves organizations to edit.
   * We only retrieve organization's child organizations,
   * unless super organization is now editing.
   */
  async getOrganizations ({ commit, dispatch, getters }) {
    await dispatch('loading')
    const { currentOrganization: { id, isSuperOrganization } = {} } = getters
    if (id) {
      const parentOrganizationId = isSuperOrganization ? undefined : id
      const organizations = await SecurityAPI.getOrganizations({
        parentOrganizationId
      })
      if (organizations) {
        commit('storeOrganizations', { organizations })
      }
      await dispatch('done')
      return organizations
    }
  },

  /**
   * Retrieves organization if not retrieved yet
   */
  async requireOrganizations ({ state, dispatch }) {
    if (state.items) {
      return state.items
    } else {
      return await dispatch('getOrganizations')
    }
  },

  /**
   * Retrieves all organizations.
   */
  async getAllOrganizations ({ commit, dispatch }) {
    await dispatch('loading')
    const items = await SecurityAPI.getOrganizations()
    if (items) {
      commit('storeAllOrganizations', { items })
    }
    await dispatch('done')
    return items
  },

  /**
   * Retrieves all organization if not retrieved yet
   */
  async requireAllOrganizations ({ state, dispatch }) {
    if (state.allItems) {
      return state.allItems
    } else {
      return await dispatch('getAllOrganizations')
    }
  },

  /**
   * Retrieves child organizations of a specified organization.
   * @param id Organization identifier
   */
  async getChildOrganizations ({ dispatch }, { id } = {}) {
    if (!id) throw new Error('Organization identifier is required')

    await dispatch('loading')
    const items = await SecurityAPI.getOrganizations({ parentOrganizationId: id })
    await dispatch('done')
    return items
  },

  /**
   * Retrieves devices owned by a specified organization.
   * @param id Organization identifier
   */
  async getOrganizationDevices ({ dispatch }, { id } = {}) {
    if (!id) throw new Error('Organization identifier is required')

    await dispatch('loading')
    const items = await DeviceAPI.getOwnDevices({ id })
    await dispatch('done')
    return items
  },

  /**
   * Retrieves the specified organization with all details such as permissions,
   * creator, organization profile and parent organizations.
   * @param id Organization identifier
   */
  async getOrganization ({ commit, dispatch }, { id } = {}) {
    if (!id) throw new Error('Organization identifier is required')

    await dispatch('loading')
    const organization = await SecurityAPI.getOrganization({
      id,
      withDetails: true,
      withParents: true,
      withChildOrganizations: true,
      withPlaces: true
    })

    if (organization) {
      commit('storeOrganization', { organization })
    }

    await dispatch('done')
    return organization
  },

  /**
   * Retrieves the current organization
   */
  async getCurrentOrganization ({ getters }) {
    return getters.currentOrganization
  },

  /**
   * Creates a new organization under the specified parent organization (no saving yet!)
   * @param {Organization} organization Initial properties of the organization
   * @param {Organization} parentOrganization Parent organization
   * @returns {Promise<Organization>}
   */
  async newOrganization ({ dispatch, getters }, { organization, parentOrganization } = {}) {
    await dispatch('loading')

    const { currentOrganization, defaultSite, myDefaultOrganizationProfile, availableOrganizationProfiles } = getters

    // Currently logged in organization is default parent,
    // unless specified otherwise
    parentOrganization = parentOrganization || currentOrganization
    if (!parentOrganization) {
      throw new Error('Organization must have a parent organization assigned')
    }

    // Determine default profile for the newly created organization.
    const profile = myDefaultOrganizationProfile || availableOrganizationProfiles[0]
    if (!profile) {
      Notification.warning({
        message: 'You cannot create customers. Please contact your reseller!'
      })
      return
    }

    // Determine organization website
    const site = parentOrganization.site || defaultSite.name

    // If creator has rights to use simulated devices,
    // suggest to create a sample place with simulated devices in it
    let createSampleData
    if (getters.canUse('add-simulated-devices')) {
      createSampleData = {
        createPlace: false,
        placeName: 'First Building',
        createDevices: false,
        deviceCount: 2
      }
    }

    // Create new organization, assign to default profile
    const data = new Organization({
      ...organization,
      parentOrganization,
      parentOrganizationId: parentOrganization.id,
      profile,
      profileId: profile.id,
      site,
      createSampleData
    })
    await dispatch('done')
    return data
  },

  /**
   * Checks whether the specified organization exists
   * @param name Organization name
   */
  async organizationExists (_, { name } = {}) {
    if (name) {
      const { id, exists } = await SecurityAPI.organizationExists({ name })
      return { id, exists }
    } else {
      return { exists: false }
    }
  },

  /**
   * Shows a list of organizations where organizations can be added and edited
   */
  async editOrganizations ({ dispatch }) {
    await dispatch('gotoRoute', { name: AppletRoute.Organizations })
  },

  /**
   * Edits a new organization
   */
  async createOrganization ({ dispatch }) {
    await dispatch('gotoRoute', { name: AppletRoute.Organization, params: { id: 'new' } })
  },

  /**
   * Edits a new child organization
   * @param {Organization} organization Parent organization of the newly created child organization
   */
  async createChildOrganization ({ dispatch }, { organization } = {}) {
    await dispatch('gotoRoute', { name: AppletRoute.Organization, params: { id: 'new' }, query: { parent: organization.id, tab: 'general' } })
  },

  /**
   * Edits an existing organization
   * @param {Organization} organization Organization to edit
   * @returns {Promise}
   */
  async editOrganization ({ dispatch }, { organization: { id }, tab } = {}) {
    if (!id) throw new Error('Organization identifier is required')

    await dispatch('gotoRoute', { name: AppletRoute.Organization, params: { id }, query: { tab } })
  },

  /**
   * Saves an organization
   * @param {Organization} organization Organization to save
   * @param {Boolean} createAdministratorAccount If true and creating a new organization, administrator account will be automatically created
   * @param {Boolean} silent If true, no notifications will be shown
   * @returns {Promise<Organization>} Saved organization
   */
  async saveOrganization ({ commit, dispatch, getters }, { organization, createAdministratorAccount = false, silent } = {}) {
    if (!organization) throw new Error('Organization is required')

    const { currentUser } = getters
    const { isNew } = organization

    if (isNew) {
      // Create new organization
      let message = `Creating customer ${organization.name}${createAdministratorAccount ? ' and administrator<br>account ' + organization.email : ''} ...`
      await dispatch('busy', { message, data: organization, silent })
      const result = await SecurityAPI.createOrganization({ organization, createAdministratorAccount })

      if (result.organization) {
        organization.id = result.organization.id
        await dispatch('saveFavoriteOrganization', { organization, user: currentUser })
        commit('storeOrganization', { organization: result.organization })
        message = `Customer ${organization.name} has been saved.`
        if (createAdministratorAccount) {
          if (result.user) {
            message = message + ` <br>Administrator account ${result.user.name} has been created`
          } else {
            message = message + ` <br>Administrator account ${organization.email} COULD NOT be created`
          }
        }
        await dispatch('done', { message, silent })
      }

      if (result.error) {
        await dispatch('done', { silent })
        Notification.error({
          message: result.message,
          details: 'Error saving the customer', silent
        })
      }

      return result.organization

    } else {
      // Update favorite status and save existing organization
      let message = `Saving customer ${organization.name} ...`
      await dispatch('busy', { message, data: organization, silent })
      await dispatch('saveFavoriteOrganization', { organization, user: currentUser })
      organization = await SecurityAPI.updateOrganization({ organization })
      const { error } = organization

      if (error) {
        await dispatch('done', { silent })
        Notification.error({
          message: organization.message,
          details: 'Error saving customer', silent
        })

      } else {
        commit('storeOrganization', { organization })

        message = `Customer ${organization.name} has been saved.`
        await dispatch('done', { message, silent })
      }

      return organization
    }
  },

  /**
   * Saves organization notes
   * @param {Organization} organization Organization whose notes to save
   * @param {Boolean} silent If true, no notifications will be shown
   * @returns {Promise}
   */
  async saveOrganizationNotes ({ commit }, { organization, silent } = {}) {
    const { id, notes = [] } = organization

    for (const note of notes) {
      note.entityId = organization.id
      note.category = NoteCategory.Organization
      note.isPrivate = true
      await CommonAPI.saveNote({ note })
    }

    organization.notes = await CommonAPI.getNotesOf({ id })
    Notification.success({
      message: `Notes of ${organization.fullName} were saved`,
      silent
    })

    commit('storeOrganization', { organization })
  },

  /**
   * Removes an organization
   * @param {Organization} organization Organization to remove
   * @param {Boolean} confirm If true, user confirmation is required
   * @param {Boolean} silent If true, no notifications will be shown
   * @returns {Promise}
   */
  async removeOrganization ({ commit, dispatch, getters }, { confirm = true, organization: { id }, silent } = {}) {
    if (!id) throw new Error('Organization identifier is required')

    const organization = await SecurityAPI.getOrganization({ id, withDetails: true, withChildOrganizations: true })
    if (!organization) throw new Error(`Organization ${id} no longer exists`)

    const { currentUser } = getters
    const { fullName } = organization
    const { canDeleteOrganization, reason } = SecurityRules.canDeleteOrganization(currentUser, organization)
    if (!canDeleteOrganization) {
      Notification.error({ message: reason, silent })
      return
    }

    const yes = await Confirmation.ask({
      title: 'Delete',
      message: `Delete ${fullName}?`,
      confirm
    })

    if (yes) {
      await dispatch('busy', { message: `Deleting ${fullName} ...`, data: organization, silent })
      const result = await SecurityAPI.deleteOrganization({ organization })
      await dispatch('done', { message: `${capitalize(fullName)} has been deleted`, silent })
      commit('removeOrganization', { organization })
      return result
    }
  },

  /**
   * Creates sample data for the organization.
   * Implement in other places such as devices etc.
   * @param {Organization} organization Organization where to create sample data
   * @param {Object} data Details of the sample data to create
   */
  // eslint-disable-next-line no-unused-vars
  async createSampleOrganizationData (_, { organization, data } = {}) {
  },

  /**
   * Adds tag on a organization
   * @param {Organization} organization Organization to add the tag to
   * @param {Tag} tag Tag to add
   * @returns {Promise}
   */
  async addOrganizationTag ({ commit }, { organization, tag } = {}) {
    tag.entityId = organization.id
    tag.category = TagCategory.Organization
    const savedTag = await CommonAPI.saveTag({ tag })
    commit('addOrganizationTag', { organization, tag: savedTag })
  },

  /**
   * Removes tag from a organization
   * @param {Organization} organization Organization to remove the tag from
   * @param {Tag} tag Tag to remove
   * @returns {Promise}
   */
  async removeOrganizationTag ({ commit }, { organization, tag } = {}) {
    if (tag) {
      await CommonAPI.deleteTag({ tag })
      commit('removeOrganizationTag', { organization, tag })
    }
  },

  /**
   * Toggles organization as favorite
   * @param {Organization} organization Organization to toggle as favorite
   * @param {User} user User who (un)favorites the organization
   * @returns {Promise}
   */
  async toggleFavoriteOrganization ({ dispatch }, { organization, user } = {}) {
    if (organization && user) {
      const tag = new Tag({
        category: TagCategory.Organization,
        name: Tags.Favorite,
        entityId: organization.id,
        userId: user.id
      })

      organization.toggleTag(tag)

      // Save, but only if existing organization.
      // For new organization the tag will be saved when the record is saved.
      if (organization.id) {
        if (organization.hasTag(tag)) {
          await dispatch('addOrganizationTag', { organization, tag })
        } else {
          await dispatch('removeOrganizationTag', { organization, tag })
        }
      }
    }
  },

  /**
   * Saves the organization's favorite status
   * @param {Organization} organization Organization to save
   * @param {User} user User updating the organization
   * @returns {Promise}
   */
  async saveFavoriteOrganization ({ dispatch }, { organization, user } = {}) {
    const isFavorite = organization.isFavorite(user)
    const tag = new Tag({
      category: TagCategory.organization,
      name: Tags.Favorite,
      entityId: organization.id,
      userId: user.id
    })
    if (isFavorite) {
      await dispatch('addOrganizationTag', { organization, tag })
    } else {
      await dispatch('removeOrganizationTag', { organization, tag })
    }
  },

  /**
   * Saves an organization note
   * @param {Organization} organization Organization to save
   * @param {Note} note Note to save
   * @returns {Promise}
   */
  async saveOrganizationNote ({ commit, getters }, { organization, note } = {}) {
    if (organization && note) {
      note.entityId = organization.id
      note.category = NoteCategory.Organization
      const { currentUser: user } = getters
      note = await CommonAPI.saveNote({ note })
      commit('saveOrganizationNote', { organization, note, user })
    }
  },

  /**
   * Removes a organization note
   * @param organization Organization
   * @param note Note to remove
   */
  async removeOrganizationNote ({ commit, getters }, { organization, note } = {}) {
    if (organization && note) {
      const { currentUser: user } = getters
      if (note.id) {
        await CommonAPI.deleteNote({ note })
      }
      commit('removeOrganizationNote', { organization, note, user })
    }
  }
}
