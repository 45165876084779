<script>
import { mapActions } from 'vuex'
import { DocumentCategory } from '@stellacontrol/model'

export default {
  data () {
    return {
      // All available documents
      documents: []
    }
  },

  methods: {
    ...mapActions([
      'getDocuments'
    ]),

  },

  async created () {
    this.documents = await this.getDocuments({ category: DocumentCategory.FirmwareHistory }) || []
  }
}
</script>

<template>
  <div class="q-ma-md column">
    <div class="q-mb-md">
      Click below to see the history of firmware changes:
    </div>
    <div class="row items-center q-ml-md q-mr-md q-mb-md" v-for="document in documents">
      <q-icon name="description" size="xs" class="q-mr-xs" color="indigo-7"></q-icon>
      <a :href="document.url" target="_blank" class="item-link">
        {{ document.title }}
      </a>
    </div>
  </div>
</template>

<style scoped>
</style>