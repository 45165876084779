<script>
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const name = 'debug'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      clickCount: 0,
      touchCount: 0
    }
  },

  computed: {
  },

  methods: {
  }
}
</script>

<template>
  <sc-view :name="name">

    <main>
    </main>

  </sc-view>
</template>

<style lang='scss' scoped>
main {
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: auto;
}
</style>
