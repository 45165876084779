import { mapState, mapGetters } from 'vuex'
import { Device, Organization } from '@stellacontrol/model'
import { Guardian } from '@stellacontrol/security'
import { Secure } from '@stellacontrol/security-ui'
import Widget from './widget.vue'
import TextWidget from './text.vue'
import IconWidget from './icon.vue'
import ParameterWidget from './parameter.vue'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Device displayed in the widget
    device: {
      type: Device,
      required: true
    },
    // Viewed organization
    organization: {
      type: Organization
    },
    // Viewed organization's guardian
    organizationGuardian: {
      type: Guardian
    },
    // Indicates that device is editable, commands can be sent etc.
    isEditable: {
      type: Boolean,
      default: false
    },
    // Use to show/hide the actions menu
    hasActions: {
      type: Boolean,
      default: true
    }
  },

  components: {
    'sc-widget': Widget,
    'sc-widget-text': TextWidget,
    'sc-widget-icon': IconWidget,
    'sc-widget-parameter': ParameterWidget
  },

  computed: {
    ...mapState({
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices,
      // Status watcher clock ticks, used to enforce refreshing of labels on each status arrival
      ticks: state => state.deviceStatus.ticks
    }),

    ...mapGetters([
      'currentOrganization',
      'toLocalDateTime',
      'guardian',
      'isSmallScreen',
      'noMoreThanHD',
      'noMoreThanFullHD',
      'atLeastHD',
      'atLeastFullHD'
    ]),

    // Status of the viewed device
    status () {
      const { device, deviceStatus } = this
      return deviceStatus[device?.serialNumber]
    },

    // Indicates whether status of the selected device is now available
    hasStatus () {
      return Boolean(this.status?.connection)
    },

    // Indicates that we have to do with a multi-board device
    isMultiDevice () {
      const { device } = this
      return device?.isMultiDevice
    },

    // Indicates that device is owned by the current organization
    isMyDevice () {
      const { device, currentOrganization } = this
      return device?.ownerId === currentOrganization?.id
    },

    // Indicates multi-board device has any parts
    hasParts () {
      const { device, deviceParts } = this
      return device?.isMultiDevice && deviceParts.length > 0
    },

    // Indicates that we're looking at part of a multi-board device
    isDevicePart () {
      const { device } = this
      return device?.partOf
    }
  }
}
