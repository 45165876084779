<script>
import { mapGetters, mapActions } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { OrganizationSortOrder, sortOrganizations, getOrganizationColor, CustomOrganizationIcons, getOrganizationIcon, Countries, Languages } from '@stellacontrol/model'
import { Secure } from '../../components'
import OrganizationSampleDataComponent from './organization-sample-data.vue'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  components: {
    'sc-organization-sample-data': OrganizationSampleDataComponent
  },

  props: {
    data: {
      required: true
    },

    permissions: {
      required: true
    }
  },

  data () {
    return {
      // Countries to select from
      countries: [],
      // Languages to select from
      languages: []
    }
  },

  computed: {
    ...mapGetters([
      'configuration',
      'organizations',
      'organizationHierarchy',
      'organizationProfiles',
      'availableOrganizationProfiles',
      'defaultSite'
    ]),

    /**
     * Sites to choose from
     */
    sites () {
      return this.configuration.sites
    },

    /**
     * Selected site
     */
    selectedSiteDescription () {
      const { sites, data } = this
      const site = sites.find(s => s.name === data.site)
      return site ? (site.description || site.name) : ''
    },

    /**
     * Organization profiles to choose from
     */
    profiles () {
      const { currentOrganization, data, availableOrganizationProfiles, profile } = this
      if (data) {
        const profiles = availableOrganizationProfiles
          // Skip deactivated profiles!
          .filter(profile => profile.isEnabled)
          // Don't allow creating super-organizations!
          .filter(profile => !profile.isSuperOrganization)

        // It could be, that the currently assigned profile is no longer available to this organization.
        // Add it to the dropdown, so it displays correctly, but mark as disabled so it cannot be selected again.
        if (!data.isNew && profile && !profiles.find(p => p.id === profile.id)) {
          profile.notAvailable = true
          profiles.push(profile)
        }

        return sortOrganizations(profiles, OrganizationSortOrder.Rank, { currentOrganization, byFullName: true })
      }
    },

    /**
     * Currently selected profile
     */
    profile () {
      const { data, organizationProfiles } = this
      return organizationProfiles.find(p => p.id === data.profileId)
    },

    /**
     * Available parent organizations, which have to meet all the following criteria:
     * - enabled organizations
     * - only reseller organizations
     * - not the organization being edited
     * - none of organization's child organizations
     */
    parentOrganizations () {
      return this.organizations.filter(organization =>
        organization.isEnabled &&
        organization.id !== this.data.id &&
        organization.canHaveChildOrganizations &&
        !this.isMyChildOrganization(organization)
      )
    },

    /**
     * Currently selected parent organization
     */
    parentOrganization () {
      return this.organizations.find(organization => organization.id === this.data.parentOrganizationId)
    },

    // Custom icons
    icons () {
      return CustomOrganizationIcons.map(icon => ({ label: icon, value: icon }))
    },

    // True, if organization has been locked by creator,
    // so it can only be edited by reseller.
    // Used to protect large customers from editing their customers and divisions hierarchy,
    // managed instead by their reseller.
    isLocked () {
      return this.data.isLocked &&
        this.currentOrganization &&
        !this.currentOrganization.isSuperOrganization &&
        !this.currentOrganization.isResellerOrganization
    },

    // Cannot edit self!
    // Cannot edit if organization is locked and only editable by reseller,
    // but I'm not a reseller
    canEdit () {
      return this.currentOrganization &&
        this.data &&
        this.currentOrganization.id !== this.data.id &&
        !this.isLocked
    },

    // Cannot change own profile!
    // Cannot downgrade super organization to anything lower!
    // Cannot change profile if I don't even have right to see it!
    canEditProfile () {
      return this.canEdit &&
        !this.data.isSuperOrganization
    },

    // Can see website only as super-user, and only for reseller orgs.
    // Children of reseller orgs will inherit their site.
    canSeeSite () {
      return this.currentOrganization &&
        this.currentOrganization.isSuperOrganization &&
        this.currentOrganization.id !== this.data.id &&
        this.data.isResellerOrganization
    },

    // Can edit website only if adding highest-level resellers
    canEditSite () {
      return this.data.isNew && this.canSeeSite && this.parentOrganization && this.parentOrganization.isSuperOrganization
    },

    // Only super organizations are allowed to edit parent.
    // Also, an organization cannot set its own parent.
    canEditParentOrganization () {
      return this.isSuperOrganization &&
        this.data.id !== this.currentOrganization.id
    },

    // Checks if can edit extended organization properties
    canEditExtendedProperties () {
      return this.isSuperOrganization
    },

    // Only super organizations are allowed to edit legacy DB identifiers.
    canEditLegacyId () {
      // No longer useful, after all organizations have been imported from the legacy DB
      return false
    },

    // Only some resellers are allowed to disable organizations
    canDisableOrganization () {
      return this.canEdit && this.canUse('can-disable-organizations')
    },

    // Only some resellers are allowed to lock organizations
    canLockOrganization () {
      return this.canEdit && this.canUse('can-lock-organizations')
    },

    // Notes about the organization made by the current user
    organizationNotes () {
      const { data, currentUser } = this
      const note = data.getNoteBy(currentUser)
      return (note || {}).text
    },

    // Selected country
    country () {
      const { countryCode } = this.data
      return Countries.All.find(c => c.code === countryCode)
    },

    // Checks whether more timezones are available to select from
    hasMultipleTimezones () {
      const { country } = this
      return country?.timezones?.length > 1
    },

    // Determines whether the user can edit the organization icon
    canChangeIcon () {
      return false
      // SCL-540 Disabled on request
      // return canUse('custom-organization-icons')
    }
  },

  methods: {
    ...mapActions([
      'organizationExists',
      'userExists'
    ]),

    getOrganizationIcon,
    getOrganizationColor,

    // Validation rule which checks whether the specified organization name is already in use
    async organizationNameIsUnique (name) {
      const { id, exists } = await this.organizationExists({ name })
      const isUnique = !exists || id === this.data.id
      return isUnique || 'Customer with this name already exists'
    },

    // Checks whether the specified organization is a child
    // of the edited organization, directly or indirectly
    isMyChildOrganization ({ id }) {
      this.organizationHierarchy.isDescendantOf({ id }, this.data)
    },

    // Validation rule for e-mail address. When creating new organization,
    // it checks whether the specified user name is not used already
    async newAdministratorIsUnique (name) {
      const { data } = this
      if (data.isNew) {
        const { id, exists } = await this.userExists({ name })
        const isUnique = !exists || id === data.id
        return isUnique || 'User with this e-mail address already exists'
      } else {
        return true
      }
    },

    // Another profile was selected:
    // update the profile in the edited organization
    // and reinitialize the permissions, because
    // some features might have just become available or unavailable
    // to the edited organization.
    profileChanged (profileId) {
      const { data, organizationProfiles, permissions } = this
      const profile = organizationProfiles.find(p => p.id === profileId)
      if (profile) {
        data.profile = profile
        data.profileId = profileId
        data.permissions = profile.clonePermissions({ useDefaults: true })
        data.icon = profile.icon || data.icon
        permissions.initialize()
      }
    },

    // Another parent organization was selected
    parentOrganizationChanged (parentOrganizationId) {
      const { data, parentOrganizations, defaultSite } = this
      const parentOrganization = parentOrganizations.find(p => p.id === parentOrganizationId)
      data.parentOrganization = parentOrganization
      data.parentOrganizationId = parentOrganizationId
      if (parentOrganization) {
        data.site = parentOrganization.site || defaultSite.name
      } else {
        data.site = defaultSite.name
      }
    },

    // Sets notes about the organization made by the current user
    setOrganizationNotes (text) {
      const { data, currentUser } = this
      data.setNoteBy(currentUser, text)
    },

    // Filter function for filtering options in the country selector
    filterCountries (value = '', update) {
      update(() => {
        if (value === '') {
          this.countries = Countries.All
        } else {
          const text = value.toLowerCase()
          this.countries = Countries.All.filter(o => o.name.toLowerCase().includes(text))
        }
      })
    },

    // Filter function for filtering options in the language selector
    filterLanguages (value = '', update) {
      update(() => {
        if (value === '') {
          this.languages = Languages.Supported
        } else {
          const text = value.toLowerCase()
          this.languages = Languages.Supported.filter(o => o.name.toLowerCase().includes(text))
        }
      })
    }
  },

  watch: {
    // When country changes, assume its default timezone
    country () {
      this.data.timezone = this.country?.getDefaultTimezone() || null
    }
  },

  created () {
    // If editing new organization,
    // set initial permissions as per default profile
    const { data, currentOrganization } = this
    if (data.isNew) {
      this.profileChanged(data.profileId)

      // Assume country and timezone of the creator
      data.countryCode = currentOrganization.countryCode
      data.languageCode = currentOrganization.languageCode
      data.timezone = currentOrganization.timezone
    }
    this.countries = Countries.All
    this.languages = Languages.Supported
  }
}

</script>

<template>
  <q-form class="form" ref="form" autofocus>
    <div class="properties q-pa-md">
      <q-input v-model="data.name" dense square outlined debounce="1000" :readonly="!canEdit"
        maxlength="255" lazy-rules hide-bottom-space :rules="[
        rules.required('Customer name is required'),
        name => organizationNameIsUnique(name)
      ]">
        <template v-slot:before>
          <label>Organization Name</label>
        </template>
        <template v-slot:after>
          <sc-hint-placeholder v-if="!isLocked">
          </sc-hint-placeholder>
        </template>
      </q-input>

      <q-input v-model="data.description" dense square outlined maxlength="255" class="q-mt-md"
        :readonly="!canEdit">
        <template v-slot:before>
          <label>Description</label>
        </template>
        <template v-slot:after>
          <sc-hint-placeholder v-if="!isLocked">
          </sc-hint-placeholder>
        </template>
      </q-input>

      <div class="q-mt-md country-language">
        <label>
          Country, Language
        </label>
        <div class="controls">
          <div>
            <q-select square dense outlined label="Country" v-model="data.countryCode"
              :options="countries" emit-value map-options option-value="code" option-label="name"
              use-input debounce="500" fill-input hide-selected @filter="filterCountries">
            </q-select>
          </div>
          <div>
            <q-select square dense outlined label="Language" v-model="data.languageCode"
              :options="languages" emit-value map-options option-value="code" option-label="name"
              use-input debounce="500" fill-input hide-selected @filter="filterLanguages">
            </q-select>
          </div>
          <div v-if="hasMultipleTimezones">
            <q-select :readonly="!hasMultipleTimezones" square dense outlined label="Timezone"
              v-model="data.timezone" :options="country?.timezones || []">
            </q-select>
          </div>
        </div>
        <sc-hint-placeholder v-if="!isLocked"></sc-hint-placeholder>
      </div>

      <q-field class="q-mt-md" v-if="canDisableOrganization" dense borderless>
        <div class="row items-center">
          <q-toggle v-model="data.isEnabled" color="green"></q-toggle>
          <sc-hint text="If customer is deactivated, users will not be allowed to log into it.">
          </sc-hint>
        </div>
        <template v-slot:before> <label>Is Active?</label> </template>
      </q-field>

      <q-field class="q-mt-md" v-if="canLockOrganization" dense borderless>
        <div class="row items-center">
          <q-toggle v-model="data.isLocked" color="green"></q-toggle>
          <sc-hint
            text="If customer is locked, it can be edited only by reseller. Large customers can also have child customers. Using this option you can prevent them from editing their hierarchy.">
          </sc-hint>
        </div>
        <template v-slot:before> <label>Is Locked for Changes?</label> </template>
      </q-field>

      <div class="row q-mt-md" v-if="canChangeIcon">
        <q-select dense square outlined hide-bottom-space hide-dropdown-icon
          popup-content-class="organization-icon-picker" v-model="data.icon" emit-value map-options
          :options="icons">
          <template v-slot:before>
            <label>Customer Icon</label>
          </template>
          <template v-slot:after>
            <sc-hint width="450px" text="Icon representing this customer."></sc-hint>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-icon :name="scope.opt.value" :color="getOrganizationColor(data)" size="sm"
                  class="q-pa-xs"></q-icon>
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:selected>
            <div class="q-pl-sm q-pr-sm">
              <q-icon :name="data.icon" :color="getOrganizationColor(data)" size="sm"></q-icon>
            </div>
          </template>
        </q-select>
      </div>

      <q-select class="q-mt-md" dense square outlined hide-bottom-space v-if="canSeeSite"
        :readonly="!canEditSite" v-model="data.site" :options="sites" emit-value map-options
        option-value="name" option-label="description" :rules="[
        rules.required('Customer website is required')
      ]">

        <template v-slot:before>
          <label>Customer Website</label>
        </template>

        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section side>
              <q-icon name="public" color="indigo-6" size="sm"></q-icon>
            </q-item-section>
            <q-item-section>
              <q-item-label v-html="scope.opt.description || scope.opt.name"></q-item-label>
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:after>
          <sc-hint>
            Customer website determines the URL
            where users are allowed to log in.
            If they attempt to log in to another website,
            they will be redirected to their assigned website.
          </sc-hint>
        </template>
      </q-select>

      <q-select v-if="canEditProfile" v-show="profiles.length > 1" class="q-mt-md" dense square
        outlined hide-bottom-space v-model="data.profileId" :options="profiles" emit-value
        map-options option-value="id" option-label="fullName" option-disable="notAvailable"
        :rules="[rules.required('Customer profile is required')]"
        @update:model-value="value => profileChanged(value)">
        <template v-slot:before>
          <label>
            <router-link class="item-link" v-if="isSuperAdministrator && data.profileId"
              :to="{ name: 'organization-profile', params: { id: data.profileId } }">
              Customer Profile
              <sc-tooltip>Go to {{ profile.name }} profile</sc-tooltip>
            </router-link>
            <span v-else>
              Customer Profile
            </span>
          </label>
        </template>
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section side>
              <q-icon :name="getOrganizationIcon(scope.opt)"
                :color="getOrganizationColor(scope.opt)" size="sm"></q-icon>
            </q-item-section>
            <q-item-section>
              <q-item-label v-html="scope.opt.fullName"></q-item-label>
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:after>
          <sc-hint>
            Customer profile determines the initial set of permissions for the customer.
            You can fine-tune these permissions on Permissions tab.
          </sc-hint>
        </template>
      </q-select>

      <q-input class="q-mt-md" dense square outlined v-if="!canEditProfile"
        v-model="data.profile.fullName" readonly>
        <template v-slot:before>
          <label>Customer Profile</label>
        </template>
        <template v-slot:after>
          <sc-hint v-if="!isLocked">
            Customer profile determines the initial set of permissions for the customer.
          </sc-hint>
        </template>
      </q-input>

      <q-field dense borderless v-if="profiles.length === 0" class="q-mt-md">
        <template v-slot:before>
          <label></label>
        </template>
        <label class="text-red-8">
          You does not have the necessary permisions to create
          child organizations. Please contact your reseller.
        </label>
      </q-field>

      <sc-organization-selector class="q-mt-md" dense square outlined bg-color="white"
        v-if="canEditParentOrganization" v-model="data.parentOrganizationId"
        :items="parentOrganizations" :rules="[rules.required('Parent is required')]"
        @update:model-value="value => parentOrganizationChanged(value)">
        <template v-slot:before>
          <label>
            <router-link class="item-link"
              v-if="isSuperAdministrator && data.profileId && !data.isSuperOrganization && data.id !== currentOrganization.id"
              :to="{ name: 'organization', params: { id: data.parentOrganizationId } }">
              Parent
              <sc-tooltip>Go to {{ parentOrganization.name }}</sc-tooltip>
            </router-link>
            <span v-else>
              Parent
            </span>
          </label>
        </template>
        <template v-slot:after>
          <sc-hint>
            Parent is responsible for managing customer's
            affairs. It will have full access to their
            users, places, inventory etc.
          </sc-hint>
        </template>
      </sc-organization-selector>

      <q-input v-if="canEditExtendedProperties" v-model="data.phone" dense square outlined
        :readonly="!canEdit" class="q-mt-md">
        <template v-slot:before> <label>Phone Number</label> </template>
        <template v-slot:after>
          <sc-hint text="Phone number of the customer" v-if="!isLocked"></sc-hint>
        </template>
      </q-input>

      <q-input class="q-mt-md q-pb-none" v-model="data.email" dense square outlined type="email"
        v-if="data.isNew" hide-bottom-space maxlength="45" lazy-rules
        :label="data.isNew ? 'If you enter the e-mail, administrator account will be added' : ''"
        :rules="[
        rules.isEmail('Invalid e-mail address'),
        name => newAdministratorIsUnique(name)
      ]">
        <template v-slot:before> <label>E-mail address</label> </template>
        <template v-slot:after>
          <sc-hint
            text="E-mail adress of the administrator. If you specify this, we will create administrator account and send activation e-mail.">
          </sc-hint>
        </template>
      </q-input>

      <q-field dense borderless v-if="data.isNew && data.createSampleData"
        class="sample-data q-mt-md">
        <template v-slot:before> <label>Create sample data</label> </template>
        <div class="row items-center">
          <sc-organization-sample-data :data="data"></sc-organization-sample-data>
        </div>
      </q-field>

      <q-field dense borderless v-if="!data.isNew && data.primaryAdministrator" class="q-mt-md">
        <template v-slot:before> <label>Primary Administrator</label> </template>
        <div class="row items-center">
          {{ data.primaryAdministrator.fullName }}
          {{ data.primaryAdministrator.fullName !== data.primaryAdministrator.email
        ? `(${data.primaryAdministrator.email})`
        : '' }}
        </div>
      </q-field>

      <q-field dense borderless v-if="!data.isNew">
        <template v-slot:before> <label>Created</label> </template>
        <div class="row items-center">
          {{ data.createdText }}
        </div>
      </q-field>

      <q-field dense borderless v-if="!data.isNew">
        <template v-slot:before> <label>Updated</label> </template>
        <div class="row items-center">
          {{ data.updatedText }}
        </div>
      </q-field>
    </div>

    <div class="notes q-pa-md">
      <q-input :model-value="organizationNotes"
        @update:model-value="text => setOrganizationNotes(text)" type="textarea" dense square
        outlined class="note" label="Notes and Remarks">
      </q-input>
    </div>
  </q-form>
</template>

<style lang='scss'>
.organization-icon-picker {
  .q-virtual-scroll__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 416px;
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 16px;
  }
}
</style>

<style lang='scss' scoped>
.form {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;

  label {
    font-size: 14px;
    min-width: 160px;
    color: #0000008a;
  }

  .properties {
    flex: 1;
    flex-basis: 450px;
  }

  .notes {
    flex: 1;
    flex-basis: 450px;
    display: flex;
    flex-direction: column;

    .note {
      height: 100%;
    }

    :deep(.q-textarea) {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    :deep(.q-field__control) {
      height: 100%;
      flex: 1;
    }
  }

  .sample-data {
    border: dotted #9fa8da 1px;
    background-color: #f3f4fd;
    padding: 16px;
    border-radius: 4px;
  }

  .country-language {
    display: flex;
    flex-direction: row;
    align-items: center;

    >label {
      padding-right: 6px;
      display: flex;
      align-items: center;
    }

    >.controls {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px;
      padding-left: 6px;
      padding-right: 6px;

      label {
        min-width: 0;
      }

      div {
        flex: 1;
      }
    }
  }
}
</style>
