<script>
import { countString, pluralize, formatDateTime } from '@stellacontrol/utilities'
import { mapGetters, mapState, mapActions } from 'vuex'
import { Place, getPlaceLabel } from '@stellacontrol/model'
import { ListAction, ViewMixin, ActionItem } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import CloneBuildingDialog from './clone-building-dialog.vue'

const name = 'installations'

/**
 * WARNING!
 * For performance reasons reactivity is tweaked on this view.
 * Once the list of buildings is rendered, we no longer observe the underlying data collection.
 * Instead, we use `v-memo` directive to trigger the refresh only in some specific circumstance
 * such as sorting or filtering. If list needs to be refreshed as a result of some other action,
 * you can use increase the `state.installationsRefresh` in state mutation. This property is
 * included in the `v-memo` directive and its increase will force refreshing of the list.
 */
export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    'sc-clone-building-dialog': CloneBuildingDialog
  },

  data () {
    return {
      name,
      // Table columns
      columns: [
        { field: 'pinned', label: '', sortable: false },
        { field: 'name', label: 'Name', sortable: true },
        { field: 'organizationPath', label: 'Customer', sortable: true },
        { field: 'alertCount', label: 'Alerts', sortable: true, tooltipInfo: 'Alerts from the past 24h', permissions: ['alerts'] },
        { field: 'deviceCount', label: 'Devices', sortable: true },
        { field: 'hasPlan', label: 'Plan', sortable: false, permissions: ['planner'] },
        { field: 'hasNotes', label: 'Notes', sortable: false, permissions: ['edit-installations'] },
        { field: 'updatedAt', label: 'Last Modified', sortable: true },
        { field: 'updatedBy', label: 'Modified By', sortable: true },
        { field: 'commands', label: '', sortable: false, permissions: ['edit-installations'] }
      ],
      // Tooltips for various states of the building
      tooltips: {
        notes: {
          on: 'Edit notes',
          off: 'Add notes'
        },
        pinned: {
          on: 'Remove from favourite',
          off: 'Add to favourites'
        }
      },
      // Virtual list settings
      virtualList: {
        rowHeight: 40,
        start: 0,
        end: 0,
        isRendering: false
      }
    }
  },

  computed: {
    ...mapGetters([
      'organizationProfiles',
      'isSmallScreen',
      'toLocalDateTime'
    ]),

    ...mapState({
      // Buildings view state
      installationsView: state => state.installationsView,
      // Used to force the update of the installations view
      installationsRefresh: state => state.installationsView.installationsRefresh,
      // Indicates that we're loading the buildings
      isLoading: state => state.installationsView.isLoading,
      // All devices
      devices: state => state.devices.devices,
      // Buildings to display
      buildings: state => state.installationsView.places,
      // All places
      allPlaces: state => state.places.items,
      // Current filter
      filter: state => state.installationsView.filter,
      exactFilter: state => state.installationsView.exactFilter,
      // Current sorting
      sortBy: state => state.installationsView.sortBy,
      sortDescending: state => state.installationsView.sortDescending,
      // User's favourite buildings
      pinnedBuildings: state => state.installationsView.pinnedBuildings || [],
      // Number of days for which to retrieve the recent alerts
      recentAlertDays: state => state.installationsView.recentAlertDays
    }),


    // Checks whether the table is sorted by the specified column
    isSortedBy () {
      return column => this.sortBy === column.field
    },

    // CSS class for the specified column
    getColumnClass () {
      return column => {
        const sorted = this.isSortedBy(column)
        const descending = sorted && this.sortDescending
        return {
          column: true,
          sortable: column.sortable,
          sorted,
          descending,
          'hidden-cell': !this.canUseAll(column.permissions)
        }
      }
    },


    // CSS class for the specified cell
    getCellClass () {
      return (column) => {
        const permissions = this.columns.find(c => c.field === column)?.permissions
        return { 'hidden-cell': !this.canUseAll(permissions) }
      }
    },

    // Checks whether the list of buildings is now filtered
    isFiltered () {
      return Boolean(this.filter?.trim().toLowerCase())
    },

    // List of buildings matching eventual filter
    filteredBuildings () {
      let result
      const { buildings, exactFilter } = this
      const filter = this.filter?.trim().toLowerCase() || ''
      const filters = filter.split(' ').filter(word => word.trim()) || []

      if (filters.length === 0) {
        result = buildings
      } else {
        result = buildings.filter(building => {
          const organizationPath = building
            .organizationPath
            .map(o => o.name)
            .join(' ')

          // Check whether text fields match
          const values = [
            building.name.toLowerCase(),
            organizationPath.toLowerCase(),
            building.deviceCount.toString(),
            formatDateTime(building.updatedAt) || '',
            building.updatedBy?.name || ''
          ]

          if (exactFilter) {
            if (values.some(v => v === filter)) {
              return true
            }
          } else {
            if (filters.every(f => values.some(v => v.includes(f)))) {
              return true
            }
          }

          // Check whether device serial numbers in the place match
          if (filters.some(f => building.devices.find(s => s.toLowerCase() === f))) {
            return true
          }

          return false
        })
      }

      // Prevent Vue from observing the list
      // We only refresh the UI in specific circumstances.
      return Object.freeze(result)
    },

    // Indicates an exact match of a device
    filteredDevice () {
      const { buildings } = this
      const filter = this.filter?.toLowerCase() || ''
      const filters = filter.split(' ').filter(word => word.trim()) || []
      const serialNumber = filters.find(f =>
        buildings.some(building =>
          building.devices.some(s => s.toLowerCase() === f)))
      return serialNumber
    },

    // Subset of the buildings which is currently in view
    visibleBuildings () {
      const { virtualList: { start, end } } = this
      return this.filteredBuildings.slice(start, end + 1)
    },

    // Checks whether the building is user's favourite
    isBuildingPinned () {
      return building => this.pinnedBuildings.includes(building.id)
    },

    // Determines the icon for the place
    buildingIcon () {
      return building => this.pinnedBuildings.includes(building.id) ? 'star' : 'star_border'
    },

    // Determines the color of the building icon
    buildingIconColor () {
      return building => this.pinnedBuildings.includes(building.id) ? 'amber-9' : 'grey-6'
    },

    // Determines whether building has any alerts
    buildingHasAlerts () {
      return building => this.canSeeAlerts && building.alertCount > 0
    },

    // Determines a title for the alerts popup
    buildingAlertsTitle () {
      return building => {
        const { recentAlertDays } = this
        const buildingName = building.isStock
          ? `${building.organizationName} Stock`
          : building.name
        const title = `${buildingName}: ${countString(building.alertCount, 'alert')}
          in the last ${pluralize(recentAlertDays, '24h', `${recentAlertDays} days`)}`
        return title
      }
    },

    // Determines the actions available for the building
    buildingActions () {
      return building => {
        const actions = []

        if (this.canEditPlace(building)) {
          actions.push(ListAction.Edit)
        }

        if (this.canUse('clone-installations')) {
          actions.push(new ActionItem({
            name: 'clone',
            label: 'Clone',
            icon: 'content_copy',
            color: 'indigo-5',
            isVisible: building => !building.isStock
          }))
        }

        if (this.canEditPlace(building)) {
          actions.push(ListAction.Delete)
        }

        return actions
      }
    },

    // View title
    title () {
      return `Installations (${this.buildings.length})`
    },

    // Determines a route for the building
    getBuildingRoute () {
      return building => ({
        name: 'building-dashboard',
        params: {
          id: building.isStock ? Place.ID_STOCK : building.id,
          organizationId: building.organizationId
        }
      })
    },

    // Determines whether the organization can be edited by the current user
    canEditOrganization () {
      return organization => {
        // The user must be an administrator, the organization cannot be his own
        const { currentUser, canUse } = this
        return canUse('administrator') &&
          currentUser.organizationId != organization.id
      }
    },

    // Determines the route to organization settings view
    getOrganizationRoute () {
      return organization => ({
        name: 'organization',
        params: {
          id: organization.id,
        }
      })
    },

    // Determines the route for the building plan
    getBuildingPlanRoute () {
      return building => building.isStock
        ? null
        : {
          name: 'building-plan',
          params: {
            id: building.id
          }
        }
    },

    // Checks whether the user can see alerts
    canSeeAlerts () {
      return this.canUse('alerts')
    },

    // Checks whether the user can edit buildings
    canEditPlaces () {
      return this.canUse('edit-installations')
    },

    // Checks whether the user can edit the specified building
    canEditPlace () {
      return building => !building.isStock &&
        this.canUse('edit-installations') &&
        (building.isMyBuilding || this.canUse('child-places'))
    },

    // Checks whether the user can open the building plan
    canOpenPlan () {
      return building => !building.isStock &&
        this.canUse('planner') &&
        (building.isMyBuilding || this.canUse('child-floor-plans'))
    },

    // Checks whether the user can edit the building plan
    canEditPlan () {
      return building => this.canOpenPlan(building) && this.canUse('planner-edit-plans')
    },

    // Plan icon tooltip
    planTooltip () {
      return building => {
        const { canOpenPlan, canEditPlan } = this
        if (canEditPlan(building)) {
          return `${building.hasPlan ? 'Edit' : 'Create'} ${getPlaceLabel(building.placeType)} plan`
        } else if (canOpenPlan(building)) {
          return `Open ${getPlaceLabel(building.placeType)} plan`
        }
      }
    },

    // Checks whether the user can edit the building notes
    canEditNotes () {
      return building => !building.isStock &&
        this.canUse('edit-installations') &&
        (building.isMyBuilding || this.canUse('child-places'))
    },

    // Indicates whether the user has access to device dashboard
    canSeeDeviceDashboard () {
      return this.canUse('device-dashboard')
    },

    // Route to device dashboard
    getDeviceDashboardRoute () {
      return serialNumber => serialNumber
        ? {
          name: 'device-dashboard',
          params: { serialNumber }
        }
        : null
    },

    // Height of the list element, in pixels
    listHeight () {
      const { filteredBuildings, virtualList } = this
      return virtualList.rowHeight * (filteredBuildings.length + 1)
    },

    // Building list style
    listStyle () {
      const { virtualList, filteredBuildings: buildings } = this
      return {
        'height': `${(buildings.length + 1) * virtualList.rowHeight}px`,
        'padding-top': `${virtualList.start * virtualList.rowHeight}px`,
        'padding-bottom': `${(buildings.length - virtualList.end) * virtualList.rowHeight}px`
      }
    }
  },

  methods: {
    ...mapActions([
      'showDialog',
      'createPlace',
      'loadBuildings',
      'filterBuildings',
      'sortBuildings',
      'pinFavouriteBuilding',
      'showPlaceNotes',
      'getPlace',
      'editPlace',
      'removePlace',
      'cloneBuilding',
      'getBuildingAlerts',
      'refreshBuildings'
    ]),

    formatDateTime,

    // Changes the sort order of the table
    sort (column) {
      const { installationsView } = this
      const { field: sortBy, sortable } = column
      if (sortable) {
        const sortDescending = installationsView.sortBy === sortBy && !installationsView.sortDescending
        this.sortBuildings({ sortBy, sortDescending })
      }
    },

    // Applies a filter to the list
    async applyFilter (filter) {
      await this.filterBuildings({ filter })
      await this.renderVisibleBuildings()
    },

    // Edit building notes
    async editBuildingNotes (id) {
      const building = await this.getPlace({ id, withAttachments: true })
      if (building) {
        this.showPlaceNotes({ place: building })
      }
    },

    // Clones the building
    async clonePlace (building) {
      const place = this.allPlaces.find(p => p.id === building.id)
      if (place) {
        const { isOk, data } = await this.showDialog({
          dialog: 'clone-building',
          data: { building: place }
        })

        if (isOk) {
          const { building, clonePlan } = data
          await this.cloneBuilding({ building, clonePlan })
          await this.loadBuildings()
        }
      }
    },

    // Executes a context menu action for the specified building
    executeAction (building, action) {
      switch (action.name) {
        case 'edit':
          return this.editPlace({ place: building })
        case 'delete':
          return this.removePlace({ place: building, confirm: true })
        case 'clone':
          return this.clonePlace(building)
      }
    },

    // Returns tooltips for table icons
    tooltip (type, value) {
      return this.tooltips[type][value ? 'on' : 'off']
    },

    // Loads the building alerts
    async loadBuildingAlerts (building) {
      const { recentAlertDays: days } = this
      await this.getBuildingAlerts({ building, days })
    },

    // Renders only the part of the buildings list which would be visible in the current screen
    renderVisibleBuildings () {
      const { filteredBuildings: buildings, listHeight } = this
      const { buildingsContainer: container } = this.$refs

      if (container) {
        const { scrollTop, clientHeight } = container
        const count = buildings.length
        const viewPercent = 100 * (clientHeight / listHeight)
        const scrollPercent = 100 * (scrollTop / listHeight)
        const start = Math.max(0, Math.floor(count * scrollPercent / 100) - 1)
        const end = Math.min(count - 1, start + Math.ceil(count * viewPercent / 100))

        this.virtualList.start = start
        this.virtualList.end = end
        this.refreshBuildings()
      }
    },

    // Triggered when list is scrolled
    onScroll () {
      this.renderVisibleBuildings()
    },

    // Triggered when window is resized
    onWindowResized () {
      this.renderVisibleBuildings()
    }
  },

  created () {
  },

  mounted () {
    window.addEventListener('resize', this.onWindowResized)
    this.renderVisibleBuildings()
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onWindowResized)
  }
}

</script>

<template>
  <sc-view :name="name" subheader-color="#fff" :title="title">
    <!-- Desktop mode toolbar -->
    <template #toolbar>
      <div class="buildings-toolbar row items-center no-wrap">
        <div class="buildings-filter row items-center no-wrap">
          <q-input clearable square label="Filter" :model-value="filter" class="input-filter"
            :bg-color="isFiltered ? 'yellow-1' : 'white'" :outlined="true"
            @update:model-value="filter => applyFilter(filter)" dense debounce="500">
            <template v-slot:append>
              <sc-hint class="hint" text="Enter filters separated by spaces" size="20px"></sc-hint>
            </template>
          </q-input>
        </div>

        <q-space></q-space>

        <div class="row items-center no-wrap justify-end">
          <q-btn v-if="canEditPlaces" flat class="primary" icon="add" label="Add Building"
            color="indigo-6" @click="createPlace()"></q-btn>
        </div>
      </div>
    </template>

    <!-- When in mobile mode, show buttons inside the topbar -->
    <teleport v-if="isSmallScreen" to="#topbar-items">
      <div class="buildings-filter row items-center no-wrap q-pl-md">
        <q-input clearable square label="Filter" class="input-filter" bg-color="white" outlined
          dense :model-value="filter" @update:model-value="filter => applyFilter(filter)"
          debounce="500">
          <template v-slot:append>
            <sc-hint class="hint" text="Enter filters separated by spaces" size="20px"></sc-hint>
          </template>
        </q-input>
      </div>
    </teleport>

    <div v-if="isLoading" class="loading">
      <sc-busy title="Loading ..."></sc-busy>
    </div>

    <div v-else class="buildings-outer" ref="buildingsContainer" @scroll="e => onScroll(e)">
      <div class="buildings column items-stretch"
        v-memo="[sortBy, sortDescending, filter, pinnedBuildings, installationsRefresh]"
        :style="listStyle">

        <div class="list">
          <!-- Header row -->
          <div class="header">
            <span v-for="column of columns" @click="sort(column)" :class="getColumnClass(column)">
              <template v-if="canUseAll(column.permissions)">
                {{ column.label }}
                <q-icon v-if="isSortedBy(column)"
                  :name="sortDescending ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
                  color="indigo-7" size="xs" class="q-ml-xs">
                </q-icon>

                <q-icon v-if="column.tooltipInfo" name="info" size="xs" class="q-ml-xs">
                  <sc-tooltip :text="column.tooltipInfo"></sc-tooltip>
                </q-icon>
              </template>
            </span>
          </div>

          <!-- Buildings -->
          <template v-for="building, index of visibleBuildings" :key="index">
            <div class="building">
              <!-- 1 -->
              <span>
                <q-btn dense unelevated no-caps class="clear pointer" :icon="buildingIcon(building)"
                  :textColor="buildingIconColor(building)"
                  @click.stop="pinFavouriteBuilding(building)">
                  <sc-tooltip :text="tooltip('pinned', isBuildingPinned(building))"></sc-tooltip>
                </q-btn>
              </span>

              <!-- 2 -->
              <span>
                <router-link class="item-link" :to="getBuildingRoute(building)">
                  {{ building.name }}
                  <label class="stock-owner" v-if="building.isStock">
                    / {{ building.organizationName }}
                  </label>
                  <label class="owner" v-else>
                    / {{ building.organizationName }}
                  </label>
                  <sc-tooltip v-if="!isSmallScreen">
                    Go to the dashboard ...
                  </sc-tooltip>
                </router-link>
              </span>

              <!-- 3 -->
              <span>
                <template v-for="o, index in building.organizationPath || []">
                  <router-link class="item-link" v-if="canEditOrganization(o)"
                    :to="getOrganizationRoute(o)">
                    {{ o.name }}
                    <sc-tooltip>
                      Go to {{ o.name }} settings ...
                    </sc-tooltip>
                  </router-link>

                  <span v-else>
                    {{ o.name }}
                  </span>

                  <q-icon name="chevron_right" size="xs" color="grey-7"
                    v-if="index < building.organizationPath.length - 1">
                  </q-icon>

                </template>
              </span>

              <!-- 4 -->
              <span
                :class="{ ...getCellClass('alertCount'), pointer: buildingHasAlerts(building) }">
                <q-badge v-if="buildingHasAlerts(building)" rounded outline color="orange-9"
                  v-close-popup>
                  {{ building.alertCount }}
                  <sc-alert-popup :ref="`alerts-popup-${index}`" :alerts="building.alerts"
                    @showing="loadBuildingAlerts(building)" :showSerialNumber="true"
                    :title="buildingAlertsTitle(building)">
                  </sc-alert-popup>
                </q-badge>
              </span>

              <!-- 5 -->
              <span>
                <template v-if="filteredDevice && canSeeDeviceDashboard">
                  <q-btn dense unelevated no-caps icon="dashboard" textColor="indigo-5"
                    class="clear pointer" :to="getDeviceDashboardRoute(filteredDevice)">
                    <sc-tooltip>
                      Open the {{ filteredDevice }} dashboard
                    </sc-tooltip>
                  </q-btn>
                </template>
                <template v-else>
                  <span class="device-count">
                    {{ building.deviceCount || '' }}
                  </span>
                </template>
              </span>


              <!-- 6 -->
              <span :class="getCellClass('hasPlan')">
                <q-btn v-if="canOpenPlan(building)" dense unelevated no-caps class="clear pointer"
                  icon="category" :textColor="building.hasPlan ? 'indigo-5' : 'indigo-2'"
                  :to="getBuildingPlanRoute(building)">
                  <sc-tooltip :text="planTooltip(building)"></sc-tooltip>
                </q-btn>
                <span v-else>
                  &nbsp;
                </span>
              </span>

              <!-- 7 -->
              <span :class="getCellClass('hasNotes')">
                <q-btn v-if="canEditNotes(building)" dense unelevated no-caps class="clear"
                  icon="comment" :textColor="building.hasNotes ? 'indigo-5' : 'indigo-2'"
                  @click.stop="editBuildingNotes(building.id)">
                  <sc-tooltip :text="tooltip('notes', building.hasNotes)"></sc-tooltip>
                </q-btn>
                <span v-else>
                  &nbsp;
                </span>
              </span>

              <!-- 8 -->
              <span>
                {{ building.updatedAt ? date(toLocalDateTime(building.updatedAt)) : '' }}
              </span>

              <!-- 9 -->
              <span>
                {{ building.updatedBy }}
              </span>

              <!-- 10 -->
              <span :class="getCellClass('commands')">
                <sc-action-dropdown v-if="!building.isStock" :data="building"
                  :actions="buildingActions(building)"
                  @action="action => executeAction(building, action)">
                </sc-action-dropdown>
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>

    <sc-document-upload-dialog></sc-document-upload-dialog>
    <sc-clone-building-dialog></sc-clone-building-dialog>
  </sc-view>
</template>

<style lang="scss" scoped>
.buildings-toolbar {
  flex: 1;
}

.buildings-filter {
  flex: 1;
  padding-left: 100px;
  padding-right: 100px;

  .q-input {
    width: 100%;
  }
}

.loading {
  flex: 1;
  position: relative;
}

.buildings-outer {
  flex: 1;
  overflow: auto;
  padding: 16px;
  background-color: white;

  .buildings {
    position: relative;
    overflow: hidden;

    .hidden-cell {
      width: 0 !important;
      visibility: hidden !important;
      padding: 0 !important
    }

    .list {
      max-width: 1400px;
      background-color: transparent;
      display: grid;
      grid-template-columns: 50px 300px auto max-content 85px max-content max-content 170px auto 50px;

      /* Header */
      .header {
        .column {
          color: #6772a2;

          &.sortable {
            cursor: pointer;
          }

          &.sorted {
            color: #332a92;
            font-weight: bold;
          }
        }
      }

      /* Rows */
      .header,
      .building {
        display: contents;

        >span {
          display: flex;
          height: 40px;
          padding-right: 8px;
          flex-direction: row;
          align-items: center;
          text-wrap: nowrap;
          flex-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          border-bottom: solid #0000001f 1px;

          /* Columns */
          /* Hide building owner, only needed on very small screens */
          &:nth-child(2) {
            .owner {
              display: none;
            }
          }

          /* Right-align device count */
          &:nth-child(4),
          &:nth-child(5) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 12px;
          }

          span.device-count {
            padding-top: 4px;
          }
        }
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (width <=1024px) {
  .buildings-filter {
    padding-left: 10px;
    padding-right: unset;

    .input-filter {
      width: 100%;
    }
  }

  .buildings {
    .list {
      grid-template-columns: 40px auto auto max-content 85px 0 0 auto auto 0;

      /* Rows */
      .header,
      .building {
        span {

          /* Hide action columns */
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(10) {
            width: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}

@media screen and (width <=820px) {
  .buildings-outer {
    .buildings {

      .list {
        grid-template-columns: 40px max-content auto max-content 85px 0 0 auto 0 0;

        /* Rows */
        .header,
        .building {
          span {

            /* Also hide the updater */
            &:nth-child(9) {
              width: 0;
              visibility: hidden;
            }
          }
        }
      }
    }
  }
}

@media screen and (width <=680px) {
  .buildings-outer {
    padding: 4px;

    .buildings {

      .list {
        grid-template-columns: 40px auto 0 max-content 0 0 0 0 0 0;

        .header {
          display: none;
        }

        .building {
          span {

            /* Hide path and everything from device count onwards */
            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10) {
              width: 0;
              visibility: hidden;
            }

            /* Show building owner */
            &:nth-child(2) {
              .owner {
                display: unset;
              }
            }

            /* Right-padding on alert count */
            &:nth-child(4) {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (width <=400px) {
  .buildings-outer {
    .buildings {
      .header {
        display: none;
      }
    }
  }
}
</style>
