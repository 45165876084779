<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import AlertConfigurationEditor from './editors/alert-configuration-editor.vue'
import { AlertDescription, isObsoleteAlert } from '@stellacontrol/model'
import { isAlertSupported } from '@stellacontrol/alerts'

export default {
  components: {
    'sc-alert-configuration-editor': AlertConfigurationEditor
  },

  props: {
    // Device whose alert configurations are edited
    device: {
    },
    // Last known status of the device
    status: {
    },
    // Indicates whether we're editing configuration for a batch of devices
    isBatch: {
      type: Boolean,
      default: false
    },
    // Alert configurations to edit
    configurations: {
      required: true
    },
    // Toggle states of alert configuration panes
    expandedConfigurations: {
      default: () => { }
    },
  },

  data () {
    return {
      // Indicates that component has been fully initialized
      isInitialized: false,
      // Alert type descriptions
      AlertDescription
    }
  },

  computed: {
    ...mapState({
      // Default alert configurations
      defaultConfigurations: state => state.alerts.defaultConfigurations
    }),

    ...mapGetters([
      'currentOrganization',
      'defaultAlertConfigurations',
      'isAlertPermitted'
    ]),

    // Alert configurations available to the user
    availableConfigurations () {
      const { device, status } = this
      return (this.configurations || [])
        .filter(c => !isObsoleteAlert(c.alertType))
        .filter(c => this.isAlertPermitted(c.alertType))
        .filter(c => isAlertSupported(c.alertType, c, device, status))
    },

    // Returns default configuration for the specified alert type
    defaultConfiguration () {
      return alertType => this.defaultConfigurations[alertType] || { parameters: {} }
    },

    // Determines alert text color
    alertColor () {
      return configuration => {
        const { device, status } = this
        const { isEnabled, isModified, alertType } = configuration
        return isEnabled && isAlertSupported(alertType, configuration, device, status)
          ? (isModified ? 'text-orange-9' : 'text-indigo-9')
          : 'text-grey-7'
      }
    },

    // Determines alert bg color
    alertBackgroundColor () {
      return configuration => {
        const { alertType } = configuration
        const isExpanded = this.expandedConfigurations[alertType]
        return isExpanded
          ? '#d2d5e9'
          : '#e8eaf6'
      }
    },

    // Determines alert icon
    alertIcon () {
      return configuration => {
        const { device, status } = this
        const { isEnabled, alertType } = configuration
        return isEnabled && isAlertSupported(alertType, configuration, device, status)
          ? 'notifications'
          : 'notifications_off'
      }
    }
  },

  emit: [
    'update'
  ],

  methods: {
    ...mapActions([
    ]),

    // Configuration has been updated, notify
    update (configuration) {
      this.$emit('update', configuration)
    }
  }
}

</script>

<template>
  <q-list v-if="configurations?.length > 0">
    <template v-for="(configuration, index) in availableConfigurations" :key="`alert-${index}`">
      <q-expansion-item expand-separator :duration="0"
        :header-style="{ 'background-color': alertBackgroundColor(configuration) }"
        :header-class="alertColor(configuration)" :icon="alertIcon(configuration)"
        :label="AlertDescription[configuration.alertType]"
        v-model="expandedConfigurations[configuration.alertType]">
        <q-card>
          <q-card-section>
            <sc-alert-configuration-editor :model-value="configuration"
              :default-configuration="defaultConfiguration(configuration.alertType)" :device="device"
              :status="status" :isBatch="isBatch" @update:model-value="update">
            </sc-alert-configuration-editor>
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-separator v-if="index !== configurations.length - 1"></q-separator>
    </template>
  </q-list>
</template>

<style lang="scss">
.alert-configuration {
  article {
    display: flex;
    flex-direction: column;

    section {
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: center;

      &.vertical {
        flex-direction: column !important;
        align-items: flex-start;
      }

      &.dense {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      & > label {
        min-width: 180px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &.modified {
          color: #ef6c00 !important;
        }

        &.disabled {
          color: #808080 !important;
        }

        i.q-icon {
          margin-left: 8px;
        }
      }
    }
  }

  &.readonly {
    article {
      section {
        & > label {
          &.modified {
            color: #606060 !important;
          }
        }
      }
    }
  }

  .toggle {
    &.off {
      border: 1px solid #757575;
    }

    &.on {
      border: 1px solid #3f51b5;
    }
  }

  .numeric-input {
    max-width: 150px;
  }
}

</style>