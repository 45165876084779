export function createState () {
  return {
    // Organization to which the place belongs
    organization: null,
    // Organization's guardian
    organizationGuardian: null,
    // Place displayed on the dashboard
    place: null,
    // Devices to show on the dashboard
    devices: [],
    // Indicates to show multi-devices or their individual boards
    showParts: false,
    // Selected devices
    selectedDevices: []
  }
}

export const state = createState()

export const getters = {
}
