<script>
export default {
  props: {
    // Value to display
    value: {
      type: Number,
      required: true
    },
    unit: {
      type: String,
      default: ''
    },
    // Range definition: `{ min, max, good, bad }`
    range: {
      type: String,
      required: true
    },
    // Maximal width of the gauge, in pixels
    width: {
      type: Number,
      default: 120
    }
  },

  data () {
    return {
      // Ranges for power/quality indicators
      ranges: {
        signalPower: { bad: -105, good: -100, min: -120, max: -60 },
        signalQuality: { bad: -14, good: -12, min: -20, max: -5 }
      }
    }
  },

  computed: {
    // Cell power indicator
    indicatorClass () {
      const { value, ranges } = this
      const range = ranges[this.range]
      const good = value > range.good
      const average = value <= range.good && value >= range.bad
      const bad = value < range.bad
      return {
        good,
        average,
        bad
      }
    },

    indicatorStyle () {
      const { value, ranges, width: maxWidth } = this
      const range = ranges[this.range]
      const normalizedValue = Math.min(range.max, Math.max(range.min, value))
      const level = (normalizedValue - range.min) / (range.max - range.min)
      const width = Math.max(2, Math.round(maxWidth * Math.min(1, level)))
      return {
        width: `${width}px`
      }
    }
  }
}
</script>

<template>
  <div class="indicator">
    <div class="gauge" :class="indicatorClass" :style="indicatorStyle">
    </div>
    <div class="value">
      {{ value }} {{ unit }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.indicator {
  width: 100%;
  height: 100%;
  position: relative;

  .gauge {
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    opacity: 0.4;

    &.good {
      background-color: #82caa0;
    }

    &.average {
      background-color: #ffa500;
    }

    &.bad {
      background-color: #e20400;
    }
  }

  .value {
    position: absolute;
    left: 8px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
  }
}
</style>
