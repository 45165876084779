import { parseDate, safeParseInt, parseBoolean, getId, withoutProperties, isEmptyOrNull, pointBelongsToVector, Point, Rectangle, notNull, getPropertyValue, setPropertyValue } from '@stellacontrol/utilities'
import { Assignable, DeviceType } from '@stellacontrol/model'
import { PlanLineStyle, PlanTextStyle, PlanBackgroundStyle, PlanPointStyle } from '../styles'
import { PlanPort, PlanPortType } from './plan-port'
import { PlanLayers } from '../layout/plan-layers'
import { PlanScale } from '../layout/plan-scale'

/**
 * Types of items on a plan
 */
export const PlanItemType = {
  // Primitives
  Circle: 'circle',
  Ellipse: 'ellipse',
  Rectangle: 'rectangle',
  Polygon: 'polygon',
  Curve: 'curve',
  Line: 'line',
  Icon: 'icon',
  Image: 'image',
  Connector: 'connector',
  Text: 'text',
  Device: 'device',
  Antenna: 'antenna',
  Cable: 'cable',
  Splitter: 'splitter',
  Beam: 'beam',
  Legend: 'legend',
  Riser: 'cable-riser',
  Plug: 'cable-plug',
  Ruler: 'ruler',
}

/**
 * User-friendly names of items on a plan
*/
export const PlanItemName = {
  // Primitives
  [PlanItemType.Circle]: 'Circle',
  [PlanItemType.Ellipse]: 'Ellipse',
  [PlanItemType.Rectangle]: 'Rectangle',
  [PlanItemType.Polygon]: 'Polygon',
  [PlanItemType.Curve]: 'Curve',
  [PlanItemType.Line]: 'Line',
  [PlanItemType.Icon]: 'Icon',
  [PlanItemType.Image]: 'Image',
  [PlanItemType.Connector]: 'Connector',
  [PlanItemType.Text]: 'Text',
  [PlanItemType.Device]: 'Device',
  [PlanItemType.Antenna]: 'Antenna',
  [PlanItemType.Cable]: 'Cable',
  [PlanItemType.Splitter]: 'Splitter',
  [PlanItemType.Beam]: 'Radiation Beam',
  [PlanItemType.Legend]: 'Legend',
  [PlanItemType.Riser]: 'Cable Riser',
  [PlanItemType.Plug]: 'Cable Plug',
  [PlanItemType.Ruler]: 'Ruler'
}

/**
 * Generic plan item
 */
export class PlanItem extends Assignable {
  constructor (data = {}) {
    super(data)
    this.type = this.constructor.type
    this.assign(data)
  }

  /**
   * Item type, must be implemented in descendants
   * @type {PlanItemType}
   */
  static get type () {
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      label: '',
      coordinates: Point.Zero,
      layer: PlanLayers.Items,
      isLocked: false,
      rotation: 0,
      scale: PlanScale.Normal,
      blurRadius: 0,
      isHidden: false,
      lineStyle: PlanLineStyle.None,
      backgroundStyle: PlanBackgroundStyle.None,
      lineStyleInProgress: null,
      backgroundStyleInProgress: null,
      textStyle: PlanTextStyle.Default,
      crossSection: {
        cordinates: Point.Zero,
        rotation: 0
      },
    }
  }

  /**
   * Normalizes properties of the item after deserialization
   */
  normalize () {
    super.normalize()

    const { defaults } = this

    // Clear any previously saved sizes if item has fixed size
    if (!this.canResize) {
      this.width = undefined
      this.height = undefined
      this.radius = undefined
    }

    this.created = parseDate(this.created) || new Date()
    this.id = this.id || getId('item')
    this.index = safeParseInt(this.index, 0)

    this.coordinates = this.cast(this.coordinates, Point, defaults.coordinates)
    if (this.coordinates && !this.coordinates.isDefined) {
      this.coordinates = defaults.coordinates
    }

    this.width = safeParseInt(this.width, defaults.width)
    this.height = safeParseInt(this.height, defaults.height)
    this.radius = safeParseInt(this.radius, defaults.radius)
    this.blurRadius = safeParseInt(this.blurRadius, defaults.blurRadius)
    this.layer = this.layer || defaults.layer
    this.isLocked = this.isLocked == null ? defaults.isLocked : Boolean(this.isLocked)
    this.rotation = Math.max(-360, Math.min(360, safeParseInt(this.rotation, defaults.rotation)))
    this.scale = this.customize(this.scale, PlanScale, defaults.scale)
    this.isHidden = parseBoolean(this.isHidden, defaults.isHidden)
    this.label = this.label || defaults.label
    this.labelPosition = this.label ? this.cast(this.labelPosition, Point) : null
    this.lineStyle = this.customize(this.lineStyle, PlanLineStyle, new PlanLineStyle(defaults.lineStyle))
    this.backgroundStyle = this.customize(this.backgroundStyle, PlanBackgroundStyle, new PlanBackgroundStyle(defaults.backgroundStyle))
    this.lineStyleInProgress = this.customize(this.lineStyle, PlanLineStyle, new PlanLineStyle(defaults.lineStyleInProgress))
    this.backgroundStyleInProgress = this.customize(this.backgroundStyle, PlanBackgroundStyle, new PlanBackgroundStyle(defaults.backgroundStyleInProgress))
    this.textStyle = this.customize(this.textStyle, PlanTextStyle, new PlanTextStyle(defaults.textStyle))
    this.ports = this.castArray(this.ports, PlanPort)

    // Properties of the item while displayed on the cross-section view
    this.showOnlyOnCrossSection = parseBoolean(this.showOnlyOnCrossSection, false)
    if (this.showOnCrossSection) {
      this.crossSection = this.crossSection || {}
      this.crossSection.rotation = Math.max(-360, Math.min(360, safeParseInt(this.crossSection?.rotation, defaults.crossSection.rotation)))
      if (!this.isPointBased) {
        this.crossSection.coordinates = this.cast(this.crossSection?.coordinates, Point, defaults.crossSection.cordinates)
        if (this.crossSection.coordinates && !this.crossSection.coordinates.isDefined) {
          this.crossSection.coordinates = defaults.crossSection.coordinates
        }
      }
    }

    // Create default joint style, if shape is line-like
    if (this.hasEditablePoints) {
      const isWall = this.isWall || this.isYard
      this.jointStyle = new PlanPointStyle({
        radius: Math.max(5, Math.ceil(this.lineStyle.width * 1.2)),
        color: isWall ? 'red' : 'white',
        hoverColor: isWall ? 'red' : this.lineStyle.color,
        borderColor: isWall ? 'red' : this.lineStyle.color,
        borderWidth: 1
      })
    }
  }

  /**
   * List of properties which should be always stored, regardless whether thay have default value or not.
   * For simple shapes such as rectangles or circles we do want to preserve their original dimensions,
   * even if default changes later, otherwise it would magically change size of all existing shapes.
   * For specific predefined shapes such as devices we do want them to react to defaults changes.
   * @type {Array[String]}
   */
  get persistentProperties () {
    return []
  }

  /**
   * Serializes the plan item to JSON.
   * Inside this method we perform systematic cleaning of all information
   * which is not strictly required to be saved. This includes:
   * 1. Properties recalculated at runtime
   * 2. Properties which still have default values
   * 3. Properties which always have fixed values which user can never change
   * Point 3. is of crucial importance. For example some shapes, such as devices, have fixed width and height.
   * If we save this width and height, then in future we change our mind and want to use a new width and height,
   * the shape must use the new value, instead of using the stored one.
   * @returns {Object}
   */
  toJSON () {
    const type = this.constructor.type
    const { id, created, label, defaults, coordinates: { x, y, z }, layer } = this
    const result = {
      id,
      type,
      created: created.toISOString(),
      label,
      coordinates: { x, y, z },
      layer,
      ...withoutProperties(
        { ...this },
        [
          'id',
          'created',
          'label',
          'type',
          'coordinates',
          'layer'
        ])
    }

    // Delete default values
    this.clearDefaults(result, null, this.persistentProperties)

    if (result.textStyle?.sameAs(defaults.textStyle)) {
      delete result.textStyle
    }
    if (result.lineStyle?.sameAs(defaults.lineStyle)) {
      delete result.lineStyle
    }
    if (result.backgroundStyle?.sameAs(defaults.backgroundStyle)) {
      delete result.backgroundStyle
    }
    if (this.scale.sameAs(defaults.scale)) {
      delete result.scale
    }
    if (result.labelPosition == null) {
      delete result.labelPosition
    }
    if (!result.showOnlyOnCrossSection) {
      delete result.showOnlyOnCrossSection
    }

    // Delete sizes if item has fixed size
    if (!this.canResize) {
      delete result.width
      delete result.height
      delete result.radius
    }

    // Delete empty coordinates if point-based item
    if (this.isPointBased) {
      delete result.coordinates
      if (result.crossSection) {
        delete result.crossSection.coordinates
      }
    }

    // Delete runtime data
    delete result.data
    delete result.shape
    delete result.jointStyle
    delete result.inProgress
    delete result.repeaterId
    delete result.root
    delete result.isCrossFloor
    delete result.lineStyleInProgress
    delete result.backgroundStyleInProgress

    // Serialize
    const sample = JSON.parse(JSON.stringify(result))

    // Delete empty styles etc.
    if (isEmptyOrNull(sample.lineStyle)) {
      delete result.lineStyle
    }
    if (isEmptyOrNull(sample.backgroundStyle)) {
      delete result.backgroundStyle
    }
    if (isEmptyOrNull(sample.textStyle)) {
      delete result.textStyle
    }

    return result
  }

  /**
   * Debug string describing the item, useful for log statements
   * @param {Boolean} details If true, detailed description is returned
   * @returns {String}
   */
  toString (details = true) {
    const { type, tag, tagIndex, deviceType, antennaType, splitterType, cableType, realLength, serialNumber, isConnector, start, end } = this
    let label = `${(deviceType || antennaType || splitterType || cableType || type).toUpperCase()}${tag ? ` [${tag}${tagIndex}]` : ''}`.trim()
    if (deviceType && serialNumber) {
      label = `${label} ${serialNumber}`
    }
    if (details) {
      if (cableType && realLength) {
        label = `${label} ${realLength}m`
      }
      if (isConnector && start?.item && end?.item) {
        label = `${label} [${start.item.toString()} ==> ${end.item.toString()}]`
      }
    }
    return label
  }

  /**
   * Human-friendly representation of the item, useful for the UI
   * @param {Boolean} details If true, detailed description is returned
   * @returns {String}
   */
  // eslint-disable-next-line no-unused-vars
  toHumanString (details) {
    const { type } = this
    let label = PlanItemName[type]
    return label
  }

  /**
   * Checks whether the item is one of the specified types
   * @param {Array[PlanItemType]} types Types to check
   * @returns {Boolean}
   */
  is (...types) {
    return types.some(type => this.constructor.type === type)
  }

  /**
   * Checks whether the item is not one of the specified types
   * @param {Array[PlanItemType]} types Types to check
   * @returns {Boolean}
  */
  isNot (...types) {
    return !types.some(type => this.constructor.type === type)
  }

  /**
   * Indicates that the shape should be displayed on the regular floor view.
   * This is false for some items which were added on the cross-section view
   * such as custom shapes or legends, and also for cross-floor connectors.
   * @type {Boolean}
   */
  get showOnFloor () {
    return this.floorId && !this.showOnlyOnCrossSection
  }

  /**
   * Indicates that the shape should be displayed on the cross-section view.
   * This is true for all equipment items, which are automatically appearing
   * on the cross-section view on their respective floors.
   * @type {Boolean}
   */
  get showOnCrossSection () {
    // Don't show connector ends on the cross section
    if (this.isConnector && this.partOf) return false

    // Don't show plugs on the cross section
    if (this.isPlug) return false

    if (this.isOnItemLayer) {
      // Equipment item is shown on the cross-section view unconditionally
      if (this.is(PlanItemType.Device, PlanItemType.Antenna, PlanItemType.Splitter, PlanItemType.Cable)) return true

      // Other items can be shown on the cross-section only if they were explicitly added
      // to the cross-section view
      if (this.is(PlanItemType.Text, PlanItemType.Legend)) return Boolean(this.showOnlyOnCrossSection)
    }

    return false
  }

  /**
   * Indicates that the shape, although is not automatically displayed on the cross-section,
   * can still be added manually to it. This is true for items such as text or labels.
   * Any such item added to the cross-section view remains visibly only on this view!
   * @type {Boolean}
   */
  get canAddToCrossSection () {
    return this.is(PlanItemType.Text, PlanItemType.Legend)
  }

  /**
   * Indicates that the item has been added while on cross-section view
   * and therefore should only be shown on it, never on the floor view.
   * @type {Boolean}
   */
  showOnlyOnCrossSection

  /**
   * Indicates that the shape is point-based,
   * made of connected points with absolute coordinates
   * @type {Boolean}
   */
  get isPointBased () {
    return this.is(PlanItemType.Line,
      PlanItemType.Connector,
      PlanItemType.Cable,
      PlanItemType.Polygon,
      PlanItemType.Ruler
    )
  }

  /**
   * When adding the item points, indicates whether to show
   * markers for each added points, for better visibility of the shape
   * @type {Boolean}
   */
  get showPointMarkers () {
    return this.isPointBased && !this.isRuler
  }

  /**
   * Indicates that the shape is a line
   * made of connected points with absolute coordinates
   * @type {Boolean}
   */
  get isLine () {
    return this.is(PlanItemType.Line,
      PlanItemType.Connector,
      PlanItemType.Cable,
      PlanItemType.Ruler
    )
  }

  /**
   * Indicates that the shape is a closed shape.
   * Open shapes are line-like shapes
   */
  get isClosedShape () {
    return !this.is(PlanItemType.Line, PlanItemType.Connector, PlanItemType.Cable)
  }

  /**
   * Indicates that the shape can be removed from the plan.
   * Some items such as in/out risers are fixed and cannot be deleted.
   * @type {Boolean}
   */
  get canRemove () {
    if (this.isWall) return false
    if (this.is(PlanItemType.Riser, PlanItemType.Plug)) return false
    return true
  }

  /**
   * Indicates that item can be cloned
   * @type {Boolean}
   */
  get canClone () {
    return !(
      this.isWall ||
      this.isYard ||
      this.is(
        PlanItemType.Connector,
        PlanItemType.Cable,
        PlanItemType.Riser,
        PlanItemType.Plug,
        PlanItemType.Ruler
      )
    )
  }

  /**
   * Indicates that item can be copied and pasted using clipboard
   * @type {Boolean}
   */
  get canCopyPaste () {
    if (this.isPlug) return false
    if (this.isConnector && this.partOf) return false
    return true
  }

  /**
   * Indicates that the shape can be resized
   * @type {Boolean}
   */
  get canResize () {
    return this.is(
      PlanItemType.Rectangle,
      PlanItemType.Circle,
      PlanItemType.Ellipse,
      PlanItemType.Curve,
      PlanItemType.Image,
      PlanItemType.Icon,
      PlanItemType.Text,
      PlanItemType.Beam
    )
  }

  /**
   * Indicates that the shape changes looks when hovered over
   * @type {Boolean}
   */
  get refreshOnHover () {
    return this.is(
      PlanItemType.Line,
      PlanItemType.Connector,
      PlanItemType.Cable,
      PlanItemType.Polygon
    )
  }

  /**
   * Indicates that the shape changes looks when selected
   * @type {Boolean}
   */
  get refreshOnSelect () {
    return this.is(
      PlanItemType.Line,
      PlanItemType.Connector,
      PlanItemType.Cable,
      PlanItemType.Polygon
    )
  }

  /**
   * Indicates that the shape changes looks when moved
   * @type {Boolean}
   */
  get refreshOnMove () {
    return this.is(
      PlanItemType.Line,
      PlanItemType.Connector,
      PlanItemType.Cable,
      PlanItemType.Polygon
    )
  }

  /**
   * Indicates that the shape aspect ratio has to be kept when resizing
   * @type {Boolean}
   */
  get keepRatio () {
    return this.isNot(
      PlanItemType.Rectangle,
      PlanItemType.Ellipse,
      PlanItemType.Curve,
      PlanItemType.Text
    )
  }

  /**
   * Indicates that the shape can be freely rotated
   * @type {Boolean}
   */
  get canRotate () {
    return this.isDirectionalAntenna
  }

  /**
   * Indicates that the shape can be rotated by predefined angles
   * using `flip` context menu
   * @type {Boolean}
   */
  get canFlip () {
    return this.isDirectionalAntenna || this.is(
      PlanItemType.Rectangle,
      PlanItemType.Circle,
      PlanItemType.Ellipse,
      PlanItemType.Curve,
      PlanItemType.Image,
      PlanItemType.Icon,
      PlanItemType.Text,
      PlanItemType.Device,
      PlanItemType.Splitter,
      PlanItemType.Beam
    )
  }

  /**
   * Indicates that the item shape can be scaled
   * @type {Boolean}
   */
  get canScale () {
    return this.is(
      PlanItemType.Device,
      PlanItemType.Splitter,
      PlanItemType.Antenna
    )
  }

  /**
   * Indicates that the shape z-order
   * can be rearranged
   * @type {Boolean}
   */
  get canReorder () {
    if (this.is(PlanItemType.Connector, PlanItemType.Cable, PlanItemType.Riser, PlanItemType.Plug)) return false
    if (this.isWall || this.isYard) return false
    return true
  }

  /**
   * Indicates whether the item can be resized or reshaped
   * @type {Boolean}
   */
  get canTransform () {
    return this.canResize
  }

  /**
   * Indicates that the default shape selector cannot be used.
   * Some shapes have their own indication of selection, such as lines or connectors.
   * @type {Boolean}
   */
  get hasCustomSelector () {
    return this.is(
      PlanItemType.Line,
      PlanItemType.Ruler,
      PlanItemType.Connector,
      PlanItemType.Cable
    )
  }

  /**
   * Indicates whether user can edit the properties of the item using the toolbox.
   * Some items, such as building walls, should not be editable there,
   * as their properties are fixed
   * @type {Boolean}
   */
  get canEditProperties () {
    return !(
      this.isWall ||
      this.isYard ||
      this.isRiser ||
      this.isRuler
    )
  }

  /**
   * Indicates that the shape should have visible joints,
   * allowing to deform the shape by dragging them
   * @type {Boolean}
   */
  get hasEditablePoints () {
    return this.isPointBased
  }

  /**
   * Indicates whether user can edit points on the item
   * @type {Boolean}
   */
  get canEditPoints () {
    return this.hasEditablePoints && !this.isLocked
  }

  /**
   * Checks whether item is a transient automatically created item, such as risers, plugs and into-plug cables
   * Transient items can be deleted automatically when conditions change, they aren't taken into any BOM calculations etc.
   * @type {Boolean}
   */
  get isTransient () {
    return this.is(
      PlanItemType.Riser,
      PlanItemType.Plug) ||
      (this.isConnector && this.partOf)
  }

  /**
   * Checks whether item represents a dynamic connector
   * between two items
   * @type {Boolean}
   */
  get isConnector () {
    return this.is(PlanItemType.Connector, PlanItemType.Cable)
  }

  /**
   * Checks whether item represents a cable
   * @type {Boolean}
   */
  get isCable () {
    return this.is(PlanItemType.Cable)
  }

  /**
   * Checks whether item represents a device
   * @type {Boolean}
   */
  get isDevice () {
    return this.is(PlanItemType.Device)
  }

  /**
   * Checks whether the device is one of the specified types
   * @param {Array[DeviceType]} types Device types to check
   * @returns {Boolean}
   */
  isDeviceType (...types) {
    return this.deviceType && types.includes(this.deviceType)
  }

  /**
   * Checks whether the item is a repeater device
   * @returns {Boolean}
   */
  get isRepeater () {
    return this.deviceType === DeviceType.Repeater
  }

  /**
   * Checks whether the item is a lineamp device
   * @returns {Boolean}
   */
  get isLineamp () {
    return this.deviceType === DeviceType.LineAmp
  }

  /**
   * Checks whether item represents an antenna
   * @type {Boolean}
   */
  get isAntenna () {
    return this.is(PlanItemType.Antenna)
  }

  /**
   * Checks whether item represents a directional antenna
   * @type {Boolean}
   */
  get isDirectionalAntenna () {
    return false
  }

  /**
   * Indicates that antenna can only be used as external
   * @type {Boolean}
   */
  get isExternalAntenna () {
    return false
  }

  /**
   * Checks whether the antenna is one of the specified types
   * @param {Array[AntennaType]} types Antenna types to check
   * @returns {Boolean}
   */
  isAntennaType (...types) {
    return this.antennaType && types.includes(this.antennaType)
  }

  /**
   * Checks whether we have to do with an outdoor antenna
   * @type {Boolean}
   */
  get isOutdoorAntenna () {
    return this.antennaType === 'yagi' || this.antennaType === 'laser'
  }

  /**
   * Checks whether item represents a splitter
   * @type {Boolean}
   */
  get isSplitter () {
    return this.is(PlanItemType.Splitter)
  }

  /**
   * Checks whether item represents a text block
   * @type {Boolean}
   */
  get isText () {
    return this.is(PlanItemType.Text)
  }

  /**
   * Checks whether item represents a piece of equipment - device, antenna, splitter or cable
   * @type {Boolean}
   */
  get isEquipment () {
    return this.is(PlanItemType.Device, PlanItemType.Antenna, PlanItemType.Splitter, PlanItemType.Cable)
  }

  /**
   * Checks whether item represents a piece of equipment - device, antenna, splitter or cable
   * @type {Boolean}
   */
  get isWall () {
    return this.key === 'wall'
  }

  /**
   * Checks whether item represents a piece of equipment - device, antenna, splitter or cable
   * @type {Boolean}
   */
  get isYard () {
    return this.key === 'yard'
  }

  /**
   * Checks whether item represents a legend
   * @type {Boolean}
   */
  get isLegend () {
    return this.is(PlanItemType.Legend)
  }

  /**
   * Checks whether item represents a cable riser
   * @type {Boolean}
   */
  get isRiser () {
    return this.is(PlanItemType.Riser)
  }

  /**
   * Checks whether item represents a cable plug
   * @type {Boolean}
   */
  get isPlug () {
    return this.is(PlanItemType.Plug)
  }

  /**
   * Checks whether item represents a ruler
   * @type {Boolean}
   */
  get isRuler () {
    return this.is(PlanItemType.Ruler)
  }

  /**
   * Determines whether the item has a context menu
   * @returns  {Boolean}
   */
  get hasContextMenu () {
    return true
  }

  /**
  * Shape associated with the item
  * @type {Shape}
  * @description RUNTIME PROPERTY
  */
  shape

  /**
   * Unique identifier of an item
   * @type {String}
   */
  id

  /**
   * Identifier of the floor where the item belongs
   * @type {String}
   */
  floorId

  /**
   * Item index on the floor, increased monotonically as items are being added
   * @type {Number}
   */
  index

  /**
   * External key representing the item, such as device serial number
   * @type {String}
   */
  key

  /**
   * Identifier of a root item to which the item is linked in the hierarchy
   * @type {String}
   * @description RUNTIME
   */
  root

  /**
   * Generic item name
   * @type {String}
   */
  get name () {
    return PlanItemName[this.type]
  }

  /**
   * Creation date
   * @type {Date}
   */
  created

  /**
   * User-friendly label
   * @type {String}
   */
  label

  /**
   * Custom position of the label
   */
  labelPosition

  /**
   * Automatically generated label, usually containing the item type and subtype
   * @type {String}
   */
  get autoLabel () {
    return PlanItemName[this.type]
  }

  /**
   * If true, the label is fixed in place and user cannot move it elsewhere
   */
  get isLabelFixed () {
    return false
  }

  /**
   * If true, the label remains horizontal regardless of rotation of the item
   */
  get isLabelHorizontal () {
    return false
  }

  /**
   * Item tag
   * @type {String}
   */
  tag

  /**
   * Item tag index
   * @type {Number}
   */
  tagIndex

  /**
   * Determines whether the item can have a tag
   * @type {Boolean}
   */
  get canHaveTag () {
    return this.is(PlanItemType.Device, PlanItemType.Antenna)
  }

  /**
   * Clears item tag
   */
  clearTag () {
    this.tag = undefined
    this.tagIndex = undefined
  }

  /**
   * Indicates an item in progress of being added.
   * For some items this means different rendering -
   * for example polygons are closed only after all
   * their points have been added.
  * @description RUNTIME PROPERTY
   */
  inProgress

  /**
   * Indicates whether the shape has label
   * @type {Boolean}
   */
  get hasLabel () {
    return this.isNot(PlanItemType.Text)
  }

  /**
  * Detailed description
  * @type {String}
  */
  description

  /**
   * Layer to which the item belongs
   * @type {String}
   */
  layer

  /**
   * Returns true if item belongs to item layer
   * @type {Boolean}
   */
  get isOnItemLayer () {
    return this.layer === PlanLayers.Items
  }

  /**
   * Returns true if item belongs to radiation layer
   * @type {Boolean}
   */
  get isOnRadiationLayer () {
    return this.layer === PlanLayers.Radiation
  }

  /**
   * Returns true if item belongs to the specified layer
   * @type {Boolean}
   */
  isOnLayer (layer) {
    return this.layer === layer
  }

  /**
   * Indicates whether user can modify the shape of the item
   * @type {Boolean}
   */
  isLocked

  /**
   * Item coordinates on the floor
   * @type {Point}
   */
  coordinates

  /**
   * Properties applicable on the cross-section view,
   * such as cross-section coordinates
   * @type {Object}
   */
  crossSection

  /**
  * X coordinate
  * @type {Number}
  */
  get x () {
    return this.coordinates.x
  }
  set x (value) {
    this.coordinates.x = value
  }

  /**
   * Y coordinate
   * @type {Number}
   */
  get y () {
    return this.coordinates.y
  }
  set y (value) {
    this.coordinates.y = value
  }

  /**
   * Z order
   * @type {Number}
   */
  get z () {
    return this.coordinates.z
  }
  set z (value) {
    this.coordinates.z = value
  }

  /**
   * Sets item coordinates
   * @param {Point} coordinates Coordinates to set
   * @param {Boolean} isCrossSection Indicates on which view the coordinates apply
   */
  setCoordinates (coordinates, isCrossSection) {
    if (this.isPointBased) return

    if (isCrossSection) {
      if (!this.crossSection) {
        this.crossSection = {}
      }
      this.crossSection.coordinates = Point.from(coordinates)
    } else {
      this.coordinates = Point.from(coordinates)
    }
  }

  /**
   * Width, on X axis
   * @type {Number}
   */
  width

  /**
   * Length, on Y axis
   * @type {Number}
   */
  height

  /**
   * Radius
   * @type {Number}
   */
  radius

  /**
   * Indicates that shape is circular
   * @returns {Boolean}
   */
  get isCircular () {
    return this.is(PlanItemType.Circle, PlanItemType.Ellipse) ||
      this.isIndoorOmni ||
      this.isLaser
  }

  /**
   * Radius on the X axis.
   * Also implemented in {@link Ellipse} shape, makes handling circular shapes easier
   * @returns {Number}
   */
  get radiusX () {
    return this.radius
  }

  /**
   * Radius on the Y axis.
   * Also implemented in {@link Ellipse} shape, makes handling circular shapes easier
   * @returns {Number}
   */
  get radiusY () {
    return this.radius
  }

  /**
   * Additional data associated with the item, such as device details
   * @type {any}
   * @description RUNTIME PROPERTY
   */
  data

  /**
   * Returns current coordinates of the item,
   * optionally on the specified floor.
   * We return a clone, as this property is often used
   * to perform calculations, and we must prevent
   * any inadvertent mutation of the item's position
   * during such calculations.
   * @param {Boolean} isCrossSection Specifies whether we need coordinates of the item on the cross-section
   * rather than on the floor to which it belongs. Notice that some items cannot be displayed on the cross-section!
   * @returns {Point}
   */
  getCoordinates (isCrossSection) {
    if (!this.isPointBased) {
      if (isCrossSection) {
        if (this.showOnCrossSection) {
          return this.crossSection.coordinates.copy()
        }
      } else {
        return this.coordinates.copy()
      }
    }
  }

  /**
   * Returns points making up the shape,
   * if {@link isPointBased} shape
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]}
   */
  // eslint-disable-next-line no-unused-vars
  getPoints (isCrossSection) {
    return []
  }

  /**
   * Assigns points making up the shape,
   * if {@link isPointBased} shape
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @param {Array[Point]} points Points to assign
   */
  // eslint-disable-next-line no-unused-vars
  setPoints (points, isCrossSection) {
  }

  /**
   * Returns a rectangle containing the shape
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Rectangle}
   */
  getBounds (isCrossSection) {
    const { width, height, radiusX, radiusY, isPointBased, isConnector } = this
    const bounds = new Rectangle()

    if (isConnector) {
      const points = this.getTurns(isCrossSection)
      if (points.length > 0) {
        bounds.setBounds(Rectangle.fromPoints(points))
      } else {
        bounds.setBounds(Rectangle.Zero)
      }

    } else if (isPointBased) {
      const points = this.getPoints(isCrossSection)
      if (points.length > 0) {
        bounds.setBounds(Rectangle.fromPoints(points))
      } else {
        bounds.setBounds(Rectangle.Zero)
      }

    } else {
      const coordinates = isCrossSection
        ? this.crossSection?.coordinates
        : this.coordinates

      if (coordinates) {
        const { x, y } = coordinates
        if (radiusX != null && radiusY != null) {
          // Circular shapes
          bounds.setBounds({
            x: x - radiusX,
            y: y - radiusY,
            width: radiusX * 2,
            height: radiusY * 2
          })

        } else if (width != null && height !== null) {
          // Shapes delimited by a rectangle
          bounds.setBounds({ x, y, width, height })
        }
      }
    }

    return bounds
  }

  /**
   * Returns a rectangle containing the shape,
   * scaled to the specified scale
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Rectangle}
   */
  getScaledBounds (scale, isCrossSection) {
    const { isPointBased, isCircular, canScale } = this
    if (!scale || !canScale || isPointBased) return this.getBounds(isCrossSection)

    const coordinates = isCrossSection ? this.crossSection?.coordinates : this.coordinates
    if (!coordinates) return this.getBounds(isCrossSection)

    const { x, y } = coordinates
    const bounds = new Rectangle()
    const width = !isCircular ? this.width * scale.x : null
    const height = !isCircular ? this.height * scale.y : null
    const radiusX = isCircular ? this.radiusX * scale.x : null
    const radiusY = isCircular ? this.radiusY * scale.y : null

    if (isCircular) {
      // Circular shapes
      bounds.setBounds({
        x: x - radiusX,
        y: y - radiusY,
        width: radiusX * 2,
        height: radiusY * 2
      })

    } else if (width != null && height !== null) {
      // Shapes delimited by a rectangle
      bounds.setBounds({ x, y, width, height })
    }

    return bounds
  }

  /**
   * Returns the first point of the item.
   * Implemented in shapes which are made of points.
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point}
   */
  getFirstPoint (isCrossSection) {
    return this.getPoints(isCrossSection)[0]
  }

  /**
   * Rotation in degrees, from -360 to 360
   * @type {Number}
   */
  rotation

  /**
   * Returns item rotation
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Number}
  */
  getRotation (isCrossSection) {
    return ((isCrossSection ? this.crossSection.rotation : this.rotation) || 0) % 360
  }

  /**
   * Sets the new rotation of the item in degrees
   * @param {Number} degrees Rotation in degrees
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Number} Current rotation of the item
   */
  rotate (degrees, isCrossSection) {
    // Set the rotation, stay within 360 degrees
    const rotation = Math.round(degrees) % 360
    if (isCrossSection) {
      this.crossSection.rotation = rotation
    } else {
      this.rotation = rotation
    }
    return rotation
  }

  /**
   * Rotates the item by the specified amount of degrees
   * @param {Number} degrees Number of degrees to add to the current rotation of the item
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Number} Current rotation of the item
   */
  rotateBy (degrees, isCrossSection) {
    if (degrees) {
      // Add the rotation
      const rotation = this.getRotation(isCrossSection) + degrees
      return this.rotate(rotation, isCrossSection)
    }
  }

  /**
   * Scaling of the shape on both axes,
   * from 0 to infinity where 1 is normal scale
   * @type {PlanScale}
   */
  scale

  /**
   * Indicates that item has been transformed - rotated and/or scaled
   * @type {Boolean}
   */
  get isTransformed () {
    return this.rotation !== 0 || this.scale.value !== 1
  }

  /**
   * Blur radius
   * @type {Number}
   */
  blurRadius

  /**
   * Indicates whether the item is hidden
   * @type {Boolean}
   */
  isHidden

  /**
   * Indicates whether the item is visible
   * @type {Boolean}
   */
  get isVisible () {
    return !this.isHidden
  }
  set isVisible (value) {
    this.isHidden = !value
  }

  /**
   * Checks whether the data of the item is valid.
   * Invalid items will be discarded on load, to prevent the application from crashing
   * @type {Boolean}
   */
  get isValid () {
    return true
  }

  /**
   * Shows the item
   */
  show () {
    this.isHidden = false
  }

  /**
   * Hides the item
   */
  hide () {
    this.isHidden = true
  }

  /**
   * If true, the item can be selected and manipulated
   * @type {Boolean}
   */
  get canSelect () {
    return true
  }

  /**
   * If true, the item can be moved around
   * @type {Boolean}
   */
  get canMove () {
    return !(
      this.isRuler ||
      this.is(
        PlanItemType.Line,
        PlanItemType.Connector,
        PlanItemType.Cable)
    )
  }

  /**
   * Connection ports on the item
   * @type {Array[PlanPort]}
   */
  ports

  /**
   * The number of ports
   * @type {Number}
   */
  get portCount () {
    return this.ports ? this.ports.length : 0
  }

  /**
   * Returns the first (default) connection port of the item
   * @type {PlanPort}
   */
  get port () {
    return this.ports ? this.ports[0] : undefined
  }

  /**
   * Checks whether the item has any ports
   * @type {Boolean}
   */
  get hasPorts () {
    return Boolean(this.port)
  }

  /**
   * Checks whether the item has any active ports
   * @type {Boolean}
   */
  get hasActivePorts () {
    return this.ports ? this.ports.some(port => port.isActive) : false
  }

  /**
   * Checks whether the item has a port with the specified identifier
   * @param {Number} id Port identifier
   * @returns {Boolean}
   */
  hasPort (id) {
    return this.ports ? this.ports.some(p => p.id === id) : false
  }

  /**
   * Line or border style for the shape.
   * @type {LineStyle}
   * @description Shapes which actually use this style
   * must provide the default value in {@link defaults}
   */
  lineStyle

  /**
   * Background style
   * @type {PlanBackgroundStyle}
   * @description Shapes which actually use this style
   * must provide the default value in {@link defaults}
   */
  backgroundStyle

  /**
   * Line or border style for the shape when it's being drawn by the user.
   * @type {LineStyle}
   * @description Shapes which actually use this style
   * must provide the default value in {@link defaults}
   */
  lineStyleInProgress

  /**
   * Background style for the shape when it's being drawn by the user.
   * @type {PlanBackgroundStyle}
   * @description Shapes which actually use this style
   * must provide the default value in {@link defaults}
   */
  backgroundStyleInProgress

  /**
   * Label style
   * @type {PlanTextStyle}
   * @description Shapes which actually use this style
   * must provide the default value in {@link defaults}
   */
  textStyle

  /**
   * Line joint style
   * @type {PlanPointStyle}
   * @description RUNTIME
   */
  jointStyle

  /**
  * Assigns custom data to the item
  * @param {any} data
  * @returns {PlanItem}
  */
  setData (data) {
    this.data = data
    return this
  }

  /**
   * Checks whether the item is at the specified position.
   * @param {Point} position Position to check
   * @returns {Boolean}
   */
  isAt (position) {
    if (position) {
      return this.x === position.x && this.y === position.y
    }
  }

  /**
   * Moves the item to the specified position
   * @param {Point} position Position to move the item to
   * @param {Boolean} isCrossSection If true, the coordinates on cross section have been changed,
   * otherwise just the ordinary coordinates on the floor
   * @param {Point} delta Position change, optional
   * @returns {PlanItem}
   */
  // eslint-disable-next-line no-unused-vars
  moveTo (position, isCrossSection, delta) {
    // Only store position for items not based on absolute points!
    if (position && this.canMove && !this.isPointBased) {
      const coordinates = isCrossSection ? this.crossSection?.coordinates : this.coordinates
      coordinates?.moveTo(Point.from(position).round())
    }
    return this
  }

  /**
   * Moves the item by the specified delta.
   * @param {Point} delta Delta to move the item by
   * @param {Boolean} isCrossSection If true, the coordinates on cross section have been changed,
   * otherwise just the ordinary coordinates on the floor
   * @returns {PlanItem}
   */
  moveBy (delta, isCrossSection) {
    if (delta && this.canMove) {
      const coordinates = isCrossSection ? this.crossSection?.coordinates : this.coordinates
      coordinates?.moveBy(Point.from(delta).round())
    }
    return this
  }

  /**
   * Moves shape point to the specified new coordinates
   * @param {Number} index Point index
   * @param {Point} position Position to which the point was moved
   * @param {Point} align If true, points are aligned to each other
   * to ensure proper straight angles when movements are minuscule
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point} Moved point
   */
  // eslint-disable-next-line no-unused-vars
  movePoint (index, position, align, isCrossSection) {
  }

  /**
   * Returns the specified point of the shape
   * @param {Number} index Point index
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point}
  */
  getPoint (index, isCrossSection) {
    const points = this.getPoints(isCrossSection) || []
    return points[index]
  }

  /**
   * Returns the preceding point of the specified one
   * @param {Array[Point]} points Points to select from
   * @param {Number} index Point index
   * @param {Boolean} loop If true, we consider points as closed polygon
   * @returns {Point}
   */
  getPreviousPoint (points, index, loop) {
    if (index > 0) {
      return points[index - 1]
    } else if (loop) {
      return points[points.length - 1]
    }
  }

  /**
   * Returns the point following the specified one
   * @param {Array[Point]} points Points to select from
   * @param {Number} index Point index
   * @param {Boolean} loop If true, we consider points as closed polygon
   * @returns {Point}
   */
  getNextPoint (points, index, loop) {
    if (index < points.length - 1) {
      return points[index + 1]
    } else if (loop) {
      return points[0]
    }
  }

  /**
   * Aligns the coordinates of {@link point} point to coordinates
   * of the {@link previous} and {@link next} point, if delta
   * is smaller than the specified {@link threshold}, so that we get
   * nice and straight lines.
   * @param {Point} previous Previous point
   * @param {Point} point Point to align
   * @param {Point} next Next point
   * @param {Number} threshold Alignment threshold
   * @returns {Point} Aligned point
   */
  alignPoint (previous, point, next, threshold) {
    if (point && threshold > 0) {
      if (previous) {
        if (Math.abs(point.x - previous.x) <= threshold) {
          point.x = previous.x
        }
        if (Math.abs(point.y - previous.y) <= threshold) {
          point.y = previous.y
        }
      }
      if (next) {
        if (Math.abs(point.x - next.x) <= threshold) {
          point.x = next.x
        }
        if (Math.abs(point.y - next.y) <= threshold) {
          point.y = next.y
        }
      }
    }
    return point
  }

  /**
   * Resizes the item
   * @param {Number} width New width
   * @param {Number} height New height
   */
  resize (width, height) {
    if (this.canResize) {
      this.width = width == null ? this.width : width
      this.height = height == null ? this.height : height
    }
  }

  /**
   * Resizes the item by the specified delta
   * @param {Number} width New width
   * @param {Number} height New height
   */
  resizeBy (width, height) {
    if (this.canResize) {
      this.width = width == null ? this.width : Math.max(1, this.width + width)
      this.height = height == null ? this.height : Math.max(1, this.height + height)
    }
  }

  /**
   * Removes all {@link ports}
   */
  clearPorts () {
    this.ports = undefined
  }

  /**
   * Adds port to item {@link ports}
   * @param {String} id Unique port identifier
   * @param {PlanPortType} type Port type
   * @param {String} label User-friendly label
   * @returns {PlanPort}
   */
  addPort (id = PlanPortType.In, type = PlanPortType.In, label) {
    if (!id) throw new Error('Port identifier is required')
    if (!type) throw new Error('Port type is required')
    if (!this.ports) this.ports = []
    if (this.ports.some(p => p.id === id)) throw new Error('Port identifier must be unique')
    const itemId = this.id
    const port = new PlanPort({ id, type, label, itemId })
    this.ports = [...this.ports, port]
    return port
  }

  /**
   * Adds a passive port to item {@link ports}
   * @param {String} id Unique port identifier
   * @param {PlanPortType} type Port type
   * @param {String} label User-friendly label
   * @returns {PlanPort}
   */
  addPassivePort (id = PlanPortType.In, type = PlanPortType.In, label) {
    const port = this.addPort(id, type, label)
    port.isActive = false
    return port
  }

  /**
   * Returns the specified port
   * @param {String} id Port identifier
   * @returns {PlanPort}
   */
  getPort (id) {
    return (this.ports || []).find(port => port.id === id)
  }

  /**
   * Indicates that current item is connected to the specified item.
   * Override in descendants such as connectors, cables etc.
   * @param {PlanItem} item
   * @returns {Boolean}
   */
  isConnectedTo (item) {
    if (item) {
      return false
    }
  }

  /**
   * Finds an index at which the specified point belongs on the specified line or polygon,
   * within the specified fuzzy radius
   * @param {Point} point Point to check
   * @param {Array[Point]} points Line or polygon coordinates
   * @param {Number} fuzzy Search radius
   * @returns {Number} Index at which the point belongs
   */
  findPointIndex (target, shape, fuzzy = 0) {
    if (!target) return -1
    if (!(shape?.length > 0)) return -1

    // For closed shapes add the first point at the end,
    // to also check the edge between shape end and shape start
    const points = this.isClosedShape
      ? [...shape, shape[0]]
      : [...shape]

    // Find the edge on which the point belongs
    for (let i = 0; i < points.length - 1; i++) {
      const p1 = points[i]
      const p2 = points[i + 1]
      if (pointBelongsToVector(target, p1, p2, fuzzy)) {
        return i
      }
    }

    return -1
  }

  /**
   * Returns the index of the last point of the item
   * @returns {Number}
   */
  get lastPointIndex () {
    throw new Error('Not implemented')
  }

  /**
   * Adds a point to the item.
   * Implement in items where points can be actually edited.
   * @param {Point} position Point position
   * @param {Number} index Point index, optional. If not specified, the point will be added at the end.
   * @param {Point} align If true, added point will be aligned to other existing points
   * to ensure proper straight angles when movements are minuscule
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   */
  // eslint-disable-next-line no-unused-vars
  addPoint (position, index, align, isCrossSection) {
    throw new Error('Not implemented')
  }

  /**
   * Removes a point from the shape.
   * Implement in items where points can be actually edited.
   * @param {Number} index Index at which to remove the point
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   */
  // eslint-disable-next-line no-unused-vars
  removePoint (index, isCrossSection) {
    throw new Error('Not implemented')
  }

  /**
   * Sets item properties from the specified dictionary
   * @param {Dictionary<String, any|Function<PlanItem>} properties Properties to set
   * @description Properties are specified as dictionary, where keys represent:
   * - simple property names, such as `x` or `width`
   * - nested property names, such as `scale.x` or `backgroundStyle.color`
   * - special value `item`, which requires that the value of the key
   *   is a function plan item as input parameter, and modifying it
   */
  setProperties (properties = {}) {
    for (const [name, value] of Object.entries(properties)) {
      if (name === 'item') {
        value(this)
      } else {
        const currentValue = getPropertyValue(this, name, undefined, false)
        setPropertyValue(this, name, notNull(value, currentValue), false)
      }
    }
    this.normalize()
  }

  /**
   * Returns true when item supports the specified editable property
   * @param {String} name Property name, such as `x` or `backgroundStyle`
   * @returns {Boolean}
   */
  hasProperty (name) {
    if (['label'].includes(name)) {
      return !this.is(PlanItemType.Beam, PlanItemType.Legend)
    }

    if (['x', 'y'].includes(name)) {
      return !this.isPointBased
    }

    if (['width', 'height'].includes(name)) {
      return !this.isPointBased &&
        !this.is(PlanItemType.Circle, PlanItemType.Ellipse, PlanItemType.Curve, PlanItemType.Beam) &&
        !(this.is(PlanItemType.Circle, PlanItemType.Antenna) && this.isIndoorOmni)
    }

    if (['scale'].includes(name)) {
      return this.is(PlanItemType.Curve)
    }

    if (['rotation'].includes(name)) {
      return this.canRotate
    }

    if (['backgroundStyle'].includes(name)) {
      return this.is(PlanItemType.Circle, PlanItemType.Ellipse, PlanItemType.Rectangle, PlanItemType.Polygon, PlanItemType.Device, PlanItemType.Splitter)
    }

    if (['radius'].includes(name)) {
      return this.is(PlanItemType.Circle, PlanItemType.Ellipse, PlanItemType.Beam) ||
        (this.is(PlanItemType.Circle, PlanItemType.Antenna) && this.isIndoorOmni)
    }

    if (['innerRadius'].includes(name)) {
      return this.is(PlanItemType.Circle)
    }

    if (['blurRadius'].includes(name)) {
      return this.is(PlanItemType.Rectangle, PlanItemType.Circle, PlanItemType.Polygon, PlanItemType.Ellipse, PlanItemType.Line, PlanItemType.Beam)
    }

    return false
  }

  /**
   * Determine signal gain/loss at the item
   * @param {PlanLayout} layout Plan layout
   * @returns {Number}
   */
  getGain (layout) {
    if (layout) {
      return 0
    }
  }

  /**
   * Caps signal going out of the device
   * @param {Number} signal Outoing signal on device output, in `dB`
   * @returns {Number} Capped signal on device output, in `dB`
   */
  capSignal (signal) {
    return signal
  }

  /**
   * Indicates whether item details can be collected and displayed to the user
   * @type {Boolean}
   */
  get showDetails () {
    return false
  }

  /**
   * Returns a list of details about the item.
   * The entries have the following structure, with `icon` and `order` being optional.
   * Property `category` can be used to group the entries when displaying.
   * `{ category, key, label, description, icon, order }`
   * @param {PlanLayout} layout Plan layout
   * @param {PlanHierarchy} hierarchy Equipment hierarchy
   * @returns {Array}
   */
  getDetails (layout, hierarchy) {
    if (layout && hierarchy) {
      const items = []
      return items
    }
  }

  /**
   * Recently evaluated item details
   * @type {Array}
   */
  details

  /**
   * Indicates whether item details have been evaluated and are present
   * @type {Boolean}
   */
  get hasDetails () {
    return this.showDetails && this.details?.length > 0
  }
}
