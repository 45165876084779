import { distinctValues } from '@stellacontrol/utilities'
import { plmnToOperator } from './utilities'

/**
 * Extracts the operators selected for neighbour cell scan
 * @param {Attachment} attachment
 * @returns {Object}
 */
export function getScanSelectedOperators (attachment) {
  if (!attachment?.isScan) return
  if (!attachment.data?.results) return
  const { neighbourResults: items, operatorNames: operators = {} } = attachment.data.results
  if (!(items?.length > 0)) return

  // Get individual cell results,
  // Resolve operator, get timing metrics,
  // Check whether they're for the scanning cell or neighboring cell,
  const selected = items
    .map(({ plmn }) => plmnToOperator(plmn, operators))
    .flatMap(item => item)

  return distinctValues(selected)
}
