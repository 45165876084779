<script>
import { mapGetters } from 'vuex'
import { Viewport } from '../../responsive'
import { ActionItem } from '../action-dropdown'

export default {
  props: {
    // Unique list name
    name: {
      type: String,
      required: true
    },
    // Data object property used as unique key
    rowKey: {
      required: true
    },
    // Column definition
    columns: {
      type: Array,
      required: true
    },
    // Data items to display
    items: Array,
    // Label to show when list is loading
    loadingLabel: String,
    // Label to show when list has no data
    noDataLabel: String,
    // Toggles `loading` state which displays loading indicator
    loading: Boolean,
    // If true, table has a border
    bordered: Boolean,
    // Pagination properties
    initialPagination: {
      default: () => ({
        page: 0,
        rowsPerPage: 0,
        sortBy: null,
        descending: false
      })
    },
    // If true, pagination is hidden
    hidePagination: Boolean,
    // If true, the list will be scrolled if there's more items than fit on the screen
    scroll: {
      type: Boolean,
      default: true
    },
    // Default sorting order
    sortBy: {
      type: String,
      value: 'name'
    },
    // If true, header will be always visible when scrolling the items.
    // Disabled by default as it interferes with displaying of list scrollbar.
    stickyHeader: Boolean,
    // Hide the list footer with item count, pager component etc.
    hideBottom: {
      type: Boolean,
      default: false
    },

    // Hide the table header
    hideHeader: {
      type: Boolean,
      default: false
    },
    // Turn on virtual scrolling for better performance of long tables.
    // Requires setting maxHeight as well.
    virtualScroll: {
      type: Boolean,
      default: false
    },
    // Fixed height of the table, used for example in combination with virtual scrolling
    maxHeight: {
      type: String
    },
    // Fixed height of the table item, used for example in combination with virtual scrolling
    itemHeight: {
      type: Number
    },
    // Actions to display in the list: { name, text, color }
    actions: Array,
    // Optional title for the actions popup
    actionsTitle: {
      default: null
    },
    // Column visibility callback for advanced scenarios,
    // such as when columns should be dependent on certain permissions etc.
    isColumnVisible: {
      type: Function,
      default: () => true
    },
    // Custom filter method
    filterMethod: {
      type: Function
    },
    // Custom cell class method
    cellClassMethod: {
      type: Function,
      default: () => ({})
    }
  },

  data () {
    return {
      console,
      pagination: {}
    }
  },

  computed: {
    ...mapGetters([
      'isDenseListView',
      'isCardView',
      'list',
      'listFilter',
      'listSortBy',
      'listSortDescending'
    ]),

    // List filter value
    filter () {
      return this.listFilter(this.name)
    },

    // Items visible in the list
    visibleItems () {
      const { filterMethod, filter, items } = this
      if (filterMethod) {
        return items.filter(item => filterMethod(item, filter))
      } else {
        return items
      }
    },

    /**
     * Returns the action marked as default
     */
    defaultAction () {
      const action = (this.actions || []).find(action => action.isDefault)
      return action ? new ActionItem(action) : undefined
    },

    /**
     * Calculates width of the actions column
     */
    actionsWidth () {
      if (this.actions && this.actions.length) {
        const charCount = (this.actions || [])
          .map(action => (action.text || action.name).length)
          .reduce((a, b) => a + b, 0)
        return 64 + charCount * 15
      } else {
        return 0
      }
    },

    // Currently visible columns.
    // Filters such as `desktopOnly` or `mobileOnly` are resolved here
    visibleColumns () {
      return this.columns
        .filter(column => column.desktopOnly ? Viewport.isNormalScreen : true)
        .filter(column => column.mobileOnly ? Viewport.isSmallScreen : true)
        .filter(column => this.isColumnVisible(column))
    },

    // List component style
    listStyle () {
      const { maxHeight } = this
      return {
        ...(maxHeight ? { 'max-height': maxHeight } : {})
      }
    }
  },

  methods: {
    // Notifies about selecting an item
    click (e, row) {
      this.$emit('click', e, row)
    },

    // Determines the title of the actions popup
    getActionsTitle (row) {
      const { actionsTitle } = this
      if (actionsTitle) {
        if (typeof actionsTitle === 'function') {
          return actionsTitle(row)
        }
        return actionsTitle
      }
    },

    // Executes the specified action
    executeAction (row, action) {
      this.$emit('action', row, action)
    },

    // Executes the default action on the specified item
    doubleClick (e, row) {
      const { defaultAction } = this
      if (defaultAction && defaultAction.canExecute(row)) {
        this.executeAction(row, defaultAction)
      } else {
        this.$emit('dblclick', e, row)
      }
    }
  },

  watch: {
    pagination (value) {
      this.$emit('paginate', value)
    }
  },

  created () {
    this.pagination = {
      ...this.initialPagination,
      sortBy: this.listSortBy(this.name),
      descending: this.listSortDescending(this.name)
    }
  }
}

</script>

<template>
  <div class="sc-list column items-stretch" :class="{ [name]: true }">
    <q-table v-model:pagination="pagination" :flat="!isCardView(name)" :bordered="bordered"
      :class="{ 'scroll': scroll, 'sticky-header': stickyHeader }" :dense="isDenseListView(name)"
      :grid="isCardView(name)" :filter="filterMethod ? undefined : filter" :sort-by="sortBy"
      :descending="true" :rows="visibleItems" :columns="visibleColumns" :row-key="rowKey"
      :loading="loading" :loading-label="loadingLabel || 'Loading ...'"
      :no-data-label="noDataLabel || 'No data available'" :hide-bottom="hideBottom"
      :hide-header="hideHeader" :virtual-scroll="virtualScroll" :virtualScrollItemSize="itemHeight"
      :style="listStyle" :hide-pagination="hidePagination" @row-click="click"
      @row-dblclick="doubleClick">

      <template v-slot:body-cell-icon="props">
        <q-td style="width: 32px;" :props="props" class="icon" :class="cellClassMethod(props.row)">
          <q-icon size="sm" :name="props.value.icon" :color="props.value.color || 'indigo-5'">
            <sc-tooltip v-if="props.value.tooltip" :text="props.value.tooltip"></sc-tooltip>
          </q-icon>
        </q-td>
      </template>

      <template v-slot:body-cell-email="props">
        <q-td :props="props" class="email" :style="props.style" :class="cellClassMethod(props.row)">
          <a class="item-link" v-if="props.value" :href="`mailto:${props.value}`">
            {{ props.value }}
            <sc-tooltip :text="`Send e-mail to ${props.value}`"></sc-tooltip>
          </a>
        </q-td>
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :style="{ width: `${actionsWidth}px`, ...(props.style || {}) }" :props="props"
          class="actions" :class="{ busy: props.row.isBusy, ...cellClassMethod(props.row) }">
          <sc-action-dropdown :data="props.row" :title="getActionsTitle(props.row)"
            :actions="actions"
            @action="action => executeAction(props.row, action)"></sc-action-dropdown>
          <q-inner-loading :showing="props.row.isBusy">
            <q-spinner-gears size="24px" color="grey-8"></q-spinner-gears>
          </q-inner-loading>
        </q-td>
      </template>

      <!--
        Pass all other custom slots from the parent,
        which might supply additional custom templates for cells.
      -->
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>
    </q-table>
  </div>
</template>

<style lang="scss">
.sc-list {
  flex: 1;
  position: relative;
  overflow: hidden;

  .scroll {
    overflow: auto;
    position: relative;

    .q-table thead tr {
      color: #7a7a7a;
      background-color: white;
    }

    .q-table td.q-td.not-applicable {
      background-color: #f8f8f8 !important;
      color: #a0a0a0 !important;
    }

    &.sticky-header {
      .q-table thead tr th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }
  }
}
</style>
