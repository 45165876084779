<script>

/**
 * Loading... component
 */
export default {
  props: {
    title: {
    },
    size: {
      default: 'md'
    }
  },

  data () {
    return {
      defaultTitle: 'Loading, please wait ...'
    }
  },

  computed: {
    iconSize () {
      const { size } = this
      return {
        'xs': '16px',
        'sm': '28px',
        'md': '40px',
        'lg': '64px',
        'xl': '96px'
      }[size]
    },

    textSize () {
      const { size } = this
      return {
        'xs': 'text-body2',
        'sm': 'text-subtitle2',
        'md': 'text-h6',
        'lg': 'text-h4',
        'xl': 'text-h3'
      }[size]
    },

    labelMargin () {
      const { size } = this
      const margin = {
        'xs': '0 0 0 5px',
        'sm': '0 0 0 10px',
        'md': '0 0 0 15px',
        'lg': '0 0 0 25px',
        'xl': '0 0 0 40px'
      }[size]
      return { margin }
    }
  }
}

</script>

<template>
  <div class="loading">
    <q-inner-loading :showing="true">
      <div class="row items-center">
        <q-spinner-gears :size="iconSize" color="grey-7" />
        <label class="text-weight-light text-black q-mt-md" :class="{ [textSize]: true }"
          :style="{ ...labelMargin }">
          {{ title || defaultTitle }}
        </label>
      </div>
    </q-inner-loading>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
</style>
