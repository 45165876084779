<script>
import { mapActions } from 'vuex'
import { clone, pluralize } from '@stellacontrol/utilities'
import { FormMixin, DialogMixin } from '@stellacontrol/client-utilities'
import { getDeviceLabel } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'

const dialog = 'portsense-settings-dialog'

export default {
  mixins: [
    Secure,
    FormMixin,
    DialogMixin
  ],

  data () {
    return {
      dialog,
      device: null,
      ports: [],
      antennaLEDsOn: true
    }
  },

  computed: {
    internalPorts () {
      return this.ports.filter(p => p.isInternal)
    },

    externalPorts () {
      return this.ports.filter(p => p.isExternal)
    },

    portGroups () {
      const { internalPorts, externalPorts } = this
      return [
        { label: pluralize(internalPorts, 'Internal Antenna'), ports: internalPorts },
        { label: pluralize(externalPorts, 'External Antenna'), ports: externalPorts }
      ]
    },

    // Tooltip explaining the whereabouts of the port
    portLabel () {
      return (port) => {
        const label = port.isExternal ? 'Upstream' : `Antenna ${port.id.toString()}`
        return `${label}, ${port.isConnected ? 'connected' : 'disconnected'}`
      }
    },

    antennaLEDsOnLabel () {
      return this.antennaLEDsOn
        ? 'Antenna LEDs are turned on'
        : 'Antenna LEDs are turned off, while PortSense still works'
    },

    // Check whether antenna LEDs can be toggled by the current user
    canToggleLEDs () {
      return this.isSuperAdministrator
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      const { data: { device, ports, antennaLEDsOn } } = this
      this.device = device
      this.ports = clone(ports)
      this.antennaLEDsOn = antennaLEDsOn
      this.title = `PortSense Settings for ${getDeviceLabel(device)} `
    },

    // Validates and OKs the dialog
    ok () {
      const { ports, antennaLEDsOn } = this
      this.dialogOk({
        dialog,
        data: { ports, antennaLEDsOn }
      })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form class="form" ref="form">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-lg">
        <div class="column">
          <span>
            Assign custom names to antennas, choose
            whether they should be monitored for alerts.
          </span>

          <template v-for="group in portGroups">
            <span class="q-mt-md q-mb-sm text-indigo-8">
              {{ group.label }}
            </span>

            <div v-for="port in group.ports" class="row items-center no-wrap q-mb-sm">
              <q-input dense square outlined v-model="port.label" maxlength="20"
                :label="portLabel(port)">
              </q-input>

              <q-toggle class="q-ml-md" dense v-model="port.isMonitored" color="green-7">
                <sc-tooltip v-if="port.isMonitored && port.isConnected" max-width="400px">
                  The port is currently monitored.<br>
                  Uncheck to prevent alerts after disconnecting the antenna.
                </sc-tooltip>
                <sc-tooltip v-else-if="port.isMonitored && !port.isConnected">
                  The port is currently monitored.<br>
                  The antenna is not yet connected.<br>
                  Uncheck to prevent any alerts.
                </sc-tooltip>
                <sc-tooltip v-else-if="!port.isMonitored">
                  The port is not monitored.<br>
                  Check to start receiving alerts.
                </sc-tooltip>
              </q-toggle>

              <span class="port-info q-ml-sm">
                {{ port.isMonitored ? 'Monitored' : 'Not monitored' }}
              </span>
            </div>
          </template>
        </div>

        <div class="column q-mt-md q-pt-sm" v-if="canToggleLEDs">
          <span class="q-mb-sm text-indigo-8">
            Antenna LEDs
          </span>
          <div class="row items-center">
            <q-toggle color="green-7" v-model="antennaLEDsOn"></q-toggle>
            <span class="q-ml-sm">
              {{ antennaLEDsOnLabel }}
            </span>
          </div>
        </div>

        <div class="row q-mt-xl">
          <q-space></q-space>
          <q-btn label="Cancel" unelevated class="q-mr-md" @click="cancel()"></q-btn>
          <q-btn label="Save" unelevated class="primary" @click="ok()"></q-btn>
        </div>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 16px;
}

.form {
  width: 650px;
}

@media screen and (max-width: 1024px) {
  .port-info {
    display: none;
  }
}
</style>