import { parseEnum, safeParseInt, round } from '@stellacontrol/utilities'
import { PlanItemType } from './plan-item'
import { PlanConnector } from './plan-connector'
import { PlanLineStyle } from '../styles'

/**
 * Cable types
 */
export const CableType = {
  SD240: 'sd240',
  SD400: 'sd400',
  SD600: 'sd600',
  SD900: 'sd900',
  Custom: 'custom-cable'
}

/**
 * Cable names
 */
export const CableName = {
  [CableType.SD240]: 'SD240',
  [CableType.SD400]: 'SD400',
  [CableType.SD600]: 'SD600',
  [CableType.SD900]: 'SD900',
  [CableType.Custom]: 'Other'
}

/**
 * Cable parameters per type
 */
export const CableParameters = {
  [CableType.SD240]: { gain: -0.248, thickness: 2, color: 'blue' },
  [CableType.SD400]: { gain: -0.212, thickness: 3, color: 'blue' },
  [CableType.SD600]: { gain: -0.132, thickness: 5, color: 'maroon' },
  [CableType.SD900]: { gain: -0.088, thickness: 7, color: 'purple' },
  [CableType.Custom]: { gain: -0.128, thickness: 4, color: 'grey' }
}

/**
 * Available cable types
 */
export const AvailableCableTypes = [
  { value: CableType.SD240, label: CableName[CableType.SD240] },
  { value: CableType.SD400, label: CableName[CableType.SD400] },
  { value: CableType.SD600, label: CableName[CableType.SD600] },
  { value: CableType.SD900, label: CableName[CableType.SD900] },
  { value: CableType.Custom, label: CableName[CableType.Custom] }
]

/**
 * Cable linking two devices
 */
export class PlanCable extends PlanConnector {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Cable
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      cableType: CableType.SD400,
      lineStyle: new PlanLineStyle()
    }
  }

  normalize () {
    const { defaults } = this
    super.normalize()
    this.cableType = parseEnum(CableType, this.cableType, defaults.cableType)

    this.realLength = safeParseInt(this.realLength)

    // Determine line style for the cable
    if (!this.isCustom) {
      this.lineStyle = new PlanLineStyle(defaults.lineStyle)
    }
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Don't store default line styles
    if (!this.isCustom || this.goesIntoRiser) {
      delete result.lineStyle
    }

    return result
  }

  /**
   * Human-friendly representation of the item, useful for the UI
   * @param {Boolean} details If true, detailed description is returned
   * @returns {String}
  */
  toHumanString (details) {
    let label = CableName[this.cableType]
    const { realLength, start, end } = this
    if (details) {
      label = `${label}${realLength ? `${realLength}m` : ''}${start.item ? ` ${start.item.toHumanString()}` : ''}${end.item ? ` => ${end.item.toHumanString()}` : ''}`
    }
    return label
  }

  /**
   * Real cable length
   * @type {Number}
   */
  realLength

  /**
   * Cable type
   * @type {CableType}
   */
  cableType

  /**
   * Automatically generated label
   * @type {String}
   */
  get autoLabel () {
    return `${CableName[this.cableType]} Cable`
  }

  /**
   * Indicates whether the cable is of custom type
   * @type {Boolean}
   */
  get isCustom () {
    return this.cableType === CableType.Custom
  }

  /**
   * Cable parameters
   * @type {Object}
   */
  get parameters () {
    return CableParameters[this.cableType]
  }

  /**
   * Determine signal gain/loss at the item
   * @param {PlanLayout} layout Plan layout
   * @returns {Number}
   */
  getGain (layout) {
    const { parameters, realLength = 0 } = this
    if (layout && parameters) {
      // Only lengths >=0 should count
      const length = realLength >= 1 ? realLength : 0
      return round(length * parameters.gain || 0, 2)

    } else {
      return 0
    }
  }
}
