<script>
import { mapState } from 'vuex'
import { PlannerMode } from '@stellacontrol/planner'
import { PlanActions, createPlanAction } from '../../renderer'
import Menu from './menu'

export default {
  mixins: [
    Menu
  ],

  computed: {
    ...mapState({
      // Planner editing mode
      mode: state => state.planner.mode
    }),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },

    // Determines whether the planner allows only a limited feature set
    isRegularMode () {
      return this.mode === PlannerMode.Regular
    },

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.mode === PlannerMode.ReadOnly
    },

    // Plan items for which to show the menu
    items () {
      return this.menu?.items || []
    },

    // Single item to show the menu for
    item () {
      return this.menu?.items ? this.menu?.items[0] : undefined
    },

    // Indicates whether the menu is visible
    isVisible () {
      return this.position != null && this.item != null
    },

    // Menu items
    actions () {
      const { items, item, isAdvancedMode } = this
      const canCopy = items.some(item => item.canClone)
      const canRemove = items.some(item => item.canRemove)
      const canReorder = isAdvancedMode && items.some(item => item.canReorder)
      const canFlip = items.some(item => item.canFlip || item.canRotate)
      const canShowDetails = items.length === 1 && item.hasDetails

      const actions = [
        // Show item details
        canShowDetails ? { action: PlanActions.ShowItemDetails } : null,
        // Duplicate
        canCopy ? { action: PlanActions.DuplicateItems } : null,
        // Rotate
        canFlip ? { action: PlanActions.RotateItems, args: { angle: 45, keepMenu: true } } : null,
        // Reorder
        canReorder ? { action: PlanActions.BringToTop } : null,
        canReorder ? { action: PlanActions.BringToBottom } : null,
        // Remove items
        canRemove ? { action: PlanActions.RemoveItems } : null
      ]

      return actions
        .filter(item => item)
        .map(({ action, separator, label, icon, color = 'grey-2', args, ...options }) => ({
          separator,
          action: action ? createPlanAction({ action, items, label, icon, color, args, ...options }) : null
        }))
    },

    // Indicates whether any actions are available
    hasActions () {
      return this.actions.length > 0
    }
  }
}

</script>

<template>
  <div ref="menu" class="menu bg-grey-9 shadow-2 rounded-borders q-pa-sm" :class="cssClass"
    :style="{ ...cssStyle, display: hasActions ? undefined : 'none' }">
    <div class="row items-center no-wrap">
      <template v-for="{ separator, action } in actions">
        <q-btn :icon="action.icon" :color="action.color" flat unelevated dense size="sm"
          :class="buttonClass(action, separator)" @click="execute({ action, items })">
          <sc-tooltip>
            {{ action.label }}
          </sc-tooltip>
        </q-btn>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  position: absolute;
  z-index: 100;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}
</style>
