<script>
import { startOfDay, startOfWeek, startOfMonth, endOfDay, endOfWeek, endOfMonth, isToday, addWeeks, addDays, addMonths, subDays, subWeeks, subMonths } from 'date-fns'
import { DateFormats, parseDate, formatDate, countString, Period, TimeDirection } from '@stellacontrol/utilities'
import { DateRange } from '@stellacontrol/model'

export default {
  props: {
    /**
     * Edited value
     */
    modelValue: {
    },

    // Quick-selection periods
    periods: {
      type: Array,
      default: () => ([
        { type: Period.Day, count: 1, days: 1, label: 'Select today' },
        { type: Period.Day, count: 3, days: 3, label: 'Select the last 3 days' },
        { type: Period.Day, count: 7, days: 7, label: 'Select the last 7 days' },
        { type: Period.Day, count: 30, days: 30, label: 'Select the last 30 days' }
      ])
    },

    /**
     * Calendar popup title
     */
    popupTitle: {
      type: String,
      default: 'Select start and end date'
    },

    /**
     * Date format
     */
    format: {
      type: String,
      default: DateFormats.date.toUpperCase()
    },

    /**
     * First day of week, Monday by default
     */
    firstDayOfWeek: {
      type: Number,
      default: 1
    },

    /**
     * Lower bound of the possible date selection, inclusive
     */
    min: {
      type: Date,
      default: null
    },

    /**
     * Upper bound of the possible date selection, inclusive
     */
    max: {
      type: Date,
      default: endOfDay(new Date())
    },

    /**
     * Maximal duration of the selected period, in days
     */
    maxLength: {
    },

    /**
     * If true, only the calendar button is shown
     */
    calendarOnly: {
      type: Boolean,
      default: false
    },

    /**
     * Quasar q-date properties
     */
    minimal: {
      type: Boolean,
      default: false
    },

    todayButton: {
      type: Boolean,
      default: true
    },

    /**
     * Quasar q-input properties
     */
    square: {
      type: Boolean,
      default: true
    },

    outlined: {
      type: Boolean,
      default: true
    },

    dense: {
      type: Boolean,
      default: true
    },

  },

  data () {
    return {
      Period,
      TimeDirection,

      // Range length
      period: Period.Day
    }
  },

  computed: {
    // Currently selected period, for the calendar popup
    selectedPeriod () {
      const { modelValue } = this
      return modelValue
        ? {
          from: formatDate(modelValue.from, 'yyyy/MM/dd'),
          to: formatDate(modelValue.to, 'yyyy/MM/dd')
        }
        : {
          from: '',
          to: ''
        }
    },

    // Text representation of the FROM date
    textFrom () {
      const { modelValue } = this
      return formatDate(modelValue?.from)
    },

    // Text representation of the TO date
    textTo () {
      const { modelValue } = this
      return formatDate(modelValue?.to)
    },

    // Determines whether the calendar popup should be shown in minimal mode
    isPopupMinimal () {
      return !this.popupTitle || this.minimal === true
    },

    // Label describing the length of a period by which the user would jump,
    // when pressing BACK or FORWARD buttons
    periodJump () {
      return direction => {
        const { modelValue, period } = this
        if (modelValue) {
          if (period === Period.Day && modelValue.length <= 1) {
            if (isToday(modelValue.from)) {
              return direction === TimeDirection.Back ? 'Yesterday' : 'Tomorrow'
            } else {
              return direction === TimeDirection.Back ? 'Previous day' : 'Next day'
            }

          } else if (period === Period.Week) {
            return direction === TimeDirection.Back ? 'Previous week' : 'Next week'

          } else if (period === Period.Month) {
            return direction === TimeDirection.Back ? 'Previous month' : 'Next month'

          } else {
            return `${countString(modelValue.length, 'day')} ${direction === TimeDirection.Back ? 'back' : 'forward'}`
          }
        }
        return ''
      }
    },

    // Determines whether the specified period has been selected by the user
    isPeriodSelected () {
      return period => this.period === period.type &&
        this.modelValue?.length === period.days
    }
  },

  methods: {
    // Applies the defined range limits such as min/max date and range span
    applyLimits (range) {
      if (!range) return

      const { min, max, maxLength } = this

      if (min && range.from < min) {
        range.date = min
      }

      if (max && range.to > max) {
        range.to = max
      }

      if (range.from > range.to) {
        range.from = range.to
      }

      if (range.to < range.from) {
        range.to = range.from
      }

      if (maxLength > 0 && range.length > maxLength) {
        range.to = addDays(range.from, maxLength)
      }

      return range
    },

    // Checks if the specified date from q-date component is valid
    // for the user to select it
    isValidDate (value) {
      const { min, max } = this
      const date = parseDate(value)
      if (min && date < min) {
        return false
      }
      if (max && date > max) {
        return false
      }
      return true
    },

    // Updates the model with the new date range
    setRange ({ from, to, range } = {}) {
      // Date range selected in calendar popup
      if (from || to) {
        const fromDay = from
          ? Quasar.date.buildDate({ year: from.year, month: from.month, date: from.day })
          : this.selectedPeriod?.from
        const toDay = from
          ? Quasar.date.buildDate({ year: to.year, month: to.month, date: to.day })
          : this.selectedPeriod?.from
        range = DateRange.from(fromDay, toDay)
      }

      range = this.applyLimits(range)

      this.$emit('update:model-value', range)
    },

    // Selects the period specified as days, selected in the date popup
    setDays (details) {
      this.$refs.qDateProxy.hide()
      this.setRange(details)

      const { length } = this.modelValue || {}
      if (length === 7) {
        this.period = Period.Week

      } else if (length === 30 || length == 31) {
        this.period = Period.Month

      } else {
        this.period = Period.Day
      }
    },

    // Selects the specified period
    setPeriod (period, count) {
      this.period = period

      let range
      if (period === Period.Day) {
        range = count === 1
          ? DateRange.today()
          : DateRange.recent(count - 1)

      } else if (period === Period.Week) {
        range = DateRange.from(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          endOfDay(new Date())
        )

      } else if (period === Period.Month) {
        range = DateRange.from(
          startOfMonth(new Date()),
          endOfDay(new Date())
        )
      }

      this.setRange({ range })
    },

    // Jumps by the currently selected period
    jump (direction, count = 1) {
      const { period, modelValue } = this
      if (!modelValue) return

      let from, to
      if (period === Period.Day) {
        if (direction === TimeDirection.Back) {
          from = subDays(startOfDay(modelValue.from), count * modelValue.length)
        } else {
          from = addDays(startOfDay(modelValue.from), count * modelValue.length)
        }
        to = endOfDay(addDays(from, count * modelValue.length - 1))

      } else if (period === Period.Week) {
        if (direction === TimeDirection.Back) {
          from = subWeeks(startOfWeek(modelValue.from, { weekStartsOn: 1 }), count)
        } else {
          from = addWeeks(startOfWeek(modelValue.from, { weekStartsOn: 1 }), count)
        }
        to = endOfWeek(from, { weekStartsOn: 1 })

      } else if (period === Period.Month) {
        if (direction === TimeDirection.Back) {
          from = subMonths(startOfMonth(modelValue.from), count)
        } else {
          from = addMonths(startOfMonth(modelValue.from), count)
        }
        to = endOfMonth(from)
      }

      this.setRange({
        range: new DateRange({ from, to })
      })
    }
  }
}
</script>

<template>
  <div class="date-range-buttons row items-center no-wrap">
    <div class="left" v-if="!calendarOnly">
      <q-btn class="button-period" :class="{ [`${period.type}-${period.count}`]: true }" v-for="period in periods"
        :label="`${period.count}${period.type.toLowerCase()}`" dense unelevated no-caps
        :color="isPeriodSelected(period) ? 'green-7' : undefined"
        @click="setPeriod(period.type, period.count)">
        <sc-tooltip>{{ period.label }}</sc-tooltip>
      </q-btn>
    </div>

    <q-space v-if="!calendarOnly"></q-space>

    <div class="right" :class="{ 'q-ml-sm': !calendarOnly }">
      <q-btn v-if="!calendarOnly" class="button-jump" icon="chevron_left" dense unelevated
        @click="jump(TimeDirection.Back)">
        <sc-tooltip>
          {{ periodJump(TimeDirection.Back) }}
        </sc-tooltip>
      </q-btn>
      <q-btn class="button-calendar" icon="calendar_month" dense unelevated>
        <sc-tooltip>Open calendar</sc-tooltip>
        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
          <q-date square range color="indigo-7" :model-value="selectedPeriod"
            :minimal="isPopupMinimal" :first-day-of-week="firstDayOfWeek" :today-btn="todayButton"
            :options="date => isValidDate(date)" :subtitle="popupTitle"
            @update:model-value="(value, reason, details) => setDays(details)">
          </q-date>
        </q-popup-proxy>
      </q-btn>
      <q-btn v-if="!calendarOnly" class="button-jump" icon="chevron_right" dense unelevated
        @click="jump(TimeDirection.Forward)">
        <sc-tooltip>
          {{ periodJump(TimeDirection.Forward) }}
        </sc-tooltip>
      </q-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.q-date__header) {
  height: 54px;
}

:deep(.q-date__header-title) {
  display: none;
}

:deep(.q-date__header-subtitle.q-date__header-link) {
  opacity: 1;
}

.date-range-buttons {
  flex: 1;

  .left,
  .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 4px;
  }

  .button-period {
    width: 28px;
    font-size: 12px;
  }

  .button-calendar {
    width: 28px;
    font-size: 12px;
  }

  .button-jump {
    width: 22px;
    font-size: 12px;
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 640px) {
  .date-range-buttons {
    .button-period.D-30 {
      display: none;
    }
  }
}

</style>
