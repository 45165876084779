import Konva from 'konva'
import { Size } from '@stellacontrol/utilities'
import { PlanItemType } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Rectangle
 * @param {PlanRectangle} item Plan item details
 */
export class RectangleShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Rectangle
  }

  /**
   * Rectangle shape
   * @type {Konva.Rect}
   */
  rect

  /**
   * Returns the shape size
   * @returns {Size}
   */
  getShapeSize () {
    const size = Size.from(this.rect.size()).round()
    return size
  }

  createShapes () {
    super.createShapes()

    this.rect = new Konva.Rect()
    this.content.add(this.rect)
  }

  async render (renderer) {
    await super.render(renderer)
    const { rect, item } = this
    if (rect && item) {
      const { lineStyle, backgroundStyle } = item
      rect.width(item.width)
      rect.height(item.height)
      rect.fill(backgroundStyle.color)
      rect.stroke(lineStyle.color)
      rect.strokeWidth(lineStyle.width)
      rect.dash(lineStyle.dash)
      rect.cornerRadius(lineStyle.radius)
      this.applyFilters()
    }
  }

  /**
   * Triggered when shape is being transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Shape position after the transformation
   * @param {PlanScale} scale Scale of the shape after the transformation
   * @param {Number} rotation Shape rotation after the transformation
   * @returns {Boolean}
   */
  async transforming ({ renderer, position, scale, rotation }) {
    super.transforming({ renderer, position, scale, rotation })
    const { item } = this

    // Adjust the position
    if (position) {
      item.setCoordinates(position, renderer.isCrossSection)
    }

    // Set the size
    if (scale) {
      item.width = Math.round(item.width * scale.x)
      item.height = Math.round(item.height * scale.y)
    }

    // Re-render the shape
    await this.render(renderer)

    return true
  }
}
