import { PlaceType } from './place-type'
import { capitalize } from '@stellacontrol/utilities'

/**
 * Human-friendly labels for place types
 */
export const PlaceNames = {
  [PlaceType.Building]: 'building',
  [PlaceType.Ship]: 'ship',
  [PlaceType.Vehicle]: 'vehicle',
  [PlaceType.Other]: 'place',
  [PlaceType.Stock]: 'stock',
  [PlaceType.SharedPlace]: 'shared'
}

/**
 * Returns a human-friendly label for a place type
 * @param placeType Place type
 * @param defaultValue Value to return if place name could not be determined
 */
export function getPlaceLabel (placeType, defaultValue = 'Unknown') {
  return (PlaceNames[placeType] || '') || defaultValue
}

/**
 * Returns a human-friendly label for a place
 * @param place Place
 * @param defaultValue Value to return if place description could not be determined
 */
export function getPlaceDescription (place, defaultValue = 'Unknown') {
  const { placeType, name } = place || {}
  const placeName = (place && placeType !== PlaceType.Stock && placeType !== PlaceType.SharedPlace)
    ? getPlaceLabel(placeType, '')
    : ''
  const description = place
    ? `${capitalize(placeName)} ${name}`.trim()
    : defaultValue
  return description
}
