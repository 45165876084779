import { round, getDurationString } from '@stellacontrol/utilities'
import { Assignable, DeviceBandIndex } from '@stellacontrol/model'
import { MegaParameterChange } from './mega-parameter-change'
import { MegaParameterStatus } from './mega-parameter-status'
import { MegaParameterType } from './mega-parameter-type'

/**
 * Display rules for MEGA parameters,
 * used when parameter is store using different measurement unit
 * than the unit which we want to show to the user (i.e. seconds vs hours)
 */
const MEGADisplayRules = {
  'uptime': {
    // Recalculate the original value to hours
    value: value => value == null
      ? null
      : round(value / 3600, 2),
    // Show the value as user-friendly duration string
    label: value => value == null
      ? null
      : getDurationString(value)
  }
}

/**
 * Describes a value reported by device
 */
export class MegaParameter extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({
      ...data,
      itemType: data.itemType,
      isFloat: Boolean(data.float),
      store: Boolean(data.store),
      computed: Boolean(data.computed),
      isReliable: data.reliable == null ? true : Boolean(data.reliable),
      isMonitored: data.monitored == null ? true : Boolean(data.monitored),
      history: data.history == null ? undefined : Boolean(data.history)
    })

    delete this.float

    if (this.isBoolean) {
      this.min = 0
      this.max = 1
    }
  }

  normalize () {
    super.normalize()
    this.when = this.castArray(this.when, MegaParameterChange)
  }

  /**
   * Database identifier
   * @type {Number}
   */
  id

  /**
   * Parameter key
   * @description If parameter is a band parameter,
   * {@link name} might include the band identifier, eg. `_mgn_dw_07` * while
   * {@link key} is the part without band identifier, eg. `_mgn_dw`
   * @type {String}
   */
  key

  /**
   * Parameter name.
   * @description If parameter is a band parameter,
   * {@link name} might include the band identifier, eg. `_mgn_dw_07` * while
   * {@link key} is the part without band identifier, eg. `_mgn_dw`
   * @type {String}
   */
  name

  /**
   * Checks if specified parameter name matches the current one.
   * @param {String} name Parameter name
   * @param {Boolean} exact If true, exact parameter name must match,
   * otherwise both name and key are checked, i.e. band parameter name
   * `_mgn_dw_07` will match `_mgn_dw` as well
   * @returns {Boolean}
   */
  isParameter (name, exact) {
    if (name) {
      if (exact) {
        return this.name === name
      } else {
        return this.name === name ||
          this.key === name ||
          this.key === name.replace(/_\d{2}$/, '')
      }
    }
  }

  /**
   * If true, the parameter is not received from the device but computed from other parameters
   * @type {Boolean}
   */
  computed

  /**
   * Color used to render the parameter on history graphs.
   * If not specified, random colors are assigned.
   * @type {String}
   */
  color

  /**
   * Parameter status: present | absent | obsolete | unknown, default is `present`,
   * @type {MegaParameterStatus}
   */
  status

  /**
   * Indicates whether the parameter has reliable value.
   * Some parameters can only be trusted upon
   * on certain models, firmware versions etc.,
   * i.e. `sliding` which is not reliable on legacy firmwares
   * @type {Boolean}
   */
  isReliable

  /**
   * Indicates whether the parameter can be monitored
   * in Alert Monitor and trigger alerts.
   * Some alerts aren't applicable on certain versions or models.
   * @type {Boolean}
   */
  isMonitored

  /**
   * Indicates whether the parameter is a band parameter,
   * expanded into multiple actual parameters per band
   * @type {Boolean}
   */
  isBandParameter

  /**
   * Indicates whether the parameter is editable
   * @type {Boolean}
   */
  get isEditable () {
    return (this.name || '').startsWith('_')
  }

  /**
   * Identifier of a band associated with this parameter
   * @type {String}
   */
  band

  /**
   * Ordinal index of the band, 0-based
   * @type {Number}
   */
  get bandIndex () {
    return this.band ? DeviceBandIndex[this.band] : undefined
  }

  /**
   * Returns true if the parameter is currently not applicable
   * @type {Boolean}
   */
  get notApplicable () {
    const { status } = this
    return status === MegaParameterStatus.Absent || status === MegaParameterStatus.Unknown
  }

  /**
   * Returns true if the parameter is currently applicable
   * @type {Boolean}
   */
  get isApplicable () {
    return !this.notApplicable
  }

  /**
   * When this parameter is deemed obsolete, another MEGA field
   * indicated by this property might take over
   * @type {String}
   */
  replacedBy

  /**
   * User-friendly label of a parameter, concise
   * @type {String}
   */
  label

  /**
   * User-friendly label, longer
   * @type {String}
   */
  longLabel

  /**
   * Detailed description of the parameter
   * @type {String}
   */
  description

  /**
   * Detailed explanation of the parameter, shown in tooltips etc
   * @type {String}
   */
  details

  /**
   * Value type
   * @type {MegaParameterType}
   */
  type

  /**
   * Item type, if {@link type} is an array
   * @type {MegaParameterType}
   */
  itemType

  /**
   * Number of items, if {@link type} is an array
   * @type {Number}
   */
  itemCount

  /**
   * Item labels, if {@link type} is an array
   * @type {Array[String]}
   */
  itemLabels

  /**
   * True if parameter value is a string
   * @type {Boolean}
   */
  get isString () {
    return this.type === MegaParameterType.String
  }

  /**
   * True if parameter value is a free-entry number
   * @type {Boolean}
   */
  get isNumber () {
    return this.type === MegaParameterType.Number
  }

  /**
   * Indicates that parameter is a floating-type number
   * @type {Boolean}
   */
  isFloat

  /**
   * True if parameter value is a floating-type number
   * @type {Boolean}
   */
  get isInteger () {
    return this.isNumber && !this.isFloat
  }

  /**
   * Check if parameter value is a boolean
   * @type {Boolean}
   */
  get isBoolean () {
    return this.type === 'boolean' && !this.deviceValues
  }

  /**
   * True if parameter value is a geo-position
   * @type {Boolean}
   */
  get isGeo () {
    return this.type === MegaParameterType.GeoPosition
  }

  /**
   * True if parameter can only assume predefined values
   * @type {Boolean}
   */
  get isEnumeration () {
    return this.deviceValues?.length > 0
  }

  /**
   * Check if parameter value is an array
   * @type {Boolean}
   */
  get isArray () {
    return this.type === 'array'
  }

  /**
   * Check if parameter value is an array of numbers
   * @type {Boolean}
   */
  get isNumberArray () {
    return this.isArray && this.itemType === 'number'
  }

  /**
   * Check if parameter value is an array of strings
   * @type {Boolean}
   */
  get isStringArray () {
    return this.isArray && this.itemType === 'string'
  }

  /**
   * Check if parameter value is an array of booleans
   * @type {Boolean}
   */
  get isBooleanArray () {
    return this.isArray && this.itemType === 'boolean'
  }

  /**
   * Measurement unit for parameter values, for example min, °C, dB
   * @type {MegaParameterUnit}
   */
  unit

  /**
   * Description of the measurement unit for parameter values greater than 1, for example minutes, LEDs
   * @type {String}
  */
  unitPlural

  /**
   * Measurement unit for displaying the parameter value. For example, the value might be stored in seconds but we show it in the UI in hours.
   * @type {MegaParameterUnit}
   */
  displayUnit

  /**
   * Array of discrete values permitted for the parameter
   * @type {Array}
   */
  values

  /**
   * Values sent to / received from the device.
   * @description Some device values are hard to use, such as _default_sampling_speed encoded as duration string.
   * It can be handy to translate them internally to more handy unit, such as number of seconds.
   * This parameter defines a list of device values, to which internal values should be mapped
   * before being sent to devices, and vice versa.
   * @type {Array}
   */
  deviceValues

  /**
   * Array of values which user can select when configuring the device.
   * For some variables not all permitted {@link values} should be available
   * for users to select
   * @type {Array}
   */
  selectableValues

  /**
* Item colors, if {@link type} is an array
* @type {Array[String]}
*/
  labels

  /**
   * Returns value recalculated for user display.
   * Some parameters are shown using a different unit than the unit with which they're stored,
   * i.e.`uptime` parameter stored in seconds but shown in hours.
   * @returns {String}
   */
  getDisplayValue (value) {
    const display = MEGADisplayRules[this.name]
    return display
      ? display.value(value)
      : value
  }

  /**
   * Returns user-friendly label of the specified raw value.
   * Some parameters have user-friendly descriptions for numeric values,
   * defined in `labels` section.
   * @param {any} value
   * @param {String} noValueLabel Text to return if value is not present
   * @returns {String}
   */
  getValueLabel (value, noValueLabel = '') {
    const { name, values, labels } = this

    if (value == null) {
      return noValueLabel
    }

    // Some values are shown in a custom way, i.e. using a different unit than the unit with which they're stored
    const display = MEGADisplayRules[name]
    if (display) {
      return display.label(value)
    }

    if (!(values && labels)) {
      return value.toString()
    }

    const index = values.findIndex(v => v == value)
    return labels[index] || value.toString()
  }

  /**
   * Returns user-friendly label representing the unit in which parameter values are displayed.
   * @returns {String}
   */
  getValueUnit () {
    const { unit, displayUnit } = this
    return displayUnit == null
      ? unit || ''
      : displayUnit
  }

  /**
   * Item colors, if {@link type} is an array
   * @type {Array[String]}
   */
  colors

  /**
   * Returns user-friendly color representing the specified raw value.
   * @param {any} value
   * @param {String} noValueColor Color to return if value is not present
   * @returns {String}
   */
  getValueColor (value, noValueColor) {
    const { values, colors } = this

    if (value == null) {
      return noValueColor
    }

    const index = values?.findIndex(v => v == value)
    return (colors || [])[index]
  }

  /**
   * Minimal allowed value, if parameter can have continuous values
   * @type {Number}
   */
  min

  /**
   * Maximal allowed value, if parameter can have continuous values,
   * @type {Number}
   */
  max

  /**
   * Expected length of a string value
   * @type {Number}
   */
  length

  /**
   * Maximal allowed length of string value
   * @type {Number}
   */
  maxLength

  /**
   * Minimal required length of string value
   * @type {Number}
   */
  minLength

  /**
  * If true, the parameter will be stored in device history database
  * @type {Boolean}
  */
  store

  /**
   * If true, the parameter can be analyzed on historical data charts and queries
   * @type {Boolean}
   */
  history

  /**
   * List of values which should be displayed on history graphs. All other values are skipped. Useful for those boolean variables where we only care about TRUE
   * @type {Array}
   */
  show

  /**
   * Parameter definition changes for some specific firmware versions,
   * device types etc.
   * @type {Array[MegaParameterChange]}
   */
  when

  /**
   * Permissions required to access the parameter
   * @type {Array[String]}
   */
  permissions

  /**
   * Checks whether the specified permission is required
   * to access this parameter
   * @param {String} permission Permission name
   * @returns {Boolean}
   */
  isPermissionRequired (permission) {
    return permission && (this.permissions || []).includes(permission)
  }

  /**
   * Returns true if parameters can be seen on history graphs.
   * Only numeric or boolean parameters can be seen.
   * String parameters can be seen only if values are discrete and color mapping is provided for each value.
   * Parameters are explicitly marked for availability on history graphs using {@link history} property.
   * Additionally, super organization can see all stored parameters except those explicitly marked
   * as not to be included in history charts.
   * @param {Boolean} isSuperOrganization Indicates whether viewer is a super organization,
   * which permits access to wider array of parameters
   * @returns {Boolean}
   */
  isAvailableOnHistoryGraphs (isSuperOrganization) {
    const { isApplicable, store, history, type, values, colors } = this

    if (!isApplicable) return false
    if (!store) return false
    if (history === false) return isSuperOrganization

    const isNumeric = type === MegaParameterType.Number
    const isBoolean = type === MegaParameterType.Boolean
    const isEncoded = type === MegaParameterType.String && values?.length > 0 && colors?.length === values?.length
    if (!(isNumeric || isBoolean || isEncoded)) return false

    return history === true || isSuperOrganization
  }

  /**
   * Checks whether the specified value of the parameter
   * should be visible on history graphs
   * @param {any} value
   * @returns {Boolean}
   */
  isValueVisibleOnHistoryGraphs (value) {
    if (value == null) return false
    const { show, isBoolean } = this
    return show
      ? show.includes(isBoolean ? Boolean(value) : value)
      : true
  }
}
