<script>
import { mapActions } from 'vuex'
import { range } from '@stellacontrol/utilities'
import { getBandLabel, getBandFrequencyLabel, getDeviceLabel } from '@stellacontrol/model'
import { getMegaParameter } from '@stellacontrol/mega'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  props: {
    // Indicates whether the widget is embedded in another component,
    // which impacts the way it resizes itself to available size
    embedded: {
      type: Boolean,
      default: false
    },
    // Indicates whether the header should be visible
    showHeader: {
      type: Boolean,
      default: false
    },
    // Indicates whether reset button should be visible
    canResetDevice: {
      type: Boolean,
      default: false
    },
    // Indicates whether bands configuration button should be visible
    canToggleBands: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    // Widget class
    widgetClass () {
      const { embedded, bandIdentifiers, LEDs } = this
      return {
        embedded,
        [`bands-${bandIdentifiers.length}`]: true,
        [`leds-${LEDs.length}`]: true
      }
    },

    // LEDs present on the device
    LEDs () {
      const parameter = getMegaParameter('signal_leds', this.device, this.status)
      const LEDs = Array.from(range(0, parameter.max + 1))
      return LEDs
    },

    /**
     * Bands status parsed from MEGA
     */
    bandStatus () {
      return this.status?.bands
    },

    /**
     * Band identifiers
     */
    bandIdentifiers () {
      return this.bandStatus?.identifiers || []
    },

    /**
     * Returns true if any bands are available in the status
     */
    hasBands () {
      return this.bandIdentifiers.length > 0
    },

    /**
     * Status of device bands
     */
    bands () {
      return this.bandStatus?.status || {}
    },

    /**
     * Other details about states of device bands
     */
    details () {
      return this.bandStatus?.details || {}
    },

    /**
     * User-friendly and device-family-specific label of a band
     * @param identifier Band identifier
     */
    bandLabel () {
      const family = this.device?.family
      return identifier => getBandLabel(identifier, family)
    },

    /**
     * User-friendly and device-family-specific label of band's frequency
     * @param identifier Band identifier
     */
    bandFrequencyLabel () {
      const family = this.device?.family
      return identifier => getBandFrequencyLabel(identifier, family)
    },

    /**
     * Short label representing the general state of the band
     */
    bandStatusLabel () {
      return identifier => {
        const band = this.details[identifier]
        return (band ? band.label : 'N/A') || 'Status unknown'
      }
    },

    /**
     * Details of the state of the band
     */
    bandStatusDetails () {
      return identifier => {
        const band = this.details[identifier]
        if (band) {
          const details = `
          ${this.bandLabel(identifier)}: ${this.bandFrequencyLabel(identifier)}
          <br>
          ${band.details || band.description || ''}
        `
          return details
        }
      }
    },

    /**
     * CSS style of band status indicator
     */
    bandIndicatorStyle () {
      return identifier => {
        const band = this.details[identifier]
        return band
          ? {
            'background-color': band.color,
            'border': band.borderColor ? `solid ${band.borderColor} 2px` : 'none'
          }
          : {
          }
      }
    },

    /**
     * Indicates if the specified band is in uplink status
     */
    isUplink () {
      return identifier => this.bands[identifier].isUplink
    },

    /**
     * Checks if specified led on the band is on.
     * Led index starts from 1.
     */
    isLedOn () {
      return (identifier, index) => {
        const band = this.bands[identifier]
        return band.ledCount >= index + 1
      }
    },

    /**
     * Style of a band tooltip with status details
     */
    bandStatusTooltipStyle () {
      return identifier => ({
        identifier: true,
        'transform': 'translate(67px, -13px)',
        'font-size': '13px',
        'background-color': this.details[identifier].color,
        'color': 'white',
        'border-radius': 0
      })
    },

    // Returns a value of a parameter for the specified band with unit and a descriptive text
    describedParameter () {
      return (text, identifier, name, unit) => {
        const { bands } = this
        if (bands) {
          const values = bands[identifier].values
          let value = values[name]
          if (value == null || value === 0) {
            return text
          }

          return `${text} ${value} ${unit}`
        }
      }
    },

    // Indicates whether the user can reset the device
    canReset () {
      return this.canResetDevice && this.canUse('device-management-rebalance')
    },

    // Indicates whether the user can toggle device bands
    canToggle () {
      return this.canToggleBands && this.canUse('device-configuration-toggle-bands')
    },
  },

  methods: {
    ...mapActions([
      'resetDevice',
      'toggleBands'
    ]),

    // Resets the device
    async reset () {
      const { device } = this
      await this.resetDevice({
        devices: [device],
        silent: false
      })
    },

    // Shows a dialog where user can toggle device bands
    async toggle () {
      const { device } = this
      await this.toggleBands({
        devices: [device],
        title: `Toggle bands on ${getDeviceLabel(device)}`
      })
    }
  }
}
</script>

<template>
  <sc-widget class="widget widget-device-bands" v-bind="{ ...$props, ...$attrs }"
    :class="widgetClass" v-if="hasBands">

    <header class="title row items-center q-mb-sm" v-if="showHeader">
      <span>
        Device Bands
      </span>
      <q-space></q-space>
      <div class="button-setup" v-if="canToggle">
        <q-btn icon="settings" round flat unelevated dense size="13px" color="grey-7"
          @click="toggle()">
          <sc-tooltip>
            Click to configure device bands
          </sc-tooltip>
        </q-btn>
      </div>
    </header>

    <div class="bands">
      <div class="band" v-for="identifier in bandIdentifiers" :key="identifier">
        <div class="dots">
          <div class="dot" v-for="index in LEDs" :key="index"
            :class="{ on: isLedOn(identifier, index) }">
            <sc-tooltip text="The green dots indicate the downlink signal strength">
            </sc-tooltip>
          </div>

          <div class="dot square uplink" :class="{ active: isUplink(identifier) }">
            <sc-tooltip
              :text="`${isUplink(identifier) ? 'Uplink ON. ' : ''}The indicator turns blue when the band is turned on. This happens when a person makes a call or uses mobile data.`" />
          </div>

          <div class="dot square" :style="bandIndicatorStyle(identifier)">
            <sc-tooltip :text="bandStatusDetails(identifier)" anchor="bottom middle"
              self="top middle">
            </sc-tooltip>
          </div>
        </div>

        <div class="label">
          {{ bandLabel(identifier) }}
          <sc-tooltip :text="bandStatusDetails(identifier)" anchor="bottom middle"
            self="top middle">
          </sc-tooltip>
        </div>
      </div>
    </div>

    <div class="toolbar" v-if="canReset">
      <q-btn class="button-reset" unelevated dense no-caps icon="restart_alt" :ripple="false"
        label="Reset the device" @click="reset()">
      </q-btn>
    </div>
  </sc-widget>
</template>

<style lang="scss" scoped>
.widget-device-bands {
  --dot-size: 12px;
  --dot-margin: 5px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #2a2a2a;
    padding-left: 8px;
  }

  .bands {
    display: flex;
    flex-direction: column;
    justify-items: space-between;
    background-color: transparent;
    margin-bottom: 10px;
    padding-left: 8px;

    .band {
      height: 22px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;

      .dots {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: var(--dot-margin);

        .dot {
          cursor: pointer;
          width: var(--dot-size);
          height: var(--dot-size);
          border-radius: calc(var(--dot-size)/2);
          border: solid #bebebe 1px;
          background-color: white;

          &.square {
            border-radius: 2px;
          }

          &.on {
            border: solid #2ECD6F 1px;
            background-color: #2ECD6F;
          }

          &.active {
            background-color: #5991fa;
            border: solid #5991fa 1px;
          }

          &.uplink {
            margin-left: 10px;
          }
        }
      }

      .label {
        padding-left: 5px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .toolbar {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 8px;
  }

  &.embedded {
    width: auto;
    background-color: transparent;

    .title {
      padding-left: 0;
    }

    .bands {
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  &.leds-5 {
    --dot-margin: 8px;
  }

  &.leds-4 {
    --dot-margin: 10px;
  }
}

/* Adjust dot sizes for variety of mobile screens */
@media screen and (width <=640px) {
  .widget-device-bands {
    --dot-size: 11px;

    .title {
      font-size: 14px;
      padding-left: 5px;
    }

    .bands {
      padding-left: 5px;
    }
  }
}

@media screen and (width <=350px) {
  .widget-device-bands {
    --dot-size: 9px;
  }
}
</style>
