import HomeView from '../views/home.vue'
import AdministrationView from '../views/administration/administration.vue'
import TermsAndConditionsView from '../views/terms-and-conditions/terms-and-conditions.vue'
import DebugView from '../views/debug/debug.vue'
import { dispatch, getters } from '@stellacontrol/client-utilities'

export const Routes = [
  {
    name: 'home',
    path: '/',
    component: HomeView
  },
  {
    name: 'administration',
    path: '/administration',
    component: AdministrationView
  },
  {
    name: 'terms-and-conditions',
    path: '/terms-and-conditions',
    component: TermsAndConditionsView,

    async beforeEnter (to, from, next) {
      const applicable = await dispatch('termsAndConditionsApply')
      if (applicable) {
        next()
      }
    }
  },
  {
    name: 'debug',
    path: '/debug',
    component: DebugView,

    async beforeEnter (to, from, next) {
      // DEBUG view must not be accessible on PRODUCTION
      if (!getters.isProductionEnvironment) {
        next()
      }
    }
  }
]
