<script>
import { mapActions } from 'vuex'
import { distinctValues } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import ScanSection from './ttscan.section.vue'
import ScanSectionSummary from './ttscan.section.summary.vue'
import ScanSectionSpectrum from './ttscan.section.spectrum.vue'
import ScanSectionNeighbours from './ttscan.section.neighbours.vue'
import ScanSectionSurvey from './ttscan.section.survey.vue'
import ScanSectionSurplusPower from './ttscan.section.surplus-power.vue'
import ScanSectionRaw from './ttscan.section.raw.vue'

export default {
  mixins: [
    Secure
  ],

  props: {
    // File with Network Scan results
    file: {
    }
  },

  components: {
    'sc-ttscan-section': ScanSection,
    'sc-ttscan-section-summary': ScanSectionSummary,
    'sc-ttscan-section-spectrum': ScanSectionSpectrum,
    'sc-ttscan-section-neighbours': ScanSectionNeighbours,
    'sc-ttscan-section-survey': ScanSectionSurvey,
    'sc-ttscan-section-surplus-power': ScanSectionSurplusPower,
    'sc-ttscan-section-raw': ScanSectionRaw
  },

  data () {
    return {
      // Status of preview sections
      collapsed: {
        summary: false,
        neighbours: false,
        spectrum: false,
        survey: false,
        surplusPower: false,
        data: true
      },

      // Indicates whether to hide the unboosted bands
      skipUnboostedBands: false
    }
  },

  computed: {
    // Device which has sent the scan
    device () {
      return this.file?.entity
    },

    // Scan results
    results () {
      return this.file?.data?.results
    },

    // Indicates whether any scan results are available
    hasResults () {
      return this.results != null
    },

    // Scanned operators
    operators () {
      return this.results?.operatorNames
    },

    operatorNames () {
      return Object
        .entries(this.results?.operatorNames)
        .map(([id, name]) => ({ id, name }))
    },

    // Scanned bands
    scannedBands () {
      const neighbour = this.results.neighbourResults || []
      return distinctValues(neighbour.flatMap(item => item.bandNumbersScanned))
    },

    // Determines whether the specified section can be seen by the current user
    canSeeSection () {
      return name => {
        if (name === 'data') {
          return this.isSuperAdministrator
        }
        return true
      }
    },

    // Indicates whether to show the summary
    hasSummary () {
      return true
    },

    // Spectrum scan results
    spectrumResults () {
      return this.results?.spectrumResults || []
    },

    hasSpectrumResults () {
      return this.spectrumResults.length > 0
    },

    // Cell scan results
    neighbourResults () {
      return this.results?.neighbourResults || []
    },

    hasNeighbourResults () {
      return this.neighbourResults.length > 0
    },

    // Survey results
    surveyResults () {
      return this.results?.surveyResults || []
    },

    hasSurveyResults () {
      return this.surveyResults.length > 0
    },

    // Surplus power results
    surplusPowerResults () {
      return this.results?.surplusPowerResults || []
    },

    hasSurplusPowerResults () {
      return this.surplusPowerResults.length > 0
    }
  },

  methods: {
    ...mapActions([
      'getUserPreference',
      'storeUserPreference'
    ]),

    // Populates the view
    async populate () {
      // expand raw data if not other section is visible
      const { hasSpectrumResults, hasNeighbourResults, hasSurveyResults, hasSurplusPowerResults } = this
      if (!(hasSpectrumResults || hasNeighbourResults || hasSurveyResults || hasSurplusPowerResults)) {
        this.collapsed.data = false
      }
      this.skipUnboostedBands = await this.getUserPreference({
        name: 'ttscan-hide-unboosted-bands',
        defaultValue: false
      })
    },

    // Toggles a section
    toggle ({ section }) {
      this.collapsed[section] = !this.collapsed[section]
    },

    // Triggered when user shows/hides the unboosted bands
    async toggleUnboostedBands () {
      await this.storeUserPreference({ name: 'ttscan-hide-unboosted-bands', value: this.skipUnboostedBands })
    }
  },

  async created () {
    await this.populate()
  }
}

</script>

<template>
  <div class="ttscan" v-if="hasResults">
    <!-- Summary -->
    <sc-ttscan-section name="summary" title="Summary" :collapsed="collapsed" @toggle="toggle"
      v-if="hasSummary">

      <sc-ttscan-section-summary :collapsed="collapsed" @toggle="toggle" :file="file"
        :operators="operators">
      </sc-ttscan-section-summary>

    </sc-ttscan-section>

    <!-- Neighbour results -->
    <sc-ttscan-section v-if="hasNeighbourResults" name="neighbours" title="Live Scan"
      :collapsed="collapsed" @toggle="toggle">

      <sc-ttscan-section-neighbours :collapsed="collapsed" @toggle="toggle" :file="file"
        :items="neighbourResults" :operators="operators">
      </sc-ttscan-section-neighbours>

    </sc-ttscan-section>

    <!-- Survey results -->
    <sc-ttscan-section v-if="hasSurveyResults" name="survey" title="Cell ID Scan"
      :collapsed="collapsed" @toggle="toggle">

      <sc-ttscan-section-survey :collapsed="collapsed" @toggle="toggle" :file="file"
        :items="surveyResults" :operators="operators" :skip-unboosted-bands="skipUnboostedBands">
      </sc-ttscan-section-survey>

      <template #toolbar>
        <div v-if="!device.isTestTool">
          <q-checkbox dense v-model="skipUnboostedBands"
            label="Hide bands not boosted by the device"
            @update:model-value="toggleUnboostedBands()">
          </q-checkbox>
        </div>
      </template>

    </sc-ttscan-section>

    <!-- Spectrum results -->
    <sc-ttscan-section v-if="hasSpectrumResults" name="spectrum" title="Spectrum Scan"
      :collapsed="collapsed" @toggle="toggle">

      <sc-ttscan-section-spectrum :collapsed="collapsed" @toggle="toggle" :file="file"
        :items="spectrumResults" :operators="operators">
      </sc-ttscan-section-spectrum>

    </sc-ttscan-section>

    <!-- Surplus power results -->
    <sc-ttscan-section v-if="hasSurplusPowerResults" name="surplusPower" title="Surplus Power"
      :collapsed="collapsed" @toggle="toggle">

      <sc-ttscan-section-surplus-power :collapsed="collapsed" @toggle="toggle" :file="file"
        :items="surplusPowerResults">
      </sc-ttscan-section-surplus-power>

    </sc-ttscan-section>

    <!-- Raw data -->
    <sc-ttscan-section v-if="canSeeSection('data')" name="data" title="Raw Data"
      :collapsed="collapsed" @toggle="toggle" collapsible>
      <sc-ttscan-section-raw :collapsed="collapsed" @toggle="toggle" :file="file">
      </sc-ttscan-section-raw>
    </sc-ttscan-section>
  </div>
</template>

<style lang="scss" scoped>
.ttscan {
  overflow: auto;

  .data {
    padding: 8px;
  }
}
</style>
