<script>
import { sortItems } from '@stellacontrol/utilities'
import { isMegaParameterApplicable } from '@stellacontrol/mega'
import { DeviceStatusFeatures } from '@stellacontrol/devices'
import Panel from './panel'

export default {
  mixins: [
    Panel
  ],

  computed: {
    // Label
    featuresLabel () {
      const enabledCount = this.features
        .filter(f => f.isEnabled)
        .length
      return `${enabledCount} Product Features`
    },

    // Produce features to display
    features () {
      const { device, status } = this
      if (device && status?.identity) {
        const hasDeviceFeatures = isMegaParameterApplicable('product_features', device) && !status.features.isEmpty
        const items = hasDeviceFeatures
          ? Object
            .keys(DeviceStatusFeatures.Fields)
            .map(feature => ({
              label: feature,
              isEnabled: status.features[feature]
            }))
          : []
        return sortItems(items, 'label')
      } else {
        return []
      }
    },


    // Determines whether any product features are enabled
    anyFeaturesEnabled () {
      return this.features.some(f => f.isEnabled)
    },

    // Determines whether the widget should be shown
    isVisible () {
      return this.isSuperAdministrator &&
        this.anyFeaturesEnabled
    },
  }
}
</script>

<template>
  <div v-if="isVisible" class="widget panel-widget device-features">
    <header class="row items-center text-bold">
      <q-icon size="26px" name="check_circle" color="indigo-6">
      </q-icon>
      <span class="q-ml-sm">
        {{ featuresLabel }}
      </span>
      <q-space></q-space>

      <q-btn round flat dense size="11px" icon="more_horiz" v-close-popup>
        <q-popup-proxy>
          <main class="details">
            <header class="bg-indigo-7 text-white text-bold q-pa-sm row items-center">
              <span>
                Device Features
              </span>
              <q-space></q-space>
              <q-btn size="sm" round flat dense icon="close" color="white" v-close-popup>
              </q-btn>
            </header>
            <div>
              <div class="item" v-for="(feature, index) in features" :key="index"
                :class="{ present: feature.isEnabled, absent: !feature.isEnabled }">
                <span class="name" :title="feature.label">
                  {{ feature.label }}
                </span>
                <span class="value">
                  {{ feature.isEnabled ? 'Yes' : 'No' }}
                </span>
              </div>
            </div>
          </main>

        </q-popup-proxy>
      </q-btn>
    </header>
  </div>
</template>

<style lang="scss" scoped>
.details {
  overflow: hidden;
  overflow-y: auto;
  width: 250px;
  max-height: 550px;

  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 8px;
    border-bottom: solid #dddddd 1px;

    &:hover {
      background-color: #fafafa;
    }

    &.customized {
      background-color: #fff9c4;

      &:hover {
        background-color: #f7efaa;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      color: #4a4a4a;
    }

    .value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      color: #242424;
      padding-right: 2px;
    }

    &.present {

      .name,
      .value {
        color: #304ffe;
      }
    }

    &.absent {

      .name,
      .value {
        color: #7a7a7a;
      }
    }
  }
}
</style>
