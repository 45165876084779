import { dispatch } from '@stellacontrol/client-utilities'

/**
 * Prepares data for the place plan route
 */
export async function resolvePlacePlan ({ from, to } = {}) {
  const { params: { id } } = to
  const plan = await dispatch('getBuildingPlan', { id })
  await dispatch('setRouteData', { from, to })
  return plan
}

/**
 * Prepares data for the plan route and starts editing the plan
 */
export async function resolvePlan ({ from, to } = {}) {
  await dispatch('requireOrganizations')
  await dispatch('requirePlaces')

  const { params: { id, floorId } } = to
  const plan = await dispatch('getPlan', { id })
  await dispatch('editPlan', { plan, floorId })
  await dispatch('setRouteData', { from, to })
  return plan
}

/**
 * Starts editing the specified floor of the plan
 */
export async function resolvePlanFloor ({ from, to } = {}) {
  const { floorId } = to.params
  await dispatch('editPlanFloor', { floorId })
  await dispatch('setRouteData', { from, to })
  return floorId
}
