<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { capitalize } from '@stellacontrol/utilities'
import { getDeviceLabel, DeviceConnectionStatus, DateRange, DeviceOperatingMode, Tag, TagCategory } from '@stellacontrol/model'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceCommands, DefaultDeviceCommands } from '@stellacontrol/devices'
import { isMegaParameterApplicable, isDeviceCommandApplicable } from '@stellacontrol/mega'
import { DashboardWidgets } from './widgets'
import { DashboardPanels } from './panels'
import { resolve } from './device-dashboard.resolve'

const name = 'device-dashboard'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    ...DashboardWidgets,
    ...DashboardPanels
  },

  data () {
    return {
      name,
      // Indicates whether the dashboard is initialized
      isInitialized: false,
      // Most recent message counters of the selected device
      counters: null,
      // Indicates whether scans were fetched
      scansLoaded: false,
      // Available device commands
      DeviceCommands,
      DefaultDeviceCommands,
      // Push client
      pushClient: null
    }
  },

  computed: {
    ...mapState({
      // Current user
      user: state => state.security.currentUser,
      // Currently viewed organization
      organization: state => state.deviceDashboard.organization,
      // Viewed organization's guardian
      organizationGuardian: state => state.deviceDashboard.organizationGuardian,
      // Viewed place
      place: state => state.deviceDashboard.place,
      // Device status
      deviceStatus: state => state.deviceStatus.devices,
      // Currently viewed device
      device: state => state.deviceDashboard.device,
      // Network scans collected by the device
      scans: state => state.deviceDashboard.scans,
      // Status of the currently viewed device
      status: state => state.deviceStatus.devices[state.deviceDashboard.device?.serialNumber],
      // Currently viewed device part
      devicePart: state => state.deviceDashboard.devicePart,
      // Pending premium service associated with device
      pendingPremiumService: state => state.deviceDashboard.pendingPremiumService,
      // Status watch clock ticks, used to enforce refreshing of labels such as remaining fast sampling time
      ticks: state => state.deviceStatus.ticks,
      // Ongoing fast-sampling processes
      fastSampling: state => state.deviceStatus.fastSampling,
    }),

    ...mapGetters([
      'isSmallScreen',
      'atLeastHD',
      'environment',
      'availableDeviceCommands',
      'getDeviceStatus',
      'getStatusWatchSettings',
      'availableFastSamplingSlots',
      'isDeviceCommandAvailable',
      'organizations',
      'configuration'
    ]),

    // Indicates that we're adding a place in another organization, not ours
    inAnotherOrganization () {
      return this.organization.id !== this.currentOrganization.id
    },

    // Dashboard title
    title () {
      return getDeviceLabel(this.device)
    },

    // View breadcrumbs
    breadcrumbs () {
      const { organization, place, device, getViewTitle, organizations } = this
      if (!(organization && place && device)) {
        return []
      }

      // Find a distributor of the current organization.
      // If parent is super-organization, there's no distributor.
      const { parentOrganizationId } = organization
      const distributor = parentOrganizationId != null
        ? organizations.find(item => item.id === parentOrganizationId && !item.isSuperOrganization)
        : null

      let breadcrumbs = [
        {
          name: 'home',
          title: getViewTitle('home')
        },
        {
          name: 'installations',
          title: getViewTitle('installations'),
          route: 'installations'
        },
        distributor
          ? {
            name: 'installations',
            title: distributor.name,
            route: 'installations',
            query: {
              filter: distributor.name,
              exact: true
            }
          }
          : null,
        {
          name: 'installations',
          title: organization.name,
          route: 'installations',
          query: {
            filter: organization.name,
            exact: true
          }
        },
        {
          name: 'building-dashboard',
          title: place.name,
          route: 'building-dashboard',
          params: {
            organizationId: organization.id,
            id: place.id
          }
        },
        device.location
          ? {
            title: capitalize(device.location),
          }
          : undefined,
        {
          name: 'device-dashboard',
          title: getDeviceLabel(device)
        }
      ]

      return breadcrumbs
    },

    // Checks whether viewed device's live status can be monitored.
    // If user is on paid plan, this required an active subscription for alerts.
    isLiveStatusAllowed () {
      const { currentOrganizationGuardian, organizationGuardian, device, isMultiDevice, hasParts } = this
      if (currentOrganizationGuardian && device) {
        if (isMultiDevice && !hasParts) {
          return false
        }
        if (currentOrganizationGuardian.requiresPremiumSubscription('live-status')) {
          return organizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian)
        } else {
          return currentOrganizationGuardian.canUse('live-status')
        }
      }
    },

    // Indicates that we have to do with a multi-board device
    isMultiDevice () {
      const { device } = this
      return device?.isMultiDevice
    },

    // Indicates multi-board device has any parts
    hasParts () {
      const { device, deviceParts } = this
      return device?.isMultiDevice && deviceParts.length > 0
    },

    // Indicates whether the currently selected device is the main multi-device
    isMultiDeviceSelected () {
      return this.isMultiDevice && this.selectedDevice?.serialNumber === this.device?.serialNumber
    },

    // Indicates whether the currently selected device is a part of a multi-device
    isMultiDevicePartSelected () {
      return this.isMultiDevice && Boolean(this.selectedDevice?.partOf)
    },

    // Physical components of the displayed device
    deviceParts () {
      const { device } = this
      if (device) {
        return device.isMultiDevice
          ? device.parts
          : [device]
      } else {
        return []
      }
    },

    // Returns link to part dashboard
    getPartLink () {
      const { device } = this
      return part => ({
        name: 'device-dashboard',
        params: {
          serialNumber: device.serialNumber
        },
        query: {
          part: part.serialNumber
        }
      })
    },

    // Currently selected device or device part
    selectedDevice () {
      return this.devicePart || this.device
    },

    // Device connection status
    connectionStatus () {
      const { status } = this
      return (status ? status.connection.status : '') || DeviceConnectionStatus.NeverConnected
    },

    // Indicates whether the device has ever connected to report its status
    hasConnected () {
      const { connectionStatus } = this
      return connectionStatus !== DeviceConnectionStatus.NeverConnected && connectionStatus !== DeviceConnectionStatus.Unknown
    },

    // Indicates whether the device or any of its parts has ever connected to report the status
    hasAnyPartConnected () {
      const status = this.getDeviceStatus(this.selectedDevice)
      return status?.hasAnyPartConnected
    },

    // Returns true if device status is available
    hasStatus () {
      return Boolean(this.status?.connection?.status)
    },

    // Indicates whether the selected device part has ever connected to report the status
    hasSelectedDeviceConnected () {
      const status = this.getDeviceStatus(this.selectedDevice)
      return status?.hasDeviceConnected
    },

    // Status watch settings for the current view
    statusWatchSettings () {
      return this.getStatusWatchSettings(name)
    },

    // Indicates that device is editable for the current user, commands can be sent to it etc.
    // Device is not editable when:
    // - it's only shared with user's organization
    // - organization is a guest organization
    // - user is a guest user
    isEditable () {
      const { device, currentOrganization, currentUser } = this
      return !(device?.isShared ||
        currentOrganization.isGuestOrganization ||
        currentUser.isGuestUser)
    },

    // Indicates that viewed device is test tool
    isTestTool () {
      return this.device?.isTestTool
    },

    // Indicates whether user can send commands
    // to the selected device
    canSendCommands () {
      return this.device &&
        this.isEditable &&
        this.hasAnyPartConnected &&
        this.isLiveStatusAllowed &&
        !this.isMultiDevice &&
        this.hasDeviceCommands
    },

    // Available commands
    deviceCommands () {
      const { device, deviceStatus, isSuperAdministrator } = this

      // If not super-organization,
      // return all commands except those which are already
      // available in the widgets
      let commands
      if (isSuperAdministrator) {
        commands = DefaultDeviceCommands

      } else {
        const widgetCommands = [
          DeviceCommands.Reset,
          DeviceCommands.StartFastSampling,
          DeviceCommands.RequestDeepCellScan,
          DeviceCommands.ToggleBands,
        ]
        commands = DefaultDeviceCommands
          .filter(c => !widgetCommands.includes(c.name))
      }

      // Filter out commands not applicable to the current device / user
      return commands.filter(c => this.isDeviceCommandAvailable(c, [device], deviceStatus))
    },

    // Checks whether there are any commands to show in the COMMANDS menu
    hasDeviceCommands () {
      return this.deviceCommands.length > 0
    },

    // Indicates whether the user can favorite the device
    canFavorite () {
      return this.canUse('tag-devices')
    },

    // Indicates whether the user can tag the device
    canTag () {
      return this.canUse('tag-devices') && this.isSuperAdministrator
    },

    // All tag definitions
    availableTags () {
      return this.configuration.entities.tag.items
    },

    // Tags available to assign.
    // Favorite is treated specially, it has its own icon.
    assignableTags () {
      const { device } = this
      return Object
        .entries(this.availableTags)
        .map(([name, tag]) => ({ name, ...tag }))
        .filter(t => t.name !== 'favorite')
        .filter(t => !device.hasTag(t))
    },

    // Returns true if notes are available for the current user
    canEditNotes () {
      return this.hasStatus &&
        this.isEditable &&
        this.canUse('edit-device-notes') ||
        this.device?.hasNotesOf(this.currentUser)
    },

    // Checks whether resetting device is allowed
    canResetDevice () {
      const { device, deviceStatus } = this
      return this.isDeviceCommandAvailable(DeviceCommands.Reset, [device], deviceStatus)
    },

    // Checks whether toggling device bands is allowed
    canToggleBands () {
      const { device, deviceStatus } = this
      return this.isDeviceCommandAvailable(DeviceCommands.ToggleBands, [device], deviceStatus)
    },

    // Checks whether navigating to inventory is allowed
    canGoToInventory () {
      return this.canUse('inventory')
    },

    // Indicates whether user can configure the currently selected device
    canConfigure () {
      const { device, isEditable, currentOrganizationGuardian, organizationGuardian } = this
      if (device && currentOrganizationGuardian) {
        const yes = device &&
          isEditable &&
          this.canUseChildrenOf('device-configuration')
        if (yes) {
          return currentOrganizationGuardian.requiresPremiumSubscription('live-status')
            ? organizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian)
            : currentOrganizationGuardian.canUse('live-status')
        }
      }
    },

    // Indicates whether temperature widget should be displayed
    // Temporarily hidden.
    canSeeTemperature () {
      const { isLiveStatusAllowed, hasStatus, canUse, device, status } = this
      return isLiveStatusAllowed &&
        hasStatus &&
        canUse('device-parameters-temperature') &&
        isMegaParameterApplicable('temperature', device, status) &&
        false
    },

    // Indicates whether message counters widget should be displayed
    canSeeCounters () {
      const { isLiveStatusAllowed, hasStatus, canUse, counters } = this
      return isLiveStatusAllowed &&
        hasStatus &&
        canUse('device-counters') &&
        (counters?.day != null ||
          counters?.hour != null ||
          counters?.minute != null)
    },

    // Indicates whether battery widget should be displayed
    canSeeBattery () {
      const { isLiveStatusAllowed, hasStatus, canUse } = this
      return isLiveStatusAllowed && hasStatus && canUse('device-battery')
    },

    // Indicates whether firmware update widget should be displayed
    canSeeFirmwareUpdates () {
      const { hasStatus, hasFirmwareUpdate, canUse } = this
      return hasStatus && canUse('device-management-firmware') && hasFirmwareUpdate
    },

    // Indicates whether PortSense widget should be displayed
    canSeePortSense () {
      const { isLiveStatusAllowed, hasStatus, device, status } = this
      return this.canUse('device-portsense') &&
        isLiveStatusAllowed &&
        isMegaParameterApplicable('status_portsense', device, status) &&
        hasStatus &&
        status?.health?.hasPortSense
    },

    // Indicates whether last alert widget should be displayed.
    // This is when:
    // - user is allowed to see live status of devices
    // - device is able to trigger alerts
    // - device owner has alert subscription on this device
    // - viewer organization has alerts permission
    canSeeLastAlert () {
      const { selectedDevice, organizationGuardian, currentOrganizationGuardian, hasStatus, hasConnected, isLiveStatusAllowed } = this
      return selectedDevice &&
        currentOrganizationGuardian &&
        isLiveStatusAllowed &&
        hasStatus &&
        hasConnected &&
        selectedDevice &&
        selectedDevice.canTriggerAlerts &&
        organizationGuardian.canDeviceUse('alerts', selectedDevice.serialNumber, currentOrganizationGuardian)
    },

    // Returns true if TT usage widget is available
    canSeeTTUsage () {
      const { device, hasStatus, status } = this
      return this.canUse('device-tt-scans') &&
        hasStatus &&
        isMegaParameterApplicable('can_send_scans', device, status) &&
        status?.health?.ttUsage != null
    },

    // Returns true if scans widget is available.
    // Here we only show scans collected by modem-equipped repeaters.
    // Seemingly strange that we don't show them for test tool devices,
    // but it would be useless. The same test tool will be used countless
    // times with different buildings and owners, and the list would contain
    // completely unrelated scans. Network scans are to be viewed in context of a building.
    canSeeScans () {
      const { device, status } = this
      return !device.isTestTool &&
        this.canUse('device-tt-scans') &&
        isMegaParameterApplicable('can_send_scans', device, status)
    },

    // Checks whether the device is currently performing a scan
    isScanning () {
      return this.status?.connection?.operatingMode === DeviceOperatingMode.Scanning
    },

    // Returns true if device bands widget is available
    canSeeBands () {
      return this.hasStatus &&
        this.canUse('device-bands') &&
        !this.isTestTool
    },

    // Returns true if device band details widget is available
    canSeeBandDetails () {
      return this.hasStatus &&
        this.canUse('device-db-table') &&
        !this.isTestTool
    },

    // Indicates whether the device has a firmware update to show
    hasFirmwareUpdate () {
      const { selectedDevice: { updateStatus } } = this
      return updateStatus?.inProgress || updateStatus?.isScheduled
    },

    // Indicates whether current user can change device region on this device.
    canSeeRegion () {
      if (this.isInitialized) {
        const { canUse, device } = this
        return canUse('device-management-region-change') &&
          device?.place?.hasRegion
      }
    },

    // Indicates whether history is available for this device
    canSeeHistory () {
      if (this.isInitialized && this.canUse('history-graph')) {
        const { currentOrganizationGuardian, organizationGuardian, selectedDevice: device, hasStatus, status } = this
        if (device && device.isConnectedDevice && !device.isMultiDevice && hasStatus) {
          return isMegaParameterApplicable('history', device, status) &&
            (currentOrganizationGuardian.requiresPremiumSubscription('history-graph')
              ? organizationGuardian.canDeviceUse('history-graph', device.serialNumber, currentOrganizationGuardian)
              : currentOrganizationGuardian.canUse('history-graph'))
        }
      }
    },

    // Whether the device can enter fast-sampling mode
    canFastSample () {
      return this.device &&
        isDeviceCommandApplicable(DeviceCommands.StartFastSampling, this.device, this.status) &&
        this.hasAnyPartConnected &&
        this.isLiveStatusAllowed &&
        this.availableFastSamplingSlots > 0
    },

    // The remaining time of the ongoing fast sampling of the device
    remainingFastSamplingTime () {
      const { device } = this
      if (this.ticks > 0) {
        return this.fastSampling[device.serialNumber]?.remaining
      }
    },

    // Indicates that we're currently fast-sampling some devices in the building
    isFastSampling () {
      return this.remainingFastSamplingTime > 0
    }
  },

  methods: {
    ...mapActions([
      'gotoRoute',
      'goBack',
      'gotoInventory',
      'populateDeviceDashboard',
      'showDialog',
      'hideDialog',
      'showDeviceHistory',
      'gotoDeviceDashboard',
      'gotoDevicePartDashboard',
      'watchDeviceStatus',
      'watchDeviceScanResults',
      'unwatchDeviceStatus',
      'unwatchDeviceScanResults',
      'startFastSampling',
      'fastSamplingCountdown',
      'toggleFavoriteDevice',
      'addDeviceTag',
      'removeDeviceTag'
    ]),

    getDeviceLabel,

    // Populates the dashboard
    async populate () {
      await this.watchStatus()
      this.isInitialized = true
    },

    // Starts watching device status
    async watchStatus () {
      if (!this.isLiveStatusAllowed) {
        return
      }

      const devices = this.deviceParts
      await this.watchDeviceStatus({ name, devices })
    },

    // Stops watching device status
    async unwatchStatus () {
      await this.unwatchDeviceStatus({ name })
    },

    // Launches dialog for configuring devices
    async showConfigurationDialog () {
      const { selectedDevice: { serialNumber } = {} } = this
      if (serialNumber) {
        await this.showDialog({ dialog: 'device-configuration', data: { serialNumber } })
      }
    },

    async hideConfigurationDialog () {
      this.hideDialog({ dialog: 'device-configuration' })
    },

    // Shows history of the current device
    async showHistory () {
      await this.showDeviceHistory({ device: this.selectedDevice, period: DateRange.today() })
    },

    // Starts fast sampling of the device
    async startDeviceFastSampling () {
      if (this.canFastSample) {
        const { device, statusWatchSettings: { fastSamplingDuration } } = this
        const name = device.serialNumber
        const devices = [device]
        this.startFastSampling({ name, devices, fastSamplingDuration })
        this.fastSamplingCountdown({ name, devices, fastSamplingDuration })
      }
    },

    // Navigates to the specified device part or to the main device
    async selectDevice (serialNumber) {
      const { device } = this
      if (device.serialNumber === serialNumber) {
        this.gotoDeviceDashboard({ device })
      } else {
        this.gotoDevicePartDashboard({ device, part: serialNumber })
      }
    },

    // Toggles the device as current user's favorite
    async toggleFavorite () {
      const { device, currentUser: user } = this
      await this.toggleFavoriteDevice({ device, user })
    },

    // Removes the specified tag from the device
    async removeTag ({ name }) {
      const { device } = this
      const tag = new Tag({ category: TagCategory.Device, name })
      this.removeDeviceTag({ device, tag })
    },

    // Toggles the specified tag on the device
    async toggleTag ({ name }) {
      const { device } = this
      const tag = new Tag({ category: TagCategory.Device, name })
      if (device.hasTag(tag)) {
        this.removeDeviceTag({ device, tag })
      } else {
        this.addDeviceTag({ device, tag })
      }
    },

    // Closes the dashboard
    close () {
      this.gotoRoute({
        name: 'building-dashboard',
        params: {
          organizationId: this.organization.id,
          id: this.place.id
        }
      })
    }
  },

  watch: {
    // When status is received, update the counters,
    // but keep the ones fetched last time,
    // if the newly received status doesn't have any
    status () {
      const { device, status } = this

      // Update message counters
      this.counters = this.status?.counters || this.counters

      // Get the scan results sent by device, if any
      if (status && !this.scansLoaded) {
        this.watchDeviceScanResults({ device, status, age: 365 })
        this.scansLoaded = true
      }
    }
  },

  // First visit
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.populate()
    })
  },

  // Reload data on navigation to another device
  async beforeRouteUpdate (to, from, next) {
    this.isInitialized = false
    this.scansLoaded = false

    await this.hideConfigurationDialog()
    await this.unwatchStatus()
    await this.unwatchDeviceScanResults()

    const { redirectTo } = await resolve({ to, from })
    if (redirectTo) {
      next(redirectTo)

    } else {
      await this.populate()
      next()
    }
  },

  // Stop all status subscriptions before leaving the route
  async beforeUnmount () {
    this.hideConfigurationDialog()
    this.unwatchStatus()
    this.unwatchDeviceScanResults()
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" :breadcrumbs="breadcrumbs">
    <!-- Desktop mode toolbar -->
    <template #toolbar>
      <div v-if="isInitialized" class="toolbar q-gutter-sm">
        <!-- Device tags -->
        <div class="tags q-ml-xl" v-if="canFavorite || canTag">
          <q-icon v-if="canFavorite" dense size="20px" class="tag"
            :color="device.isFavorite() ? availableTags.favorite.colorOn : availableTags.favorite.colorOff"
            :name="device.isFavorite() ? availableTags.favorite.iconOn : availableTags.favorite.iconOff"
            @click="() => toggleFavorite()">
            <sc-tooltip v-if="device.isFavorite()">
              Favorite device<br>
              Click to remove from favorites
            </sc-tooltip>
            <sc-tooltip v-else>
              Click to mark as favorite device
            </sc-tooltip>
          </q-icon>

          <template v-if="canTag">
            <q-icon dense size="20px" class="tag q-ml-sm"
              v-for="tag of (device.tags || []).filter(t => t.name !== 'favorite')"
              :color="availableTags[tag.name].colorOn" :name="availableTags[tag.name].iconOn"
              @click="() => removeTag(tag)">
              <sc-tooltip>
                {{ availableTags[tag.name].label }}.
                <br>
                Click to remove the tag.
              </sc-tooltip>
            </q-icon>

            <q-icon dense size="20px" class="tag q-ml-sm" color="grey-5" name="add"
              v-if="assignableTags.length !== 0">
              <q-popup-proxy>
                <div class="tag-popup">
                  <div v-for="tag in assignableTags" class="tag row items-center"
                    @click="() => toggleTag(tag)" v-close-popup>
                    <q-icon dense size="20px"
                      :color="device.hasTag(tag) ? tag.colorOn : tag.colorOff" :name="tag.iconOn">
                    </q-icon>
                    <span class="q-ml-sm">
                      {{ tag.label }}
                    </span>
                  </div>
                </div>
              </q-popup-proxy>
            </q-icon>
          </template>
        </div>

        <q-space></q-space>

        <!-- Menu with additional commands -->
        <q-btn-dropdown label="Commands" unelevated icon="sym_o_more_horiz" :ripple="false"
          v-if="canSendCommands">
          <sc-device-commands :show-header="false" :commands="deviceCommands"
            :devices="isMultiDevicePartSelected ? [selectedDevice] : deviceParts">
          </sc-device-commands>
        </q-btn-dropdown>

        <q-btn label="Configure" unelevated icon="settings" @click="showConfigurationDialog()"
          :ripple="false" v-if="canConfigure">
        </q-btn>

        <q-btn label="inventory" unelevated icon="arrow_forward"
          @click="gotoInventory({ selection: [device.serialNumber] })" :ripple="false"
          v-if="canGoToInventory">
        </q-btn>

        <q-btn class="primary" icon="arrow_back" @click="goBack()" label="Back" unelevated
          :ripple="false">
        </q-btn>
      </div>
    </template>

    <!-- Small screen toolbar -->
    <teleport v-if="isInitialized && isSmallScreen" to="#topbar-items">
      <span class="device-label q-mr-sm text-white">
        {{ getDeviceLabel(device) }}
      </span>

      <q-space>
      </q-space>

      <div class="row items-center no-wrap q-gutter-md">
        <sc-round-icon-button v-if="canEditNotes" icon="description" bg-color="indigo-6">
          <q-badge color="grey-2" text-color="black" floating v-if="device.hasNotes">
            {{ device.notes.length }}
          </q-badge>
          <q-popup-proxy ref="notesPopup">
            <div class="notes-popup q-pa-md">
              <sc-device-notes :popup="true" :devices="[device]" :create-new-note="!device.hasNotes"
                @cancel="() => $refs.notesPopup.hide()">
              </sc-device-notes>
            </div>
          </q-popup-proxy>
        </sc-round-icon-button>

        <sc-round-icon-button v-if="canSendCommands" icon="sym_o_more_horiz" bg-color="indigo-6">
          <q-popup-proxy>
            <div class="command-menu">
              <sc-device-commands :show-header="false" :commands="DefaultDeviceCommands"
                :devices="isMultiDevicePartSelected ? [selectedDevice] : deviceParts">
              </sc-device-commands>
            </div>
          </q-popup-proxy>
        </sc-round-icon-button>

        <sc-round-icon-button icon="arrow_back" bg-color="indigo-6" @click="close()">
        </sc-round-icon-button>
      </div>
    </teleport>

    <!-- Dashboard content -->
    <main class="device-dashboard">
      <!-- top banner -->
      <header class="banner" v-if="canSeeScans && isScanning">
        <sc-widget-scan-in-progress :device="selectedDevice" :isEditable="isEditable">
        </sc-widget-scan-in-progress>
      </header>

      <main v-if="isInitialized && selectedDevice" class="content">
        <!-- Left section with widgets -->
        <section class="widgets">

          <sc-tabs v-if="isMultiDevice" :model-value="selectedDevice?.serialNumber"
            @update:model-value="serialNumber => selectDevice(serialNumber)">
            <q-tab :name="device.serialNumber" icon="router" :label="getDeviceLabel(device)"
              :ripple="false"></q-tab>
            <q-tab v-for="part in deviceParts" :name="part.serialNumber" icon="memory"
              :label="`${part.model}: ${part.serialNumber} (${part.modelRegion})`" :ripple="false">
            </q-tab>
          </sc-tabs>

          <div class="rows">
            <template v-if="isMultiDevice && !isMultiDevicePartSelected">
              <!-- Multi-device details -->
              <div class="multi-device q-gutter-md">
                <div class="box">
                  <div class="header">
                    <sc-widget-device-summary :device="selectedDevice" :isEditable="isEditable"
                      :organization="organization" :organizationGuardian="organizationGuardian">
                    </sc-widget-device-summary>
                  </div>
                  <div class="boards">
                    <sc-widget-device-card v-for="part in device.parts" dense :masterDevice="device"
                      :device="part" :place="place" :organization="organization"
                      :isEditable="isEditable" :isSelectable="false" :canComment="false"
                      :cardLink="getPartLink(part)">
                    </sc-widget-device-card>
                  </div>
                </div>

                <sc-widget-device-subscriptions :device="device"
                  :pendingPremiumService="pendingPremiumService" :organization="organization"
                  :organizationGuardian="organizationGuardian" :isEditable="isEditable"
                  v-if="organizationGuardian.mustUse('premium-services-buy')">
                </sc-widget-device-subscriptions>

              </div>
            </template>

            <!-- Info widgets -->
            <div class="row" v-if="!isMultiDevice || isMultiDevicePartSelected">
              <sc-widget-device-summary :device="selectedDevice" :isEditable="isEditable"
                :organization="organization" :organizationGuardian="organizationGuardian">
              </sc-widget-device-summary>

              <sc-widget-device-firmware :device="selectedDevice" :isEditable="isEditable"
                :organization="organization" :organizationGuardian="organizationGuardian">
              </sc-widget-device-firmware>

              <sc-widget-device-subscriptions :device="selectedDevice" :isEditable="isEditable"
                :pendingPremiumService="pendingPremiumService" :organization="organization"
                :organizationGuardian="organizationGuardian">
              </sc-widget-device-subscriptions>

              <sc-widget-device-alerts :device="selectedDevice" :isEditable="isEditable" mini
                v-if="canSeeLastAlert">
              </sc-widget-device-alerts>

              <sc-widget-device-region :device="selectedDevice" :isEditable="isEditable"
                v-if="canSeeRegion">
              </sc-widget-device-region>

              <sc-widget-device-flags :device="selectedDevice" :isEditable="isEditable"
                :organization="organization" :organizationGuardian="organizationGuardian">
              </sc-widget-device-flags>
            </div>

            <!-- Data widgets -->
            <div class="row" v-if="!isMultiDevice || isMultiDevicePartSelected">
              <sc-widget-device-status :device="selectedDevice" :isEditable="isEditable"
                :name="name" :isLiveStatusAllowed="isLiveStatusAllowed"
                :can-fast-sample="canFastSample" :is-fast-sampling="isFastSampling"
                :fast-sampling-settings="statusWatchSettings"
                :remaining-fast-sampling-time="remainingFastSamplingTime"
                @start-fast-sampling="startDeviceFastSampling()">
              </sc-widget-device-status>

              <sc-widget-device-bands v-if="canSeeBands" micro :device="selectedDevice"
                :isEditable="isEditable" show-header :can-reset-device="canResetDevice"
                :can-toggle-bands="canToggleBands">
              </sc-widget-device-bands>

              <sc-widget-device-portsense v-if="canSeePortSense" :device="selectedDevice"
                :isEditable="isEditable">
              </sc-widget-device-portsense>

              <sc-widget-device-alerts v-if="canSeeLastAlert" :device="selectedDevice"
                :isEditable="isEditable">
              </sc-widget-device-alerts>

              <sc-widget-device-temperature v-if="canSeeTemperature" :device="selectedDevice"
                :isEditable="isEditable">
              </sc-widget-device-temperature>

              <sc-widget-device-battery v-if="canSeeBattery" :device="selectedDevice"
                :isEditable="isEditable">
              </sc-widget-device-battery>

              <sc-widget-device-counters v-if="canSeeCounters" :device="selectedDevice"
                :counters="counters" :isEditable="isEditable">
              </sc-widget-device-counters>
            </div>

            <div class="row" v-if="hasSelectedDeviceConnected && isLiveStatusAllowed">
              <sc-widget-tt-usage :device="selectedDevice" :isEditable="isEditable"
                v-if="canSeeTTUsage">
              </sc-widget-tt-usage>

              <sc-widget-device-band-details :device="selectedDevice" :isEditable="isEditable"
                v-if="canSeeBandDetails">
              </sc-widget-device-band-details>

              <sc-widget-device-history v-if="canSeeHistory" :device="selectedDevice"
                :isEditable="isEditable" :organization="organization"
                :organizationGuardian="organizationGuardian">
              </sc-widget-device-history>
            </div>
          </div>

        </section>

        <!-- Right section with info panels -->
        <section class="panels">
          <sc-panel-device-features :device="selectedDevice" :isEditable="isEditable"
            :organization="organization" :organizationGuardian="organizationGuardian">
          </sc-panel-device-features>

          <sc-panel-device-notes :device="selectedDevice" :isEditable="isEditable"
            :organization="organization" :organizationGuardian="organizationGuardian">
          </sc-panel-device-notes>

          <sc-panel-device-scans :device="selectedDevice" :isEditable="isEditable"
            :organization="organization" :organizationGuardian="organizationGuardian">
          </sc-panel-device-scans>
        </section>


        <sc-band-selector-dialog></sc-band-selector-dialog>
        <sc-band-toggle-dialog></sc-band-toggle-dialog>
        <sc-purchase-premium-service-dialog></sc-purchase-premium-service-dialog>
        <sc-file-preview></sc-file-preview>
      </main>
    </main>
  </sc-view>
</template>

<style lang="scss">
.toolbar {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  .tags {
    background-color: white;
    border-radius: 8px;
    padding: 8px;

    .tag {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.tag-popup {
  background-color: white;
  padding: 8px;

  .tag {
    padding: 4px;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
  }
}

.command-menu {
  background-color: white;
  padding: 8px;
}

.notes-popup {
  background-color: white;
  padding: 8px;
}

.device-dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  --widget-spacing: 10px;

  >.loading {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  >.content {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: auto;
    background-color: #efefef;
    padding: var(--widget-spacing);

    >.banner {
      flex: 0;
    }

    >.widgets {
      display: flex;
      flex-direction: column;
      flex: 1;

      >.rows {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #efefef;
        gap: var(--widget-spacing);

        >.row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--widget-spacing);
        }
      }

      .multi-device {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        >.box {
          background-color: white;
          padding: 8px 0 0 16px;
          position: relative;

          >.device-comments {
            position: absolute;
            right: 4px;
            top: 4px;
            cursor: pointer;
          }

          >.boards {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;

            >.widget,
            >.device-card {
              margin-right: 8px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    >.panels {
      flex-basis: 350px;
      max-width: 350px;
      display: flex;
      flex-direction: column;
      padding-left: var(--widget-spacing);
      gap: var(--widget-spacing);
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .device-dashboard {
    --widget-spacing: 5px;

    >.content {
      flex-direction: column;
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .device-label {
    font-size: 16px;
    text-wrap: wrap;
    margin-bottom: unset;
    margin-left: 8px;
    margin-right: 8px;
  }

  .device-dashboard {
    >.content {
      >.rows {
        >.row {
          display: flex;
          margin: 4px;
          gap: 4px;

          >.widget {
            margin: 0;
          }
        }
      }

      /* Hide panels on small screens, there's not enough room for them.
          They are substituted with popups, for example notes popup */
      >.panels {
        display: none;
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 640px) {
  .notes-popup {
    width: 100vw;
  }
}
</style>
