import Konva from 'konva'
import { Point, Rectangle, subtractPolygon, sameArrays, throttle } from '@stellacontrol/utilities'
import { Layer } from './layer'
import { moveToTop, moveToBottom } from '../utilities'
import { createImageShape } from '../utilities'

/**
 * Radiation map layer
 */
export class RadiationLayer extends Layer {
  constructor (data) {
    super(data)
  }

  /**
   * Rendering parameters
   */
  parameters = {
    blurRadius: 12,
    opacity: 0.9,
    blendMode: 'multiply',
    gradient: [0, 'red', 0.4, 'yellow', 0.6, 'greenyellow', 1.0, 'white'],
    blobsOnConnectedAntennasOnly: true,
    radiationMask: {
      color: 'white'
    }
  }

  /**
   * Heatmap layer
   * @type {Konva.Group}
   */
  heatmap

  /**
   * Plan items reflected on the heatmap
   * @type {Dictionary<String, Konva.Shape>}
   */
  items = {}

  /**
   * Heatmap shapes representing plan items
   * @type {Dictionary<String, Konva.Shape>}
   */
  blobs = {}

  /**
   * Returns all antenna items on the plan
   * @type {Array[PlanAntenna]}
   */
  get antennae () {
    return this.floor.items.filter(i => i.isAntenna)
  }

  /**
   * Mask covering everything outside the building perimeter
   * defined as the first of the {@link walls}
   * @type {Konva.Group}
   */
  radiationMask

  /**
   * Set to true when radiation mask is forcefully hidden
   * during certain operations, such as editing the building walls
   * @type {Boolean}
   */
  isRadiationMaskHidden

  /**
   * Plan item currently being processed, i.e. moved, deleted etc.
   * @type {PlanItem}
   */
  processedItem

  /**
   * Returns all shapes representing building confines
   * within which mobile signal is present
   * @type {Array[Konva.Shape]}
   */
  get walls () {
    return this.shapes.filter(s => s.item?.isWall)
  }

  /**
   * Returns true if radiation layer has any walls
   * @type {Boolean}
   */
  get hasWalls () {
    // Any walls must be completed!
    return this.walls.length > 0 &&
      this.walls.every(wall => !wall.item.inProgress)
  }

  /**
   * Returns all shapes representing masks used to delineate
   * inner yards where signal doesn't reach
   * @type {Array[Konva.Shape]}
   */
  get yards () {
    return this.shapes.filter(s => s.item?.isYard)
  }

  /**
   * Returns all shapes representing beams
   * @type {Array[Konva.Shape]}
   */
  get beams () {
    return this.shapes.filter(s => s.item?.key === 'beam')
  }

  /**
   * Creates own shapes of the layer
   * @return {Promise<Array[Shape|Konva.Shape]>}
   */
  async createOwnShapes () {
    const { floor, antennae, parameters } = this
    if (!floor.hasRadiation) {
      return []
    }

    // Remove previous elements
    this.destroy(this.heatmap, this.radiationMask)

    // Heatmap layer
    this.heatmap = new Konva.Group({
      draggable: false,
      listening: false,
      perfectDrawEnabled: false
    })

    // Radiation mask
    this.radiationMask = new Konva.Group({
      listening: false,
      x: 0,
      y: 0
    })
    const contour = new Konva.Line({
      fill: parameters.radiationMask.color,
      stroke: null,
      strokeWidth: 0,
      closed: true
    })
    this.radiationMask.add(contour)

    // Add antenna items
    for (const antenna of antennae) {
      this.itemAdded(antenna)
    }

    // Mask around the entire building shape
    this.refreshRadiationMask()

    // Lock walls and yards
    this.lockWalls(floor.radiation.lockWalls)

    return [this.heatmap, this.radiationMask]
  }

  /**
   * Checks whether anything changed in the wall shape,
   * which could indicate we need to re-render it
   * @param {Konva.Polygon} contour Wall shape
   * @param {Rectangle} bounds Outer wall bounds
   * @param {PlanPolygon} wall Plan item representing the outer wall
   * @returns {Boolean}
   */
  wallHasChanged (contour, bounds, wall) {
    if (!contour) return true
    if ((contour.points() || []).length === 0) return true
    if (!this.__wallBounds) return true
    if (!this.__wallPoints) return true
    if (!this.__wallBounds.sameAs(bounds)) return true
    if (!sameArrays(wall.points, this.__wallPoints, p => p.toString())) return true

    return false
  }

  /**
   * Creates a masking shape over the outside of the building
   * @param {Boolean} forceRefresh If true, the mask is re-rendered even if there were no changes to its shape
   */
  refreshRadiationMask (forceRefresh) {
    const { floor, radiationMask, walls, hasWalls, isRadiationMaskHidden } = this
    if (!floor.hasRadiation) return

    if (forceRefresh) {
      this.__wallBounds = null
      this.__wallPoints = null
    }

    if (hasWalls && floor.radiation.maskOutside && !isRadiationMaskHidden) {
      const wall = walls[0]
      const item = wall.item
      const contour = this.radiationMask.getChildren()[0]
      const bounds = Rectangle
        .dimensions(floor.dimensions)
        .growBy(floor.margin)

      if (this.wallHasChanged(contour, bounds, item)) {
        const contourPoints = subtractPolygon(bounds, item.points).flatMap(p => ([p.x, p.y]))
        contour.points(contourPoints)
        this.__wallBounds = bounds
        this.__wallPoints = [...item.points]
      }

      if (!radiationMask.visible()) {
        radiationMask.show()
      }

    } else {
      radiationMask.hide()
    }

    this.reorder()
  }

  /**
   * Locks/unlocks walls and yards for modifications by the user
   * @param {Boolean} status Lock status
   */
  lockWalls (status) {
    const { floor, walls, yards } = this
    if (!floor.hasRadiation) return

    const isLocked = status == null ? floor.radiation.lockWalls : Boolean(status)
    floor.radiation.lockWalls = isLocked

    const wallsAndYards = floor.items.filter(i => i.isWall || i.isYard)
    for (const item of wallsAndYards) {
      item.isLocked = isLocked
    }

    for (const wall of walls) {
      wall.content.listening(!isLocked)
    }

    for (const yard of yards) {
      yard.content.listening(!isLocked)
    }
  }

  /**
   * Refreshes the layer
   */
  refresh () {
    const { floor } = this
    super.refresh()

    if (floor.hasRadiation) {
      this.refreshRadiationMask()
      this.lockWalls(floor.radiation.lockWalls)
      this.reorder()
    }
  }

  /**
   * Assigns correct z-order to shapes on the layer
   * @returns {Layer} Self-reference if reordering was performed
   */
  reorder () {
    if (super.reorder()) {
      const { content, floor, heatmap, beams, radiationMask, walls, yards } = this
      if (!floor.hasRadiation) return

      // Reordering is not possible when layer is still not at the stage
      if (!content.getParent()) return

      // Walls Masks must be on top of the heatmap
      for (const wall of walls) {
        moveToBottom(wall)
      }

      // Heatmap beams must be on top of the walls
      moveToTop(heatmap)
      for (const beam of beams) {
        moveToTop(beam)
      }

      // If outside masking is turned on
      // yard masks must be on top of all shapes,
      if (floor.radiation.maskOutside) {
        moveToTop(radiationMask)
        for (const yard of yards) {
          moveToTop(yard)
        }
      } else {
        // If radiation outside masking is turned off,
        // bring all the heatmap elements to the very top
        for (const beam of beams) {
          moveToTop(beam)
          moveToTop(heatmap)
        }
      }
    }
  }

  /**
   * Clears the layer
  */
  clear () {
    super.clear()
  }

  /**
   * Returns radiation shape for the specified plan item
   * @param {PlanItem} item Plan item generating radiation, such as antenna
   * @returns {Konva.Shape}
   */
  getRadiationBlob (item) {
    if (item && this.floor.hasRadiation) {
      const shape = this.blobs[item.id]
      return shape
    }
  }

  /**
   * Creates radiation shape for the specified plan item
   * @param {PlanItem} item Plan item generating radiation, such as antenna
   */
  async createRadiationBlob (item) {
    const { layout, floor } = this

    if (!floor.hasRadiation) return
    if (layout.isExternalAntenna(item)) return

    // Destroy any previous blobs related to this item
    const previousBlob = this.blobs[item.id]
    previousBlob?.destroy()

    // Render radiation pattern of antenna
    let blob
    if (item.isOmnidirectionalAntenna) {
      // Omnidirectional antenna
      blob = new Konva.Circle()
    } else if (item.isYagi) {
      // Single YAGI
      blob = await createImageShape('/images/planner/radiation/yagi.png')
    } else if (item.isTwinYagi) {
      // Twin YAGI
      blob = await createImageShape('/images/planner/radiation/twin-yagi.png')
    } else if (item.isWallPanel) {
      // Wall Panel
      blob = await createImageShape('/images/planner/radiation/wall-panel.png')
    }

    if (blob) {
      blob.draggable(false)
      blob.listening(false)
      const shape = new Konva.Group({
        id: item.id,
        draggable: false,
        listening: false
      })

      // Remember the antenna type. If changed later, we will have to re-create the element
      shape.setAttr('antennaType', item.antennaType)
      shape.add(blob)
      this.items[item.id] = item
      this.blobs[item.id] = shape
      this.heatmap.add(shape)

      this.refreshRadiationBlob(item)
    }
  }

  /**
   * Deletes radiation shape for the specified plan item
   * @param {PlanItem} item Plan item generating radiation, such as antenna
   */
  deleteRadiationBlob (item) {
    const shape = this.getRadiationBlob(item)
    if (!shape) return

    shape.destroy()
    delete this.blobs[item.id]
    delete this.items[item.id]
  }

  /**
   * Positions the element representing radiation pattern around the specified plan item
   * @param {PlanItem} item Item whose radiation pattern to position
   */
  placeRadiationBlob (item) {
    const shape = this.getRadiationBlob(item)
    if (!shape) return
    const blob = shape.getChildren()[0]
    if (!blob) return

    const { x, y } = item
    const itemScale = this.layout.scale
    const scale = shape.scale()
    const size = blob.size()

    shape.x(x)
    shape.y(y)

    if (isNaN(scale.x)) {
      scale.x = 1
    }
    if (isNaN(scale.y)) {
      scale.y = 1
    }

    let position
    if (item.isYagi) {
      // Yagi
      // Shift the blob so that the antenna is placed inside, minding the current scale of the blob.
      position = Point
        .from({
          x: x - (200 * scale.x),
          y: y + itemScale.y * (item.height / 2) - ((size.height * scale.y) / 2)
        })
        .rotate(item.rotation, { x, y })

    } else if (item.isTwinYagi) {
      // Twin Yagi
      position = Point
        .from({
          x: x + itemScale.x * (item.width / 2) - (size.width * scale.x) / 2,
          y: y + itemScale.y * (item.height / 2 - 3) - (size.height * scale.y) / 2
        })
        .rotate(item.rotation, { x, y })

    } else if (item.isCeilingPanel) {
      shape.x(x + itemScale.x * item.width / 2)
      shape.y(y + itemScale.y * item.height / 2)

    } else if (item.isWallPanel) {
      // Wall Panel
      position = Point
        .from({
          x: x - (180 * scale.x),
          y: y + itemScale.y * (item.height / 2 - 5) - (size.height * scale.y) / 2
        })
        .rotate(item.rotation, { x, y })
    } else {

      // All other antennas
      position = Point.from(shape.position())
    }

    if (position) {
      // Apply antenna position and radiation
      shape.position(position)
      shape.rotation(item.rotation)
    }
  }

  /**
   * Refreshes the element representing radiation pattern around the specified plan item
   * @param {PlanItem} item Item whose radiation pattern to refresh
   */
  refreshRadiationBlob (item) {
    const shape = this.getRadiationBlob(item)
    if (!shape) return
    const blob = shape.getChildren()[0]
    if (!blob) return

    const {
      renderer: { layout, equipmentHierarchy },
      parameters: { blurRadius, gradient, blendMode, blobsOnConnectedAntennasOnly },
      floor,
      floor: { radiation }
    } = this
    const cableStats = layout.getCableStats(item, equipmentHierarchy)
    shape.hide()

    // Find out the calculated antenna radius (in meters),
    // taking into consideration signal gain/loss on the way from the repeater.
    // Then, apply the global scaling factor for antennae on the floor.
    const radius = cableStats
      ? cableStats.radiation?.radius || 0
      : (blobsOnConnectedAntennasOnly ? 0 : item.parameters.radius) || 0
    // Translate the radius to pixels, using the current map scale
    const pixelRadius = Math.round(floor.metersToPixels(radius) * (radiation.strength / 100) * (item.radiationStrength / 100))

    if (item.isOmnidirectionalAntenna) {
      // Omni-directional antennae
      if (pixelRadius > 0 && (pixelRadius - blurRadius) > 0) {
        blob.radius(pixelRadius)
        blob.fillRadialGradientStartRadius(0)
        blob.fillRadialGradientEndRadius(pixelRadius - blurRadius)
        blob.fillRadialGradientColorStops(gradient)
        shape.show()
      }

    } else if (item.isYagi || item.isTwinYagi || item.isWallPanel) {
      // Directional antennae (excluding laser)
      if (pixelRadius > 0) {
        // Rescale the blob image to match the pixel radius
        if (item.parameters.blobWidth == null || item.parameters.blobHeight == null) {
          throw new Error('Directional antennas require `blobWidth` and `blobHeight` to be defined in AntennaParameters')
        }
        const scale = (pixelRadius * 2) / item.parameters.blobHeight
        shape.scale({ x: scale, y: scale })
        shape.show()
      }
    }

    // Update the blob position
    this.placeRadiationBlob(item)

    // Blur the radiation pattern
    if (shape.visible() && shape.hasChildren()) {
      moveToTop(shape)

      // Omnidirectional antennae
      if (item.isOmnidirectionalAntenna) {
        shape.opacity(this.parameters.opacity)
        shape.cache()
        shape.filters([Konva.Filters.Blur])
        shape.blurRadius(blurRadius)
        shape.globalCompositeOperation(blendMode)
      }

      shape.cache({ imageSmoothingEnabled: false })
    }

  }

  /**
   * Refreshes the radiation blobs of items related to the specified item
   * or all antennas on the floor, if {@link item} is not specified
   * @param {PlanItem} item Item whose related antennas to refresh, optional
   */
  refreshRadiationBlobs (item) {
    const { renderer } = this

    // For other items, check whether they're connected to antennae
    // and update the respective radiation blobs
    const antennae = item && !item.isPlug
      ? renderer.findConnectedEquipment(item, item => item.antennaType)
      : renderer.floor.items.filter(i => i.isAntenna)

    for (const antenna of antennae) {
      this.refreshRadiationBlob(antenna)
    }
  }

  /**
   * Throttled variant of {@link refreshRadiationBlob} used
   * for actions such as moving items, when refresing the radiation
   * blob at every pixel is degrading performance
   * @type {Function}
   */
  throttledRefreshRadiationBlob = throttle(() => {
    const { processedItem } = this
    if (processedItem) {
      this.refreshRadiationBlob(processedItem)
    }
  }, 50)

  /**
   * Throttled variant of {@link refreshRadiationBlobs} used
   * for actions such as moving items, when refresing the radiation
   * blob at every pixel is degrading performance
   * @type {Function}
   */
  throttledRefreshRadiationBlobs = throttle(() => {
    const { processedItem } = this
    if (processedItem) {
      this.refreshRadiationBlobs(processedItem)
    }
  }, 50)


  /**
   * Notifies that the specified item has been added to the plan
   * @param {PlanItem} item Added item
   */
  async itemAdded (item) {
    if (!item) return
    if (!this.floor.hasRadiation) return

    // Render radiation blob for antennae
    if (item.isAntenna) {
      await this.createRadiationBlob(item)
      await this.refreshRadiationMask()
    }

    // Auto-create radiation mask when building wall has been added
    if (item.isWall) {
      await this.refreshRadiationMask()
    }

    // Lock wall and mask items
    if (item.isWall || item.isYard) {
      item.isLocked = this.floor.radiation.lockWalls
    }
  }

  /**
   * Notifies that the specified item has been removed from the plan
   * @param {PlanItem} item Removed item
   */
  itemRemoved (item) {
    if (!item) return

    const { floor } = this
    if (!floor.hasRadiation) return

    // Remove the item shape
    super.itemRemoved(item)

    // If antenna removed, delete the accompanying radiation blob
    if (item.isAntenna) {
      this.deleteRadiationBlob(item)
    }

    // If connector removed, refresh the radiation blobs of any antennas on the floor
    if (item.isConnector) {
      this.refreshRadiationBlobs()
    }

    if (item.isWall) {
      this.refreshRadiationMask()
    }
  }

  /**
   * Notifies that the specified item has been moved
   * @param {PlanItem} item Moved item
   * @param {Boolean} completed If true, the movement has completed (usually when user releases the mouse button)
  */
  // eslint-disable-next-line no-unused-vars
  itemMoved (item, completed) {
    if (!item) return
    if (!this.floor.hasRadiation) return

    // If antenna moved, move its radiation blob
    this.processedItem = item
    if (item.isAntenna) {
      this.placeRadiationBlob(item)
      this.throttledRefreshRadiationBlob()

    } else {
      this.throttledRefreshRadiationBlobs()
    }

    // If wall moved, update the outer mask
    if (item.isWall) {
      this.refreshRadiationMask(true)
    }
  }

  /**
   * Notifies that the specified item properties have changed
   * @param {PlanItem} item Changed item
   */
  itemChanged (item) {
    if (!item) return
    if (!this.floor.hasRadiation) return

    // If antenna properties changed, its radiation blob may need to be re-created or just redrawn
    if (item.isAntenna) {
      const shape = this.getRadiationBlob(item)
      if (shape) {
        // If antenna type changed, re-create its radiation blob
        if (item.isAntenna) {
          if (shape.getAttr('antennaType') !== item.antennaType) {
            this.itemRemoved(item)
            this.itemAdded(item)
          } else {
            this.refreshRadiationBlob(item)
          }
        }
      }
    }

    // If wall changed, re-create the radiation mask
    if (item.isWall) {
      this.refreshRadiationMask(true)
    }
  }

  /**
   * Notifies that the specified item has been selected
   * @param {PlanItem} item Selected item
   */
  itemSelected (item) {
    if (item.isWall) {
      this.isRadiationMaskHidden = true
      this.refreshRadiationMask()
    }
  }

  /**
   * Notifies that the specified item has been deselected
   * @param {PlanItem} item Deselected item
  */
  itemDeselected (item) {
    if (item.isWall) {
      this.isRadiationMaskHidden = false
      this.refreshRadiationMask()
    }
  }
}
