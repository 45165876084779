<script>
import { Color } from '../../services'

// Wrapper component allowing to render any icon inside a circle
export default {
  props: {
    size: {
      type: String,
      default: '32px'
    },

    borderWidth: {
      type: String,
      default: '2px'
    },

    color: {
      type: String,
      default: 'white'
    },

    bgColor: {
      type: String,
      default: 'transparent'
    },

    interactive: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    outerCircleClass () {
      const { interactive, active } = this
      return {
        interactive,
        active
      }
    },

    outerCircleStyle () {
      const { size, borderWidth, color, bgColor } = this
      return {
        '--size': size,
        '--border-width': borderWidth,
        '--color': Color.getColor(color),
        '--bg-color': Color.getColor(bgColor)
      }
    }
  }
}
</script>

<template>
  <div class="outer-circle" :style="outerCircleStyle" :class="outerCircleClass">
    <div class="inner-circle">
      <!-- Prevent icon size passed directly, we calculate it in the icon style -->
      <q-icon class="icon" v-bind="{ ...$props, ...$attrs }" :size="undefined">
      </q-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.outer-circle {
  --size: '';
  --border-width: '';
  --color: '';
  --bg-color: '';
  --inner-size: calc(var(--size) - var(--border-width) * 2);
  --icon-size: calc(var(--size) - var(--border-width) * 2 - 8px);

  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  background-color: var(--color);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .inner-circle {
    width: var(--inner-size);
    height: var(--inner-size);
    border-radius: calc(var(--inner-size) / 2);
    background-color: var(--bg-color);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .icon {
      --size: '';
      --border-width: '';
      font-size: var(--icon-size) !important;
      margin-left: 1px;
    }
  }

  &.interactive {
    cursor: pointer;

    .inner-circle {
      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
      }
    }
  }
}
</style>