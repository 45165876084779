import { state, getters } from './device-history.state'
import * as mutations from './device-history.mutations'
import * as actions from './device-history.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
