<script>
import { getScanSurvey } from '@stellacontrol/devices'
import Indicator from './indicator.vue'

/**
 * Cell ID Scan (Survey)
 */
export default {
  props: {
    // Section collapse status, dictionary
    collapsed: {
      type: Object,
      default: () => { }
    },
    // Operators
    operators: {
      default: () => { }
    },
    // Data file
    file: {
    },
    // Cells results
    items: {
      default: () => []
    },
    // Indicates whether to hide bands which are not boosted by the device
    skipUnboostedBands: {
      type: Boolean,
      default: false
    }
  },

  components: {
    'sc-tt-indicator': Indicator
  },

  computed: {
    // Cell groups to display
    groups () {
      const { file, skipUnboostedBands } = this
      const groups = getScanSurvey(file, { skipUnboostedBands })
      return groups
    },

    // Determines the operator label
    operatorName () {
      return cell => !cell.operator || cell.operator === '-'
        ? cell.plmn
        : cell.operator
    },

    // Determines the CSS class for rendering the cell
    cellClass () {
      return cell => ({
        'serving': cell.isServing,
        'not-boosted': !cell.deviceHasBand,
        'hidden': !cell.deviceHasBand && this.skipUnboostedBands
      })
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    // Toggles a section
    toggle ({ section }) {
      this.$emit('toggle', { section })
    },

    // Populates the view
    populate () {
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <main>
    <table class="cell">
      <thead>
        <tr>
          <th>Operator</th>
          <th>Band</th>
          <th>Cell ID</th>
          <th class="indicator">Power</th>
          <th class="indicator">Quality</th>
          <th class="number">PCI</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="operator in Object.keys(groups)" :key="operator">
          <tr v-for="(cell, index) in groups[operator]" :key="cell.key" :class="cellClass(cell)">
            <td class="operator" v-if="index === 0" :rowspan="groups[operator].length + 1">
              {{ index === 0 ? operatorName(cell) : '' }}
            </td>
            <td class="band">{{ cell.bandLabel }}</td>
            <td class="cell">{{ cell.cellId }}</td>
            <td class="indicator">
              <sc-tt-indicator v-if="cell.signalPower != 0" :value="cell.signalPower"
                range="signalPower"></sc-tt-indicator>
            </td>
            <td class="indicator">
              <sc-tt-indicator v-if="cell.signalPower != 0" :value="cell.signalQuality"
                range="signalQuality"></sc-tt-indicator>
            </td>
            <td class="number">{{ cell.physicalCellId }}</td>
          </tr>
          <tr>
            <td colspan="7">
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </main>
</template>

<style lang="scss" scoped>
main {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  flex-wrap: wrap;
  width: 70%;

  table {
    width: 100%;
    border-collapse: collapse;

    tr {

      td,
      th {
        border: solid #0000001f 1px;
        text-align: left;
        vertical-align: top;
        padding: 8px;
        width: 100px;
        height: 38px;
      }

      &.serving td {
        font-weight: bold;
      }

      &.not-boosted td {
        color: #c8c8c8;

        &.operator {
          color: unset;
        }
      }

      td.operator {
        padding-left: 16px;
        width: auto;
      }

      th.indicator,
      th.number {
        text-align: right;
      }

      td.number {
        text-align: right;
      }

      td.indicator {
        text-align: right;
        padding: 0;
        width: 120px;
      }

      &:hover {
        td {
          background-color: #fafafa;

          &.operator {
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>
