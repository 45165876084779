import { isMultiDevice } from '@stellacontrol/model'
import { createState } from './inventory-view.state'
import { DevicePresenter } from './device-presenter'

export const mutations = {
  /**
   * Initializes the inventory grid with the specified list of devices.
   * @param guardian Initialized security guardian
   * @param deviceTags Available device tags from configuration
   */
  initializeInventoryView (state, { guardian, deviceTags } = {}) {
    // Extract columns which are allowed for the current user
    const columnList = Object
      .entries(state.defaultGridColumns)
      .map(([name, column]) => {
        column.name = name
        const permission = `inventory-column-${name}`
        column.isAllowed = guardian.permissionExists(permission) ? guardian.canUse(permission) : true
        return column
      })

    // Convert to a dictionary
    const columns = columnList
      .reduce((all, column) => ({ ...all, [column.name]: column }), {})

    // Prepare available device tag list
    const deviceTagsList = Object
      .entries(deviceTags)
      .filter(([name, tag]) => name && tag.categories.includes('device'))
      .map(([name, tag]) => ({ name, ...tag }))
    state.deviceTags = deviceTags
    state.deviceTagsList = deviceTagsList

    // Pass available tags to tags column as select filter options
    columns.tags.options = ['all', ...deviceTagsList.map(tag => tag.name)]

    // Initialize grid columns if not done yet, otherwise retain column state from previous time!
    if (Object.keys(state.gridColumns).length === 0) {
      state.gridColumns = columns
      state.gridColumnsList = Object.values(state.gridColumns)
    }
  },

  /**
   * Populates the inventory grid with the specified list of devices.
   * @param {Guardian} guardian Initialized security guardian
   * @param {User} user Currently logged in user
   * @param {Array[Device]} devices Devices to display in the inventory view
   * @param {OrganizationHierarchy} organizationHierarchy Organization hierarchy
   */
  populateInventoryView (state, { guardian, user, devices = [], organizationHierarchy } = {}) {
    // Map devices to presenter items
    state.items = devices.map(device => {
      // Make sure that device knows its parts
      if (isMultiDevice(device)) {
        device.parts = devices.filter(i => i.device.partOf === device.id)
      }
      const item = new DevicePresenter(device, user, organizationHierarchy, guardian)
      return item
    })
  },

  /**
   * Indicates whether inventory view is currently reloading
   * @param {Boolean} clear If true, disposes of all items currently shown
   * @param {Boolean} done If true, signals that reloading is completed
   */
  inventoryReloading (state, { clear = false, done = false } = {}) {
    if (clear) {
      state.items = []
    }
    state.isReloading = done ? false : true
  },

  /**
   * Triggered when device has been added to the list of recently created devices
   */
  deviceAddedToInventory (state, { device } = {}) {
    if (device) {
      const item = state.items.find(i => i.serialNumber === device.serialNumber)
      if (item) {
        item.isRecentlyAdded = true
      }
    }
  },

  /**
   * Changes the sorting order of the inventory grid
   * @param sortBy Sort column
   * @param sortOrder Sort order
   */
  sortInventory (state, { sortBy, sortOrder } = {}) {
    state.gridOptions.sortOrder = sortOrder
    state.gridOptions.sortBy = sortBy
  },

  /**
   * Changes number of rows per page in the inventory grid
   * @param {Number} rowsPerPage Rows per page
   */
  paginateInventory (state, { rowsPerPage = 50 } = {}) {
    state.gridOptions.rowsPerPage = rowsPerPage
  },

  /**
   * Shows/hides a column of the inventory grid
   * @param {String} name Name of a column to show or hide
   * @param {Boolean} isVisible Visibility of the column.
   * If not specified, the current visibility will be toggled.
   */
  toggleInventoryColumn (state, { name, isVisible } = {}) {
    const column = state.gridColumns[name]
    if (column) {
      if (isVisible == null) {
        column.isVisible = !column.isVisible
      } else {
        column.isVisible = isVisible
      }
    }
  },

  /**
   * Toggles visibility of decommissioned devices in the inventory grid
   * @param {Boolean} isVisible Visibility of decommissioned devices.
   * If not specified, the current visibility will be toggled.
   */
  toggleDecommisionedDevices (state, { isVisible } = {}) {
    const { gridOptions } = state
    if (isVisible == null) {
      gridOptions.showDecommissionedDevices = !gridOptions.showDecommissionedDevices
    } else {
      gridOptions.showDecommissionedDevices = isVisible
    }
  },

  /**
   * Toggles visibility of non-connected devices in the inventory grid
   * @param {Boolean} isVisible Visibility of non-connected devices.
   * If not specified, the current visibility will be toggled.
   */
  toggleNonConnectedDevices (state, { isVisible } = {}) {
    const { gridOptions } = state
    if (isVisible == null) {
      gridOptions.showNonConnectedDevices = !gridOptions.showNonConnectedDevices
    } else {
      gridOptions.showNonConnectedDevices = isVisible
    }
  },

  /**
   * Toggles visibility of multi-device parts in the inventory grid
   * @param {Boolean} isVisible Visibility of multi-device parts.
   * If not specified, the current visibility will be toggled.
   */
  toggleDeviceParts (state, { isVisible } = {}) {
    const { gridOptions } = state
    if (isVisible == null) {
      gridOptions.showDeviceParts = !gridOptions.showDeviceParts
    } else {
      gridOptions.showDeviceParts = isVisible
    }
  },

  /**
   * Toggles visibility of simulated devices in the inventory grid
   * @param {Boolean} isVisible Visibility of simulated devices.
   * If not specified, the current visibility will be toggled.
   */
  toggleSimulatedDevices (state, { isVisible } = {}) {
    const { gridOptions } = state
    if (isVisible == null) {
      gridOptions.showSimulatedDevices = !gridOptions.showSimulatedDevices
    } else {
      gridOptions.showSimulatedDevices = isVisible
    }
  },

  /**
   * Applies a grid option from the specified user preference
   * @param preferences Dictionary of user preferences
   * @param preference Name of the preference to apply
   * @param option Name of the option to set
   * @param column If true, the preference should be applied to a grid column
   */
  inventoryApplyPreference (state, { preferences, preference: key, option, column: columnName }) {
    const { gridOptions: options, gridColumns: columns } = state
    const preferenceName = columnName
      ? `inventory-column-${columnName.toLowerCase()}-${key}`
      : `inventory-${key}`
    let value = preferences[preferenceName]

    if (value != null) {
      if (columnName) {
        const column = (columns[columnName] || {})

        // Do not show columns previously visible but now disabled
        if (option === 'isVisible' && columns[columnName].isDisabled) {
          value = false
        }

        column[option] = value
      } else {
        options[option] = value
      }
    }
  },

  /**
   * Marks the whole inventory as (un)selected
   * @param isSelected State of the inventory items
   */
  selectWholeInventory (state, { isSelected = true } = {}) {
    for (const item of state.items) {
      item.isSelected = isSelected
    }
  },

  /**
   * Marks the specified inventory item as (un)selected
   * @param item Inventory item to (un)select, specified by either identifier or serial number
   * @param isSelected State of the inventory item
   */
  selectInventoryItem (state, { item: { id, serialNumber }, isSelected = true } = {}) {
    const item = state.items.find(i => serialNumber ? i.serialNumber === serialNumber : i.id === id)
    if (item) {
      item.isSelected = isSelected
    }
  },

  /**
   * Marks the specified inventory items as (un)selected
   * @param items Inventory items to (un)select, specified by either identifier or serial number
   * @param isSelected State of the inventory items
   */
  selectInventoryItems (state, { items = [], isSelected = true } = {}) {
    for (const { id, serialNumber } of items) {
      const item = state.items.find(i => serialNumber ? i.serialNumber === serialNumber : i.id === id)
      if (item) {
        item.isSelected = isSelected
      }
    }
  },

  /**
   * Deselects all currently selected inventory items
   */
  deselectInventoryItems (state) {
    for (const item of state.items) {
      item.isSelected = false
    }
  },

  /**
   * Selects a range of items on a current page
   * @param items Items on the current page
   * @param startItem Item marking start of the range. If not specified, the last selected item
   * before the end of the range will be taken as start item.
   * @param endItem Item marking end of the range
   * @param isSelected Required state of the inventory items
   */
  selectInventoryRange (state, { items = [], isSelected = true, startItem, endItem } = {}) {
    if (endItem && items && items.length > 0) {
      let rangeStart = startItem ? items.findIndex(i => i.id === startItem.id) : undefined
      let rangeEnd = items.findIndex(i => i.id === endItem.id)

      // Determine range start if not explicitly specified
      if (rangeStart == null) {
        if (isSelected) {
          // SELECT MODE: find the last selected item preceding the range end
          for (let i = rangeEnd - 1; i >= -1; i--) {
            if ((items[i] || {}).isSelected) {
              rangeStart = i
              break
            }
          }
        } else {
          // DESELECT MODE: find continuous range of selected items preceding the range end
          rangeStart = rangeEnd - 1
          while ((items[rangeStart] || {}).isSelected) {
            rangeStart--
          }
          rangeStart++
        }
      }

      // (DE)SELECT the range
      if (rangeStart != null && rangeStart < rangeEnd) {
        const itemsBetween = items.slice(rangeStart, rangeEnd + 1)
        for (const { id } of itemsBetween) {
          const item = state.items.find(i => i.id === id)
          if (item) {
            item.isSelected = isSelected
          }
        }
      }
    }
  },

  /**
   * Filters the inventory on specified column
   * @param name Name of a column to filter on
   * @param value Filter value
   */
  setInventoryFilter (state, { column: { name }, value } = {}) {
    state.gridFilters[name] = value
  },

  /**
   * Applies current filters to the inventory items
   */
  applyInventoryFilters (state) {
    const { items, gridColumns: columns, gridOptions: options, gridFilters } = state

    // Get the applicable filters
    const filters = Object
      .entries(columns)
      .filter(([name, column]) => name && column.isAllowed && column.isVisible && gridFilters[name] != null)
      .map(([name]) => ({
        name,
        filter: gridFilters[name]
      }))

    // Apply to grid items
    for (const item of (items || [])) {
      item.isSelected = false
      item.filter(filters, options)
    }
  },

  /**
   * Clears a column filter
   * @param name Name of a column to filter on
   */
  clearInventoryFilter (state, { column: { name } } = {}) {
    const column = state.gridColumnsList.find(c => c.name === name)
    if (column) {
      state.gridFilters[name] = column.defaultFilter == null ? null : column.defaultFilter
    }
  },

  /**
   * Clears all the filters currently applied to the inventory grid
   */
  clearInventoryFilters (state) {
    state.gridFilters = {}
    for (const column of state.gridColumnsList) {
      state.gridFilters[column.name] = column.defaultFilter == null ? null : column.defaultFilter
    }
  },

  /**
   * Triggered when device data has been refreshed
   * @param {Device} device Device
   */
  deviceRefreshed (state, { device } = {}) {
    if (device) {
      const item = (state.items || []).find(i => i.serialNumber === device.serialNumber)
      if (item) {
        // Make sure that device knows its parts
        item.device = device
        if (isMultiDevice(device)) {
          item.device.parts = (state.items || []).filter(i => i.device.partOf === device.id).map(i => i.device)
        }
      }
    }
  },

  /**
   * Triggered when upload job update is received.
   * Updates upload status on any corresponding devices.
   * @param {UploadJob} job Updated job
   */
  storeUploadJob (state, { job = {} } = {}) {
    const item = state.items.find(i => i.id === job.deviceId)
    if (item && item.device) {
      item.device.updateUploadStatus(job)
    }
  },

  /**
   * Triggered when upload job status is received.
   * Updates upload status on any corresponding devices.
   * @param {UploadJobStatus} status Upload job status
   */
  storeUploadStatus (state, { status = {} } = {}) {
    const item = state.items.find(i => i.id === status.deviceId)
    if (item && item.device) {
      item.device.updateUploadStatus(status)
    }
  },

  /**
   * Triggered when upload job has been removed.
   * Removes it from devices to which the job refers.
   * @param {UploadJob} job Remove job
   */
  removeUploadJob (state, { job } = {}) {
    if (job) {
      const item = state.items.find(i => (i.device.snapshot || {}).firmwareUpdateJobId === job.id)
      if (item && item.device) {
        item.device.cancelUpload()
      }
    }
  },

  /**
   * Triggered when devices are removed from a premium subscription
   * @param {PremiumServiceSubscription} subscription Premium subscription
   * @param {Array[Devices]} devices Devices to remove from premium subscription
   * @returns {PremiumServiceSubscription} Modified subscription
   */
  unsubscribeDevices (state, { subscription = {}, devices = [] } = {}) {
    if (subscription && devices && devices.length > 0) {
      for (const { device } of state.items) {
        if (devices.find(d => d.id === device.id)) {
          if (device.premiumSubscriptionId === subscription.id) {
            device.clearPremiumService()
          }
        }
      }
    }
  },

  /**
   * Clears free-of-charge premium services currently assigned to specified devices
   * @param {Array[Device]} devices Devices to clear the service from
   */
  clearPremiumServicesOnDevices (state, { devices } = {}) {
    if (devices && devices.length > 0) {
      for (const { device } of state.items) {
        if (devices.find(d => d.id === device.id)) {
          device.clearPremiumService()
        }
      }
    }
  },

  /**
   * Triggered when live status of a device has been received.
   * Stores the status of a device under its matching entry in `items`.
   * @param {DeviceStatus} status Live status of a single device
   * @param {Device} device Device whose status has been received
   */
  deviceStatusReceived (state, { device, status } = {}) {
    const { serialNumber } = device || {}
    if (serialNumber) {
      const item = state.items.find(item => item.serialNumber === serialNumber)
      if (item) {
        item.status = status
        status.updateDevice(item.device)
      }
    }
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  },

  // Called when navigating to the inventory,
  // clears the intermittent state
  resetInventoryView (state) {
    Object.assign(state, createState(state))
  }
}


