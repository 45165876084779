<script>
import { DeviceOperatingMode } from '@stellacontrol/model'
import Widget from './widget'

export default {
  mixins: [
    Widget
  ],

  computed: {
    // Checks whether the device is currently performing a scan
    isScanning () {
      return this.status?.connection?.operatingMode === DeviceOperatingMode.Scanning
    }
  }
}
</script>

<template>
  <div class="widget mini-widget scan-in-progress bg-orange-7">
    <q-banner class="cell-scan bg-orange-7 text-white row items-center" dense>
      <q-icon name="network_check" class="q-mr-sm" size="md"></q-icon>
      <span class="text-subtitle1">
        Cell scan in progress ...
      </span>
    </q-banner>
  </div>
</template>

<style lang="scss" scoped>
.scan-in-progress {
  width: 100%;
  padding-left: 8px;
}
</style>
