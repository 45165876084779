<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { EntityType, EntityDescription, getOrganizationIcon, getPlaceIcon, getPlaceDescription, getUserIcon, DateRange } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Indicates whether navigation links etc.
    // are enabled
    enabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      // Search condition
      condition: '',
      EntityType,
      EntityDescription
    }
  },

  computed: {
    ...mapState({
      // Recent status of devices
      deviceStatus: state => state.deviceStatus.devices,
      // Indicates that search is ongoing
      isSearching: state => state.search.isSearching,
      // Search results
      results: state => state.search.groups
    }),

    ...mapGetters([
      'organizations',
      'organizationsDictionary',
      'loggedInAsMaster',
      'isSmallScreen',
      'canLoginAsUser'
    ]),

    // Current device status
    status () {
      return (device) => this.deviceStatus[device.serialNumber]
    },

    statusColor () {
      return (device) => {
        const status = this.status(device)
        return status?.connection?.color || '#a0a0a0'
      }
    },

    // Search results per entity type
    groups () {
      return Object
        .entries(this.results)
        .map(([type, items]) => ({ type, items }))
        .filter(group => group.items?.length > 0)
    },

    // Number of found items
    resultCount () {
      return this.groups.reduce((count, group) => count + group.items.length, 0)
    },

    // Returns true if there are any search results to be shown
    hasResults () {
      return this.groups.length > 0
    },

    // Indicates whether devices can be configured by the current user
    canConfigureDevice () {
      return device => device.serialNumber &&
        device.isConnectedDevice &&
        this.canUseChildrenOf('device-configuration') &&
        this.guardian.canDeviceUse('live-status', device.serialNumber)
    },

    // Dictionary of organizations administrators
    administrators () {
      const administrators = this.organizations
        .map(({ id, name, primaryAdministrator = [] }) => {
          if (primaryAdministrator) {
            return {
              organization: { id, name },
              text: primaryAdministrator.name
            }
          }
        })
        .reduce((all, a) => ({ ...all, [a.organization.id]: a }), {})
      return administrators
    },

    // Checks whether user is allowed to log in as admin to the specified organization
    canLoginToOrganization () {
      return organization => organization &&
        organization.id !== this.currentOrganization.id && // Cannot log on to self,
        this.canUse('login-to-child-organizations') && // Must have permission to log in to child organizations,
        !this.loggedInAsMaster && // Must not already be logged-in-as,
        this.administrators[organization.id] && // Primary administrator of the organization must be there
        !this.isSmallScreen // We don't allow login-as on mobile
    },

    // Checks whether user is allowed to impersonate the specified user
    canImpersonateUser () {
      return user => this.canLoginAsUser(user) &&
        !this.isSmallScreen // We don't allow login-as on mobile
    },
  },

  methods: {
    ...mapActions([
      'startEntitySearch',
      'clearEntitySearch',
      'filterInventory',
      'gotoRoute',
      'showDialog',
      'showDeviceHistory',
      'loginToOrganization'
    ]),

    countString,
    getOrganizationIcon,
    getPlaceIcon,
    getPlaceDescription,
    getUserIcon,

    // Starts searching
    async search (condition = '') {
      condition = condition.trim()
      this.condition = condition
      this.clearEntitySearch()
      if (condition) {
        await this.startEntitySearch({ condition })
      }
    },

    // Hides results
    hideResults () {
      this.condition = ''
      this.clearEntitySearch()
    },

    // Launches device configuration dialog
    configureDevice (device) {
      const { serialNumber } = device
      this.showDialog({ dialog: 'device-configuration', data: { serialNumber } })
      this.hideResults()
    },

    // Shows history of the specified device
    showHistory (device) {
      this.showDeviceHistory({ device, period: DateRange.today() })
      this.hideResults()
    },

    // Opens the specified device in the inventory
    async showInInventory (device) {
      const { serialNumber } = device
      await this.filterInventory({ column: { name: 'serialNumber' }, value: serialNumber })
      await this.gotoRoute({ name: 'inventory', query: { selection: serialNumber } })
      this.hideResults()
    },

    // Logs in as the specified user
    loginAsUser (user) {
      const organization = this.organizationsDictionary[user?.organizationId]
      this.loginToOrganization({ organization, user, confirm: !this.isSuperAdministrator })
    }
  }
}
</script>

<template>
  <div class="search">
    <q-input class="input-filter" dark standout square dense icon="search" debounce="250"
      max-length="50" :model-value="condition" @update:model-value="condition => search(condition)"
      :disabled="isSearching">
      <template v-slot:prepend>
        <q-icon name="search"></q-icon>
      </template>
    </q-input>

    <div class="searching row items-center q-pa-md" v-if="isSearching && !hasResults">
      <q-icon name="change_circle" class="rotate-reverse q-mr-md" color="blue-7"
        size="22px"></q-icon>
      <span>
        Searching for
        <b><i>{{ condition }}</i></b>
        ...
      </span>
    </div>

    <div class="search-results" v-if="hasResults">
      <header class="row items-center bg-indigo-7 q-pa-sm">
        <span class="text-subtitle1 text-white text-bold">
          Found {{ countString(resultCount, 'item') }}
        </span>

        <q-space></q-space>

        <q-icon v-if="isSearching" class="icon-searching rotate-reverse" name="change_circle"
          color="white" size="20px">
        </q-icon>
        <q-icon v-else class="button-close pointer" name="close" size="22px" color="white"
          @click="hideResults()">
        </q-icon>
      </header>

      <main class="q-pa-md">
        <div v-for="group in groups" class="group">
          <div class="group-title">
            {{ countString(group.items, EntityDescription[group.type]) }}
          </div>

          <!-- Devices -->
          <ul class="group-items" v-if="group.type === EntityType.Device">
            <li v-for="item in group.items" class="group-item">
              <div class="content row justify-between">
                <div class="row items-center">
                  <q-icon size="16px" name="fiber_manual_record"
                    :style="{ color: statusColor(item) }"></q-icon>
                  <router-link class="item-link q-ml-sm" @click="hideResults()"
                    :to="{ name: 'device-dashboard', params: { serialNumber: item.serialNumber } }">
                    {{ item.acronym }} {{ item.serialNumber }}
                    <sc-tooltip :text="`Go to ${item.serialNumber} dashboard`"></sc-tooltip>
                  </router-link>
                </div>

                <div class="buttons row items-center"
                  :class="{ 'one-item': group.items.length === 1 }">

                  <q-btn @click="hideResults()" unelevated round dense icon="dashboard" size="sm"
                    class="q-ml-sm"
                    :to="{ name: 'device-dashboard', params: { serialNumber: item.serialNumber } }">
                    <sc-tooltip :text="`Go to ${item.serialNumber} dashboard`"></sc-tooltip>
                  </q-btn>

                  <q-btn v-if="canUse('history-graph') && item.serialNumber" unelevated round dense
                    icon="timeline" size="sm" class="q-ml-sm" @click="showHistory(item)">
                    <sc-tooltip text="Show device history"></sc-tooltip>
                  </q-btn>

                  <q-btn v-if="canUse('inventory') && item.serialNumber" unelevated round dense
                    icon="list_alt" size="sm" class="q-ml-sm" @click="showInInventory(item)">
                    <sc-tooltip text="Show in the inventory"></sc-tooltip>
                  </q-btn>

                  <q-btn v-if="canConfigureDevice(item)" unelevated round dense icon="settings"
                    size="sm" class="q-ml-sm" @click="configureDevice(item)">
                    <sc-tooltip text="Configure the device"></sc-tooltip>
                  </q-btn>

                </div>
              </div>
            </li>
          </ul>

          <!-- Buildings -->
          <ul class="group-items"
            v-if="canUse('device-dashboard') && group.type === EntityType.Place">
            <li v-for="item in group.items" class="group-item">
              <div class="content row justify-between">
                <div class="row items-center">
                  <q-icon :name="getPlaceIcon(item.placeType)" size="sm" color="blue-6">
                  </q-icon>
                  <span class="q-ml-xs">
                    {{ item.name }}
                  </span>
                </div>

                <div class="buttons row items-center">
                  <q-btn @click="hideResults()" unelevated round dense icon="dashboard" size="sm"
                    class="q-ml-sm"
                    :to="{ name: 'building-dashboard', params: { id: item.id, organizationId: item.organizationId } }">
                    <sc-tooltip :text="`Go to ${item.name} dashboard`"></sc-tooltip>
                  </q-btn>

                  <q-btn v-if="isAdministrator" unelevated round dense icon="edit" size="sm"
                    class="q-ml-sm" @click="hideResults()"
                    :to="{ name: 'organization-place', params: { organizationId: item.organizationId, id: item.id } }">
                    <sc-tooltip :text="`Go to ${item.name} settings`"></sc-tooltip>
                  </q-btn>
                </div>
              </div>
            </li>
          </ul>

          <!-- Organizations -->
          <ul class="group-items" v-if="isAdministrator && group.type === EntityType.Organization">
            <li v-for="item in group.items" class="group-item">
              <div class="content row justify-between">
                <div class="row items-center">
                  <q-icon :name="getOrganizationIcon(item)" size="sm" color="blue-6">
                  </q-icon>
                  <span class="q-ml-xs">
                    {{ item.name }} {{ item.profile ? `(${item.profile.fullName})` : '' }}
                  </span>
                </div>

                <div class="buttons row items-center">
                  <q-btn @click="hideResults()" unelevated round dense icon="dashboard" size="sm"
                    class="q-ml-sm"
                    :to="{ name: 'installations', query: { filter: item.name, exact: true } }">
                    <sc-tooltip :text="`Go to ${item.name} dashboard`"></sc-tooltip>
                  </q-btn>

                  <q-btn v-if="isAdministrator" unelevated round dense icon="edit" size="sm"
                    @click="hideResults()" class="q-ml-sm"
                    :to="{ name: 'organization', params: { id: item.id } }">
                    <sc-tooltip :text="`Go to ${item.name} settings`"></sc-tooltip>
                  </q-btn>

                  <q-btn v-if="canLoginToOrganization(item)" unelevated round dense icon="login"
                    size="sm" class="q-ml-sm"
                    @click="loginToOrganization({ organization: item, confirm: !isSuperAdministrator })">
                    <sc-tooltip :text="`Log in to ${item.name}`"></sc-tooltip>
                  </q-btn>

                </div>
              </div>
            </li>
          </ul>

          <!-- Users -->
          <ul class="group-items" v-if="isAdministrator && group.type === EntityType.User">
            <li v-for="item in group.items" class="group-item">
              <div class="content row justify-between">
                <div class="row items-center">
                  <q-icon :name="getUserIcon(item)" size="sm" color="blue-6">
                  </q-icon>
                  <span class="q-ml-xs">
                    {{ item.fullName }}
                    {{ item.fullName !== item.name ? `, ${item.name}` : '' }},
                    {{ organizationsDictionary[item.organizationId]?.name || '' }}
                  </span>
                </div>

                <div class="buttons row items-center">
                  <q-btn v-if="isAdministrator" unelevated round dense icon="edit" size="sm"
                    class="q-ml-sm" @click="hideResults()"
                    :to="{ name: 'user', params: { id: item.id }, query: { organizationId: item.organizationId } }">
                    <sc-tooltip :text="`Go to ${item.name} settings`"></sc-tooltip>
                  </q-btn>

                  <q-btn v-if="canImpersonateUser(item)" unelevated round dense icon="login"
                    size="sm" class="q-ml-sm" @click="loginAsUser(item)">
                    <sc-tooltip :text="`Log in as ${item.name}`"></sc-tooltip>
                  </q-btn>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </main>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.search {
  position: relative;

  .searching,
  .search-results {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 100;
    top: 65px;
    left: 48px;
    min-width: 430px;
    max-height: 590px;
    overflow: hidden;
    background-color: #f0f4fd;
    box-shadow: 0 0 15px #333333;
    border-radius: 5px;

    >header {
      flex: 0;
    }

    >main {
      flex: 1;
      overflow: hidden;
      overflow-y: auto;

      .group {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .group-title {
          font-weight: bold;
        }

        .group-items {
          list-style: none;
          margin: 0;
          padding: 2px 6px 0 0;

          .group-item {
            padding: 2px;

            &:hover {
              background-color: #e0e7f6;
            }

            .content {
              height: 30px;

              .buttons {
                visibility: hidden;
              }

              .buttons.one-item {
                visibility: visible;
              }

              &:hover {
                .buttons {
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }

}
</style>